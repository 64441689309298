<div class="header" [ngClass]="{ expanded: expanded }">
  <ion-grid class="ion-no-padding">
    <ion-row class="ion-no-padding">
      <ion-col
        class="header-icon flex ion-justify-content-center ion-align-items-center"
        size="2"
      >
        <ng-content select="[list-item-header-icon]"></ng-content>
      </ion-col>
      <div class="header-title">
        <ng-content></ng-content>
      </div>
      <ion-col
        class="flex ion-justify-content-center ion-align-items-center"
        size="2"
      >
        <i
          class="icon icon-ic_arrow icon-expand-collapse"
          [@openCloseArrow]="expanded"
        ></i>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
