import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { landingActions } from '@store/actions';
import { catchError, concatMap, map } from 'rxjs/operators';
import { TickethistoryService } from 'src/app/pages/betslip/tickethistory/tickethistory.service';
import { of } from 'rxjs';
import { CasinoService } from '@core/services/casino/casino.service';

@Injectable()
export class LandingWinnersEffects {
  loadWinners = createEffect(() => {
    return this._actions$.pipe(
      ofType(landingActions.loadWinners),
      concatMap(action =>
        this._ticketHistoryService.getBettingWinnerTicket().pipe(
          map(data => landingActions.loadWinnersSuccess({ tickets: data })),
          catchError(error => of(landingActions.loadWinnersFailure(error)))
        )
      )
    );
  });

  constructor(
    private _actions$: Actions,
    private _ticketHistoryService: TickethistoryService
  ) {}
}

@Injectable()
export class LandingCasinoWinnersEffects {
  loadCasinoWinners = createEffect(() => {
    return this._actions$.pipe(
      ofType(landingActions.loadCasinoWinners),
      concatMap(action =>
        this._casinoService.playerOfLastDay().pipe(
          map(data =>
            landingActions.loadCasinoWinnersSuccess({ wins: data.Result })
          ),
          catchError(error =>
            of(landingActions.loadCasinoWinnersFailure(error))
          )
        )
      )
    );
  });

  constructor(
    private _actions$: Actions,
    private _casinoService: CasinoService
  ) {}
}
