import * as authActions from '../auth/auth.actions';
import * as offerActions from '../../pages/offer/store/offer.actions';
import * as detailActions from '../../pages/detail/detail/store/detail.actions';
import * as landingActions from '../../pages/landing/landing/store/landing.actions';
import * as analysesActions from '../../pages/analysis/store/analyses.actions';
import * as actualitiesActions from '../../pages/actuality/store/actualities.actions';
import * as maintenanceActions from '../maintenance/maintenance.actions';
import * as personalInfoActions from '../../pages/profile/store/personalinfo.actions';
import * as tickethistoryActions from '../../pages/betslip/tickethistory/store/tickethistory.actions';
import * as documentActions from '../../pages/document/store/document.actions';
import * as ticketActions from '../../pages/ticket/store/ticket.actions';
import * as buyBackActions from '../buyback/buyback.actions';
import * as favoritesActions from '../favorites/favorites.actions';
import * as notificationsActions from '../notifications/notifications.actions';

export {
  authActions,
  offerActions,
  detailActions,
  landingActions,
  analysesActions,
  actualitiesActions,
  maintenanceActions,
  personalInfoActions,
  tickethistoryActions,
  documentActions,
  ticketActions,
  buyBackActions,
  favoritesActions,
  notificationsActions
};

import * as bonusesActions from 'src/app/pages/bonuses/store/bonuses.actions';
import * as casinoActions from 'src/app/pages/casino/store/casino.actions';
import { createAction } from '@ngrx/store';

export { bonusesActions, casinoActions };

export const initApp = createAction('APP_INITIALIZED');
