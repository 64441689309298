import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { favoritesSelectors } from '@store/selectors';
import { Observable } from 'rxjs';

@Pipe({
  name: 'isFavLeague'
})
export class IsFavLeaguePipe implements PipeTransform {
  constructor(private _store: Store) {}
  /** return observable of boolean indicates league is favorite */
  transform(id: number | string): Observable<boolean> {
    if (typeof id === 'string') {
      id = parseInt((id + '').replace(/\D*/, ''), 10);
    }
    return this._store.select(favoritesSelectors.selectIsLeagueInFav(id));
  }
}
