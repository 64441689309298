import { PipeTransform, Pipe, Host, Optional } from '@angular/core';
import { translateRoute } from '@core/functions/translateRoute';
import { NavigationConfig, ParamValue, ScreenParamsType } from 'src/navigation';
import { RouterLink } from '@angular/router';

const REGX =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/g;

@Pipe({
  name: 'translateRoute',
  pure: true
})
export class TranslateRoutePipe implements PipeTransform {
  constructor(@Host() @Optional() private _routerLink: RouterLink) {}
  /**
   * Translate route link in template using pipe
   * Check translate route function
   */
  transform<T extends keyof NavigationConfig>(
    screen: T,
    params?: { [key in ScreenParamsType<T>]?: ParamValue }
  ): string {
    if (!screen) {
      return;
    }

    if (typeof screen === 'string' && REGX.test(screen)) {
      // is external link
      return screen;
    } else {
      // is internal link
      const splittedScreen = screen.toString().split('?');
      if (splittedScreen[1]) {
        this._routerLink.queryParams = Object.fromEntries(
          new URLSearchParams('?' + splittedScreen[1]).entries()
        );
      }
      return translateRoute(splittedScreen[0] as T, params as any);
    }
  }
}
