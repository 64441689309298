import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { ticketSelectors } from '@store/selectors';
import { configuration } from '@configuration';

@Component({
  selector: 'doxx-freebets-modal',
  templateUrl: './freebets-modal.component.html',
  styleUrls: ['./freebets-modal.component.scss']
})
export class FreebetsModalComponent {
  constructor(private _modalCtrl: ModalController, private _store: Store) {}

  freebets$ = this._store.select(ticketSelectors.selectFreebets);
  configuration = configuration;
  @Input() selectedFreebet;

  /** on select freebet */
  selectFreebet(event: number) {
    if (!!event) {
      this._modalCtrl.dismiss(event);
    }
  }

  /**
   * zatvori modal
   */
  closeFreebetsModal(): void {
    this._modalCtrl.dismiss(this.selectedFreebet);
  }
}
