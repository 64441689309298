import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLanding from '../landing.reducer';

const selectLandingState = createFeatureSelector<fromLanding.State>(
  fromLanding.LANDING_FEATURE_KEY
);

export const selectTopLeagues = createSelector(
  selectLandingState,
  (state: fromLanding.State) => state.topLeagues
);
