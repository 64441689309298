import { configuration } from '@configuration';
import { ConfigBaseCurrencies } from 'src/configuration/types/configBaseCurrencies';
import { localeParseFloat } from './localeParseFloat';

/**
 * Transforms number to string with currency, you can use additional properties if you want
 * default value for currency is currency set in evnironment
 */
export function formatNumberWithCurrency(
  value: number | string,
  props?: { currency?: string; decimalDigits?: number }
): string;
export function formatNumberWithCurrency(
  value: number | string,
  props: { currency?: string; decimalDigits?: number },
  complex: boolean
): { value: string; currency: ConfigBaseCurrencies; originalValue: number };
/** */
export function formatNumberWithCurrency(
  value: number | string,
  props: { currency?: string; decimalDigits?: number } = null,
  complex: boolean = false
):
  | { value: string; currency: ConfigBaseCurrencies; originalValue: number }
  | string {
  const minimumFractionDigits = !isNaN(props?.decimalDigits)
    ? props.decimalDigits
    : undefined;
  const maximumFractionDigits = !isNaN(props?.decimalDigits)
    ? props.decimalDigits
    : 20;

  if (!(value === null || value === undefined)) {
    let currency = configuration.base.defaultCurrency;
    if (props?.currency) {
      currency =
        configuration.base.currencies.find(
          curr => curr.code === props.currency || curr.symbol === props.currency
        ) || currency;
    }

    if (typeof value === 'string') {
      value = !isNaN(+value)
        ? +value
        : localeParseFloat(value, currency.locale.replace('_', '-'));
    }
    let val;
    try {
      try {
        val = value.toLocaleString(currency.locale.replace('_', '-'), {
          style: 'currency',
          currency: currency.code,
          currencyDisplay: 'narrowSymbol',
          minimumFractionDigits,
          maximumFractionDigits
        } as any);
      } catch (e) {
        // currencyDisplay: 'narrowSymbol', may not be supported, replace formated symbol to sybol from config eg. US$100 => $100

        val = value.toLocaleString(currency.locale.replace('_', '-'), {
          style: 'currency',
          currency: currency.code,
          minimumFractionDigits,
          maximumFractionDigits
        });
        const tmp = (0).toLocaleString(currency.locale.replace('_', '-'), {
          style: 'currency',
          currency: currency.code,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
        val = val.replace(tmp.replace('0', ''), currency.symbol);
      }
    } catch (e) {
      val = value.toLocaleString(currency.locale.replace('_', '-'), {
        style: 'currency',
        currency: currency.code,
        minimumFractionDigits,
        maximumFractionDigits
      });
    }
    val = val.replace(/nan/i, '-');
    if (complex) {
      return {
        currency,
        originalValue: value,
        value: val
      };
    } else {
      return val;
    }
  }
}
