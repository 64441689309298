import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { of } from 'rxjs';

import { TickethistoryService } from '../tickethistory.service';
import { buyBackActions, tickethistoryActions } from '@store/actions';
import { Store } from '@ngrx/store';
import { tickethistorySelectors } from '@store/selectors';
import { statusTransform } from '@core/functions/statusTransform/statusTransform';
import { LAST_TICKETS_COUNT } from '@constants';

const ticketsToChanges = tickets => ({
  changes: tickets
    .filter(ticket => statusTransform(ticket).status === 'pend')
    .map(ticket => ({
      ticketCode: ticket.ticketCode,
      changes: {
        BuyBackValue: Number(ticket.buyBackValue)
      }
    }))
});

@Injectable()
export class TickethistoryEffects {
  loadLastTickets$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(tickethistoryActions.loadLastTickets),
      switchMap(action =>
        this._ticketHistoryService
          .getTicketHistory({
            type: 'last',
            page: 1,
            size: LAST_TICKETS_COUNT
          })
          .pipe(
            map(data =>
              tickethistoryActions.loadLastTicketsSuccess({
                tickets: data.tickets
              })
            ),
            catchError(err =>
              of(tickethistoryActions.loadLastTicketsFailure({ error: err }))
            )
          )
      )
    );
  });

  loadTickethistoryList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(tickethistoryActions.loadTickethistoryList),
      withLatestFrom(
        this._store.select(tickethistorySelectors.selectTicketHistoryCursor)
      ),
      switchMap(([action, cursor]) =>
        this._ticketHistoryService.getTicketHistory(cursor).pipe(
          switchMap(data => [
            tickethistoryActions.loadTickethistoryListSuccess({
              ...data
            }),
            buyBackActions.updateBuybackValue(ticketsToChanges(data.tickets))
          ]),
          catchError(error =>
            of(tickethistoryActions.loadTickethistoryListFailure({ error }))
          )
        )
      )
    );
  });

  loadMoreTicketHistoryList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(tickethistoryActions.loadMoreHistoryList),
      withLatestFrom(
        this._store.select(tickethistorySelectors.selectTicketHistoryCursor)
      ),
      concatMap(([action, cursor]) =>
        this._ticketHistoryService
          .getTicketHistory({
            ...cursor
          })
          .pipe(
            switchMap(data => [
              tickethistoryActions.loadMoreHistoryListSuccess({
                ...data,
                cursor
              }),
              buyBackActions.updateBuybackValue(ticketsToChanges(data.tickets))
            ]),
            catchError(error =>
              of(tickethistoryActions.loadMoreHistoryListFailure({ error }))
            )
          )
      )
    );
  });

  refreshTickethistoryList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(tickethistoryActions.refreshTickethistoryList),
      switchMap(action => {
        return this._ticketHistoryService
          .getTicketHistory({
            type: 'approved'
          })
          .pipe(
            switchMap(data => [
              tickethistoryActions.refreshTickethistoryListSuccess({
                ...data
              }),
              buyBackActions.updateBuybackValue(ticketsToChanges(data.tickets))
            ]),
            catchError(error =>
              of(
                tickethistoryActions.refreshTickethistoryListFalilure({ error })
              )
            )
          );
      })
    );
  });

  loadTicketHistoryDetail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(tickethistoryActions.loadTicketDetail),
      switchMap(action =>
        this._ticketHistoryService.getTicketDetailNEW(action.ticketCode).pipe(
          map(ticket =>
            tickethistoryActions.loadTicketDetailSuccess({ ticket })
          ),
          catchError(error =>
            of(tickethistoryActions.loadTicketDetailFailure({ error }))
          )
        )
      )
    )
  );

  loadTicketEditHistory$ = createEffect(() =>
    this._actions$.pipe(
      ofType(tickethistoryActions.loadEditHistory),
      switchMap(action =>
        this._ticketHistoryService.getTicketEditHistory(action.ticketCode).pipe(
          map(_editHistory =>
            tickethistoryActions.loadEditHistorySuccess({
              ticketCode: action.ticketCode,
              editedTickets: _editHistory
            })
          ),
          catchError(error =>
            of(
              tickethistoryActions.loadEditHistoryFailure({
                ticketCode: action.ticketCode,
                error
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _ticketHistoryService: TickethistoryService
  ) {}
}
