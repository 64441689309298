import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {
  /**
   * Replaces string in given value
   */
  transform(
    value: string,
    strToReplace: string,
    replacementStr: string | number
  ): string {
    if (
      typeof value !== 'string' ||
      typeof strToReplace !== 'string' ||
      typeof replacementStr.toString() !== 'string'
    ) {
      return value;
    }

    return value.split(strToReplace).join(replacementStr.toString());
  }
}
