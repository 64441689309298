import { Maintenance } from '@models/Maintenance';
import { createReducer, on } from '@ngrx/store';
import * as MaintenanceActions from './maintenance.actions';

export type State = {
  maintenance: Maintenance;
  isAppBlocked: boolean;
};

export const initialState: State = {
  maintenance: undefined,
  isAppBlocked: false
};

export const reducer = createReducer(
  initialState,
  on(
    MaintenanceActions.initMaintenance,
    MaintenanceActions.loadMaintenanceSuccess,
    (state, action) => ({
      ...state,
      maintenance: {
        ...state.maintenance,
        ...action.maintenance
      }
    })
  ),
  on(MaintenanceActions.blockApp, (state, action) => ({
    ...state,
    isAppBlocked: true
  }))
);
