import { createReducer, on } from '@ngrx/store';
import { Analysis, AnalysisWithOdds } from '@models/Analysis';
import { analysesActions } from '@store/actions';

export const analysesFeatureKey = 'analyses';

// Count of analysis to fetch in one request
const ANALYSIS_PER_PAGE = 6;

export interface State {
  actualAnalyses: AnalysisWithOdds[];
  analysesHistory: {
    loading: boolean;
    analyses: Analysis[];
  };
  analysisDetail: Analysis;
  analysesHistoryCursor: {
    page: number;
    itemsPerPage: number;
    date: string; // Date in format MM-DD-YYYY
  };
}

export const initialState: State = {
  actualAnalyses: [],
  analysesHistory: {
    loading: false,
    analyses: []
  },
  analysisDetail: null,
  analysesHistoryCursor: {
    page: 0,
    itemsPerPage: ANALYSIS_PER_PAGE,
    date: ''
  }
};

export const reducer = createReducer(
  initialState,

  on(analysesActions.loadActualAnalyses, state => state),
  on(analysesActions.loadActualAnalysesSuccess, (state, action) => ({
    ...state,
    actualAnalyses: action.analyses
  })),
  on(analysesActions.setAnalysisHistoryDateFilter, (state, action) => ({
    ...state,
    analysesHistoryCursor: {
      ...state.analysesHistoryCursor,
      date: action.date,
      page: 0
    },
    analysesHistory: {
      loading: true,
      analyses: []
    }
  })),
  on(analysesActions.nextAnalysisHistoryLoaded, (state, action) => ({
    ...state,
    analysesHistory: {
      loading: false,
      analyses: [...state.analysesHistory.analyses, ...action.analyses]
    },
    analysesHistoryCursor: {
      ...state.analysesHistoryCursor,
      page:
        action.analyses.length > 0
          ? state.analysesHistoryCursor.page +
            state.analysesHistoryCursor.itemsPerPage
          : state.analysesHistoryCursor.page
    }
  })),
  on(analysesActions.loadAnalysisDetail, (state, action) => ({
    ...state,
    analysisDetail:
      state.actualAnalyses.find(
        analyse => analyse.EventID === +action.analysisID
      ) ||
      state.analysesHistory.analyses.find(
        analyse => analyse.EventID === +action.analysisID
      ) ||
      null
  })),
  on(analysesActions.loadAnalysisDetailSuccess, (state, action) => ({
    ...state,
    analysisDetail: action.analysis
  }))
);
