import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@core/functions/formatNumber';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {
  constructor() {}

  /**
   * Transforms number to string with currency, you can use additional properties if you want
   * default value for currency is currency set in evnironment
   * default number of decimalDigits is 2
   */
  transform(
    value: number | string,
    props?: { decimalDigits?: number }
  ): string {
    return formatNumber(value, props);
  }
}
