import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { landingActions } from '@store/actions';
import {
  concatMap,
  withLatestFrom,
  map,
  catchError,
  distinctUntilChanged
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { landingSelectors } from '@store/selectors';
import { OfferService } from '@core/services/offer/offer.service';
import { of } from 'rxjs';

@Injectable()
export class LandingTopBettingEffects {
  loadTopBetting = createEffect(() => {
    return this._actions$.pipe(
      ofType(landingActions.loadTopBetting),
      withLatestFrom(
        this._store.select(landingSelectors.selectTopBettingFilter)
      ),
      map(([action, filter]) => ({ action, filter })),
      concatMap(({ filter }) =>
        this._offerService
          .feetchTopBetting({
            date: filter.date,
            count: filter.count
          })
          .pipe(
            map(data => landingActions.loadTopBettingSuccess({ ...data })),
            catchError(() => of(landingActions.loadTopBettingFailure()))
          )
      )
    );
  });

  /**
   * This effect just dispatch action to load new top betting data
   */
  updateFilter$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(landingActions.setTopBettingFilter),
      map(() => landingActions.loadTopBetting())
    );
  });

  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _offerService: OfferService
  ) {}
}
