import { Pipe, PipeTransform } from '@angular/core';
import { isAllowed, ScreenAllowed, AppScreens } from '@core/functions/isAlowed';
import { Settings } from '@models/Settings';
import { User } from '@models/User';

@Pipe({
  name: 'isScreenOnlyForLogged'
})
export class IsScreenOnlyForLoggedPipe implements PipeTransform {
  /**
   * chcek if screen is allowed for logged user
   *
   * @param value screen name
   * @param userSettings user settings object
   * @returns true if screen is allowed
   */
  transform(value: AppScreens, userSettings: Settings): boolean {
    const screen = isAllowed(value);
    if (!!userSettings) {
      if (
        value === 'BetslipHistoryList' &&
        typeof userSettings?.setting?.showBettingHistory?.value === 'boolean'
      ) {
        return userSettings.setting.showBettingHistory.value === true;
      }
      if (
        value === 'CasinoSpinHistoryList' &&
        typeof userSettings?.setting?.showCasinoHistory?.value === 'boolean'
      ) {
        return userSettings.setting.showCasinoHistory.value === true;
      }
      if (screen === ScreenAllowed.onlyForUnlogged) {
        return false;
      }
      if (screen === ScreenAllowed.onlyForLogged) {
        return true;
      }
      if (screen === ScreenAllowed.allowed) {
        return true;
      }
      if (screen === ScreenAllowed.disabled) {
        return true;
      }
    } else {
      if (screen === ScreenAllowed.onlyForUnlogged) {
        return true;
      }
      if (screen === ScreenAllowed.onlyForLogged) {
        return false;
      }
      if (screen === ScreenAllowed.allowed) {
        return true;
      }
      if (screen === ScreenAllowed.disabled) {
        return true;
      }
    }
  }
}
