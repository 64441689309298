import { Pipe, PipeTransform } from '@angular/core';
import { EventChanceType } from '@models/EventChanceType';
import { Store } from '@ngrx/store';
import { favoritesSelectors } from '@store/selectors';
import { Observable } from 'rxjs';

@Pipe({
  name: 'isFavChanceType'
})
export class IsFavChanceTypePipe implements PipeTransform {
  constructor(private _store: Store) {}

  /** return observable of boolean indicates chance is favorite */
  transform(echt: EventChanceType, sportId: number): Observable<boolean> {
    return this._store.select(
      favoritesSelectors.selectIsChanceTypeInFav({
        chanceTypeId: echt.ChanceTypeID,
        sportId,
        liveBetting: echt.LiveBetting === 'Y' ? true : false
      })
    );
  }
}
