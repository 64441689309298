import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TicketState } from '../store/ticket.reducer';
import * as ticketSelectors from '../store/ticket.selectors';
import * as ticketActions from '../store/ticket.actions';
import { TranslateService } from '@ngx-translate/core';
import { PresentMessageService } from 'src/app/services/present-message.service';
import { TranslateSpecialPipe } from '@shared/pipes/translate.pipe';
import { Ticket, TicketOddsCombi } from '@models/Ticket';

@Component({
  selector: 'doxx-combinationsdetail',
  templateUrl: './combinationsdetail.component.html',
  styleUrls: ['./combinationsdetail.component.scss']
})
export class CombinationsdetailComponent implements OnInit, OnDestroy {
  private _subscriptions$ = new Subscription();

  constructor(
    private _modalController: ModalController,
    private _store: Store<TicketState>,
    private _translateService: TranslateService,
    private _presentMessageService: PresentMessageService,
    private _translateSpecial: TranslateSpecialPipe
  ) {}

  hasPinnedCombination = false;
  combinations: TicketOddsCombi[] = [];
  overallTotalBet: Ticket['OverallTotalBet'];
  overallPotWin: Ticket['OverallPotWin'];

  /**
   * on init
   */
  ngOnInit(): void {
    this._subscriptions$.add(
      this._store.select(ticketSelectors.selectTicket).subscribe(ticket => {
        this.combinations = ticket.OddsCombi;
        this.hasPinnedCombination = ticket.OddsCombi.some(
          combi => combi.Pinned
        );
        this.overallPotWin = ticket.FiscalOverallPotWin;
        this.overallTotalBet = ticket.OverallTotalBet;
      })
    );
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this._subscriptions$.unsubscribe();
  }

  /**
   * Dismiss modal
   */
  async closeCombinationsDetail(): Promise<any> {
    await this._modalController.dismiss();
  }

  /**
   * Change spell value (one combination bet)
   */
  combiBetChange(
    event: Event,
    combination: TicketOddsCombi,
    spell: string
  ): void {
    const value = (event.target as HTMLInputElement).value;
    if (
      value !== null &&
      value.toString() !== combination.Bet &&
      !isNaN(parseFloat(value))
    ) {
      combination.Bet = value;
      this._store.dispatch(ticketActions.setSpellStake({ bet: value, spell }));
    }
  }

  /**
   * Change value inside combination bet
   */
  overallBetChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    if (
      value !== null &&
      value.toString() !== this.overallTotalBet &&
      !isNaN(parseFloat(value))
    ) {
      this.overallTotalBet = value; // optimistics response
      this._store.dispatch(
        ticketActions.setCombinationStake({
          bet: value,
          combiID: 'all',
          isPartial: false
        })
      );
    }
  }

  /**
   * unpin all combinations
   */
  unpinAll(): void {
    this._store.dispatch(
      ticketActions.unpinCombination({ combi: 'all', spell: 'all' })
    );
  }

  /**
   * unpin single comvination
   *
   * @param combination Combination
   */
  unpinSingle(combination: TicketOddsCombi): void {
    this._store.dispatch(
      ticketActions.unpinCombination({ combi: 'all', spell: combination.ID })
    );
  }

  /**
   *  show tooltip
   */
  showSrtTooltyp(): void {
    this._presentMessageService.presentCustomMessage(
      `<p>${
        (this._translateSpecial.transform('srt_hint_p3') as any)
          .changingThisBreaksApplicationSecurity
      }</p>
         <p>${
           (this._translateSpecial.transform('srt_hint_p2') as any)
             .changingThisBreaksApplicationSecurity
         }</p>
         <p>${
           (this._translateSpecial.transform('srt_hint_p1') as any)
             .changingThisBreaksApplicationSecurity
         }</p>
        `,
      'info-alert',
      this._translateService.instant('srt_hint_header'),
      ['OK']
    );
  }
}
