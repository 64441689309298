import { environment } from '@environment';
import slug from 'slugify';

const slugifyConfig = {
  replacement: '-', // replace spaces with replacement character
  remove: /[/(/).,]/gm, // remove characters that match regex
  lower: true // convert to lower case
  // strict: false // strip special characters except replacement
  // locale: environment.language // language code of the locale to use
};

// extra config for special characters:
slug.extend({ ĺ: 'l' });

/**
 * Slugify any string, function wraps function from  package https://www.npmjs.com/package/slugify
 * with defaul config for our application
 */
export function slugify(str: string): string {
  if (typeof str !== 'string') {
    if (!environment.production) {
      console.warn('String type expected. Get:' + JSON.stringify(str, null, 2));
    }
    return null;
  }
  return slug(str, slugifyConfig);
}
