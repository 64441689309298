import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';
import { map, pairwise, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'doxx-box-input',
  templateUrl: './box-input.component.html',
  styleUrls: ['./box-input.component.scss']
})
export class BoxInputComponent implements OnInit, AfterViewInit, OnDestroy {
  private _subscription$: Subscription;

  @Input() boxes = 4;
  @Input() pattern = '';
  @Input() inputmode = 'numeric';
  @Input() autocomplete = 'off';
  @ViewChildren('codeNumberInput') _inputElements: QueryList<
    ElementRef<HTMLInputElement>
  >;

  @HostBinding('class.ng-touched') get isTouched(): boolean {
    return Object.values(this.codeForm.controls).reduce(
      (a, c) => a && c.touched,
      true
    );
  }

  @HostBinding('class.ng-invalid') get isInvalid(): boolean {
    return this.codeForm.invalid;
  }

  @HostBinding('class.ng-valid') get isValid(): boolean {
    return this.codeForm.valid;
  }

  @HostBinding('class.ng-dirty') get isDirty(): boolean {
    return this.codeForm.dirty;
  }

  constructor() {}

  codeForm: FormGroup;

  /** Set value for boxes */
  setValue(value: string): void {
    Object.values(this.codeForm.controls).forEach((control, index) => {
      control.setValue(value[index], { emitEvent: false });
    });
  }

  /** Set value for boxes with emit for android browers */
  setValueWithEmit(value: string): void {
    Object.values(this.codeForm.controls).forEach((control, index) => {
      control.setValue(value[index], { emitEvent: true });
    });
  }

  /** Create form controls */
  ngOnInit(): void {
    this._subscription$ = new Subscription();

    const controls = {};
    for (let index = 0; index < this.boxes; index++) {
      controls[index] = new FormControl('', Validators.required);
    }
    this.codeForm = new FormGroup(controls);
  }

  /** Bind event subscriptions on elements */
  ngAfterViewInit(): void {
    for (const key in this.codeForm.controls) {
      if (this.codeForm.controls.hasOwnProperty(key)) {
        const control = this.codeForm.controls[key];
        const element = this._inputElements.find(
          input => input.nativeElement.id === key
        ).nativeElement;

        this._subscription$.add(
          fromEvent(element, 'paste').subscribe((event: any) => {
            event.preventDefault();
            const pasteValue = (
              event.clipboardData || (window as any).clipboardData
            )
              .getData('text')
              .replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g, '');

            const valuesArray = [...pasteValue].slice(0, this.boxes - +key);

            valuesArray.forEach((value, index) => {
              this.codeForm.controls[+key + index].setValue(value);
            });
          })
        );

        this._subscription$.add(
          control.valueChanges
            .pipe(
              startWith(''),
              map(value => (value === null ? ' ' : value)),
              pairwise()
            )
            .subscribe(([prew, current]) => {
              const value = current.replace(
                new RegExp(`^${prew.slice(-1) || ''}`),
                ''
              );
              if (value.length > 2) {
                const valuesArray = [...value].slice(0, this.boxes - +key);

                valuesArray.forEach((val, index) => {
                  this.codeForm.controls[+key + index].setValue(val, {
                    emitEvent: false
                  });
                });
              } else {
                control.setValue(value.slice(-1), { emitEvent: false });
                const thisInput = this._inputElements.find(
                  input => input.nativeElement.id === key
                ).nativeElement;

                if (value.length >= 1) {
                  const nextInput = this._inputElements.toArray()[+key + 1];
                  if (nextInput && value.trim().length >= 1) {
                    thisInput.blur();
                    nextInput.nativeElement.focus();
                  }
                } else {
                  const prewInput = this._inputElements.toArray()[+key - 1];
                  if (prewInput) {
                    thisInput.blur();
                    prewInput.nativeElement.value =
                      prewInput.nativeElement.value || ' ';
                    prewInput.nativeElement.focus();
                  }
                }
              }
            })
        );
      }
    }
  }

  /** Clear all subscriptions */
  ngOnDestroy(): void {
    this._subscription$.unsubscribe();
  }

  get valid(): boolean {
    return this.codeForm?.valid || false;
  }

  get codeValue(): string {
    return Object.values(this.codeForm.controls)
      .reduce((a, c) => (a = [...a, c.value ? c.value.slice(-1) : '']), [])
      .join('');
  }
}
