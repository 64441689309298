import { NgModule } from '@angular/core';
import { StoreModule, Store } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AnalysesEffects } from './analyses.effects';
import * as fromAnalyses from './analyses.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(
      fromAnalyses.analysesFeatureKey,
      fromAnalyses.reducer
    ),
    EffectsModule.forFeature([AnalysesEffects])
  ]
})
export class AnalysesStoreModule {}
