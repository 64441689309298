/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable jsdoc/require-jsdoc */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

/**
 * A simple lightweight library for Angular with that detects when an
 * element is within the browsers viewport and adds a `in-viewport` or
 * `not-in-viewport` class to the element.
 *
 * @example
 * ```html
 * <p
 *  class="foo"
 *  doxxInViewport
 *  (inViewportChange)="myEventHandler($event)">
 *  Amet tempor excepteur occaecat nulla.
 * </p>
 * ```
 */
// @dynamic
@Directive({
  selector: '[doxxInViewport]',
  exportAs: 'doxxInViewport'
})
export class InViewportDirective implements AfterViewInit, OnDestroy, OnInit {
  private inViewport: boolean;
  private hasIntersectionObserver: boolean;
  @Input()
  public inViewportOptions: IntersectionObserverInit;
  @Output()
  public inViewportChange = new EventEmitter<boolean>();
  public observer: IntersectionObserver;

  @HostBinding('class.xx-viewport--in')
  get isInViewport(): boolean {
    return this.inViewport;
  }

  @HostBinding('class.xx-viewport--out')
  get isNotInViewport(): boolean {
    return !this.inViewport;
  }

  constructor(private el: ElementRef) {
    this.hasIntersectionObserver = this.intersectionObserverFeatureDetection();
  }

  ngOnInit() {
    if (!this.hasIntersectionObserver) {
      this.inViewport = true;
      this.inViewportChange.emit(this.inViewport);
    }
  }

  ngAfterViewInit() {
    if (this.hasIntersectionObserver) {
      const IntersectionObserver = window.IntersectionObserver;
      this.observer = new IntersectionObserver(
        this.intersectionObserverCallback.bind(this),
        this.inViewportOptions
      );

      this.observer.observe(this.el.nativeElement);
    }
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.unobserve(this.el.nativeElement);
    }
  }

  intersectionObserverCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach(entry => {
      if (this.inViewport === entry.isIntersecting) {
        return;
      }
      this.inViewport = entry.isIntersecting;
      this.inViewportChange.emit(this.inViewport);
    });
  }

  private intersectionObserverFeatureDetection() {
    // Exits early if all IntersectionObserver and IntersectionObserverEntry
    // features are natively supported.
    if (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window
    ) {
      // Minimal polyfill for Edge 15's lack of `isIntersecting`
      // See: https://github.com/w3c/IntersectionObserver/issues/211
      if (
        !('isIntersecting' in window.IntersectionObserverEntry['prototype'])
      ) {
        Object.defineProperty(
          window.IntersectionObserverEntry['prototype'],
          'isIntersecting',
          {
            get() {
              return this.intersectionRatio > 0;
            }
          }
        );
      }
      return true;
    }
    return false;
  }
}
