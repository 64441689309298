import { FetchEventDetailOptions } from '@core/services/types';
import { createAction, props } from '@ngrx/store';
import {
  SignalrOddChangePayload,
  SignalrEventChanceTypeChangePayload
} from 'src/app/services/signalr.service';
import { Event } from '@models/Event';

/**
 * LOAD EVENT DETAIL
 */

export const loadEventDetailStart = createAction(
  '[Detail] Load event detail start',
  props<{ options: FetchEventDetailOptions }>()
);

export const loadEventDetailSuccess = createAction(
  '[Detail] Load event detail success',
  props<{ eventDetailData: any }>()
);

export const loadEventDetailFailure = createAction(
  '[Detail] Load event detail failure',
  props<{ error: any }>()
);

/**
 * REFRESH EVENT DETAIL
 */

export const refreshEventDetailStart = createAction(
  '[Detail] Refresh event detail start',
  props<{ payload: FetchEventDetailOptions }>()
);

export const refreshEventDetailSuccess = createAction(
  '[Detail] Refresh event detail success',
  props<{ eventDetailData: any }>()
);

export const refreshEventDetailFailure = createAction(
  '[Detail] Refresh event detail failure',
  props<{ error: any }>()
);

export const updateOdds = createAction(
  '[Detail] Update Odds',
  (changes: SignalrOddChangePayload[]) => ({
    payload: changes.map(change => ({
      id: change.oddID,
      changes: {
        OddsRate: change.oddRate,
        Status: change.oddStatus
      }
    }))
  })
);

export const changeChanceTypeGroup = createAction(
  '[Detail] Change chancetype group',
  props<{ ChanceTypeGroupID: string }>()
);

export const updateEvent = createAction(
  '[Detail] Update Event',
  props<{ event: Partial<Event> }>()
);

export const updateEventChanceTypes = createAction(
  '[Detail] Update Event Chance Type',
  (changes: SignalrEventChanceTypeChangePayload[]) => ({
    payload: changes.map(change => ({
      id: change.EventChanceTypeID,
      changes: {
        EventChanceTypeStatus: change.EventChanceTypeStatus
      }
    }))
  })
);
