import { Pipe, PipeTransform } from '@angular/core';
import { Odd } from '@models/Odd';
import { EventChanceType } from '@models/EventChanceType';
import { Event } from '@models/Event';
import { TopEvent } from '@models/TopEvent';

@Pipe({
  name: 'isOddLocked',
  pure: false
})
export class IsOddLockedPipe implements PipeTransform {
  /**
   * Checks if odd is locked
   * @param odd required
   * @param eventChanceType optional
   * @param event optional
   */
  transform(
    odd: Odd,
    eventChanceType: EventChanceType | Event | TopEvent,
    event: EventChanceType | Event | TopEvent
  ): boolean {
    let isLocked = false;
    if (typeof odd.OddsWinning === 'string') {
      return false;
    }

    if (odd) {
      isLocked = odd.OddsRate < 1.01 || odd.Status !== 'active';
    }
    if (eventChanceType) {
      isLocked = isLocked || eventChanceType.EventChanceTypeStatus !== 'active';
    }
    if (event) {
      isLocked = isLocked || event.LiveBettingView === -1;
    }

    return isLocked;
  }
}
