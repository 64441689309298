import { createReducer, on } from '@ngrx/store';
import { landingActions } from '@store/actions';
import { Winner, CasinoWinner } from '@models/Winners';

export interface State {
  ticketWins: {
    loading: boolean;
    tickets: Winner;
    error: any;
  };
  casinoWins: {
    loading: boolean;
    wins: CasinoWinner;
    error: any;
  };
}

const initialWinnersState: State = {
  ticketWins: {
    loading: false,
    tickets: null,
    error: null
  },
  casinoWins: {
    loading: false,
    wins: null,
    error: null
  }
};

export const winnersReducer = createReducer(
  initialWinnersState,
  on(landingActions.loadWinners, (state, action) => ({
    ...state,
    ticketWins: {
      ...state.ticketWins,
      loading: true,
      error: undefined
    }
  })),
  on(landingActions.loadWinnersSuccess, (state, action) => ({
    ...state,
    ticketWins: {
      ...state.ticketWins,
      loading: false,
      error: null,
      tickets: action.tickets
    }
  })),
  on(landingActions.loadWinnersFailure, (state, action) => ({
    ...state,
    ticketWins: {
      ...state.ticketWins,
      loading: false,
      error: action.error
    }
  })),
  // MY CASINO WINS
  on(landingActions.loadCasinoWinners, (state, action) => ({
    ...state,
    casinoWins: {
      ...state.casinoWins,
      loading: true,
      error: undefined
    }
  })),
  on(landingActions.loadCasinoWinnersSuccess, (state, action) => ({
    ...state,
    casinoWins: {
      ...state.casinoWins,
      loading: false,
      error: null,
      wins: action.wins
    }
  })),
  on(landingActions.loadCasinoWinnersFailure, (state, action) => ({
    ...state,
    casinoWins: {
      ...state.casinoWins,
      loading: false,
      error: action.error
    }
  }))
);
