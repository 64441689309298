import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BuyBackEffects } from './buyback.effects';
import { BUYBACK_STATE_KEY, reducer } from './buyback.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(BUYBACK_STATE_KEY, reducer),
    EffectsModule.forFeature([BuyBackEffects])
  ]
})
export class BuybackStoreModule {}
