import { Inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CaptchaComponent } from './captcha/captcha.component';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environment';

@Injectable()
export class CaptchaService {
  constructor(
    private _modalCtrl: ModalController,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  /** show captcha dialog */
  showCaptcha(): Observable<any> {
    return new Observable(subscriber => {
      this._modalCtrl
        .create({
          component: CaptchaComponent,
          backdropDismiss: false,
          keyboardClose: false,
          cssClass: 'modal-fullscreen'
        })
        .then(m => {
          m.present();
          m.onWillDismiss().then(data => {
            subscriber.next(data);
            subscriber.complete();
            if (environment.production) {
              this._document.location.reload();
            }
          });
        });
    });
  }
}
