import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { FiltermodalComponent } from './filtermodal.component';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { MaterialDesignModule } from '@shared/material-design/material-design.module';

@NgModule({
  imports: [CommonModule, IonicModule, DoxxPipesModule, MaterialDesignModule],
  exports: [FiltermodalComponent],
  declarations: [FiltermodalComponent]
})
export class FiltermodalModule {}
