import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLanding from '../landing.reducer';
import * as fromAnnouncement from './landing-announcement.reducer';

const selectLandingState = createFeatureSelector<fromLanding.State>(
  fromLanding.LANDING_FEATURE_KEY
);

const selectAnnouncementState = createSelector(
  selectLandingState,
  (state: fromLanding.State) => state.announcement
);

export const selectAnnouncement = createSelector(
  selectAnnouncementState,
  (state: fromAnnouncement.State) => state.announcement
);
