import { createFeatureSelector } from '@ngrx/store';
import * as fromLanding from './landing.reducer';

export * from './landing-top-betting/landing-top-betting.selectors';
export * from './landing-virtual-games/landing-virtual-games.selectors';
export * from './landing-banner/landing-banner.selectors';
export * from './landing-top-leagues/landing-top-leagues.selectors';
export * from './landing-my-account/landing-my-account.selectors';
export * from './landing-winners/landing-winners.selectors';
export * from './landing-announcement/landing-announcement.selectors';

export const selectLandingState = createFeatureSelector<fromLanding.State>(
  fromLanding.LANDING_FEATURE_KEY
);
