import { createAction, props } from '@ngrx/store';
import { TopBettingDateFilter } from './landing-top-betting.reducer';
import { TopEvent } from '@models/TopEvent';
import { SignalrOddChangePayload } from 'src/app/services/signalr.service';
import { Odd } from '@models/Odd';

export const loadTopBetting = createAction('[Landing] Load Top Betting');
export const loadTopBettingSuccess = createAction(
  '[Landing] Load Top Betting Success',
  props<{
    topRateSum: number;
    events: TopEvent[];
    odds: Odd[];
  }>()
);
export const loadTopBettingFailure = createAction(
  '[Landing] Load Top Betting Fail'
);

export const setTopBettingFilter = createAction(
  '[Landing] Set Top Betting Filter',
  props<{ filter: { date?: TopBettingDateFilter; count?: number } }>()
);

/**
 * UPDATE ODDS
 */
export const updateOdds = createAction(
  '[Landing] Update Odds',
  (changes: SignalrOddChangePayload[]) => ({
    payload: changes.map(change => ({
      id: change.oddID,
      changes: {
        OddsRate: change.oddRate,
        Status: change.oddStatus
      }
    }))
  })
);

export const updateEvents = createAction(
  '[Landing] Update Events',
  (changes: Partial<TopEvent>[]) => ({
    payload: changes.map(change => ({
      id: change.EventID,
      changes: {
        ...change
      }
    }))
  })
);
