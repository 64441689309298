import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { oneLine } from '@core/functions/oneLine';
import { environment } from '@environment';
import { Maintenance } from '@models/Maintenance';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { maintenanceActions } from '@store/actions';
import { maintenanceSelectors } from '@store/selectors';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, first, map, skip } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  constructor(
    private _httpClient: HttpClient,
    private _router: Router,
    private _store: Store,
    private _actions: Actions
  ) {
    this._actions
      .pipe(
        ofType(maintenanceActions.initMaintenance, maintenanceActions.blockApp),
        first()
      )
      .subscribe(() => {
        combineLatest([
          this._store.select(maintenanceSelectors.selectIsBettingBlocked),
          this._store.select(maintenanceSelectors.selectIsCasinoBlocked),
          this._store.select(maintenanceSelectors.selectIsDepositBlocked),
          this._store.select(maintenanceSelectors.selectIsWithdrawalBlocked)
        ])
          .pipe(skip(1), distinctUntilChanged())
          .subscribe(state => {
            this._router.navigate([], { skipLocationChange: true });
          });
      });
  }

  /** Get blocking information for maintanance */
  getBlockingInformation(): Observable<Maintenance> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/web/system/getBlockingInformations`,
        {
          withCredentials: true
        }
      )
      .pipe(map(data => data.Result));
  }
}
