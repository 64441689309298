import { environment } from '@environment';
import { PARAMS_MATCHER } from './resolveParams';
import { INTERN_PARAM_PREFIX, ScreenConfig } from './screenConfig';

const offerResolvers = {
  regionID: ({ prewParameters }) =>
    prewParameters.get('sportID')?.childrens || [],
  leagueID: ({ prewParameters }) =>
    prewParameters.get('regionID')?.childrens || [],
  eventID: ({ screen }) => screen.parametersAlias,
  eventName: () => []
};

export const NAVIGATION = {
  Landing: new ScreenConfig(),
  NewsList: new ScreenConfig(),
  NewsDetail: new ScreenConfig([['newsID', PARAMS_MATCHER.FLAT]]),
  AvailableBonusesList: new ScreenConfig(),
  BonusesList: new ScreenConfig(),
  BonusesListCasino: new ScreenConfig(),
  BonusDetail: new ScreenConfig([
    ['bonusID', PARAMS_MATCHER.FLAT],
    [
      'tabIndex',
      ({ prewParameters, screen }) => [
        ...(prewParameters.get('bonusID')?.Children || []),
        ...screen.parametersAlias
      ]
    ]
  ]),
  BonusDetailCasino: new ScreenConfig([
    ['bonusID', PARAMS_MATCHER.FLAT],
    [
      'tabIndex',
      ({ prewParameters, screen }) => [
        ...(prewParameters.get('bonusID')?.Children || []),
        ...screen.parametersAlias
      ]
    ]
  ]),
  AnalysisList: new ScreenConfig(),
  AnalysisDetail: new ScreenConfig([['analysisID', PARAMS_MATCHER.FLAT]]),
  DocumentsList: new ScreenConfig(),
  DocumentDetail: new ScreenConfig([['documentID', PARAMS_MATCHER.FLAT]]),
  LiveList: new ScreenConfig([
    [
      'sportID',
      ({ screen, navigation }) => [
        screen.parametersAlias,
        navigation.EventDetail_Live.parametersAlias,
        navigation.PrematchList.parametersAlias
      ]
    ],
    ['regionID', offerResolvers.regionID],
    ['leagueID', offerResolvers.leagueID]
  ]),
  PrematchList: new ScreenConfig([
    [
      'sportID',
      ({ screen, navigation }) => [
        screen.parametersAlias,
        navigation.EventDetail.parametersAlias
      ]
    ],
    ['regionID', offerResolvers.regionID],
    ['leagueID', offerResolvers.leagueID]
  ]),
  ResultsList: new ScreenConfig([
    [
      'sportID',
      ({ screen, navigation }) => [
        screen.parametersAlias,
        navigation.EventDetail_Results.parametersAlias,
        navigation.PrematchList.parametersAlias
      ]
    ],
    ['regionID', offerResolvers.regionID],
    ['leagueID', offerResolvers.leagueID]
  ]),
  VirtualGames: new ScreenConfig([
    [
      'regionID',
      ({ screen }) =>
        screen.parametersAlias.find(
          pa =>
            pa.Value.toString() ===
            environment.virtualGames.vgSportID.toString()
        )?.childrens
    ],
    ['leagueID', offerResolvers.leagueID]
  ]),
  FavoritesList: new ScreenConfig(),
  EventDetail: new ScreenConfig([
    [
      'sportID',
      ({ screen, navigation }) => [
        screen.parametersAlias,
        navigation.PrematchList.parametersAlias
      ]
    ],
    ['regionID', offerResolvers.regionID],
    ['leagueID', offerResolvers.leagueID],
    ['eventID', offerResolvers.eventID],
    ['eventName', offerResolvers.eventName]
  ]),
  EventDetail_Live: new ScreenConfig([
    [
      'sportID',
      ({ screen, navigation }) => [
        screen.parametersAlias,
        navigation.LiveList.parametersAlias,
        navigation.PrematchList.parametersAlias
      ]
    ],
    ['regionID', offerResolvers.regionID],
    ['leagueID', offerResolvers.leagueID],
    ['eventID', offerResolvers.eventID],
    ['eventName', offerResolvers.eventName]
  ]),
  EventDetail_Results: new ScreenConfig([
    [
      'sportID',
      ({ screen, navigation }) => [
        screen.parametersAlias,
        navigation.ResultsList.parametersAlias,
        navigation.PrematchList.parametersAlias
      ]
    ],
    ['regionID', offerResolvers.regionID],
    ['leagueID', offerResolvers.leagueID],
    ['eventID', offerResolvers.eventID],
    ['eventName', offerResolvers.eventName]
  ]),
  EventDetail_VirtualGames: new ScreenConfig([
    ['sportID', PARAMS_MATCHER.EMPTY],
    ['leagueID', PARAMS_MATCHER.EMPTY],
    ['eventID', PARAMS_MATCHER.FLAT],
    ['eventName', PARAMS_MATCHER.EMPTY]
  ]),
  BetslipScanner: new ScreenConfig([
    [`${INTERN_PARAM_PREFIX}subpage`, PARAMS_MATCHER.EMPTY],
    [`${INTERN_PARAM_PREFIX}guid`, PARAMS_MATCHER.EMPTY]
  ]),
  BetslipHistoryList: new ScreenConfig(),
  BetslipHistoryDetail: new ScreenConfig([['ticketID', PARAMS_MATCHER.FLAT]]),
  SavedBetslipsList: new ScreenConfig(),
  SavedBetslipDetail: new ScreenConfig([['ticketID', PARAMS_MATCHER.FLAT]]),
  SharedBetslipDetail: new ScreenConfig([['ticketID', PARAMS_MATCHER.FLAT]]),
  Login: new ScreenConfig(),
  ResetPassword: new ScreenConfig([
    [`${INTERN_PARAM_PREFIX}subpage`, PARAMS_MATCHER.EMPTY]
  ]),
  Register: new ScreenConfig([
    [`${INTERN_PARAM_PREFIX}subpage`, PARAMS_MATCHER.EMPTY]
  ]),
  RegisterPersonalInfo: new ScreenConfig(),
  RegisterEmailActivation: new ScreenConfig([['token', PARAMS_MATCHER.FLAT]]),
  Profile: new ScreenConfig([
    [`${INTERN_PARAM_PREFIX}subpage`, PARAMS_MATCHER.EMPTY]
  ]),
  PersonalInformation: new ScreenConfig([
    [`${INTERN_PARAM_PREFIX}subpage`, PARAMS_MATCHER.EMPTY]
  ]),
  Settings: new ScreenConfig(),
  Settings_ChangePassword: new ScreenConfig([
    [`${INTERN_PARAM_PREFIX}subpage`, PARAMS_MATCHER.EMPTY]
  ]),
  Settings_Language: new ScreenConfig(),
  Settings_Notifications: new ScreenConfig(),
  VerificationsList: new ScreenConfig(),
  VerificationAccount: new ScreenConfig(),
  VerificationBankAccount: new ScreenConfig(),
  VerificationPhoneAccount: new ScreenConfig(),
  VerificationCreditCard: new ScreenConfig(),
  ResponsibleGamingBetting: new ScreenConfig(),
  ResponsibleGamingCasino: new ScreenConfig(),
  DepositWithdrawal_Deposit: new ScreenConfig(),
  DepositWithdrawal_Withdrawal: new ScreenConfig(),
  DepositWithdrawal_BankAccount: new ScreenConfig(),
  DepositWithdrawal_Card: new ScreenConfig(),
  TransactionHistory: new ScreenConfig(),
  CasinoHome: new ScreenConfig([['categoryID', PARAMS_MATCHER.FLAT]]),
  CasinoFavorites: new ScreenConfig(),
  RioHome: new ScreenConfig([['categoryID', PARAMS_MATCHER.FLAT]]),
  CasinoSpinHistoryList: new ScreenConfig(),
  WinnerOfDayDetail: new ScreenConfig([['ticketID', PARAMS_MATCHER.FLAT]]),
  WinnersOfDayHistoryList: new ScreenConfig(),
  Maintenance: new ScreenConfig([['type', PARAMS_MATCHER.EMPTY]]),
  Settings_Cookies: new ScreenConfig(),
  Quickbet: new ScreenConfig()
};

export type NavigationConfig = typeof NAVIGATION;

NAVIGATION.Maintenance.addRoute({
  IsDefault: true,
  Link: 'maintenance/{type}',
  Data: { section: 'total' },
  Screen: 'Maintenance',
  ParametersAlias: [
    {
      Link: 'casino',
      Value: 'casino',
      ParamName: 'type'
    },
    {
      Link: 'betting',
      Value: 'betting',
      ParamName: 'type'
    },
    {
      Link: '',
      Value: 'total',
      ParamName: 'type'
    }
  ]
});

(window as any).__navigation = NAVIGATION;
