import { configuration } from '@configuration';
import { environment } from '@environment';
export type formatOption = Intl.DateTimeFormatOptions & {
  postFormat?: (s: string) => string;
};

interface Formats {
  MMMMDD: formatOption;
  MMDD: formatOption;
  YYYYMMDD: formatOption;
  hhmm: formatOption;
  MMDDhhmm: formatOption;
  YYYYMMDDhhmm: formatOption;
  MMMDDhhmm: formatOption;
  YYYYMMDDhhmmss: formatOption;
  YYYYhhmm: formatOption;
}
const FORMATS: Formats = {
  MMMMDD: {
    month: 'long',
    day: '2-digit'
  },
  MMDD: {
    month: '2-digit',
    day: '2-digit'
  },
  YYYYMMDD: {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  },
  hhmm: {
    minute: '2-digit',
    hour: '2-digit'
  },
  MMDDhhmm: {
    month: '2-digit',
    day: '2-digit',
    minute: '2-digit',
    hour: '2-digit',
    postFormat: str => str.replace(',', '')
  },
  YYYYMMDDhhmm: {
    month: '2-digit',
    day: '2-digit',
    minute: '2-digit',
    hour: '2-digit',
    year: 'numeric'
  },
  MMMDDhhmm: {
    month: 'short',
    day: '2-digit',
    minute: '2-digit',
    hour: '2-digit',
    postFormat: str => str.replace(',', '')
  },
  YYYYMMDDhhmmss: {
    month: '2-digit',
    day: '2-digit',
    minute: '2-digit',
    hour: '2-digit',
    year: 'numeric',
    second: '2-digit'
  },
  YYYYhhmm: {
    minute: '2-digit',
    hour: '2-digit',
    year: 'numeric'
  }
};

export type dateFormat = keyof Formats;

/** format time based on intern keyword, and turn it into locale string */
export function formatDate(
  date: Date,
  format: dateFormat | formatOption
): string {
  const locale = configuration.base.languages.find(
    lang => lang.code === environment.language
  ).locale;

  const option: formatOption =
    typeof format === 'string' ? FORMATS[format] : format;

  let formatted = date.toLocaleString(locale, option);
  if (!!option.postFormat) {
    formatted = option.postFormat(formatted);
  }

  return formatted;
}
