import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromTicket from './ticket.reducer';
import { TicketEffects } from './ticket.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(fromTicket.TICKET_STATE_KEY, fromTicket.reducer),
    EffectsModule.forFeature([TicketEffects])
  ]
})
export class TicketStoreModule {}
