<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <doxx-main-header-back-button
        (click)="closeCombinationsDetail()"
        [disabled]="true"
      >
      </doxx-main-header-back-button>
    </ion-buttons>
    <ion-title
      ><h1>{{ 'combstable_title' | translate }}</h1></ion-title
    >
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <!-- Header Detailu kombinacii -->
    <ion-row class="header">
      <ion-col class="ion-text-left">
        {{ 'ticket_combi_group' | translate }}
      </ion-col>
      <ion-col class="ion-text-center" size="5">
        <div class="bet-table flex items-center">
          <i
            *ngIf="hasPinnedCombination"
            class="icon icon-ic_lock-unlocked-fill inline-block p-2"
            (click)="unpinAll()"
          ></i>
          <span class="bet-table-text ml-auto">
            {{ 'ticket_combi_detail_bet' | translate }}
            <i
              class="icon icon-ic_info-circle-filled p-2 pl-3"
              (click)="showSrtTooltyp()"
            ></i>
          </span>
        </div>
      </ion-col>
      <ion-col class="ion-text-center"> {{ 'app_rate' | translate }} </ion-col>
      <ion-col class="ion-text-right">
        {{ 'ticket_eventual_win_short' | translate }}
      </ion-col>
    </ion-row>
    <!-- End Header Detailu kombinacii -->
    <!-- Detail kombinacii obsah -->
    <ion-row
      *ngFor="let combination of combinations; trackBy: 'ID' | trackByProperty"
    >
      <ion-col class="ion-text-left">
        {{ combination.ID }}
      </ion-col>
      <ion-col class="ion-text-center" size="5">
        <div class="flex relative items-center">
          <i
            (click)="unpinSingle(combination)"
            *ngIf="combination.Pinned"
            class="icon icon-ic_lock p-2 inline-block absolute left-0 pl-3"
          ></i>
          <div class="bet text-primary rounded border flex px-4 py-2">
            <input
              #combiInput
              spyFocus
              #spyFocus="spyFocus"
              [attr.tabindex]="combination.Pinned ? -1 : 1"
              class="bg-transparent w-full text-right"
              [ngClass]="{ 'pointer-events-none': combination.Pinned }"
              inputmode="decimal"
              type="number"
              [placeholder]="combination.Bet"
              (focus)="combiInput.value = null"
              (blur)="combiBetChange($event, combination, combination.ID)"
              [ngModel]="spyFocus.focused ? undefined : combination.Bet"
            />
            <span
              [style.order]="
                ('base.defaultCurrency.symbolPosition' | conf) === 'start'
                  ? -1
                  : 1
              "
              >{{ 'base.defaultCurrency.symbol' | conf }}</span
            >
          </div>
        </div>
      </ion-col>
      <ion-col class="ion-text-center">
        {{ combination.Rate | rate }}
      </ion-col>
      <ion-col class="ion-text-right">
        {{ combination.PotWin | formatNumberWithCurrency }}
      </ion-col>
    </ion-row>
    <!-- End Detail kombinacii obsah -->
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-grid class="ion-padding-horizontal">
      <ion-row>
        <ion-col class="sum" size="3">
          {{ 'ticket_combs_sum' | translate }}
        </ion-col>
        <ion-col size="3">
          <ion-item class="bet">
            <input
              #overallInput
              spyFocus
              #spyFocus="spyFocus"
              inputmode="decimal"
              class="bg-transparent text-right w-full"
              type="number"
              [placeholder]="overallTotalBet"
              [ngModel]="spyFocus.focused ? null : overallTotalBet"
              (focus)="overallInput.value = null"
              (blur)="overallBetChange($event)"
            />
            <ion-label [slot]="'base.defaultCurrency.symbolPosition' | conf">{{
              'base.defaultCurrency.symbol' | conf
            }}</ion-label>
          </ion-item>
        </ion-col>
        <ion-col class="ion-text-right">
          {{ overallPotWin | formatNumberWithCurrency }}
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
