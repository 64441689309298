import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ion-select[interface=modal]'
})
export class IonSelectInterfaceModalDirective {
  @Input() interfaceOptions: {
    header: string;
    closeButton: string;
  };

  constructor(
    private _el: ElementRef<HTMLIonSelectElement>,
    private _modal: ModalController
  ) {}

  @HostListener('click', ['$event']) private async _onClick(
    event: PointerEvent
  ): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    const { OfferViewSettingsModalComponent } = await import(
      './ion-select-modal-interface-modal'
    );
    const modal = await this._modal.create({
      component: OfferViewSettingsModalComponent,
      cssClass: 'small-modal',
      componentProps: {
        header: this.interfaceOptions.header,
        closeButton: this.interfaceOptions.closeButton,
        value: this._el.nativeElement.value,
        options: Array.from(
          this._el.nativeElement.querySelectorAll('.ion-select-option-wrapper')
        ).map(el => ({
          value: el.querySelector('ion-select-option').value,
          html: el.outerHTML
        }))
      }
    });
    modal.present();
    await modal.onDidDismiss();
    const val = modal.getAttribute('value');
    if (val) {
      this._el.nativeElement.value = val;
    }
  }
}
