import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDocument from './document.reducer';

export const selectDocumentState = createFeatureSelector<fromDocument.State>(
  fromDocument.documentFeatureKey
);

export const selectDocuments = createSelector(selectDocumentState, state =>
  state.documents.sort((a, b) => b.RecordOrder - a.RecordOrder)
);

export const selectLoading = createSelector(
  selectDocumentState,
  state => state.loading
);

export const selectLoaded = createSelector(
  selectDocumentState,
  state => state.loaded
);
