import { NAVIGATION } from './navigation';

/** init navigation config */
export function initNavigation(config?: any[]): void {
  config
    ?.sortBy(nav => (nav.IsDefault ? -1 : 1))
    .forEach(screen => {
      NAVIGATION[screen.Screen as string] =
        NAVIGATION[screen.Screen]?.addRoute(screen);
    });
  (NAVIGATION as any).__initialized = true;
}
