import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { authSelectors } from '@store/selectors';
import { take } from 'rxjs/operators';

@Component({
  selector: 'doxx-event-list-item-skeleton',
  templateUrl: './event-list-item-skeleton.component.html',
  styleUrls: ['./event-list-item-skeleton.component.scss']
})
export class EventListItemSkeletonComponent implements OnInit {
  oddType: 'NUMBERING' | 'NAMING' = 'NAMING';
  constructor(private _store: Store) {}

  /** init list item */
  ngOnInit(): void {
    this._store
      .select(authSelectors.selectSettings)
      .pipe(take(1))
      .subscribe(settings => {
        this.oddType =
          settings?.setting?.offerViewType?.value === 'NUMBERING'
            ? 'NUMBERING'
            : 'NAMING';
      });
  }
}
