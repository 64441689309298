import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth/auth.service';
import { Actions, Effect, createEffect, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import { authActions, favoritesActions } from '@store/actions';
import { favoritesSelectors } from '@store/selectors';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class FavoritesEffects {
  /**
   * LOGIN SUCCESS
   */
  loginSuccessFavEvents$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.loginSuccess, authActions.autologinSuccess),
      map(() => favoritesActions.favEventsReadStart())
    )
  );

  /**
   * LOGIN SUCCESS
   */
  loginSuccessFavLeagues$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.loginSuccess, authActions.autologinSuccess),
      map(() => favoritesActions.getFavouritesStart())
    )
  );

  favRead$ = createEffect(() =>
    this._actions$.pipe(
      ofType(favoritesActions.favEventsReadStart),
      map(action => {
        const cookieEventsRaw = this._cookieService.get('FavoritedEvents');
        let cookieEvents = [];
        if (cookieEventsRaw && cookieEventsRaw.length > 0) {
          cookieEvents = cookieEventsRaw.split('+');
          cookieEvents = cookieEvents.filter(
            (id, index) => cookieEvents.indexOf(id) === index
          );
          cookieEvents = cookieEvents.map(id => parseFloat(id));
        }
        return favoritesActions.favEventsReadSuccess({ payload: cookieEvents });
      })
    )
  );

  favEventAdd$ = createEffect(() =>
    this._actions$.pipe(
      ofType(favoritesActions.favEventAdd),
      map(action => {
        return favoritesActions.favEventsWrite();
      })
    )
  );

  favEventRemove$ = createEffect(() =>
    this._actions$.pipe(
      ofType(favoritesActions.favEventRemove),
      map(action => {
        return favoritesActions.favEventsWrite();
      })
    )
  );

  favEventsRemove$ = createEffect(() =>
    this._actions$.pipe(
      ofType(favoritesActions.favEventsRemove),
      map(action => {
        return favoritesActions.favEventsWrite();
      })
    )
  );

  favWrite$ = createEffect(() =>
    this._actions$.pipe(
      ofType(favoritesActions.favEventsWrite),
      withLatestFrom(this._store.select(favoritesSelectors.selectFavEventIDs)),
      map(([action, favEventIDs]) => {
        this._cookieService.set(
          'FavoritedEvents',
          favEventIDs.join('+'),
          null,
          '/'
        );
        return favoritesActions.favEventsWriteSuccess();
      })
    )
  );

  getFavsStart$ = createEffect(() =>
    this._actions$.pipe(
      ofType(favoritesActions.getFavouritesStart),
      switchMap(action => {
        return this._authService.getFavourites().pipe(
          map(data => {
            return favoritesActions.getFavouritesSuccess({ payload: data });
          }),
          catchError(error => of(favoritesActions.getFavouritesFailure()))
        );
      })
    )
  );

  favSportAddStart$ = createEffect(() =>
    this._actions$.pipe(
      ofType(favoritesActions.favSportAddStart),
      switchMap(action => {
        return this._authService.addFavSport(action.payload).pipe(
          map(data => {
            return favoritesActions.favSportAddSuccess({
              payload: action.payload
            });
          }),
          catchError(error => of(favoritesActions.favSportAddFailure()))
        );
      })
    )
  );

  favSportRemoveStart$ = createEffect(() =>
    this._actions$.pipe(
      ofType(favoritesActions.favSportRemoveStart),
      switchMap(action => {
        return this._authService.removeFavSport(action.payload).pipe(
          map(data => {
            return favoritesActions.favSportRemoveSuccess({
              payload: action.payload
            });
          }),
          catchError(error => of(favoritesActions.favSportRemoveFailure()))
        );
      })
    )
  );

  favLeagueAddStart$ = createEffect(() =>
    this._actions$.pipe(
      ofType(favoritesActions.favLeagueAddStart),
      switchMap(action => {
        return this._authService.addFavLeague(action.payload).pipe(
          map(data => {
            return favoritesActions.favLeagueAddSuccess({
              payload: action.payload
            });
          }),
          catchError(error => of(favoritesActions.favLeagueAddFailure()))
        );
      })
    )
  );

  favLeagueRemoveStart$ = createEffect(() =>
    this._actions$.pipe(
      ofType(favoritesActions.favLeagueRemoveStart),
      switchMap(action => {
        return this._authService.removeFavLeague(action.payload).pipe(
          map(data => {
            return favoritesActions.favLeagueRemoveSuccess({
              payload: action.payload
            });
          }),
          catchError(error => of(favoritesActions.favLeagueRemoveFailure()))
        );
      })
    )
  );

  favChanceTypeAddStart$ = createEffect(() =>
    this._actions$.pipe(
      ofType(favoritesActions.favChanceTypeAddStart),
      switchMap(action => {
        return this._authService.addFavChanceType(action.payload).pipe(
          map(data => {
            return favoritesActions.favChanceTypeAddSuccess({
              payload: action.payload
            });
          }),
          catchError(error => of(favoritesActions.favChanceTypeAddFailure()))
        );
      })
    )
  );

  favChanceTypeRemoveStart$ = createEffect(() =>
    this._actions$.pipe(
      ofType(favoritesActions.favChanceTypeRemoveStart),
      switchMap(action => {
        return this._authService.removeFavChanceType(action.payload).pipe(
          map(data => {
            return favoritesActions.favChanceTypeRemoveSuccess({
              payload: action.payload
            });
          }),
          catchError(error => of(favoritesActions.favChanceTypeRemoveFailure()))
        );
      })
    )
  );

  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _authService: AuthService,
    private _cookieService: CookieService
  ) {}
}
