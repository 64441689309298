import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventListItemComponent } from './event-list-item.component';
import { EventListItemSkeletonComponent } from './event-list-item-skeleton/event-list-item-skeleton.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { OddModule } from '../odd/odd.module';
import { BuybackModule } from '../buyback/buyback.module';

@NgModule({
  declarations: [EventListItemComponent, EventListItemSkeletonComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    DoxxPipesModule,
    OddModule,
    BuybackModule
  ],
  exports: [EventListItemComponent, EventListItemSkeletonComponent]
})
export class EventListItemModule {}
