import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CombinationsdetailComponent } from './combinationsdetail.component';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { SharedModule } from '@shared/shared.module';
import { TicketComponent } from '../ticket.component';
import { FormatNumberWithCurrencyPipe } from '@shared/pipes/format-number-with-currency.pipe';
import { TranslateSpecialPipe } from '@shared/pipes/translate.pipe';

@NgModule({
  declarations: [CombinationsdetailComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    DoxxPipesModule,
    SharedModule
  ],
  exports: [CombinationsdetailComponent],
  providers: [
    TicketComponent,
    FormatNumberWithCurrencyPipe,
    TranslateSpecialPipe
  ]
})
export class CombinationsdetailModule {}
