import { Pipe, PipeTransform } from '@angular/core';
import { isAllowed, ScreenAllowed, AppScreens } from '@core/functions/isAlowed';

@Pipe({
  name: 'isScreenAllowed'
})
export class IsScreenAllowedPipe implements PipeTransform {
  /**
   * chcek if screen is allowed
   *
   * @param value screen name
   * @returns true if screen is allowed
   */
  transform(value: AppScreens): boolean {
    const screen = isAllowed(value);
    if (screen === ScreenAllowed.onlyForUnlogged) {
      return true;
    }
    if (screen === ScreenAllowed.onlyForLogged) {
      return true;
    }
    if (screen === ScreenAllowed.allowed) {
      return true;
    }
    if (screen === ScreenAllowed.disabled) {
      return false;
    }
  }
}
