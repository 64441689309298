import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { translateRoute } from '@core/functions/translateRoute';
import {
  TranslatePipe as NgxTranslatePipe,
  TranslateService
} from '@ngx-translate/core';

const BOLD_REGEX = /\*\*(.*?\*?)\*\*/g;
const ITALIC_REGEX = /\*(.*?)\*/g;
const VAR_REGEX = /{}/g;
const LINE_THROUG_REGEX = /~~(.*?)~~/g;
const MONOSPACE_REGEX = /`(.*?)`/g;
const LINK_LIKE_REGEX = /\[(.*?)\]\((.*?)\)/g;
const INTERNAL_LINK_REGEX = /\[([\w\sÀ-ž,.?!]*)\]\(doxxbet:(.*?)\)/g;
const EXTERNAL_LINK_REGEX =
  /\[(.*?)\]\(((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)\)/g;
@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe extends NgxTranslatePipe implements PipeTransform {
  /** odstrani z popisku specialne texty */
  transform(value: string, ...args: any[]): string {
    return (super.transform(value, args) || '')
      .replaceAll(BOLD_REGEX, '$1')
      .replaceAll(ITALIC_REGEX, '$1')
      .replaceAll(LINE_THROUG_REGEX, '$1')
      .replaceAll(MONOSPACE_REGEX, '$1')
      .replaceAll(LINK_LIKE_REGEX, '$1');
  }
}

@Pipe({
  name: 'translateSpecial',
  pure: false
})
export class TranslateSpecialPipe
  extends NgxTranslatePipe
  implements PipeTransform
{
  constructor(
    ts: TranslateService,
    cd: ChangeDetectorRef,
    private _sanitizer: DomSanitizer,
    private _router: Router
  ) {
    super(ts, cd);
    (window as any).fakeRouterLink = where => {
      this._router.navigate([where]);
      return false;
    };
  }

  /** premeni popisky do html, a zvyrazni specialne texty */
  transform(value: string, ...args: any[]): SafeHtml {
    let counter = 0;
    return this._sanitizer.bypassSecurityTrustHtml(
      (super.transform(value, args) || '')
        .replaceAll(BOLD_REGEX, '<span class="label_bold">$1</span>')
        .replaceAll(ITALIC_REGEX, '<span class="label_italic">$1</span>')
        .replaceAll(
          VAR_REGEX,
          () =>
            `<span class="label_var var${++counter}">${
              arguments[counter]
            }</span>`
        )
        .replaceAll(
          LINE_THROUG_REGEX,
          '<span class="label_line_throug">$1</span>'
        )
        .replaceAll(MONOSPACE_REGEX, '<span class="label_monospace">$1</span>')
        .replaceAll(
          INTERNAL_LINK_REGEX,
          (match, group1, group2) =>
            `<a
              href="${translateRoute(group2)}"
              onclick="
                return fakeRouterLink('${translateRoute(group2)}');
              "
              class="label_link"  >${group1} </a>`
        )
        .replaceAll(
          EXTERNAL_LINK_REGEX,
          `<a target="_blank" href="$2" class="label_link" >$1</a>`
        )
        .replaceAll(LINK_LIKE_REGEX, `<span class="label_$2">$1<span>`)
    );
  }
}
