import { Pipe, PipeTransform } from '@angular/core';
import { TICKET_HISTORY_STATUS } from '@constants/betslipStatus';
import { statusTransform } from '@core/functions/statusTransform/statusTransform';
import { TicketHistoryListItem } from '@models/TicketHistory';

@Pipe({
  name: 'sportTransform'
})
export class SportTransformPipe implements PipeTransform {
  /**
   * creates classes based on ticket sportID property
   * @param sportID required
   * @param icon optional
   * @param color optional
   * @param background optional
   * @param border optional
   */
  transform(
    sportID: number,
    icon?: boolean,
    color?: boolean,
    background?: boolean,
    border?: boolean
  ): string {
    if (sportID) {
      let sportClasses = '';
      if (icon) {
        sportClasses += ` icon var(--sport-icon-${sportID})`;
      }
      if (color) {
        sportClasses += ` color: var(--xx-sport-color-${sportID})`;
      }
      if (background) {
        sportClasses += ` background-color: var(--xx-sport-color-${sportID}, #f40034)`;
      }
      if (border) {
        sportClasses += ` border-color: var(--xx-sport-color-${sportID})`;
      }
      return sportClasses;
    } else {
      return '';
    }
  }
}

@Pipe({
  name: 'statusTransform'
})
export class StatusTransformPipe implements PipeTransform {
  /**
   * creates classes based on ticket status property
   * @param ticket required
   * @param icon optional
   * @param color optional
   * @param background optional
   * @param border optional
   */
  transform(
    ticket: TicketHistoryListItem,
    icon?: boolean,
    color?: boolean,
    background?: boolean,
    border?: boolean
  ): {
    icon: string;
    color: string;
    background: string;
    status: keyof typeof TICKET_HISTORY_STATUS;
  } {
    return statusTransform(ticket);
  }
}
