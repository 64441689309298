import { Injectable } from '@angular/core';
import { formatNumber } from '@core/functions/formatNumber';
import { formatNumberWithCurrency } from '@core/functions/formatNumberWithCurrency';
import { oneLine } from '@core/functions/oneLine';
import { trycatch } from '@core/tryCatch/tryCatch';
import { Ticket } from '@models/Ticket';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TicketErrorParser {
  constructor(private _translateService: TranslateService) {}
  /** transform errors */
  parseError(
    errors: string[],
    ticket: Ticket
  ): {
    message: string;
    errorCode: string;
    errorParams: string[];
  }[] {
    if (errors[0]?.length === 0) {
      return;
    }
    // turn error string to this map:
    // join params for this errors: ERR_RATEX, ERR_EDIT_ODDEXP
    // "ERR_MAXBET:3030;ERR_MAXBET:5850;ERR_RATEX:1297618706;ERR_RATEX:1297619631;ERR_EVT_DATE:1297618706;ERR_EVT_DATE:1297619631;ERR_EDIT_ODDEXP:1323889175;ERR_EDIT_ODDEXP:1326208503;ERR_EDIT_ODDEXP:1323458852;"
    // [
    //   [ERR_MAXBET, [3030]],
    //   [ERR_MAXBET, [5850]],
    //   [ERR_RATEX, [1297618706, 1297619631]],
    //   [ERR_EVT_DATE: [1297618706]],
    //   [ERR_EVT_DATE: [1297619631]],
    //   [ERR_EDIT_ODDEXP: [1323889175, 1326208503, 1323458852]]
    // ]

    let errorsMap: [string, string[]][] = [];

    errors[0]
      .split(';')
      .filter(error => error.length > 0)
      .forEach(error => {
        const [errorCode, ...params] = error.split(/:|\//);
        const index = ['ERR_RATEX', 'ERR_EDIT_ODDEXP'].includes(errorCode)
          ? errorsMap.findIndex(item => item[0] === errorCode)
          : -1;

        if (index !== -1) {
          errorsMap[index][1] = [...errorsMap[index][1], ...params];
        } else {
          errorsMap = [...errorsMap, [errorCode, [...params]]];
        }
      });
    const paramToCurrency = param =>
      trycatch(
        () =>
          formatNumberWithCurrency(param, {
            currency: ticket.CurrencyID
          }),
        param || ''
      );

    const paramToNumber = param =>
      trycatch(
        () =>
          formatNumber(param, {
            decimalDigits: 2
          }),
        param || ''
      );

    const paramsToEventNames = params =>
      trycatch(
        () =>
          params
            .map(
              param => ticket.Odds.find(odd => odd.OddID === +param).EventName
            )
            .filter(event => event.length > 0),
        []
      );

    const errorParsers = {
      ERR_MINBET: params =>
        this._translateService
          .instant('errors_ticket_error_ERR_MINBET')
          .replace('%s', paramToCurrency(params[0])),
      ERR_MINOVERALLBET: params =>
        this._translateService
          .instant('errors_ticket_error_ERR_MINOVERALLBET')
          .replace('%s', paramToCurrency(params[0])),
      ERR_MAXBET: params =>
        (ticket.IsEdited
          ? this._translateService.instant('newticket_ERR_MAXBET_EDIT')
          : this._translateService.instant('errors_ticket_error_ERR_MAXBET')
        ).replace('%s', paramToCurrency(params[0])),
      DBMAXBET: params =>
        (ticket.IsEdited
          ? this._translateService.instant('newticket_DBMAXBET_EDIT')
          : this._translateService.instant('errors_ticket_error_DBMAXBET')
        ).replace('%s', paramToCurrency(params[0])),
      ERR_LIMIT: params =>
        this._translateService
          .instant('errors_ticket_error_ERR_LIMIT')
          .replace('%s', paramToCurrency(params[0])),
      ERR_DBMAXOKBET: params =>
        this._translateService
          .instant('errors_ticket_error_DBMAXOKBET')
          .replace('%s', paramToCurrency(params[0])),
      ERR_RATE_MAX: params =>
        this._translateService
          .instant('errors_ticket_error_RATE_MAX')
          .replace('%s', paramToNumber(params[0])),
      ERR_MINCB: params =>
        oneLine`${this._translateService
          .instant('errors_ticket_error_ERR_MINCB')
          .replace('%s', params[1])}
          <br><br>
          ${paramsToEventNames([params[0]])[0]}`,
      ERR_ELCRST: params =>
        oneLine`${this._translateService.instant(
          'errors_ticket_error_ERR_ELCRST'
        )}
        <br><br>
        ${paramsToEventNames(params).join('<br>')}`,
      ERR_LIVEBAD: params =>
        oneLine`${this._translateService.instant(
          'errors_ticket_error_ERR_LIVEBAD'
        )}
        <br><br>
        ${paramsToEventNames(params).join('<br>')}`,
      ERR_CR_LCUPCOMP: params =>
        oneLine`${this._translateService.instant(
          'errors_ticket_error_ERR_CR_LCUPCOMP'
        )}
        <br><br>
        ${paramsToEventNames(params).join('<br>')}`,

      ERR_EVT_DATE: params =>
        oneLine`${this._translateService.instant(
          'errors_ticket_error_ERR_EVT_DATE'
        )}
        <br><br>
        ${paramsToEventNames(params).join('<br>')}`,
      ERR_EDIT_ODDEXP: params =>
        oneLine`${this._translateService.instant('newticket_ERR_EDIT_ODDEXP')}
        <br><br>
        ${paramsToEventNames(params).join('<br>')}`,
      ERR_EDIT_REM_ODDEXP: params =>
        oneLine`${this._translateService.instant(
          'newticket_ERR_EDIT_REM_ODDEXP'
        )}
        <br><br>
        ${paramsToEventNames(params).join('<br>')}`,
      ERR_EDIT_ODDREM_RATE: params =>
        oneLine`${this._translateService.instant(
          'newticket_ERR_EDIT_ODDREM_RATE'
        )}
        <br><br>
        ${paramsToEventNames(params).join('<br>')}`,
      ERR_NOCLIENT: () => this._translateService.instant('errors_ticket_login'),
      ERR_INSUFICIENT_FOUNDS: () =>
        this._translateService.instant('errors_ticket_ticketMax'),
      SYSTEM_ERROR: () =>
        this._translateService.instant('newticket_SYSTEM_ERROR'),
      ERR_MAXODDS: params =>
        this._translateService
          .instant('errors_ticket_error_ERR_MAXODDS')
          .replace('%s', params[1]),
      ERR_MAXCOMBI: params =>
        this._translateService
          .instant('errors_ticket_error_ERR_MAXCOMBI')
          .replace('%s', params[1])
    };

    return errorsMap.map(([errorCode, errorParams]) => ({
      message:
        errorParsers[errorCode]?.(errorParams) ||
        (`errors_ticket_error_${errorCode}` in
        this._translateService.translations[this._translateService.currentLang]
          ? this._translateService.instant(`errors_ticket_error_${errorCode}`)
          : `newticket_${errorCode}` in
            this._translateService.translations[
              this._translateService.currentLang
            ]
          ? this._translateService.instant(`newticket_${errorCode}`)
          : errorCode
        ).replace('%s', errorParams[0] || ''),
      errorCode,
      errorParams
    }));
  }
}
