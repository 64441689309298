import { createAction, props } from '@ngrx/store';
import { Analysis, AnalysisWithOdds } from '@models/Analysis';

/**
 * ANALYSIS LIST
 */
export const loadActualAnalyses = createAction('[Analyses] Load Analysess');

export const loadActualAnalysesSuccess = createAction(
  '[Analyses] Load analyses Success',
  props<{ analyses: AnalysisWithOdds[] }>()
);

export const setAnalysisHistoryDateFilter = createAction(
  '[Analyses] Set analyses history date filter',
  props<{ date: string }>()
);

export const loadNextPageOfAnalysisHistory = createAction(
  '[Analyses] Load Next Page Of Analysis History'
);

export const nextAnalysisHistoryLoaded = createAction(
  '[Analyses] Next Analyses History Loaded',
  props<{ analyses: Analysis[] }>()
);

/**
 * ANALYSIS DETAIL
 */
export const loadAnalysisDetail = createAction(
  '[Analyses] Load analysis detail',
  props<{ analysisID: number }>()
);

export const loadAnalysisDetailSuccess = createAction(
  '[Analyses] Load analysis detail success',
  props<{ analysis: Analysis }>()
);

export const loadAnalysisDetailFailure = createAction(
  '[Analyses] Load analysis detail failure',
  props<{ err: any }>()
);
