import { createReducer, on } from '@ngrx/store';
import { personalInfoActions } from '@store/actions';
import { User } from '@models/User';
import { ClientPersonalInfo } from '@models/PersonalInfo';

export const personalInfoFeatureKey = 'personalinfo';

export interface State {
  client: ClientPersonalInfo;
  form: any;
  bankcards: any;
  bankaccounts: any;
}

export const initialState: State = {
  client: undefined,
  form: undefined,
  bankaccounts: undefined,
  bankcards: undefined
};

export const reducer = createReducer(
  initialState,

  on(personalInfoActions.loadClientInfoSuccess, (state, action) => ({
    ...state,
    client: {
      ...state.client,
      ...action.client
    },
    form: {
      ...state.form,
      ...action.form
    }
  }))
);
