import { Component, Input } from '@angular/core';

@Component({
  selector: 'doxx-tooltip',
  templateUrl: './tooltip.component.html'
})
export class TooltipComponent {
  @Input() tooltipMsg: string;

  constructor() {}
}
