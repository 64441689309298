import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environment';
import { env } from 'process';
import { configuration } from '@configuration';

@Component({
  selector: 'doxx-download-app-banner',
  templateUrl: './download-app-banner.component.html',
  styleUrls: ['./download-app-banner.component.scss']
})
export class DownloadAppBannerComponent implements OnInit {
  @Input() appType: 'betting' | 'casino' = 'betting';

  constructor(
    private _platform: Platform,
    private _cookieService: CookieService
  ) {}

  public shouldShowAppModal = undefined;
  otherPlatforms = configuration.otherPlatforms;

  /**
   * banner should be shown only when you open
   * app for the first time
   */
  ngOnInit(): void {
    const showAppDownloadModal = this._cookieService.get(
      environment.cookies.appModal.key
    );
    if (showAppDownloadModal) {
      this.shouldShowAppModal = showAppDownloadModal === 'true';
    }
  }

  /**
   * Get operation system - Ionic 5 implementation
   */
  getOperatingSystem(): string {
    const platforms = this._platform.platforms();

    if (platforms.indexOf('android') !== -1) {
      return 'android';
    } else if (platforms.indexOf('ios') !== -1) {
      return 'ios';
    } else {
      return 'desktop';
    }
  }

  /**
   * skryje modal s vyzvou pre instalovanie appky
   */
  hideAppModal(): void {
    this.shouldShowAppModal = false;
    this._cookieService.set(
      environment.cookies.appModal.key,
      'false',
      +environment.cookies.appModal.expiration,
      '/'
    );
  }

  /**
   * Zisti ci ma stiahnut kasino alebo betting appku a presmeruje pouzivatela do storu
   */
  downloadIosApp(): void {
    if (this.appType === 'casino') {
      window.open(this.otherPlatforms.iosCasino.link, '_blank');
    } else {
      window.open(this.otherPlatforms.ios.link, '_blank');
    }
  }

  /**
   * check from config, if is allowed to show banner
   * @returns boolean
   */
  isAllowed(): boolean {
    if (this.getOperatingSystem() === 'android') {
      return this.otherPlatforms.android.allowed;
    } else if (this.getOperatingSystem() === 'ios') {
      if (this.appType === 'casino') {
        return this.otherPlatforms.iosCasino.allowed;
      } else {
        return this.otherPlatforms.ios.allowed;
      }
    }
    return false;
  }
}
