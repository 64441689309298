import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { filter, first, tap } from 'rxjs/operators';
import { PresentMessageService } from 'src/app/services/present-message.service';
import { Observable } from 'rxjs';

import { customFloor } from '@core/functions/customFloor';
import * as fromApp from '@store/app.reducer';
import { Store } from '@ngrx/store';
import { TicketHistoryListItem } from '@models/TicketHistory';
import { buyBackActions } from '@store/actions';
import { buyBackSelectors } from '@store/selectors';
import { configuration } from '@configuration';

@Component({
  selector: 'doxx-autobuyback-modal',
  templateUrl: './autobuyback-modal.component.html',
  styleUrls: ['./autobuyback-modal.component.scss']
})
export class AutoBuybackModalComponent implements OnInit {
  config = configuration;
  isAutoBuybackActive: boolean;
  constructor(
    private _translateService: TranslateService,
    private _presentMessageService: PresentMessageService,
    private _modalCtrl: ModalController,
    private _router: Router,
    private _store: Store<fromApp.AppState>
  ) {}

  @Input() ticketCode: TicketHistoryListItem['ticketCode'];
  buyBack$;

  autobuybackValueSlider: number;
  autoBuyBackSet: boolean;
  min: number;
  configuration = configuration;

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this._store.dispatch(
      buyBackActions.loadBuybackValues({ ticketCode: this.ticketCode })
    );

    this.buyBack$ = this._store
      .select(
        buyBackSelectors.selectBuybackStateValueByTicketCode(this.ticketCode)
      )
      .pipe(
        tap(data => {
          if (data.BuyBackValue === -1) {
            this.closeBuybackModal(false);
          }

          this.min =
            data.BuyBackValue >= 0 ? data.BuyBackValue : data.AmountMoney;

          if (data.AutoBuyBackValue !== 0) {
            this.autobuybackValueSlider =
              this.autobuybackValueSlider || data.AutoBuyBackValue;
            this.autoBuyBackSet = true;
          } else {
            this.autobuybackValueSlider =
              this.autobuybackValueSlider || this.min;
            this.autoBuyBackSet = false;
          }
        })
      );

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        first()
      )
      .subscribe(e => {
        this._modalCtrl.getTop().then(modal => {
          if (modal) {
            modal.dismiss();
          }
        });
      });
  }

  /**
   * closes modal
   */
  closeBuybackModal(success: boolean, autobuyback?: boolean): void {
    this._modalCtrl.dismiss({ success, autobuyback });
  }

  /**
   * focusOnValue
   */
  focusOnValue($event: any): void {
    this.autobuybackValueSlider = null;
  }

  /**
   * zmena by slider
   */
  changeAutobuybackValue(): void {
    this.autobuybackValueSlider = customFloor(this.autobuybackValueSlider);
  }

  /**
   * check rozsahu odkupu
   */
  checkRange(buybackValue): void {
    if (this.autobuybackValueSlider < buybackValue.BuyBackValue) {
      this.autobuybackValueSlider = buybackValue.BuyBackValue;
      this._presentMessageService.presentCustomMessage(
        this._translateService.instant('autocashout_min_amount'),
        'info-alert'
      );
    } else if (
      buybackValue.BuyBackValue === 0 &&
      this.autobuybackValueSlider < buybackValue.AmountMoney
    ) {
      this._presentMessageService.presentCustomMessage(
        this._translateService.instant('autocashout_min_amount'),
        'info-alert'
      );
      this.autobuybackValueSlider = buybackValue.AmountMoney;
    } else if (
      this.autobuybackValueSlider > customFloor(buybackValue.AmountMoneyWin)
    ) {
      this.autobuybackValueSlider = customFloor(buybackValue.AmountMoneyWin);
    }
  }

  /**
   * uskutocni odkupenie tiketu
   */
  executeAutoBuyback(autoBuybackValue): void {
    this._store.dispatch(
      buyBackActions.executeAutoBuyback({
        ticketCode: this.ticketCode,
        autoBuybackValue
      })
    );
  }

  /** present autobuyback tooltip */
  showAutoBuybackTooltip(): void {
    this._presentMessageService.presentCustomMessage(
      this._translateService.instant('ticket_autobuyback_tooltip'),
      'info-alert-blue',
      this._translateService.instant('ticket_buyback_ticketbuyback'),
      ['OK']
    );
  }

  /** prevent falsy values to set to range */
  onInputChange(event): void {
    if (typeof event === 'number') {
      this.autobuybackValueSlider = event;
    }
  }
}
