import { Pipe, PipeTransform } from '@angular/core';
import { TicketOdd } from '@models/Ticket';
import { InGroup } from './in-group';

@Pipe({
  name: 'isTopLine'
})
export class IsTopLinePipe extends InGroup implements PipeTransform {
  /**
   * used in rozpisovy tiket to display top line
   */
  transform(index: number, odds: TicketOdd[]): boolean {
    if (!this.isInGroup(index, odds)) {
      return false;
    }
    const prevOdd = odds[index - 1];
    const odd = odds[index];
    return prevOdd?.Group === odd.Group;
  }
}
