import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { filter, first } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { getOddsCounts } from '@core/functions/parseOddsStats/parseOddsStats';
import { TicketHistroyDetail } from '@models/TicketHistory';

@Component({
  selector: 'doxx-odds-modal',
  templateUrl: './odds-modal.component.html',
  styleUrls: ['./odds-modal.component.scss']
})
export class OddsModalComponent {
  constructor(private _modalCtrl: ModalController, private _router: Router) {
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        first()
      )
      .subscribe(e => this.closeModal());
  }
  badgen = 0;
  badgey = 0;
  badgeu = 0;
  _oddsStats = {
    Y: {
      count: 0,
      percent: 0
    },
    U: {
      count: 0,
      percent: 0
    },
    N: {
      count: 0,
      percent: 0
    }
  };

  @Input() set oddStats(oddStats: TicketHistroyDetail['oddsStats']) {
    this._oddsStats = getOddsCounts(oddStats);

    this.badgeu = this._oddsStats.U.percent / 200 + 0.5;

    this.badgen =
      ((this._oddsStats.N.percent - this._oddsStats.U.percent) / 2 +
        this._oddsStats.U.percent) /
        100 +
      0.5;
    this.badgey =
      ((100 - this._oddsStats.N.percent - this._oddsStats.U.percent) / 2 +
        this._oddsStats.N.percent +
        this._oddsStats.U.percent) /
        100 +
      0.5;
  }

  /** close modal */
  closeModal(): void {
    this._modalCtrl.getTop().then(modal => {
      if (modal) {
        modal.dismiss();
      }
    });
  }
}
