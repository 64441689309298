import { createAction, props } from '@ngrx/store';
import { CasinoGame } from '@models/CasinoGame';
import { GameCategory } from '@models/GameCategory';
import { CasinoJackpot, CasinoJackpotResponse } from '@models/CasinoJackpot';
import { SignalrCasinoJackpotPayload } from 'src/app/services/signalr.service';

export const loadCasinoGames = createAction('[Casino] Load Casino Games');

export const loadCasinoGamesSuccess = createAction(
  '[Casino] Load Casino Games Success',
  props<{ games: GameCategory[] }>()
);

export const loadCasinoGamesFailure = createAction(
  '[Casino] Load Casino Games Failure'
);

export const loadCasinoCategories = createAction(
  '[Casino] Load Casino Categories'
);

export const loadCasinoCategoriesSuccess = createAction(
  '[Casino] Load Casino Categories Success',
  props<{ categories: GameCategory[] }>()
);

export const loadCasinoCategoriesFailure = createAction(
  '[Casino] Load Casino Categories Failure'
);

export const loadRioGames = createAction('[Casino] Load Rio Games');

export const loadRioGamesSuccess = createAction(
  '[Casino] Load Rio Games Success',
  props<{ games: GameCategory[] }>()
);

export const loadRioGamesFailure = createAction(
  '[Casino] Load Rio Games Failure'
);

export const loadGamesbyCategory = createAction(
  '[Casino] Load GamesbyCategory start',
  props<{ categoryID: number; casinoType: any }>()
);

export const loadGamesbyCategorySuccess = createAction(
  '[Casino] Load GamesbyCategory success',
  props<{ games: GameCategory[] }>()
);

export const loadGamesbyCategoryFailure = createAction(
  '[Casino] Load GamesbyCategory Failure'
);

export const loadCasinoJackpots = createAction('[Casino] Load casino jackpots');

export const loadCasinoJackpotsSuccess = createAction(
  '[Casino] Load casino jackpots success',
  props<{ jackpots: CasinoJackpotResponse }>()
);

export const loadCasinoJackpotsFailure = createAction(
  '[Casino] Load casino jackpots failure'
);

export const incrementCasinoJackpotsAmounts = createAction(
  '[Casino] Increment casino jackpots amounts'
);

export const setCasinoJackpotsAmounts = createAction(
  '[Casino] Set casino jackpots amounts',
  props<{ referenceJackpots: SignalrCasinoJackpotPayload[] }>()
);
