import {
  Component,
  Input,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { from, of, BehaviorSubject, Subscription } from 'rxjs';
import { concatMap, delay, tap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'doxx-progressive-loading-container',
  template: `<ng-container #container></ng-container>`,
  styles: [
    `
      :host {
        display: block;
      }
    `
  ]
})
export class ProgressiveLoadingContainerComponent
  implements AfterViewInit, OnDestroy
{
  @Input() set dataset(dataset: any[]) {
    const map = new Map();
    dataset.forEach((model, index) => {
      map.set(this.trackByFn ? this.trackByFn(index, model) : index, {
        model,
        index
      });
    });
    this._dataset.next(map);
  }

  private _templateTrack = new Map();

  @Input() trackByFn: (index, model) => string | number;

  @Input() scope: any;
  @Input() template: TemplateRef<any>;

  @Input() cycleItems = 3;
  @Input() cycleDelay = 20;

  private _dataset = new BehaviorSubject<Map<string | number, any>>(new Map());
  @ViewChild('container', { read: ViewContainerRef })
  container: ViewContainerRef;

  private _subscription: Subscription;

  constructor() {}

  /**
   * Create subssiption which will load item progresively
   */
  ngAfterViewInit(): void {
    this._subscription = this._dataset
      .pipe(
        switchMap(data => {
          const dataCpy = [...data];
          if (!this.trackByFn) {
            this.container.clear();
            this._templateTrack.clear();
          } else {
            [...this._templateTrack]
              .sort(([a, iA], [b, iB]) => iB - iA)
              .forEach(([key, index]) => {
                if (!data.get(key)) {
                  if (this.container.get(index)) {
                    this.container.remove(index);
                    this._templateTrack.delete(key);
                  } else {
                    this.container.clear();
                    this._templateTrack.clear();
                  }
                }
              });
          }
          let sets = [];
          while (dataCpy.length > 0) {
            sets = [...sets, dataCpy.splice(0, this.cycleItems)];
          }
          return from(sets).pipe(
            concatMap(set => of(set).pipe(delay(this.cycleDelay))),
            tap(set =>
              set.forEach(([key, item]) => {
                if (
                  !(
                    this.trackByFn && this._templateTrack.get(key) !== undefined
                  )
                ) {
                  this.container.createEmbeddedView(
                    this.template,
                    {
                      item: item.model,
                      scope: this.scope
                    },
                    item.index
                  );
                  this._templateTrack.set(key, item.index);
                }
              })
            )
          );
        })
      )
      .subscribe();
  }

  /**
   * Clear subscription to prevent memory leaks
   */
  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
