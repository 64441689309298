import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

export const tabAnimations = [
  trigger('tabPosition', [
    state(
      'right',
      style({
        transform: 'translateX(-110%)',
        opacity: 0
      })
    ),
    state(
      'left',
      style({
        transform: 'translateX(110%)',
        opacity: 0
      })
    ),
    state(
      'center',
      style({
        transform: 'translateX(0)',
        opacity: 1
      })
    ),
    transition('right <=> center', animate('{{duration}}ms'), {
      params: { duration: 100 }
    }),
    transition('left <=> center', animate('{{duration}}ms'), {
      params: { duration: 100 }
    })
  ]),
  trigger('tabHeight', [
    state(
      'right',
      style({
        height: 0
      })
    ),
    state(
      'left',
      style({
        height: 0
      })
    ),
    state(
      'center',
      style({
        height: '*'
      })
    ),
    transition('right => center', animate(0)),
    transition('left => center', animate(0)),
    transition('center => left', animate('{{duration}}ms'), {
      params: { duration: 100 }
    }),
    transition('center => right', animate('{{duration}}ms'), {
      params: { duration: 100 }
    })
  ])
];
