import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { oneLine } from '@core/functions/oneLine';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '@environment';
import { GameCategory } from '@models/GameCategory';
import { User } from '@models/User';
import { CasinoWinnerResponse } from '@models/Winners';

import { PresentMessageService } from 'src/app/services/present-message.service';
import { configuration } from '@configuration';
import { translateRoute } from '@core/functions/translateRoute';
import { CasinoJackpotResponse } from '@models/CasinoJackpot';
import { Store } from '@ngrx/store';
import { authSelectors, maintenanceSelectors } from '@store/selectors';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as URITemplate from 'uri-template-lite';

@Injectable({
  providedIn: 'root'
})
export class CasinoService {
  public userData: User;
  selectedType: any;
  gameURL: string;
  isCasinoBlocked: boolean;
  user?: User;
  game: string;

  alert?: Promise<HTMLIonAlertElement>;

  get templobbyUrl(): string {
    return encodeURIComponent(
      'https://m.doxxbet.com.lr/en/casino/casino-online'
    );
  }

  get lobbyUrl(): string {
    return encodeURIComponent(configuration.connections.casino.url);
  }
  get depositUrl(): string {
    return encodeURIComponent(
      `${new URL(configuration.connections.betting.url).origin}` +
        translateRoute('DepositWithdrawal_Deposit')
    );
  }

  constructor(
    private _httpClient: HttpClient,
    private _presentMessageService: PresentMessageService,
    private _store: Store,
    private _router: Router,
    private _translateService: TranslateService
  ) {
    this._store
      .select(maintenanceSelectors.selectIsCasinoBlocked)
      .subscribe(isBlocked => (this.isCasinoBlocked = isBlocked));
    this._store
      .select(authSelectors.selectLoggedUser)
      .subscribe(user => (this.user = user));
  }

  /**
   * Hry podla kategorii
   */
  public getGames(): Observable<any> {
    //const url: string = oneLine`${environment.apiUrl}/web/casino/categoriesByVariant/LanguageId/sk/GamesCount/-1`;
    const url: string = oneLine`${environment.apiUrl}/web/casino/categoriesByVariant/LanguageId/${environment.language}/GamesCount/-1`;

    return this._getRequest(url);
  }

  /**
   * Ziska token pre danu hru
   */
  public createToken(gamecode: string): Observable<any> {
    const url: string = oneLine`${environment.apiUrl}/web/casino/createToken/
      GameCode/${gamecode}`;
    return this._getRequest(url);
  }

  /** Shows alert with "Play for fun" and "Play for real" options */
  presentPlayOptions(game: string, casinoType?: string, categoryID?: number) {
    if (this.user) {
      this.openRealGame(game, null, casinoType, categoryID);
    } else {
      this.alert = this._presentMessageService.presentCustomMessage(
        '',
        'casino-play-alert',
        this._translateService.instant('casino_play_game_alert'),
        [this._translateService.instant('modal_default_cancel')],
        [
          {
            label: this._translateService.instant('app_casino_playForFun'),
            type: 'radio',
            handler: input => {
              this.openFunGame(game, casinoType);
            },
            cssClass: 'button-main'
          },
          {
            label: this._translateService.instant('casino_play_long'),
            type: 'radio',
            handler: input => {
              if (!this.user) {
                this._router.navigate([translateRoute('Login')]);
              } else {
                this.openRealGame(game, null, casinoType, categoryID);
              }

              this.alert?.then(window => window.dismiss());
            },
            cssClass: 'button-secondary'
          }
        ]
      );
    }
  }

  /**
   * Spusti bezplatnu hru
   */
  openFunGame(game: string, casinoType?: string, TableAlias?: string): void {
    const URItemplate = new URITemplate(
      environment.casinoGameURL + '{?query*}'
    );
    let query;

    switch (environment.location) {
      case 'lbr':
        query = {
          gameName: game,
          currency: configuration.base.defaultCurrency.code,
          gameMode: 'Demo',
          language: environment.language
        };
        break;
      case 'nga':
        if (environment.production) {
          query = {
            gameName: game,
            clientType: 'casino',
            clientPlatform: 'mobile',
            language: environment.language,
            depositUrl: this.depositUrl,
            lobbyUrl: this.lobbyUrl,
            casino: environment.casinoURLparam
          };
          if (!TableAlias) {
            query.tableAlias = TableAlias;
          }
        } else {
          query = {
            gameName: game,
            currency: configuration.base.defaultCurrency.code,
            casino: 'doxxbet.ng',
            gameMode: 'Demo',
            language: environment.language
          };
        }
        break;
      default:
        if (!casinoType) {
          casinoType = this.selectedType.Route;
        }
        query = {
          gameName: game,
          depositUrl: this.depositUrl,
          lobbyUrl: this.lobbyUrl + '/' + casinoType,
          casino: environment.casinoURLparam,
          clientType: 'casino',
          clientPlatform: 'mobile',
          language: environment.language
        };
        break;
    }

    window.open(URItemplate.expand({ query }));
    console.log(URItemplate.expand({ query }));

    //old solution
    //   if (environment.location === 'lbr') {
    //     const url: string =
    //       `${environment.casinoGameURL}` +
    //       //'?gameName=' +
    //       game +
    //       '&currency=USD&gameMode=Demo&language=' +
    //       `${environment.language}`;
    //     window.open(url, '_self');
    //   } else if (environment.location === 'nga') {
    //     const url: string =
    //       `${environment.casinoGameURL}` +
    //       //'?gameName=' +
    //       game +
    //       '&currency=USD&casino=doxxbet.ng&gameMode=Demo&language=' +
    //       `${environment.language}`;
    //     window.open(url, '_self');
    //   }
    //   //casino=doxxbet.ng
    //  else if (environment.name === 'production-nga') {

    //   let url: string =
    //     `${environment.casinoGameURL}` +
    //     game +
    //     '&clientType=casino&clientPlatform=mobile&language=en' +
    //     '&depositUrl=' +
    //     this.depositUrl +
    //     '&lobbyUrl=' +
    //     this.lobbyUrl +
    //     '&casino=' +
    //     `${environment.casinoURLparam}`;

    //   if (TableAlias) {
    //     url = url + '&tableAlias=' + TableAlias;
    //   }

    //   console.log(url);
    //   window.open(url, '_self');
    // }
    //   else {
    //     if (!casinoType) {
    //       casinoType = this.selectedType.Route;
    //     }
    //     const url: string =
    //       `${environment.casinoGameURL}` +
    //       // '?gameName=' +
    //       game +
    //       '&depositUrl=' +
    //       this.depositUrl +
    //       '&lobbyUrl=' +
    //       this.lobbyUrl +
    //       '&casino=' +
    //       casinoType +
    //       `${environment.casinoURLparam}` +
    //       '&clientType=casino&clientPlatform=mobile&language=' +
    //       `${environment.language}`;
    //        console.log(url);
    //    // window.open(url, '_self');
    //   }
  }

  /**
   * Spusti hru za peniaze
   */
  //  if(game.providerID === 2){
  //   // https://games-dxb-wc11.apollogames.com/casino-games/apollo/launcher/?gameName=blood&currency=USD&gameMode=Real&language=en&historyUrl=
  //   game.url = $rootScope.apolloGameURL + "?gameName="+ game.systemName +"&currency="+ $rootScope.currencyID+"&gameMode="+ ($rootScope.isClientLoggedIn?"Real":"Demo") +"&language="+$rootScope.languageID+"&historyUrl=";
  // } else {
  //   game.url = $rootScope.mainGameURL + "?gameCodeName="+ game.systemName +"&clientType=casino&clientPlatform=web&language="+$rootScope.languageID +"&depositUrl="+$rootScope.depositURL+'&lobbyUrl='+$rootScope.lobbyURL+'&casino='+ $rootScope.gameUrlparam
  //   // +($rootScope.ENV.name === 'staging' && false?"&logoutMode=1":"")
  //     +"&regulationsenabled=false";
  // }

  ///
  openRealGame(
    game: string,
    clientCode?: string,
    casinoType?: string,
    categoryID?: number,
    TableAlias?: string
  ): void {
    // check if real game can be played
    if (this.isCasinoBlocked) {
      this._router.navigate([
        translateRoute('Maintenance', { type: 'casino' })
      ]);
      return;
    }

    if (
      !this.user ||
      (configuration.verification.allowed && this.user.verified === false)
    ) {
      return;
    }

    // try to open real game
    let casinoToken = null;
    this.createToken(game).subscribe(resp => {
      if (!resp.Result && resp.errors) {
        this._presentMessageService.presentCustomMessage(
          resp.errors.validation[0].messages[0],
          'error-alert',
          '',
          ['OK']
        );
      } else {
        casinoToken = resp.Result;
        casinoToken = encodeURIComponent(casinoToken);
        if (!casinoToken) {
          return;
        }
        if (!casinoType) {
          casinoType = this.selectedType.Route;
        }

        if (environment.location === 'lbr') {
          const url: string =
            `${environment.casinoGameURL}` +
            '?gameName=' +
            game +
            '&authToken=' +
            casinoToken +
            '&playerId=' +
            this.user.clientCode +
            '&currency=USD&gameMode=Real&language=' +
            `${environment.language}` +
            '&backUrl=' +
            this.templobbyUrl;

          // console.log(url);
          window.open(url, '_self');
          //   }else if (environment.location === 'nga') {
        } else if (environment.name === 'production-nga') {
          let url: string =
            `${environment.casinoGameURL}` +
            '?gameCodeName=' +
            game +
            '&clientType=casino&clientPlatform=mobile&language=en' +
            '&depositUrl=' +
            this.depositUrl +
            '&lobbyUrl=' +
            this.lobbyUrl +
            '&casino=' +
            `${environment.casinoURLparam}` +
            //'&regulationsenabled=false&disable_swiper=1' +
            '&username=' +
            this.user.clientCode +
            '&externalToken=' +
            casinoToken;

          if (TableAlias) {
            url = url + '&tableAlias=' + TableAlias;
          }
          window.open(url, '_self');
        } else if (categoryID && categoryID === 108) {
          const apolloGamesURL =
            'https://games-dxb-wc11.apollogames.com/casino-games/apollo/launcher/';

          const url: string =
            apolloGamesURL +
            '?gameName=' +
            game +
            '&currency=' +
            'EUR' +
            '&gameMode=' +
            'Real' +
            '&language=' +
            'en' +
            '&historyUrl=' +
            this.lobbyUrl +
            '&playerId=' +
            this.user.clientCode +
            '&authToken=' +
            casinoToken;
          window.open(url, '_self');
        } else if (
          categoryID &&
          environment.location === 'svk' &&
          categoryID === 102
        ) {
          const url: string =
            `${environment.casinoGameURL}` +
            '?gameCodeName=' +
            game +
            '&depositUrl=' +
            this.depositUrl +
            '&lobbyUrl=' +
            this.lobbyUrl +
            '/' +
            casinoType +
            '&casino=' +
            `${environment.casinoURLparam}` +
            '&clientType=casino&clientPlatform=mobile&disable_swiper=1&language=sk&username=' +
            this.user.clientCode +
            '&externalToken=' +
            casinoToken;
          window.open(url, '_self');
        } else if (
          categoryID &&
          environment.location === 'stg' &&
          categoryID === 101
        ) {
          const url: string =
            `${environment.casinoGameURL}` +
            '&gameName=' +
            game +
            '&depositUrl=' +
            this.depositUrl +
            '&lobbyUrl=' +
            this.lobbyUrl +
            '/' +
            casinoType +
            '&casino=' +
            `${environment.casinoURLparam}` +
            '&clientType=casino&clientPlatform=mobile&disable_swiper=1&language=sk&username=' +
            this.user.clientCode +
            '&externalToken=' +
            casinoToken;
          window.open(url, '_self');
        } else {
          const url: string =
            `${environment.casinoGameURL}` +
            '&gameCodeName=' +
            game +
            '&depositUrl=' +
            this.depositUrl +
            '&lobbyUrl=' +
            this.lobbyUrl +
            '/' +
            casinoType +
            '&casino=' +
            `${environment.casinoURLparam}` +
            '&clientType=casino&clientPlatform=mobile&language=sk&username=' +
            this.user.clientCode +
            '&externalToken=' +
            casinoToken;
          window.open(url, '_self');
        }
      }
    });
  }

  /**
   * Get Request
   * @param url url
   */
  private _getRequest(url: string): Observable<any> {
    return this._httpClient
      .get<any>(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        withCredentials: true
      })
      .pipe(
        catchError(err => {
          if (err.status === 409) {
            return of(err.error);
          }
          return throwError(err);
        }),
        map(resp => {
          return resp;
        })
      );
  }

  /** Fetch casino games  */
  fetchCasinoGames(): Observable<{
    games: GameCategory[];
  }> {
    return this._fetchGameList(
      oneLine`${environment.apiUrl}/web/casino/categoriesByVariant/LanguageId/` +
        `${environment.language}` +
        `/GamesCount/-1`
    );
  }

  /** Fetch rio games  */
  fetchRioGames(): Observable<{
    games: GameCategory[];
  }> {
    return this._fetchGameList(
      oneLine`${environment.apiUrl}/web/rio/categoriesByVariant/LanguageId/` +
        `${environment.language}` +
        `/GamesCount/-1`
    );
  }

  /** Get player of the day
   * https://m-api-staging.doxxbet.sk/web/casino/playerOfLastDay
   */
  playerOfLastDay(): Observable<CasinoWinnerResponse> {
    const url = oneLine`${environment.apiUrl}/web/casino/playerOfLastDay`;
    return this._httpClient
      .get<any>(url, {
        withCredentials: true
      })
      .pipe(
        catchError(err => {
          if (err.status === 409) {
            return of(err.error);
          }
          return throwError(err);
        }),
        map(resp => {
          return resp;
        })
      );
  }

  /** Approve policy of casino
   * https://m-api-staging.doxxbet.sk/web/casino/approvePolicy
   */
  approvePolicy(): Observable<CasinoWinnerResponse> {
    const url = oneLine`${environment.apiUrl}/web/casino/approvePolicy`;
    return this._httpClient
      .get<any>(url, {
        withCredentials: true
      })
      .pipe(
        catchError(err => {
          if (err.status === 409) {
            return of(err.error);
          }
          return throwError(err);
        }),
        map(resp => {
          return resp;
        })
      );
  }

  /**
   *  fetch games by categories
   */
  fetchGamesbyCategory(
    categoryID,
    casinoType
  ): Observable<{
    games: GameCategory[];
  }> {
    if (!categoryID) {
      categoryID = 0;
      return;
    }
    return this._httpClient
      .get<any>(
        oneLine`
        ${environment.apiUrl}
           /web/${casinoType}
           /gamesByVariant/LanguageId/${environment.language}/CategoryId/${categoryID}`,
        {
          withCredentials: true
        }
      )
      .pipe(
        map(data => ({
          games: data.Result.Games
        }))
      );
  }

  /** Fetch casino jackpots */
  fetchCasinoJackpots(): Observable<{ jackpots: CasinoJackpotResponse }> {
    return this._httpClient
      .get<CasinoJackpotResponse>(
        oneLine`${environment.apiUrl}/central/casino/game/jackpot`,
        { withCredentials: true }
      )
      .pipe(map(jackpotsData => ({ jackpots: jackpotsData })));
  }

  /**
   *  fetch gameList
   */
  private _fetchGameList(url: string): Observable<{
    games: GameCategory[];
  }> {
    return this._httpClient
      .get<any>(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        withCredentials: true
      })
      .pipe(
        map(data => {
          return {
            games: data.Result
          };
        })
      );
  }
}
