<div class="no-odds">
  <div class="waves">
    <div class="before-wave"></div>
    <div class="container_1 shadow">
      <i class="icon icon-ic_ticket-paper-stroke-light"></i>
      <span class="text">
        {{ 'ticket_empty' | translate }}
      </span>
    </div>
    <div class="after-wave"></div>
  </div>

  <div class="container_2">
    <span class="text1">{{ 'ticket_empty_1' | translate }}</span>
    <div class="menu">
      <span
        class="item border"
        [routerLink]="'LiveList' | translateRoute"
        (click)="hideTicket()"
      >
        <i class="icon icon-ic_live"></i>
        <span class="text">
          {{ 'ticket_empty_menu_1' | translate }}
        </span>
      </span>
      <span
        class="item border"
        [routerLink]="'PrematchList' | translateRoute"
        (click)="hideTicket()"
      >
        <i class="icon icon-ic_offer"></i>
        <span class="text">
          {{ 'ticket_empty_menu_2' | translate }}
        </span>
      </span>
      <span
        class="item"
        [routerLink]="'VirtualGames' | translateRoute"
        (click)="hideTicket()"
      >
        <i class="icon icon-ic_virtual"></i>
        <span class="text">
          {{ 'ticket_empty_menu_3' | translate }}
        </span>
      </span>
    </div>
  </div>
</div>
