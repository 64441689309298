import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoxInputComponent } from './box-input.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [BoxInputComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [BoxInputComponent]
})
export class BoxInputModule {}
