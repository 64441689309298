import {
  Directive,
  Input,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';

@Directive({ selector: '[doxxCopyClipboard]' })
export class CopyClipboardDirective {
  @Input()
  public doxxCopyClipboard: string;

  @Output()
  public copied: EventEmitter<string> = new EventEmitter<string>();

  /** adds event listener on click */
  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    if (!this.doxxCopyClipboard) {
      return;
    }

    const x = document.createElement('TEXTAREA') as HTMLTextAreaElement;
    x.value = this.doxxCopyClipboard.toString();
    document.body.appendChild(x);
    x.select();
    document.execCommand('copy');
    document.body.removeChild(x);
    this.copied.emit(this.doxxCopyClipboard);
  }
}
