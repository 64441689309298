import { createAction, props } from '@ngrx/store';
import { CasinoWinner, Winner } from '@models/Winners';

export const loadWinners = createAction(
  '[Landing] Winners load winning tickets'
);

export const loadWinnersSuccess = createAction(
  '[Landing] Winners load winning tickets success',
  props<{ tickets: Winner }>()
);

export const loadWinnersFailure = createAction(
  '[Landing] Winners load winning tickets failure',
  props<{ error: any }>()
);

export const loadCasinoWinners = createAction(
  '[Landing] Winners load casino winners'
);

export const loadCasinoWinnersSuccess = createAction(
  '[Landing] Winners load casino winners success',
  props<{ wins: CasinoWinner }>()
);

export const loadCasinoWinnersFailure = createAction(
  '[Landing] Winners load casino winners failure',
  props<{ error: any }>()
);
