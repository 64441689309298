import { DoxxDocument } from '@models/Documents';
import { createReducer, on } from '@ngrx/store';
import * as DocumentActions from './document.actions';

export const documentFeatureKey = 'document';

export interface State {
  loaded: boolean;
  loading: boolean;
  documents: DoxxDocument[];
}

export const initialState: State = {
  loading: false,
  loaded: false,
  documents: []
};

export const reducer = createReducer(
  initialState,

  on(DocumentActions.loadDocuments, state => ({ ...state, loading: true })),
  on(DocumentActions.loadDocumentsSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    documents: action.data
  })),
  on(DocumentActions.loadDocumentsFailure, (state, action) => ({
    ...state,
    loading: false,
    loaded: true
  }))
);
