import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import {
  filter,
  map,
  mapTo,
  shareReplay,
  startWith,
  tap
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppVisibilityService {
  private _hidden: string;
  private _hiddenAt: number;

  get hidden(): boolean {
    return document[this._hidden];
  }

  get hiddenTime(): number {
    return this._hiddenAt ? Date.now() - this._hiddenAt : 0;
  }

  private _visibilityChange: string;
  visibilityChange: Observable<boolean>;
  visibilityChangeHide: Observable<void>;
  /** emit ms delay after window was again visible */
  visibilityChangeReveal: Observable<number>;

  constructor() {
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      this._hidden = 'hidden';
      this._visibilityChange = 'visibilitychange';
    } else if (typeof (document as any).msHidden !== 'undefined') {
      this._hidden = 'msHidden';
      this._visibilityChange = 'msvisibilitychange';
    } else if (typeof (document as any).webkitHidden !== 'undefined') {
      this._hidden = 'webkitHidden';
      this._visibilityChange = 'webkitvisibilitychange';
    }

    this.visibilityChange = fromEvent(document, this._visibilityChange).pipe(
      map(event => this.hidden),
      tap(hidden => {
        if (hidden) {
          this._hiddenAt = Date.now();
        }
      })
    );

    this.visibilityChangeHide = this.visibilityChange.pipe(
      filter(hidden => hidden === true),
      mapTo(void 0)
    );

    this.visibilityChangeReveal = this.visibilityChange.pipe(
      filter(hidden => hidden === false),
      map(() => (this._hiddenAt ? Date.now() - this._hiddenAt : 0)),
      tap(() => {
        this._hiddenAt = null;
      }),
      startWith(0),
      shareReplay(1)
    );
  }
}
