import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'doxx-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
  constructor(private _modalCtrl: ModalController) {}

  // deelay in ms
  @Input() delay;
  closeLoadingSubscripton: Subscription;
  /**
   * Close modal after interval runnig out
   */
  ngOnInit(): void {
    this.closeLoadingSubscripton = interval(1000).subscribe(() => {
      this.delay -= 1000;
      if (this.delay < 1000) {
        this._modalCtrl.dismiss();
      }
    });
  }

  /** clear subscription on close */
  ngOnDestroy(): void {
    this.closeLoadingSubscripton?.unsubscribe();
  }
}
