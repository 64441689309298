import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { oneLine } from '@core/functions/oneLine';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { Credit } from '@models/Credit';
import { map } from 'rxjs/operators';
import { configuration } from '@configuration';
import { addLeadingZero } from '@core/functions/addLeadingZero';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  constructor(private _httpClient: HttpClient) {}

  /**
   * Loads available virtual games
   */
  fetchWalletMethods(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/payment/deposit/method`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * ferch bank accounts
   */
  fetchBankAccount(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/payment/bankAccount`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * ferch bank accounts  - central payment
   */
  fetchBankAccounts(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/central/payment/bank/accounts`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * Search banks
   */
  searchBank(searchText: string): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/central/payment/client/bank/search/${searchText}`,
      {
        withCredentials: true
      }
    );
    // .pipe(map(result => JSON.parse(result)));
  }

  /**
   * BVN value
   */
  getBVN(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/central/payment/client/bvn`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * BVN status
   */
  statusBVN(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/central/payment/client/bvn/status`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * Update status
   */
  updateBVN(bvnnumber): Observable<any> {
    return this._httpClient.put<any>(
      oneLine`${environment.apiUrl}/central/payment/client/bvn`,
      {
        bvn: bvnnumber
      },
      {
        withCredentials: true
      }
    );
  }

  /**
   * Fetch cards
   */
  fetchCards(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/api/v1/client/creditCard/list`,
      {
        withCredentials: true
      }
    );
    // .pipe(map(data => data.data.fileUpload.creditCards));
  }

  /**
   * fetch withdraw
   */
  fetchWithdraw(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/payment/withdrawal/method`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * fetch WithdrawableAmoutWithoutFeeFor
   */
  fetchWithdrawFee(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/payment/withdrawal/amount/fee/free`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * fetch trans
   */
  fetchTrans(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/payment/withdrawal/check/unprocessed/trans`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * fetch trans
   */
  cancelTrans(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/payment/withdrawal/cancel/unprocessed/trans`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * fetch history
   * //money/history/{type}?isFilter={isFilter}&from=YYYY-MM-DD&to=YYYY-MM-DD
   */
  fetchHistory(type: string, from: string, to: string): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/bp/money/history/${type}?lang=${environment.language}&from=${from}&to=${to}`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * fetch history
   * //money/history/{type}?isFilter={isFilter}&from=YYYY-MM-DD&to=YYYY-MM-DD
   */
  fetchHistoryAll(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/bp/money/history/all`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * send sportbox code for deposit
   */
  sendSportboxCode(code: string): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/bp/credit/deposit/create/${code}`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * send sportbox code for deposit
   */
  transferToOnlinePercentageFee(code: string): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/responsive/web/bp/client/transferToOnlinePercentageFee/guid/${code}`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * create credit
   */
  createCredit(amount): Observable<Credit> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/centralticket/credit/create/` + amount,
        {
          withCredentials: true
        }
      )
      .pipe(
        map(data => ({
          amount: Math.abs(+data.Data.Credit.Amount).toString(),
          code: data.Data.Credit.Guid,
          currency: configuration.base.defaultCurrency.code,
          date: new Date().toISOString(),
          status: 'unused'
        }))
      );
  }

  /**
   * fetch credit withdrawal
   */
  fetchWithdrawCreditsHistory(): Observable<Credit[]> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/client/credits/history/withdrawal`,
        {
          withCredentials: true
        }
      )
      .pipe(
        map(resp =>
          resp.data.creditHistory.withdrawal.map(credit => ({
            ...credit,
            date: credit.date.replace(
              /(\d*).(\d*).(\d*), (\d*):(\d*)/,
              (date, p1, p2, p3, p4, p5) => {
                return oneLine`
                ${p3}-${addLeadingZero(p2)}-
                ${addLeadingZero(p1)}T
                ${addLeadingZero(p4)}:${addLeadingZero(p5)}:00`;
              }
            )
          }))
        )
      );
  }

  /**
   * post create withdrwall modal
   * @param amount amount
   * @param type type
   * @param bankAccountId bankAccountId
   * @param password password
   */
  public createWithdrwal(
    amount: string,
    type: string,
    bankAccountId: string,
    password: string,
    feeAmount?: number
  ): Observable<any> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/web/payment/withdrawal/create`,
      {
        amount,
        type,
        bankAccountId,
        password,
        feeAmount
      },
      {
        withCredentials: true
      }
    );
  }

  /**
   * edit bank account
   * @param id id
   * @param iban iban
   * @param password password
   */
  public editAccount(
    id: string,
    iban: string,
    password: string
  ): Observable<any> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/web/payment/bankAccount/domestic/edit`,
      {
        id,
        iban,
        password
      },
      {
        withCredentials: true
      }
    );
  }

  /**
   * edit BVN bank account
   * @param id id
   * @param iban iban
   * @param password password
   */
  public editBVNAccount(
    bankAccNumber: string,
    bankAccID: string,
    bankID: number,
    bvnNumber: string,
    pass: string
  ): Observable<any> {
    return this._httpClient.put<any>(
      oneLine`${environment.apiUrl}/central/payment/bank/accounts/bvn`,
      {
        bankAccountNumber: bankAccNumber,
        bankAccountId: parseInt(bankAccID, 10),
        bankId: bankID, //???????
        bvn: bvnNumber,
        password: pass
      },
      {
        withCredentials: true
      }
    );
  }

  /**
   * delet bank account
   * @param id id
   */
  public deleteAccount(id: string): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/payment/bankAccount/${id}`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * add bank account
   * @param form "iban":string,"bankName":string,"bankAddress":string,"swiftCode":string,"password":string
   * @param requestType foreign, domestic
   */
  public addBankAccount(form: any, requestType: string): Observable<any> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/web/payment/bankAccount/${requestType}`,
      form,
      {
        withCredentials: true
      }
    );
  }

  /**
   * add bank account with BVN
   * @param form "bankId":number,"bankAccountNumber":string,"bvn":string,"password":string
   */
  public addBankBVNAccount(form: any): Observable<any> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/central/payment/bank/accounts/bvn`,
      {
        bankAccountNumber: form.iban,
        bankId: form.bank,
        bvn: form.bvn,
        password: form.password
      },
      {
        withCredentials: true
      }
    );
  }

  /**
   * Activate or deactivate account
   * @param requestType activate deactivate
   * @param id account.id
   */
  public activateAccount(requestType: string, id: string): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/payment/bankAccount/${requestType}/${id}`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * create payment
   * @param amount amount
   * @param type type
   */
  public createPayment(amount: string, type: string): Observable<any> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/web/payment/deposit/create`,
      {
        amount,
        type
      },
      {
        withCredentials: true
      }
    );
  }

  /**
   * Activate or deactivate account
   */
  public getBalance(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/client/balance`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * volanie na odregistrovanie karty
   * @cardNumber je prave to číslo karty z prveho volania
   * /payment/unregisterComfortpay/cardNumber/
   */
  public unregisterComfortpay(cardNumber: string): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.apiUrl}/web/payment/unregisterComfortpay/cardNumber/${cardNumber}`,
      {},
      {
        withCredentials: true
      }
    );
  }
}
