import { createAction, props } from '@ngrx/store';
import { Bonus, MyBonus } from '@models/Bonus';
import { LoyaltyBonusState, CodeBonus, FreeSpin } from '..';

export const loadBettingBonusesOnce = createAction(
  '[Bonuses] Load Betting Bonuses'
);

export const loadBettingBonusesSuccess = createAction(
  '[Bonuses] Load Betting Bonuses Success',
  props<{ bonuses: Bonus[] }>()
);

export const loadBettingBonusesFailure = createAction(
  '[Bonuses] Load Betting Bonuses Failure'
);

export const loadMyBettingBonuses = createAction(
  '[Bonuses] Load My Betting Bonuses'
);

export const loadMyBettingBonusesSuccess = createAction(
  '[Bonuses] Load My Betting Bonuses Success',
  props<{ bonuses: MyBonus[] }>()
);

export const loadMyBettingBonusesFailure = createAction(
  '[Bonuses] Load My Betting Bonuses Failure'
);

export const loadCasinoBonusesOnce = createAction(
  '[Bonuses] Load Casino Bonuses'
);

export const loadCasinoBonusesSuccess = createAction(
  '[Bonuses] Load Casino Bonuses Success',
  props<{ bonuses: Bonus[] }>()
);

export const loadCasinoBonusesFailure = createAction(
  '[Bonuses] Load Casino Bonuses Failure'
);

export const loadFreebets = createAction('[Bonuses] Load Freebets');

export const loadFreebetsSuccess = createAction(
  '[Bonuses] Load Freebets Success',
  props<{ freeBets: any }>()
);

export const loadScoutSummary = createAction('[User] Load Scout State');

export const setScoutSummary = createAction(
  '[User] Set Scout Summary',
  props<{
    scoutBonusSummary: {
      profit: any;
      summary: any;
    };
  }>()
);

export const loadLoyalityPoints = createAction(
  '[Bonuses] Load Loyality Points'
);

export const loadLoyalityPointsSuccess = createAction(
  '[Bonuses] Load Loyality Points Success',
  props<{ points: LoyaltyBonusState }>()
);

export const changeLoyalityPointsToMoney = createAction(
  '[Bonuses] Change Loyalty Points To Money'
);
export const changeLoyalityPointsToMoneySuccess = createAction(
  '[Bonuses] Change Loyalty Points To Money Success'
);
export const changeLoyalityPointsToMoneyFailure = createAction(
  '[Bonuses] Change Loyalty Points To Money Failure'
);

export const loadCodeBonusHistory = createAction(
  '[Bonuses] Load Code Bonus History'
);

export const loadCodeBonusHistorySuccess = createAction(
  '[Bonuses] Load Code Bonus History Success',
  props<{ codeBonuses: CodeBonus[] }>()
);

// FREE SPINS
export const loadFreespins = createAction(
  '[Bonuses] Load Freespins',
  props<{ freespinsType: 'history' | 'active' }>()
);

export const loadFreespinsSuccess = createAction(
  '[Bonuses] Load Freespins Success',
  props<{ freespinsType: 'history' | 'active'; freespins: FreeSpin[] }>()
);

export const loadFreespinsFailure = createAction(
  '[Bonuses] Load Freespins Failure',
  props<{ freespinsType: 'history' | 'active' }>()
);
