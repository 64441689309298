import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractTeamName'
})
export class ExtractTeamNamePipe implements PipeTransform {
  /**
   * Returns name of team from event name;
   * If event has juts one team, away team is undefined
   * If you do not type which team you want, function returns names of team in array
   */
  transform(eventName?: string, team?: 'home' | 'away'): string | string[] {
    if (eventName) {
      const [home, away] = eventName.split('vs.');
      if (team) {
        return team === 'home' ? home : away;
      } else {
        return eventName.split('vs.');
      }
    } else {
      return '';
    }
  }
}
