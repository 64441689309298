import { NgModule } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SeoService, SEO_TEXT } from './seo.service';

@NgModule({
  providers: [
    {
      provide: SEO_TEXT,
      useValue: new BehaviorSubject(null)
    },
    SeoService
  ]
})
export class SeoModule {
  constructor(private _seoService: SeoService) {}
}
