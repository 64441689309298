import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'casinoTransaction'
})
export class CasinoTransactionPipe implements PipeTransform {
  constructor(private _translateService: TranslateService) {}
  /**
   * Turn dictionary to array from its values
   */
  // transform(dictionary: Record<any, any>): any[] {
  //   if (dictionary) {
  //     return Object.values(dictionary);
  //   }
  // }

  // eslint-disable-next-line
  transform(transaction) {
    if (transaction) {
      return this._translateService.instant(
        'casino_transaction_' + transaction
      );
    } else {
      return '-';
    }

    // switch (transaction) {

    //       case 'win_casino':

    //       return this._translateService
    //       .instant('errors_ticket_error_ERR_MINBET');

    //       case 'bet_casino':

    //       return this._translateService
    //       .instant('errors_ticket_error_ERR_MINBET');

    //       case 'bet_casino_free':

    //       return 'Otočenie zdarma';

    //       case 'bet_casino_storno':

    //       return 'Storno stávky';

    //       case 'win_casino_storno':

    //       return 'Storno výhry';

    //       case 'bonus_casino':

    //       return 'Bonus';

    //       case 'refund_casino':

    //       return 'Úhrada';

    //       case 'bet_casino_freegame':

    //       return 'Stávka na bonusovú hru';

    //       case 'bet_casino_freegame_fake':

    //       return 'Sumár bonusovej hry';

    //       case 'win_casino_freegame':

    //       return 'Výhra z bonusovej hry';

    //       case 'transfer_funds_casino':

    //       return 'Prevod peňazí';

    //       case 'win_casino_free_bonus':

    //       return 'Výhra z freespinu (nepripísaná)';

    //       case 'bet_casino_free_bonus':

    //       return 'Výhra z freespinu';

    //       case 'bonus_casino_code':

    //       return 'Kódový bonus';

    //       case 'bonus_casino_loyalty':

    //       return 'Vernostný bonus';

    //       default:

    //       return '-';

    //     }
  }
}
