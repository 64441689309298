import { Injectable } from '@angular/core';
@Injectable()
export class TimezoneService {
  private _usersTimezone: string;

  set timezone(timezone: string) {
    this._usersTimezone = timezone;
  }

  get timezone(): string {
    return this._usersTimezone;
  }
}
