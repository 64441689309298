import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromBonuses from './bonuses.reducer';
import { EffectsModule } from '@ngrx/effects';
import { BonusesEffects } from './bonuses.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromBonuses.bonusesFeatureKey, fromBonuses.reducer),
    EffectsModule.forFeature([BonusesEffects])
  ]
})
export class BonusesStoreModule {}
