import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild
} from '@angular/core';
import { translateRoute } from '@core/functions/translateRoute';
import { IonBackButton } from '@ionic/angular';

@Component({
  template: `
    <ion-back-button
      [disabled]="disabled"
      mode="md"
      text=""
      icon="assets/icon/arrow-left.svg"
      [defaultHref]="defaultHref"
      [style]="style"
    >
    </ion-back-button>
  `,
  selector: 'doxx-main-header-back-button'
})
export class MainHeaderBackButtonComponent {
  @Input() defaultHref = translateRoute('Landing');
  @Input() disabled = false;
  /**
   * used to make disabled button same color as no disabled,
   * when you want button to act diferently then back button
   * 
   * style = "opacity: 1";
   */
  @Input() style = "";
}
