import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketdetailComponent } from './ticketdetail.component';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { IonicModule } from '@ionic/angular';
import { BuybackModule } from '../buyback/buyback.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { RouterModule } from '@angular/router';
import { DoxxDirectivesModule } from '@shared/directives/doxx-directives.module';

@NgModule({
  declarations: [TicketdetailComponent],
  imports: [
    CommonModule,
    DoxxPipesModule,
    IonicModule,
    BuybackModule,
    UtilsModule,
    RouterModule,
    DoxxDirectivesModule
  ],
  exports: [TicketdetailComponent]
})
export class TicketdetailModule {}
