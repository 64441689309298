import { combineReducers } from '@ngrx/store';

import * as fromLandingTopBetting from './landing-top-betting/landing-top-betting.reducer';
import * as fromLandingTopLeagues from './landing-top-leagues/landing-top-leagues.reducer';
import * as fromLandingBaner from './landing-banner/landing-banner.reducer';
import * as fromLandingVirtualGames from './landing-virtual-games/landing-virtual-games.reducer';
import * as fromLandingMyAccount from './landing-my-account/landing-my-account.reducer';
import * as fromLangingWinners from './landing-winners/landing-winners.reducer';
import * as fromLangingAnnouncement from './landing-announcement/landing-announcement.reducer';

export const LANDING_FEATURE_KEY = 'landing';

export interface State {
  banners: fromLandingBaner.State;
  topBetting: fromLandingTopBetting.State;
  topLeagues: fromLandingTopLeagues.State;
  virtualGames: fromLandingVirtualGames.State;
  myAccount: fromLandingMyAccount.State;
  winners: fromLangingWinners.State;
  announcement: fromLangingAnnouncement.State;
}

export const reducer = combineReducers<State>({
  banners: fromLandingBaner.bettingBannersReducer,
  topBetting: fromLandingTopBetting.topBettingReducer,
  topLeagues: fromLandingTopLeagues.topLeaguesReducer,
  virtualGames: fromLandingVirtualGames.landingVirtualGamesReducer,
  myAccount: fromLandingMyAccount.myAccountReducer,
  winners: fromLangingWinners.winnersReducer,
  announcement: fromLangingAnnouncement.announcementReducer
});
