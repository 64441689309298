export interface ParsedScore {
  home: TeamScore;
  away: TeamScore;
  overtime: boolean;
  penalty: boolean;
  winners?: string[];
}

interface TeamScore {
  points: number;
  game: number | string;
  partials: number[];
  serving: boolean;
  yellowCards?: number;
  redCards?: number;
  overtimePoints: number[];
  penaltyPoints: number;
  corners?: number;
}

/**
 * Pares score
 */
export function parseScore(score: string, sportID: number): ParsedScore {
  const parsedScore: ParsedScore = {
    home: {
      points: null,
      game: null,
      partials: [],
      overtimePoints: null,
      serving: false,
      penaltyPoints: null
    },
    away: {
      points: null,
      game: null,
      partials: [],
      overtimePoints: null,
      serving: false,
      penaltyPoints: null
    },
    overtime: false,
    penalty: false,
    winners: []
  };

  if (!score || score === '-1') {
    return undefined;
  }

  if (sportID === 153 && score?.includes('res_order')) {
    parsedScore.winners[0] = score.split(',')[0].split('=')[1];
    parsedScore.winners[1] = score.split(',')[1].split('=')[1];
    parsedScore.winners[2] = score.split(',')[2].split('=')[1];
    return parsedScore;
  }

  // Remove spaces
  score = score.toString().replace(/\s/g, '');

  // Serve
  if (score.match('H')) {
    parsedScore.home.serving = true;
  }

  if (score.match('A')) {
    parsedScore.away.serving = true;
  }

  // Yellow Cards
  const yellowCards = score.match(/Y\((\w*):(\w*)\)/);
  if (yellowCards) {
    parsedScore.home.yellowCards = +yellowCards[1];
    parsedScore.away.yellowCards = +yellowCards[2];
  }

  // Red Cards
  const redCards = score.match(/R\((\w*):(\w*)\)/);
  if (redCards) {
    parsedScore.home.redCards = +redCards[1];
    parsedScore.away.redCards = +redCards[2];
  }

  // corners
  const corners = score.match(/C\((\w*):(\w*)\)/);
  if (corners) {
    parsedScore.home.corners = +corners[1];
    parsedScore.away.corners = +corners[2];
  }

  // overtime
  if (score.match('overtime')) {
    // possible strings
    // (overtime_1=1:1, overtime_2=0:0)
    // (overtime_1=1:1)
    // (overtime=1:1)
    // match all ovetimes
    parsedScore.home.overtimePoints = [];
    parsedScore.away.overtimePoints = [];
    parsedScore.overtime = true;
    const regExp = /(overtime.{0,2}?=(\w*):(\w*))/g;
    let match: any[] = regExp.exec(score);
    while (match !== null) {
      parsedScore.home.overtimePoints.push(+match[2]);
      parsedScore.away.overtimePoints.push(+match[3]);
      match = regExp.exec(score);
    }
  }

  // penalty
  const penalties = score.match(/penalties=(\w*):(\w*)/);
  if (penalties) {
    parsedScore.penalty = true;
    parsedScore.home.penaltyPoints = +penalties[1];
    parsedScore.away.penaltyPoints = +penalties[2];
  }

  // Total Score
  const totalScore = score.match(/restot=(\d*):(\d*)/);
  if (totalScore) {
    parsedScore.home.points = +totalScore[1];
    parsedScore.away.points = +totalScore[2];
  }

  // Partial scores
  const partialScore = score.match(/\[.+\]/);
  if (partialScore) {
    let partials: any = partialScore[0];

    const gamescore = partials.match(/{gamescore=(\w*):(\w*)}/);
    if (gamescore) {
      parsedScore.home.game = gamescore[1].replace(
        '50',
        sportID === 58 ? 'A' : '50'
      );
      parsedScore.away.game = gamescore[2].replace(
        '50',
        sportID === 58 ? 'A' : '50'
      );
    }

    partials = partials.replace(']', '').split('{')[0].split(',');

    partials.forEach(partial => {
      if (/.*=.+:.+/.test(partial)) {
        partial = partial.split('=')[1].split(':');
        parsedScore.home.partials.push(partial[0]);
        parsedScore.away.partials.push(partial[1]);
      }
    });
  }
  return parsedScore;
}
