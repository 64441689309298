import { Injectable } from '@angular/core';
import { WalletService } from '@core/services/wallet/wallet.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateSpecialPipe } from '@shared/pipes/translate.pipe';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PresentMessageService } from 'src/app/services/present-message.service';

interface TransferToOnlinePercentageFeeReturn {
  ErrorCode: number;
  ErrorDescription: string;
  IsSuccessed: boolean;
  ResponseStatus: string;
  Result: number;
}

@Injectable({
  providedIn: 'root'
})
/**
 * used to share common function and data between:
 * - sportbox-ticket-verify.component.ts
 * - sportboxticket.page.ts
 */
export class BetslipscannerService {
  credit = {
    code: null,
    amount: null
  };

  constructor(
    private _walletService: WalletService,
    private _presentMessage: PresentMessageService,
    private _translateService: TranslateService,
    private _translateSpecial: TranslateSpecialPipe
  ) {}

  /**
   * send sportbox code
   */
  private _sendSportboxCode(code: string): Observable<void> {
    return this._walletService.sendSportboxCode(code).pipe(
      tap(data => this._presentMessage.presentResponse(data.data)),
      catchError(data => {
        throw new Error(data.error.errors.payment.message);
      })
    );
  }

  /**
   * display notice about sportbox deposit fee
   */
  private _submitSportboxFee(percentage: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._presentMessage
        .presentCustomMessage(
          `<p>${
            (
              this._translateSpecial.transform(
                'deposit_sb_confirm_1',
                percentage
              ) as any
            ).changingThisBreaksApplicationSecurity
          }
          </p>
          <p>${this._translateService.instant('deposit_sb_confirm_2')}
          </p>`,
          'info-alert',
          this._translateService.instant('deposit_sb_confirm_title'),
          [
            {
              text: this._translateService.instant('deposit_sb_confirm_submit'),
              id: 'confirm-button',
              cssClass: 'primary-btn expand-btn',
              handler: () => ({
                confirm: true
              })
            },
            {
              text: this._translateService.instant('deposit_sb_confirm_cancel'),
              role: 'cancel',
              id: 'cancel-button',
              cssClass: 'expand-btn'
            }
          ]
        )
        .then(alert => {
          alert.onDidDismiss().then(data => {
            if (data.data?.confirm === true) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
        });
    });
  }

  /**
   * used to make deposit by sportbox code
   */
  submitBetslipDeposit(code: string): Promise<void> {
    return new Promise(resolve => {
      this._walletService
        .transferToOnlinePercentageFee(code)
        .pipe(
          map(data => {
            if (data.IsSuccessed) {
              return data;
            } else {
              throw new Error(data.ErrorDescription);
            }
          }),
          // get confirmation for fee
          switchMap(data =>
            data.Result ? from(this._submitSportboxFee(data.Result)) : of(true)
          ),
          switchMap(confirmed =>
            confirmed ? this._sendSportboxCode(code) : EMPTY
          )
        )
        .subscribe(resolve, ({ message }) => {
          this._presentMessage.presentCustomMessage(
            message || this._translateService.instant('error_page_title'),
            'error-alert'
          );
        });
    });
  }
}
