import { createAction, props } from '@ngrx/store';
import { IMyAvailableBonus } from '@models/Bonus';

export const loadMyCasionWins = createAction(
  '[Landing] My account casino wins'
);

export const loadMyCasionWinsSuccess = createAction(
  '[Landing] My account casino wins success',
  props<{ wins: any[] }>()
);

export const loadMyCasionWinsFailure = createAction(
  '[Landing] My account casino wins failure',
  props<{ error: any }>()
);

export const loadBonusesStatus = createAction(
  '[Landing] My account load bonuses status'
);

export const loadBonusesStatusSuccess = createAction(
  '[Landing] My account load bonuses status success',
  props<{ hasActiveBonuses: boolean }>()
);

export const loadBonusesStatusFailure = createAction(
  '[Landing] My account load bonuses status failure',
  props<{ error: any }>()
);
