import { NgModule } from '@angular/core';
import { EnvPipe } from './env.pipe';
import { TranslateRoutePipe } from './translate-route.pipe';
import { ReplacePipe } from './replace.pipe';
import { SlugifyPipe } from './slugify.pipe';
import { ExtractTeamNamePipe } from './extract-team-name.pipe';
import { AddLeadingZeroPipe } from './add-leading-zero.pipe';
import { IsOddLockedPipe } from './is-odd-locked.pipe';
import { HasMatchTrackerPipe } from './has-matchtracker.pipe';
import { FormatNumberWithCurrencyPipe } from './format-number-with-currency.pipe';
import {
  SportTransformPipe,
  StatusTransformPipe
} from './status-transform.pipe';
import { ValuesPipe } from './values.pipe';
import { HighlightPipe } from './highlight.pipe';
import { IsNumberPipe } from './type-check.pipe';
import { RatePipe } from './rate.pipe';
import { ShowParamGroupWrapperPipe } from './showParamGroupWrapper.pipe';
import { HasVisibleOddsPipe } from './has-visible-odd.pipe';
import { CasinoTransactionPipe } from './casino-transaction-type.pipe';
import { IsBlockedPipe } from './is-blocked.pipe';
import { TrackByPropertyPipe } from './track-by-property.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { TranslatePipe, TranslateSpecialPipe } from './translate.pipe';
import { IsScreenAllowedPipe } from './is-screen-allowed.pipe';
import { IsProfileEditablePipe } from './is-profile-editable.pipe';
import { IsScreenOnlyForLoggedPipe } from './is-screen-only-for-logged.pipe';
import { SafePipe, SafePipeModule } from 'safe-pipe';
import { IsFavEventPipe } from './is-fav-event.pipe';
import { IsFavSportPipe } from './is-fav-sport.pipe';
import { IsFavLeaguePipe } from './is-fav-league.pipe';
import { IsFavChanceTypePipe } from './is-fav-chance-type.pipe';
import { eventsCountPipe } from './eventsCounts.pipe';
import { GetSectionPipe } from './get-section.pipe';
import { RandomPipe } from './random.pipe';
import { FormatNumberPipe } from './format-number.pipe';
import { GuidPipe } from './guid.pipe';
import { HasPropertyPipe } from './has-property.pipe';
import { FormatNumberFractionsPipe } from './format-number-fractions.pipe';
@NgModule({
  declarations: [
    EnvPipe,
    TranslateRoutePipe,
    ReplacePipe,
    SlugifyPipe,
    ExtractTeamNamePipe,
    AddLeadingZeroPipe,
    HasMatchTrackerPipe,
    IsOddLockedPipe,
    FormatNumberWithCurrencyPipe,
    StatusTransformPipe,
    ValuesPipe,
    HighlightPipe,
    IsNumberPipe,
    RatePipe,
    ShowParamGroupWrapperPipe,
    HasVisibleOddsPipe,
    IsNumberPipe,
    CasinoTransactionPipe,
    IsBlockedPipe,
    SportTransformPipe,
    TrackByPropertyPipe,
    FormatDatePipe,
    TranslatePipe,
    TranslateSpecialPipe,
    IsScreenAllowedPipe,
    IsProfileEditablePipe,
    IsScreenOnlyForLoggedPipe,
    IsFavEventPipe,
    IsFavSportPipe,
    IsFavLeaguePipe,
    IsFavChanceTypePipe,
    eventsCountPipe,
    GetSectionPipe,
    RandomPipe,
    FormatNumberPipe,
    GuidPipe,
    HasPropertyPipe,
    FormatNumberFractionsPipe
  ],
  imports: [SafePipeModule],
  exports: [
    EnvPipe,
    TranslateRoutePipe,
    ReplacePipe,
    SlugifyPipe,
    FormatNumberWithCurrencyPipe,
    ExtractTeamNamePipe,
    AddLeadingZeroPipe,
    HasMatchTrackerPipe,
    IsOddLockedPipe,
    StatusTransformPipe,
    ValuesPipe,
    HighlightPipe,
    IsNumberPipe,
    RatePipe,
    ShowParamGroupWrapperPipe,
    HasVisibleOddsPipe,
    IsNumberPipe,
    CasinoTransactionPipe,
    IsBlockedPipe,
    SportTransformPipe,
    TrackByPropertyPipe,
    FormatDatePipe,
    TranslatePipe,
    TranslateSpecialPipe,
    IsScreenAllowedPipe,
    IsProfileEditablePipe,
    IsScreenOnlyForLoggedPipe,
    SafePipe,
    IsFavEventPipe,
    IsFavSportPipe,
    IsFavLeaguePipe,
    IsFavChanceTypePipe,
    eventsCountPipe,
    GetSectionPipe,
    RandomPipe,
    FormatNumberPipe,
    GuidPipe,
    HasPropertyPipe,
    FormatNumberFractionsPipe
  ],
  providers: [SportTransformPipe]
})
export class DoxxPipesModule {}
