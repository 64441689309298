<ion-card>
  <div class="event">
    <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
    <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
  </div>
  <ng-container *ngIf="oddType === 'NAMING'">
    <div class="odds">
      <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
    </div>
    <div class="odds">
      <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
    </div>
  </ng-container>
  <ng-container *ngIf="oddType === 'NUMBERING'">
    <div class="odds">
      <ion-skeleton-text animated style="width: 10%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 10%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 10%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 10%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 10%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 10%"></ion-skeleton-text>
    </div>
  </ng-container>
</ion-card>
