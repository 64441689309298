import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { Ticket, TicketOdd } from '@models/Ticket';
import { ticketOddsRateAnimations } from 'src/app/animations';
import { Store } from '@ngrx/store';
import { TicketState } from '../store/ticket.reducer';
import * as ticketSelectors from '../store/ticket.selectors';
import { Subject, Subscription } from 'rxjs';

import { authSelectors } from '@store/selectors';
import { configuration } from '@configuration';
import { TranslateService } from '@ngx-translate/core';
import { ticketActions } from '@store/actions';
import { Actions, ofType } from '@ngrx/effects';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { parseScore } from '@core/functions/parseScore';
import { SignalrService } from 'src/app/services/signalr.service';

@Component({
  selector: 'doxx-ticketodds',
  templateUrl: './ticketodds.component.html',
  styleUrls: ['./ticketodds.component.scss'],
  animations: [...ticketOddsRateAnimations]
})
export class TicketoddsComponent implements OnInit, OnDestroy {
  private _subscription$ = new Subscription();
  oddNaming$ = this._store.select(authSelectors.selectSettings).pipe(
    map(settings =>
      configuration.settings.offer.oddsNaming.editable &&
      ['NUMBERING', 'NAMING'].includes(settings?.setting?.offerViewType?.value)
        ? settings?.setting?.offerViewType?.value
        : configuration.settings.offer.oddsNaming.defaultNamingType
    ),
    distinctUntilChanged()
  );
  oddNaming: 'NUMBERING' | 'NAMING';

  ticket$ = this._store.select(ticketSelectors.selectTicket).pipe(
    map(({ ...ticket }) => {
      ticket = {
        ...ticket,
        Odds: [...ticket.Odds.map(odd => ({ ...odd }))]
      };
      ticket.Odds.forEach((odd: any) => {
        odd.OddNameNaming =
          {
            1: odd.Team1,
            X: this._translareService.instant('app_draw'),
            2: odd.Team2
          }[odd.Tip] || odd.Tip;
        odd.OddNameNumbering = odd.Tip;
        odd._isBuyBack = odd.IsWinning === 'BB';
        odd._removable =
          !odd.IsEditedOdd ||
          (odd.IsEditedOdd &&
            !odd.IsRemoveForbiden &&
            odd.IsWinning !== 'BB' &&
            odd.IsRemoveable);
        odd._buyBackable =
          (odd.IsWinning === 'BB' && !odd.IsEditedEvaluated) ||
          (!odd.IsRemoveForbiden && +odd.BuyBackValue > 0 && !odd.IsRemoveable);
        odd._score = parseScore(odd.SCORENEW, odd.SportID);
        odd._scoreStyle =
          +odd.SportID === 58
            ? 'tenis'
            : odd._score?.home?.serving || odd._score?.away?.serving
            ? 'serving'
            : 'normal';
      });

      return ticket;
    })
  );
  odds: TicketOdd[];
  rates: Record<TicketOdd['OddID'], TicketOdd['Rate']> = {};
  ticket: Ticket;
  selectedOdds: TicketOdd['OddID'][] = [];

  resetSelectedOdds$ = this._actions$.pipe(
    ofType(
      ticketActions.addOddsComplete,
      ticketActions.removeOddsComplete,
      ticketActions.changeTicketTypeComplete,
      ticketActions.oddTagComplete,
      ticketActions.oddUntagComplete,
      ticketActions.oddTopComplete,
      ticketActions.resetTicketComplete
    )
  );

  constructor(
    private _store: Store<TicketState>,
    private _translareService: TranslateService,
    private _actions$: Actions,
    private _signalrService: SignalrService
  ) {}

  @Input() ratexOdds: TicketOdd['OddID'][] = [];
  @Output() removeOdd = new Subject<TicketOdd['OddID']>();

  /**
   * On init ticket odds
   */
  ngOnInit(): void {
    this._subscription$.add(
      this.ticket$.subscribe(ticket => {
        this.odds = ticket.Odds;
        this.ticket = ticket;
        ticket.Odds.forEach(odd => {
          if (this.rates[odd.OddID] !== odd.Rate) {
            this.rates[odd.OddID] = odd.Rate;
          }
        });
      })
    );

    this._subscription$.add(
      this.oddNaming$.subscribe(naming => {
        this.oddNaming = naming;
      })
    );

    this._subscription$.add(
      this._store
        .select(ticketSelectors.selectOdds)
        .pipe(
          map(odds => [
            ...new Set(
              odds
                .filter(
                  odd =>
                    odd.LiveBet ||
                    new Date().minutesDiff(new Date(odd.DATE)) < 30
                )
                .map(odd => odd.EVT)
                .sort()
            )
          ]),
          distinctUntilChanged((a, b) => a.join() === b.join())
        )
        .subscribe(eventIds => {
          if (eventIds.length) {
            this._signalrService.changeViews(['_LIVE'], 'ticket');
          } else {
            this._signalrService.changeViews([], 'ticket');
          }
        })
    );

    this._subscription$.add(
      this.resetSelectedOdds$.subscribe(() => {
        this.selectedOdds = [];
      })
    );
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this._subscription$.unsubscribe();
    this._signalrService.changeViews([], 'ticket');
  }

  /** add/remove odd from selected array */
  toggleOdd(oddID: TicketOdd['OddID']): void {
    if (this.ticket.TYPE === 'SRT') {
      if (this.selectedOdds.includes(oddID)) {
        this.selectedOdds = this.selectedOdds.filter(odd => odd !== oddID);
      } else {
        this.selectedOdds = [...this.selectedOdds, oddID];
      }
    }
  }

  /**
   * Vytori skupinu
   */
  createGroup(): void {
    if (this.selectedOdds.length > 0) {
      this._store.dispatch(ticketActions.oddTag({ odds: this.selectedOdds }));
    }
  }

  /**
   * zrusi odd zo skupiny v rozpisivom tikete
   */
  untagOdd(oddID: number): void {
    this._store.dispatch(ticketActions.oddUntag({ odds: [oddID] }));
  }

  /**
   * zrusi celu skupinu oddov
   * */
  untagGroup(groupId: string) {
    this._store.dispatch(
      ticketActions.oddUntag({
        odds: this.odds
          .filter(odd => odd.Group === groupId)
          .map(odd => odd.OddID)
      })
    );
  }

  /**
   * oznaci tutovky
   */
  topOdd(): void {
    if (this.selectedOdds.length > 0) {
      this._store.dispatch(ticketActions.oddTop({ odds: this.selectedOdds }));
    }
  }

  /** toggle buyback/ reset buyback */
  toggleBuyback(odd: TicketOdd, event: MatSlideToggleChange): void {
    if (event.checked) {
      this._store.dispatch(ticketActions.oddBuyback({ oddID: odd.OddID }));
    } else {
      this._store.dispatch(ticketActions.oddBuybackReset({ oddID: odd.OddID }));
    }
  }
}
