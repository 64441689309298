import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { oneLine } from '@core/functions/oneLine';
import { environment } from '@environment';
import { map } from 'rxjs/operators';
import { ClientWinsAndBets } from '@models/ResponsibleGaming';
import { Settings } from '@models/Settings';
import { NotificationOptions } from '@models/Notifications';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private _httpClient: HttpClient) {}

  /**
   * Fetch personal informations
   * used in client/personalinfo/personal
   */
  public fetchPersonalInfo(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/responsive/web/bp/client/info/personal`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * Updare personal info of user
   * used in client/personalinfo/personal
   * @param form form
   */
  public updatePersonalData(form: any): Observable<any> {
    return this._httpClient
      .post<any>(
        oneLine`${environment.apiUrl}/responsive/web/bp/client/info/personal`,
        form,
        {
          withCredentials: true
        }
      )
      .pipe(
        map(response => {
          if (response.data.action.status === 'nok') {
            throw response;
          } else {
            return response.data;
          }
        })
      );
  }

  /**
   * get blocking info with affiliate params
   * used in client
   * @param form form
   */
  public getAffiliateData(url) {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/web/system/getBlockingInformations` + url,
        {
          withCredentials: true
        }
      )
      .pipe(map(data => data.Result));
  }

  /**
   * Change password
   * used in client/personalinfo/settings
   * @param form form
   */
  public changePassword(form: any): Observable<any> {
    return this._httpClient
      .post<any>(
        oneLine`${environment.apiUrl}/web/bp/client/password/change`,
        form,
        {
          withCredentials: true
        }
      )
      .pipe(
        map(response => {
          if (response.data.action.status === 'nok') {
            throw response;
          } else {
            return response.data;
          }
        })
      );
  }

  /**
   * Change sending emails and time and language
   * used in client/personalinfo/settings
   * @param form form
   */
  public changeSendngEmails(form: any): Observable<any> {
    return this._httpClient
      .post<any>(
        oneLine`${environment.apiUrl}/responsive/web/bp/client/info/setting`,
        form,
        {
          withCredentials: true
        }
      )
      .pipe(
        map(response => {
          if (response.data.action?.status === 'nok') {
            throw response;
          } else {
            return response.data;
          }
        })
      );
  }

  /**
   * fetch client settings
   * used in client/personalinfo/responisblegaming
   */
  public fetchCancelAccountReasonns(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/responsive/web/bp/client/cancel/list`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * fetch client settings
   * used in client/personalinfo/responisblegaming
   */
  public fetchClientLimits(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/casino/getClientLimits`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * fetch client settings
   * used in client/personalinfo/responisblegaming
   */
  public fetchClientCasinoWinsAndBet(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/casino/clientWinsAndBets`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * fetch client settings
   * used in client/personalinfo/responisblegaming
   */
  public fetchClientWinsAndBet(): Observable<ClientWinsAndBets> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/web/client/responsibleGaming/getWinsAndBetSummary`,
        {
          withCredentials: true
        }
      )
      .pipe(map(data => data.Result));
  }

  /**
   * Change sending emails and time and language
   * used in client/personalinfo/responsibleGaming
   * @param form form
   */
  public setGamingLimits(
    BetLimitRequest: string,
    LossLimitRequest: string
  ): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/casino/setClientLimits/BetLimitRequest/${BetLimitRequest}/LossLimitRequest/${LossLimitRequest}`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * Cancel account
   * used in client/personalinfo/responisbleGaming
   * used in client/personalinfo/responsibleBetting
   * @param form form
   */
  public cancelAccount(form: any): Observable<any> {
    return this._httpClient
      .post<any>(
        oneLine`${environment.apiUrl}/responsive/web/bp/client/cancel/reason`,
        form,
        {
          withCredentials: true
        }
      )
      .pipe(
        map(response => {
          if (response.data.action.status === 'nok') {
            throw response;
          } else {
            return response.data;
          }
        })
      );
  }

  /**
   * fetch client settings
   */
  public fetchBettingLimits(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/bp/client/getLimits`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * Change betting limits
   * used in client/personalinfo/responsibleBetting
   * @param form form
   */
  public changeBettingLimits(
    betLimitRequest: number,
    lossLimitRequest: number
  ): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/bp/client/setLimits/BetLimitRequest/${betLimitRequest}/LossLimitRequest/${lossLimitRequest}`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * Get number of uploaded documents
   */
  public getWaitingDocumetsCount(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/api/v1/client/verification/waitingDocumentCount`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * Get bank accounts
   * used in client/personalinfo/verification/bankAccount
   */
  public getBankAccounts(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/payment/bankAccount`,
      // oneLine`${environment.apiUrl}/web/api/v1/client/verification/settings`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * Get status of bank accounts
   */
  public getBankAccoVerificationStatus(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/central/payment/bank/accounts/verification/pending/status`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * Verify bank account
   * used in client/personalinfo/verification/bankAccount
   */
  public verifyBankAccount(id: number): Observable<any> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/central/payment/bank/accounts/verification`,
      { bankAccountId: id }
    );
  }

  /**
   * Get creditCards
   */
  public getCreditCards(): Observable<any> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/web/api/v1/client/creditCard/list`,
        {
          withCredentials: true
        }
      )
      .pipe(map(data => data.data.fileUpload.creditCards));
  }

  /**
   * Get player account verification
   * used in client/personalinfo/verification/playeraccount
   */
  public getPlayerAccount(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/api/v1/client/verification/settings`,
      {
        withCredentials: true
      }
    );
  }

  /** Odosle sms s verifikacnym kodom na pouzivateove tel. cislo */
  public sendPhoneVerifySMS(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/bp/client/phone/verify`,
      {
        withCredentials: true
      }
    );
  }
  /** Odosle sms s verifikacnym kodom na pouzivateove tel. cislo */
  public verifyPhoneByCode(code: string): Observable<any> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/web/bp/client/phone/verify`,
      { smsCode: code },
      { withCredentials: true }
    );
  }

  /**
   * Change betting limits
   * used in client/personalinfo/responsibleBetting
   * @param picture file
   * @param file_type_id file_type_id
   */
  public submitPicture(
    picture: any,
    fileTypeTd: string,
    queryParams?: any
  ): Observable<any> {
    const formData = new FormData();
    formData.append('file', picture);

    return this._httpClient
      .post<any>(
        oneLine`${environment.apiUrl}/web/api/v1/client/verification/upload/${fileTypeTd}`,
        formData,
        { withCredentials: true, params: queryParams }
      )
      .pipe(
        map(response => {
          if (response.data.action.status === 'nok') {
            throw response;
          } else {
            return response.data;
          }
        })
      );
  }

  /**
   * Change betting limits
   * used in client/personalinfo/responsibleBetting
   * @param picture file
   * @param file_type_id file_type_id
   */
  public submitPictureWithProgress(
    picture: any,
    fileTypeTd: string,
    queryParams?: any
  ): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('file', picture);

    const req = new HttpRequest(
      'POST',
      oneLine`${environment.apiUrl}/web/api/v1/client/verification/upload/${fileTypeTd}`,
      formData,
      {
        params: new HttpParams({ fromObject: queryParams }),
        reportProgress: true,
        responseType: 'json',
        withCredentials: true
      }
    );

    return this._httpClient.request(req);
  }

  /**
   * Upload profile picture
   * used in client/
   * @param picture file
   */
  public uploadProfileImage(picture: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', picture);

    return this._httpClient
      .post<any>(
        oneLine`${environment.apiUrl}/web/client/uploadProfileImage`,
        formData,
        { withCredentials: true }
      )
      .pipe(
        map(response => {
          if (response.ErrorCode) {
            throw response;
          } else {
            return response;
          }
        })
      );
  }

  /**
   * get profile pricutre
   * used in client
   * @param form form
   */
  public getProfileImage(): Observable<any[]> {
    return this._httpClient
      .get<any>(oneLine`${environment.apiUrl}/web/client/getProfileImage`, {
        withCredentials: true
      })
      .pipe(map(data => data.Result));
  }

  /**
   * remove profile pricutre
   * used in client
   * @param form form
   */
  public removeProfileImage(): Observable<any[]> {
    return this._httpClient
      .get<any>(oneLine`${environment.apiUrl}/web/client/removeProfileImage`, {
        withCredentials: true
      })
      .pipe(map(data => data.Result));
  }

  /**
   * Load bonuses status
   * @param form form
   */
  public getBonusesStatus(): Observable<boolean> {
    return this._httpClient
      .get<any>(oneLine`${environment.apiUrl}/central/bonus/status`, {
        withCredentials: true
      })
      .pipe(map(data => data.hasActiveBonuses));
  }

  /**
   * Change sending emails and time and language
   * used in client/personalinfo/responsibleGaming
   * @param form form
   */
  public getAvailableBonuses(): Observable<any[]> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/web/client/availablebonuses`,
        // oneLine`https://portal-client-staging.doxxbet.sk/Client.svc/client/AvalaibleBonuses/ClientID/-1`
        {
          withCredentials: true
        }
      )
      .pipe(map(data => data.Result));
  }

  /**
   *  send verification sms to users phone
   */
  phoneVerify(options: {
    phonePrefix: string;
    phone: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post(
      oneLine`${environment.apiUrl}/responsive/web/bp/client/phone/update/verify`,
      { ...options }
    );
  }

  /**
   * update phone, veriried by SMS code
   */
  phoneUpdate(options: {
    phonePrefix: string;
    phone: string;
    smsCode: string;
  }): Observable<any> {
    return this._httpClient.post(
      oneLine`${environment.apiUrl}/responsive/web/bp/client/phone/update`,
      { ...options }
    );
  }

  /**
   * get user settings
   */
  getSettings(): Observable<Settings> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/responsive/web/bp/client/info/setting`
      )
      .pipe(map(data => data.data.client));
  }

  /**
   * set visibility to user settings
   */
  setCasinoAndBetslipHistoryVisibility(options: {
    showBettingHistory?: boolean;
    showCasinoHistory?: boolean;
  }): Observable<any> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/responsive/web/bp/client/info/setting/history`,
      { ...options }
    );
  }

  /** verify reset password token */
  resetPasswordTokenVerify(options: { token: string }): Observable<any> {
    return this._httpClient.get(
      oneLine`${environment.apiUrl}/web/bp/client/password/reset`,
      {
        params: {
          d: options.token
        }
      }
    );
  }

  /** reset password by token */
  resetPasswordByToken(options: {
    password: string;
    token: string;
  }): Observable<any> {
    return this._httpClient.post(
      oneLine`${environment.apiUrl}/web/bp/client/password/reset`,
      {
        password: options.password
      },
      { params: { token: options.token } }
    );
  }

  /**
   * send sms when resetting password
   */
  resetPasswordSendCode(options: { credentials: string }): Observable<any> {
    return this._httpClient.post(
      oneLine`${environment.apiUrl}/web/bp/client/password/reset/code`,
      {
        credentials: options.credentials
      }
    );
  }
  /**
   * send sms when resetting password
   */
  resetPasswordCodeVerify(options: {
    credentials: string;
    smsCode: string;
  }): Observable<any> {
    return this._httpClient.post(
      oneLine`${environment.apiUrl}/web/bp/client/password/reset/code/verify`,
      { ...options }
    );
  }
  /**
   * send sms when resetting password
   */
  resetPasswordConfirm(options: {
    credentials: string;
    smsCode: string;
    newPassword: string;
  }): Observable<any> {
    return this._httpClient.post(
      oneLine`${environment.apiUrl}/web/bp/client/password/reset/confirm`,
      { ...options }
    );
  }

  /**
   * Change password step 1, send sms code
   *
   * @param form form
   */
  public changePasswordStep1(oldPassword: string): Observable<any> {
    return this._httpClient
      .post<any>(
        oneLine`${environment.apiUrl}/web/bp/client/password/change/code`,
        {
          oldPassword
        },
        {
          withCredentials: true
        }
      )
      .pipe(
        map(response => {
          if (response.data.action.status === 'nok') {
            throw response.errors;
          } else {
            return response.data;
          }
        })
      );
  }

  /**
   * confirm change password - step 2
   *
   * @param form form
   */
  public changePasswordStep2(
    smsCode: string,
    oldPassword: string,
    newPassword: string
  ): Observable<any> {
    return this._httpClient
      .post<any>(
        oneLine`${environment.apiUrl}/web/bp/client/password/change/confirm`,
        {
          smsCode,
          oldPassword,
          newPassword
        },
        {
          withCredentials: true
        }
      )
      .pipe(
        map(response => {
          if (response.data.action.status === 'nok') {
            throw response.errors;
          } else {
            return response.data;
          }
        })
      );
  }

  /** Update user settins for viewing offer */
  public updateOfferSettings(options: {
    viewType: 'NAMING' | 'NUMBERING';
    liveOption: 'OPEN' | 'CLOSED';
    preMatchOption: 'OPEN' | 'CLOSED';
    detailOption: 'OPEN' | 'CLOSED';
    favouriteLeagueStatus: boolean;
    favouriteChanceStatus: boolean;
  }): Observable<any> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/responsive/web/bp/client/info/setting/offer`,
      options
    );
  }

  /** get client notification options */
  getClientNotificationOptions(): Observable<NotificationOptions> {
    return this._httpClient.get<NotificationOptions>(
      `${environment.apiUrl}/client/notification/options`
    );
  }

  /** get client notification options */
  postClientNotificationOptions(
    options: NotificationOptions
  ): Observable<NotificationOptions> {
    return this._httpClient.post<NotificationOptions>(
      `${environment.apiUrl}/client/notification/options`,
      options
    );
  }
}
