import { Component } from '@angular/core';
import { ResponsibleGamingModalService } from '@shared/responsible-gaming-modal/responsible-gaming-modal.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { authSelectors } from '@store/selectors';
import { ActivatedRoute } from '@angular/router';
import { NavigationConfig } from 'src/navigation';
import { configuration } from '@configuration';

/** page name with shared active icon */
const SAME_PAGES: Array<Array<keyof NavigationConfig>> = [
  ['CasinoHome', 'RioHome', 'CasinoFavorites'],
  ['PrematchList', 'EventDetail'],
  ['LiveList', 'EventDetail_Live'],
  ['VirtualGames', 'EventDetail_VirtualGames']
];
@Component({
  selector: 'doxx-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss']
})
export class FooterMenuComponent {
  isUserLoggedIn$: Observable<boolean> = this._store.select(
    authSelectors.selectIsUserLoggedIn
  );

  pageName = this._route.snapshot.data.PageName;

  links = configuration.tabbar.map(tab => ({
    ...tab,
    isActive:
      this.pageName === tab.content.split('?')[0] ||
      SAME_PAGES.some(
        pages =>
          pages.includes(tab.content.split('?')[0] as keyof NavigationConfig) &&
          pages.includes(this.pageName)
      )
  }));

  constructor(
    private _responsibleGamingModalService: ResponsibleGamingModalService,
    private _store: Store,
    private _route: ActivatedRoute
  ) {}

  /** Show responisble gaming modal */
  showResponsibleGamingModal(): void {
    this._responsibleGamingModalService.showDismissableResponsibleGamingModal();
  }
}
