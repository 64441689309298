import { Component, Input } from '@angular/core';
import { Analysis } from '@models/Analysis';

@Component({
  selector: 'doxx-analysis-detail',
  templateUrl: './analysis-detail.component.html',
  styleUrls: ['./analysis-detail.component.scss']
})
export class AnalysisDetailComponent {
  private _analysis: Analysis;
  @Input() set analysis(analysis: Analysis) {
    let totalTipsCount = analysis.Stats.Tip1;
    totalTipsCount += analysis.Stats.TipX;
    totalTipsCount += analysis.Stats.Tip2;
    analysis.Stats.Tip1 = Math.round(
      (analysis.Stats.Tip1 / totalTipsCount) * 100
    );
    analysis.Stats.TipX = Math.round(
      (analysis.Stats.TipX / totalTipsCount) * 100
    );
    analysis.Stats.Tip2 = Math.round(
      (analysis.Stats.Tip2 / totalTipsCount) * 100
    );
    this._analysis = analysis;
  }

  get analysis(): Analysis {
    return this._analysis;
  }
}
