import { Pipe, PipeTransform } from '@angular/core';
import { TicketOdd } from '@models/Ticket';
import { InGroup } from './in-group';

@Pipe({
  name: 'isBottomLine'
})
export class IsBottomLinePipe extends InGroup implements PipeTransform {
  /**
   * used in rozpisovy tiket to display bottom line
   */
  transform(index: number, odds: TicketOdd[]): boolean {
    if (!this.isInGroup(index, odds)) {
      return false;
    }
    const odd = odds[index];
    const nextOdd = odds[index + 1];
    return nextOdd?.Group === odd.Group;
  }
}
