import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromBonuses from './bonuses.reducer';
import { BonusSection } from '..';
import { FreeBetStatus } from '@models/Freebet';

export const selectBonusesState = createFeatureSelector<fromBonuses.State>(
  fromBonuses.bonusesFeatureKey
);

export const selectLoading = createSelector(
  selectBonusesState,
  state => state.loading
);

export const selectBettingBonuses = createSelector(
  selectBonusesState,
  state => state.betting
);

export const selectBettingBonusesLoaded = createSelector(
  selectBonusesState,
  state => state.betting.loaded
);

export const selectCasinoBonuses = createSelector(
  selectBonusesState,
  state => state.casino
);

export const selectCasinoBonusesLoaded = createSelector(
  selectBonusesState,
  state => state.casino.loaded
);

export const selectMyBettingBonuses = createSelector(
  selectBonusesState,
  state => state.mybetting
);

export const selectAvailableBettingBonuses = createSelector(
  selectBettingBonuses,
  betting => betting.bonuses
);

// .filter(bonus =>
//   new Date().isBetween(
//     new Date(bonus.StartDateString),
//     new Date(bonus.EndDateString)
//   )
// )

export const selectAvailableCasinoBonuses = createSelector(
  selectCasinoBonuses,
  casino => casino.bonuses
);

export const selectMyAvailableBettingBonuses = createSelector(
  selectMyBettingBonuses,
  betting =>
    betting.bonuses.filter(bonus =>
      new Date().isBetween(new Date(bonus.startDate), new Date(bonus.endDate))
    )
);

/**
 *
 * @param bonusName use attribute info.param from Bonus type
 */
export const selectBettingBonus = (bonusID: string) =>
  createSelector(
    selectAvailableBettingBonuses,
    bonuses =>
      bonuses.find(bonus => bonus.BonusName === bonusID) ||
      // koli spatnej kompatibilite
      bonuses.find(bonus => bonus.Param.endsWith(bonusID))
  );

export const selectCasinoBonus = (bonusID: string) =>
  createSelector(
    selectAvailableCasinoBonuses,
    bonuses =>
      bonuses.find(bonus => bonus.BonusName === bonusID) ||
      // koli spatnej kompatibilite
      bonuses.find(bonus => bonus.Param.endsWith(bonusID))
  );

export const selectAvailableBonuses = (section: BonusSection) => {
  switch (section) {
    case 'casino':
      return selectAvailableCasinoBonuses;
    case 'betting':
      return selectAvailableBettingBonuses;
  }
};

export const selectAvailableBonusess = section => {
  switch (section) {
    case 'mybonuses':
      return selectMyAvailableBettingBonuses;
  }
};

export const selectBonus = (bonusID: string, section: 'betting' | 'casino') => {
  switch (section) {
    case 'betting':
      return selectBettingBonus(bonusID);
    case 'casino':
      return selectCasinoBonus(bonusID);
  }
};

export const selectAllFreebets = createSelector(
  selectBonusesState,
  state => state.freebets
);

export const selectFreebets = (status: FreeBetStatus) =>
  createSelector(selectBonusesState, state =>
    state.freebets.filter(freebet => freebet.status === status)
  );

export const selectScoutBonusSumary = createSelector(
  selectBonusesState,
  state => state.scout
);

export const selectLoyality = createSelector(
  selectBonusesState,
  state => state.loyality
);

export const selectCodeBonusHistory = createSelector(
  selectBonusesState,
  state => state.codeBonusHistory
);

export const selectActiveFreespins = createSelector(
  selectBonusesState,
  state => state.freespins.active
);

export const selectActiveFreespinLoading = createSelector(
  selectActiveFreespins,
  state => state.loading
);
export const selectActiveFreespinLoaded = createSelector(
  selectActiveFreespins,
  state => state.loaded
);
export const selectActiveFreespinFreespins = createSelector(
  selectActiveFreespins,
  state => state.freespins
);

export const selectFreespinHistory = createSelector(
  selectBonusesState,
  state => state.freespins.history
);

export const selectFreespinHistoryLoading = createSelector(
  selectFreespinHistory,
  state => state.loading
);
export const selectFreespinHistoryLoaded = createSelector(
  selectFreespinHistory,
  state => state.loaded
);
export const selectFreespinHistoryFreespins = createSelector(
  selectFreespinHistory,
  state => state.freespins
);
