import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[spyFocus]', exportAs: 'spyFocus' })
export class SpyFocusDirective {
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _elRef: ElementRef
  ) {}
  get focused() {
    return this._document.activeElement === this._elRef.nativeElement;
  }
}
