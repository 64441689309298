<div class="tab-group-header" [ngClass]="{ hidden: tabs.length <= 1 }">
  <ion-segment
    [(ngModel)]="activeTabIndex"
    (ionChange)="onTabChange()"
    scrollable="true"
  >
    <ion-segment-button
      mode="ios"
      *ngFor="let tab of tabs; let i = index; trackBy: trackTabs"
      [value]="i"
      (click)="preloaded = false"
      [disabled]="tab.disabled"
    >
      <div class="label">
        <span
          class="badge"
          *ngIf="tab.badge?.showBadge && tab.badge.colors.length === 1"
        ></span>
        <ng-container
          *ngIf="tab.badge?.showBadge && tab.badge.colors.length > 1"
        >
          <span class="ht-b"></span>
          <span class="hb-b"></span>
        </ng-container>
        {{ tab.label }}
      </div>
    </ion-segment-button>
  </ion-segment>
  <ng-content select="div[slot=right]"></ng-content>
</div>
<div #tabOutlet class="tab-outlet" [ngClass]="{ preloaded: preloaded }">
  <ng-content select="doxx-tab"></ng-content>
</div>
