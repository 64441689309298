<ion-card doxxInViewport (inViewportChange)="onViewportChange($event)">
  <!-- {{_event.ActualGamePartID}} -->
  <!-- *ngIf="_event.ActualGamePartID != 'ended'" -->
  <!-- *ngIf="!isVirtual || (isVirtual && _event.LiveBettingView !== 1)"  {{ _event.EventChanceTypeStatus }} -->
  <ion-card-content class="ion-no-padding">
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col [size]="12">
          <ion-grid class="ion-no-padding">
            <div class="event-container">
              <div *ngIf="showSportIcon" class="container-sport-icon">
                <i
                  [style.color]="
                    'var(--xx-sport-color-' + _event?.SportID + ')'
                  "
                  class="icon sport-icon-{{ _event?.SportID }}"
                  [title]="_event?.SportName"
                ></i>
              </div>
              <div class="event-container-content">
                <ion-row>
                  <ion-col class="fav-margin">
                    <div class="event-name">
                      <!-- EVENT NAME -->
                      <a routerLink="{{ eventUrl }}">
                        <!-- Event has two team -->
                        <div
                          class="event-name-inner"
                          [ngClass]="{
                            'serving-home': _event?.ParsedScore?.home.serving,
                            'serving-away': _event?.ParsedScore?.away.serving
                          }"
                          *ngIf="
                            (_event?.EventName | extractTeamName).length > 1
                          "
                        >
                          <p class="team-home">
                            {{ _event?.EventName | extractTeamName : 'home' }}
                          </p>
                          <p
                            class="team-vs"
                            *ngIf="!_event.Score || _event.Score === '-1'"
                          >
                            vs
                          </p>
                          <p
                            class="team-vs-points"
                            [ngClass]="{
                              live: _event?.LiveBetting === 'Y',
                              results: section === 'results'
                            }"
                            *ngIf="_event.Score && _event.Score !== '-1'"
                          >
                            {{ _event.ParsedScore?.home.points || 0 }} :
                            {{ _event.ParsedScore?.away.points || 0 }}
                          </p>
                          <p class="team-away">
                            {{ _event?.EventName | extractTeamName : 'away' }}
                          </p>
                        </div>
                        <!-- Event has not team -->
                        <ng-container
                          *ngIf="
                            (_event?.EventName | extractTeamName).length === 1
                          "
                        >
                          <p class="event-name">
                            {{ _event?.EventName }}
                          </p>
                        </ng-container>
                      </a>

                      <span
                        class="container-icon"
                        (click)="showTooltip(_event)"
                        *ngIf="
                          _event?.EventComment ||
                          _event?.EventResultComment ||
                          _event?.AggregateScore ||
                          _event?.SeriesResult
                        "
                      >
                        <i class="icon icon-ic_info-circle-filled"></i>
                      </span>
                    </div>
                  </ion-col>
                  <i
                    class="fav-event-icon icon icon-ic_favourites-outlined"
                    *ngIf="
                      (showEventFav || !isLoggedIn) &&
                      section === 'live' &&
                      (_event | isFavEvent | async) === false
                    "
                    (click)="addEventToFav(_event.EventID)"
                  ></i>
                  <i
                    class="fav-event-icon icon icon-ic_star"
                    *ngIf="
                      (showEventFav || !isLoggedIn) &&
                      section === 'live' &&
                      (_event | isFavEvent | async)
                    "
                    (click)="removeEventFromFav(_event.EventID)"
                  ></i>
                  <span
                    routerLink="{{ eventUrl }}"
                    *ngIf="_event?.LiveBetting !== 'Y' && $any(_event)?.SideBet"
                    class="side-bet"
                    [ngClass]="{
                      'on-ticket': hasOddOnTicket(_event.EventID, eventOdds)
                    }"
                  >
                    + {{ $any(_event)?.SideBet }}
                  </span>
                </ion-row>
                <ion-row>
                  <ion-col
                    class="event-date-time flex ion-justify-content-between ion-align-items-center"
                  >
                    <!-- EVENT TIME -->
                    <!-- PREMATCH RESULTS EVENT -->
                    <ng-container
                      *ngIf="_event?.LiveBetting !== 'Y' && !isVirtual"
                    >
                      <div class="event-date">
                        <span>{{ _event?.EventDate | date : 'dd.MM.' }}</span>
                        <span>{{ _event?.EventDate | date : 'HH:mm' }}</span>
                      </div>
                    </ng-container>

                    <!-- LIVE EVENT -->
                    <ng-container
                      *ngIf="_event?.LiveBetting === 'Y' && !isVirtual"
                    >
                      <div class="live-event-time">
                        <i class="icon icon-ic_live-small"></i>
                        <b
                          class="time-highlighted"
                          *ngIf="_event?.ActualGamePartTime > 0"
                        >
                          {{ _event?.ActualGamePartTime | addLeadingZero }}'
                        </b>
                        <span *ngIf="_event.ActualGamePartID">
                          {{
                            'app_gamepart_' + _event.ActualGamePartID
                              | translate
                          }}
                        </span>
                        <span *ngIf="_event?.ParsedScore?.home.partials.length">
                          <span class="tenis-partialscore">|</span>
                          <span class="tenis-span">
                            {{
                              _event.ParsedScore.home.partials[
                                _event.ParsedScore.home.partials.length - 1
                              ]
                            }}
                            :
                            {{
                              _event.ParsedScore.away.partials[
                                _event.ParsedScore.home.partials.length - 1
                              ]
                            }}
                          </span>
                          <span
                            class="tenis-span bold"
                            *ngIf="
                              _event.ParsedScore.home.game ||
                              _event.ParsedScore.away.game
                            "
                          >
                            ({{ _event.ParsedScore.home.game }} -
                            {{ _event.ParsedScore.away.game }})
                          </span>
                        </span>
                      </div>
                    </ng-container>

                    <!-- VIRTUAL EVENT -->
                    <ng-container
                      *ngIf="isVirtual && _event?.LiveBettingView !== -1"
                    >
                      <div class="virtual-games-event-time">
                        <b>{{ _event?.EventDate | date : 'HH:mm' }}</b>
                      </div>
                    </ng-container>
                    <div class="event-status-icons">
                      <!-- EVENT ICONS ANALYSIS STREAM STATISTICS  -->
                      <i
                        *ngIf="_event.EventAnalyseID && !hasStatistics"
                        class="stats-icon icon icon-ic_analysis"
                        routerLink="{{ eventUrl }}"
                        [queryParams]="{ tab: 'analysis' }"
                      ></i>
                      <i
                        *ngIf="
                          _event?.EventComment || _event?.EventResultComment
                        "
                        (click)="showTooltip(_event)"
                        routerLink="{{ eventUrl }}"
                        [queryParams]="{ tab: 'analysis' }"
                      ></i>
                      <i
                        *ngIf="hasStatistics && !_event.EventAnalyseID"
                        class="stats-icon icon icon-ic_stats"
                        routerLink="{{ eventUrl }}"
                        [queryParams]="{ tab: 'stats' }"
                      ></i>
                      <i
                        *ngIf="hasStatistics && _event.EventAnalyseID"
                        class="stats-icon icon icon-ic_analysis-stats"
                      ></i>
                      <i
                        *ngIf="_event && _event | hasMatchTracker"
                        class="matchtracker-icon icon icon-ic_field"
                        routerLink="{{ eventUrl }}"
                        [queryParams]="{ tab: 'odds', show: 'matchtracker' }"
                      ></i>
                      <i
                        *ngIf="
                          (_event?.Stream || $any(_event)?.ProviderStreamID) &&
                          _event?.LiveBetting === 'Y' &&
                          config.streams.allowed
                        "
                        class="stream-icon icon icon-ic_tv"
                        routerLink="{{ eventUrl }}"
                        [queryParams]="{ tab: 'odds', show: 'stream' }"
                      ></i>
                      <i
                        *ngIf="
                          _event?.LiveBetting === 'N' && _event.BetBuilderID
                        "
                        routerLink="{{ eventUrl }}"
                        [queryParams]="{ tab: 'odds', show: 'betBuilder' }"
                        class="icon icon-ic_bb"
                      ></i>
                    </div>
                  </ion-col>
                </ion-row>
              </div>
            </div>
            <div
              class="odd-container"
              ngClass="{{
                oddType === 'NUMBERING'
                  ? 'cols'
                  : eventOdds.length % 3 === 0
                  ? 'cols-3'
                  : eventOdds.length % 2 === 0
                  ? 'cols-2'
                  : 'cols-1'
              }}"
            >
              <!-- EVENT ODDS -->
              <div
                *ngFor="let odd of eventOdds; trackBy: trackOdd"
                class="odd ion-activatable"
              >
                <doxx-odd
                  *ngIf="odd !== null"
                  [ngClass]="{
                    'top-tip': $any(_event)?.TopTip === odd.TipType
                  }"
                  [odd]="odd"
                  [locked]="odd | isOddLocked : _event : _event"
                  [event]="_event"
                >
                </doxx-odd>
              </div>
            </div>

            <!-- *ngIf="_event.buybackTickets"  -->
            <ng-container
              *doxxBuybackEvent="
                _event.EventID;
                let tickets;
                let hasBuyBack = hasBuyBack
              "
            >
              <ng-container *ngIf="hasBuyBack">
                <ion-row
                  class="buyback-container"
                  [ngClass]="{ expanded: buybackEventsExpanded }"
                  (click)="buybackEventsExpanded = !buybackEventsExpanded"
                >
                  <ion-col class="odd ion-activatable">
                    <span style="color: #72cd21; font-weight: bold">
                      {{ 'ticket_buyback_ticketbuyback' | translate }}
                    </span>
                  </ion-col>
                  <ion-col
                    class="flex ion-justify-content-center ion-align-items-center"
                    size="2"
                  >
                    <i
                      class="icon icon-ic_arrow icon-expand-collapse"
                      [@openCloseArrow]="buybackEventsExpanded"
                    ></i>
                  </ion-col>
                </ion-row>
                <div
                  *ngIf="buybackEventsExpanded"
                  class="px-4 py-2 space-y-4 bg-white mb-2"
                >
                  <!-- {{ _event.buybackTickets }} -->
                  <div
                    class="flex w-full space-x-2 h-9"
                    *ngFor="
                      let ticket of more ? tickets : (tickets | slice : 0 : 3);
                      trackBy: 'TicketCode' | trackByProperty
                    "
                  >
                    <div
                      class="flex border rounded-lg border-[var(--color-buyback)] h-full justify-between overflow-hidden w-full"
                    >
                      <div
                        class="flex-grow h-full pl-2 flex items-center"
                        [routerLink]="
                          'BetslipHistoryDetail'
                            | translateRoute : { ticketID: ticket.TicketCode }
                        "
                      >
                        {{
                          ticket.AmountMoney | formatNumberWithCurrency: { decimalDigits: config.base.defaultCurrency.numberDecimalPlaces}
                        }}&nbsp;/&nbsp;
                        <b class="text-black">
                          {{
                            ticket.AmountMoneyWin | formatNumberWithCurrency: { decimalDigits: config.base.defaultCurrency.numberDecimalPlaces}
                          }}</b
                        >
                      </div>
                      <div
                        class="bg-[var(--color-buyback)] text-white h-full w-2/5 flex items-center justify-center font-bold"
                        [doxxBuybackModalTrigger]="ticket.TicketCode"
                        *ngIf="ticket.BuyBackValue > 0"
                      >
                        {{ ticket.BuyBackValue | formatNumberWithCurrency: { decimalDigits: config.base.defaultCurrency.numberDecimalPlaces} }}
                      </div>
                    </div>
                    <div
                      class="border rounded-lg border-[var(--color-buyback)] text-[var(--color-buyback)] flex justify-center items-center w-9"
                      [ngClass]="{
                        '!text-white bg-[var(--color-buyback)]':
                          ticket.AutoBuyBackValue
                      }"
                    >
                      <i
                        class="icon-ic_gear-arrows-stroke text-2xl"
                        [doxxAutoBuybackModalTrigger]="ticket.TicketCode"
                      ></i>
                    </div>
                  </div>
                  <button
                    *ngIf="tickets.length > 3"
                    (click)="more = !more"
                    class="show-more-button"
                  >
                    {{ more ? 'Zobraziť menej' : 'Zobraziť viac' }}
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
