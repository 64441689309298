import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  /**
   * Find and wraps phrase in text into span with class name highlighted
   * @param text base text
   * @param phrase string to be highlighted
   * @param className class name of <span> element, in which will be wrapped text default is set to `highlighted`
   */
  transform(
    text: string,
    phrase: string,
    className: string = 'highlighted'
  ): SafeHtml {
    if (!phrase) {
      return this._sanitizer.bypassSecurityTrustHtml(text);
    }

    // remove diacritics
    const normalizedText = text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    // find begining index of match
    const firstIndex = normalizedText.search(
      new RegExp('(' + phrase + ')', 'gi')
    );

    // if no match
    if (firstIndex < 0) {
      return this._sanitizer.bypassSecurityTrustHtml(text);
    }

    // get search phrase from text with diacritics
    const phraseWithDiacritics = text.substring(
      firstIndex,
      firstIndex + phrase.length
    );

    // replace
    text = text.replace(
      new RegExp('(' + phraseWithDiacritics + ')', 'gi'),
      `<span class="${className}">$1</span>`
    );

    return this._sanitizer.bypassSecurityTrustHtml(text);
  }
}
