import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ProfileService } from '@core/services/profile/profile.service';
import { ModalController } from '@ionic/angular';
import { ClientWinsAndBets } from '@models/ResponsibleGaming';
import { TranslateSpecialPipe } from '@shared/pipes/translate.pipe';

@Component({
  templateUrl: './responsible-gaming-modal.component.html',
  styleUrls: ['./responsible-gaming-modal.component.scss']
})
export class ResponsibleGamingModalComponent implements OnInit {
  casinoWnsAndBet: ClientWinsAndBets;

  @Input() isDismissable = false;
  error: any;

  constructor(
    private _profileService: ProfileService,
    private _modalCtrl: ModalController,
    private _translateSpecial: TranslateSpecialPipe,
    private _sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  /**
   * Load data for modal
   */
  ngOnInit(): void {
    this._profileService.fetchClientWinsAndBet().subscribe(
      data => {
        this.casinoWnsAndBet = data;
      },
      error => (this.error = error)
    );

    if (!this.isDismissable) {
      setTimeout(() => {
        (
          this._document.querySelector(
            'ion-modal.responsible-gaming-modal'
          ) as HTMLIonModalElement
        )?.dismiss();
      }, 5500);
    }
  }

  /** Dismiss this modal */
  dismiss(): void {
    this._modalCtrl.getTop().then(modal => {
      modal.dismiss();
    });
  }

  /**
   * used to change color of link in special label
   *
   * encapsulation: ViewEncapsulation.None - did not work
   * because it messed all components css
   *
   * @returns safehtml
   */
  getSpecialLabel(): SafeHtml {
    const label = (
      (this._translateSpecial.transform('responsible_gaming_warning_2') as any)
        .changingThisBreaksApplicationSecurity as string
    ).replace('href', 'style="color: #BEC1C4" href');

    return this._sanitizer.bypassSecurityTrustHtml(label);
  }
}
