import {
  Component,
  QueryList,
  ContentChildren,
  ViewChild,
  AfterViewInit,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { BannerDirective } from './banner.directive';
import { SwiperComponent } from 'swiper/angular';

const DEFAULT_SLIDE_DELAY = 3000;
// Transition duration between two slides
const SPEED = 300;
// Preview height and width in px
const PREVIEW_HEIGHT = 44;
const PREVIEW_WIDTH = 72;

@Component({
  selector: 'doxx-banner-group',
  templateUrl: './banner-group.component.html',
  styleUrls: ['./banner-group.component.scss']
})
export class BannerGroupComponent implements AfterViewInit {
  @ViewChild('slider', { static: false }) slider?: SwiperComponent;
  @ViewChild('preview', { static: false }) previewSlider?: SwiperComponent;
  @ViewChild('previewAnimation', { read: ElementRef })
  previewAnimationEl: ElementRef<SVGElement>;

  @ContentChildren(BannerDirective) slides: QueryList<BannerDirective>;

  PREVIEW_HEIGHT = PREVIEW_HEIGHT;
  PREVIEW_WIDTH = PREVIEW_WIDTH;

  slideOpts = {
    autoplay: {
      disableOnInteraction: false
    },
    speed: SPEED,
    loop: true,
    pagination: {
      clickable: false
    }
  };

  previewOpts = {
    loop: true,
    allowTouchMove: false
  };

  /** Show second slide on preview */
  ngAfterViewInit(): void {
    this.previewSlider.swiperRef.slideTo(2, 0);
  }

  /** Detect active slide, and update slide on preview, also update timing for slide */
  updatePreviewSliderState([swiper]): void {
    const activeIndex = swiper.activeIndex;
    let slideToIndex = 1;
    if (this.slides.length > activeIndex) {
      slideToIndex = activeIndex + 1;
    }
    this.previewSlider.swiperRef?.slideTo(slideToIndex);

    this.previewAnimationEl.nativeElement.classList.remove('animated');

    this._cd.detectChanges();
    setTimeout(() => {
      this.previewAnimationEl.nativeElement.classList.add('animated');
      this.previewAnimationEl.nativeElement.style.animationPlayState =
        'running';
      this.previewAnimationEl.nativeElement.style.animationDuration =
        (this.slides.toArray()[slideToIndex - 1]?.timing ||
          DEFAULT_SLIDE_DELAY) + 'ms';
      this._cd.detectChanges();
    }, 10);
  }

  /** Show next slide */
  slideNext(): void {
    const activeIndex = this.slider.swiperRef.activeIndex;
    if (this.slides.length > activeIndex - 1) {
      this.slider.swiperRef.slideTo(activeIndex + 1);
    } else {
      this.slider.swiperRef.slideTo(1);
    }
  }

  /** Return to first slide */
  onReachEnd(): void {
    this.slider.swiperRef.slideTo(1);
  }

  /** Show last slide */
  onReachStart(): void {
    this.slider.swiperRef.slideTo(this.slides.length);
  }

  /** Start autoplay when slider is loaded */
  startAutoplay(): void {
    this.slider.swiperRef.autoplay?.start();
  }

  constructor(private _cd: ChangeDetectorRef) {}
}
