import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LetModule, PushModule } from '@ngrx/component';
import { DoxxComponentsModule } from './components/doxx-components.module';
import { DoxxDirectivesModule } from './directives/doxx-directives.module';
import { MaterialDesignModule } from './material-design/material-design.module';
import { DoxxPipesModule } from './pipes/doxx-pipes.module';
import { SharepopupService } from './sharepopup/sharepopup.service';
import { UtilsModule } from './utils/utils.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    DoxxComponentsModule,
    DoxxDirectivesModule,
    DoxxPipesModule,
    IonicModule
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    CommonModule,
    DoxxComponentsModule,
    DoxxDirectivesModule,
    DoxxPipesModule,
    IonicModule,
    LetModule,
    PushModule,
    UtilsModule,
    MaterialDesignModule
  ],
  providers: [SharepopupService]
})
export class SharedModule {
  public static injector: Injector;
  constructor(_injector: Injector) {
    SharedModule.injector = _injector;
  }
}
