import { NgModule, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { CoreModule } from '@core/core.module';
import { OfferStorageModule } from './pages/offer/store/offer-storage.module';
// import { TicketModule } from './tabs/ticket/ticket.module';
import { TicketModule } from './pages/ticket/ticket.module';
import { TicketBubleComponent } from './ticket-buble/ticket-buble.component';
import { TicketBubleHostDirective } from './ticket-buble/ticket-buble-host.directive';
import { fromEvent, merge, of } from 'rxjs';
import { delay, filter, first, skip, take } from 'rxjs/operators';
import { LandingStorageModule } from './pages/landing/landing/landing-storage.module';
import { BonusesStoreModule } from './pages/bonuses/store/bonuses-store.module';
import { DoxxComponentsModule } from '@shared/components/doxx-components.module';
import { ContactsComponent } from '@shared/components/contacts/contacts.component';
import { ResponsibleGamingModalComponent } from '@shared/responsible-gaming-modal/responsible-gaming-modal.component';
import { CasinoStoreModule } from './pages/casino/casino/casino-store.module';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import sk from '@angular/common/locales/sk';
import { SharedModule } from '@shared/shared.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environment';
import { DownloadAppBannerComponent } from '@shared/components/download-app-banner/download-app-banner.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { TicketStoreModule } from './pages/ticket/store/ticket-store.module';
import { BuybackStoreModule } from '@store/buyback/buyback.store.module';
import { TickethistoryStoreModule } from './pages/betslip/tickethistory/store/tickethistory-store.module';
import { FavoritesStoreModule } from '@store/favorites/favorites.store.module';
import { NotificationsStoreModule } from '@store/notifications/notifications.store.module';

let devModules = [StoreDevtoolsModule.instrument({})];

if (environment.production) {
  devModules = [];
}
@NgModule({
  declarations: [
    AppComponent,
    TicketBubleComponent,
    TicketBubleHostDirective,
    ContactsComponent,
    ResponsibleGamingModalComponent,
    DownloadAppBannerComponent
  ],
  imports: [
    ...devModules,
    TicketModule,
    HttpClientModule,
    SharedModule,
    OfferStorageModule,
    BonusesStoreModule,
    CasinoStoreModule,
    LandingStorageModule,
    TicketStoreModule,
    DoxxPipesModule,
    DoxxComponentsModule,
    BuybackStoreModule,
    FavoritesStoreModule,
    NotificationsStoreModule,
    CoreModule,
    NgOtpInputModule,
    TickethistoryStoreModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router, @Inject(DOCUMENT) document) {
    // FLASH SCREEN
    merge(
      of('dummy').pipe(delay(1000)),
      router.events.pipe(
        filter(ev => ev instanceof NavigationEnd),
        first()
      )
    )
      .pipe(skip(1), take(1))
      .subscribe(() => {
        fromEvent(document.getElementById('flash-screen'), 'animationend', {
          once: true
        }).subscribe(() => document.getElementById('flash-screen').remove());
        document.getElementById('flash-screen').classList.add('finish');
      });
  }
}

registerLocaleData(sk);
