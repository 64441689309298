import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CombinationsdetailComponent } from '../combinationsdetail/combinationsdetail.component';
import { Combi } from '@models/Ticket';
import { Store } from '@ngrx/store';
import { TicketState } from '../store/ticket.reducer';
import { Observable, Subscription, of } from 'rxjs';
import * as ticketSelectors from '../store/ticket.selectors';
import { ticketActions } from '@store/actions';
import { Actions, ofType } from '@ngrx/effects';
import { filter, first, map } from 'rxjs/operators';

@Component({
  selector: 'doxx-ticketcombinations',
  templateUrl: './ticketcombinations.component.html',
  styleUrls: ['./ticketcombinations.component.scss']
})
export class TicketcombinationsComponent implements OnInit, OnDestroy {
  private _skipSelect = false;
  private _subscriptions$ = new Subscription();
  section = 'group';
  combinations: Combi[];
  isAnyCombinationActivated: boolean;

  constructor(
    private _combinationsDetailModal: ModalController,
    private _store: Store<TicketState>,
    private _action$: Actions
  ) {}

  /**
   * Open Combinations Detail
   */
  openCombinationsDetail(): void {
    this._combinationsDetailModal
      .create({
        component: CombinationsdetailComponent,
        cssClass: 'combinations-detail-fullscreen'
      })
      .then(modal => modal.present());
  }

  /**
   * Initialisation Ticket Combinations Component
   */
  ngOnInit(): void {
    this._subscriptions$.add(
      this._store
        .select(ticketSelectors.selectCombi)
        .pipe(filter(() => !this._skipSelect))
        .subscribe(combi => {
          this.combinations = combi;
          this.isAnyCombinationActivated = combi.some(cmb => cmb.On);
        })
    );
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this._subscriptions$.unsubscribe();
  }

  /**
   * Toggle checkbox combination
   */
  onToggleCombi(combiId: string, combiOn: boolean): void {
    if (combiOn) {
      this._store.dispatch(ticketActions.deactivateCombi({ combi: combiId }));
    } else {
      this._store.dispatch(ticketActions.activateCombi({ combi: combiId }));
    }
  }

  /**
   * Blur handle on checkbox combination
   */
  onCombiBetChange(event: Event, combi: Combi, isPartial: boolean): void {
    const value = (event.target as HTMLInputElement).value;
    // validate number
    if (value && !isNaN(parseFloat(value))) {
      // validate distinct values
      if (
        (isPartial && combi.ParcialBet !== value.toString()) ||
        (!isPartial && combi.Bet !== value.toString())
      ) {
        let afterActivateCombi$: Observable<any> = of(combi);
        // optimistics UI
        combi.ParcialBet = isPartial ? value : combi.ParcialBet;
        combi.Bet = !isPartial ? value : combi.Bet;

        if (!combi.On) {
          this._skipSelect = true;
          afterActivateCombi$ = this._action$.pipe(
            ofType(ticketActions.activateCombiComplete),
            map(({ ticket }) => ticket?.Combi.find(cmb => cmb.ID === combi.ID)),
            first()
          );
          this._store.dispatch(
            ticketActions.activateCombi({ combi: combi.ID })
          );
        }

        afterActivateCombi$.subscribe(cmb => {
          this._store.dispatch(
            ticketActions.setCombinationStake({
              bet: value,
              combiID: combi.ID,
              isPartial
            })
          );
          this._skipSelect = false;
        });
      }
    }
  }
}
