import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ElementRef,
  HostListener,
  HostBinding
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ToolbarListService } from '../toolbar-list.service';

@Component({
  selector: 'doxx-toolbar-list-item',
  templateUrl: './toolbar-list-item.component.html',
  styleUrls: ['./list-item-button.scss', './list-item-normal.scss']
})
export class ToolbarListItemComponent<T> implements OnInit, OnDestroy {
  @Input() value: any;
  @Input() selectedStyles: any;
  @Input() showBadge: boolean;
  @Input()
  @HostBinding('class.gradient')
  showGradient = true;
  @Input() events: Observable<string>;

  @HostBinding('class.selected')
  selected = false;
  private _isSelectedSubscription$: Subscription;
  private _navigateToSubscription$: Subscription;

  /**
   * Selects new value
   */
  @HostListener('click', ['$event'])
  onItemClicked(): void {
    if (this.value) {
      if (!this.selected) {
        this._parentToolbarListService.selectedItem.next(this.value);
      } else if (this._parentToolbarListService.unselectable) {
        this._parentToolbarListService.selectedItem.next(null);
      }
    }
  }

  @HostBinding('style')
  get styles(): any {
    return this.selected ? this.selectedStyles : {};
  }

  constructor(
    private _parentToolbarListService: ToolbarListService<T>,
    public el: ElementRef
  ) {}

  /**
   * check if item is selected
   */
  ngOnInit(): void {
    this._isSelectedSubscription$ =
      this._parentToolbarListService.selectedItem.subscribe(() =>
        this._checkIfIsSelected()
      );
    if (this.events) {
      this._navigateToSubscription$ = this.events.subscribe(data =>
        this.navigateTo(data)
      );
    }
  }

  private _checkIfIsSelected(): void {
    this.selected = this._parentToolbarListService.compareValuesFunction(
      this.value,
      this._parentToolbarListService.selectedItem.value
    );
  }

  /**
   * Navigate to item, from parent
   * @param itemID item
   */
  navigateTo(itemID: string): void {
    if (this.value.ChanceTypeGroupID === itemID) {
      this.onItemClicked();
    }
  }

  /**
   * Clear all subscriptions
   */
  ngOnDestroy(): void {
    if (this.selected) {
      this._parentToolbarListService.selectedItem.next(null);
    }
    this._isSelectedSubscription$.unsubscribe();
    if (this._navigateToSubscription$) {
      this._navigateToSubscription$.unsubscribe();
    }
  }
}
