import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { authSelectors } from '@store/selectors';
import { authActions } from '@store/actions';
import { configuration } from '@configuration';

@Component({
  selector: 'doxx-wallet-balance',
  templateUrl: './wallet-balance.component.html',
  styleUrls: ['./wallet-balance.component.scss']
})
export class WalletBalanceComponent implements OnInit {
  @Input() showTransactionHistory = false;

  balance$: Observable<number>;
  configuration;

  constructor(private _store: Store) {
    this.balance$ = this._store.select(authSelectors.selectBalance);
  }

  /**
   * ngoninit
   */
  ngOnInit(): void {
    this._store.dispatch(authActions.balanceRefreshStart());
    this.configuration = configuration;
  }
}
