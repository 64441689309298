import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLanding from '../landing.reducer';
import * as fromMyAccount from './landing-my-account.reducer';

const selectLandingState = createFeatureSelector<fromLanding.State>(
  fromLanding.LANDING_FEATURE_KEY
);

export const selectMyAccount = createSelector(
  selectLandingState,
  (state: fromLanding.State) => state.myAccount
);

// CASINO WINS
export const selectMyAccountCasinoWins = createSelector(
  selectMyAccount,
  (state: fromMyAccount.State) => state.casinoWins
);
export const selectMyAccountCasinoWinsLoading = createSelector(
  selectMyAccountCasinoWins,
  state => state.loading
);
export const selectMyAccountCasinoWinsCasinoWinsOrderByWin = createSelector(
  selectMyAccountCasinoWins,
  state => state.wins
  // .sort((a, b) => b.WinAmount - a.WinAmount)
);
export const selectMyAccountCasinoWinsError = createSelector(
  selectMyAccountCasinoWins,
  state => state.error
);

// BONUSES AVAILABLE
export const selectloadBonusesStatus = createSelector(
  selectMyAccount,
  (state: fromMyAccount.State) => state.bonusesStatus
);
