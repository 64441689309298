import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNumber'
})
export class IsNumberPipe implements PipeTransform {
  /**
   * Pipe to return true if the number is NaN
   */
  transform(item: any): boolean {
    return typeof item === 'number';
  }
}
