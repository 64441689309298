import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OddComponent } from './odd.component';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { RouterModule } from '@angular/router';
import { UtilsModule } from '@shared/utils/utils.module';
import { InViewportModule } from '@shared/directives/in-viewport/in-viewport.module';

@NgModule({
  declarations: [OddComponent],
  imports: [
    CommonModule,
    DoxxPipesModule,
    RouterModule,
    UtilsModule,
    InViewportModule
  ],
  exports: [OddComponent]
})
export class OddModule {}
