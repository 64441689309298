import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {
  @Output() noticeButton: EventEmitter<string> = new EventEmitter();

  /**
   * emit button
   */
  buttonEmit(context: string): void {
    this.noticeButton.emit(context);
  }
}
