import { Announcement } from '@models/Landing';
import { createAction, props } from '@ngrx/store';

export const loadAnnouncement = createAction('[Lading] Set Announcement');
export const loadAnnouncementSuccess = createAction(
  '[Lading] Load Announcement Success',
  props<{ announcement: Announcement }>()
);
export const loadAnnouncementFailure = createAction(
  '[Lading] Load Announcement Failure'
);
