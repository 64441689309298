import { configuration } from '@configuration';
import { localeParseFloat } from './localeParseFloat';
import { environment } from '@environment';
import { trycatch } from '@core/tryCatch/tryCatch';

/**
 * Transforms number to string with currency, you can use additional properties if you want
 * default value for currency is currency set in evnironment
 */
export function formatNumber(
  value: number | string,
  props?: { decimalDigits?: number }
): string {
  const minimumFractionDigits = !isNaN(props?.decimalDigits)
    ? props.decimalDigits
    : 2;
  const maximumFractionDigits = !isNaN(props?.decimalDigits)
    ? props.decimalDigits
    : 20;

  if (!(value === null || value === undefined)) {
    const locale = configuration.base.languages.find(
      lang => lang.code === environment.language
    ).locale;

    if (typeof value === 'string') {
      value = !isNaN(+value)
        ? +value
        : localeParseFloat(value, locale.replace('_', '-'));
    }
    return trycatch(
      () =>
        value.toLocaleString(locale.replace('_', '-'), {
          minimumFractionDigits,
          maximumFractionDigits
        } as any),
      value.toFixed(minimumFractionDigits)
    );
  }
}
