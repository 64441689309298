import {
  ChangeDetectorRef,
  Directive,
  DoCheck,
  EmbeddedViewRef,
  Input,
  IterableChangeRecord,
  IterableChanges,
  IterableDiffer,
  IterableDiffers,
  NgIterable,
  Output,
  TemplateRef,
  TrackByFunction,
  ViewContainerRef
} from '@angular/core';
import { from, of, Subject } from 'rxjs';
import { concatMap, delay, map } from 'rxjs/operators';

/**
 * @publicApi
 */
export class NgForOfContext<T, U extends NgIterable<T> = NgIterable<T>> {
  constructor(
    public $implicit: T,
    public dataset: U,
    public index: number,
    public count: number
  ) {}

  get first(): boolean {
    return this.index === 0;
  }

  get last(): boolean {
    return this.index === this.count - 1;
  }

  get even(): boolean {
    return this.index % 2 === 0;
  }

  get odd(): boolean {
    return !this.even;
  }
}

@Directive({ selector: '[doxxProgresiveLoading][doxxProgresiveLoadingOf]' })
export class ProgresiveLoadingDirective<
  T,
  U extends NgIterable<T> = NgIterable<T>
> implements DoCheck
{
  @Input()
  set doxxProgresiveLoadingOf(ngForOf: (U & NgIterable<T>) | undefined | null) {
    this._dataset = ngForOf;
    this._isDatasetDirty = true;
  }

  @Input()
  set doxxProgresiveLoadingTrackBy(fn: TrackByFunction<T>) {
    this._trackByFn = fn;
  }

  @Input() doxxProgresiveLoadingCycleItems = 3;
  @Input() doxxProgresiveLoadingCycleDelay = 20;
  @Output() rendered = new Subject();

  constructor(
    private _viewContainer: ViewContainerRef,
    private _template: TemplateRef<NgForOfContext<T, U>>,
    private _differs: IterableDiffers,
    private _cd: ChangeDetectorRef
  ) {}

  /**
   * A reference to the template that is stamped out for each item in the iterable.
   * @see [template reference variable](guide/template-reference-variables)
   */
  @Input()
  set doxxProgresiveLoadingTemplate(value: TemplateRef<NgForOfContext<T, U>>) {
    // TODO(TS2.1): make TemplateRef<Partial<NgForRowOf<T>>> once we move to TS v2.1
    // The current type is too restrictive; a template that just uses index, for example,
    // should be acceptable.
    if (value) {
      this._template = value;
    }
  }

  private _dataset: U | undefined | null = null;
  private _isDatasetDirty = true;
  private _differ: IterableDiffer<T> | null = null;
  private _trackByFn!: TrackByFunction<T>;
  private _isRendered = false;

  /**
   * Asserts the correct type of the context for the template that `NgForOf` will render.
   *
   * The presence of this method is a signal to the Ivy template type-check compiler that the
   * `NgForOf` structural directive renders its template with a specific context type.
   */
  static ngTemplateContextGuard<T, U extends NgIterable<T>>(
    dir: ProgresiveLoadingDirective<T, U>,
    ctx: any
  ): ctx is NgForOfContext<T, U> {
    return true;
  }

  /**
   * Applies the changes when needed.
   */
  ngDoCheck(): void {
    try {
      if (this._isDatasetDirty) {
        this._isDatasetDirty = false;
        // React on ngForOf changes only once all inputs have been initialized
        const value = this._dataset;
        if (!this._differ && value) {
          try {
            this._differ = this._differs.find(value).create(this._trackByFn);
          } catch {
            throw new Error(
              `Cannot find a differ supporting object '${value}' of type '${getTypeName(
                value
              )}'. NgFor only supports binding to Iterables such as Arrays.`
            );
          }
        }
        if (this._differ) {
          const changes = this._differ.diff(this._dataset);
          if (changes) {
            if (!this._isRendered) {
              this._initialRender(changes).then(() => {
                this._isRendered = true;
                this.rendered.next();
              });
            } else {
              this._applyChanges(changes);
            }
          }
        }
      }
    } catch (ignored) {}
  }

  private _applyChanges(changes: IterableChanges<T>): void {
    const insertTuples: RecordViewTuple<T, U>[] = [];
    changes.forEachOperation(
      (
        item: IterableChangeRecord<any>,
        adjustedPreviousIndex: number | null,
        currentIndex: number | null
      ) => {
        if (item.previousIndex == null) {
          // NgForOf is never "null" or "undefined" here because the differ detected
          // that a new item needs to be inserted from the iterable. This implies that
          // there is an iterable value for "_ngForOf".
          const view = this._viewContainer.createEmbeddedView(
            this._template,
            new NgForOfContext<T, U>(null, this._dataset, -1, -1),
            currentIndex === null ? undefined : currentIndex
          );
          const tuple = new RecordViewTuple<T, U>(item, view);
          insertTuples.push(tuple);
        } else if (currentIndex == null) {
          this._viewContainer.remove(
            adjustedPreviousIndex === null ? undefined : adjustedPreviousIndex
          );
        } else if (adjustedPreviousIndex !== null) {
          const view = this._viewContainer.get(adjustedPreviousIndex);
          this._viewContainer.move(view, currentIndex);
          const tuple = new RecordViewTuple(
            item,
            view as EmbeddedViewRef<NgForOfContext<T, U>>
          );
          insertTuples.push(tuple);
        }
      }
    );

    for (const item of insertTuples) {
      this._perViewChange(item.view, item.record);
    }

    for (let i = 0, ilen = this._viewContainer.length; i < ilen; i++) {
      const viewRef = this._viewContainer.get(i) as EmbeddedViewRef<
        NgForOfContext<T, U>
      >;
      viewRef.context.index = i;
      viewRef.context.count = ilen;
      viewRef.context.dataset = this._dataset;
    }

    changes.forEachIdentityChange((record: any) => {
      const viewRef = this._viewContainer.get(
        record.currentIndex
      ) as EmbeddedViewRef<NgForOfContext<T, U>>;
      viewRef.context.$implicit = record.item;
    });
  }

  private _initialRender(changes: IterableChanges<T>): Promise<void> {
    return new Promise(resolve => {
      let operations = [];
      changes.forEachOperation((item, adjustedPreviousIndex, currentIndex) =>
        operations.push({
          item,
          adjustedPreviousIndex,
          currentIndex
        })
      );
      operations = splitToChunks(
        operations,
        this.doxxProgresiveLoadingCycleItems
      );

      from(operations)
        .pipe(
          concatMap(set =>
            of(set).pipe(delay(this.doxxProgresiveLoadingCycleDelay))
          ),
          map(set =>
            set.map(({ item, currentIndex }) => {
              const view = this._viewContainer.createEmbeddedView(
                this._template,
                new NgForOfContext<T, U>(null, this._dataset, currentIndex, -1),
                currentIndex === null ? undefined : currentIndex
              );
              return new RecordViewTuple<T, U>(item, view);
            })
          )
        )
        .subscribe(
          tuples => {
            for (const item of tuples) {
              this._perViewChange(item.view, item.record);
            }
            this._cd.detectChanges();
          },
          null,
          () => {
            for (let i = 0, ilen = this._viewContainer.length; i < ilen; i++) {
              const viewRef = this._viewContainer.get(i) as EmbeddedViewRef<
                NgForOfContext<T, U>
              >;
              viewRef.context.index = i;
              viewRef.context.count = ilen;
              viewRef.context.dataset = this._dataset;
            }

            changes.forEachIdentityChange((record: any) => {
              const viewRef = this._viewContainer.get(
                record.currentIndex
              ) as EmbeddedViewRef<NgForOfContext<T, U>>;
              if (viewRef) {
                viewRef.context.$implicit = record.item;
              }
            });
            resolve();
          }
        );
    });
  }

  private _perViewChange(
    view: EmbeddedViewRef<NgForOfContext<T, U>>,
    record: IterableChangeRecord<any>
  ): void {
    view.context.$implicit = record.item;
  }
}

class RecordViewTuple<T, U extends NgIterable<T>> {
  constructor(
    public record: any,
    public view: EmbeddedViewRef<NgForOfContext<T, U>>
  ) {}
}

function getTypeName(type: any): string {
  return type.name || typeof type;
}

function splitToChunks(array: any[], parts: number): any[] {
  const result = [];
  while (array.length > 0) {
    result.push(array.splice(0, parts));
  }
  return result;
}
