import { ParameterAlias } from '@models/ParameterAlias';

function isParameterAlias(object: any): object is ParameterAlias {
  return 'Link' in object && 'Value' in object;
}

export const mapParamAlias = (params: ParameterAlias[]): ParameterAlias[] =>
  (params || []).map(param => {
    const childParams = Object.values(param).find(
      val => val && Array.isArray(val) && isParameterAlias(val[0])
    );
    const { Link, Value, MetadataKey, ParamName } = param;
    if (!!childParams) {
      return {
        Link,
        Value,
        MetadataKey,
        ParamName,
        childrens: mapParamAlias(childParams as ParameterAlias[])
      };
    } else {
      return { Link, Value, MetadataKey, ParamName };
    }
  });
