import { Pipe, PipeTransform } from '@angular/core';
import { Event } from '@models/Event';
import { TopEvent } from '@models/TopEvent';
import { Store } from '@ngrx/store';
import { favoritesSelectors } from '@store/selectors';
import { Observable } from 'rxjs';

@Pipe({
  name: 'isFavEvent'
})
export class IsFavEventPipe implements PipeTransform {
  constructor(private _store: Store) {}
  /** return observable of boolean indicates event is favorite */
  transform(ev: Event | TopEvent): Observable<boolean> {
    return this._store.select(
      favoritesSelectors.selectIsEventInFav(ev.EventID)
    );
  }
}
