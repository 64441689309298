import { TICKET_HISTORY_STATUS } from '@constants/betslipStatus';
import { TicketHistoryListItem } from '@models/TicketHistory';

/** gets ticket status */
export function statusTransform(ticket: TicketHistoryListItem): {
  icon: string;
  color: string;
  background: string;
  status: keyof typeof TICKET_HISTORY_STATUS;
} {
  let status: keyof typeof TICKET_HISTORY_STATUS;
  if (!ticket) {
    status = 'default';
  } else if (
    ticket.state === 'closed' &&
    ticket.winningStatus === 'nonwinning'
  ) {
    if (ticket.noRiskTicket) {
      status = 'norisk';
    } else if (ticket.compensationTicket) {
      status = 'compensation';
    } else {
      status = 'lost';
    }
  } else if (ticket.state === 'approved') {
    status = 'pend';
  } else if (ticket.state === 'waiting_approval') {
    status = 'await';
  } else if (ticket.state === 'refused') {
    status = 'refused';
  } else if (ticket.state === 'expired') {
    status = 'expired';
  } else if (ticket.state === 'edited') {
    status = 'edited';
  } else if (ticket.state === 'canceled') {
    status = 'canceled';
  } else if (ticket.state === 'closed' && ticket.winningStatus === 'winning') {
    if (ticket.buyBackActivity) {
      status = 'buyback';
    } else if (ticket.compensationTicket) {
      status = 'compensation';
    } else {
      status = 'won';
    }
  } else {
    status = 'default';
  }

  return {
    ...TICKET_HISTORY_STATUS[status],
    status
  };
}
