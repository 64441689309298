<table [ngClass]="{ 'ticket-history-event': isFromTicketHistory }">
  <thead
    [style]="
      isFromTicketHistory
        ? (sportID | sportTransform : false : false : false : true)
        : null
    "
  >
    <tr *ngIf="score.winners.length === 0">
      <th>
        <ng-container *ngIf="!isFromTicketHistory">
          <span *ngIf="actualGamePartID && section !== 'results'">
            {{ 'app_gamepart_' + actualGamePartID | translate }}
          </span>
          <span *ngIf="actualGamePartTime && actualGamePartTime !== -1"
            >&nbsp;{{ actualGamePartTime }}'</span
          >
        </ng-container>

        <ng-container *ngIf="isFromTicketHistory && liveBettingView">
          <div *ngIf="!isLive" class="ic-live-loading play"></div>
          <i
            class="icon live-icon icon-ic_live"
            *ngIf="sportID !== ('virtualGames.vgSportId' | env) && isLive"
          ></i>
          <span *ngIf="actualGamePartID && section !== 'results'">
            {{ 'app_gamepart_' + actualGamePartID | translate }}
          </span>
          <span *ngIf="actualGamePartTime && actualGamePartTime !== -1"
            >&nbsp;{{ actualGamePartTime }}'</span
          >
        </ng-container>
      </th>
      <th colspan="2"></th>
      <th
        *ngFor="let partialPoints of score.home.partials; index as i"
        class="partial-score-td {{
          i === score.home.partials.length - 1 && isLive
            ? 'highlight-score'
            : ''
        }}"
      >
        {{ i + 1 }}.
      </th>
      <th
        *ngFor="let overtime of score.home.overtimePoints; index as i"
        class="partial-score-td"
      >
        {{ 'app_gamepart_ot_abbr' | translate }}
        {{ score.home.overtimePoints.length > 1 ? i + 1 : '' }}
      </th>
      <th *ngIf="score.penalty" class="partial-score-td">
        {{ 'app_gamepart_penalties_abbr' | translate }}
      </th>
      <th *ngIf="score.home.game"></th>
      <th *ngIf="sportID === 54">
        <img
          src="{{
            'staticUrl' | env
          }}/livebet/stylesheets/images/scoreboards/icon-yellow-card.png"
        />
      </th>
      <th *ngIf="sportID === 54">
        <img
          src="{{
            'staticUrl' | env
          }}/livebet/stylesheets/images/scoreboards/icon-red-card.png"
        />
      </th>
      <th *ngIf="score.home.corners || score.away.corners">
        <i class="icon corner-icon icon-ic_field-corner-stroke"></i>
      </th>
    </tr>
    <tr *ngIf="score.winners.length > 0">
      <th class="partial-score-td virtual-td">
        {{ 'score_table_virtual_1' | translate }}
      </th>
      <th class="partial-score-td virtual-td text-right">
        {{ 'score_table_virtual_2' | translate }}
      </th>
    </tr>
  </thead>
  <tbody
    *ngIf="score.winners.length === 0"
    class="sport-color-{{ sportID }}"
    [style]="sportID | sportTransform : false : false : false : true"
  >
    <tr>
      <td class="text-left team-name">
        <div>
          <div class="live-team-icon">
            <img
              src="{{ 'staticUrl' | env }}/teams/medium/{{
                homeTeam.ID || '0-grey'
              }}.png"
              (error)="changeToDefaultTeamIcon($event)"
            />
          </div>
          <span class="underlined">{{ homeTeam.name }}</span>
        </div>
      </td>
      <!-- <td></td> -->
      <td>
        <i
          *ngIf="score.home.serving"
          class="sport-icon serving-icon sport-icon-{{ sportID }}"
        ></i>
      </td>
      <td class="score">{{ score.home.points }}</td>
      <td
        *ngFor="let partialPoints of score.home.partials; index as i"
        class="partial-score-td {{
          i === score.home.partials.length - 1 && isLive
            ? 'highlight-score'
            : ''
        }}"
      >
        {{ partialPoints }}
      </td>

      <td
        *ngFor="let overtimePoints of score.home.overtimePoints"
        class="partial-score-td"
      >
        {{ overtimePoints }}
      </td>
      <td *ngIf="score.penalty" class="partial-score-td">
        {{ score.home.penaltyPoints }}
      </td>
      <td *ngIf="score.home.game">
        {{ score.home.game }}
      </td>

      <td *ngIf="sportID === 54">
        {{ score.home.yellowCards ? score.home.yellowCards : '-' }}
      </td>
      <td *ngIf="sportID === 54">
        {{ score.home.redCards ? score.home.redCards : '-' }}
      </td>
      <td *ngIf="score.home.corners || score.away.corners">
        {{ score.home.corners ? score.home.corners : '-' }}
      </td>
    </tr>
    <tr>
      <td class="text-left team-name">
        <div>
          <div class="live-team-icon">
            <img
              src="{{ 'staticUrl' | env }}/teams/medium/{{
                awayTeam.ID || '0-grey'
              }}.png"
              (error)="changeToDefaultTeamIcon($event)"
            />
          </div>
          <span class="underlined">{{ awayTeam.name }}</span>
        </div>
      </td>
      <!-- <td></td> -->
      <td>
        <i
          *ngIf="score.away.serving"
          class="sport-icon serving-icon sport-icon-{{ sportID }}"
        ></i>
      </td>
      <td class="score">{{ score.away.points }}</td>
      <td
        *ngFor="let partialPoints of score.away.partials; index as i"
        class="partial-score-td {{
          i === score.away.partials.length - 1 && isLive
            ? 'highlight-score'
            : ''
        }}"
      >
        {{ partialPoints }}
      </td>

      <td
        *ngFor="let overtimePoints of score.away.overtimePoints"
        class="partial-score-td"
      >
        {{ overtimePoints }}
      </td>
      <td *ngIf="score.penalty" class="partial-score-td">
        {{ score.away.penaltyPoints }}
      </td>
      <td *ngIf="score.away.game">
        {{ score.away.game }}
      </td>

      <td *ngIf="sportID === 54">
        {{ score.away.yellowCards ? score.away.yellowCards : '-' }}
      </td>
      <td *ngIf="sportID === 54">
        {{ score.away.redCards ? score.away.redCards : '-' }}
      </td>
      <td *ngIf="score.home.corners || score.away.corners">
        {{ score.away.corners ? score.away.corners : '-' }}
      </td>
    </tr>
  </tbody>
  <tbody
    *ngIf="score.winners.length > 0"
    class="sport-color-{{ sportID }}"
    [style]="sportID | sportTransform : false : false : false : true"
  >
    <tr *ngFor="let winner of score.winners; index as i">
      <td class="team-name virtual">{{ i + 1 }}.</td>
      <td class="text-right team-name virtual-name" *ngIf="score">
        #{{ winner }}
      </td>
    </tr>
  </tbody>
</table>
