import { Inject, Injectable, InjectionToken } from '@angular/core';
import { CanLoad } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

export const IS_APPLICATION_INITIALIZED = new InjectionToken<
  BehaviorSubject<boolean>
>('');

@Injectable()
export class IsRoutingInitializedGuard implements CanLoad {
  constructor(
    @Inject(IS_APPLICATION_INITIALIZED)
    private _isRoutingInitialized: BehaviorSubject<boolean>
  ) {}
  /** return true after routing is initialized */
  canLoad(): Observable<boolean> {
    return this._isRoutingInitialized.pipe(
      filter(initialized => !!initialized),
      first()
    );
  }
}
