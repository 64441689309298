import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { oneLine } from '@core/functions/oneLine';
import { PagesMetadata } from '@models/PagesMetadata';
import { translateRoute } from '@core/functions/translateRoute';
import { ScreenMember } from '@models/ScreensMember';
import { Config } from 'src/configuration/types/config';
import { NAVIGATION, ScreenConfig } from 'src/navigation';
import { ParameterAlias } from '@models/ParameterAlias';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  private _httpClient: HttpClient;

  /** Important, for dependency injecting use Injector in this service. It is because some function in this service are
   * executing durring bootstrapping the app.
   */
  constructor(private _injector: Injector) {
    this._httpClient = this._injector.get(HttpClient);
  }

  /** load config  */
  getConfig(): Observable<Config> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/responsive/web/app/portalclient/globalPortalPlatformConfiguration/version/${environment.configVersion}`,
        {
          headers: {
            'Accept-Language': '*'
          }
        }
      )
      .pipe(map(data => data.Result.Config));
  }

  /** Loads remote config for bet builder */
  getBetbuilderInfromation(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/system/getBetbuilderInformations`,
      {
        withCredentials: true
      }
    );
  }

  /** Get logged user session */
  getBetBuilderToken(): Observable<string> {
    return this._httpClient
      .get(`${environment.betBuilder.betBuilderUrl}/token`, {
        withCredentials: true,
        responseType: 'text',
        observe: 'response'
      })
      .pipe(map(response => response.headers.get('token')));
  }

  /**
   * Fetch metadata for pages
   */
  fetchOfferSeoTexts(): Observable<PagesMetadata[]> {
    return this._httpClient
      .get<any>(oneLine`${environment.apiUrl}/web/client/data/seotext/mobile`, {
        withCredentials: true
      })
      .pipe(map(result => result.Result.PagesMetadata));
  }

  /**
   * Fetch router config
   */
  fetchRouterConfig(): Observable<any> {
    return this._httpClient
      .get<any>(
        // oneLine`https://portal-client-staging.doxxbet.sk/client.svc/screen/GetScreenMembers`,
        oneLine`${environment.apiUrl}/responsive/web/app/portalclient/screen/members`
      )
      .pipe(
        map(({ Result }) => {
          if (Result?.length > 0) {
            return Result;
          } else {
            throw new Error('Routing config is empty!');
          }
        })
      );
  }

  /**
   * Fetch config for route
   * TODO checknut ci nerobi problemy ked je settnuty aj header Accpet-Language aj parameter lang
   */
  translateLink(link: string, lang: string): Observable<string> {
    return this._httpClient
      .get<{ Result: ScreenMember[]; [key: string]: any }>(
        // oneLine`https://portal-client-staging.doxxbet.sk/client.svc/screen/GetScreenMember`,
        oneLine`${environment.apiUrl}/responsive/web/app/portalclient/screen/member`,
        {
          params: {
            link: link.split('?')[0],
            lang
          }
        }
      )
      .pipe(
        map(({ Result }) => {
          if (Result.length === 0) {
            throw new Error('Route not found for this link');
          } else {
            let routeParams = (
              Result[0].Link.split('?')[0].match(/\/?{\w*}/g) || []
            ).map(x => ({
              paramName: x.match(/{(\w*)}/)[1],
              originalValue: null
            }));

            const urlParamsRegex = new RegExp(
              Result[0].Link.replace(/(\/{\w*})/g, '/?([^/#?&]*)?')
            );

            const match = link.split('?')[0].match(urlParamsRegex);

            routeParams = routeParams.map((p, i) => ({
              ...p,
              originalValue: match[i + 1]
            }));

            const queryParams = (
              (Result[0].Link.split('?')[1] || '').match(/\w*={\w*}/g) || []
            )
              .map(x => x.match(/(\w*)={(\w*)}/))
              .map(x => ({
                paramName: x[2],
                originalValue: new URLSearchParams(
                  link.split('?')[1] || ''
                ).get(x[1])
              }));

            const params = {};
            [...routeParams, ...queryParams].forEach(
              param =>
                (params[param.paramName] = {
                  value: param.originalValue,
                  fallbackValue: param.originalValue
                })
            );

            const screenConfig = new (class extends ScreenConfig<string> {
              protected _findInParametersAlias(
                parametersAliases: ParameterAlias[] | ParameterAlias[][],
                paramName: string
              ): ParameterAlias {
                return (
                  (Array.isArray(parametersAliases[0])
                    ? parametersAliases[0]
                    : parametersAliases) as ParameterAlias[]
                ).find(pa => pa.ParamName.toString() === paramName.toString());
              }
            })([...NAVIGATION[Result[0].Screen].resolveFunctions.entries()]);
            screenConfig.addRoute(Result[0]);
            return `${environment.language}/${screenConfig.buildLink(params)}`;
          }
        })
      );
  }
}
