import { Inject, Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { environment } from '@environment';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { CaptchaService } from '@core/captcha/captcha.service';
import { DOCUMENT } from '@angular/common';
import { configuration } from '@configuration';
import { SKIP_CAPTCHA_BLOCK } from '@core/captcha/captcha';

@Injectable()
export class JavaBackendInterceptor implements HttpInterceptor {
  constructor(
    private _injector: Injector,
    @Inject(DOCUMENT) private _document: Document
  ) {}
  private _isPaused = new BehaviorSubject<boolean>(false);

  /** ak sa vola na juniho, prida withcredentials flag a checkuje captchu... */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // zisti ci sa ma request spracovavat captchou
    const skipCaptchaBlock =
      !configuration.base?.captchaSiteKey ||
      req.headers.has(SKIP_CAPTCHA_BLOCK);

    if (new RegExp(environment.apiUrl).test(req.url)) {
      let headers: any = req.headers
        .keys()
        .reduce((a, c) => ({ ...a, [c]: req.headers.get(c) }), {});

      headers = {
        'Accept-Language': environment.language,
        ...headers
      };
      if (req.method === 'GET') {
        headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...headers
        };
      }
      delete headers[SKIP_CAPTCHA_BLOCK];

      req = req.clone({
        withCredentials: true,
        headers: new HttpHeaders(headers)
      });
    }

    if (skipCaptchaBlock) {
      return next.handle(req);
    }

    // spracovavanie captche
    if (this._isPaused.value) {
      return this._isPaused.pipe(
        filter(isPaused => !isPaused),
        switchMap(() => next.handle(req))
      );
    } else {
      return next.handle(req).pipe(
        catchError(err => {
          if (err?.error?.showCaptcha === true && !this._isPaused.value) {
            this._isPaused.next(true);
            return this._injector
              .get(CaptchaService)
              .showCaptcha()
              .pipe(
                tap(() => {
                  this._isPaused.next(false);
                }),
                map(() => {
                  throw err;
                })
              );
          } else {
            throw err;
          }
        })
      );
    }
  }
}
