import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import { NotificationsService } from '@shared/components/notifications/notifications.service';
import { authActions, notificationsActions } from '@store/actions';
import { notificationsSelectors } from '@store/selectors';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class NotificationsEffect {
  onLogin$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.autologinSuccess, authActions.loginSuccess),
      map(() => notificationsActions.getUnreadNotificationsCount())
    )
  );

  getUnreadNotificationsCount$ = createEffect(() =>
    this._actions$.pipe(
      ofType(notificationsActions.getUnreadNotificationsCount),
      switchMap(() =>
        this._notificationsService.getUnreadNotifications().pipe(
          map(data =>
            notificationsActions.getUnreadNotificationsCountSuccess({
              count: data.count
            })
          ),
          catchError(error =>
            of(
              notificationsActions.getUnreadNotificationsCountFailure({ error })
            )
          )
        )
      )
    )
  );

  getNotificationsList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(notificationsActions.getNotificationsList),
      switchMap(({ page, pageSize }) =>
        this._notificationsService.getNotificationList({ page, pageSize }).pipe(
          map(data =>
            notificationsActions.getNotificationsListSuccess({
              notifications: data
            })
          ),
          catchError(error =>
            of(
              notificationsActions.getUnreadNotificationsCountFailure({
                error
              })
            )
          )
        )
      )
    )
  );

  getFirstNotificationsList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(notificationsActions.getFirstNotificationsList),
      switchMap(action =>
        this._notificationsService
          .getNotificationList({ page: 0, pageSize: action.count })
          .pipe(
            map(data =>
              notificationsActions.getNotificationsListSuccess({
                notifications: data
              })
            ),
            catchError(error =>
              of(
                notificationsActions.getUnreadNotificationsCountFailure({
                  error
                })
              )
            )
          )
      )
    )
  );

  getNextNotificationsList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(notificationsActions.getNextNotificationsList),
      withLatestFrom(
        this._store.select(notificationsSelectors.selectNotificationsListCursor)
      ),
      switchMap(([action, cursor]) =>
        this._notificationsService
          .getNotificationList({ page: cursor.page, pageSize: cursor.pageSize })
          .pipe(
            map(data =>
              notificationsActions.getNotificationsListSuccess({
                notifications: data
              })
            ),
            catchError(error =>
              of(
                notificationsActions.getUnreadNotificationsCountFailure({
                  error
                })
              )
            )
          )
      )
    )
  );

  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _notificationsService: NotificationsService
  ) {}
}
