import * as fromOffer from './offer.reducer';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Odd } from '@models/Odd';
import { parseScore } from '@core/functions/parseScore';
import { Sport } from '@models/Sport';
import { Event } from '@models/Event';

type OfferTree = Array<{
  key: `SP_${number}`;
  order: number;
  sportID: number;
  typ: 'SP';
  visible: boolean;
  [key: string]: any;
  children?: {
    countryID: string;
    eventCount: number;
    hasStatistics: boolean;
    key: `LC_${number}_RE_${number}`;
    leagueID: number;
    order: number;
    regionID: number;
    seasonID: number;
    sportID: number;
    typ: 'LC';
    visible: boolean;
    [key: string]: any;
    children?: {
      [key: string]: any;
    }[];
  }[];
}> & { sourceTree?: any };

export const selectOffer = createFeatureSelector(fromOffer.OFFER_STATE_KEY);

export const selectRawSports = createSelector(
  selectOffer,
  (state: fromOffer.State) => state.sports
);

export const selectRawRegions = createSelector(
  selectOffer,
  (state: fromOffer.State) => state.regions
);

export const selectRawLeagues = createSelector(
  selectOffer,
  (state: fromOffer.State) => state.leagues
);

export const selectRawEvents = createSelector(
  selectOffer,
  (state: fromOffer.State) => state.events
);

export const selectRawPhases = createSelector(
  selectOffer,
  (state: fromOffer.State) => state.phases
);

export const selectRawOdds = createSelector(
  selectOffer,
  (state: fromOffer.State) => state.odds
);

export const selectStreamState = createSelector(
  selectOffer,
  (state: fromOffer.State) => state.stream
);

export const selectStreamEventCount = createSelector(
  selectStreamState,
  state => state.eventCount
);

export const selectIsStreamScheduleLoaded = createSelector(
  selectStreamState,
  state => state.loaded
);

export const selectStreamScheduleTimestamp = createSelector(
  selectStreamState,
  state => state.timestamp
);

export const selecStreamScheduleEvents = createSelector(
  selectStreamState,
  selectRawEvents,
  selectRawSports,
  selectRawRegions,
  selectRawLeagues,
  (state, events, sports, regions, leagues) =>
    state.streamList.map(eventID => {
      const event = events[eventID];
      return {
        ...event,
        SportName: sports[event.SportID].Name,
        RegionName: regions[event.RegionID].Name,
        LeagueCupName: leagues[event.LeagueCupID].Name,
        ParsedScore: parseScore(event.Score, event.SportID)
      };
    })
);

export const selecStreamScheduleError = createSelector(
  selectStreamState,
  state => state.error
);

export const selectTree = createSelector(
  selectOffer,
  (state: fromOffer.State) => state.tree
);

export const selectOfferTree = ({
  section,
  date,
  sportFilter = () => true,
  leagueFilter = () => true,
  eventFilter = () => true
}: {
  section: string;
  date: string;
  sportFilter?: (sport) => boolean;
  leagueFilter?: (league, sport) => boolean;
  eventFilter?: (event) => boolean;
}) =>
  createSelector(selectTree, (tree: fromOffer.State['tree']): OfferTree => {
    let offerTree = [];

    Object.values<any>(tree[section]?.[date] || {})
      .filter(sport => sport.visible && sportFilter(sport))
      .sortBy('order')
      // .forEach(sportLevel => {
      //   const { children: sportChildren, ...sportProps } = sportLevel;
      //   Object.values<any>(sportLevel.children || {})
      //     .filter(item => item.visible)
      //     .sortBy('order')
      //     .forEach(region => {
      //       const { children: leagues, ...regionProps } = region;
      //       sportProps.children = [...(sportProps.children || []), regionProps];
      //       Object.values<any>(leagues)
      //         .filter(item => item.visible)
      //         .sortBy('order')
      //         .forEach(league => {
      //           const { children: leagueChildren, ...leagueProps } = league;
      //           regionProps.children = [
      //             ...(regionProps.children || []),
      //             leagueProps
      //           ];
      //           Object.values<any>(league.children || {})
      //             .filter(item => item.visible)
      //             .sortBy('order')
      //             .forEach(event => {
      //               leagueProps.children = [
      //                 ...(leagueProps.children || []),
      //                 event
      //               ];
      //             });
      //         });
      .forEach(({ children: regions, ...sport }) => {
        Object.values<any>(regions || {})
          .filter(region => region.visible)
          .sortBy('order')
          .forEach(({ children: leagues, ...region }) => {
            sport.children = [...(sport.children || []), region];
            Object.values<any>(leagues)
              .filter(league => league.visible && leagueFilter(league, sport))
              .sortBy('order')
              .forEach(({ children: events, ...league }) => {
                let visibleEvents = 0;
                const filteredEvents = [];
                Object.values<any>(events || {}).forEach(event => {
                  if (event.visible) {
                    visibleEvents++;
                    if (eventFilter(event)) {
                      filteredEvents.push(event);
                    }
                  }
                });
                filteredEvents.sortBy('order').forEach(event => {
                  league.children = [...(league.children || []), event];
                });
                if (
                  !(
                    filteredEvents.length < visibleEvents &&
                    filteredEvents.length === 0
                  )
                ) {
                  region.children = [...(region.children || []), league];
                }
              });
          });
        offerTree = [...offerTree, sport];
      });

    const sourceTree = {};
    offerTree.forEach(({ children: leagues, ...sport }) => {
      sourceTree[sport.sportID] = { ...sport, children: {} };
      leagues?.forEach(({ children: events, ...leagueProps }) => {
        sourceTree[sport.sportID].children[leagueProps.leagueID] = {
          ...leagueProps,
          children: {}
        };
        events?.forEach(event => {
          sourceTree[sport.sportID].children[leagueProps.leagueID].children[
            event.eventID
          ] = event;
        });
      });
    });
    (offerTree as OfferTree).sourceTree = sourceTree;
    return offerTree as OfferTree;
  });

export const selectEvent = createSelector(
  selectRawEvents,
  (state, eventID) => state[eventID]
);

export const selectOdds = createSelector(
  selectOffer,
  (state: fromOffer.State, oddsIDs: Odd['OddsID'][]) => {
    return oddsIDs.map(oddsID => state.odds[oddsID]);
  }
);

export const selectSportsLoaded = createSelector(
  selectOffer,
  (state, { section, date }) => !!state.tree[section]?.[date]
);

export const selectSports = createSelector(
  selectOffer,
  (state, { section, date }) =>
    Object.values<any>(state.tree[section]?.[date] || {})
      .filter(sport => sport.visible)
      .sortBy('order')
      .map(sport => state.sports[sport.sportID])
);

export const selectRegionsSubtree = ({ section, date, sportID }) =>
  createSelector(selectOffer, (state: fromOffer.State) => {
    const regionSubtree = state.tree[section]?.[date]?.[sportID]?.children;
    return regionSubtree
      ? Object.values<any>(regionSubtree)
          .filter(region => region.visible)
          .sortBy('order')
      : null;
  });

export const selectLeagues = ({ section, date, sportID, regionID }) =>
  createSelector(selectOffer, (state: fromOffer.State) =>
    Object.values<any>(
      state.tree[section]?.[date]?.[sportID]?.children?.[regionID].children ||
        {}
    )
      .filter(league => league.visible)
      .sortBy('order')
      .map(league => state.leagues[league.leagueID])
  );

export const selectEventsSubtree = ({
  section,
  date,
  sportID,
  regionID,
  leagueID
}) =>
  createSelector(selectOffer, (state: fromOffer.State) => {
    const eventsSubtree =
      state.tree[section]?.[date]?.[sportID]?.children?.[regionID]?.children?.[
        leagueID
      ]?.children;
    return eventsSubtree
      ? Object.values<any>(eventsSubtree)
          .filter(event => event.visible)
          .sortBy('order')
      : null;
  });

export const selectEventByLiveFeedReferenceID = liveFeedReferenceID =>
  createSelector(selectRawEvents, (events: fromOffer.State['events']) =>
    Object.values<Event>(events).find(
      event => event.LiveFeedReference === liveFeedReferenceID
    )
  );
