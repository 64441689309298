import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromActualities from './actualities.reducer';
import { Actuality } from '@models/Actuality';

export const selectActualitiesState =
  createFeatureSelector<fromActualities.State>(
    fromActualities.actualitiesFeatureKey
  );

export const selectAvailableActualities = createSelector(
  selectActualitiesState,
  state =>
    state.actualities &&
    state.actualities.filter(actuality =>
      new Date().isBetween(
        new Date(actuality.StartDateString),
        new Date(actuality.EndDateString)
      )
    )
);

export const selectActuality = createSelector(
  selectActualitiesState,
  (state: fromActualities.State, param: string): Actuality =>
    state.actualities.find(actuality => actuality.Param.endsWith(param))
);
