import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[onError]'
})
export class OnErrorDirective {
  private _fallbackApplied = false;
  @Input() onError: string;
  constructor(private _elRef: ElementRef<HTMLElement>) {}
  @HostListener('error') private _onError(): void {
    if (!this._fallbackApplied) {
      this._elRef.nativeElement.setAttribute('src', this.onError);
      this._fallbackApplied = true;
    }
  }
}
