<ion-card
  doxxInViewport
  mode="ios"
  class="game-card"
  tabindex="0"
  [ngClass]="{ disabled: isDisabled }"
>
  <img
    *ngIf="gameImage"
    class="game-card-image"
    load="lazy"
    [src]="gameImage"
    alt="{{ DisplayName }}"
    [onError]="
      ('staticUrl' | env) +
      '/doxxbetPortal/assets/images/placeholders/casino/games/default.jpg'
    "
  />
</ion-card>
<ng-content></ng-content>
