import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasProperty'
})
export class HasPropertyPipe implements PipeTransform {
  /**
   * Check if key exists in object
   */
  transform(object: object, property: any): boolean {
    return object.hasOwnProperty(property);
  }
}
