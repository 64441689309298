import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { isAvailable, ModulesWithOperator } from '@core/functions/isAvailable';
import { BehaviorSubject, Subscription } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ifAvailable]'
})
export class IfAvailableDirective implements OnInit, OnDestroy {
  private _subscription: Subscription;
  private _searchModule = new BehaviorSubject<
    string | string[] | ModulesWithOperator
  >([]);

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef
  ) {}

  @Input()
  set ifAvailable(searchModule: string | string[] | ModulesWithOperator) {
    this._searchModule.next(searchModule);
  }

  /** start subscribe */
  ngOnInit(): void {
    this._subscription = new Subscription();
    this._subscription.add(
      this._searchModule.subscribe(searchModule => {
        if (isAvailable(searchModule)) {
          this._viewContainer.createEmbeddedView(this._templateRef);
        } else {
          this._viewContainer.clear();
        }
      })
    );
  }

  /** stop subscribe */
  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }
}
