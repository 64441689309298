<ion-icon
  class="close-icon"
  name="close-circle"
  *ngIf="url"
  (click)="fileInput.value = null; removePicture()"
></ion-icon>

<label [hidden]="url" class="custom-file-upload text">
  <input #fileInput type="file" (change)="selectFile($event)" />
</label>

<!-- image preview -->
<img [src]="url" *ngIf="url" />

<div class="empty" *ngIf="!url">
  <ion-icon class="icon-ic_camera"></ion-icon>
  <ion-label
    ><h2>{{ message }}</h2></ion-label
  >
</div>
