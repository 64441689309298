import { Pipe, PipeTransform } from '@angular/core';
import { TicketOdd } from '@models/Ticket';
import { InGroup } from './in-group';

@Pipe({
  name: 'isInGroup'
})
export class IsInGroupPipe extends InGroup implements PipeTransform {
  /**
   * check if clicked odd is inside group
   *
   * used in rozpisovy tiket to display pill
   */
  transform(index: number, odds: TicketOdd[]): boolean {
    return this.isInGroup(index, odds);
  }
}
