import { Maintenance } from '@models/Maintenance';
import { createAction, props } from '@ngrx/store';

export const loadMaintenance = createAction('[Maintenance] Load Maintenance');
export const loadMaintenanceSuccess = createAction(
  '[Maintenance] Load MaintenanceSuccess',

  props<{ maintenance: Maintenance }>()
);

export const initMaintenance = createAction(
  '[Maintenance] Init Maintenance State',
  props<{ maintenance: Maintenance }>()
);

export const blockApp = createAction('[Maintenance] Block App');
