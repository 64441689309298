import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { concatMap, map, catchError, take } from 'rxjs/operators';
import { of } from 'rxjs';

import { BonusesService } from '@core/services/bonuses/bonuses.service';
import { casinoActions } from '@store/actions';
import { CasinoService } from '@core/services/casino/casino.service';

@Injectable()
export class CasinoEffects {
  loadCasinoGames$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(casinoActions.loadCasinoGames),
      take(1),
      concatMap(() =>
        this._casinoService.fetchCasinoGames().pipe(
          map(data => casinoActions.loadCasinoGamesSuccess({ ...data })),
          catchError(err => of(casinoActions.loadCasinoGamesFailure()))
        )
      )
    );
  });

  loadRioGames$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(casinoActions.loadRioGames),
      take(1),
      concatMap(() =>
        this._casinoService.fetchRioGames().pipe(
          map(data => casinoActions.loadRioGamesSuccess({ ...data })),
          catchError(err => of(casinoActions.loadRioGamesFailure()))
        )
      )
    );
  });

  loadGamesbyCategory$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(casinoActions.loadGamesbyCategory),
      concatMap(action =>
        this._casinoService
          .fetchGamesbyCategory(action.categoryID, action.casinoType)
          .pipe(
            map(data => casinoActions.loadGamesbyCategorySuccess({ ...data })),
            catchError(err => of(casinoActions.loadGamesbyCategoryFailure()))
          )
      )
    );
  });

  loadCasinoJackpots$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(casinoActions.loadCasinoJackpots),
      concatMap(() =>
        this._casinoService.fetchCasinoJackpots().pipe(
          map(jackpots =>
            casinoActions.loadCasinoJackpotsSuccess({ ...jackpots })
          ),
          catchError(err => of(casinoActions.loadCasinoJackpotsFailure()))
        )
      )
    );
  });

  constructor(
    private _actions$: Actions,
    private _casinoService: CasinoService
  ) {}
}
