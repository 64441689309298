import {
  Component,
  Inject,
  QueryList,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { PopupButton, PopupType } from '../popoup.service';

export interface DefaultComponentOptions {
  buttons?: PopupButton[];
  type?: PopupType;
  template?: TemplateRef<any>;
  text?: string | Observable<string>;
  heading?: string | Observable<string>;
}
@Component({
  templateUrl: './default-popup.component.html',
  styleUrls: ['./default-popup.component.scss']
})
export class DefaultPopupComponent {
  @ViewChildren(MatButton) childs: QueryList<MatButton>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DefaultComponentOptions
  ) {
    if (!!data.text && typeof data.text === 'string') {
      data.text = of(data.text);
    }
    if (!!data.heading && typeof data.heading === 'string') {
      data.heading = of(data.heading);
    }
    data.buttons = data.buttons?.map(button => ({
      ...button,
      title: typeof button.title === 'string' ? of(button.title) : button.title
    }));
  }
}
