import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventsCount'
})
export class eventsCountPipe implements PipeTransform {
  /**
   * Adds leading zero to number, if number is less than 10
   */
  transform(value, type?: 'Y' | 'U' | 'N'): string {
    let counts;
    if (type === 'Y' && value.match(/Y=(\w*)/)) {
      counts = value.match(/Y=(\w*)/)[1];
    } else if (type === 'U' && value.match(/U=(\w*)/)) {
      counts = value.match(/U=(\w*)/)[1];
    } else if (type === 'N' && value.match(/N=(\w*)/)) {
      counts = value.match(/N=(\w*)/)[1];
    }
    return counts;
  }
}
