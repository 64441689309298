import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLanding from '../landing.reducer';

const selectLandingState = createFeatureSelector<fromLanding.State>(
  fromLanding.LANDING_FEATURE_KEY
);

export const selectLandingVirtualGamesState = createSelector(
  selectLandingState,
  (state: fromLanding.State) => state.virtualGames
);

export const selectVirtualGamesLeagues = createSelector(
  selectLandingVirtualGamesState,
  state => state.leagues
);
export const selectVirtualGamesRegions = createSelector(
  selectLandingVirtualGamesState,
  state => state.regions
);
export const selectVirtualGamesLeaguesJoinRegions = createSelector(
  selectVirtualGamesLeagues,
  selectVirtualGamesRegions,
  (leagues, regions) =>
    leagues
      .map(league => ({
        league,
        region: regions.find(region => region.ID === league.ParentID)
      }))
      .sortBy(league => league.league.Order)
      .sortBy(league => league.region.Order)
);

export const selectVirtualGamesLoading = createSelector(
  selectLandingVirtualGamesState,
  state => state.loading
);

export const selectVirtualGamesError = createSelector(
  selectLandingVirtualGamesState,
  state => state.error
);
