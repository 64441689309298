/**
 * Pipe to return value with decimal pieces
 */
export function customFloor(value: any): number {
  // Zaokruhlenie na dve desatine smerom nadol (presne do 1e+8)
  // Ak je pocet znakov pred desatinou ciarkou vacsi ako 9 tak druhe desatine miesto zaokruhli matematicky
  let figure = Number(value);
  if (Math.round(figure).toString().length > 9) {
    figure = parseFloat(figure.toString());
  }
  // Inak ho zaokruhli nadol (chyba v js sa prenasa na 13-16te desatine miesto)
  else {
    figure =
      parseInt(parseFloat((figure * 100).toPrecision(12)).toString(), 10) / 100;
  }
  // Formatuje vysledok (ciarka na miesto bodky)
  return +figure.toFixed(2);
}
