import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NotificationsEffect } from './notifications.effects';
import { NOTIFICATIONS_STATE_KEY, reducer } from './notifications.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(NOTIFICATIONS_STATE_KEY, reducer),
    EffectsModule.forFeature([NotificationsEffect])
  ]
})
export class NotificationsStoreModule {}
