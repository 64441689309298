import { createAction, props } from '@ngrx/store';
import { FavouriteChanceType } from '@models/FavouriteChanceType';
import { FavouritesResponse } from '@models/FavouritesResponse';

// FAV EVENTS
export const favEventsReadStart = createAction(
  '[Favourites] Start reading fav events from cookie'
);
export const favEventsReadSuccess = createAction(
  '[Favourites] Read events from cookies successfully',
  props<{ payload: number[] }>()
);

export const favEventAdd = createAction(
  '[Favourites] Add fav event',
  props<{ payload: number }>()
);
export const favEventRemove = createAction(
  '[Favourites] Remove fav event',
  props<{ payload: number }>()
);

export const favEventsRemove = createAction(
  '[Favourites] Remove fav events',
  props<{ payload: number[] }>()
);

export const favEventsWrite = createAction(
  '[Favourites] Write fav events to cookie'
);

export const favEventsWriteSuccess = createAction(
  '[Favourites] Wrote fav events to cookie successfully'
);

export const getFavouritesStart = createAction(
  '[Favourites] Get favourites start'
);
export const getFavouritesSuccess = createAction(
  '[Favourites] Get favourites success',
  props<{ payload: FavouritesResponse }>()
);

export const getFavouritesFailure = createAction(
  '[Favourites] Get favourites fail'
);

// FAV SPORT ADD
export const favSportAddStart = createAction(
  '[Favourites] Add favourite sport start',
  props<{ payload: number }>()
);

export const favSportAddSuccess = createAction(
  '[Favourites] Add favourite sport success',
  props<{ payload: number }>()
);

export const favSportAddFailure = createAction(
  '[Favourites] Add favourite sport fail'
);

// FAV SPORT REMOVE
export const favSportRemoveStart = createAction(
  '[Favourites] Remove favourite event start',
  props<{ payload: number }>()
);

export const favSportRemoveSuccess = createAction(
  '[Favourites] Remove favourite event success',
  props<{ payload: number }>()
);

export const favSportRemoveFailure = createAction(
  '[Favourites] Remove favourite event fail'
);

// FAV LEAGUE ADD
export const favLeagueAddStart = createAction(
  '[Favourites] Add favourite league start',
  props<{ payload: number }>()
);

export const favLeagueAddSuccess = createAction(
  '[Favourites] Add favourite league success',
  props<{ payload: number }>()
);

export const favLeagueAddFailure = createAction(
  '[Favourites] Add favourite league fail'
);

// FAV LEAGUE REMOVE
export const favLeagueRemoveStart = createAction(
  '[Favourites] Remove favourite league start',
  props<{ payload: number }>()
);

export const favLeagueRemoveSuccess = createAction(
  '[Favourites] Remove favourite league success',
  props<{ payload: number }>()
);

export const favLeagueRemoveFailure = createAction(
  '[Favourites] Remove favourite league success'
);

// FAV CHANCETYPE ADD
export const favChanceTypeAddStart = createAction(
  '[Favourites] Add favourite chancetype start',
  props<{ payload: FavouriteChanceType }>()
);

export const favChanceTypeAddSuccess = createAction(
  '[Favourites] Add favourite chancetype success',
  props<{ payload: FavouriteChanceType }>()
);

export const favChanceTypeAddFailure = createAction(
  '[Favourites] Add favourite chancetype fail'
);

// FAV CHANCETYPE REMOVE
export const favChanceTypeRemoveStart = createAction(
  '[Favourites] Remove favourite chancetype start',
  props<{ payload: FavouriteChanceType }>()
);

export const favChanceTypeRemoveSuccess = createAction(
  '[Favourites] Remove favourite chancetype success',
  props<{ payload: FavouriteChanceType }>()
);

export const favChanceTypeRemoveFailure = createAction(
  '[Favourites] Remove favourite chancetype fail'
);
