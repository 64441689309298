import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FavoritesEffects } from './favorites.effects';
import { FAVORITES_STATE_KEY, reducer } from './favorites.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(FAVORITES_STATE_KEY, reducer),
    EffectsModule.forFeature([FavoritesEffects])
  ]
})
export class FavoritesStoreModule {}
