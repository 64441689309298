<ion-list id="filterModal" class="ion-no-padding">
  <ion-list-header class="ion-no-padding ion-text-center">
    <ion-label class="popup-title">
      {{ 'universal_filter_label' | translate }}
    </ion-label>
  </ion-list-header>
  <ion-radio-group
    mode="md"
    [value]="filterData.selectedOption"
    class="radio-group ion-padding-vertical"
    (ionChange)="onTicketTypeButtonSelected($event)"
  >
    <ion-item
      mode="md"
      *ngFor="let itemFilter of filterData.availableOptions"
      class="time-filter"
      style="--inner-border-width: 0px"
    >
      <ion-radio
        mode="md"
        name="{{ itemFilter.value }}"
        value="{{ itemFilter.value }}"
      ></ion-radio>
      <ion-label mode="md" class="filter-radio ion-padding-horizontal">{{
        itemFilter.label
      }}</ion-label>
    </ion-item>
  </ion-radio-group>
  <ion-grid class="ion-no-padding">
    <ion-row>
      <div
        class="grid grid-cols-2 w-full text-sm text-gray-400 whitespace-nowrap"
        *ngIf="filterData"
      >
        <span (click)="pickerFrom.open()" class="pl-4"
          >{{ 'universal_filter_date_from' | translate }}:
          <b class="text-black">{{ dayLabel(pickerFromInput.value) }}</b></span
        >
        <span (click)="pickerTo.open()" class="pl-4"
          >{{ 'universal_filter_date_to' | translate }}:
          <b class="text-black">{{ dayLabel(pickerToInput.value) }}</b></span
        >
      </div>
      <div class="hidden">
        <input
          #pickerFromInput
          [matDatepicker]="pickerFrom"
          [min]="filterData.filterDate.minDate || null"
          [max]="maximalDateFrom"
          [value]="filterData.filterDate.from"
          (dateChange)="dateFromChange($event)"
        />
        <input
          #pickerToInput
          [matDatepicker]="pickerTo"
          [min]="minimalDateTo"
          [max]="maximalDateTo"
          [value]="filterData.filterDate.to"
          (dateChange)="dateToChange($event)"
        />
        <mat-datepicker touchUi #pickerFrom></mat-datepicker>
        <mat-datepicker touchUi #pickerTo></mat-datepicker>
      </div>
    </ion-row>
    <ion-row class="popup-buttons">
      <ion-col size="6" class="ion-no-padding">
        <ion-button
          (click)="closeModal()"
          class="button bold ion-no-margin ion-no-padding"
          expand="full"
          fill="clear"
        >
          {{ 'universal_filter_reset' | translate }}</ion-button
        >
      </ion-col>
      <ion-col size="6" class="ion-no-padding">
        <ion-button
          (click)="closeModal(true)"
          class="button bold ion-no-margin ion-no-padding"
          expand="full"
          fill="clear"
        >
          {{ 'universal_filter_confirm' | translate }}</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-list>
