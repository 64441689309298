import { environment as defaultEnvironment } from './environment.default';
import { Environment } from './types/Environment';

// export const environment: Environment = {
//   ...defaultEnvironment,
//   production: true,  // true, false,
//   apiUrl: 'https://m-api-responsive.tembobet.co.tz/',  // 'https://m-api-responsive.tembobet.co.tz',
//   language: 'en',  //  'sk',  'en',
//   name: 'production-tza', // 'production-tza', 'production-svk',   'staging-lbr',
//   location: 'tza',  // tza
//   hubConnection: 'https://offer-signalr-mobile-tza.tembobet.co.tz/signalr', // 'https://offer-signalr-mobile-tza.tembobet.co.tz/signalr' 'https://offer-signalr-tza.doxxbet.sk/signalr' 'https://offer-signalr-mobile-staging.doxxbet.sk/signalr', 'https://offer-signalr-mobile-svk.doxxbet.sk/signalr', ,
//   casinoGameURL: 'https://login-fr3.onegameslink.com/GameLauncher?gameCodeName=', //'https://extstg3-login01.ptstaging.eu/GameLauncher?gameCodeName=', 'https://login-fr.onegameslink.com/GameLauncher?gameCodeName=',
//   casinoURLparam: 'doxxbet.ng', // 'ptstaging3.35', 'doxxbet',
//   configVersion: '1.0' // '1.0'
// };

export const environment: Environment = {
  ...defaultEnvironment,
  production: true,
  apiUrl: 'https://m-api-responsive.tembobet.co.tz',
  language: 'en',
  name: 'staging-sk',
  location: 'tza',
  //hubConnection: 'https://offer-signalr-mobile-staging.doxxbet.sk/signalr',
  hubConnection: 'https://offer-signalr-mobile-tza.tembobet.co.tz/signalr',
  casinoGameURL:
    'https://extstg3-login01.ptstaging.eu/GameLauncher?gameCodeName=',
  casinoURLparam: 'ptstaging3.35',
  configVersion: '1.0'
};
