import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'doxx-register-log-in-modal',
  templateUrl: './register-log-in-modal.component.html',
  styleUrls: ['./register-log-in-modal.component.scss']
})
export class RegisterLogInModalComponent implements OnInit, OnDestroy {
  constructor(private _modalCtrl: ModalController) {}

  /**
   * ngOnInit
   */
  ngOnInit(): void {}

  /** Clear remove fragment from url */
  ngOnDestroy(): void {}

  /**
   * closes modal
   */
  closeModal(): void {
    this._modalCtrl.dismiss();
  }
}
