import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromOffer from './store/landing.reducer';
import { LandingTopBettingEffects } from './store/landing-top-betting/landing-top-betting.effects';
import { LandingTopLeaguesEffect } from './store/landing-top-leagues/landing-top-leagues.effects';
import { LandingVirtualGamesEffects } from './store/landing-virtual-games/landing-virtual-games.effects';
import { LandingMyAccountEffects } from './store/landing-my-account/landing-my-account.effects';
import { LandingWinnersEffects } from './store/landing-winners/landing-winners.effects';
import { LandingCasinoWinnersEffects } from './store/landing-winners/landing-winners.effects';
import { LandingBannerEffects } from './store/landing-banner/landing-banner.effects';
import { LandingAnnouncementEffects } from './store/landing-announcement/landing-announcement.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(fromOffer.LANDING_FEATURE_KEY, fromOffer.reducer),
    EffectsModule.forFeature([
      LandingTopBettingEffects,
      LandingTopLeaguesEffect,
      LandingVirtualGamesEffects,
      LandingMyAccountEffects,
      LandingWinnersEffects,
      LandingCasinoWinnersEffects,
      LandingBannerEffects,
      LandingAnnouncementEffects
    ])
  ]
})
export class LandingStorageModule {}
