import { OfferResponse } from './offerResponse';

/** transforms response from main menu to unified response */
export function transformMainMenuResponse(data): OfferResponse {
  const tree: OfferResponse['tree'] = {};
  const sports: OfferResponse['sports'] = {};
  const events: OfferResponse['events'] = {};
  const odds: OfferResponse['odds'] = {};
  const regions: OfferResponse['regions'] = {};
  const leagues: OfferResponse['leagues'] = {};
  const phases: OfferResponse['phases'] = {};
  let stream: OfferResponse['stream'] = null;

  data.forEach(sport => {
    if (['SP', 'SE'].includes(sport.Typ)) {
      sports[sport.ID] = sport;
      tree[sport.ID] = tree[sport.ID] || {
        key: `SP_${sport.ID}`,
        typ: 'SP',
        order: sport.Order,
        sportID: sport.ID,
        children: {}
      };
    } else if (sport.Typ === 'ST') {
      stream = {
        ...stream,
        eventCount: sport.EventCount
      };
    }
  });

  return {
    sports,
    regions,
    leagues,
    events,
    phases,
    odds,
    tree,
    stream
  };
}
