import { EntityState } from '@ngrx/entity';
import { Sport } from '@models/Sport';
import { Event } from '@models/Event';
import { Odd } from '@models/Odd';
import { parseScore } from '@core/functions/parseScore';
import { createReducer, on } from '@ngrx/store';
import { offerActions } from '@store/actions';
import { League } from '@models/League';
import { Region } from '@models/Region';
import { OfferResponse } from '@core/services/offer/offerResponse';
import { Label } from '@models/Label';
export const OFFER_STATE_KEY = 'offer';
export interface SectionState {
  sports: EntityState<Sport>;
  regions: EntityState<Region>;
  leagues: EntityState<League>;
  events: EntityState<Event>;
  odds: EntityState<Odd>;
}
/**
 * OFFER STATE
 */
export interface State {
  tree: {
    [key: string]: {
      key: string;
    };
  };

  stream: {
    eventCount: number;
    streamList: Event['EventID'][];
    loaded: boolean;
    timestamp: number;
    error: any;
  };
  events: Record<Event['EventID'], Event>;
  phases: Record<Label['ID'], Label>;
  leagues: Record<League['ID'], League>;
  regions: Record<Region['ID'], Region>;
  sports: Record<Sport['ID'], Sport>;
  odds: Record<string, Odd>;
}

const initialState: State = {
  stream: {
    eventCount: 0,
    streamList: [],
    loaded: false,
    timestamp: null,
    error: null
  },

  events: {},
  phases: {},
  leagues: {},
  regions: {},
  sports: {},
  odds: {},
  tree: {}
};

/**
 * Reducer for managing state of offer
 * @param state current state of application
 * @param action dispatched action
 */
export const reducer = createReducer(
  initialState,
  on(offerActions.updateOffer, (state, action) => ({
    ...state,
    leagues: {
      ...state.leagues,
      ...action.leagues
    },
    sports: {
      ...state.sports,
      ...action.sports
    },
    regions: {
      ...state.regions,
      ...action.regions
    },
    events: {
      ...state.events,
      ...action.events
    },
    odds: {
      ...state.odds,
      ...action.odds
    },
    phases: {
      ...state.phases,
      ...action.phases
    },
    tree: {
      ...state.tree,
      [action.section]: {
        ...state.tree[action.section],
        [action.date || '-1']: mergeTrees(
          state.tree[action.section]?.[action.date || '-1'] || {},
          action.tree,
          action.delta
        )
      }
    },
    stream: {
      ...state.stream,
      ...action.stream
    }
  })),
  // UPDATE ODDS
  on(offerActions.updateOdds, (state, action) => ({
    ...state,
    odds: updateMany(action.payload, state.odds)
  })),
  // UPDATE EVENTS
  on(offerActions.updateEvents, (state, action) => ({
    ...state,
    events: updateMany(
      action.payload.map(change => ({
        ...change,
        changes: {
          ...change.changes,
          ParsedScore: state.events[change.id]
            ? parseScore(change.changes.Score, state.events[change.id].SportID)
            : null
        }
      })),
      state.events
    )
  })),
  on(offerActions.loadStreamScheduleSuccess, (state, action) => ({
    ...state,
    stream: {
      ...state.stream,
      loaded: true
    }
  })),
  on(offerActions.loadStreamScheudleFailure, (state, action) => ({
    ...state,
    stream: {
      ...state.stream,
      loaded: true,
      error: action.error
    }
  }))
);

function mergeTrees(
  target,
  source,
  delta: 'sports' | 'regions' | 'leagues' | 'events' = null,
  level: 'sports' | 'regions' | 'leagues' | 'events' = 'sports'
): any {
  if (isObject(target) && isObject(source)) {
    if (delta === level) {
      for (const key in target) {
        if (target[key]) {
          target[key].visible = false;
        }
      }
    }
    for (const key in source) {
      if (source[key]) {
        const { children: sourceChildren, ...sourceRest } = source[key];
        const { children: targetChildren, ...targetRest } = target[key] || {};
        target[key] = {
          ...targetRest,
          ...sourceRest,
          visible: true,
          order: !isNaN(targetRest.order)
            ? targetRest.order
            : !isNaN(sourceRest.order)
            ? sourceRest.order
            : null,
          children:
            sourceChildren || targetChildren
              ? mergeTrees(
                  targetChildren || {},
                  sourceChildren,
                  delta,
                  sourceRest.typ === 'SP'
                    ? 'sports'
                    : sourceRest.typ === 'RE'
                    ? 'regions'
                    : sourceRest.typ === 'LC'
                    ? 'leagues'
                    : 'events'
                )
              : null
        };
      }
    }
  }
  return target;
}

/**
 * Simple object check.
 */
function isObject(item): boolean {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/** updates many objects in state */
function updateMany(changes, state): typeof state {
  changes.forEach(change => {
    state = {
      ...state,
      [change.id]: {
        ...state[change.id],
        ...change.changes
      }
    };
  });
  return state;
}
