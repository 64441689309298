import { Config } from '@configurationTypes/config';
import { ConfigOverride } from './types/configOverride';

// Configuration will be loaded from service
export const configuration: Readonly<ConfigOverride> = {} as any;

export type TypeEnum = 'mainitem' | 'subitem';

/** upravi config pre potreby appky */
export function transformConfig(config: Config): ConfigOverride {
  const override: ConfigOverride = config as any;

  override.base.languages = config.base.languages.map(lang => ({
    ...lang,
    locale: lang.locale.replace('_', '-')
  }));

  override.base.currencies = config.base.currencies.map(curr => ({
    ...curr,
    locale: curr.locale.replace('_', '-'),
    symbolPosition: /^€/.test(
      (1)
        .toLocaleString(curr.locale.replace('_', '-'), {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
        .replace('EUR', '€')
        .replace(/\s*/g, '')
    )
      ? 'start'
      : 'end'
  }));

  override.base.defaultCurrency = override.base.currencies.find(
    curr => curr.code === (config.base.defaultCurrency as any)
  );

  override.responsibleGaming.modalAllowed = !config.responsibleGaming
    .modalAllowed
    ? false
    : config.responsibleGaming.bettingAllowed ||
      config.responsibleGaming.casinoAllowed;

  override.virtualGames.sports = (config.virtualGames.sports as any[]).reduce(
    (acc, sport) => ({
      ...acc,
      [sport.id]: {
        ...sport,
        allowed: sport.allowed ? sport.leagues.some(l => l.allowed) : false,
        leagues: sport.leagues.reduce(
          (_acc, league) => ({
            ..._acc,
            [league.id]: {
              ...league,
              allowed: !sport.allowed ? false : league.allowed
            }
          }),
          {}
        )
      }
    }),
    {}
  );

  override.verification.allowed =
    config.verification.allowed &&
    [
      config.verification.account,
      config.verification.bankAccount,
      config.verification.creditCard,
      config.verification.twoId
    ].some(allowed => allowed);

  override.verification.account =
    config.verification.allowed && config.verification.account;
  override.verification.bankAccount =
    config.verification.allowed && config.verification.bankAccount;
  override.verification.creditCard =
    config.verification.allowed && config.verification.creditCard;
  override.verification.twoId =
    config.verification.allowed && config.verification.account;

  return override;
}
