import { Component, Input } from '@angular/core';
import { TicketHistoryListItem } from '@models/TicketHistory';
import { configuration } from '@configuration';

@Component({
  selector: 'doxx-ticket-list-item',
  templateUrl: './ticket-list-item.component.html',
  styleUrls: ['./ticket-list-item.component.scss']
})
export class TicketListItemComponent {
  @Input() isStoredTicket = false;
  @Input() buybackValue: number;
  @Input() ticket: TicketHistoryListItem;
  configuration = configuration;

  constructor() {}
}
