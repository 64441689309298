import { Injectable } from '@angular/core';
import { AnnouncementService } from '@core/services/announcement/announcement.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { landingActions } from '@store/actions';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class LandingAnnouncementEffects {
  loadAnnouncement$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(landingActions.loadAnnouncement),
      switchMap(() =>
        this._announcementService.loadAnnouncement().pipe(
          map(announcement =>
            landingActions.loadAnnouncementSuccess({ announcement })
          ),
          catchError(error => of(landingActions.loadAnnouncementFailure()))
        )
      )
    );
  });

  constructor(
    private _actions$: Actions,
    private _announcementService: AnnouncementService
  ) {}
}
