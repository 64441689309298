import { createReducer, on } from '@ngrx/store';
import { landingActions } from '@store/actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Odd } from '@models/Odd';
import { TopEvent } from '@models/TopEvent';
import { parseScore } from '@core/functions/parseScore';

export type TopBettingDateFilter = 'TD' | 'LIVE' | '-1';
export interface State {
  filter: {
    date: TopBettingDateFilter;
    count: number;
  };
  events: EntityState<TopEvent>;
  odds: EntityState<Odd>;
  topRateSum: number;
  visibleEventIds: { [date: string]: number[] };
}

const initialTopBettingState: State = {
  filter: {
    date: 'TD',
    count: 5
  },
  events: {
    ids: [],
    entities: {}
  },
  odds: {
    ids: [],
    entities: {}
  },
  topRateSum: 0,
  visibleEventIds: {}
};

export const eventAdapter = createEntityAdapter<TopEvent>({
  selectId: entity => entity.EventID
});

export const oddAdapter = createEntityAdapter<Odd>({
  selectId: entity => entity.OddsID
});

export const topBettingReducer = createReducer(
  initialTopBettingState,
  on(landingActions.setTopBettingFilter, (state, action) => ({
    ...state,
    filter: {
      ...state.filter,
      ...action.filter
    }
  })),
  on(landingActions.loadTopBettingSuccess, (state, action) => ({
    ...state,
    events: eventAdapter.upsertMany(
      action.events?.map(event => ({
        ...event,
        ParsedScore: parseScore(event.Score, event.SportID)
      })),
      state.events
    ),
    odds: oddAdapter.upsertMany(action.odds, state.odds),
    topRateSum: action.topRateSum,
    visibleEventIds: {
      ...state.visibleEventIds,
      [state.filter.date]: action.events?.map(event => event.EventID)
    }
  })),
  // UPDATE ODDS
  on(landingActions.updateOdds, (state, action) => ({
    ...state,
    odds: oddAdapter.updateMany(action.payload, state.odds)
  })),
  // UPDATE EVENTS
  on(landingActions.updateEvents, (state, action) => ({
    ...state,
    events: eventAdapter.updateMany(action.payload, state.events)
  }))
);
