import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { landingActions } from '@store/actions';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TickethistoryService } from 'src/app/pages/betslip/tickethistory/tickethistory.service';
import { HttpClient } from '@angular/common/http';
import { oneLine } from '@core/functions/oneLine';
import { environment } from '@environment';
import {
  loadMyCasionWinsFailure,
  loadMyCasionWinsSuccess,
  loadBonusesStatusFailure
} from './landing-my-account.actions';
import { ProfileService } from '@core/services/profile/profile.service';

@Injectable()
export class LandingMyAccountEffects {
  loadCasinoWins$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(landingActions.loadMyCasionWins),
      switchMap(action => {
        // TODO: replace with service
        return this._http
          .get<any>(
            oneLine`${environment.apiUrl}/web/casino/transactions
              /TransactionType/win_casino
              /DateFrom/${new Date(
                new Date().setDate(new Date().getDate() - 60)
              ).toISODateString()}
              /DateTo/${new Date().toISODateString()}
              /PageSize/10
              /PageNumber/1`,
            {
              withCredentials: true
            }
          )
          .pipe(
            map(data =>
              loadMyCasionWinsSuccess({ wins: data.Result.Transactions })
            ),
            catchError(err => of(loadMyCasionWinsFailure({ error: err })))
          );
      })
    );
  });

  loadBonusesStatus$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(landingActions.loadBonusesStatus),
      switchMap(action =>
        this._profileService.getBonusesStatus().pipe(
          map(hasActiveBonuses =>
            landingActions.loadBonusesStatusSuccess({
              hasActiveBonuses
            })
          ),
          catchError(err => of(loadBonusesStatusFailure({ error: err })))
        )
      )
    );
  });

  constructor(
    private _actions$: Actions,
    private _ticketHistoryService: TickethistoryService,
    private _http: HttpClient,
    private _profileService: ProfileService
  ) {}
}
