import { Injectable } from '@angular/core';
import { BannersService } from '@core/services/banners/banners.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { landingActions } from '@store/actions';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class LandingBannerEffects {
  loadBettingBanners$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(landingActions.loadBettingBanners),
      switchMap(() =>
        this._bannersService.loadBettingBanners().pipe(
          map(banners => landingActions.loadBettingBannersSuccess({ banners })),
          catchError(error => of(landingActions.loadBettingBannersFailure()))
        )
      )
    );
  });

  loadCasinoBanners$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(landingActions.loadCasinoBanners),
      switchMap(() =>
        this._bannersService.loadCasinoBanners().pipe(
          map(banners => landingActions.loadCasinoBannersSuccess({ banners })),
          catchError(error => of(landingActions.loadCasinoBannersFailure()))
        )
      )
    );
  });

  loadRioBanners$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(landingActions.loadRioBanners),
      switchMap(() =>
        this._bannersService.loadRioBanners().pipe(
          map(banners => landingActions.loadRioBannersSuccess({ banners })),
          catchError(error => of(landingActions.loadRioBannersFailure()))
        )
      )
    );
  });

  constructor(
    private _actions$: Actions,
    private _bannersService: BannersService
  ) {}
}
