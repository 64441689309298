<div
  id="ticketEvents"
  *ngIf="_ticket"
  class="content-contains-ticket-statuses"
  [ngClass]="{ regular: style === 'regular', buyback: style === 'buyback' }"
>
  <ion-grid class="combi-grid ion-no-padding" *ngIf="_ticket.type === 'SRT'">
    <ion-row
      (click)="oddsCombiExpanded = !oddsCombiExpanded"
      class="combi-button is-anchor ion-padding"
    >
      <ion-col size="10  " class="bold">
        <span> {{ 'ticket_combs' | translate }}</span>
      </ion-col>

      <ion-col class="ion-text-center">
        <i
          class="icon icon-ic_arrow icon-expand-collapse"
          [@openCloseArrow]="oddsCombiExpanded"
        ></i>
      </ion-col>
    </ion-row>
    <ng-container *ngIf="_ticket.oddsCombi && oddsCombiExpanded">
      <ion-row class="combinations-header ion-padding">
        <ion-col class="ion-text-left">
          {{ 'ticket_group' | translate }}
        </ion-col>
        <ion-col class="ion-text-right">
          {{ 'app_bet' | translate }}
        </ion-col>
        <ion-col class="ion-text-right">
          {{ 'ticket_course' | translate }}
        </ion-col>

        <ion-col class="ion-text-right">
          {{ 'history_win' | translate }}
        </ion-col>
      </ion-row>
      <ion-row
        *ngFor="
          let oddCombi of _ticket.oddsCombi;
          trackBy: 'id' | trackByProperty
        "
        class="combination-row ion-padding"
      >
        <ion-col class="group ion-text-left bold">
          {{ oddCombi.id }}
        </ion-col>
        <ion-col class="bet ion-text-right">
          {{ oddCombi.bet | formatNumberWithCurrency }}
        </ion-col>
        <ion-col class="rate ion-text-right">
          {{ oddCombi.rateTicket | rate }}  
        </ion-col>

        <ion-col
          class="pot-win ion-text-right bold"
          [ngClass]="{
            won: oddCombi.winningStatus === 'Y',
            lost: oddCombi.winningStatus === 'N'
          }"
        >
          {{ oddCombi.potWin | formatNumberWithCurrency }}
        </ion-col>
      </ion-row>
    </ng-container>
  </ion-grid>
  <div
    class="px-5 pt-1 w-full flex items-center justify-between"
    style="margin: 15px 0px"
    *ngIf="_ticket.odds?.length > 1 && _ticket.type !== 'SRT'"
  >
    <div class="text-sm text-slate font-bold">{{ 'ticket_history_detail_matches_order_title' | translate}}</div>
    <mat-button-toggle-group
      name="matchOrder"
      class="xx-button-row flex-none time-order"
      value="{{ order }}"
    >
      <mat-button-toggle
        value="default"
        class="flex-none text-xs rounded border border-slate-75 py-1 px-3 text-slate-250 active:bg-slate-75"
        (click)="orderBy('default')"
      >
        {{
          'ticket_history_detail_matches_order_switch_add_time' | translate
        }}</mat-button-toggle
      >
      <mat-button-toggle
        value="time"
        class="flex-none text-xs rounded border border-slate-75 py-1 px-3 text-slate-250 bg-white !text-slate"
        (click)="orderBy('time')"
      >
        {{
          'ticket_history_detail_matches_order_switch_play_time' | translate
        }}</mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
  <ion-grid
    style="--ion-grid-columns: 24"
    class="events-grid ion-no-padding"
    [ngClass]="{ 'ion-padding-horizontal': style === 'regular' }"
  >
    <ion-list class="ion-no-padding ticket-list">
      <ng-container
        *ngFor="
          let odd of this._ticket.odds;
          trackBy: 'eventID' | trackByProperty
        "
      >
        <ion-item
          id="ticket-odds-container"
          [ngClass]="{
            'buyback-bg': style === 'buyback',
            'expanded-score': odd.extras.isScoreExpanded
          }"
        >
          <div class="wawe" *ngIf="style === 'regular'"></div>
          <ion-label
            class="ion-margin-right overflow-hidden"
            [ngClass]="{ Y: 'won', N: 'lost', BB: 'won' }[odd.winningStatus]"
          >
            <ion-row style="overflow: visible">
              <ion-col size="3" *ngIf="_ticket.type === 'SRT'">
                <div style="height: 50%"></div>
                <div class="group-labels">
                  <div
                    *ngIf="
                      odd.extras.combinations.GroupValue === '.' ||
                      odd.extras.combinations.GroupValue === 'X'
                    "
                    class="line"
                    [ngClass]="{ green: odd.group === '1' }"
                  ></div>
                  <div
                    *ngIf="odd.extras.combinations.drowline2"
                    class="line2"
                    [ngClass]="{ green: odd.group === '1' }"
                  ></div>
                  <div
                    *ngIf="odd.extras.combinations.GroupValue !== '.'"
                    class="circle"
                    [ngClass]="{ green: odd.group === '1' }"
                  >
                    <div
                      *ngIf="
                        odd.extras.combinations.GroupValue !== '1' &&
                        odd.extras.combinations.GroupValue !== 'X'
                      "
                    >
                      {{ odd.extras.combinations.GroupValue }}
                    </div>
                    <div
                      *ngIf="
                        odd.extras.combinations.GroupValue !== '1' &&
                        odd.extras.combinations.GroupValue === 'X'
                      "
                    >
                      {{ odd.extras.combinations.GroupValue }}
                    </div>
                    <div
                      *ngIf="odd.extras.combinations.GroupValue === '1'"
                      class="green-dot"
                    ></div>
                  </div>
                  <div
                    *ngIf="odd.extras.combinations.GroupValue === '.'"
                    class="red-dot"
                    [ngClass]="{ green: odd.group === '1' }"
                  ></div>
                </div>
              </ion-col>
              <ion-col size="{{ _ticket.type === 'SRT' ? 21 : 24 }}">
                <ion-row *ngIf="style !== 'buyback'">
                  <ion-col>
                    <ion-badge
                      [style]="
                        +odd.sportId
                          | sportTransform : false : false : true : false
                      "
                      >{{ odd.sportName }}</ion-badge
                    >
                    <ion-badge
                      [style]="
                        +odd.sportId
                          | sportTransform : false : false : true : false
                      "
                      >{{ odd.regionName }}</ion-badge
                    >
                    <ion-badge
                      [style]="
                        +odd.sportId
                          | sportTransform : false : false : true : false
                      "
                      >{{ odd.leagueCupName }}</ion-badge
                    >
                  </ion-col>

                  <ion-col size="8" class="event-date-time-col">
                    <span
                      class="event-date-time"
                      *ngIf="!odd.eventLiveActivity"
                    >
                      {{ odd.date | formatDate : 'YYYYMMDDhhmm' }}
                    </span>
                  </ion-col>
                </ion-row>
                <!-- <ion-row *ngIf="style !== 'buyback'">
                  <ion-col>
                    <span
                      class="event-date-time"
                      *ngIf="odd.statusMessage !== 'playing '"
                    >
                      {{ odd.date | formatDate: 'YYYYMMDDhhmm' }}
                    </span>
                  </ion-col>
                </ion-row> -->
                <ion-row class="vs-row" >
                  <ion-col size="20">
                    <span
                      class="teams-wrapper is-anchor"
                      (click)="showEventDetail(odd)"
                    >
                      <span
                        *ngIf="odd.liveBet && !this.odd.extras.isVirtual"
                        class="live-small-icon icon-ic_live-small"
                      >
                        &nbsp;
                      </span>
                      <span
                        *ngIf="this.odd.extras.isVirtual"
                        class="virtual-small-icon icon-ic_virtural-games-small"
                      >
                        &nbsp;
                      </span>
                      <span class="team-name">{{ odd.team1 }}</span>

                      <span
                        *ngIf="
                          !odd.extras.parsedScore &&
                          odd.team2 &&
                          odd.team1 !== odd.team2
                        "
                        class="vs"
                        >vs.</span
                      >
                      <span
                        *ngIf="
                          odd.extras.parsedScore?.home.points !== null &&
                          odd.extras.parsedScore?.away.points !== null
                        "
                        class="score"
                        [ngClass]="{
                          'live-score': odd.eventLiveActivity
                        }"
                      >
                        &nbsp;{{
                          odd.extras.parsedScore.home.points +
                            ' : ' +
                            odd.extras.parsedScore.away.points
                        }}&nbsp;
                      </span>
                      <ng-container
                        *ngIf="
                          odd.extras.parsedScore &&
                          odd.sportId === '53' &&
                          !odd.eventLiveActivity
                        "
                      >
                        <span
                          *ngIf="odd.extras.parsedScore.penalty"
                          class="score"
                          >({{ 'ticket_result_penalties_hockey' | translate }})
                        </span>
                        <span
                          *ngIf="
                            odd.extras.parsedScore.overtime &&
                            !odd.extras.parsedScore.penalty
                          "
                          class="score"
                        >
                          ({{ 'ticket_result_overtime' | translate }})
                        </span>
                      </ng-container>
                      <ng-container
                        *ngIf="
                          odd.extras.parsedScore &&
                          odd.sportId !== '53' &&
                          !odd.eventLiveActivity
                        "
                      >
                        <span
                          *ngIf="odd.extras.parsedScore.penalty"
                          class="score"
                        >
                          ({{ 'ticket_result_penalties' | translate }})
                        </span>
                        <span
                          *ngIf="
                            odd.extras.parsedScore.overtime &&
                            !odd.extras.parsedScore.penalty
                          "
                          class="score"
                        >
                          ({{ 'ticket_result_overtime' | translate }})
                        </span>
                      </ng-container>
                      <span
                        *ngIf="odd.team2 && odd.team1 !== odd.team2"
                        class="team-name"
                        >{{ odd.team2 }}</span
                      >
                    </span>
                    <span
                      class="container-icon"
                      (click)="showTooltip(odd)"
                      *ngIf="
                        ((odd?.eventInfo?.comment ||
                          odd?.eventInfo?.aggregateScore ||
                          odd?.eventInfo?.seriesResult) &&
                          odd.eventStatus !== 'ended') ||
                        (odd?.eventInfo?.resultComment &&
                          odd.eventStatus === 'ended')
                      "
                    >
                      <i class="icon icon-ic_info-circle-filled"></i>
                    </span>
                  </ion-col>

                  <ion-col
                    size="4"
                    *ngIf="odd.scoreNew && !odd.eventLiveActivity"
                    (click)="
                      odd.extras.isScoreExpanded = !odd.extras.isScoreExpanded
                    "
                    class="ion-text-center is-anchor"
                  >
                    <i
                      class="icon icon-ic_arrow icon-expand-collapse score-expander"
                      [@openCloseArrow]="odd.extras.isScoreExpanded === true"
                    ></i>
                  </ion-col>
                </ion-row>
                <ion-row>
                  
                  <ion-col>
                    <ion-text
                      class="chance-type"
                      [innerHTML]="
                        odd.chanceType
                          | replace
                            : ('betBuilder.oddsDelimiter' | env)
                            : '<br>'
                      "
                    >
                    </ion-text>
                  </ion-col>
                  
                  <div
                    class="mr-5 text-right text-green pb-0.5"
                    *ngIf="
                      !_ticket.buyBackActivity && odd.winningStatus === 'BB'
                    "
                  >
                    <i class="icon-ic_u-in-circle-stroke text-xl"></i>
                  </div>
                  <ion-col *ngIf="odd.rate==='1.00' && odd.winningStatus==='Y'" class="ion-text-center" size="8" style="color: #7E848A; font-size: 12px;">
                    <ion-text
                   >{{'ticket_storno_event' | translate}} </ion-text>
                  </ion-col>
                </ion-row>
                <ion-row class="last-row">
                  <ion-col>
                    <ion-text
                      *ngIf="!odd.betbuilder"
                      [ngClass]="{
                        'bet-name': true,
                        'virtual-text': this.odd.extras.isVirtual
                      }"
                      >{{ odd.tip }}</ion-text
                    >
                  </ion-col>

                  <ion-col size="4" class="mr-5 text-right">
                    <ion-badge class="rate ion-text-center">
                      {{
                        (!_ticket.buyBackActivity ? odd.rate : odd.rateTicket)
                          | rate
                      }} 
                    </ion-badge>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="odd.winningStatus === 'S'">
                  <ion-col size="ion-text-end">{{
                    'ticket_canceled' | translate
                  }}</ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
            <ion-row>
              <doxx-event-score-table
                *ngIf="odd.scoreNew"
                [isFromTicketHistory]="true"
                [ngClass]="{ expanded: odd.extras.isScoreExpanded }"
                [sportID]="+odd.sportId"
                [actualGamePartID]="odd.actualGamePartId"
                [homeTeam]="{ name: odd.team1, ID: odd.team1Id }"
                [awayTeam]="{ name: odd.team2, ID: odd.team2Id }"
                [liveBettingView]="odd.eventLiveActivity"
                [isLive]="odd.extras.isSignalrConnected"
                [actualGamePartTime]="odd.extras.actualGamePartTime"
                [rawScore]="odd.scoreNew"
              ></doxx-event-score-table>
            </ion-row>
          </ion-label>
        </ion-item>
      </ng-container>
    </ion-list>
    <div class="xx-ticket-vertical-small-end w-full h-[6px]"></div>
  </ion-grid>
</div>
