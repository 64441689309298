<div
  [@.disabled]="locked"
  doxxInViewport
  (inViewportChange)="onViewportChange($event)"
>
  <div
    class="odd"
    [@oddRateChanged]="odd.OddsRate"
    [ngClass]="{
      locked: locked && !isResultOdd,
      winning: odd.OddsWinning === 'Y',
      'on-ticket': isOnTicket
    }"
    (click)="onAddOddOnTicket()"
  >
    <img
      class="odd-team-img"
      loading="lazy"
      [src]="iconUrl"
      *ngIf="odd.CompetitorID && showIcon"
      (error)="showIcon = false"
    />
    <div class="odd-name">
      <span *ngIf="!showIcon">{{ missIconText }} &nbsp;</span>
      <ng-container *ngIf="oddType === 'NAMING'">
        {{ odd.OddNameNaming || odd.OddNameNumbering }}
      </ng-container>
      <ng-container *ngIf="oddType === 'NUMBERING'">
        {{ odd.OddNameNumbering }}
      </ng-container>
    </div>
    <div
      class="odd-icon-stats"
      *ngIf="('statistics' | available) && odd.BetradarPlayerID"
    >
      <i
        class="icon icon-ic_stats"
        routerLink="."
        queryParamsHandling="merge"
        [queryParams]="{
          tab: 'stats',
          statsView: 'players',
          o1: odd.BetradarPlayerID
        }"
        (click)="$event.stopPropagation()"
      ></i>
    </div>
    <div class="odd-rate" class="odd-icon-lock">
      <i class="icon icon-ic_lock"></i>
    </div>
    <div class="odd-rate" [@oddRateNumbers]="odd.OddsRate">
      <span> {{ odd.OddsRate > 1 ? (odd.OddsRate | rate) : '-' }}</span>
    </div>

    <!-- <i [@oddRateArrow]="odd.OddsRate" class="icon icon-ic_arrow rate-icon"></i> -->
    <!-- <i [@oddRateArrow]="odd.OddsRate" class="icon icon-ic_arrow rate-icon">▲</i> -->

    <i [@oddRateArrow]="odd.OddsRate" class="icon icon-ic_arrow rate-icon">▸</i>
    <!-- <i [@oddRateArrowDown]="odd.OddsRate"
      ><img src="../../../../assets/images/rate-down.svg" alt=""
    /></i> -->
  </div>
</div>
