import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { Odd } from '@models/Odd';
import { EventChanceType } from '@models/EventChanceType';
import { Label } from '@models/Label';
import { ChanceTypeGroup } from '@models/ChanceTypeGroup';
import { ParamGroup } from '@models/ParamGroup';
import * as detailActions from './detail.actions';
import { Event } from '@models/Event';
import { parseScore } from '@core/functions/parseScore';
import {
  getEventChanceTypeVisibility,
  getEventVisibility,
  getOddVisibility
} from '@core/functions/visibilityFunctions';
import { visibility } from '@models/Visibility';
import { createReducer, on } from '@ngrx/store';
import { EVENT_TYPE } from '@constants';

export const EVENT_DETAIL_STATE_KEY = 'eventDetail';

const emptyEntity: EntityState<any> = {
  ids: [],
  entities: {}
};

export type EventDetailState = {
  event: Event & { betradarTeams: any };
  odds: EntityState<Odd>;
  eventChanceTypes: EntityState<EventChanceType>;
  labels: { [labelId: string]: Label };
  chanceTypeGroups: ChanceTypeGroup[];
  chanceTypeParamGroups: ParamGroup[];
  selectedChancetypeGroupID: string;
  section: 'live' | 'results' | 'prematch' | 'virtual';
};

const initialState: EventDetailState = {
  event: null,
  odds: emptyEntity,
  eventChanceTypes: emptyEntity,
  labels: null,
  chanceTypeGroups: [],
  chanceTypeParamGroups: [],
  selectedChancetypeGroupID: '',
  section: undefined
};

const emptyEventState: EventDetailState = {
  ...initialState
};

export const eventDetailOddAdapter = createEntityAdapter<Odd>({
  selectId: entity => entity.OddsID
});

export const eventChanceTypeAdapter = createEntityAdapter<EventChanceType>({
  selectId: entity => entity.EventChanceTypeID
});

const oddAdapter = createEntityAdapter<Odd>({
  selectId: entity =>
    entity.OddsID
      ? entity.OddsID + ''
      : entity.EventChanceTypeID + '_' + entity.TipType // default id for results
});

export const detailReducer = createReducer(
  initialState,
  on(detailActions.loadEventDetailStart, (state, action) => ({
    ...initialState,
    section: action.options.section
  })),
  on(detailActions.loadEventDetailSuccess, handleEventDetailFetch),
  on(detailActions.refreshEventDetailSuccess, handleEventDetailFetch),
  on(detailActions.updateOdds, (state, action) => {
    let newState = { ...state };
    let i = 0;
    while (action.payload[i]) {
      if (newState.odds.entities[action.payload[i].id]) {
        let newOdd = {
          ...newState.odds.entities[action.payload[i].id],
          OddsRate: action.payload[i].changes.OddsRate
        };
        if (action.payload[i].changes.Status !== newOdd.Status) {
          newOdd = {
            ...newOdd,
            Status: action.payload[i].changes.Status,
            OldStatus: newState.odds.entities[action.payload[i].id].Status
          };
          newOdd = {
            ...newOdd,
            Visibility:
              state.section === 'results'
                ? visibility.SHOW
                : getOddVisibility(
                    newOdd,
                    state.selectedChancetypeGroupID,
                    state.event.Visibility
                  )
          };
        }
        newState = {
          ...newState,
          odds: {
            ...newState.odds,
            entities: {
              ...newState.odds.entities,
              [action.payload[i].id]: newOdd
            }
          }
        };
      }
      i++;
    }
    return newState;
  }),
  on(detailActions.updateEvent, (state, action) => {
    if (state.event && state.event.EventID === action.event.EventID) {
      let newEvent = {
        ...state.event,
        ...action.event
      };
      newEvent = {
        ...newEvent,
        Visibility:
          state.section === 'results'
            ? visibility.SHOW
            : getEventVisibility(newEvent)
      };
      let newOdds = { ...state.odds };
      let newEcts = { ...state.eventChanceTypes };
      if (state.event.Visibility !== newEvent.Visibility) {
        newOdds = oddAdapter.map(odd => {
          return {
            ...odd,
            Visibility:
              state.section === 'results'
                ? visibility.SHOW
                : getOddVisibility(
                    odd,
                    state.selectedChancetypeGroupID,
                    newEvent.Visibility
                  )
          };
        }, newOdds);
        newEcts = eventChanceTypeAdapter.map(ect => {
          let newEct = { ...ect, OldEventChanceTypeStatus: null };
          newEct = {
            ...newEct,
            Visibility:
              state.section === 'results'
                ? visibility.SHOW
                : getEventChanceTypeVisibility(
                    newEct,
                    state.selectedChancetypeGroupID,
                    newEvent.Visibility
                  )
          };
          return newEct;
        }, newEcts);
      }
      return {
        ...state,
        event: newEvent,
        odds: newOdds,
        eventChanceTypes: newEcts
      };
    } else {
      return state;
    }
  }),
  on(detailActions.updateEventChanceTypes, (state, action) => {
    let newState = { ...state };
    let i = 0;
    while (action.payload[i]) {
      if (newState.eventChanceTypes.entities[action.payload[i].id]) {
        let newEct = {
          ...newState.eventChanceTypes.entities[action.payload[i].id]
        };
        if (
          action.payload[i].changes.EventChanceTypeStatus !==
          newEct.EventChanceTypeStatus
        ) {
          newEct = {
            ...newEct,
            EventChanceTypeStatus:
              action.payload[i].changes.EventChanceTypeStatus,
            OldEventChanceTypeStatus:
              newState.eventChanceTypes.entities[action.payload[i].id]
                .EventChanceTypeStatus
          };
          newEct = {
            ...newEct,
            Visibility:
              state.section === 'results'
                ? visibility.SHOW
                : getEventChanceTypeVisibility(
                    newEct,
                    state.selectedChancetypeGroupID,
                    state.event.Visibility
                  )
          };
        }
        newState = {
          ...newState,
          eventChanceTypes: {
            ...newState.eventChanceTypes,
            entities: {
              ...newState.eventChanceTypes.entities,
              [action.payload[i].id]: newEct
            }
          }
        };
      }
      i++;
    }
    return newState;
  }),
  on(detailActions.changeChanceTypeGroup, (state, action) => {
    const evVis =
      state.section === 'results'
        ? visibility.SHOW
        : getEventVisibility(state.event);
    return {
      ...state,
      event: {
        ...state.event,
        Visibility: evVis
      },
      selectedChancetypeGroupID: action.ChanceTypeGroupID,
      odds: oddAdapter.map(odd => {
        let newOdd = { ...odd, OldStatus: null };
        newOdd = {
          ...newOdd,
          Visibility:
            state.section === 'results'
              ? visibility.SHOW
              : getOddVisibility(newOdd, action.ChanceTypeGroupID, evVis)
        };
        return newOdd;
      }, state.odds),
      // removes all old statuses, to simulate reload
      eventChanceTypes: eventChanceTypeAdapter.map(ect => {
        let newEct = { ...ect, OldEventChanceTypeStatus: null };
        newEct = {
          ...newEct,
          Visibility:
            state.section === 'results'
              ? visibility.SHOW
              : getEventChanceTypeVisibility(
                  newEct,
                  action.ChanceTypeGroupID,
                  evVis
                )
        };
        return newEct;
      }, state.eventChanceTypes)
    };
  })
);

function handleEventDetailFetch(
  state: EventDetailState,
  action: any
): EventDetailState {
  if (!action.eventDetailData?.Event?.EventID) {
    return emptyEventState;
  } else {
    const [home, away] = action.eventDetailData.Event.EventName.split(' vs.');
    const odds: Odd[] = action.eventDetailData.Odds.map((odd: Odd) => {
      return {
        ...odd,
        OddNameNumbering:
          action.eventDetailData.Labels['TP_' + odd.TipID]?.Name || odd.TipType
      };
    });
    const eventChanceTypes: EventChanceType[] = Object.values(
      action.eventDetailData.EventChanceTypes
    );

    eventChanceTypes.forEach(ect => {
      const ectOdds = odds.filter(
        odd => odd.EventChanceTypeID === ect.EventChanceTypeID
      );

      ectOdds.forEach(ectOdd => {
        // console.log(ectOdd);
        // console.log(action.eventDetailData.Event); //HasBetradarHostedStatistics
        if (ect.BetType === 'VICTORIA_COMPETITOR') {
          ectOdd.OddNameNumbering =
            action.eventDetailData.Labels['CP_' + ectOdd.CompetitorID]?.Name ||
            ectOdd.OddNameNumbering;
        }

        if (ect.BetType === 'VICTORIA_PLAYER') {
          const playerAndCompetitor = [
            action.eventDetailData.Labels['PL_' + ectOdd.PlayerID]?.Name ||
              'Nikto'
          ].filter(val => (val ? true : false));
          ectOdd.OddNameNumbering = playerAndCompetitor.join(' - ');

          // ectOdd.BetradarPlayerID =
          //   'oes' + action.eventDetailData.Event.HomeTeamID
          //     ? action.eventDetailData.Event.HomeTeamID
          //     : action.eventDetailData.Event.AwayTeamID;
          //  ,
          //  action.eventDetailData.Labels['CP_' + ectOdd.CompetitorID]
          //  ?.Name || undefined
        }

        if (
          ect.BetType === 'VICTORIA' &&
          ectOdd.OddNameNumbering?.includes('#')
        ) {
          if (
            action.eventDetailData.Event.HasBetradarHostedStatistics === false
          ) {
            ectOdd.BetradarPlayerID = undefined;
          }
          const playerAndCompetitor = [
            action.eventDetailData.Labels['PL_' + ectOdd.PlayerID]?.Name ||
              'Nikto',
            action.eventDetailData.Labels['CP_' + ectOdd.CompetitorID]?.Name ||
              undefined
          ].filter(val => (val ? true : false));
          ectOdd.OddNameNumbering =
            ectOdd.OddNameNumbering + ' ' + playerAndCompetitor.join(' - ');
        }

        if (ect.ParamGroupID) {
          const paramGroup = action.eventDetailData.ChanceTypeParamGroups.find(
            pg => +pg.ParamGroupID === ect.ParamGroupID
          );
          if (ectOdd.ParamLimitMark) {
            ectOdd.OddNameNumbering += ' ' + ectOdd.ParamLimitMark;
          } else {
            let paramLimit = ect.ParamLimit;
            if (paramGroup.tipClutch) {
              ectOdd.OddNameNumbering += ' ' + paramGroup.tipClutch + ' ';
            } else if (
              paramGroup.handicapMarker === '-' ||
              paramGroup.handicapMarker === '+'
            ) {
              ectOdd.OddNameNumbering += ' ' + paramGroup.handicapMarker;
              paramGroup.handicapMarker =
                paramGroup.handicapMarker === '-' ? '+' : '-';
            } else if (paramGroup.handicapMarker === 'Y') {
              if (paramLimit.charAt(0) !== '-') {
                paramLimit = ' +' + paramLimit;
              } else {
                paramLimit = ' ' + paramLimit;
              }
              paramGroup.handicapMarker = 'N';
            } else if (paramGroup.handicapMarker === 'N') {
              if (paramLimit.charAt(0) !== '-') {
                paramLimit = ' -' + paramLimit;
              } else {
                paramLimit = ' +' + paramLimit.substring(1);
              }
              paramGroup.handicapMarker = 'Y';
            } else {
              ectOdd.OddNameNumbering += ' ';
            }
            ectOdd.OddNameNumbering += paramLimit;
          }

          // let paramLimit = ect.ParamLimit;
          // if (paramGroup.tipClutch) {
          //   ectOdd.OddNameNumbering += ' ' + paramGroup.tipClutch + ' ';
          // } else if (
          //   paramGroup.handicapMarker === '-' ||
          //   paramGroup.handicapMarker === '+'
          // ) {
          //   ectOdd.OddNameNumbering += ' ' + paramGroup.handicapMarker;
          //   paramGroup.handicapMarker =
          //     paramGroup.handicapMarker === '-' ? '+' : '-';
          // } else if (paramGroup.handicapMarker === 'Y') {
          //   if (paramLimit.charAt(0) !== '-') {
          //     paramLimit = ' +' + paramLimit;
          //   } else {
          //     paramLimit = ' ' + paramLimit;
          //   }
          //   paramGroup.handicapMarker = 'N';
          // } else if (paramGroup.handicapMarker === 'N') {
          //   if (paramLimit.charAt(0) !== '-') {
          //     paramLimit = ' -' + paramLimit;
          //   } else {
          //     paramLimit = ' +' + paramLimit.substring(1);
          //   }
          //   paramGroup.handicapMarker = 'Y';
          // } else {
          //   ectOdd.OddNameNumbering += ' ';
          // }
          // ectOdd.OddNameNumbering += paramLimit;
        }

        if (home && away && ['1', 'X', '2'].includes(ectOdd.OddNameNumbering)) {
          ectOdd.OddNameNaming =
            ectOdd.TipType === '1'
              ? home
              : ectOdd.TipType === '2'
              ? away
              : action.eventDetailData.LocalizedTips?.[ectOdd.TipType] ||
                ectOdd.OddNameNumbering;
        }
      });
    });
    const betradarTeamIDs = {
      HomeTeamBetradarID: null,
      AwayTeamBetradarID: null,
      HomeTeamID: null,
      AwayTeamID: null
    };
    Object.keys(action.eventDetailData.BetradarTeams).forEach(teamID => {
      if (/_10$/.test(teamID)) {
        betradarTeamIDs.HomeTeamBetradarID = teamID.split('_')[0];
        betradarTeamIDs.HomeTeamID =
          action.eventDetailData.BetradarTeams[teamID];
      } else if (/_20$/.test(teamID)) {
        betradarTeamIDs.AwayTeamBetradarID = teamID.split('_')[0];
        betradarTeamIDs.AwayTeamID =
          action.eventDetailData.BetradarTeams[teamID];
      }
    });
    let newChancetypegroups: ChanceTypeGroup[] = [
      ...action.eventDetailData.ChanceTypeGroups
    ];
    if (action.eventDetailData.Event.EventType !== 'special') {
      if (action.eventDetailData.Event.LiveBetting === 'Y') {
        // if (
        //   action.eventDetailData.Event.RegionID !== 1237 &&
        //   action.eventDetailData.Event.SportID === 153
        // ) {
        //   newChancetypegroups = [
        //     {
        //       ChanceTypes: [],
        //       RecordOrder: 1,
        //       ChanceTypeGroupID: 'all',
        //       ChanceTypeGroupName: 'detail_groups_all'
        //     },
        //     ...newChancetypegroups
        //   ];
        // } else {
        newChancetypegroups = [
          {
            ChanceTypes: [],
            RecordOrder: 1,
            ChanceTypeGroupID: 'active',
            ChanceTypeGroupName: 'detail_groups_active'
          },
          ...newChancetypegroups,
          {
            ChanceTypes: [],
            RecordOrder: Number.MAX_SAFE_INTEGER,
            ChanceTypeGroupID: 'locked',
            ChanceTypeGroupName: 'detail_groups_locked'
          }
        ];
        // }
      } else {
        newChancetypegroups.forEach((ctg, ind, orig) => {
          if (ctg.ChanceTypeGroupID === 'top') {
            ctg.IsDefault = true;
          }
        });
        newChancetypegroups = [
          {
            ChanceTypes: [],
            RecordOrder: 1,
            ChanceTypeGroupID: 'all',
            ChanceTypeGroupName: 'detail_groups_all'
          },
          ...newChancetypegroups
        ];
      }
    }
    // overall event has only all chance type group
    if (action.eventDetailData.Event.EventType === EVENT_TYPE.SPECIAL) {
      newChancetypegroups = [
        {
          ChanceTypes: [],
          RecordOrder: 1,
          ChanceTypeGroupID: 'all',
          ChanceTypeGroupName: 'detail_groups_all'
        }
      ];
    }

    return {
      ...state,
      event: {
        ...action.eventDetailData.Event,
        SportName:
          action.eventDetailData.Labels[
            'SP_' + action.eventDetailData.Event.SportID
          ].Name,
        RegionName:
          action.eventDetailData.Labels[
            'RE_' + action.eventDetailData.Event.RegionID
          ].Name,
        LeagueCupName:
          action.eventDetailData.Labels[
            'LC_' + action.eventDetailData.Event.LeagueCupID
          ].Name,
        betradarTeams: action.eventDetailData.BetradarTeams,
        ...betradarTeamIDs,
        ParsedScore: parseScore(
          action.eventDetailData.Event.Score,
          action.eventDetailData.Event.SportID
        )
      },
      odds: eventDetailOddAdapter.setAll(odds, state.odds),
      eventChanceTypes: eventChanceTypeAdapter.setAll(
        eventChanceTypes,
        state.eventChanceTypes
      ),
      labels: action.eventDetailData.Labels,
      chanceTypeGroups: [...newChancetypegroups],
      chanceTypeParamGroups: action.eventDetailData.ChanceTypeParamGroups
    };
  }
}
