import { Injectable, OnDestroy } from '@angular/core';
import { DISCONNECT_SIGNALR_INACTIVE_TIMEOUT } from '@constants';
import { AppVisibilityService } from '@core/services/app-visibility/app-visibility.service';
import { environment } from '@environment';
import * as signalR from '@microsoft/signalr';
import {
  Subject,
  Observable,
  BehaviorSubject,
  iif,
  of,
  Subscription,
  merge
} from 'rxjs';
import {
  tap,
  bufferTime,
  filter,
  switchMap,
  distinctUntilChanged,
  delay,
  pairwise,
  startWith,
  map
} from 'rxjs/operators';

type signalrView = string;
class SignalrViews {
  constructor(
    public views: {
      global?: signalrView[];
      [key: string]: signalrView[];
    } = {}
  ) {}

  get activeViews(): signalrView[] {
    return [
      ...new Set([
        ...Object.values(this.views).reduce((a, c) => [...a, ...c], [])
      ])
    ];
  }
}

const ODD_CHANGE_BUFFER_TIME = 500;
export interface SignalrOddChangePayload {
  oddID: number;
  oddRate: number;
  oddStatus: string;
}
export interface SignalrOddChangeBuybackPayload {
  oddID: number;
  oddRate: string;
  buybackValue: string;
  isRemoveable: boolean;
}

export interface SignalrEventChangeJsonPayload {
  EventID?: number;
  Score?: string;
  ActualGamePartID?: string;
  ActualGamePartTime?: number;
  ActualGamePartStartTime?: string;
  EventDate?: string;
  LiveBettingView?: number;
}

export interface SiganlrBuyBackChangePayload {
  ticketID: string;
  buybackValue: number;

  // AmountMoney: number;
  // AmountMoneyWin: number;
  // EventId: number;
  // TicketCode: string;
}

export interface SignalrEventChanceTypeChangePayload {
  EventChanceTypeID: number;
  EventChanceTypeStatus: 'active' | 'suspended' | 'closed';
  EventID: number;
}

export interface SignalrOddsCreatedPayload {
  eventID: number;
}

export interface SignalrCasinoJackpotPayload {
  JackpotCode: string;
  Amount: number;
}

@Injectable({
  providedIn: 'root'
})
export class SignalrService implements OnDestroy {
  private _changeViewSubscribtion$: Subscription;
  private _hub: signalR.HubConnection;
  private _hubPaused = false;
  private _signalrOptions = {
    hubName: environment.hubConnection,
    skipNegotiation: true
  };
  private _view = new BehaviorSubject<SignalrViews>(new SignalrViews());
  private _state = new BehaviorSubject<signalR.HubConnectionState>(
    signalR.HubConnectionState.Disconnected
  );

  get currentViews(): SignalrViews['views'] {
    const _currentViews = this._view.value.views;
    return Object.seal(_currentViews);
  }

  /** VIEW UPDATED */
  public viewUpdated = new Subject<SignalrViews>();

  /**
   * ODD CHANGES
   */
  private _oddChanges = new Subject<SignalrOddChangePayload>();
  get oddsChanges(): Observable<SignalrOddChangePayload[]> {
    return this._oddChanges.pipe(
      bufferTime(ODD_CHANGE_BUFFER_TIME),
      filter(
        (buffer: SignalrOddChangePayload[]) =>
          !this._hubPaused && buffer.length > 0
      ) // ,
      // tap(changes => console.log('[Signalr] Odd changes:', changes))
    );
  }
  /**
   * ODD BUYBACK CHANGES
   */
  private _oddsBuyBackChanged = new Subject<SignalrOddChangeBuybackPayload>();
  get oddsBuyBackChanged(): Observable<SignalrOddChangeBuybackPayload[]> {
    return this._oddsBuyBackChanged.pipe(
      bufferTime(ODD_CHANGE_BUFFER_TIME),
      filter(
        (buffer: SignalrOddChangeBuybackPayload[]) =>
          !this._hubPaused && buffer.length > 0
      )
    );
  }

  /**
   *  EVENT CHANGES
   */
  private _eventChangesJson = new Subject<SignalrEventChangeJsonPayload>();
  get eventChangesJson(): Observable<SignalrEventChangeJsonPayload[]> {
    return this._eventChangesJson.pipe(
      bufferTime(ODD_CHANGE_BUFFER_TIME),
      filter(
        (buffer: SignalrEventChangeJsonPayload[]) =>
          !this._hubPaused && buffer.length > 0
      ),
      tap(changes => {
        // console.log('[Signalr] Event changed:', changes);
      })
    );
  }

  /**
   *  BUY BACK EVENTS CHANGES
   */
  private _buyBackChanges = new Subject<SiganlrBuyBackChangePayload>();
  get buyBackChanges(): Observable<SiganlrBuyBackChangePayload[]> {
    return this._buyBackChanges.pipe(
      bufferTime(ODD_CHANGE_BUFFER_TIME),
      filter((buffer: SiganlrBuyBackChangePayload[]) => buffer.length > 0),
      tap(changes => {
        // console.log('[Signalr] Events changed:', changes);
      })
    );
  }

  /**
   * EVENT CHANCE TYPE CHANGES
   */
  private _eventChcanceTypeChanges =
    new Subject<SignalrEventChanceTypeChangePayload>();
  get eventChanceTypeChanges(): Observable<
    SignalrEventChanceTypeChangePayload[]
  > {
    return this._eventChcanceTypeChanges.pipe(
      bufferTime(ODD_CHANGE_BUFFER_TIME),
      filter(
        (buffer: SignalrEventChanceTypeChangePayload[]) =>
          !this._hubPaused && buffer.length > 0
      ),
      tap(changes => {
        //  console.log('[Signalr] Event chance type changed:', changes);
      })
    );
  }

  /**
   * ODDS CREATED
   */
  private _oddsCreated = new Subject<SignalrOddsCreatedPayload>();
  get oddsCreated(): Observable<SignalrOddsCreatedPayload[]> {
    return this._oddsCreated.pipe(
      bufferTime(ODD_CHANGE_BUFFER_TIME),
      filter(
        (buffer: SignalrOddsCreatedPayload[]) =>
          !this._hubPaused && buffer.length > 0
      ),
      tap(changes => {
        //  console.log('[Signalr] Odds created:', changes);
      })
    );
  }

  /**
   * Casino jackpots amounts changed
   */
  private _casinoJackpotsChanged = new Subject<SignalrCasinoJackpotPayload[]>();
  get casinoJackpotsChanged(): Observable<SignalrCasinoJackpotPayload[]> {
    return this._casinoJackpotsChanged.pipe(
      bufferTime(ODD_CHANGE_BUFFER_TIME),
      filter(buffer => buffer.length !== 0),
      map(buffer => buffer[0])
    );
  }

  /**
   * REFRESH VIEW
   */
  private _refreshView = new Subject<string>();
  get refreshView(): Observable<string[]> {
    return this._refreshView.pipe(
      bufferTime(ODD_CHANGE_BUFFER_TIME),
      filter((buffer: string[]) => !this._hubPaused && buffer.length > 0)
    );
  }

  get events(): Observable<any> {
    return merge(
      this.oddsChanges.pipe(map(ev => ({ type: 'oddsChanges', payload: ev }))),
      this.eventChangesJson.pipe(
        map(ev => ({ type: 'eventChangesJson', payload: ev }))
      ),
      this.eventChanceTypeChanges.pipe(
        map(ev => ({ type: 'eventChanceTypeChanges', payload: ev }))
      ),
      this.oddsCreated.pipe(map(ev => ({ type: 'oddsCreated', payload: ev }))),
      this.refreshView.pipe(map(ev => ({ type: 'refreshView', payload: ev }))),
      this._oddsBuyBackChanged.pipe(
        map(ev => ({ type: 'oddsBuyBackChanged', payload: ev }))
      )
    );
  }

  /**
   * Changes signalr view
   * @param newViews new views
   */
  changeViews(newViews: string[], scope: string = 'global'): any {
    if (this._state.value === signalR.HubConnectionState.Disconnected) {
      this.startHub();
    }

    if (!newViews || newViews.length === 0) {
      this._hubPaused = true;
    }
    // nahradi null alebo undefined za prazdny array
    newViews = newViews || [];

    // add new value for scope or remove if from views if empty
    const views = { ...this._view.value.views };
    delete views[scope];
    if (newViews.length > 0) {
      views[scope] = [...new Set([...newViews])];
    }

    this._view.next(new SignalrViews(views));
  }

  /**
   * Computes view string from IDs
   * @param eventID event ID
   * @param isLive live/prematch
   * @param sportID sport ID
   * @param sportEventID sportEvent ID
   * @param regionID region ID
   * @param leagueCupID league ID
   * @param isBuyback isBuyback
   */
  getViewArray(
    eventID?: number,
    isLive = false,
    sportID?: number,
    sportEventID?: number,
    regionID?: number,
    leagueCupID?: number,
    isBuyback?: number
  ): string[] {
    const retViews = [];
    if (isBuyback) {
      retViews.push('bb_' + isBuyback);
    }
    if (eventID) {
      retViews.push('E_' + eventID);
    }
    let view = '';
    if (sportID || sportEventID) {
      view += sportID ? 'S_' + sportID : 'SE_' + sportEventID;
      if (regionID) {
        view += '_R_' + regionID;
        if (leagueCupID) {
          view += '_L_' + leagueCupID;
        }
      }
    }
    if (sportID && sportID === 153) {
      view += '_VG';
    } else if (isLive || !sportID) {
      view += '_LIVE';
    }
    retViews.push(view);
    return retViews;
  }

  /**
   * Starts hub and updates state of hub
   */
  startHub(): Promise<void> {
    return new Promise(resolve => {
      this._state.next(signalR.HubConnectionState.Connecting);
      this._hub.start().then(() => {
        this._state.next(signalR.HubConnectionState.Connected);
        resolve();
      });
    });
  }

  /**
   * Stops hub and updates state of hub
   */
  stopHub(): Promise<void> {
    return new Promise(resolve => {
      this._state.next(signalR.HubConnectionState.Disconnecting);
      this._hub.stop().then(() => {
        this._state.next(signalR.HubConnectionState.Disconnected);
        resolve();
      });
    });
  }

  constructor(private _appVisibilityService: AppVisibilityService) {
    this._hub = new signalR.HubConnectionBuilder()
      .withUrl(this._signalrOptions.hubName, {
        skipNegotiation: this._signalrOptions.skipNegotiation,
        transport: signalR.HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

    this._hub.onreconnected = () => {
      this._state.next(signalR.HubConnectionState.Connected);
    };

    this._hub.onreconnecting = () => {
      this._state.next(signalR.HubConnectionState.Reconnecting);
    };

    this._hub.onclose = () => {
      this._state.next(signalR.HubConnectionState.Disconnected);
    };

    this._changeViewSubscribtion$ = this._state
      .pipe(
        filter(state => state === signalR.HubConnectionState.Connected),
        switchMap(() =>
          this._view.pipe(
            switchMap(view =>
              // ak je view prazdny array, hub pocka sekundu, ak dovtedy nepride ziadny iny view
              // tak service invokne view ako prazdny array
              // ak do sekundy pride ina zmena, signalr invokne tuto zmenu
              iif(
                () => view.activeViews.length === 0,
                of(view).pipe(delay(1000)),
                of(view)
              )
            ),
            tap(() => (this._hubPaused = false)),
            distinctUntilChanged(
              (prew, next) =>
                prew.activeViews.join() === next.activeViews.join()
            )
          )
        ),
        startWith(this._view.value),
        pairwise()
      )
      .subscribe(([oldViews, newViews]) =>
        this._hub
          .invoke('ChangeView', newViews.activeViews, oldViews.activeViews)
          .then(() => {
            (window as any).__signalrViews = newViews;
            this.viewUpdated.next(newViews);
          })
      );

    this._hub.on('OddsChanged', (oddID, oddRate, oddStatus) =>
      this._oddChanges.next({ oddID, oddRate, oddStatus })
    );

    this._hub.on(
      'OddsBuyBackChanged',
      (oddID, oddRate, buybackValue, isRemoveable) =>
        this._oddsBuyBackChanged.next({
          oddID,
          oddRate: oddRate.toString(),
          buybackValue: buybackValue.toString(),
          isRemoveable
        })
    );

    this._hub.on(
      'EventChanceTypeChanged',
      (EventChanceTypeID, EventChanceTypeStatus, EventID) =>
        this._eventChcanceTypeChanges.next({
          EventChanceTypeID,
          EventChanceTypeStatus,
          EventID
        })
    );

    this._hub.on('EventChangedJson', Data =>
      this._eventChangesJson.next(JSON.parse(Data))
    );

    this._hub.on('OddsCreated', eventID =>
      this._oddsCreated.next({
        eventID
      })
    );

    this._hub.on('BuyBackChanged', (ticketID, buybackValue) =>
      this._buyBackChanges.next({ ticketID, buybackValue })
    );

    // casino jackpots amounts change
    this._hub.on('CasinoJackpotChanged', data => {
      const payload: SignalrCasinoJackpotPayload[] = JSON.parse(data);
      this._casinoJackpotsChanged.next(payload);
    });

    // this._hub.on('BuyBackChanged', data => console.log(data));

    this._hub.on('RefreshView', payload => this._refreshView.next(payload));
    (window as any).__signalrService = this;

    this._appVisibilityService.visibilityChange.subscribe(hidden => {
      if (hidden) {
        setTimeout(() => {
          // if document is still hidden after 20s stop hub
          if (
            this._appVisibilityService.hidden &&
            this._hub?.state === signalR.HubConnectionState.Connected
          ) {
            this.stopHub();
          }
        }, DISCONNECT_SIGNALR_INACTIVE_TIMEOUT);
      } else {
        if (this._hub?.state === signalR.HubConnectionState.Disconnected) {
          this.startHub();
        }
      }
    });
  }

  /**
   * disconect from signalr
   */
  ngOnDestroy(): void {
    if (this._hub?.state === signalR.HubConnectionState.Connected) {
      this.stopHub();
    }
    this._changeViewSubscribtion$?.unsubscribe();
  }
}
