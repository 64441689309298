import { Component, Input } from '@angular/core';
import { Event } from '@models/Event';
import { ParsedScore, parseScore } from '@core/functions/parseScore';
import { environment } from '@environment';

@Component({
  selector: 'doxx-event-score-table',
  templateUrl: './event-score-table.component.html',
  styleUrls: ['./event-score-table.component.scss']
})
export class EventScoreTableComponent {
  score: ParsedScore;

  @Input() liveBettingView: boolean;
  @Input() isFromTicketHistory: boolean;
  @Input() isLive: boolean;
  @Input() section: string;
  @Input() sportID: Event['SportID'];
  @Input() actualGamePartID: Event['ActualGamePartID'];
  @Input() actualGamePartTime: Event['ActualGamePartTime'];

  @Input() set rawScore(score: string) {
    this.score = parseScore(score, this.sportID);
  }
  @Input() homeTeam: {
    name: string;
    ID?: string;
  };

  @Input() awayTeam: {
    name: string;
    ID?: string;
  };

  /**
   * Changes team logo to default one
   */
  changeToDefaultTeamIcon(ev): void {
    ev.target.src = environment.staticUrl + '/teams/medium/0-grey.png';
  }
}
