import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromCasino from '../store/casino.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CasinoEffects } from '../store/casino.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCasino.casinoFeatureKey, fromCasino.reducer),
    EffectsModule.forFeature([CasinoEffects])
  ]
})
export class CasinoStoreModule {}
