import { ActionReducerMap, createReducer, on } from '@ngrx/store';

import * as fromAuth from './auth/auth.reducer';
import * as fromMaintenance from './maintenance/maintenance.reducer';

import { initApp } from './actions';

const appState = {
  initialized: false
};
export interface AppState {
  auth: fromAuth.AuthState;
  maintenance: fromMaintenance.State;
  app: typeof appState;
  [key: string]: any;
}

export const appReducer: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  maintenance: fromMaintenance.reducer,
  app: createReducer(
    appState,
    on(initApp, (state, action) => ({
      ...state,
      initialized: true
    }))
  )
};
