import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { oneLine } from '@core/functions/oneLine';
import { environment } from '@environment';
import { Announcement } from '@models/Landing';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
  constructor(private _httpClient: HttpClient) {}

  /**
   * URL: GET {{env}}/system/text/information
   * https://doxxmine.doxxbet.sk/issues/248533
   */
  loadAnnouncement(): Observable<Announcement> {
    return this._httpClient
      .get<any>(oneLine`${environment.apiUrl}/web/system/text/information`)
      .pipe(
        map(data => ({
          ...data.Result,
          Text: data.Result.Text.replace(
            'DateFrom',
            `<b>${data.Result.DateFromString}</b>`
          ).replace('DateTo', `<b>${data.Result.DateToString}</b>`)
        }))
      );
  }
}
