import { createReducer, on } from '@ngrx/store';
import { Actuality } from '@models/Actuality';
import { actualitiesActions } from '@store/actions';

export const actualitiesFeatureKey = 'actualities';

export interface State {
  actualities: Actuality[];
  loading: boolean;
  error: any;
}

export const initialState: State = {
  actualities: null,
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,

  on(actualitiesActions.loadActualities, state => ({
    ...state,
    loading: true,
    error: null
  })),
  on(actualitiesActions.loadActualitiesSuccess, (state, action) => ({
    ...state,
    actualities: action.data.actualities,
    error: null
  })),
  on(actualitiesActions.loadActualitiesFailure, (state, action) => ({
    ...state,
    error: action.error
  }))
);
