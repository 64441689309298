export const LAST_TICKETS_COUNT = 15;
// ticket history refresh interval
export const DELTA_HISTORY = 10000;

export const OFFER_SETTINGS_PATH = 'offer-settings';
export const COOKIES_SETTINGS_PATH = 'cookies-settings';
export const CASINO_SPINS_HISTORY_SETTINGS_PATH = 'casino-settings';
export const BETSLIPS_HISTORY_SETTINGS_PATH = 'sports-betting-settings';

// signalr cope
export const STREAM_SCHEDULE_SIGNALR_SCOPE = 'stream-schedule';
export const BUYBACK_SIGNALR_SCOPE = 'buy-back';
export const VIRTUAL_GAMES_SIGNALR_SCOPE = 'virtual-games';
export const TOP_BETTING_SIGNALR_SCOPE = 'top-betting';
export const EVENT_DETAIL_SIGNALR_SCOPE = 'event-detail';
export const TICKET_HISTORY_DETAIL_SIGNALR_SCOPE = 'ticket-history-detail';

/** disconect signalr after given miliseconds */
export const DISCONNECT_SIGNALR_INACTIVE_TIMEOUT = 20000;
/** when window is in background for longer time in miliseconds wiew will be refreshed after user come back to page */
export const OFFER_LIST_REFRESH_INACTIVE_TIMEOUT = 20000;
/** when window is in background for longer time in miliseconds wiew will be refreshed after user come back to page */
export const EVENT_DETAIL_REFRESH_INACTIVE_TIMEOUT = 20000;
/** when window is in background for longer time in miliseconds wiew will be refreshed after user come back to page */
export const BUYBACK_REFRESH_INACTIVE_TIMEOUT = 5 * 60 * 1000;
/** when window is in background for longer time in miliseconds wiew will be refreshed after user come back to page */
export const TICKET_REFRESH_INACTIVE_TIMEOUT = 20000;

export const EVENT_TYPE = {
  SPECIAL: 'special', // overall event
  DEFAULT: 'default'
};

/** subpage string for registration */
export const REGISTRATION_PHONE_NUMBER_VERIFY_LINK = 'sms';
export const VIRTUAL_GAMES_SECTION = 'virtual-games';

export const RETURN_URL_QP = 'returnUrl';
