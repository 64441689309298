<ion-header [ngClass]="{ transparent: isTransparent }">
  <ion-toolbar class="ion-align-items-baseline">
    <!-- BUTTONS -->
    <ion-buttons slot="start">
      <div #backButtonContent>
        <ng-content select="doxx-main-header-back-button"></ng-content>
      </div>
      <ion-menu-button
        *ngIf="backButtonContent.children.length === 0"
        menuClose
        menu="mainMenu"
      >
        <i class="icon icon-ic_menu"></i>
      </ion-menu-button>
    </ion-buttons>
    <!-- END BUTTONS -->

    <!-- WHEN SEARCH IS INACTIVE -->
    <ng-container *ngIf="!showSearchBar">
      <!-- TITLE -->
      <div #titleContent>
        <ng-content select="ion-title"></ng-content>
      </div>
      <!-- DEFAULT VALUE -->
      <ion-title class="title-image" *ngIf="titleContent.children.length === 0">
        <!-- <img *ngIf="!user" src="assets/logos/logo-web-red.svg" alt="" />
        <img *ngIf="user" src="assets/logos/logo-web-red-compact.svg" alt="" /> -->
        <img src="assets/logos/l_tza.svg" alt="" />
      </ion-title>
      <!-- END TITLE -->
    </ng-container>

    <!-- WHEN SEARCH IS ACTIVE -->
    <ng-container *ngIf="showSearchBar">
      <!-- TITLE -->
      <ion-title>
        <h1>{{ 'search_title' | translate }}</h1>
      </ion-title>
      <!-- END TITLE -->
    </ng-container>

    <div class="toolbar-buttons-right" slot="end">
      <div
        class="toolbar-action-button user-balance"
        *ngIf="showBalance && this.user"
        [routerLink]="'DepositWithdrawal_Deposit' | translateRoute"
      >
        {{ user?.balanceDecimal | formatNumberWithCurrency: { decimalDigits: config.base.defaultCurrency.numberDecimalPlaces} }}
       
      </div>

      <div
        class="toolbar-action-button"
        [routerLink]="'Register' | translateRoute"
        *ngIf="showRegisterButton && !this.user"
        routerLinkActive="active"
      >
        <i class="icon icon-ic_sign-up1"></i>
        <span class="action-button-label">
          {{ 'menu_register' | translate }}
        </span>
      </div>

      <div
        class="toolbar-action-button"
        [routerLink]="'Login' | translateRoute"
        *ngIf="showLoginButton && !this.user"
        routerLinkActive="active"
      >
        <i class="icon icon-ic_login"></i>
        <span class="action-button-label">
          {{ 'app_login_submit' | translate }}
        </span>
      </div>

      <div
        (click)="showNotifications()"
        class="toolbar-action-button search-button tickets relative"
        *ngIf="showNotificationsButton && this.user"
      >
        <i class="icon icon-ic_bell-white-o40-fill"></i>
        <span *ngIf="unreadNotificationsCount > 0" class="notification-badge">{{
          unreadNotificationsCount
        }}</span>
      </div>

      <div
        class="toolbar-action-button search-button tickets"
        *ngIf="showTicketHistoryButton && this.user"
        [routerLink]="'BetslipHistoryList' | translateRoute"
      >
        <i class="icon icon-ic_two-tickets-stroke"></i>
      </div>

      <div
        class="toolbar-action-button search-button"
        *ngIf="showSearchButton && !showSearchBar"
        (click)="showSearchBar = true"
      >
        <i class="icon icon-ic_search1"></i>
      </div>

      <div class="toolbar-action-button">
        <ng-content select="[main-header-button]"></ng-content>
      </div>
    </div>
  </ion-toolbar>
</ion-header>
<doxx-main-header-search-bar
  *ngIf="showSearchBar"
  (closeSeachBar)="this.showSearchBar = false"
></doxx-main-header-search-bar>
