import { TicketHistoryListItem } from '@models/TicketHistory';
import { Action, createReducer, on } from '@ngrx/store';
import { authActions, buyBackActions } from '@store/actions';

export const BUYBACK_STATE_KEY = 'buyback';
export interface State {
  buyBackTickets: {
    [ticketCode: TicketHistoryListItem['ticketCode']]: {
      AmountMoney: number;
      AmountMoneyWin: number;
      ClientID: number;
      EventIDs: number[];
      IsFreebetTicket: number;
      TicketCode: string;
      TicketType: 'SRT' | 'JT';

      // buyback ext
      AutoBuyBackValue: number;
      BuyBackDelayMs: number;
      BuyBackValue: number;
      IsBuyBack: boolean;
      __hydrated?: boolean;
    };
  };

  buyBackEvents: {
    eventID: number;
    ticketCode: TicketHistoryListItem['ticketCode'];
  }[];
}
const initialState: State = {
  buyBackTickets: {},
  buyBackEvents: []
};

const buyBackReducer = createReducer(
  initialState,
  on(buyBackActions.loadBuybackTickets, state => ({ ...state })),
  on(buyBackActions.loadBuybackTicketsSuccess, (state, action) => ({
    ...state,
    buyBackTickets: {
      ...action.data.reduce(
        (acc, ticket) => ({
          ...acc,
          [ticket.TicketCode]: {
            ...state.buyBackTickets[ticket.TicketCode],
            ...ticket
          }
        }),
        {}
      )
    },
    buyBackEvents: [].concat(
      ...action.data.map(e =>
        e.EventIDs.map(eventID => ({
          eventID,
          ticketCode: e.TicketCode
        }))
      )
    )
  })),
  on(buyBackActions.loadBuybacTicketsFailure, state => ({ ...state })),

  on(buyBackActions.loadBuybackValues, (state, action) => ({
    ...state,
    buyBackTickets: {
      ...state.buyBackTickets,
      [action.ticketCode]: {
        ...state.buyBackTickets[action.ticketCode],
        __hydrated: true
      }
    }
  })),
  on(buyBackActions.loadBuybackValuesSuccess, (state, action) => ({
    ...state,
    buyBackTickets: {
      ...state.buyBackTickets,
      [action.ticketCode]: {
        ...state.buyBackTickets[action.ticketCode],
        ...action.buybackValues,
        __hydrated: false
      }
    }
  })),
  on(buyBackActions.loadBuybackValuesFailure, (state, action) => ({
    ...state,
    buyBackTickets: {
      ...state.buyBackTickets,
      [action.ticketCode]: {
        ...state.buyBackTickets[action.ticketCode],
        __hydrated: true
      }
    }
  })),

  // execute buyback
  on(buyBackActions.executeBuybackSuccess, (state, action) => ({
    ...state,
    buyBackEvents: state.buyBackEvents.filter(
      event => event.ticketCode !== action.ticketCode
    ),
    buyBackTickets: {
      ...(() => {
        delete state.buyBackTickets[action.ticketCode];
        return state.buyBackTickets;
      })()
    }
  })),

  // remove ticket
  on(buyBackActions.removeTicket, (state, action) => ({
    ...state,
    buyBackEvents: state.buyBackEvents.filter(
      event => event.ticketCode !== action.ticketCode
    ),
    buyBackTickets: {
      ...(() => {
        delete state.buyBackTickets[action.ticketCode];
        return state.buyBackTickets;
      })()
    }
  })),

  // execute autobuback
  on(buyBackActions.executeAutoBuybackSuccess, (state, action) => ({
    ...state,
    buyBackTickets: {
      ...state.buyBackTickets,
      ...(state.buyBackTickets[action.ticketCode]
        ? {
            [action.ticketCode]: {
              ...state.buyBackTickets[action.ticketCode],
              ...action.data
            }
          }
        : {})
    }
  })),

  on(buyBackActions.updateBuybackValue, (state, action) => ({
    ...state,
    buyBackTickets: {
      ...state.buyBackTickets,
      ...action.changes.reduce(
        (changes, change) => ({
          ...changes,
          ...(state.buyBackTickets[change.ticketCode]
            ? {
                [change.ticketCode]: {
                  ...state.buyBackTickets[change.ticketCode],
                  ...change.changes
                }
              }
            : {})
        }),
        {}
      )
    }
  })),
  on(authActions.logoutSuccess, () => initialState),
  on(authActions.softLogoutSuccess, () => initialState)
);

/** buyback reducer */
export function reducer(state: State | undefined, action: Action) {
  return buyBackReducer(state, action);
}
