import { createReducer, on } from '@ngrx/store';
import { CasinoGame } from '@models/CasinoGame';

import { casinoActions } from '@store/actions';
import { GameCategory } from '@models/GameCategory';
import { CasinoJackpot, CasinoJackpotResponse } from '@models/CasinoJackpot';

export const casinoFeatureKey = 'casino';

export interface State {
  loading: boolean;
  casinoGames: {
    games: GameCategory[];
  };
  rioGames: {
    games: GameCategory[];
  };
  categoryGames: {
    games: GameCategory[];
  };
  casinoJackpots: {
    jackpots: CasinoJackpotResponse;
  };
}

export const initialState: State = {
  loading: false,
  casinoGames: {
    games: []
  },
  rioGames: {
    games: []
  },
  categoryGames: {
    games: []
  },
  casinoJackpots: {
    jackpots: {
      jackpots: [],
      url: {
        mobile: ''
      }
    }
  }
};

export const reducer = createReducer(
  initialState,

  on(casinoActions.loadCasinoGames, state => state),
  on(casinoActions.loadCasinoGamesSuccess, (state, action) => ({
    ...state,
    casinoGames: {
      ...state.casinoGames,
      games: action.games
    }
  })),

  on(casinoActions.loadRioGames, state => state),
  on(casinoActions.loadRioGamesSuccess, (state, action) => ({
    ...state,
    rioGames: {
      ...state.rioGames,
      games: action.games
    }
  })),

  on(casinoActions.loadGamesbyCategory, state => state),
  on(casinoActions.loadGamesbyCategorySuccess, (state, action) => ({
    ...state,
    categoryGames: {
      ...state.categoryGames,
      games: action.games
    }
  })),

  on(casinoActions.loadCasinoJackpots, state => state),
  on(casinoActions.loadCasinoJackpotsSuccess, (state, action) => ({
    ...state,
    casinoJackpots: {
      ...state.casinoJackpots,
      jackpots: action.jackpots
    }
  })),
  // Increment casino jackpots by its step value
  on(casinoActions.incrementCasinoJackpotsAmounts, state => {
    const incrementedJackpots = [...state.casinoJackpots.jackpots.jackpots];

    incrementedJackpots.forEach(jackpot => {
      jackpot.jackpotAmount = jackpot.jackpotAmount + jackpot.step;
    });

    return {
      ...state,
      casinoJackpots: {
        jackpots: {
          ...state.casinoJackpots.jackpots,
          jackpots: incrementedJackpots
        }
      }
    };
  }),
  // Set casino jackpots amounts by given values
  on(casinoActions.setCasinoJackpotsAmounts, (state, action) => {
    const updatedJackpots = [...state.casinoJackpots.jackpots.jackpots];
    const referenceJackpots = action.referenceJackpots;

    updatedJackpots.forEach(jackpot => {
      const foundID: number = action.referenceJackpots.findIndex(
        ref => ref.JackpotCode === jackpot.jackpotCode
      );
      if (foundID !== -1) {
        jackpot.jackpotAmount = referenceJackpots[foundID].Amount;
      }
    });

    return {
      ...state,
      casinoJackpots: {
        jackpots: {
          ...state.casinoJackpots.jackpots,
          jackpots: updatedJackpots
        }
      }
    };
  })
);
