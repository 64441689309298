import { configuration as configuration } from '@configuration';

/**
 * screen aloowed enmu
 */
export enum ScreenAllowed {
  allowed = 'allowed',
  disabled = 'disabled',
  onlyForLogged = 'onlyForLogged',
  onlyForUnlogged = 'onlyForUnlogged'
}

/**
 * list of screens
 */
export enum AppScreens {
  Landing = 'Landing',
  Search = 'Search',
  NewsList = 'NewsList',
  NewsDetail = 'NewsDetail',
  AvailableBonusesList = 'AvailableBonusesList',
  BonusesList = 'BonusesList',
  BonusesListCasino = 'BonusesListCasino',
  BonusDetail = 'BonusDetail',
  BonusDetailCasino = 'BonusDetailCasino',
  AnalysisList = 'AnalysisList',
  AnalysisDetail = 'AnalysisDetail',
  DocumentsList = 'DocumentsList',
  DocumentDetail = 'DocumentDetail',
  LiveList = 'LiveList',
  PrematchList = 'PrematchList',
  ResultsList = 'ResultsList',
  VirtualGames = 'VirtualGames',
  FavoritesList = 'FavoritesList',
  EventDetail = 'EventDetail',
  EventDetail_Results = 'EventDetail_Results',
  EventDetail_VirtualGames = 'EventDetail_VirtualGames',
  Betslip = 'Betslip',
  BetslipScanner = 'BetslipScanner',
  BetslipHistoryList = 'BetslipHistoryList',
  BetslipHistoryDetail = 'BetslipHistoryDetail',
  SavedBetslipsList = 'SavedBetslipsList',
  SavedBetslipDetail = 'SavedBetslipDetail',
  SharedBetslipDetail = 'SharedBetslipDetail',
  Login = 'Login',
  ResetPassword = 'ResetPassword',
  Register = 'Register',
  RegisterSMS = 'RegisterSMS',
  RegisterPersonalInfo = 'RegisterPersonalInfo',
  RegisterEmailActivation = 'RegisterEmailActivation',
  Profile = 'Profile',
  PersonalInformation = 'PersonalInformation',
  Settings = 'Settings',
  VerificationsList = 'VerificationsList',
  VerificationAccount = 'VerificationAccount',
  VerificationBankAccount = 'VerificationBankAccount',
  VerificationPhoneAccount = 'VerificationPhoneAccount',
  VerificationCreditCard = 'VerificationCreditCard',
  ResponsibleGamingBetting = 'ResponsibleGamingBetting',
  ResponsibleGamingCasino = 'ResponsibleGamingCasino',
  DepositWithdrawal_Deposit = 'DepositWithdrawal_Deposit',
  DepositWithdrawal_Withdrawal = 'DepositWithdrawal_Withdrawal',
  DepositWithdrawal_BankAccount = 'DepositWithdrawal_BankAccount',
  DepositWithdrawal_Card = 'DepositWithdrawal_Card',
  TransactionHistory = 'TransactionHistory',
  CasinoHome = 'CasinoHome',
  RioHome = 'RioHome',
  CasinoGame = 'CasinoGame',
  CasinoSpinHistoryList = 'CasinoSpinHistoryList',
  CasinoFavorites = 'CasinoFavorites',
  WinnerOfDayDetail = 'WinnerOfDayDetail',
  WinnersOfDayHistoryList = 'WinnersOfDayHistoryList',
  Maintenance = 'Maintenance',
  Blank_VLC = 'VLC',
  Quickbet = 'Quickbet'
}

/**
 * return state of screen
 *
 * @param screen scrrren name
 * @returns ScreenAllowed
 */
export function isAllowed(screen: AppScreens): ScreenAllowed {
  switch (screen) {
    case AppScreens.Landing:
      return ScreenAllowed.allowed;
    case AppScreens.Search:
      return configuration.search?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.NewsList:
      return configuration.news?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.NewsDetail:
      return configuration.news?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.BonusesList:
      return configuration.bonuses?.allowedBetting
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.AvailableBonusesList:
      return configuration.bonuses?.allowedBetting
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.BonusesListCasino:
      return configuration.bonuses?.allowedCasino
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.BonusDetail:
      return configuration.bonuses?.allowedBetting
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.BonusDetailCasino:
      return configuration.bonuses?.allowedCasino
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.AnalysisList:
      return configuration.analysis?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.AnalysisDetail:
      return configuration.analysis?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.DocumentsList:
      return configuration.documents?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.DocumentDetail:
      return configuration.documents?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.LiveList:
      return configuration.offerLive?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.PrematchList:
      return configuration.offer?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.ResultsList:
      return configuration.results?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.VirtualGames:
      return configuration.virtualGames?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.FavoritesList:
      return configuration.favorites?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.EventDetail:
      return configuration.offer?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.EventDetail_Results:
      return configuration.results?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.EventDetail_VirtualGames:
      return configuration.virtualGames?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.Betslip:
      return configuration.betslip?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.BetslipScanner:
      return configuration.sportbox?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.Blank_VLC:
      return configuration.connections.vlc?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.BetslipHistoryList:
      return configuration.betslip?.allowed
        ? ScreenAllowed.onlyForLogged
        : ScreenAllowed.disabled;
    case AppScreens.BetslipHistoryDetail:
      return configuration.betslip?.allowed
        ? ScreenAllowed.onlyForLogged
        : ScreenAllowed.disabled;
    case AppScreens.SavedBetslipsList:
      return configuration.betslip?.saveBetslip
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.SavedBetslipDetail:
      return configuration.betslip?.saveBetslip
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.SharedBetslipDetail:
      return configuration.betslip?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.Login:
      return configuration.login?.allowed
        ? ScreenAllowed.onlyForUnlogged
        : ScreenAllowed.disabled;
    case AppScreens.ResetPassword:
      return configuration.login?.allowed
        ? ScreenAllowed.onlyForUnlogged
        : ScreenAllowed.disabled;
    case AppScreens.Register:
      return configuration.profile.registrationAllowed
        ? ScreenAllowed.onlyForUnlogged
        : ScreenAllowed.disabled;
    case AppScreens.RegisterSMS:
      return configuration.profile.registrationAllowed
        ? ScreenAllowed.onlyForUnlogged
        : ScreenAllowed.disabled;
    case AppScreens.RegisterPersonalInfo:
      return configuration.profile.registrationAllowed
        ? ScreenAllowed.onlyForUnlogged
        : ScreenAllowed.disabled;
    case AppScreens.RegisterEmailActivation:
      return configuration.profile.registrationAllowed
        ? ScreenAllowed.onlyForUnlogged
        : ScreenAllowed.disabled;
    case AppScreens.Profile:
      return ScreenAllowed.onlyForLogged;
    case AppScreens.PersonalInformation:
      return ScreenAllowed.onlyForLogged;
    case AppScreens.Settings:
      return ScreenAllowed.allowed;
    case AppScreens.VerificationsList:
      return configuration.verification?.allowed
        ? ScreenAllowed.onlyForLogged
        : ScreenAllowed.disabled;
    case AppScreens.VerificationAccount:
      return configuration.verification?.allowed
        ? ScreenAllowed.onlyForLogged
        : ScreenAllowed.disabled;
    case AppScreens.VerificationBankAccount:
      return configuration.verification.bankAccount
        ? ScreenAllowed.onlyForLogged
        : ScreenAllowed.disabled;
    case AppScreens.VerificationPhoneAccount:
      return configuration.verification?.allowed
        ? ScreenAllowed.onlyForLogged
        : ScreenAllowed.disabled;
    case AppScreens.VerificationCreditCard:
      return configuration.verification.creditCard
        ? ScreenAllowed.onlyForLogged
        : ScreenAllowed.disabled;
    case AppScreens.ResponsibleGamingBetting:
      return configuration.responsibleGaming.bettingAllowed
        ? ScreenAllowed.onlyForLogged
        : ScreenAllowed.disabled;
    case AppScreens.ResponsibleGamingCasino:
      return configuration.responsibleGaming.casinoAllowed
        ? ScreenAllowed.onlyForLogged
        : ScreenAllowed.disabled;
    case AppScreens.DepositWithdrawal_Deposit:
      return configuration?.depositWithdrawal?.depositAllowed
        ? ScreenAllowed.onlyForLogged
        : ScreenAllowed.disabled;
    case AppScreens.DepositWithdrawal_Withdrawal:
      return configuration.depositWithdrawal.withdrawalAllowed
        ? ScreenAllowed.onlyForLogged
        : ScreenAllowed.disabled;
    case AppScreens.DepositWithdrawal_BankAccount:
      return configuration.depositWithdrawal.bankAccountAllowed
        ? ScreenAllowed.onlyForLogged
        : ScreenAllowed.disabled;
    case AppScreens.DepositWithdrawal_Card:
      return configuration.depositWithdrawal.creditCardAllowed
        ? ScreenAllowed.onlyForLogged
        : ScreenAllowed.disabled;
    case AppScreens.TransactionHistory:
      return ScreenAllowed.onlyForLogged;
    case AppScreens.CasinoHome:
      return configuration.casino?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.RioHome:
      return configuration.casino?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.CasinoGame:
      return configuration.casino?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.CasinoSpinHistoryList:
      return configuration.casinoSpinsHistory?.allowed
        ? ScreenAllowed.onlyForLogged
        : ScreenAllowed.disabled;
    case AppScreens.CasinoFavorites:
      return configuration.casino?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.WinnerOfDayDetail:
      return configuration.landing.winnersOfDay?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.WinnersOfDayHistoryList:
      return configuration.landing.winnersOfDay?.allowed
        ? ScreenAllowed?.allowed
        : ScreenAllowed.disabled;
    case AppScreens.Maintenance:
      return ScreenAllowed.allowed;
    default:
      return ScreenAllowed.allowed;
  }
}
