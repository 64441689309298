import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { NotificationsService } from './notifications.service';
import { NotificationsComponent } from './notifications.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DoxxDirectivesModule } from '@shared/directives/doxx-directives.module';
import { LetModule } from '@ngrx/component';

@NgModule({
  declarations: [NotificationsComponent],
  imports: [
    CommonModule,
    RouterModule,
    DoxxPipesModule,
    DragDropModule,
    InfiniteScrollModule,
    DoxxDirectivesModule,
    LetModule
  ]
})
export class NotificationsModule {
  /** provide service */
  static forRoot(): ModuleWithProviders<NotificationsModule> {
    return {
      ngModule: NotificationsModule,
      providers: [NotificationsService]
    };
  }
}
