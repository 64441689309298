import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromOffer from './offer.reducer';
import { OfferEffects } from './offer.effects';
import { EffectsModule } from '@ngrx/effects';
import { AnalysesStoreModule } from '../../analysis/store/analyses-store.module';

@NgModule({
  imports: [
    StoreModule.forFeature(fromOffer.OFFER_STATE_KEY, fromOffer.reducer),
    EffectsModule.forFeature([OfferEffects]),
    AnalysesStoreModule
  ]
})
export class OfferStorageModule {}
