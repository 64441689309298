import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerDirective } from './banner.directive';
import { BannerGroupComponent } from './banner-group.component';
import { IonicModule } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [BannerGroupComponent, BannerDirective],
  exports: [BannerGroupComponent, BannerDirective],
  imports: [CommonModule, IonicModule, SwiperModule]
})
export class BannerGroupModule {}
