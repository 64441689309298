<div id="spinner-container">
  <div class="logo">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="48px"
      height="48px"
      viewBox="0 0 48 48"
      enable-background="new 0 0 48 48"
      xml:space="preserve"
    >
      <image
        id="image0"
        width="48"
        height="48"
        x="0"
        y="0"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
  AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABs1BMVEUAAADzDTDzDTDzDTDz
  DTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDz
  DTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDz
  DTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDz
  DTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDz
  DTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDz
  DTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDz
  DTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDzDTDz
  DTDzDTDzDTDzDTDzDTDzDTDzDTD////L1bgcAAAAj3RSTlMACDlDB2Xw+Pf6xRQ64lQawP2DA5Oy
  EmP22C434PFWAg8OARi9hgQbt9LQ0b8zTMqZBpC1EwWN/roWKPt5X/TaMBW5jKrsSzXe8lnd81t6
  zyS7il3Zd+rlwaiVuI75bSH8F7yfCkXpNt8gcugMoirhaqSFdFXLLKALr2ZwoSdhSedCJTvjlMgf
  SmepnZhuOAzM3rwAAAABYktHRJB4Co4MAAAAB3RJTUUH5AgZEB4iQWd9qwAAAeVJREFUSMdjYBgF
  QwYwMjEDARML0RpY2dg5ODi5uIlVz8PbDwRsfMSq5xcAqRcUEiZSPYsISH2/qBixFohLgNRLShGr
  XloGpF5WDkVQXkEMCBSVkDhijBApZRWwB1TVUDSoa2hqaWnr6II5evpaQKBpoADiGBqBPWBsgmqt
  qZk5SNjCEsi20gQr6be2kQfybO1AbHsHdIc6OoHEzZ1NGRRd+qHA1Y2Bwd0DxPL0wvSarjdIxsdX
  yc8apqHfP4A7EGxOkCmWwAgG2x0SGgaiwiPAToyMAlPRjthCzzAGJGnOCVIRGxevD6ITwLZ562IP
  78QkuFuSUxhS02Acu2BcMZSeAVWin8nAoJTFCeGYm5ni0gANwv5sZhAnJxeiARzUOEBePsQCiJIC
  QTBPFbf6nEKImYJFoGTgYA/hpaXiUq9UDHV1v2wJA4OJMczTpfE4NCDCpV+yTF5VEM4rr8CqPr4U
  JMmhmQCiKqvYwPEHThix1djUV9SATSusBWdHax8QKVFX7wqiGxqxeKA6Fpx6mhiaW+BuaWVRsgHH
  dVs7hgbmbHD67AAyOzmg6iP4GRgUu8DM7hQ09Y5ciBzA2ANRz8sDkqnVBnujDk2DQl0cEAQrgjnK
  rCBObyMkw6aDOX0Mo2AUDDwAAMg/5cBzc9qbAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTA4LTI1
  VDE2OjMwOjM0KzAzOjAwZyXiEgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wOC0yNVQxNjozMDoz
  NCswMzowMBZ4Wq4AAAAASUVORK5CYII="
      />
    </svg>
  </div>

  <svg
    class="spinner"
    width="65px"
    height="65px"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="path"
      fill="none"
      stroke-width="3"
      stroke-linecap="round"
      cx="33"
      cy="33"
      r="30"
    ></circle>
  </svg>
</div>
