import { NgModule } from '@angular/core';
import { TickethistoryEffects } from './tickethistory.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromTickethistory from './tickethistory.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(
      fromTickethistory.tickethistoryFeatureKey,
      fromTickethistory.reducer
    ),
    EffectsModule.forFeature([TickethistoryEffects])
  ]
})
export class TickethistoryStoreModule {}
