import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControlFormatValue],[formControlFormatView]'
})
export class FormControlFormatDirective implements OnInit, OnDestroy {
  private _subscription$: Subscription;

  constructor(private _control: NgControl, private _elRef: ElementRef) {}
  @Input() formControlFormatView: (val: string | number) => string | number =
    val => val;
  @Input() formControlFormatValue: (val: string | number) => string | number =
    val => val;

  /** start subscribe on control changes */
  ngOnInit(): void {
    this._subscription$ = new Subscription();
    this._subscription$.add(
      this._control.valueChanges
        .pipe(
          map((val, index) => ({ val, index })),
          filter(({ index }) => index % 2 === 0), // to avoid recursive call
          map(({ val }) => val)
        )
        .subscribe(value => {
          // prew = this.formControlFormatValue(prew);
          value = this.formControlFormatValue(value);

          // store original unmodified value (including last change)
          this._control.control.setValue(this.formControlFormatView(value), {
            emitEvent: false
          });

          // update model without emitting event and without changing view model
          this._control.control.setValue(value, {
            emitModelToViewChange: false
          });
        })
    );
  }

  /** clear subscriptions */
  ngOnDestroy(): void {
    this._subscription$?.unsubscribe();
  }
}
