export const TICKET_HISTORY_STATUS = {
  won: {
    icon: 'icon-ic_thumbs-up',
    background: 'var(--ticket-status-background-color-won)',
    color: 'var(--ticket-status-color-won)'
  },
  lost: {
    icon: 'icon-ic_thumbs-down',
    background: 'var(--ticket-status-background-color-lost)',
    color: 'var(--ticket-status-color-lost)'
  },
  pend: {
    icon: 'icon-ic_submited',
    background: 'var(--ticket-status-background-color-pend)',
    color: 'var(--ticket-status-color-pend)'
  },
  refused: {
    icon: 'icon-ic_alert1',
    background: 'var(--ticket-status-background-color-refused)',
    color: 'var(--ticket-status-color-refused)'
  },
  canceled: {
    icon: 'icon-ic_cancelled',
    background: 'var(--ticket-status-background-color-canceled)',
    color: 'var(--ticket-status-color-canceled)'
  },
  await: {
    icon: 'icon-ic_move',
    background: 'var(--ticket-status-background-color-await)',
    color: 'var(--ticket-status-color-await)'
  },
  buyback: {
    icon: 'icon-ic_cashout',
    background: 'var(--ticket-status-background-color-won)',
    color: 'var(--ticket-status-color-won)'
  },
  compensation: {
    icon: 'icon-ic_compensated',
    background: 'var(--ticket-status-background-color-won)',
    color: 'var(--ticket-status-color-won)'
  },
  norisk: {
    icon: 'icon-ic_shield2',
    background: 'var(--ticket-status-background-color-norisk)',
    color: 'var(--ticket-status-color-norisk)'
  },
  expired: {
    icon: 'icon-ic_alert',
    background: 'var(--ticket-status-background-color-refused)',
    color: 'var(--ticket-status-color-expired)'
  },
  edited: {
    icon: 'icon-ic_alert',
    background: 'var(--ticket-status-background-color-edited)',
    color: 'var(--ticket-status-color-edited)'
  },
  default: {
    icon: '',
    background: '',
    color: ''
  }
};
