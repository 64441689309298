import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Event, Events } from '@models/Event';
import { Odd } from '@models/Odd';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducer';
import { Subscription, Observable, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { oddsAnimations, openCloseArrowAnimation } from 'src/app/animations';
import { ActivatedRoute } from '@angular/router';
import { Dictionary } from '@ngrx/entity';
import { TopEvent } from '@models/TopEvent';
import { authSelectors, ticketSelectors } from '@store/selectors';
import { ModalController } from '@ionic/angular';

import { configuration } from '@configuration';
import { IsOddLockedPipe } from '@shared/pipes/is-odd-locked.pipe';
import { RegisterLogInModalComponent } from '../register-log-in-modal/register-log-in-modal.component';
import { favoritesActions } from '@store/actions';
import { PresentMessageService } from 'src/app/services/present-message.service';
import { TranslateService } from '@ngx-translate/core';
import { isAvailable } from '@core/functions/isAvailable';

type OfferSections = 'live' | 'prematch' | 'results';
@Component({
  selector: 'doxx-event-list-item',
  templateUrl: './event-list-item.component.html',
  styleUrls: ['./event-list-item.component.scss'],
  animations: [...oddsAnimations, openCloseArrowAnimation]
})
export class EventListItemComponent implements OnInit, OnDestroy {
  _analysis: boolean;
  _stats: boolean;
  _matchtracker: boolean;
  _stream: boolean;
  _betBuilder: boolean;
  _odds$: Observable<Odd[]>;
  private _subscription$: Subscription;
  config = configuration;

  private _oddSubscription$: Subscription;

  more;
  @Input() buybackEventsExpanded = false;

  isLoggedIn = false;

  @Input() set event(ev: Observable<Event | TopEvent>) {
    if (ev instanceof Observable) {
      this._event$ = ev;
    } else {
      this._asignEvent(ev);
    }
  }
  @Input() set odds$(odds: Observable<Dictionary<Odd>>) {
    this._odds$ = odds.pipe(
      map((_odds: Dictionary<Odd>): Odd[] => {
        const tipTypeOddDict = {};
        let nonNullOdds = 0;
        Object.values(_odds).forEach(
          odd => (tipTypeOddDict[odd.TipType] = odd)
        );
        return [1, 'X', 2, '1X', 'X2', 12]
          .map(tipType => {
            if (tipTypeOddDict[tipType]) {
              nonNullOdds += 1;
              return tipTypeOddDict[tipType];
            } else {
              return null;
            }
          })
          .filter(odd => {
            if (nonNullOdds <= 2) {
              return !!odd;
            } else {
              return true;
            }
          });
      })
    );
  }
  @Input() events$: Observable<Events>;
  @Input() eventUrl?: string;
  @Input() hasStatistics: boolean;
  @Input() isVirtual = false;
  @Input() showSportIcon = false;
  @Output() oddClicked = new EventEmitter<Odd>();
  @Input() odd: Odd;
  oddType: 'NUMBERING' | 'NAMING' = 'NAMING';
  private _eventSubscription: Subscription;
  private _isLoggedInSubscription: Subscription;
  private _oddsSubscription: Subscription;
  section: OfferSections;
  private _event$: Observable<Event | TopEvent> = EMPTY;
  _event: Event | TopEvent;
  _events;
  odds: Dictionary<Odd>;
  eventOdds = [];
  ticket;
  showEventFav = false;

  constructor(
    protected _store: Store<AppState>,
    private _route: ActivatedRoute,
    private _modalController: ModalController,
    private _isOddLocked: IsOddLockedPipe,
    private _presentMessage: PresentMessageService,
    private _translate: TranslateService
  ) {
    this.section = this._route.snapshot.data.section;
  }

  /** init list item */
  ngOnInit(): void {
    this.onInViewPort();
    this._subscription$ = new Subscription();
    if (configuration.settings.offer.oddsNaming.editable) {
      this._subscription$.add(
        this._store.select(authSelectors.selectSettings).subscribe(settings => {
          this.oddType =
            settings?.setting?.offerViewType?.value === 'NUMBERING'
              ? 'NUMBERING'
              : 'NAMING';
        })
      );
    }

    this._subscription$.add(
      this._store
        .select(authSelectors.selectShowFavLeague)
        .subscribe(result => {
          this.showEventFav = result;
        })
    );
  }

  /** method is called when odd component is in view port */
  onViewportChange(isIn: boolean): void {
    if (isIn) {
      this.onInViewPort();
    } else {
      this.onOutViewPort();
    }
  }

  /**
   * Start subscribing for changes in store
   */
  onInViewPort(): void {
    this._eventSubscription = this._event$.subscribe(event => {
      this._asignEvent(event);
    });

    this._isLoggedInSubscription = this._store
      .select(authSelectors.selectIsUserLoggedIn)
      .subscribe(isLoggedIn => (this.isLoggedIn = isLoggedIn));

    this._oddsSubscription = this._odds$.subscribe(odds => {
      this.removeEmptyOdds(odds);
      this.eventOdds = odds;
    });

    this._oddSubscription$ = this._store
      .select(ticketSelectors.selectTicket)
      .subscribe(ticket => {
        this.ticket = ticket;
      });
  }

  /**
   * hasOddOnTicket
   * @param eventId any
   * @param eventOdds any
   * @returns any
   */
  hasOddOnTicket(eventId: any, eventOdds: any): boolean {
    const oddOnTicket = this.ticket?.Odds?.find(odd => odd.EVT === eventId);
    if (
      oddOnTicket &&
      !eventOdds.find(odd => odd.OddsID === oddOnTicket.OddID)
    ) {
      // OddsID
      // !eventOdds.includes(oddOnTicket
      return true;
    }
    return false;
  }

  /**
   * Clear all subscriptions
   */
  onOutViewPort(): void {
    this._eventSubscription?.unsubscribe();
    this._isLoggedInSubscription?.unsubscribe();
    this._oddsSubscription?.unsubscribe();
    this._oddSubscription$?.unsubscribe();
  }

  /** unsubscribe all subscriptions */
  ngOnDestroy(): void {
    this._subscription$?.unsubscribe();
    this._eventSubscription?.unsubscribe();
    this._isLoggedInSubscription?.unsubscribe();
    this._oddsSubscription?.unsubscribe();
    this._oddSubscription$?.unsubscribe();
  }

  /**
   * Function for tracking unique odds in template
   * to avoid unnecessary rendering
   */
  trackOdd(index: number, odd: Odd): number {
    return odd?.OddsID;
  }

  /** add event to fav */
  addEventToFav(evID: number): void {
    if (this.isLoggedIn) {
      this._store.dispatch(favoritesActions.favEventAdd({ payload: evID }));
    } else {
      this.showRegisterLogInModal();
    }
  }

  /** remove event from fav */
  removeEventFromFav(evID: number): void {
    if (this.isLoggedIn) {
      this._store.dispatch(favoritesActions.favEventRemove({ payload: evID }));
    } else {
      this.showRegisterLogInModal();
    }
  }
  /** show register modal */
  async showRegisterLogInModal(): Promise<void> {
    const registerLogInModal = await this._modalController.create({
      component: RegisterLogInModalComponent,
      cssClass: 'register-log-in-modal',
      componentProps: {}
    });
    return await registerLogInModal.present();
  }

  trackPartials = index =>
    `${this._event.ParsedScore.home.partials[index]}_${this._event.ParsedScore.away.partials[index]}`;

  private _asignEvent(event: Event | TopEvent): void {
    this._event = event;
    if (!!event) {
      this._analysis = configuration.analysis.allowed && !!event.EventAnalyseID;
      this._stats = configuration.statistics.allowed && this.hasStatistics;
      this._matchtracker =
        configuration.matchTracker.allowed && event.HasMatchTracker;
      this._stream =
        configuration.streams.allowed &&
        (event?.Stream || event?.ProviderStreamID) &&
        event?.LiveBetting === 'Y';
      this._betBuilder =
        event?.LiveBetting === 'N' &&
        event.BetBuilderID &&
        configuration.betBuilder.allowed;
    }
  }

  /**
   * ak su v druhom riadku 3 po sebe prazdne alebo zamknute odds
   */
  removeEmptyOdds(odds: Odd[]): void {
    if (odds.length === 6) {
      let countNull = 0;
      let countLocked = 0;
      for (let i = 3; i < 6; i++) {
        countNull += odds[i] === null ? 1 : 0;
        if (countNull === 0) {
          countLocked += this._isOddLocked.transform(
            odds[i],
            this._event,
            this._event
          )
            ? 1
            : 0;
        }
      }
      if (countNull === 3 || countLocked === 3) {
        odds.splice(3, 5);
      }
    }
  }

  /**
   * show tooltip message
   */
  showTooltip(event: Event | TopEvent): void {
    if (event.EventResultComment) {
      this._presentMessage.presentCustomMessage(
        event.EventResultComment,
        'info-alert'
      );
    } else if (
      event.EventComment &&
      event.AggregateScore &&
      event.SeriesResult
    ) {
      this._presentMessage.presentCustomMessage(
        event.EventComment +
          ', ' +
          this._translate.instant('agregate_score') +
          ': ' +
          event.AggregateScore +
          ', ' +
          this._translate.instant('series_result') +
          ': ' +
          event.SeriesResult,
        'info-alert'
      );
    } else if (event.AggregateScore && event.SeriesResult) {
      this._presentMessage.presentCustomMessage(
        this._translate.instant('agregate_score') +
          ': ' +
          event.AggregateScore +
          ' , ' +
          this._translate.instant('series_result') +
          ': ' +
          event.SeriesResult,
        'info-alert'
      );
    } else if (event.EventComment) {
      // EventComment
      this._presentMessage.presentCustomMessage(
        event.EventComment,
        'info-alert'
      );
    } else if (event.AggregateScore) {
      this._presentMessage.presentCustomMessage(
        this._translate.instant('agregate_score') + ': ' + event.AggregateScore,
        'info-alert'
      );
    } else if (event.SeriesResult) {
      this._presentMessage.presentCustomMessage(
        this._translate.instant('series_result') + ': ' + event.SeriesResult,
        'info-alert'
      );
    }
  }
}
