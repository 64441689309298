import {
  Component,
  Input,
  HostListener,
  OnDestroy,
  AfterViewInit,
  ViewChild
} from '@angular/core';
import { CasinoGame } from '@models/CasinoGame';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { authSelectors, maintenanceSelectors } from '@store/selectors';
import { Router } from '@angular/router';
import { User } from '@models/User';
import { CasinoService } from '@core/services/casino/casino.service';
import { translateRoute } from '@core/functions/translateRoute';
import { filter, first } from 'rxjs/operators';
import { configuration } from '@configuration';
import { InViewportDirective } from '@shared/directives/in-viewport/in-viewport.directive';
import { environment } from '@environment';

const CASINO_TYPE = {
  casino: 'kasino-online',
  rio: 'rio-online'
};
@Component({
  selector: 'doxx-casino-game-card',
  templateUrl: './casino-game-card.component.html',
  styleUrls: ['./casino-game-card.component.scss']
})
export class CasinoGameCardComponent implements OnDestroy, AfterViewInit {
  private _subscription$ = new Subscription();

  @ViewChild(InViewportDirective) card: InViewportDirective;
  @Input() casinoType: 'rio' | 'casino';
  @Input() game: CasinoGame;
  user: User;
  isCasinoBlocked: boolean;
  gameImage: string;
  isDisabled: boolean;
  DisplayName: string;

  constructor(
    private _store: Store,
    private _router: Router,
    private _casinoService: CasinoService
  ) {
    this._subscription$.add(
      this._store.select(authSelectors.selectLoggedUser).subscribe(user => {
        this.user = user;
        if (!!user) {
          this.isDisabled =
            (configuration.verification.allowed &&
              !this.user.verified &&
              environment.location !== 'nga' &&
              environment.location !== 'lbr') ||
            (configuration.verification.rvo &&
              this.user.gamblingProhibited &&
              environment.location !== 'nga' &&
              environment.location !== 'lbr') ||
            !user.casinoAllowed;
        }
      })
    );
    this._subscription$.add(
      this._store
        .select(maintenanceSelectors.selectIsCasinoBlocked)
        .subscribe(isBlocked => (this.isCasinoBlocked = isBlocked))
    );
  }

  /** set attribute for img src, this is afterviewinit hook is used for correct lazy loading */
  ngAfterViewInit(): void {
    this._subscription$.add(
      this.card.inViewportChange
        .pipe(filter(Boolean), first())
        .subscribe(
          () => (
            (this.gameImage = this.game.GameImage),
            (this.DisplayName = this.game.DisplayName)
          )
        )
    );
  }

  /** clear subscriptions */
  ngOnDestroy(): void {
    this._subscription$.unsubscribe();
  }

  /** Otvori hru */
  @HostListener('click', ['$event.target'])
  presentCasinoPlayOptions(): void {
    // this._casinoService.testURI(   this.game.SystemName,
    //   CASINO_TYPE[this.casinoType],
    //   this.game.CategoryID);
    //test

    this._casinoService.presentPlayOptions(
      this.game.SystemName,
      CASINO_TYPE[this.casinoType],
      this.game.CategoryID
    );
  }

  /** Otvori hru */
  openRealGame(): void {
    if (this.isCasinoBlocked) {
      this._router.navigate([
        translateRoute('Maintenance', { type: 'casino' })
      ]);
      return;
    }
    if (
      !this.user ||
      (configuration.verification.allowed &&
        this.user.verified === false &&
        environment.location !== 'nga')
    ) {
      return;
    }

    if (this.user) {
      console.log(this.game);
      this._casinoService.openRealGame(
        this.game.SystemName,
        this.user.clientCode,
        CASINO_TYPE[this.casinoType],
        this.game.CategoryID
      );
    }
  }
}
