import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { oneLine } from '@core/functions/oneLine';
import { map } from 'rxjs/operators';
import { FetchEventsOptions, FetchEventDetailOptions } from '../types';
import { TimezoneService } from '../timezone/timezone.service';
import { transformOfferListResponse } from '../offer/transformOfferListResponse';
import { transformWholeMenuResponse } from '../offer/transformWholeMenuResponse';
import { OfferResponse } from '../offer/offerResponse';
import { transformMainMenuResponse } from '../offer/transformMainMenuResponse';

@Injectable({
  providedIn: 'root'
})
export class ResultsService {
  constructor(
    private _httpClient: HttpClient,
    private _timezoneService: TimezoneService
  ) {}

  /**
   * Fetch events from offer service and parse it to JSON
   */
  fetchEvents(options: FetchEventsOptions): Observable<any> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/json/ResultsService.svc/List
        /SportEvent/${options.sportEvent || '-1'}
        /Sport/${options.sportId || '-1'}
        /Region/${options.regionId || '-1'}
        /LeagueCup/${options.leagueCupID || '-1'}
        /Date/${options.date || '-1'}
        /Zone/${this._timezoneService.timezone}
        /Top/${options.top ? '1' : '-1'}
        /LanguageID/${environment.language}`
      )
      .pipe(map(data => transformOfferListResponse(data)));
  }

  /**
   * Fetch event detail from offer service and parse it to JSON
   * https://m-api-staging.doxxbet.sk/ResultsService.svc/Detail/Event/15615287/Zone/undefined/LanguageID/sk?nocache=1595422423582
   */
  fetchResultDetail(options: FetchEventDetailOptions): Observable<any> {
    const result = this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/json/ResultsService.svc/Detail
        /Event/${options.eventId}
        /Zone/${this._timezoneService.timezone}
        /LanguageID/${environment.language}`
    );
    return result;
  }

  /**
   * Fetch whole menu includes sports, regions and leagues
   */
  fetchWholeMenu(options: {
    date?: string;
    sportId?: number;
    sportEvent?: number;
  }): Observable<any> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/json/ResultsService.svc/Menu/WholeMenu
        /SportEvent/${options.sportEvent || '-1'}
        /Sport/${options.sportId || '-1'}
        /Date/${options.date || '-1'}
        /Zone/${this._timezoneService.timezone}
        /LanguageID/${environment.language}`
      )
      .pipe(map(result => transformWholeMenuResponse(result)));
  }

  /** fetch sports menu */
  fetchMainMenu(options: { date: string }): Observable<OfferResponse> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/json/ResultsService.svc/Menu/MainMenu
      /Date/${options.date}
      /Zone/${this._timezoneService.timezone}
      /LanguageID/${environment.language}`
      )
      .pipe(map(result => transformMainMenuResponse(result)));
  }

  /**
   * Loads available leagues
   */
  fetchLeagueCupMenu(
    options: {
      sportID?: number;
      regionID?: number;
      sportEventID?: number;
      live?: boolean;
      date?: string;
    } = {}
  ): Observable<OfferResponse> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/json/ResultsService.svc/Menu/LeagueCupMenu
        /SportEvent/${options?.sportEventID || '-1'}
        /Sport/${options?.sportID || '-1'}
        /Region/${options?.regionID || '-1'}
        /Date/${options?.date || '-1'}
        /Zone/${this._timezoneService.timezone}
        /LanguageID/${environment.language}`
      )
      .pipe(map(result => transformWholeMenuResponse(result)));
  }
}
