import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { oneLine } from '@core/functions/oneLine';
import { environment } from '@environment';
import { Banner } from '@models/Banner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BannersService {
  constructor(private _httpClient: HttpClient) {}

  /** Nacita bettingove bannery */
  loadBettingBanners(): Observable<Banner[]> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/web/client/data/banners/category/sportbook`
      )
      .pipe(
        map(data =>
          data.Response.map(bonus => ({
            ...bonus,
            StartDateString: bonus.StartDateString?.replace(' ', 'T'),
            EndDateString: bonus.EndDateString?.replace(' ', 'T')
          }))
        )
      );
  }

  /** Nacita casino bannery */
  loadCasinoBanners(): Observable<Banner[]> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/web/client/data/banners/category/casino`
      )
      .pipe(
        map(data =>
          data.Response.map(bonus => ({
            ...bonus,
            StartDateString: bonus.StartDateString?.replace(' ', 'T'),
            EndDateString: bonus.EndDateString?.replace(' ', 'T')
          }))
        )
      );
  }

  /** Nacita casino bannery */
  loadRioBanners(): Observable<Banner[]> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/web/client/data/banners/category/rio`
      )
      .pipe(
        map(data =>
          data.Response.map(bonus => ({
            ...bonus,
            StartDateString: bonus.StartDateString?.replace(' ', 'T'),
            EndDateString: bonus.EndDateString?.replace(' ', 'T')
          }))
        )
      );
  }
}
