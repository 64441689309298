import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { configuration } from '@configuration';
import { TicketHistoryListItem } from '@models/TicketHistory';
import { Store } from '@ngrx/store';
import { buyBackActions } from '@store/actions';
import { buyBackSelectors } from '@store/selectors';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BuybackService } from './buyback.service';

type BuyBackContext = {
  AmountMoney: number;
  AmountMoneyWin: number;
  ClientID: number;
  EventIDs: number[];
  IsFreebetTicket: number;
  TicketCode: string;
  TicketType: 'SRT' | 'JT';

  // buyback ext
  AutoBuyBackValue: number;
  BuyBackDelayMs: number;
  BuyBackValue: number;
  IsBuyBack: boolean;
  __hydrated?: boolean;
};

const props = [
  'BuyBackValue',
  'AutoBuyBackValue',
  'BuyBackValueMs',
  'BuyBackDelayMs'
];

@Directive({
  selector: '[doxxBuybackTicket],[doxxBuybackEvent]'
})
export class BuybackDirective implements OnDestroy {
  private _subscription$: Subscription;

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _store: Store,
    private _cd: ChangeDetectorRef
  ) {}

  @Input()
  set doxxBuybackTicket(ticketCode: string) {
    const source$ = this._store
      .select(buyBackSelectors.selectBuybackStateValueByTicketCode(ticketCode))
      .pipe(map(data => [data]));
    this._changeView(source$, values => values[0]);
  }

  @Input()
  set doxxBuybackEvent(eventID: string) {
    const source$ = this._store.select(
      buyBackSelectors.selectBuybackStateValueByEventID(eventID)
    );
    this._changeView(source$);
  }

  private _changeView(
    source$: Observable<BuyBackContext[]>,
    mapValues = val => val
  ) {
    if (!configuration.betslip.buyBack) {
      this._render(null, false);
    } else {
      this._subscription$?.unsubscribe();
      const getter = (ticket, property) => {
        if (ticket[property] === undefined && !('__hydrated' in ticket)) {
          this._store.dispatch(
            buyBackActions.loadBuybackValues({
              ticketCode: ticket.TicketCode
            })
          );
          ticket.__hydrated = ticket.__hydrated || false;
        }
        return null;
      };
      this._subscription$ = source$.subscribe(data => {
        data = data.filter(
          ticket =>
            ticket.TicketType === 'JT' &&
            ('BuyBackValue' in ticket ? ticket.BuyBackValue >= 0 : true)
        );
        if (data.length > 0) {
          this._render(
            mapValues(
              data.map(ticket => ({
                ...ticket,
                ...props.reduce(
                  (acc, prop) => ({
                    ...acc,
                    ...(prop in ticket || ticket.__hydrated
                      ? { [prop]: ticket[prop] }
                      : {
                          get [prop]() {
                            return getter(ticket, prop);
                          }
                        })
                  }),
                  {}
                )
              }))
            ),
            true
          );
        } else {
          this._render(null, false);
        }
      });
    }
  }

  private _render(buyBackValues, hasBuyBack) {
    this._viewContainer.clear();
    this._viewContainer.createEmbeddedView(this._templateRef, {
      $implicit: buyBackValues,
      hasBuyBack
    });
    this._cd.detectChanges();
  }

  /** unsubscribe */
  ngOnDestroy(): void {
    this._viewContainer.clear();
    this._subscription$?.unsubscribe();
  }
}

@Directive({ selector: '[doxxBuybackModalTrigger]' })
export class BuybackModalTriggerDirective {
  constructor(private _buybackService: BuybackService) {}
  @Input() doxxBuybackModalTrigger: TicketHistoryListItem['ticketCode'];
  @Output() buybackExecuted = new Subject();

  /** show buyback modal */
  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    this._buybackService
      .showBuybackModal(this.doxxBuybackModalTrigger)
      .then(executed => {
        if (executed) {
          this.buybackExecuted.next(executed);
        }
      });
  }
}

@Directive({ selector: '[doxxAutoBuybackModalTrigger]' })
export class AutoBuybackModalTriggerDirective {
  constructor(private _buybackService: BuybackService) {}
  @Input() doxxAutoBuybackModalTrigger: TicketHistoryListItem['ticketCode'];
  @Output() autoBuybackExecuted = new EventEmitter();

  /** show auto buyback modal */
  @HostListener('click')
  onClick(): void {
    this._buybackService
      .showAutoBuybackModal(this.doxxAutoBuybackModalTrigger)
      .then(data => {
        if (data.autobuyback) {
          this.autoBuybackExecuted.next(data.autobuyback);
        }
      });
  }
}
