import { Directive, TemplateRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[doxxBanner]'
})
export class BannerDirective {
  public bannerTemplate: TemplateRef<any>;
  @Input() order: number;
  @Input() timing: number;
  @Input() preview: TemplateRef<any>;

  constructor(private _templateRef: TemplateRef<any>) {
    this.bannerTemplate = this._templateRef;
  }
}
