import { Component, ElementRef, OnInit } from '@angular/core';
import {
  ActionSheetController,
  MenuController,
  ModalController
} from '@ionic/angular';
import { FreebetsModalComponent } from './freebets-modal/freebets-modal.component';
import {
  TicketType,
  Ticket,
  AllowedRateChange,
  TicketOdd
} from '../../models/Ticket';
import { EMPTY, interval, race, Subscription } from 'rxjs';
import {
  tap,
  map,
  shareReplay,
  first,
  switchMap,
  filter,
  distinctUntilChanged,
  withLatestFrom
} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { TicketBubleService } from '@core/services/ticket-buble/ticket-buble.service';
import * as authActions from '@store/auth/auth.actions';
import { PresentMessageService } from 'src/app/services/present-message.service';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { authSelectors, ticketSelectors } from '@store/selectors';
import { ActivationEnd, Router } from '@angular/router';
import { translateRoute } from '@core/functions/translateRoute';
import { TranslateService } from '@ngx-translate/core';
import {
  buyBackActions,
  ticketActions,
  tickethistoryActions
} from '@store/actions';
import { environment } from '@environment';
import { TranslateSpecialPipe } from '@shared/pipes/translate.pipe';
import { User } from '@models/User';
import { TicketErrorParser } from './ticket.error-parser';
import { AppVisibilityService } from '@core/services/app-visibility/app-visibility.service';
import { TICKET_REFRESH_INACTIVE_TIMEOUT } from '@constants';
import { configuration } from '@configuration';

const CLOSE_TO_LIVE = 30;
@Component({
  selector: 'doxx-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {
  private _subscription$;
  private _suspendedOddsRemoved = true;
  private _errorAlert: HTMLIonAlertElement = null;

  ticket$ = this._store.select(ticketSelectors.selectTicket).pipe(
    filter(ticket => !!ticket),
    map(ticket => ({
      ...ticket,
      Odds:
        ticket.TYPE === 'SRT'
          ? ticket.Odds.sort((a, b) =>
              a.Group < b.Group ? -1 : a.Group > b.Group ? 1 : 0
            )
          : ticket.Odds,
      _hasSuspendedOdds: ticket?.Odds.some(
        odd => !odd.IsEditedOdd && !odd.IsActive
      ),
      _activeFreebet: ticket?.Freebets?.find(freebet => freebet.IsActive)
    })),
    shareReplay(1),
    tap(ticket => {
      this.ticket = ticket;
      this.ratexOdds = this.ratexOdds.filter(oddID =>
        ticket.Odds.find(odd => odd.OddID === oddID)
      );
      if (!this.betFocused) {
        this.bet = +ticket.OverallTotalBet;
      }
    })
  );
  ticket: Ticket & {
    _hasSuspendedOdds: boolean;
    _activeFreebet: Ticket['Freebets'][number];
  };

  user$ = this._store.select(authSelectors.selectLoggedUser);
  user: User;

  ratexOdds: TicketOdd['OddID'][] = [];

  bet: number;
  betFocused = false;
  configuration;
  /** nastavi prazdnu hodnotu na bet a zablokuje submit  */
  focusBet(): void {
    this.betFocused = true;
  }
  /** change ticket bet */
  changeTicketBet(bet: number): void {
    if (!isNaN(bet)) {
      this.bet = bet;
      this.changeBet(bet.toFixed(2));
    }
    this.betFocused = false;
  }
  /** change visible bet */
  changeVisibleBet(bet: number): void {
    if (bet) {
      this.bet = bet;
    }
  }

  // SLIDER SETUP
  // sliderValues = [
  //   0.5, 1, 1.5, 2, 2.5, 3, 5, 10, 15, 20, 25, 30, 50, 75, 100, 150, 200, 250,
  //   300, 350, 400, 450, 500
  // ];

  sliderValues = [
    2000, 5000, 7500, 10000, 15000, 20000, 30000, 50000, 75000, 90000, 100000,
    125000, 150000, 175000, 200000, 250000, 300000, 350000, 400000, 500000,
    600000, 700000, 800000, 900000, 1000000
  ];
  betToSlider = (bet: number) => {
    if (bet > this.sliderValues.last()) {
      return this.sliderValues.length;
    }
    const firstDown = this.sliderValues.findIndex(val => val >= bet);
    const sliderVal =
      firstDown +
      (bet - this.sliderValues[firstDown]) /
        (this.sliderValues[firstDown + 1] - this.sliderValues[firstDown]);
    return sliderVal;
  };

  sliderToBet = (index: number) => this.sliderValues[index];

  constructor(
    private _actionSheetController: ActionSheetController,
    private _modalController: ModalController,
    private _cookieService: CookieService,
    private _store: Store,
    private _ticketBubbleService: TicketBubleService,
    private _presentMessageService: PresentMessageService,
    private _menu: MenuController,
    private _router: Router,
    private _translateService: TranslateService,
    private _translateSpecial: TranslateSpecialPipe,
    private _actions$: Actions,
    private _contentElem: ElementRef<HTMLElement>,
    private _ticketErrorParser: TicketErrorParser,
    private _appVisibilityService: AppVisibilityService
  ) {
    this._router.events
      .pipe(
        filter(ev => ev instanceof ActivationEnd && ev.snapshot.data.PageName),
        map(
          (event: ActivationEnd, index) =>
            ({
              Landing: 'TC1',
              BonusDetail: 'TC1',
              PrematchList: 'TC1',
              EventDetail: 'TC1',
              LiveList: 'TC1',
              EventDetail_Live: 'TC1',
              VirtualGames: 'TVG1',
              EventDetail_VirtualGames: 'TVG1'
            }[event.snapshot.data.PageName] || (index === 0 ? 'current' : null))
        ),
        distinctUntilChanged(),
        withLatestFrom(this._store.select(ticketSelectors.selectTicket))
      )
      .subscribe(([flag, ticket]) => {
        if (flag && !(ticket?.TicketFlag === flag)) {
          this._store.dispatch(
            ticketActions.switchTicket({ ticketFlag: flag })
          );
        }
      });
  }

  public sliderTotalBet: number;

  public rateChanges = '0';

  /**
   * Changed Bet from input
   */
  changeBet(bet: string): void {
    if (bet !== this.ticket.OverallBet) {
      if (this.ticket.TYPE === 'SRT') {
        this._store.dispatch(
          ticketActions.setCombinationStake({
            bet,
            combiID: 'all',
            isPartial: false
          })
        );
      } else {
        this._store.dispatch(ticketActions.setTotalBet({ bet }));
      }
    }
  }

  /**
   * Initialisation Ticket Component
   */
  ngOnInit(): void {
    this.configuration = configuration;
    this._subscription$ = new Subscription();
    this._subscription$.add(
      this.user$.subscribe(user => {
        this.user = user;
      })
    );
    this._subscription$.add(
      //first occur suspended odd after previous removing, scroll ticket to top
      this.ticket$.subscribe(ticket => {
        if (ticket._hasSuspendedOdds && this._suspendedOddsRemoved) {
          this._suspendedOddsRemoved = false;
          this.scrollToTopTicket();
        } else if (!ticket._hasSuspendedOdds) {
          this._suspendedOddsRemoved = true;
        }
      })
    );

    // SET ALLOWED RATE CHANGE
    this._subscription$.add(
      this._store
        .select(ticketSelectors.selectAllowedRateChange)
        .subscribe(type => {
          if (type === 'DELTA') {
            // use allowed rate change from cookes. ALL is fallback
            let newType = this._cookieService.get(
              environment.cookies.allowedRateChange.key
            );
            newType = ['ALL', 'UP', 'NONE'].includes(newType) ? newType : 'ALL';
            this._changeAllowedRateChange(newType as AllowedRateChange);
          }
        })
    );
    /**
     * Kontrola Oddov na tikete, ci obsahuju live udalost, ak ano, setne tiket ako liveTiket
     * nova logika, 3 stavy - live, closeToLive, prematch
     * Ak je live, hned nastavi DELTU na live … ak nie je, prechadza Oddy na tikete a zistuje cas kazdeho eventu
     */
    if (configuration.betslip.creating.fullRefresh.allowed) {
      this._subscription$.add(
        this._store
          .select(ticketSelectors.selectOdds)
          .pipe(
            map(odds => {
              const timeDiffs = odds
                .map(odd =>
                  odd.LiveBet === true
                    ? -1
                    : new Date().minutesDiff(new Date(odd.DATE))
                )
                .sort((a, b) => a - b);

              if (timeDiffs.length === 0) {
                return 'off';
              } else if (timeDiffs[0] < 0) {
                return 'live';
              } else if (timeDiffs[0] < CLOSE_TO_LIVE) {
                return 'closeToLive';
              } else {
                return 'prematch';
              }
            }),
            distinctUntilChanged(),
            switchMap(
              delta =>
                ({
                  off: EMPTY,
                  live: interval(
                    configuration.betslip.creating.fullRefresh.intervals.live
                  ),
                  closeToLive: interval(
                    configuration.betslip.creating.fullRefresh.intervals
                      .closeToLive
                  ),
                  prematch: interval(
                    configuration.betslip.creating.fullRefresh.intervals
                      .prematch
                  )
                }[delta])
            )
          )
          .subscribe(() => {
            if (
              this._appVisibilityService.hiddenTime <
              TICKET_REFRESH_INACTIVE_TIMEOUT
            )
              this._store.dispatch(ticketActions.refreshTicket());
          })
      );
    }
    // select and display errors
    this._subscription$.add(
      this._store
        .select(ticketSelectors.selectTicketErrors)
        .pipe(
          distinctUntilChanged(),
          filter(errors => errors.length > 0),
          withLatestFrom(this.ticket$)
        )
        .subscribe(([errors, ticket]) => {
          try {
            let parsedErrors = this._ticketErrorParser.parseError(
              errors,
              ticket
            );
            const ratexError = parsedErrors.find(
              err => err.errorCode === 'ERR_RATEX'
            );
            if (ratexError) {
              this.ratexOdds = ratexError.errorParams.map(oddID => +oddID);
              this.scrollToTopTicket();
              parsedErrors = parsedErrors.filter(
                err => err.errorCode !== 'ERR_RATEX'
              );
              if (parsedErrors.length === 0) {
                return;
              }
            }
            const { message, errorCode, errorParams } = parsedErrors[0];
            if (
              (errorCode === 'ERR_MINBET' ||
                errorCode === 'ERR_MINOVERALLBET' ||
                errorCode === 'ERR_MAXBET') &&
              !ticket.IsEdited
            ) {
              this.changeBet(errorParams[0].replace(',', '.'));
              this.bet = +errorParams[0].replace(',', '.');
            } else if (errorCode === 'ERR_NOCLIENT') {
              this._router.navigate([translateRoute('Login')]);
              this.hideTicket();
            }

            Promise.all([
              this._errorAlert?.dismiss() || Promise.resolve(),
              this._menu.isOpen('ticketMenu')
            ]).then(([_, isOpen]) => {
              if (
                errorCode !== 'ERR_MORECOMBI' ||
                (errorCode === 'ERR_MORECOMBI' && isOpen)
              ) {
                document
                  .querySelectorAll('ion-alert')
                  .forEach(elem => elem.remove());
                this._presentMessageService
                  .presentCustomMessage(message, 'info-alert', '', ['OK'])
                  .then(alert => {
                    this._errorAlert = alert;
                    alert.onDidDismiss().then(() => {
                      this._errorAlert = null;
                    });
                  });
              }
            });
          } catch (ignored) {}
        })
    );
    // Show hide ticket bubble
    this._subscription$.add(
      this._store
        .select(ticketSelectors.selectOdds)
        .subscribe(odds =>
          odds.length > 0
            ? this._ticketBubbleService.showTicketBuble()
            : this._ticketBubbleService.hideTicketBuble()
        )
    );

    setTimeout(() => {
      // eslint-disable-next-line no-console
      console.log(
        '%c' + 'STAV SA. BAV SA!',
        'font-family: monospace; font-size:24px; font-weight:bold;  color: #f40034; background: #323840; border-radius: 5px; padding: 10px'
      );
      //console.log("%c+","font-size: 1px; padding: 60px 320px; line-height: 1px; background:
      //url(https://m.doxxbet.sk/assets/images/klikuj.gif); background-size: 640px 122px; color: transparent;");
    }, 4500);
  }

  /**
   * remove odd
   * @param oddID odd id
   */
  removeOdd(oddID: number): any {
    const y = this._contentElem.nativeElement.scrollTop;
    this._actions$
      .pipe(ofType(ticketActions.removeOddsComplete), first())
      .subscribe(() => this._contentElem.nativeElement.scrollTo(0, y));
    this._store.dispatch(ticketActions.removeOdds({ odds: [oddID] }));
  }

  /**
   * clear ticket
   */
  clearTicket(): any {
    this._store.dispatch(ticketActions.resetTicket());
  }

  /**
   * store ticket
   */
  storeTicket(): void {
    race(
      this._actions$.pipe(
        ofType(ticketActions.storeTicketSuccess),
        tap(action => {
          if (action.ticket?.Errors?.length === 0) {
            this._menu.close('ticketMenu');
            this.clearTicket();
            this._router.navigate([
              translateRoute('SavedBetslipDetail', {
                ticketID: action.ticket.PlaceBetTicketCode
              })
            ]);
          }
        })
      ),
      this._actions$.pipe(
        ofType(ticketActions.storeTicketFailure),
        tap(() => {
          this._presentMessageService.presentCustomMessage(
            this._translateService.instant('cs_err_UnexpectedError'),
            'error-alert'
          );
        })
      )
    )
      .pipe(first())
      .subscribe();
    this._store.dispatch(ticketActions.storeTicket());
  }

  /**
   * Change ticket type ('JT', 'SRT')
   */
  changeTicketType(changeToTicketType: TicketType): void {
    this._store.dispatch(
      ticketActions.changeTicketType({ ticketType: changeToTicketType })
    );
  }

  /**
   * Remove inactive Odds from ticket
   */
  removeInactiveOdds(): any {
    const y = this._contentElem.nativeElement.scrollTop;
    this._actions$
      .pipe(ofType(ticketActions.removeOddsComplete), first())
      .subscribe(() => {
        this._contentElem.nativeElement.scrollTo(0, y);
        this._suspendedOddsRemoved = true;
      });
    this._store.dispatch(
      ticketActions.removeOdds({
        odds: this.ticket.Odds.filter(
          odd => !odd.IsEditedOdd && !odd.IsActive
        ).map(odd => odd.OddID)
      })
    );
  }

  /**
   * Action sheet
   * Opens incoming rate changes options
   */
  presentActionSheet(): void {
    this._actionSheetController
      .create({
        cssClass: 'my-custom-class',
        buttons: [
          {
            text: this._translateService.instant(
              'ticket_accept_any_rate_change'
            ),
            data: 'ALL'
          },
          {
            text: this._translateService.instant(
              'ticket_accept_rate_change_up'
            ),
            data: 'UP'
          },
          {
            text: this._translateService.instant(
              'ticket_accept_no_rate_change'
            ),
            data: 'NONE'
          },
          {
            text: this._translateService.instant('ticket_cancel')
          }
        ]
      })
      .then(actionSheet => {
        actionSheet.onWillDismiss().then(({ data }) => {
          if (data) {
            this._changeAllowedRateChange(data);
          }
        });
        actionSheet.present();
      });
  }

  /**
   * Open freebets modal with input data from service
   */
  openFreebetsModal(): void {
    this._store
      .select(ticketSelectors.selectActiveFreebet)
      .pipe(first())
      .subscribe(freebet => {
        this._modalController
          .create({
            component: FreebetsModalComponent,
            componentProps: {
              selectedFreebet: freebet?.FreebetID
            }
          })
          .then(modal => {
            modal.onWillDismiss().then(({ data: freebetID }) => {
              if (freebetID && freebet?.FreebetID !== +freebetID) {
                this._store.dispatch(ticketActions.setFreebet({ freebetID }));
              } else if (!freebetID && freebet?.FreebetID) {
                this._store.dispatch(ticketActions.removeFreebet());
              }
            });
            modal.present();
          });
      });
  }

  private _changeAllowedRateChange(allowedRateChange: AllowedRateChange): void {
    this._actions$
      .pipe(ofType(ticketActions.setAllowedRateChangeComplete), first())
      .subscribe(action => {
        if (action.ticket?.Errors.length === 0) {
          this._cookieService.set(
            environment.cookies.allowedRateChange.key,
            allowedRateChange,
            +environment.cookies.allowedRateChange.expiration
          );
        }
      });
    this._store.dispatch(
      ticketActions.setAllowedRateChange({ allowedRateChange })
    );
  }

  /**
   * Odpocet pri podani tiketu
   */
  submitTicketConfirm(): void {
    if (this.user) {
      this.placeBet();
    } else {
      this._presentMessageService
        .presentCustomMessage(
          this._translateService.instant('app_login_not'),
          'info-alert',
          '',
          ['OK']
        )
        .then(alert => {
          this._errorAlert = alert;
          alert.onDidDismiss().then(() => {
            this._router.navigate([translateRoute('Login')]);
            this.hideTicket();
          });
        });
    }
  }

  /**
   * Accept rate change
   */
  acceptRatexOdds(): void {
    this.ratexOdds = [];
  }

  /**
   * Call place bet
   */
  placeBet(): void {
    this._modalController
      .create({
        component: LoadingComponent,
        cssClass: 'loading-modal',
        componentProps: {
          delay: +this.ticket.LiveBetDelayMs
        }
      })
      .then(modal => {
        if (+this.ticket.LiveBetDelayMs > 1000) {
          modal.present();
        }
        this._actions$
          .pipe(ofType(ticketActions.placeBetComplete), first())
          .subscribe(({ ticket }) => {
            modal.style.display = 'none';
            modal.dismiss();
            // placebet sucesfull
            if (ticket && ticket.Errors.length === 0) {
              this._store.dispatch(buyBackActions.loadBuybackTickets());
              this._ticketBubbleService.hideTicketBuble(
                true,
                this._translateService.instant(
                  ticket.PlaceBetState === 'waiting_approval'
                    ? 'ticket_waiting_aproval'
                    : 'errors_ticket_ok'
                )
              );
              this.hideTicket();
              setTimeout(() => {
                this._store.dispatch(authActions.balanceRefreshStart());
              }, 1000);
              this.clearTicket();
              this._store.dispatch(tickethistoryActions.loadLastTickets());
            } else {
            }
          });
        this._store.dispatch(ticketActions.placeBet());
      });
  }

  /** Schova tiketove menu */
  hideTicket(): void {
    this._menu.close('ticketMenu');
  }

  /**
   * scroll na vrch tiketu - pri hlaske o vypadnutych oddoch alebo pri zmene kurzu
   */
  scrollToTopTicket(): void {
    this._contentElem?.nativeElement.scrollTo(0, 0);
  }

  /**
   *  show tooltip
   */
  showSrtTooltyp(): void {
    this._presentMessageService.presentCustomMessage(
      `<p>${
        (this._translateSpecial.transform('srt_hint_p3') as any)
          .changingThisBreaksApplicationSecurity
      }</p>
       <p>${
         (this._translateSpecial.transform('srt_hint_p2') as any)
           .changingThisBreaksApplicationSecurity
       }</p>
       <p>${
         (this._translateSpecial.transform('srt_hint_p1') as any)
           .changingThisBreaksApplicationSecurity
       }</p>
      `,
      'info-alert',
      this._translateService.instant('srt_hint_header'),
      ['OK']
    );
  }

  /** open verification page */
  openVerification(): void {
    this.hideTicket();
    this._router.navigate([translateRoute('VerificationsList')]);
  }
  /** cancel editation and show ticket */
  cancelEditation(): void {
    const ticketCode = this.ticket.PlaceBetTicketCode;
    this._actions$
      .pipe(ofType(ticketActions.cancelEditationComplete), first())
      .subscribe(({ ticket }) => {
        if (!ticket.IsEdited && ticket.Errors.length === 0) {
          this.hideTicket();
          this._router.navigate([
            translateRoute('BetslipHistoryDetail', {
              ticketID: ticketCode
            })
          ]);
        }
      });
    this._store.dispatch(ticketActions.cancelEditation());
  }

  /** open today prematch */
  openPrematch(): void {
    if (this.ticket.TicketFlag.startsWith('TC')) {
      this._router.navigate([translateRoute('PrematchList')], {
        queryParams: { date: 'TD' }
      });
    } else {
      this._router.navigate([translateRoute('VirtualGames')]);
    }
    this.hideTicket();
  }
}
