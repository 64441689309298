<div class="progress-wrapper">
  <div class="progress">
    <svg
      *ngIf="!isDismissable"
      class="circle"
      viewBox="0 0 140 140"
      preserveAspectRatio="xMinYMin meet"
    >
      <circle r="70" cx="50%" cy="50%"></circle>
    </svg>
    <i class="icon icon-ic_alert1"></i>
  </div>
</div>
<h2 class="content-title">{{ 'responsible_gaming_title' | translate }}</h2>
<div class="content">
  <p>{{ 'responsible_gaming_warning_1' | translate }}</p>
  <p class="special-label" [innerHTML]="getSpecialLabel()"></p>

  <div class="bets-info-container-wrapper" *ngIf="!error">
    <div class="bets-info-container">
      <div class="bets-info-card">
        <h3 class="bets-info-card-title">
          {{ 'responsible_gaming_range_month' | translate }}
        </h3>
        <div class="bets-info">
          <div class="info" *ifAvailable="'responsibleGaming.bettingAllowed'">
            <span class="label"
              >{{ 'responsible_gaming_bets_sport' | translate }}:</span
            >
            <ng-container
              *ngTemplateOutlet="
                amount;
                context: { $implicit: casinoWnsAndBet?.OnlineActualMonthBet }
              "
            ></ng-container>
          </div>
          <div class="info" *ifAvailable="'responsibleGaming.bettingAllowed'">
            <span class="label"
              >{{ 'responsible_gaming_wins_sport' | translate }}:</span
            >
            <ng-container
              *ngTemplateOutlet="
                amount;
                context: { $implicit: casinoWnsAndBet?.OnlineActualMonthWin }
              "
            ></ng-container>
          </div>

          <div
            class="info"
            *ifAvailable="['casino', 'responsibleGaming.casinoAllowed']"
          >
            <span class="label"
              >{{ 'responsible_gaming_bets_casino' | translate }}:</span
            >
            <ng-container
              *ngTemplateOutlet="
                amount;
                context: { $implicit: casinoWnsAndBet?.CasinoActualMonthBet }
              "
            ></ng-container>
          </div>
          <div
            class="info"
            *ifAvailable="['casino', 'responsibleGaming.casinoAllowed']"
          >
            <span class="label"
              >{{ 'responsible_gaming_wins_casino' | translate }}:</span
            >
            <ng-container
              *ngTemplateOutlet="
                amount;
                context: { $implicit: casinoWnsAndBet?.CasinoActualMonthWin }
              "
            ></ng-container>
          </div>
        </div>
      </div>

      <div class="bets-info-card">
        <h3 class="bets-info-card-title">
          {{ 'responsible_gaming_range_total' | translate }}
        </h3>
        <div class="bets-info">
          <div class="info" *ifAvailable="'responsibleGaming.bettingAllowed'">
            <span class="label"
              >{{ 'responsible_gaming_bets_sport' | translate }}:</span
            >
            <ng-container
              *ngTemplateOutlet="
                amount;
                context: { $implicit: casinoWnsAndBet?.OnlineTotalBet }
              "
            ></ng-container>
          </div>
          <div class="info" *ifAvailable="'responsibleGaming.bettingAllowed'">
            <span class="label"
              >{{ 'responsible_gaming_wins_sport' | translate }}:</span
            >
            <ng-container
              *ngTemplateOutlet="
                amount;
                context: { $implicit: casinoWnsAndBet?.OnlineTotalWin }
              "
            ></ng-container>
          </div>
          <div
            class="info"
            *ifAvailable="['casino', 'responsibleGaming.casinoAllowed']"
          >
            <span class="label"
              >{{ 'responsible_gaming_bets_casino' | translate }}:</span
            >
            <ng-container
              *ngTemplateOutlet="
                amount;
                context: { $implicit: casinoWnsAndBet?.CasinoTotalBet }
              "
            ></ng-container>
          </div>
          <div
            class="info"
            *ifAvailable="['casino', 'responsibleGaming.casinoAllowed']"
          >
            <span class="label"
              >{{ 'responsible_gaming_wins_casino' | translate }}:</span
            >
            <ng-container
              *ngTemplateOutlet="
                amount;
                context: { $implicit: casinoWnsAndBet?.CasinoTotalWin }
              "
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div
      [ngSwitch]="
        [
          'casino',
          'responsibleGaming.casinoAllowed',
          'responsibleGaming.bettingAllowed'
        ] | available
      "
    >
      <ng-container *ngSwitchCase="true">
        <span class="sefl-limit-label">{{
          'responsible_gaming_set_self_limit_btn' | translate
        }}</span>
        <div class="buttons">
          <ion-button
            mode="md"
            class="set-responsible-gaming-btn"
            expand="block"
            [routerLink]="'ResponsibleGamingCasino' | translateRoute"
            (click)="dismiss()"
          >
            {{ 'menu_casino' | translate }}
          </ion-button>

          <ion-button
            mode="md"
            class="set-responsible-gaming-btn"
            expand="block"
            [routerLink]="'ResponsibleGamingBetting' | translateRoute"
            (click)="dismiss()"
          >
            {{ 'menu_betting' | translate }}
          </ion-button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <br />
        <div class="buttons">
          <ion-button
            mode="md"
            class="set-responsible-gaming-btn"
            expand="block"
            [routerLink]="
              (('responsibleGaming.bettingAllowed' | available)
                ? 'ResponsibleGamingBetting'
                : 'ResponsibleGamingCasino'
              ) | translateRoute
            "
            (click)="dismiss()"
          >
            {{ 'responsible_gaming_set_self_limit_btn' | translate }}
          </ion-button>
        </div>
      </ng-container>
    </div>
  </div>
  <p>
    {{ 'responsible_gaming_help_0' | translate }}
  </p>
  <p
    class="helps"
    [innerHTML]="'responsible_gaming_modal_helps' | translateSpecial"
  ></p>
</div>

<ng-template #amount let-amount>
  <span class="value" *ngIf="amount | isNumber; else amountLoader">{{
    (amount > 0 ? amount : 0) | formatNumberWithCurrency
  }}</span>

  <ng-template #amountLoader>
    <ion-skeleton-text animated></ion-skeleton-text>
  </ng-template>
</ng-template>
