import { DoxxDocument } from '@models/Documents';
import { createAction, props } from '@ngrx/store';

export const loadDocumentsOnce = createAction('[Document] Load Documents Once');

export const loadDocuments = createAction('[Document] Load Documents');

export const loadDocumentsSuccess = createAction(
  '[Document] Load Documents Success',
  props<{ data: DoxxDocument[] }>()
);

export const loadDocumentsFailure = createAction(
  '[Document] Load Documents Failure',
  props<{ error: any }>()
);
