<div #draggable>
  <div
    class="h-15 w-full flex items-center relative justify-center bg-primary cursor-pointer"
    (mousedown)="swipeStart($event)"
    (touchstart)="swipeStart($event)"
    (touchmove)="swipe($event.touches[0].pageY)"
    (mouseup)="swipeEnd($event)"
    (touchend)="swipeEnd($event)"
  >
    <div
      class="h-[3px] w-10 rounded-full bg-primary-300 absolute top-2 left-1/2 transform -translate-x-1/2"
    ></div>
    <span class="text-white text-lg font-bold">{{
      'account_notifications_notifications' | translate
    }}</span>
    <button
      class="p-3 absolute right-4"
      [routerLink]="'Settings_Notifications' | translateRoute"
    >
      <i class="icon-ic_settings-stroke text-2xl text-primary-300"></i>
    </button>
  </div>
  <div
    class="flex flex-col relative h-full bg-white"
    [ngClass]="{ 'pb-32 overflow-scroll': swiped }"
    infiniteScroll
    [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="50"
    [infiniteScrollDisabled]="
      (status$ | async) === 'loading' || (complete$ | async)
    "
    [immediateCheck]="true"
    [scrollWindow]="false"
    (scrolled)="loadNext()"
  >
    <ng-container *ngIf="emptyNotifications">
      <div
        class="h-[231px] bg-primary flex w-full justify-center items-center border-t border-primary-400"
      >
        <div class="flex flex-col items-center text-primary-300">
          <i class="icon-ic_bell-crossed-fill"></i>
          <span class="text-base mt-9">{{
            'notifications_did_not_any_notifications' | translate
          }}</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!emptyNotifications">
      <div
        class="flex items-center border-t first:border-t-0"
        *ngFor="
          let notification of notifications;
          let i = index;
          trackBy: 'guid' | trackByProperty
        "
        [style.background]="notification.data.backgroundColor"
        [ngClass]="{ 'absolute top-full': i > 2 && !swiping && !swiped }"
        (click)="notificationClick(notification)"
      >
        <img
          [src]="
            ('staticUrl' | env) +
            '/doxxbetPortal/assets/images/notifications/' +
            notification.data.icon
          "
          [onError]="
            ('staticUrl' | env) +
            '/doxxbetPortal/assets/images/notifications/ic_bell-stroke@3x.png'
          "
          class="w-5 h-5 flex-none m-5"
        />
        <div class="flex flex-col py-4 pr-7 w-full relative">
          <div class="flex justify-between items-center">
            <span
              [style.color]="notification.data.headerTextColor"
              class="text-sm font-bold"
              >{{ notification.data.header }}</span
            >
            <span
              *ngIf="notification.data.value.length > 0"
              [style.background]="notification.data.valueBackgroundColor"
              class="rounded-sm px-1 font-bold text-white"
              >{{ notification.data.value }}</span
            >
          </div>
          <span class="text-sm text-primary-300 mt-1 pt-px">{{
            notification.data.pushDescription
          }}</span>
          <div
            *ngIf="!notification.viewed"
            class="absolute w-[6px] h-[6px] rounded top-2 right-2 bg-red-500"
          ></div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showFirtsThreeLoading">
      <ng-container
        *ngFor="let _ of [].emptyEntries(3); trackBy: '0' | trackByProperty"
      >
        <ng-container *ngTemplateOutlet="notificationSkeleton"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container
      *ngIf="(status$ | async) === 'loading' && (swiped || swiping)"
    >
      <ng-container
        *ngFor="let _ of [].emptyEntries(10); trackBy: '0' | trackByProperty"
      >
        <ng-container *ngTemplateOutlet="notificationSkeleton"></ng-container>
      </ng-container>
    </ng-container>
    <div
      *ngIf="!swiped"
      class="h-screen absolute bg-white w-full left-0 top-full"
    ></div>
  </div>
</div>

<ng-template #notificationSkeleton>
  <div
    class="bg-white flex items-center w-full justify-start pr-6 py-4 border-t"
  >
    <div class="w-15 flex-none">
      <div class="bg-gray-200 w-6 h-6 rounded-md mx-auto animate-pulse"></div>
    </div>
    <div class="flex flex-col space-y-2 h-full grow">
      <div class="flex justify-between items-center">
        <div class="h-4 w-24 bg-gray-200 animate-pulse rounded"></div>
        <div class="h-5 w-14 bg-gray-200 animate-pulse rounded"></div>
      </div>
      <div class="flex flex-col space-y-1 grow">
        <div class="h-4 w-11/12 bg-gray-200 animate-pulse rounded"></div>
      </div>
    </div>
  </div>
</ng-template>
