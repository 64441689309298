import { Component, Input } from '@angular/core';
import { PopoverController, ViewWillEnter } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@core/functions/formatDate';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

export interface IFilterOptions {
  selectedOption: string;
  availableOptions: Array<{ label: string; value: string }>;
  filterDate: {
    from: Date;
    to: Date;
    minDate?: Date;
  };
  resetToDefault?: boolean;
}

@Component({
  selector: 'doxx-filtermodal',
  templateUrl: './filtermodal.component.html',
  styleUrls: ['./filtermodal.component.scss']
})
export class FiltermodalComponent implements ViewWillEnter {
  @Input() filterData: IFilterOptions;

  maximalDateFrom: string;
  maximalDateTo: string;
  minimalDateTo: string;

  constructor(
    public popoverController: PopoverController,
    private _translateService: TranslateService
  ) {}

  /**
   * on date from changed handler
   */
  dateFromChange(event: MatDatepickerInputEvent<Date>) {
    this.filterData.filterDate.from = new Date(
      event.value.toLocaleISODateString()
    );
    this.checkMinimalMaximalDateValues();
  }

  /**
   * on date to changed handler
   */
  dateToChange(event: MatDatepickerInputEvent<Date>) {
    this.filterData.filterDate.to = new Date(
      event.value.toLocaleISODateString()
    );
    this.checkMinimalMaximalDateValues();
  }

  /**
   * Set options for date picker
   */
  ionViewWillEnter(): void {
    this.checkMinimalMaximalDateValues();
  }

  /**
   * Set minimal, maximal values for datepicker
   */
  checkMinimalMaximalDateValues(): void {
    this.maximalDateFrom = new Date(
      this.filterData.filterDate.to
    ).toLocaleISODateString();
    this.maximalDateTo = new Date().addDays(1).toLocaleISODateString();
    this.minimalDateTo = new Date(
      this.filterData.filterDate.from
    ).toLocaleISODateString();
  }

  /**
   * Close modal with/without reset
   */
  async closeModal(confirm?: boolean): Promise<void> {
    if (confirm) {
      this.filterData.resetToDefault = false;
    } else {
      this.filterData.resetToDefault = true;
    }
    this.filterData.filterDate.from = new Date(this.filterData.filterDate.from);
    this.filterData.filterDate.to = new Date(this.filterData.filterDate.to);
    await this.popoverController.dismiss(this.filterData);
  }

  /**
   * Ticket type selected
   */
  onTicketTypeButtonSelected(event: any): void {
    if (this.filterData.selectedOption !== event.detail.value) {
      // Pokial sa zmenila hodnota selectu pre ticketStatus - 'all, last'... uloz novu hodnotu
      this.filterData.selectedOption = event.detail.value;
    }
  }

  dayLabel = (date: string | Date): string => {
    const d = new Date(date);
    if (d.isValid() && d.isToday()) {
      return this._translateService.instant('bets_subtitle_today');
    } else if (d.isValid()) {
      return formatDate(d, 'YYYYMMDD');
    } else {
      return date as string;
    }
  };
}
