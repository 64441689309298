import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApprovedEvent, User, UserBalanceResponse } from '@models/User';
import { environment } from '@environment';
import { catchError, map, tap } from 'rxjs/operators';
import { of, throwError, Observable } from 'rxjs';
import { ResponsibleGamingModalService } from '@shared/responsible-gaming-modal/responsible-gaming-modal.service';
import { Actions, ofType } from '@ngrx/effects';
import { authActions } from '@store/actions';
import { oneLine } from '@core/functions/oneLine';
import { PresentMessageService } from 'src/app/services/present-message.service';
import { TranslateService } from '@ngx-translate/core';
import { translateRoute } from '@core/functions/translateRoute';
import { Router } from '@angular/router';
import { configuration } from '@configuration';
import { FavouriteChanceType } from '@models/FavouriteChanceType';
import { FavouritesResponse } from '@models/FavouritesResponse';
import { DEVICE_CODE } from '@core/core.module';
export const LOGGED_USER_ORDER_AFTER_DAYS_LEFT = 14;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private _httpClient: HttpClient,
    private _responsibleGamingModalService: ResponsibleGamingModalService,
    private _actions$: Actions,
    private _translateService: TranslateService,
    private _router: Router,
    private _presentMessageService: PresentMessageService,
    @Inject(DEVICE_CODE) private _deviceCode: { getCode(): string }
  ) {
    // SHOW RESPONSIBLE GAMING MODAL AFTER LOGIN
    this._actions$
      .pipe(
        ofType(authActions.loginSuccess),
        tap(action => {
          this._responsibleGamingModalService.showResponsibleGamingModal();
        })
      )
      .subscribe();
  }

  /**
   * Makes login api request on client service
   * @param email user email
   * @param password user password
   */
  public login(email: string, password: string): Observable<User> {
    return this._httpClient
      .post<any>(
        oneLine`${environment.apiUrl}/responsive/web/bp/client/login`,
        {
          email,
          password,
          deviceId: this._deviceCode.getCode()
        },
        {
          withCredentials: true
        }
      )
      .pipe(
        catchError(err => {
          // response can have error code 409, but also contains valid data
          // we need to catch response as a valid response
          if (err.status === 409) {
            return of(err.error);
          }
          return throwError(err);
        }),
        map(response => {
          if (response.data.action.status === 'nok') {
            throw response;
          } else {
            if (
              configuration.verification.twoId &&
              !response.data.client.secondaryVerified && environment.location !== 'nga'
            ) {
              const message = this._translateService.instant(
                'identity_verification_error'
              );
              this._presentMessageService.presentCustomMessage(
                message,
                'info-alert-stamp',
                // 'Overenie totožnosti!',
                this._translateService.instant(
                  'identity_verification_general_msg_12'
                ),
                // ['OK', 'nok']
                [
                  this._translateService.instant(
                    'identity_verification_cancel'
                  ),
                  {
                    text: this._translateService.instant(
                      'identity_verification_confirm'
                    ),
                    handler: () => {
                      this._router.navigate([
                        translateRoute('VerificationsList')
                      ]);
                    }
                  }
                ]
              );
            }
            return response.data.client;
          }
        })
      );
  }

  /**
   * Makes autologin api request on client service
   */
  public getInfo(): Observable<any> {
    return this._httpClient
      .get<any>(oneLine`${environment.apiUrl}/responsive/web/bp/client/info`, {
        withCredentials: true
      })
      .pipe(map(response => response.data.client));
  }

  /**
   * Makes balance api request should be called periodically
   */
  public getBalance(): Observable<UserBalanceResponse> {
    return this._httpClient.get<UserBalanceResponse>(
      oneLine`${environment.apiUrl}/responsive/web/bp/client/balance`,
      {
        withCredentials: true
      }
    );
  }
  /**
   * Fetch client events
   * used in client/tickets/approved/events
   */
  public fetchClientEvents(): Observable<ApprovedEvent[]> {
    return this._httpClient
      .get<any>(
        //oneLine`${environment.apiUrl}/web/client/tickets/approved/events`,
        oneLine`${environment.apiUrl}/web/client/approved/tickets/events/list`,

        //oneLine`${environment.apiUrl}/centralticket/playertickets/approved`,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
          }),
          withCredentials: true
        }
      )
      .pipe(map(response => response));
  }

  //zatial nepouzivat
  // /**
  //  * Activate client events - for SignalR changes
  //  * used in /centralticket/playertickets/approved
  //  */
  // public activateClientEvents(): Observable<any> {
  //   console.log('activate events');
  //   return this._httpClient
  //     .get<any>(
  //       //oneLine`${environment.apiUrl}/web/client/tickets/approved/events`,
  //       // oneLine`${environment.apiUrl}/web/client/approved/tickets/events/list`,

  //       oneLine`${environment.apiUrl}/centralticket/playertickets/approved`,
  //       {
  //         headers: new HttpHeaders({
  //           'Content-Type': 'application/x-www-form-urlencoded'
  //         }),
  //         withCredentials: true
  //       }
  //     )
  //     .pipe(map(response => response));
  // }

  /**
   * Get buyback value
   * used in client/tickets/approved/events
   */
  public getBuybackValue(ticketCode): Observable<any> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/web/client/ticketcode/` +
          ticketCode +
          `/buybackvalueext`,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
          }),
          withCredentials: true
        }
      )
      .pipe(map(response => response));
  }

  /**
   * Makes logour api request on client service
   */
  public logout(): Observable<any> {
    return this._httpClient
      .post<any>(
        oneLine`${environment.apiUrl}/responsive/web/bp/client/logout`,
        {
          deviceId: this._deviceCode.getCode()
        },
        {
          withCredentials: true
        }
      )
      .pipe(map(response => response.data.action));
  }

  // https://m-api-staging.doxxbet.sk/web/bp/client/password/forgot

  /**
   * restore password
   * @param form form
   */
  public restorePassword(form: {
    email: string;
    registrationCode: string;
  }): Observable<User> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/web/bp/client/password/forgot`,
      form,
      {
        withCredentials: true
      }
    );
  }

  /** get state for vlc */
  getVlcState(): Observable<{ token: string; nextStep: string }> {
    return this._httpClient
      .post<any>(
        oneLine`${environment.apiUrl}/responsive/web/bp/client/vlc/getState`,
        {},
        {
          observe: 'response',
          withCredentials: true
        }
      )
      .pipe(
        map(res => ({
          token: res.headers.get('token'),
          nextStep: res.body.nextStep
        }))
      );
  }

  /**
   * Gets favourite sports, leagues and chancetype for logged-in users
   */
  getFavourites(): Observable<FavouritesResponse> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/responsive/web/bp/client/favourite`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * Adds favourite sport
   */
  addFavSport(sportId: number): Observable<any> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/responsive/web/bp/client/favourite/sport/addition`,
      {
        sportId
      },
      {
        withCredentials: true
      }
    );
  }

  /**
   * Removes favourite sport
   */
  removeFavSport(sportId: number): Observable<any> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/responsive/web/bp/client/favourite/sport/removal`,
      {
        sportId
      },
      {
        withCredentials: true
      }
    );
  }

  /**
   * Adds favourite league
   */
  addFavLeague(leagueCupId: number): Observable<any> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/responsive/web/bp/client/favourite/league/addition`,
      {
        leagueCupId
      },
      {
        withCredentials: true
      }
    );
  }

  /**
   * Removes favourite league
   */
  removeFavLeague(leagueCupId: number): Observable<any> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/responsive/web/bp/client/favourite/league/removal`,
      {
        leagueCupId
      },
      {
        withCredentials: true
      }
    );
  }
  /**
   * Adds favourite chanceType
   */
  addFavChanceType(chanceType: FavouriteChanceType): Observable<any> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/responsive/web/bp/client/favourite/chance/addition`,
      chanceType,
      {
        withCredentials: true
      }
    );
  }

  /**
   * Removes favourite chanceType
   */
  removeFavChanceType(chanceType: FavouriteChanceType): Observable<any> {
    return this._httpClient.post<any>(
      oneLine`${environment.apiUrl}/responsive/web/bp/client/favourite/chance/removal`,
      chanceType,
      {
        withCredentials: true
      }
    );
  }
}
