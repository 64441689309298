import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'values'
})
export class ValuesPipe implements PipeTransform {
  /**
   * Turn dictionary to array from its values
   */
  transform(dictionary: Record<any, any>): any[] {
    if (dictionary) {
      return Object.values(dictionary);
    }
  }
}
