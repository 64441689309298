<ion-header @searchbar mode="md" class="searchbar">
  <!-- Default Searchbar -->
  <ion-searchbar
    (ionClear)="clear()"
    mode="ios"
    placeholder="{{ 'search_placeholder' | translate }}"
  ></ion-searchbar>
</ion-header>
<div @backdrop class="content">
  <ion-backdrop
    (click)="closeSearchBar()"
    [tappable]="true"
    [stopPropagation]="false"
    [visible]="true"
  ></ion-backdrop>

  <!-- BETTING RESULTS -->
  <ion-card
    *ngIf="
      searchRresults.sportsBetting &&
      (searchRresults.sportsBetting | values).length > 0 &&
      ('betting' | isBlocked | async) === false &&
      display.betting
    "
    class="search-results-card"
  >
    <ion-card-header>
      <span class="header-icon-wrapper">
        <i class="icon icon-ic_betslip"></i>
      </span>
      <h6 class="title">
        {{ 'search_results_sportbetting_title' | translate }}
      </h6>
      <span
        *ngIf="display.showButton === 'more'"
        (click)="showMore('betting')"
        class="show-more"
        >{{ 'search_show_more' | translate }}</span
      >
      <span
        *ngIf="display.showButton === 'less'"
        (click)="showMore('all')"
        class="show-more"
        >{{ 'search_show_less' | translate }}</span
      >
    </ion-card-header>
    <div
      class="game-list"
      *ngFor="let sport of searchRresults.sportsBetting | values"
    >
      <div *ngFor="let region of sport.regions | values">
        <div *ngIf="!region.leagues">
          <div class="game-item game-category">
            <span
              [routerLink]="
                'PrematchList'
                  | translateRoute
                    : {
                        sportID: [sport.ID, sport.Name | slugify]
                      }
              "
              class="path"
              [style.background]="
                'var(--xx-sport-color-' + sport.ID + ', black)'
              "
              >{{ sport.Name }}</span
            >
            <span
              [routerLink]="
                'PrematchList'
                  | translateRoute
                    : {
                        sportID: [sport.ID, sport.Name | slugify],
                        regionID: [region.ID, region.Name | slugify]
                      }
              "
              class="path"
              [style.background]="
                'var(--xx-sport-color-' + sport.ID + ', black)'
              "
              >{{ region.Name }}</span
            >
          </div>
          <div class="game-item" *ngIf="!region.leagues">
            <div class="game-icon betting">
              <i
                class="icon sport icon sport-icon-{{ sport.ID }}"
                [style.color]="'var(--xx-sport-color-' + sport.ID + ', black)'"
                [title]="sport.Name"
              ></i>
            </div>
            <span
              [routerLink]="
                'PrematchList'
                  | translateRoute
                    : {
                        sportID: [sport.ID, sport.Name | slugify],
                        regionID: [region.ID, region.Name | slugify]
                      }
              "
              class="game-name betting"
              [innerHTML]="
                '<b>' + region.Name + '</b> - ' + sport.Name
                  | highlight: searchPhrase
              "
            >
            </span>
          </div>
        </div>

        <div *ngFor="let league of region.leagues | values">
          <div class="game-item game-category">
            <span
              [routerLink]="
                'PrematchList'
                  | translateRoute
                    : {
                        sportID: [sport.ID, sport.Name | slugify]
                      }
              "
              class="path"
              [style.background]="
                'var(--xx-sport-color-' + sport.ID + ', black)'
              "
              >{{ sport.Name }}</span
            >
            <span
              [routerLink]="
                'PrematchList'
                  | translateRoute
                    : {
                        sportID: [sport.ID, sport.Name | slugify],
                        regionID: [region.ID, region.Name | slugify]
                      }
              "
              class="path"
              [style.background]="
                'var(--xx-sport-color-' + sport.ID + ', black)'
              "
              >{{ region.Name }}</span
            >
            <span
              [routerLink]="
                'PrematchList'
                  | translateRoute
                    : {
                        sportID: [sport.ID, sport.Name | slugify],
                        regionID: [region.ID, region.Name | slugify],
                        leagueID: [league.ID, league.Name | slugify]
                      }
              "
              class="path"
              [style.background]="
                'var(--xx-sport-color-' + sport.ID + ', black)'
              "
              >{{ league.Name }}</span
            >
          </div>
          <div class="game-item" *ngIf="!league.events">
            <div class="game-icon betting">
              <i
                class="icon sport icon sport-icon-{{ sport.ID }}"
                [style.color]="'var(--xx-sport-color-' + sport.ID + ', black)'"
                [title]="sport.Name"
              ></i>
            </div>
            <span
              [routerLink]="
                'PrematchList'
                  | translateRoute
                    : {
                        sportID: [sport.ID, sport.Name | slugify],
                        regionID: [region.ID, region.Name | slugify],
                        leagueID: [league.ID, league.Name | slugify]
                      }
              "
              class="game-name betting"
            >
              <b [innerHTML]="league.Name | highlight: searchPhrase"></b>
            </span>
          </div>
          <div class="game-item" *ngFor="let event of league.events | values">
            <div class="game-icon betting">
              <b *ngIf="event.LiveBetting !== 'Y'">{{
                event.EventDate | formatDate: 'MMDD'
              }}</b>
              <span *ngIf="event.LiveBetting !== 'Y'" class="time-hours">
                {{ event.EventDate | formatDate: 'hhmm' }}</span
              >
              <i
                *ngIf="event.LiveBetting === 'Y'"
                class="icon icon-ic_live"
              ></i>
            </div>
            <span
              [routerLink]="
                (event.LiveBetting === 'Y' ? 'EventDetail_Live' : 'EventDetail')
                  | translateRoute
                    : {
                        sportID: [sport.ID, sport.Name | slugify],
                        regionID: [region.ID, region.Name | slugify],
                        leagueID: [league.ID, league.Name | slugify],
                        eventID: event.EventID,
                        eventName: event.Name | slugify
                      }
              "
              class="game-name betting"
              [innerHTML]="event.Name | highlight: searchPhrase"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </ion-card>
  <!-- END BETTING RESULTS -->

  <!-- CASINO RESULTS -->
  <ion-card
    *ngIf="
      searchRresults.casino &&
      searchRresults.casino.length > 0 &&
      ('casino' | isBlocked | async) === false &&
      display.casino
    "
    class="search-results-card"
  >
    <ion-card-header>
      <span class="header-icon-wrapper">
        <i class="icon icon-ic_casino"></i>
      </span>
      <h6 class="title">{{ 'search_results_casino_title' | translate }}</h6>
      <span
        *ngIf="display.showButton === 'more'"
        (click)="showMore('casino')"
        class="show-more"
        >{{ 'search_show_more' | translate }}</span
      >
      <span
        *ngIf="display.showButton === 'less'"
        (click)="showMore('all')"
        class="show-more"
        >{{ 'search_show_less' | translate }}</span
      >
    </ion-card-header>
    <ng-container
      *ngTemplateOutlet="
        casinoAndRioGamesResults;
        context: {
          $implicit: searchRresults.casino,
          casinoType: 'kasino-online'
        }
      "
    ></ng-container>
  </ion-card>
  <!-- END CASINO RESULTS -->

  <!-- RIO RESULTS -->
  <ion-card
    *ngIf="
      searchRresults.rio &&
      searchRresults.rio.length > 0 &&
      ('casino' | isBlocked | async) === false &&
      display.rio
    "
    class="search-results-card"
  >
    <ion-card-header>
      <span class="header-icon-wrapper">
        <i class="icon icon-ic_casino"></i>
      </span>
      <h6 class="title">RIO</h6>
      <span
        *ngIf="display.showButton === 'more'"
        (click)="showMore('rio')"
        class="show-more"
        >{{ 'search_show_more' | translate }}</span
      >
      <span
        *ngIf="display.showButton === 'less'"
        (click)="showMore('all')"
        class="show-more"
        >{{ 'search_show_less' | translate }}</span
      >
    </ion-card-header>
    <ng-container
      *ngTemplateOutlet="
        casinoAndRioGamesResults;
        context: { $implicit: searchRresults.rio, casinoType: 'rio-online' }
      "
    ></ng-container>
  </ion-card>
  <!-- END RIO RESULTS -->

  <!-- BONUSES -->
  <ion-card
    *ngIf="
      searchRresults.bonuses &&
      (searchRresults.bonuses | values).length > 0 &&
      display.bonuses
    "
    class="search-results-card"
  >
    <ion-card-header>
      <ng-container
        [ngSwitch]="
          {
            and: [
              'news',
              { or: ['bonuses.allowedCasino', 'bonuses.allowedBetting'] }
            ]
          } | available
        "
      >
        <h6 class="title" *ngSwitchCase="true">
          {{ 'search_results_bonuses_title' | translate }}
        </h6>
        <ng-container *ngSwitchCase="false">
          <h6
            class="title"
            *ifAvailable="{
              or: ['bonuses.allowedCasino', 'bonuses.allowedBetting']
            }"
          >
            {{ 'app_bonus_title' | translate }}
          </h6>
          <h6 class="title" *ifAvailable="'news'">
            {{ 'actualities_title' | translate }}
          </h6>
        </ng-container>
      </ng-container>
      <span
        *ngIf="display.showButton === 'more'"
        (click)="showMore('bonuses')"
        class="show-more"
        >{{ 'search_show_more' | translate }}</span
      >
      <span
        *ngIf="display.showButton === 'less'"
        (click)="showMore('all')"
        class="show-more"
        >{{ 'search_show_less' | translate }}</span
      >
    </ion-card-header>
    <div
      class="game-list"
      *ngFor="let bonus of searchRresults.bonuses | values"
    >
      <div class="game-item">
        <div class="game-icon">
          <i
            class="icon icon {{
              bonus.ReslutType === 'bonus'
                ? 'icon-ic_bonuses'
                : 'icon-ic_news-bold'
            }} "
            [style.color]="'var(--ion-color-secondary)'"
            [title]="bonus.ReslutHeader"
          ></i>
        </div>
        <span
          [routerLink]="
            (bonus.Category === 'sportbook'
              ? 'BonusDetail'
              : 'BonusDetailCasino'
            ) | translateRoute: { bonusID: bonus.ResultName }
          "
          class="game-name bonuses"
        >
          <ion-grid>
            <ion-row>
              <ion-col>
                <b>{{ bonus.ResultTitle }}</b>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-text>
                  {{ bonus.ReslutHeader }}
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </span>
      </div>
    </div>
  </ion-card>
  <!-- END BONUSES -->
</div>

<ng-template #casinoAndRioGamesResults let-games let-casinoType="casinoType">
  <div class="game-list" *ngFor="let item of games">
    <div class="game-item game-category">
      <span class="games-{{ item.CategoryID }} path">{{
        item.CategoryName
      }}</span>
    </div>
    <div
      class="game-item"
      *ngFor="let game of item.Games"
      [ngClass]="{
        disabled:
          user?.casinoAllowed === false ||
          user?.casinoPolicyApproved === false ||
          (('verification' | available) && user?.verified === false) ||
          (('verification.rvo' | available) && user?.gamblingProhibited)
      }"
    >
      <div (click)="presentCasinoPlayOptions(game.SystemName, casinoType, game.CategoryID)" class="game-icon">
        <img
          src="{{
            'staticUrl' | env
          }}/doxxbetPortal/assets/images/placeholders/casino/games/{{
            game.SystemName
          }}.jpg"
          [onError]="
            ('staticUrl' | env) +
            '/doxxbetPortal/assets/images/placeholders/casino/games/default.jpg'
          "
          alt=""
        />
      </div>
      <div (click)="presentCasinoPlayOptions(game.SystemName, casinoType, game.CategoryID)" class="game-name">
        <span [innerHTML]="game.DisplayName | highlight: searchPhrase"></span>
      </div>
      <div
        (click)="openFunGame(game, casinoType)"
        *ngIf="!user?.login"
        class="play-for-fun"
      >
        <span>{{ 'app_casino_playForFun' | translate }}</span>
      </div>
    </div>
  </div>
</ng-template>
