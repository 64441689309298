import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { favoritesSelectors } from '@store/selectors';
import { Observable } from 'rxjs';

@Pipe({
  name: 'isFavSport'
})
export class IsFavSportPipe implements PipeTransform {
  constructor(private _store: Store) {}
  /**
   * checks if id is in favorites
   */
  transform(id: number): Observable<boolean> {
    return this._store.select(favoritesSelectors.selectIsSportInFav(id));
  }
}
