import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NotificationsService } from './notifications.service';
import { Notification } from '@models/Notifications';
import { Store } from '@ngrx/store';
import { notificationsActions } from '@store/actions';
import { notificationsSelectors } from '@store/selectors';
import { Subscription, fromEvent } from 'rxjs';
import { first, shareReplay } from 'rxjs/operators';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

// height of top toolbar
const OFFSET_TOP = 60;

@Component({
  selector: 'doxx-notifications',
  templateUrl: './notifications.component.html',
  styles: [':host {display: block}']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private _subscription$: Subscription;

  private _minOffset;
  private _originalCursorPositionY;
  private _touchOffset = 0;
  private _pointerMove$;
  private _moreLoaded = false;
  swiped = false;
  swiping = false;
  notifications: Notification[];
  showFirtsThreeLoading = false;
  emptyNotifications = false;

  status$ = this._store
    .select(notificationsSelectors.selectNotificationsListStatus)
    .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  complete$ = this._store.select(
    notificationsSelectors.selectNotificationsListComplete
  );

  @ViewChild('draggable', { read: ElementRef })
  private _draggableEl: ElementRef<HTMLElement>;

  constructor(
    private _store: Store,
    private _notificationService: NotificationsService,
    private _bottomSheetRef: MatBottomSheetRef
  ) {}

  /** on init */
  ngOnInit(): void {
    this._subscription$ = new Subscription();
    this._subscription$.add(
      this.status$.subscribe(status => {
        if (status === 'idle') {
          this.showFirtsThreeLoading = true;
        }
        if (status === 'failure' || status === 'success') {
          this.showFirtsThreeLoading = false;
        }
      })
    );

    this._subscription$.add(
      this._store
        .select(notificationsSelectors.selectNotificationsList)
        .subscribe(notifications => {
          this.notifications = notifications;
          if (!this.showFirtsThreeLoading) {
            this.emptyNotifications = this.notifications.length === 0;
          }
        })
    );

    this._subscription$.add(
      this._notificationService.signalRnotification$.subscribe(() => {
        this._store.dispatch(
          notificationsActions.getNotificationsList({ page: 0, pageSize: 1 })
        );
      })
    );

    this._moreLoaded = false;
    this._store
      .select(notificationsSelectors.selectUnreadNotificationsCount)
      .pipe(first())
      .subscribe(count => {
        this._store.dispatch(
          notificationsActions.getFirstNotificationsList({
            count: Math.max(count, 3)
          })
        );
      });
  }

  /** load next data */
  loadNext(): void {
    this._store.dispatch(notificationsActions.getNextNotificationsList());
  }

  /** init variable when swipe started */
  swipeStart(event: MouseEvent | TouchEvent) {
    if (this.showFirtsThreeLoading || this.emptyNotifications) {
      return 0;
    }
    if (!this._moreLoaded) {
      this._store.dispatch(
        notificationsActions.getFirstNotificationsList({
          count: Math.floor(window.innerHeight / 80)
        })
      );
      this._moreLoaded = true;
    }
    const y =
      event instanceof MouseEvent ? event.pageY : event.changedTouches[0].pageY;
    if (!this._minOffset) {
      this._minOffset =
        OFFSET_TOP -
        this._draggableEl.nativeElement.getBoundingClientRect().top;
    }
    this._draggableEl.nativeElement.classList.remove('transition-transform');
    this._originalCursorPositionY = y;
    this._touchOffset =
      this._draggableEl.nativeElement.getBoundingClientRect().top -
      this._draggableEl.nativeElement.parentElement.getBoundingClientRect().top;
    this._draggableEl.nativeElement.parentElement.style.maxHeight =
      this._draggableEl.nativeElement.parentElement.offsetHeight + 'px';

    if (event instanceof MouseEvent) {
      this._pointerMove$ = fromEvent(document, 'mousemove').subscribe(
        (_event: MouseEvent) => this.swipe(_event.pageY)
      );
    }
    this.swiping = true;
  }

  /** transform element to miror pointer move */
  swipe(y: number) {
    const touchOffset = y - this._originalCursorPositionY + this._touchOffset;

    if (touchOffset > 0) {
      this._draggableEl.nativeElement.style.transform = `translateY(${touchOffset}px)`;
    } else if (touchOffset < this._minOffset) {
      this._draggableEl.nativeElement.style.transform = `translateY(${this._minOffset}px)`;
    } else {
      this._draggableEl.nativeElement.style.transform = `translateY(${touchOffset}px)`;
    }
  }

  /** pin element to top or bottom */
  swipeEnd(event: MouseEvent | TouchEvent) {
    const translateX = +this._draggableEl.nativeElement.style.transform.replace(
      /[^\d.!-]/g,
      ''
    );

    if (this.showFirtsThreeLoading || this.emptyNotifications) {
      return;
    }
    const y =
      event instanceof MouseEvent ? event.pageY : event.changedTouches[0].pageY;
    let swiped;

    this._draggableEl.nativeElement.classList.add('transition-transform');

    if (y < (window.innerHeight - OFFSET_TOP) / 2) {
      this._draggableEl.nativeElement.style.transform = `translateY(${this._minOffset}px)`;
      this._draggableEl.nativeElement.style.height = `${
        window.outerHeight - OFFSET_TOP
      }px`;
      swiped = true;
    } else if (translateX > 100) {
      this._bottomSheetRef.dismiss();
    } else {
      this._draggableEl.nativeElement.style.transform = `translateY(${0}px)`;
      swiped = false;
    }

    this.swiping = false;
    this.swiped = swiped;
    this._pointerMove$?.unsubscribe();
  }

  /** on notification click */
  notificationClick(notification: Notification) {
    this._store.dispatch(
      notificationsActions.markNotificationAsRead({
        notificationID: notification.guid
      })
    );
    this._notificationService.openNotification(
      {
        url: notification.data.actionMobile
      },
      { target: '_blank' }
    );
  }

  /** clear all subscriptions */
  ngOnDestroy(): void {
    this._subscription$?.unsubscribe();
  }
}
