import { createReducer, on } from '@ngrx/store';
import { landingActions } from '@store/actions';
import { Announcement } from '@models/Landing';

export type State = {
  announcement: Announcement;
};

const initialAnnouncementState: State = {
  announcement: null
};

export const announcementReducer = createReducer(
  initialAnnouncementState,
  on(landingActions.loadAnnouncementSuccess, (state, action) => ({
    ...state,
    announcement: action.announcement
  }))
);
