/** parse number to float from specific locale */
export function localeParseFloat(s: string | number, locale: string): number {
  if (typeof s === 'number') {
    return s;
  }

  // Get the thousands and decimal separator characters used in the locale.
  const [, thousandsSeparator, , , , decimalSeparator] =
    (1111.1).toLocaleString(locale);
  // Remove thousand separators, and put a point where the decimal separator occurs
  s = Array.from(s, c =>
    c === thousandsSeparator ? '' : c === decimalSeparator ? '.' : c
  ).join('');

  // Extract number from string
  s = (s.match(/(\d[\d\.]*\d)|(\d)/) || [])[0];

  // Now it can be parsed
  return parseFloat(s);
}
