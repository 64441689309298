import { OfferResponse } from './offerResponse';

/** transforms response from whole menu to unified response */
export function transformWholeMenuResponse(data): OfferResponse {
  const tree: OfferResponse['tree'] = {};
  const sports: OfferResponse['sports'] = {};
  const events: OfferResponse['events'] = {};
  const odds: OfferResponse['odds'] = {};
  const regions: OfferResponse['regions'] = {};
  const phases: OfferResponse['phases'] = {};
  const leagues: OfferResponse['leagues'] = {};
  let stream: OfferResponse['stream'] = {};
  data
    .sort((a, b) => a.Order - b.Order)
    .forEach(label => {
      if (['SP', 'SE'].includes(label.Typ)) {
        sports[label.ID] = label;
      } else if (['ST'].includes(label.Typ)) {
        stream.eventCount = label.EventCount;
      } else if (['RE'].includes(label.Typ)) {
        regions[label.ID] = label;
      } else if (['LC'].includes(label.Typ)) {
        leagues[label.ID] = label;
      } else if (['SX'].includes(label.Typ)) {
        label.ID = 'SX_' + label.ID;
        regions[label.ID] = label;
      } else if (['LX'].includes(label.Typ)) {
        label.ParentID = 'SX_' + label.ParentID;
        leagues[label.ID] = label;
      } else if (label.Typ === 'PH') {
        phases[label.ID] = label;
      }
    });

  Object.keys(leagues).forEach(leagueID => {
    const league = leagues[leagueID];
    const region = regions[league.ParentID];
    const sport = sports[region.ParentID];

    tree[sport.ID] = tree[sport.ID] || {
      key: `SP_${sport.ID}`,
      typ: 'SP',
      sportID: sport.ID,
      order: sport.Order,
      children: {}
    };

    tree[sport.ID].children[region.ID] = tree[sport.ID].children[region.ID] || {
      key: `RE_${region.ID}`,
      typ: 'RE',
      order: league.Order,
      regionID: region.ID,
      children: league.EventCount > 0 ? {} : null
    };

    tree[sport.ID].children[region.ID].children[league.ID] = tree[sport.ID]
      .children[region.ID].children[league.ID] || {
      key: `LC_${league.ID}`,
      typ: 'LC',
      seasonID: league.CurrentSeasonID,
      hasStatistics: league.HasStatistics || league.CurrentSeasonID,
      order: league.Order,
      leagueID: league.ID,
      sportID: sport.ID,
      sportEventID: sport.SportEventID,
      eventCount: league.EventCount,
      countryID: region.CountryID
    };
  });

  // potrebujeme len info o streamoch pre celu offeru
  if (Object.keys(sports).length <= 1) {
    stream = {};
  }
  return {
    sports,
    regions,
    phases,
    leagues,
    events,
    odds,
    tree,
    stream
  };
}
