import { LAST_TICKETS_COUNT } from '@constants';
import {
  TicketEditation,
  TicketHistoryListItem,
  TicketHistroyDetail
} from '@models/TicketHistory';
import { Winner } from '@models/Winners';
import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { tickethistoryActions } from '@store/actions';

export const tickethistoryFeatureKey = 'tickethistory';

export interface State {
  tickets: {
    [ticketCode: TicketHistoryListItem['ticketCode']]: TicketHistroyDetail;
  };
  list: {
    list: TicketHistoryListItem['ticketCode'][];
    loaded: boolean;
    loading: boolean;
    error: any;
    cursor: {
      type: string;
      page: number;
      size: number;
      dateFrom: string; // Date in format MM-DD-YYYY
      dateTo: string; // Date in format MM-DD-YYYY
    };
  };
  detail: {
    ticket: string;
    loading: boolean;
    loaded: boolean;
    error: any;

    // TODO
    isWinnerTicket?: boolean;
    winner?: Winner;
  };
  lastTickets: {
    loading: boolean;
    tickets: TicketHistoryListItem['ticketCode'][];
    error: any;
  };
  editHistory: Record<
    string,
    {
      loading: boolean;
      loaded: boolean;
      error: any;
      edits: Partial<TicketEditation>[];
    }
  >;
}

export const initialState: State = {
  tickets: {},
  list: {
    list: [],
    loaded: false,
    loading: false,
    error: null,
    cursor: null
  },
  detail: {
    ticket: null,
    loaded: false,
    loading: false,
    error: null
  },
  lastTickets: {
    loading: false,
    tickets: [],
    error: null
  },
  editHistory: {}
};

const ticketHistoryListAdapter = createEntityAdapter<TicketHistoryListItem>({
  selectId: ticket => ticket.ticketCode
});

export const reducer = createReducer(
  initialState,
  // Last tickets
  on(tickethistoryActions.loadLastTickets, (state, action) => ({
    ...state,
    lastTickets: {
      ...state.lastTickets,
      loading: true,
      error: undefined
    }
  })),
  on(tickethistoryActions.loadLastTicketsSuccess, (state, action) => ({
    ...state,
    lastTickets: {
      ...state.lastTickets,
      loading: false,
      error: null,
      tickets: action.tickets.map(ticket => ticket.ticketCode)
    },
    tickets: upsertTickets(state.tickets, action.tickets)
  })),
  on(tickethistoryActions.loadLastTicketsFailure, (state, action) => ({
    ...state,
    lastTickets: {
      ...state.lastTickets,
      loading: false,
      error: action.error
    }
  })),

  // LOAD TICKET HISTORY
  on(tickethistoryActions.loadTickethistoryList, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      list: [],
      loading: true,
      loaded: false,
      cursor: {
        type: action.args.type || 'last',
        page: 1,
        size: action.args.size || LAST_TICKETS_COUNT,
        dateFrom:
          action.args.dateFrom || new Date().addMonths(-3).toISODateString(),
        dateTo: action.args.dateTo || new Date().addDays(1).toISODateString()
      }
    },

    listLoading: true,
    ticketHistoryCursor: {
      page: 0,
      dateFrom: action.args.dateFrom,
      dateTo: action.args.dateTo,
      itemsPerPage: action.args.size || 15,
      type: action.args.type || 'last'
    }
  })),
  on(tickethistoryActions.loadTickethistoryListSuccess, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      error: null,
      loaded: true,
      loading: false,
      list: action.tickets.map(ticket => ticket.ticketCode)
    },
    tickets: upsertTickets(state.tickets, action.tickets)
  })),
  on(tickethistoryActions.loadTickethistoryListFailure, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      error: action.error,
      loaded: true,
      loading: false
    },
    error: action.error
  })),

  // LOAD MORE TICKET HISTORY
  on(tickethistoryActions.loadMoreHistoryList, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      cursor: {
        ...state.list.cursor,
        page: state.list.cursor.page + 1
      }
    }
  })),
  on(tickethistoryActions.loadMoreHistoryListSuccess, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      list: [
        ...state.list.list,
        ...action.tickets.map(ticket => ticket.ticketCode)
      ]
    },
    tickets: upsertTickets(state.tickets, action.tickets)
  })),
  on(tickethistoryActions.loadMoreHistoryListFailure, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      loaded: true,
      loading: false,
      error: action.error
    }
  })),

  // REFRESH TICKET HISTORY
  on(tickethistoryActions.refreshTickethistoryListSuccess, (state, action) => ({
    ...state,
    tickets: upsertTickets(state.tickets, action.tickets)
  })),

  // UPDATE TICKETS
  on(tickethistoryActions.updateTickethistoryTicket, (state, action) => ({
    ...state,
    tickets: upsertTickets(
      state.tickets,
      action.tickets.map(change => ({
        ticketCode: change.ticketCode,
        ...change.change
      }))
    )
  })),

  // TICKET DETAIL
  on(tickethistoryActions.loadTicketDetail, (state, action) => ({
    ...state,
    detail: {
      ...state.detail,
      ticket: action.ticketCode,
      loading: true,
      loaded: false
    }
  })),
  on(tickethistoryActions.loadTicketDetailSuccess, (state, action) => ({
    ...state,
    detail: {
      ...state.detail,
      ticket: action.ticket.ticketCode,
      loading: false,
      loaded: true
    },
    tickets: upsertTickets(state.tickets, [action.ticket])
  })),
  on(tickethistoryActions.loadTicketDetailFailure, (state, action) => ({
    ...state,
    detail: {
      ...state.detail,
      error: action.error,
      loaded: true,
      loading: false
    }
  })),
  on(tickethistoryActions.loadEditHistory, (state, action) => ({
    ...state,
    editHistory: {
      ...state.editHistory,
      [action.ticketCode as any]: {
        loaded: false,
        loading: true,
        edits: [
          {
            ticketCode: action.ticketCode
          }
        ],
        error: null
      }
    }
  })),
  on(tickethistoryActions.loadEditHistorySuccess, (state, action) => ({
    ...state,
    editHistory: {
      ...state.editHistory,
      [action.ticketCode as any]: {
        loaded: true,
        loading: false,
        edits: action.editedTickets,
        error: null
      }
    }
  })),
  on(tickethistoryActions.loadEditHistoryFailure, (state, action) => ({
    ...state,
    editHistory: {
      ...state.editHistory,
      [action.ticketCode as any]: {
        loaded: true,
        loading: false,
        edits: [
          {
            ticketCode: action.ticketCode
          }
        ],
        error: action.error
      }
    }
  }))
);

function upsertTickets(stateTickets, actionTickets) {
  return {
    ...stateTickets,
    ...actionTickets.reduce(
      (tickets, ticket) => ({
        ...tickets,
        [ticket.ticketCode]: {
          ...stateTickets[ticket.ticketCode],
          ...ticket
        }
      }),
      {}
    )
  };
}
