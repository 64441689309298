import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'random',
  pure: true
})
export class RandomPipe implements PipeTransform {
  /** random number */
  transform(value: { min?: number; max?: number }): number {
    const min = value.min || 0;
    const max = value.max || 1;
    return Math.random() * (max - min) + min;
  }
}
