import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketeventsComponent } from './ticketevents.component';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { IonicModule } from '@ionic/angular';
import { EventScoreTableModule } from '../event-score-table/event-score-table.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DoxxDirectivesModule } from '@shared/directives/doxx-directives.module';

@NgModule({
  declarations: [TicketeventsComponent],
  imports: [
    CommonModule,
    IonicModule,
    DoxxPipesModule,
    DoxxDirectivesModule,
    EventScoreTableModule,
    MatButtonToggleModule
  ],
  exports: [TicketeventsComponent]
})
export class TicketeventsModule {}
