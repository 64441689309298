import {
  TicketEditation,
  TicketHistoryListItem,
  TicketHistroyDetail
} from '@models/TicketHistory';
import { createAction, props } from '@ngrx/store';

export const loadLastTickets = createAction(
  '[Tickethistory] Load last tickets'
);

export const loadLastTicketsSuccess = createAction(
  '[Tickethistory] Load last tickets success',
  props<{ tickets: TicketHistoryListItem[] }>()
);

export const loadLastTicketsFailure = createAction(
  '[Tickethistory] Load last tickets failure',
  props<{ error: any }>()
);

export const loadTickethistoryList = createAction(
  '[Tickethistory] Load Tickethistory List',
  props<{
    args: {
      type?: string;
      size?: number;
      dateFrom?: string;
      dateTo?: string;
    };
  }>()
);

export const loadTickethistoryListSuccess = createAction(
  '[Tickethistory] Load Tickethistory List Success',
  props<{ ticketCount: number; tickets: TicketHistoryListItem[] }>()
);

export const loadTickethistoryListFailure = createAction(
  '[Tickethistory] Load Tickethistory List Failure',
  props<{ error: any }>()
);

export const loadMoreHistoryList = createAction(
  '[Tickethistory] Load More Tickethistory List'
);

export const loadMoreHistoryListSuccess = createAction(
  '[Tickethistory] Load More Tickethistory List Success',
  props<{
    ticketCount: number;
    tickets: TicketHistoryListItem[];
    cursor: any;
  }>()
);

export const loadMoreHistoryListFailure = createAction(
  '[Tickethistory] Load More Tickethistory List Error',
  props<{ error: any }>()
);

export const refreshTickethistoryList = createAction(
  '[Tickethistory] Refresh Tickethistory List'
);

export const refreshTickethistoryListSuccess = createAction(
  '[Tickethistory] Refresh Tickethistory List Success',
  props<{ ticketCount: number; tickets: TicketHistoryListItem[] }>()
);

export const refreshTickethistoryListFalilure = createAction(
  '[Tickethistory] Refresh Tickethistory List Failure',
  props<{ error: any }>()
);

// ticket detail
export const loadTicketDetail = createAction(
  '[Tickethistory] Load TicketHistory Detail',
  props<{ ticketCode: string }>()
);

export const loadTicketDetailSuccess = createAction(
  '[Tickethistory] Load TicketHistory Detail Success',
  props<{ ticket: Partial<TicketHistoryListItem> }>()
);
export const loadTicketDetailFailure = createAction(
  '[Tickethistory] Load TicketHistory Detail Failure',
  props<{ error: any }>()
);

export const updateTickethistoryTicket = createAction(
  '[Tickethistory] Update Tickethistory Ticket',
  props<{
    tickets: {
      ticketCode: TicketHistoryListItem['ticketCode'];
      change: Partial<TicketHistroyDetail>;
    }[];
  }>()
);

export const loadEditHistory = createAction(
  '[Tickethistory] Load Edit History',
  props<{ ticketCode: TicketHistoryListItem['ticketCode'] }>()
);
export const loadEditHistorySuccess = createAction(
  '[Tickethistory] Load Edit History Success',
  props<{
    ticketCode: TicketHistoryListItem['ticketCode'];
    editedTickets: TicketEditation[];
  }>()
);
export const loadEditHistoryFailure = createAction(
  '[Tickethistory] Load Edit History Failure',
  props<{ ticketCode: TicketHistoryListItem['ticketCode']; error: any }>()
);
