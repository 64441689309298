import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { slugify } from '@core/functions/slugify';
import { translateRoute } from '@core/functions/translateRoute';
import { Actions, ofType } from '@ngrx/effects';
import { GlobalService } from '@shared/global.service';
import { take } from 'rxjs/operators';
import { oneLine } from '@core/functions/oneLine';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { ticketSelectors } from '@store/selectors';
import { ticketActions } from '@store/actions';
@Injectable()
export class EmbededOfferService {
  constructor(
    private _router: Router,
    private _globalService: GlobalService,
    @Inject(DOCUMENT) document: Document,
    private _actions: Actions,
    private _store: Store
  ) {
    const adapterScript = document.createElement('script');
    adapterScript.src = oneLine`${environment.staticUrl}/offer-preview/offer-preview-adapter.js`;
    adapterScript.type = 'application/javascript';
    document.head.appendChild(adapterScript);
    adapterScript.onload = () => {
      window.addEventListener('message', ev => {
        if (ev.data.type === '[Offer] initialized') {
          const iframe = Array.from(
            document.getElementsByTagName('iframe')
          ).filter(_iframe => _iframe.contentWindow === ev.source)[0];

          const adapter = new (window as any).offerPreview.iframeAPI(iframe);

          this._store
            .select(ticketSelectors.selectOdds)
            .pipe(take(1))
            .subscribe(odds => adapter.setOddsOnBetslip(odds));

          adapter.onOddClick = data => {
            this._actions
              .pipe(ofType(ticketActions.addOddsComplete), take(1))
              .subscribe(action => {
                adapter.setOddsOnBetslip(action.ticket.Odds);
              });

            this._store.dispatch(
              ticketActions.addOdds({ odds: [{ OddID: +data.odd.OddsID }] })
            );
          };

          adapter.onEventClick = data => {
            const event = data.event;
            const sectionMap = {
              'bet-builder': { show: 'betBuilder' },
              analysis: { tab: 'analysis' },
              stats: { tab: 'stats' },
              stream: { show: 'stream' },
              'match-tracker': { show: 'matchtracker' },
              'analysis-stats': { show: 'analysis' },
              'prepared-stream': { show: 'score' }
            };
            const section = data.section;
            this._router.navigate(
              [
                translateRoute(
                  event.LiveBetting === 'Y'
                    ? 'EventDetail_Live'
                    : 'EventDetail',
                  {
                    sportID: [event.SportID, slugify(event.SportName)],
                    regionID: [event.RegionID, slugify(event.RegionName)],
                    leagueID: [event.LeagueID, slugify(event.LeagueName)],
                    eventID: event.EventID,
                    eventName: slugify(event.EventName)
                  }
                )
              ],
              { queryParams: sectionMap[section] || {} }
            );
          };
        }
      });
    };
  }
}
