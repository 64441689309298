import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PresentMessageService {
  constructor(
    private _translateService: TranslateService,
    private _alertController: AlertController
  ) {}

  get alertController(): AlertController {
    return this._alertController;
  }

  /**
   * Present custom message
   * @param message string message
   * @param cssClass error-alert || success-alert || info-alert :string
   * @param header optional string
   * @param buttons https://ionicframework.com/docs/api/alert#usage
   */
  public async presentCustomMessage(
    message: string | SafeHtml,
    cssClass:
      | 'error-alert'
      | 'success-alert'
      | 'info-alert'
      | 'info-alert-blue'
      | 'info-alert-stamp'
      | string,
    header?: string,
    buttons?: any,
    inputs?: any
  ): Promise<HTMLIonAlertElement> {
    if (typeof message !== 'string') {
      message = (message as any).changingThisBreaksApplicationSecurity;
    }
    // vybet ikony podla cssClass
    let icon: string;
    switch (cssClass) {
      case 'error-alert':
        icon = 'icon-ic_alert';
        break;

      case 'success-alert':
        icon = 'icon-ic_check-handwrite-circle';
        break;

      case 'info-alert':
        icon = 'icon-ic_info-circle';
        break;

      case 'info-alert-blue':
        icon = 'icon-ic_info-circle';
        break;

      case 'info-alert-stamp':
        icon = 'icon-ic_stamp';
        break;
      case 'casino-play-alert':
        icon = 'icon-triangle-rounded-right-stroke';
        break;
    }

    // poskladanie spravy
    let outMessage = '';
    if (icon) {
      outMessage = this._addHeader(icon, header);
    }

    outMessage += `<p>${message}</p>`;

    // zobrazenie
    return this._presentMessage(outMessage, cssClass, buttons, inputs);
  }

  /**
   * Present message based on server respond
   * @param data response from server
   * @param header optional
   * @param buttons https://ionicframework.com/docs/api/alert#usage
   */
  public presentResponse(data: any, header?: string, buttons?: any): void {
    // if (data.data) {
    //   data = data.data;
    // }

    // vybratie spravnej css class podla dat zo serveru
    let outMessage = '';
    let cssClass = '';
    if (data.action && data.action.status !== 'nok') {
      outMessage = this._buildSuccessMessage(data, header);
      cssClass = 'success-alert';
    } else if (data.error || data.errors || data.Errors) {
      outMessage = this._buildErrorMessage(data, header);
      cssClass = 'error-alert';
    } else {
      outMessage = this._buildErrorMessage(data, header);
      cssClass = 'info-alert';
    }

    // zobrazenie
    this._presentMessage(outMessage, cssClass, buttons);
  }

  // zobrazenie
  private async _presentMessage(
    message: string,
    cssClass?: string,
    buttons?: any,
    inputs?: any
  ): Promise<HTMLIonAlertElement> {
    const alert = await this.alertController.create({
      cssClass,
      mode: 'ios',
      header: '',
      message,
      buttons,
      inputs
    });

    await alert.present();
    return alert;
  }

  /**
   * Build message from success request to single string
   * @param messages message
   */
  private _buildSuccessMessage(data: any, header?: string): string {
    const messages = data.action.mesages;

    let outMessage = this._addHeader('icon-ic_check-handwrite-circle', header);

    messages.forEach(message => {
      outMessage += `<p>${message}</p>`;
    });

    return outMessage;
  }

  /**
   * Build message from error request to single string
   * @param errors errors
   */
  private _buildErrorMessage(data: any, header?: string): string {
    if (data.errors || data.error) {
      let messages;
      if(data.errors?.validation[0].messages[0]) {
         messages = '' + data.errors.validation[0].messages[0];
      } if(data.error) {
         messages = '' + data.error.message;
      } else {
         messages = ' '; //response_error_header
      }
      let outMessage = this._addHeader('icon-ic_alert', header);
      outMessage += `<p>${messages}</p>`;
      return outMessage;
    } else if (data.Errors) {
      let error = '' + data.Errors[0].split(';', 1);
      // ERR_EVTRST:1184028154;
      if (error.includes('ERR_EVTRST')) {
        // const errors = error.split(':', 2)[0];
        error = error.split(':', 2)[0];
      }
      const messages = this._translateService.instant(
        'errors_ticket_error_' + error
      );
      let outMessage = this._addHeader('icon-ic_alert', header);
      outMessage += `<p>${messages}</p>`;
      return outMessage;
    } else {
      const errors = data.error.errors.validation;
      let outMessage = this._addHeader('icon-ic_alert', header);
      errors.forEach(error => {
        let messages = '';
        error.messages.forEach(message => {
          messages += message;
        });
        outMessage += `<p>${messages}</p>`;
      });
      return outMessage;
    }
  }

  /**
   * private metod for header of message
   * @param icon icon
   * @param header header
   */
  private _addHeader(icon: string, header?: string): string {
    let outMessage = `<ion-fab slot="fixed">
                        <ion-fab-button>
                          <ion-icon class="${icon}"></ion-icon>
                        </ion-fab-button>
                      </ion-fab>`;
    if (header) {
      outMessage += `<h2>${header}</h2>`;
    }

    return outMessage;
  }
}
