import { parseScore } from '@core/functions/parseScore';
import { Label } from '@models/Label';
import { League } from '@models/League';
import { Region } from '@models/Region';
import { Sport } from '@models/Sport';
import { OfferResponse } from './offerResponse';

/** transforms response from offer list to unified response */
export function transformOfferListResponse(data): OfferResponse {
  const tree: OfferResponse['tree'] = {};
  const sports: OfferResponse['sports'] = {};
  const events: OfferResponse['events'] = {};
  const odds: OfferResponse['odds'] = {};
  const regions: OfferResponse['regions'] = {};
  const leagues: OfferResponse['leagues'] = {};
  const stream: OfferResponse['stream'] = {};
  const phases: OfferResponse['phases'] = {};

  Object.values<Label>(data.Labels).forEach(label => {
    if (['SP', 'SE'].includes(label.Typ)) {
      sports[label.ID] = label as Sport;
    }
    if (label.Typ === 'RE') {
      regions[label.ID] = label as Region;
    }
    if (label.Typ === 'LC') {
      leagues[label.ID] = label as League;
    }
    if (label.Typ === 'PH') {
      phases[label.ID] = label;
    }
  });

  const isResult = data.hasOwnProperty('Results');

  let sportEvents = [];
  const offerList: any[] = data[isResult ? 'Results' : 'EventChanceTypes'];

  offerList.forEach((event, index) => {
    event._order = index;
    if (event.SportEventID > 0) {
      regions['SX_' + event.SportID] = {
        ...sports[event.SportID],
        ID: 'SX_' + event.SportID,
        Typ: 'SX'
      };
      sportEvents = [
        ...sportEvents,
        {
          ...event,
          SportID: event.SportEventID,
          RegionID: 'SX_' + event.SportID
        }
      ];
    }
  });

  [...offerList, ...sportEvents].forEach(event => {
    const sport = sports[event.SportID];
    const region = regions[event.RegionID];
    const league = leagues[event.LeagueCupID];

    regions[event.RegionID] = {
      ...regions[event.RegionID],
      CountryID: event.CountryID
    };

    events[event.EventID] = {
      ...event,
      ParsedScore: parseScore(event.Score, sport.ID)
    };

    tree[sport.ID] = tree[sport.ID] || {
      key: `SP_${sport.ID}`,
      typ: 'SP',
      sportID: sport.ID,
      order: event._order,
      children: {}
    };

    tree[sport.ID].children[region.ID] = tree[sport.ID].children[region.ID] || {
      key: `RE_${region.ID}`,
      typ: 'RE',
      children: {},
      regionID: region.ID,
      order: event._order
    };

    tree[sport.ID].children[region.ID].children[league.ID] = tree[sport.ID]
      .children[region.ID].children[league.ID] || {
      key: `LC_${league.ID}`,
      typ: 'LC',
      eventCount: 0,
      countryID: event.CountryID,
      leagueID: league.ID,
      seasonID: event.SeasonID,
      sportID: sport.ID,
      hasStatistics: !!event.SeasonID,
      order: event._order,
      children: {}
    };

    tree[sport.ID].children[region.ID].children[league.ID].eventCount += 1;

    tree[sport.ID].children[region.ID].children[league.ID].children[
      event.EventID
    ] = {
      key: `EV_${event.EventID}`,
      typ: 'EV',
      liveStream:
        (event.Stream || event.ProviderStreamID) && event.LiveBetting === 'Y',
      eventID: event.EventID,
      order: event._order,
      children: {}
    };

    const [home, away] = event.EventName.split(' vs.');
    ['1', 'X', '2', '1X', 'X2', '12'].forEach(
      (tipType: '1' | '1X' | '2' | '12' | 'X' | 'X2', i) => {
        const odd =
          data.Odds[
            `${isResult ? event.EventID : event.EventChanceTypeID}_${tipType}`
          ];
        if (odd) {
          const oddID = isResult
            ? `${event.EventChanceTypeID}_${tipType}`
            : odd.OddsID;
          tree[sport.ID].children[region.ID].children[league.ID].children[
            event.EventID
          ].children[oddID] = {
            typ: 'ODD',
            key: `ODD_${oddID}`,
            oddsID: oddID,
            tipType,
            order: i + 1
          };
          odds[oddID] = {
            OddNameNaming:
              odd.TipType === '1'
                ? home
                : odd.TipType === '2'
                ? away
                : data.LocalizedTips?.[odd.TipType] ||
                  data.Labels[`TP_${odd.TipID}`]?.Name ||
                  odd.TipType,
            OddNameNumbering: odd.TipType,
            OddsID: oddID,
            ...odd
          };
        }
      }
    );
  });

  return {
    sports,
    regions,
    leagues,
    events,
    phases,
    odds,
    tree,
    stream
  };
}
