import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import JsBarcode from 'jsbarcode';

@Component({
  selector: 'doxx-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.css']
})
export class BarcodeComponent implements AfterViewInit {
  @Input() options: {
    format?: string;
    width?: number;
    height?: number;
    displayValue?: boolean;
    text?: string;
    fontOptions?: string;
    font?: string;
    textAlign?: string;
    textPosition?: string;
    textMargin?: number;
    fontSize?: number;
    background?: string;
    lineColor?: string;
    margin?: number;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    valid?: (valid) => void;
  };

  @Input() value: string;
  @ViewChild('barcode') barcode: ElementRef<HTMLOrSVGElement>;

  constructor() {}

  /**
   * display barcode
   */
  ngAfterViewInit(): void {
    JsBarcode(this.barcode.nativeElement, this.value, this.options);
  }
}
