import { TicketOdd } from '@models/Ticket';

export class InGroup {
  /**
   * check if clicked odd is inside group
   *
   * used in rozpisovy tiket to display pill
   */
  isInGroup(index: number, odds: TicketOdd[]) {
    const odd = odds[index];
    if (odd.Group === '1') return true;
    const prevOdd = odds[index - 1];
    const nextOdd = odds[index + 1];
    return prevOdd?.Group === odd.Group || nextOdd?.Group === odd.Group;
  }
}
