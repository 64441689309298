import { configuration } from '@configuration';

interface OrModulesWithOperator {
  or: (ModulesWithOperator | string | boolean)[];
}
interface AndModulesWithOperator {
  and: (ModulesWithOperator | string | boolean)[];
}

export type ModulesWithOperator =
  | OrModulesWithOperator
  | AndModulesWithOperator;

const findAvailabilityInConig = (c: string): boolean => {
  const foundProperty = c
    .split('.')
    .reduce((object, key) => (object && object[key]) || false, configuration);

  if (Object(foundProperty).hasOwnProperty('allowed')) {
    return !!foundProperty.allowed;
  }
  if (Object(foundProperty).hasOwnProperty('available')) {
    return !!foundProperty.available;
  }
  if (Object(foundProperty).hasOwnProperty('enabled')) {
    return !!foundProperty.enabled;
  }
  if (Object(foundProperty).hasOwnProperty('enable')) {
    return !!foundProperty.enable;
  }
  if (typeof foundProperty === 'boolean') {
    return !!foundProperty;
  }
  console.warn(
    `Property ${c} of configuration is not type boolean or has not allowed|available|enable|enabled child property. Found ${JSON.stringify(
      foundProperty
    )}`
  );
  return undefined;
};

/**
 * Zisti ci je modul dostupny, ak jeden z array modulov nie je dospuny vrati false
 * @param searchModule 'dot separated index k prpoperty configu'
 * @returns boolean, hodnota enabled alebo awailable pre property
 */
export function isAvailable(
  searchModule: string | string[] | ModulesWithOperator
): boolean {
  if (Array.isArray(searchModule)) {
    searchModule = { and: [...searchModule] };
  }
  if (typeof searchModule === 'string') {
    searchModule = { and: [searchModule] };
  }

  const getValue = (modules: ModulesWithOperator): boolean => {
    const getValueFromField = (
      field: string | boolean | ModulesWithOperator
    ) => {
      if (typeof field === 'boolean') {
        return field;
      }
      if (typeof field === 'string') {
        return findAvailabilityInConig(field);
      }
      return getValue(field);
    };

    /* eslint-disable @typescript-eslint/dot-notation */
    if (modules['or']) {
      return modules['or'].some(getValueFromField);
    } else {
      return modules['and'].every(getValueFromField);
    }
  };

  return getValue(searchModule);
}
