import { createReducer, on } from '@ngrx/store';
import { Bonus, MyBonus } from '@models/Bonus';
import { Freebet } from '@models/Freebet';
import { LoyaltyBonusState, CodeBonus, FreeSpin } from '..';
import { bonusesActions } from '@store/actions';

export const bonusesFeatureKey = 'bonuses';

export interface State {
  loading: boolean;
  betting: {
    loaded: boolean;
    bonuses: Bonus[];
  };
  mybetting: {
    loaded: boolean;
    bonuses: MyBonus[];
  };
  casino: {
    loaded: boolean;
    bonuses: Bonus[];
  };
  freebets: Freebet[];
  scout: {
    profit: any;
    summary: any;
  };
  loyality: LoyaltyBonusState;
  freespins: {
    active: {
      loaded: boolean;
      loading: boolean;
      freespins: FreeSpin[];
    };
    history: {
      loaded: boolean;
      loading: boolean;
      freespins: FreeSpin[];
    };
  };
  codeBonusHistory: CodeBonus[];
}

export const initialState: State = {
  loading: false,
  betting: {
    loaded: false,
    bonuses: []
  },
  mybetting: {
    loaded: false,
    bonuses: []
  },
  casino: {
    loaded: false,
    bonuses: []
  },
  freebets: undefined,
  scout: undefined,
  loyality: undefined,
  freespins: {
    active: {
      loaded: false,
      loading: false,
      freespins: []
    },
    history: {
      loaded: false,
      loading: false,
      freespins: []
    }
  },
  codeBonusHistory: undefined
};

export const reducer = createReducer(
  initialState,

  on(bonusesActions.loadBettingBonusesOnce, state => state),
  on(bonusesActions.loadBettingBonusesSuccess, (state, action) => ({
    ...state,
    betting: {
      ...state.betting,
      bonuses: action.bonuses,
      loaded: true
    }
  })),
  on(bonusesActions.loadMyBettingBonuses, state => state),
  on(bonusesActions.loadMyBettingBonusesSuccess, (state, action) => ({
    ...state,
    mybetting: {
      ...state.mybetting,
      bonuses: action.bonuses,
      loaded: true
    }
  })),
  on(bonusesActions.loadCasinoBonusesSuccess, (state, action) => ({
    ...state,
    casino: {
      ...state.casino,
      bonuses: action.bonuses,
      loaded: true
    }
  })),
  on(bonusesActions.loadFreebetsSuccess, (state, action) => ({
    ...state,
    freebets: action.freeBets
  })),
  on(bonusesActions.setScoutSummary, (state, action) => ({
    ...state,
    scout: {
      profit: {
        ...state.scout?.profit,
        ...action.scoutBonusSummary.profit
      },
      summary: {
        ...state.scout?.summary,
        ...action.scoutBonusSummary.summary
      }
    }
  })),
  on(bonusesActions.loadLoyalityPointsSuccess, (state, action) => ({
    ...state,
    loyality: action.points
  })),
  on(bonusesActions.loadCodeBonusHistorySuccess, (state, action) => ({
    ...state,
    codeBonusHistory: action.codeBonuses
  })),
  on(bonusesActions.loadFreespins, (state, action) => ({
    ...state,
    freespins: {
      ...state.freespins,
      [action.freespinsType]: {
        ...state.freespins[action.freespinsType],
        loading: true
      }
    }
  })),
  on(bonusesActions.loadFreespinsFailure, (state, action) => ({
    ...state,
    freespins: {
      ...state.freespins,
      [action.freespinsType]: {
        ...state.freespins[action.freespinsType],
        loading: false,
        loaded: true
      }
    }
  })),
  on(bonusesActions.loadFreespinsSuccess, (state, action) => ({
    ...state,
    loading: false,
    ...state,
    freespins: {
      ...state.freespins,
      [action.freespinsType]: {
        ...state.freespins[action.freespinsType],
        freespins: action.freespins,
        loading: false,
        loaded: true
      }
    }
  }))
);
