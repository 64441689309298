<div class="shadow">
  <div class="px-2">
    <div *ngIf="odds.length" class="before-wave"></div>
    <ng-content select="[slot=start]"></ng-content>
    <div class="flex flex-col divide-y">
      <div
        id="{{ odd.OddID }}"
        class="bg-white flex flex-row"
        *ngFor="
          let odd of odds;
          trackBy: 'OddID' | trackByProperty;
          let i = index
        "
        [ngClass]="{
          '!bg-[#F8F8F8]': selectedOdds.includes(odd.OddID),
          'pl-5': ticket.TYPE !== 'SRT',
          '!bg-[#e8f5fe]':
            (!odd.IsEditedOdd && !odd.IsActive) ||
            ratexOdds.includes(odd.OddID),
          'opacity-60': odd.IsEditedOdd && odd.IsRemoveForbiden
        }"
        (click)="toggleOdd(odd.OddID)"
      >
        <!-- combination groups -->
        <ng-container
          *ngTemplateOutlet="
            oddsGroup;
            context: {
              $implicit: {
                i,
                odd,
                selected: selectedOdds.includes(odd.OddID)
              }
            }
          "
        >
        </ng-container>
        <!-- main section -->
        <div class="flex-1 py-5 pr-5 relative">
          <!--league-->
          <div class="flex">
            <!-- league name -->
            <div class="mr-auto flex flex-wrap">
              <span
                class="text-white bold uppercase !text-xs rounded mt-1 mr-1 px-1 whitespace-nowrap"
                [style.background]="
                  'var(--xx-sport-color-' + odd.SportID + ', black)'
                "
                >{{ odd.SportName }}</span
              >
              <span
                class="text-white bold uppercase !text-xs rounded mt-1 mr-1 px-1 whitespace-nowrap"
                [style.background]="
                  'var(--xx-sport-color-' + odd.SportID + ', black)'
                "
                >{{ odd.RegionName }}</span
              >
              <span
                class="text-white bold uppercase !text-xs rounded mt-1 px-1 whitespace-nowrap"
                [style.background]="
                  'var(--xx-sport-color-' + odd.SportID + ', black)'
                "
                >{{ odd.LeagueCupName }}</span
              >
            </div>
            <div
              *ngIf="odd.DATE"
              class="text-xs text-[#7E848A] whitespace-nowrap pl-1"
            >
              {{ odd.DATE | formatDate : 'YYYYMMDDhhmm' }}
            </div>
          </div>
          <!-- teams -->
          <div class="flex">
            <!-- team vs team -->
            <div
              class="mr-auto flex flex-wrap self-center py-1.5"
              [routerLink]="
                ticket.TYPE === 'SRT'
                  ? []
                  : [
                      (odd.LiveBet ? 'EventDetail_Live' : 'EventDetail')
                        | translateRoute
                          : {
                              sportID: [odd.SportID, odd.SportName | slugify],
                              regionID: [
                                odd.RegionID,
                                odd.RegionName | slugify
                              ],
                              leagueID: [odd.LCUP, odd.LeagueCupName | slugify],
                              eventID: odd.EVT,
                              eventName:
                                odd.Team1 + ' vs ' + odd.Team2 | slugify
                            }
                    ]
              "
              [queryParams]="{ referer: 'ticket' }"
            >
              <div *ngIf="odd.SportID === '153'" class="pr-1">
                <span
                  class="text-xs text-[#a11cff] icon-ic_virtural-games-small"
                ></span>
              </div>
              <div *ngIf="odd.LiveBet && odd.SportID !== '153'" class="pr-1">
                <span class="text-xs text-[#F40034] icon-ic_live-small"></span>
              </div>
              <span class="bold text-sm text-[#323840]">{{ odd.Team1 }}</span>
              <span *ngIf="!odd._score" class="px-1 text-sm text-[#7E848A]"
                >vs</span
              >
              <!-- score -->
              <span
                class="text-sm font-bold text-red-500 px-1 whitespace-nowrap"
                *ngIf="odd._score as score"
                >{{ score.home.points }}:{{ score.away.points
                }}<span *ngIf="odd._scoreStyle === 'tenis'">
                  ({{
                    score.home.partials[score.home.partials.length - 1] || 0
                  }}
                  :
                  {{
                    score.away.partials[score.away.partials.length - 1] || 0
                  }})</span
                >
                <span
                  *ngIf="['tenis', 'serving'].includes(odd._scoreStyle)"
                  class="serving"
                >
                  <span
                    class="serving px-2 ml-1 border-l"
                    [ngClass]="{
                      home: score.home.serving,
                      away: score.away.serving
                    }"
                    ><ng-container *ngIf="odd._scoreStyle === 'tenis'"
                      >{{
                        score.home.game === '50' ? 'A' : score.home.game || 0
                      }}:{{
                        score.away.game === '50' ? 'A' : score.away.game || 0
                      }}
                    </ng-container>
                    <ng-container *ngIf="odd._scoreStyle === 'serving'"
                      >{{
                        score.home.partials[score.home.partials.length - 1]
                      }}:{{
                        score.away.partials[score.away.partials.length - 1]
                      }}
                    </ng-container></span
                  >
                </span>
              </span>
              <span class="bold text-sm text-[#323840]">{{ odd.Team2 }}</span>
            </div>
            <div
              *ngIf="odd._removable"
              class="mr-0.5"
              (click)="removeOdd.next(odd.OddID)"
            >
              <i class="text-2xl text-[#bec1c4] icon-ic_close-circle-fill2"></i>
            </div>
            <div
              *ngIf="odd.IsWinning === 'BB' && odd.IsEditedEvaluated"
              class="mr-0.5"
            >
              <i class="text-2xl text-green icon-ic_u-in-circle-stroke"></i>
            </div>
          </div>
          <!-- bet info -->
          <div class="flex">
            <!-- ChanceType -->
            <div class="mr-auto flex flex-col">
              <div class="text-xs text-[#7E848A]">
                {{
                  odd.ChanceType
                    | replace : ('betBuilder.oddsDelimiter' | env) : '\n'
                }}
              </div>
              <div *ngIf="!odd.IsBetbuilder" class="text-[#F40034] bold">
                {{
                  oddNaming === 'NAMING'
                    ? odd.OddNameNaming
                    : odd.OddNameNumbering
                }}
              </div>
            </div>
            <!-- bet name -->
            <div class="self-center">
              <div *ngIf="!odd.IsActive" class="mr-1 pr-px">
                <i class="text-2xl text-[#bec1c4] icon-ic_lock"></i>
              </div>
              <div
                *ngIf="odd.IsActive"
                class="text-white bg-[#7E848A] bold !text-xs rounded px-1"
                [@ticketOddRateChanged]="odd.Rate"
                [@.disabled]="odd.IsEditedOdd"
              >
                {{
                  (odd.IsEditedOdd &&
                  !(odd.IsWinning === 'BB' && odd.IsEditedEvaluated)
                    ? odd.RateTicket
                    : odd.Rate
                  ) | rate
                }}
              </div>
            </div>
          </div>
          <div
            *ngIf="odd._buyBackable"
            class="mt-4 text-sm flex items-center justify-between"
          >
            <mat-slide-toggle
              [(ngModel)]="odd._isBuyBack"
              (change)="toggleBuyback(odd, $event)"
              >{{ 'ticket_editing_odds_buyback' | translate }}
            </mat-slide-toggle>
            <div
              *ngIf="odd.IsActive"
              class="text-white bg-[#7E848A] bold !text-xs rounded px-1"
              [@ticketOddRateChanged]="odd.BuyBackValue"
            >
              <span *ngIf="+odd.BuyBackValue < 0">{{ odd.Rate | rate }}</span>
              <span *ngIf="+odd.BuyBackValue >= 0">{{
                odd.BuyBackValue | rate
              }}</span>
            </div>
          </div>
          <div
            *ngIf="
              odd.IsWinning === 'Y' ||
              (odd.IsWinning === 'BB' && odd.IsEditedEvaluated)
            "
            class="absolute top-0 right-0 w-0.5 h-full"
            [style.background]="'var(--match-status-gradient-win)'"
          ></div>
        </div>
      </div>
    </div>
    <div *ngIf="odds.length" class="after-wave"></div>
  </div>
  <div
    class="sticky bottom-[49px] z-50"
    *ngIf="ticket.TYPE === 'SRT' && odds.length"
  >
    <div class="gradient"></div>
    <div class="p-2 bg-[#f2f2f2]">
      <div
        class="grid grid-cols-2 divide-x bg-white rounded border border-[#DEDEDE]"
      >
        <div
          class="text-[#F40034] h-11 flex items-center justify-center"
          (click)="createGroup()"
          [ngClass]="
            selectedOdds.length === 0 && ['opacity-50 pointer-events-none']
          "
        >
          {{ 'ticket_create_group' | translate }}
        </div>
        <div
          class="text-[#72CD21] h-11 flex items-center justify-center"
          (click)="topOdd()"
          [ngClass]="
            selectedOdds.length === 0 && ['opacity-50 pointer-events-none']
          "
        >
          {{ 'ticket_mark_top' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #oddsGroup let-data>
  <div
    class="w-16 flex-none flex items-center flex-col"
    *ngIf="ticket.TYPE === 'SRT'"
  >
    <!-- top line -->
    <div
      [class]="'w-0.5 bg-[#F40034] bold h-[21px] mt-[-1px] z-10'"
      [ngClass]="{ '!bg-[#72CD21]': data.odd.Group === '1' }"
      *ngIf="data.i | isTopLine : odds"
    ></div>
    <!-- not in group - circle -->
    <div
      *ngIf="!(data.i | isInGroup : odds)"
      class="w-6 h-6 border-2 border-[#F40034] rounded-full flex justify-center items-center text-[#F40034] mt-6"
      [ngClass]="{
        '!bg-[#F40034] !text-white': data.selected && data.odd.Group !== '1',
        '!bg-[#72CD21] text-white': data.selected && data.odd.Group === '1',
        '!border-[#72CD21] text-[#72CD21]': data.odd.Group === '1'
      }"
    >
      <div>
        {{ data.odd.Group }}
      </div>
    </div>
    <!-- in group - pill -->
    <div
      *ngIf="data.i | isInGroup : odds"
      class="w-6 h-[72px] bold border-2 divide-y border-[#F40034] rounded-full flex justify-center flex-col items-center text-[#F40034]"
      [ngClass]="{
        '!bg-[#F40034] !text-white': data.selected && data.odd.Group !== '1',
        '!bg-[#72CD21] text-white': data.selected && data.odd.Group === '1',
        '!border-[#72CD21] text-[#72CD21]': data.odd.Group === '1',
        'mt-6': !(data.i | isTopLine : odds)
      }"
      [ngStyle]="{
        background: data.selected
          ? data.odd.Group === '1'
            ? '#72CD21'
            : '#F40034'
          : 'linear-gradient(0deg, ' +
            (data.odd.Group === '1' ? '#72CD21' : '#F40034') +
            ' 50%, #ffffff 0%)'
      }"
    >
      <div class="w-full text-center h-[20px] flex items-center justify-center">
        <div *ngIf="data.odd.Group !== '1'">{{ data.odd.Group }}</div>
        <div
          class="w-2 h-2 bg-[#72CD21] rounded"
          [ngClass]="{
            '!bg-white': data.selected
          }"
          *ngIf="data.odd.Group === '1'"
        ></div>
      </div>
      <div
        class="w-full text-center bg-[#F40034] text-white"
        [ngClass]="{ '!bg-[#72CD21]': data.odd.Group === '1' }"
        (click)="untagOdd(data.odd.OddID)"
      >
        –
      </div>
      <div
        class="w-full text-center bg-[#F40034] text-white"
        [ngClass]="{ '!bg-[#72CD21]': data.odd.Group === '1' }"
        (click)="untagGroup(data.odd.Group)"
      >
        ×
      </div>
    </div>
    <!-- bottom line -->
    <div
      class="w-0.5 bg-[#F40034] flex-1 z-10"
      [ngClass]="{ '!bg-[#72CD21]': data.odd.Group === '1' }"
      *ngIf="data.i | isBottomLine : odds"
    ></div>
  </div>
</ng-template>
