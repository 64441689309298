import { Inject, Injectable, InjectionToken } from '@angular/core';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { PagesMetadata } from '@models/PagesMetadata';
import { MainService } from '@core/services/main/main.service';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { appSelectors } from '@store/selectors';

export const SEO_TEXT = new InjectionToken<BehaviorSubject<string>>('SEO_TEXT');
const DEFAULT_META_KEY = 'DEFAULT';

@Injectable()
export class SeoService {
  private _metadata: Record<PagesMetadata['Key'], Partial<PagesMetadata>> = {
    [DEFAULT_META_KEY]: {
      Key: DEFAULT_META_KEY,
      Description: this._meta.getTag(`name="description"`)?.content || '',
      Keywords: this._meta.getTag(`name="keywords"`)?.content || '',
      WebSiteTitle: this._titleService.getTitle() || '',
      SeoTextMobile: '',
      OgImage: this._meta.getTag(`property="og:image"`)?.content || ''
    }
  };

  constructor(
    @Inject(SEO_TEXT) private _seoText: BehaviorSubject<SafeHtml>,
    private _sanitizer: DomSanitizer,
    private _titleService: Title,
    private _meta: Meta,
    private _mainService: MainService,
    private _router: Router,
    private _store: Store
  ) {
    this._store
      .select(appSelectors.selectIsAppInitialized)
      .pipe(
        filter(initialized => initialized === true),
        first()
      )
      .subscribe(() => {
        this._mainService.fetchOfferSeoTexts().subscribe(
          meta =>
            (this._metadata = {
              ...this._metadata,
              ...meta.reduce((a, c) => ({ ...a, [c.Key]: c }), {})
            }),
          null,
          () => {}
        );
      });

    this._router.events
      .pipe(
        filter(
          event => event instanceof ActivationEnd && !event.snapshot.firstChild
        )
      )
      .subscribe((event: ActivationEnd) => {
        // Try Find Regex Match
        const metadata = this._findMetadataForRoute(event.snapshot).distinct();
        const [actualMeta, ...defaultMetadata] = metadata;
        this.updateSeo(actualMeta, { defaultMetadata });
      });
  }

  private _findMetadataForRoute = (
    route: ActivatedRouteSnapshot,
    foundMetaKeys = []
  ): string[] => {
    if (route.data.screenParams || route.data.LinkMetadataKey) {
      const metaKey: string[] = [];
      Array.from(route.data.screenParams || [])
        .reverse()
        .forEach(([key, params]) => {
          if (!!params?.MetadataKey) {
            metaKey.push(params.MetadataKey);
          }
        });
      if (metaKey.length === 0 && route.data.LinkMetadataKey) {
        metaKey.push(route.data.LinkMetadataKey);
      }

      if (metaKey.length > 0) {
        return this._findMetadataForRoute(route.parent, [
          ...foundMetaKeys,
          ...metaKey
        ]);
      } else {
        // If metadata are not used, we need to use neares paretns data
        return this._findMetadataForRoute(route.parent, foundMetaKeys);
      }
    } else {
      // if route has not defined metadata matcher we need to use nearest parents data or default values
      if (route.parent) {
        return this._findMetadataForRoute(route.parent, foundMetaKeys);
      } else {
        return [...foundMetaKeys, DEFAULT_META_KEY];
      }
    }
  };

  /** Update seo by key */
  updateSeo(
    metadataKey: string,
    options?: {
      defaultMetadata?: string | string[];
      fallbackToDefault?: boolean;
    }
  ): void {
    if (!this._metadata[metadataKey]) {
      if (options?.defaultMetadata) {
        if (typeof options.defaultMetadata === 'string') {
          options.defaultMetadata = [options.defaultMetadata];
        }
        const defaultMeta = options.defaultMetadata.find(
          key => !!this._metadata[key]
        );
        this.updateSeo(defaultMeta, {
          fallbackToDefault: options?.fallbackToDefault
        });
      } else if (options?.fallbackToDefault) {
        this.updateSeo(DEFAULT_META_KEY);
      }
    } else {
      const metadata = this._metadata[metadataKey];
      this._seoText.next(
        this._sanitizer.bypassSecurityTrustHtml(metadata.SeoTextMobile || '')
      );
      this._titleService.setTitle(metadata.WebSiteTitle);
      this._meta.updateTag({
        name: 'description',
        content: metadata.Description
      });
      this._meta.updateTag({
        property: 'og:image',
        content: metadata.OgImage
      });
      this._meta.updateTag({ name: 'keywords', content: metadata.Keywords });
    }
  }
}
