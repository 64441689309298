import { createAction, props } from '@ngrx/store';

/**
 * LOGIN
 */

export const loginStart = createAction(
  '[Auth] Login start',
  props<{ email: string; password: string }>()
);
export const loginSuccess = createAction(
  '[Auth] Login success',
  props<{ payload: any; settings: any }>()
);
export const loginFailure = createAction(
  '[Auth] Login failure',
  props<{ error: string }>()
);

/**
 * AUTO LOGIN
 */
export const autologinStart = createAction('[Auth] AutoLogin Start');
export const autologinSuccess = createAction(
  '[Auth] AutoLogin Success',
  props<{ user: any; settings: any }>()
);
export const autologinFailure = createAction('[Auth] AutoLogin Failure');

/**
 * LOGOUT
 */
export const softLogoutStart = createAction('[Auth] Soft Logout Start');
export const softLogoutSuccess = createAction('[Auth] Soft Logout Success');
export const logoutStart = createAction('[Auth] Logout Start');
export const logoutSuccess = createAction('[Auth] Logout Success');

/**
 * BALANCE REFRESH
 */
export const balanceRefreshStart = createAction('[Auth] Balance Refresh Start');
export const balanceRefreshSuccess = createAction(
  '[Auth] Balance Refresh Success',
  props<{ payload: any }>()
);
export const balanceRefreshFailure = createAction(
  '[Auth] Balance Refresh Failure',
  props<{ error: string }>()
);

/** SETTINGS */
export const loadSettings = createAction('[Auth] Load User Settings');
export const loadSettingsSuccess = createAction(
  '[Auth] Load User Settings Success',
  props<{ settings: any }>()
);
export const loadSettingsFailure = createAction(
  '[Auth] Load User Settings Failure',
  props<{ error: any }>()
);
