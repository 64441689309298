import { EventChanceType } from '@models/EventChanceType';
import { Odd } from '@models/Odd';
import { Event } from '@models/Event';
import { visibility } from '@models/Visibility';

/**
 * matrix for determining visibility after status change
 * ectStatusMatrix.active.suspended.closed is a status
 */
export const ectStatusMatrix = {
  active: {
    active: {
      active: visibility.SHOW,
      suspended: visibility.LOCK,
      closed: visibility.HIDE
    },
    suspended: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE
    },
    closed: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE
    }
  },
  all: {
    active: {
      active: visibility.SHOW,
      suspended: visibility.LOCK,
      closed: visibility.HIDE
    },
    suspended: {
      active: visibility.SHOW,
      suspended: visibility.LOCK,
      closed: visibility.HIDE
    },
    closed: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE
    }
  },
  other: {
    active: {
      active: visibility.SHOW,
      suspended: visibility.LOCK,
      closed: visibility.HIDE
    },
    suspended: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE
    },
    closed: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE
    }
  },
  locked: {
    active: {
      active: visibility.LOCK,
      suspended: visibility.LOCK,
      closed: visibility.HIDE
    },
    suspended: {
      active: visibility.LOCK,
      suspended: visibility.LOCK,
      closed: visibility.HIDE
    },
    closed: {
      active: visibility.LOCK,
      suspended: visibility.LOCK,
      closed: visibility.HIDE
    }
  }
};

export const oddStatusMatrix = {
  active: {
    active: {
      active: visibility.SHOW,
      suspended: visibility.LOCK,
      closed: visibility.HIDE,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    suspended: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    closed: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    inactive: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    canceled: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    }
  },
  all: {
    active: {
      active: visibility.SHOW,
      suspended: visibility.LOCK,
      closed: visibility.HIDE,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    suspended: {
      active: visibility.SHOW,
      suspended: visibility.LOCK,
      closed: visibility.HIDE,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    closed: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    inactive: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    canceled: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    }
  },
  other: {
    active: {
      active: visibility.SHOW,
      suspended: visibility.LOCK,
      closed: visibility.HIDE,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    suspended: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    closed: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    inactive: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    canceled: {
      active: visibility.SHOW,
      suspended: visibility.HIDE,
      closed: visibility.HIDE,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    }
  },
  locked: {
    active: {
      active: visibility.HIDE,
      suspended: visibility.LOCK,
      closed: visibility.LOCK,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    suspended: {
      active: visibility.HIDE,
      suspended: visibility.LOCK,
      closed: visibility.LOCK,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    closed: {
      active: visibility.HIDE,
      suspended: visibility.LOCK,
      closed: visibility.LOCK,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    inactive: {
      active: visibility.HIDE,
      suspended: visibility.LOCK,
      closed: visibility.LOCK,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    },
    canceled: {
      active: visibility.HIDE,
      suspended: visibility.LOCK,
      closed: visibility.LOCK,
      inactive: visibility.HIDE,
      canceled: visibility.HIDE
    }
  }
};

/**
 * calculates event visibility
 * @param ev event
 */
export function getEventVisibility(ev: Event): visibility {
  if (ev.LiveBettingView === -1) {
    return visibility.LOCK;
  } else {
    return visibility.SHOW;
  }
}

/**
 * calculates odd visibility
 * @param odd odd
 * @param groupID group ID
 */
export function getOddVisibility(
  odd: Odd,
  groupID: string,
  eventVis: visibility
): visibility {
  try {
    if (['all', 'active', 'locked'].indexOf(groupID) === -1) {
      groupID = 'other';
    }
    const retVis =
      oddStatusMatrix[
        eventVis < visibility.SHOW && groupID === 'locked' ? 'all' : groupID
      ][odd.OldStatus ? odd.OldStatus : odd.Status][odd.Status];
    // if (
    //   eventVis < visibility.SHOW &&
    //   groupID === 'active' &&
    //   odd.OldStatus === odd.Status
    // ) {
    //   return visibility.HIDE;
    // }
    return eventVis < visibility.SHOW && retVis === visibility.SHOW
      ? visibility.LOCK
      : retVis;
  } catch (e) {
    return null;
  }
}

/**
 * calculates eventchancetype visibility
 * @param ect eventchancetype
 * @param groupID group ID
 */
export function getEventChanceTypeVisibility(
  ect: EventChanceType,
  groupID: string,
  eventVis: visibility
): visibility {
  try {
    if (['all', 'active', 'locked'].indexOf(groupID) === -1) {
      groupID = 'other';
    }

    const oldStatus = ect.OldEventChanceTypeStatus
      ? ect.OldEventChanceTypeStatus
      : ect.EventChanceTypeStatus;

    const retVis =
      ectStatusMatrix[
        eventVis < visibility.SHOW && groupID === 'locked' ? 'all' : groupID
      ][oldStatus][ect.EventChanceTypeStatus];
    // if (
    //   eventVis < visibility.SHOW &&
    //   groupID === 'active' &&
    //   ect.OldEventChanceTypeStatus === ect.EventChanceTypeStatus
    // ) {
    //   return visibility.HIDE;
    // }

    return eventVis < visibility.SHOW && retVis === visibility.SHOW
      ? visibility.LOCK
      : retVis;
  } catch (e) {
    return null;
  }
}
