import { createAction, props } from '@ngrx/store';
import { League } from '@models/League';

export const loadTopLeagues = createAction(
  '[Landing] Load Top Leagues',
  props<{ count: number }>()
);

export const loadTopLeaguesSuccess = createAction(
  '[Landing] Load Top Leagues Success',
  props<{ leagues: League[] }>()
);

export const loadTopLeaguesFailure = createAction(
  '[Landing] Load Top Leagues Failure',
  props<{ error: any }>()
);
