/** enum ID ciek znamych bonusov, ich ID potrebujem koli mape na interne likny */
export const KNOWN_BONUSES = {
  PromocodeBonus: 'promocode',
  FreespinBonus: 'freespin',
  LoyaltyBonusCasino: 'loyality',
  ScoutBonus: 'scout',
  BonusesAndPromocodes: 'coded',
  VipBonus: 'casino-vip',
  TYPES: {
    Promocode: 'PromoCode'
  }
};
