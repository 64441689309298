import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  MatNativeDateModule,
  RippleGlobalOptions
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

const modules = [
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatButtonModule,
  MatExpansionModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatBottomSheetModule
];
@NgModule({
  imports: [...modules],
  exports: [...modules],
  providers: [
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: { disabled: true } as RippleGlobalOptions
    },
    MatDatepickerModule
  ]
})
export class MaterialDesignModule {}
