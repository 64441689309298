import { Favourites } from '@models/Favourites';
import { TicketHistoryListItem } from '@models/TicketHistory';
import { Action, createReducer, on } from '@ngrx/store';
import { authActions, buyBackActions, favoritesActions } from '@store/actions';

export const FAVORITES_STATE_KEY = 'favorites';
export type State = Favourites;

const initialState: State = {
  favouriteChanceTypes: [],
  favouriteLeagues: [],
  favouriteSports: [],
  favouriteEvents: []
};

const buyBackReducer = createReducer(
  initialState,
  on(favoritesActions.favEventsReadSuccess, (state, action) => ({
    ...state,
    favouriteEvents: action.payload
  })),
  on(favoritesActions.favEventAdd, (state, action) => ({
    ...state,
    favouriteEvents: [...state.favouriteEvents, action.payload]
  })),
  on(favoritesActions.favEventRemove, (state, action) => ({
    ...state,
    favouriteEvents: [
      ...state.favouriteEvents.filter(id => id !== action.payload)
    ]
  })),
  on(favoritesActions.favEventsRemove, (state, action) => ({
    ...state,
    favouriteEvents: [
      ...state.favouriteEvents.filter(id => action.payload.indexOf(id) === -1)
    ]
  })),
  on(favoritesActions.getFavouritesSuccess, (state, action) => ({
    ...state,
    favouriteSports: action.payload.favouriteSports,
    favouriteLeagues: action.payload.favouriteLeagues,
    favouriteChanceTypes: action.payload.favouriteChanceTypes
  })),
  on(favoritesActions.favSportAddSuccess, (state, action) => ({
    ...state,
    favouriteSports: [...state.favouriteSports, action.payload]
  })),
  on(favoritesActions.favSportRemoveSuccess, (state, action) => ({
    ...state,
    favouriteSports: [
      ...state.favouriteSports.filter(id => id !== action.payload)
    ]
  })),
  on(favoritesActions.favLeagueAddSuccess, (state, action) => ({
    ...state,
    favouriteLeagues: [...state.favouriteLeagues, action.payload]
  })),
  on(favoritesActions.favLeagueRemoveSuccess, (state, action) => ({
    ...state,
    favouriteLeagues: [
      ...state.favouriteLeagues.filter(id => id !== action.payload)
    ]
  })),
  on(favoritesActions.favChanceTypeAddSuccess, (state, action) => ({
    ...state,
    favouriteChanceTypes: [...state.favouriteChanceTypes, action.payload]
  })),
  on(favoritesActions.favChanceTypeRemoveSuccess, (state, action) => ({
    ...state,
    favouriteChanceTypes: [
      ...state.favouriteChanceTypes.filter(
        cht =>
          cht.chanceTypeId !== action.payload.chanceTypeId ||
          cht.sportId !== action.payload.sportId ||
          cht.liveBetting !== action.payload.liveBetting
      )
    ]
  }))
);

/** buyback reducer */
export function reducer(state: State | undefined, action: Action) {
  return buyBackReducer(state, action);
}
