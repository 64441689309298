import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAnalyses from './analyses.reducer';

export const selectAnalysesState = createFeatureSelector<fromAnalyses.State>(
  fromAnalyses.analysesFeatureKey
);

export const selectActualAnalyses = createSelector(
  selectAnalysesState,
  (state: fromAnalyses.State) => state.actualAnalyses
);

export const selectAnalysesHistory = createSelector(
  selectAnalysesState,
  (state: fromAnalyses.State) => state.analysesHistory
);

export const selectAnalysesHistoryCursor = createSelector(
  selectAnalysesState,
  (state: fromAnalyses.State) => state.analysesHistoryCursor
);

export const selectAnalysisDetail = createSelector(
  selectAnalysesState,
  (state: fromAnalyses.State) => state.analysisDetail
);

export const selectAnalysisByEventID = (eventID: number) =>
  createSelector(selectAnalysesState, (state: fromAnalyses.State) => {
    for (const analysis of state.actualAnalyses) {
      if (analysis.EventID === eventID) {
        return analysis;
      }
    }
    return null;
  });
