import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarListComponent } from './toolbar-list/toolbar-list.component';
import { ToolbarListFilterComponent } from './toolbar-list-filter/toolbar-list-filter.component';
import { IonicModule } from '@ionic/angular';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { FormsModule } from '@angular/forms';
import { ToolbarListItemComponent } from './toolbar-list-item/toolbar-list-item.component';
import { MaterialDesignModule } from '@shared/material-design/material-design.module';

@NgModule({
  declarations: [
    ToolbarListComponent,
    ToolbarListFilterComponent,
    ToolbarListItemComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    DoxxPipesModule,
    FormsModule,
    MaterialDesignModule
  ],
  exports: [
    ToolbarListComponent,
    ToolbarListFilterComponent,
    ToolbarListItemComponent
  ]
})
export class ToolbarListModule {}
