<div class="footer">
  <ng-container
    *ngFor="let type of ['casino', 'rio', 'betting']; trackBy: trackByIndex"
  >
    <ng-container
      *ngTemplateOutlet="footerText; context: { $implicit: type }"
    ></ng-container>
  </ng-container>

  <div class="available-platforms">
    <a
      class="platform-button ion-activatable"
      *ngIf="otherPlatforms.web.allowed"
      [href]="otherPlatforms.web.link"
    >
      <button>
        <i class="icon icon-ic_pc"></i>
        <span>{{ 'app_pc' | translate }}</span>
        <ion-ripple-effect></ion-ripple-effect>
      </button>
    </a>
    <a
      class="platform-button ion-activatable"
      *ngIf="isIos && _type === 'betting' && otherPlatforms.ios.allowed"
      [href]="otherPlatforms.ios.link"
    >
      <button>
        <i class="icon icon-ic_apple"></i>
        <span>{{ 'app_ios' | translate }}</span>
        <ion-ripple-effect></ion-ripple-effect>
      </button>
    </a>
    <a
      class="platform-button ion-activatable"
      *ngIf="
        isIos &&
        ['casino', 'rio'].includes(type) &&
        otherPlatforms.iosCasino.allowed
      "
      [href]="otherPlatforms.iosCasino.link"
    >
      <button>
        <i class="icon icon-ic_apple"></i>
        <span>{{ 'app_ios' | translate }}</span>
        <ion-ripple-effect></ion-ripple-effect>
      </button>
    </a>
    <a
      class="platform-button ion-activatable"
      *ngIf="isAndroid && otherPlatforms.android.allowed"
      [href]="otherPlatforms.android.link"
    >
      <button>
        <i class="icon icon-ic_android"></i>
        <span>{{ 'app_android' | translate }}</span>
        <ion-ripple-effect></ion-ripple-effect>
      </button>
    </a>
  </div>
  <nav class="fast-links">
    <div
      [class]="'fast-links-list link-child ' + fastLinksSection.title"
      *ngFor="
        let fastLinksSection of fastLinks;
        trackBy: 'title' | trackByProperty
      "
      [ngStyle]="{ '--rows': fastLinksSection.links.length }"
    >
      <div>
        <span class="fast-links-list-title" *ngIf="fastLinksSection.title">
          {{ fastLinksSection.title | translate }}
        </span>
        <ul>
          <ng-container
            *ngFor="
              let link of fastLinksSection.links;
              trackBy: 'destination' | trackByProperty
            "
          >
            <li
              *ngIf="link.inApp"
              [ngClass]="{ inline: link.inline }"
              [routerLink]="link.destination | translateRoute"
            >
              <ng-container
                *ngTemplateOutlet="linkLabel; context: { $implicit: link }"
              ></ng-container>
            </li>
            <li *ngIf="!link.inApp" [ngClass]="{ inline: link.inline }">
              <a [href]="sanitize(link.destination)" target="blank">
                <ng-container
                  *ngTemplateOutlet="linkLabel; context: { $implicit: link }"
                ></ng-container>
              </a>
            </li>
            <ng-template #linkLabel let-link>
              <ng-container
                *ngIf="link.icon?.length > 0; then icon; else title"
              ></ng-container>
              <ng-template #title>{{ link.title | translate }}</ng-template>
              <ng-template #icon>
                <div class="icon">
                  <img class="link-icon" [src]="link.icon" />
                  <span>{{ link.title | translate }}</span>
                </div>
              </ng-template>
            </ng-template>
          </ng-container>
        </ul>
        <!-- <span
          *ngIf="fastLinksSection.title === 'footer_nav_about'"
          [routerLink]="
            'Profile'
              | translateRoute
                : {
                    section: COOKIES_SETTINGS_PATH
                  }
          "
          skipLocationChange
          >Nastavenia súborov cookies</span
        > -->

        <!-- TODO cookies labels -->
      </div>
    </div>
  </nav>
  <div>
    <p>
      <strong class="text-highlighted">
        {{ 'footer_msg_3' | translate | replace : '%s' : year }} </strong
      ><br />
      {{ 'footer_msg_4' | translate }}
    </p>

    <p *ngIf="_type === 'betting'">{{ 'footer_msg_5' | translate }}</p>
    <ng-container *ngIf="_type === 'casino' || _type === 'rio'">
      <p>{{ 'obligatory_casino' | translate }}</p>
      <span class="obligatory-info">
        {{ 'menu_obligatory_text' | translate }}
        <a
          href="mailto:{{ 'menu_obligatory_contact' | translate }}"
          class="href"
        >
          {{ 'menu_obligatory_contact' | translate }}
        </a>
      </span>
    </ng-container>
  </div>

  <div class="footer-text">
    <p class="label-style">{{ 'footer_licence_1' | translate }}</p>
    <p class="label-style">{{ 'footer_licence_2' | translate }}</p>
    <p class="label-style">
      {{ 'footer_licence_3' | translate }}
      <a target="blank" [href]="'mailto:' + 'infoline_mail' | translate">
        {{ 'infoline_mail' | translate }}
      </a>
    </p>
  </div>

  <img
    *ngIf="language === 'sk'"
    class="logo sk"
    src="https://static.doxxbet.sk/mobile/assets/images/icons/l_doxxbet-new-slogan-vertical-red@3x.png"
  />
  <img
    *ngIf="language !== 'sk' && location !== 'tza'"
    class="logo"
    src="https://static.doxxbet.sk/mobile/assets/images/icons/l_doxxbet-new-red@3x.png"
  />
  <img *ngIf="location === 'tza'" class="logo" src="assets/images/l_full.png" />

  <ng-container *ngIf="(isClientLoggedIn$ | async) === false">
    <ion-button
      class="sign-button"
      fill="outline"
      [routerLink]="'Register' | translateRoute"
    >
      {{ 'client_action_register' | translate }}</ion-button
    >
    <ion-button
      class="sign-button"
      fill="outline"
      [routerLink]="'Login' | translateRoute"
    >
      {{ 'client_action_log_in' | translate }}</ion-button
    >
  </ng-container>

  <div class="adult-notice">
    <i class="icon icon-ic_18plus"></i>
    <span>
      {{ 'resp_gaming_notice_0_text' | translate }}
    </span>
  </div>
  <div class="adult-notice" *ngIf="location !== 'tza'">
    <i class="icon icon-paragraph-in-circle"></i>
    <span>
      <h3>{{ 'corruption_notice_1' | translate }}</h3>
      {{ 'corruption_notice_2' | translate }}
      <a href="mailto:podnety@doxx.sk">{{
        'corruption_notice_3' | translate
      }}</a
      >{{ 'corruption_notice_4' | translate }}
    </span>
  </div>
</div>

<ng-template #footerText let-footerType>
  <div class="footer-text" [hidden]="_type !== footerType">
    <p [ngSwitch]="footerType">
      <strong
        *ngSwitchCase="'betting'"
        class="text-highlighted"
        [innerHTML]="replaceOssrLink('footer_title_betting' | translate)"
      >
      </strong>
      <strong *ngSwitchCase="'casino'" class="text-highlighted">
        {{ 'footer_title_casino' | translate }}
      </strong>
      <strong *ngSwitchCase="'rio'" class="text-highlighted">
        {{ 'footer_title_rio' | translate }}
      </strong>
    </p>
    <p class="label-style" [innerHtml]="'footer_text_1' | translateSpecial"></p>
    <p class="label-style" [innerHtml]="'footer_text_2' | translateSpecial"></p>
    <p class="label-style" [innerHtml]="'footer_text_3' | translateSpecial"></p>
  </div>
</ng-template>
