import {
  Component,
  OnInit,
  ElementRef,
  OnDestroy,
  HostBinding
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ticketSelectors, tickethistorySelectors } from '@store/selectors';
import { Ticket } from '@models/Ticket';
import { ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GlobalService } from '@shared/global.service';
import { translateRoute } from '@core/functions/translateRoute';
import { TicketHistoryListItem } from '@models/TicketHistory';
import { configuration } from '@configuration';

// Animation duratition in miliseconds
const TICKET_BUBBLE_ANIMATION_DURATION = 100;

@Component({
  selector: 'doxx-ticket-buble',
  templateUrl: './ticket-buble.component.html',
  styleUrls: ['./ticket-buble.component.scss']
})
export class TicketBubleComponent implements OnInit, OnDestroy {
  @HostBinding('hidden') get isHidden(): boolean {
    return this._hideBySection || !this.visible;
  }

  constructor(
    private _router: Router,
    private _store: Store<{ ticket: { ticket: Ticket } }>,
    private _elRef: ElementRef,
    private _globalService: GlobalService
  ) {
    this._routerSub = this._router.events
      .pipe(
        filter((e): e is ActivationEnd => e instanceof ActivationEnd),
        filter(e => (e.snapshot.routeConfig?.component?.name ? true : false))
      )
      .subscribe(ev => {
        if (ev.snapshot.data.ticketBubbleCanBeVisible) {
          this._hideBySection = false;
        } else {
          this._hideBySection = true;
        }
      });
    // show ticket buble on pages where is embeded offer
    window.addEventListener('message', ev => {
      if (ev.data.type === '[Offer] initialized') {
        this._hideBySection = false;
      }
    });
  }
  ticket$: Observable<Ticket>;
  newImage = 0;
  private _routerSub: Subscription;
  private _hideBySection: boolean;
  private _actualTicketFlag: string;
  visible = true;
  configuration = configuration;

  lastTicket: TicketHistoryListItem;

  showPlaceBetAnimation = false;
  placeBetMessage = '';

  /** On init Nacita hodnoty zo storu */
  ngOnInit(): void {
    this.ticket$ = this._store.select(ticketSelectors.selectTicket);
    this.ticket$.subscribe(data => {
      this._actualTicketFlag = data.TicketFlag;
    });
    this._store
      .select(tickethistorySelectors.selectLastTicketsTickets)
      .subscribe(tickets => {
        this.lastTicket = tickets[0];
      });
  }

  /** Prida animaciu pre zobrazenie bubliny */
  show(): void {
    const spinnerContainer = (
      this._elRef.nativeElement as HTMLElement
    ).querySelector('#ticket-bubble');
    if (spinnerContainer) {
      (
        spinnerContainer as HTMLElement
      ).style.animation = `bubbleIn ${TICKET_BUBBLE_ANIMATION_DURATION}ms forwards`;
    }
  }

  /** Pocka az dojde animacia na schovnie bubliny, az potom resovne promis value */
  hide(showPlaceBetAnimation?: boolean, message?: string): Promise<void> {
    if (this.showPlaceBetAnimation) {
      return Promise.reject('Animation in progress...');
    }
    let delay = 0;
    // used to always fetch new image when animation is played
    this.newImage = Math.round(Math.random() * 10000);

    // if buble was hiden by placeing the ticket
    if (showPlaceBetAnimation) {
      this.placeBetMessage = message;
      this.showPlaceBetAnimation = true;
      // setting ticket animation length
      delay = 1700;
    }

    setTimeout(() => {
      const spinnerContainer = (this._elRef.nativeElement as HTMLElement)
        .firstChild;
      (
        spinnerContainer as HTMLElement
      ).style.animation = `bubbleOut ${TICKET_BUBBLE_ANIMATION_DURATION}ms forwards`;
    }, delay);

    setTimeout(() => {
      this.showPlaceBetAnimation = false;
    }, delay + TICKET_BUBBLE_ANIMATION_DURATION);

    return new Promise(resolve =>
      setTimeout(resolve, delay + TICKET_BUBBLE_ANIMATION_DURATION)
    );
  }

  /** show ticket */
  showLastTicket(): void {
    this._router.navigate([
      translateRoute('BetslipHistoryDetail', {
        ticketID: this.lastTicket.ticketCode
      })
    ]);
  }

  /** On destroy unsunscribe router events */
  ngOnDestroy(): void {
    this._routerSub?.unsubscribe();
  }
}
