import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { oddsAnimations } from 'src/app/animations';
import { Odd } from '@models/Odd';
import { Store } from '@ngrx/store';
import { Ticket } from '@models/Ticket';
import { authSelectors, ticketSelectors } from '@store/selectors';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environment';
import { configuration } from '@configuration';
import { ticketActions } from '@store/actions';
import { Event } from '@models/Event';

@Component({
  selector: 'doxx-odd',
  templateUrl: './odd.component.html',
  styleUrls: ['./odd.component.scss'],
  animations: [...oddsAnimations]
})
export class OddComponent implements OnInit, OnDestroy {
  private _oddSubscription$: Subscription;
  oddType: 'NUMBERING' | 'NAMING' =
    configuration.settings.offer.oddsNaming.defaultNamingType;
  private _subscription$: Subscription;

  @Input() event: Event;
  @Input() odd: Odd;
  @Input() locked: boolean;
  @Input() showIcon = false;
  isOnTicket = false;
  missIconText: string;
  iconUrl: string;

  get isResultOdd(): boolean {
    return typeof this.odd.OddsWinning === 'string';
  }

  constructor(
    private _store: Store<{ ticket: { ticket: Ticket } }>,
    private _translate: TranslateService
  ) {}

  /** init list item */
  ngOnInit(): void {
    this._subscription$ = new Subscription();
    if (configuration.settings.offer.oddsNaming.editable) {
      this._subscription$.add(
        this._store.select(authSelectors.selectSettings).subscribe(settings => {
          this.oddType =
            settings?.setting?.offerViewType?.value === 'NUMBERING'
              ? 'NUMBERING'
              : 'NAMING';
        })
      );
    }

    if (this.odd && this.event && this.showIcon) {
      this.iconUrl = this._getIconURL(this.event);
    }
  }

  /** method is called when odd component is in view port */
  onViewportChange(isIn: boolean): void {
    if (isIn) {
      this.onInViewPort();
    } else {
      this.onOutViewPort();
    }
  }

  /**
   * zisti ID domaceho timu a vrati URL na ikonku + nastavi label ak by chybala ikona
   */
  private _getIconURL(event): string {
    const competitorID = this.odd.CompetitorID.toString();
    if (event.EventType !== 'special') {
      if (competitorID === event.HomeTeamBetradarID) {
        this.missIconText = this._translate.instant('odd_miss_icon_1');
        return (
          environment.staticUrl + '/teams/small/' + event.HomeTeamID + '.png'
        );
      } else {
        this.missIconText = this._translate.instant('odd_miss_icon_2');
        return (
          environment.staticUrl + '/teams/small/' + event.AwayTeamID + '.png'
        );
      }
    } else {
      for (const [key, value] of Object.entries(event.betradarTeams)) {
        if (key.split('_')[0] === competitorID) {
          return environment.staticUrl + '/teams/small/' + value + '.png';
        }
      }
    }
  }

  /**
   * urobi subscribe na tiket
   */
  onInViewPort(): void {
    this._oddSubscription$ = this._store
      .select(ticketSelectors.selectOddIds)
      .subscribe(oddsArr => {
        for (const oddId of oddsArr) {
          if (this.odd?.OddsID === oddId) {
            this.isOnTicket = true;
            return;
          }
        }
        this.isOnTicket = false;
      });
  }

  /** vycisti subscriptions */
  onOutViewPort(): void {
    if (this._oddSubscription$) {
      this._oddSubscription$.unsubscribe();
    }
  }

  /**
   * method addOddOnTicket
   */
  onAddOddOnTicket(oddID?): void {
    if (oddID) {
      // pridanie oddu z url
    } else if (!this.locked && !this.isResultOdd && this.odd.OddsRate > 1) {
      if (!this.isOnTicket) {
        this._store.dispatch(
          ticketActions.addOdds({
            odds: [
              {
                OddID: this.odd.OddsID,
                ...(this.event
                  ? {
                      EVT: this.event.EventID,
                      LiveBet: this.event.LiveBetting === 'Y',
                      SCORENEW: this.event.Score
                    }
                  : {})
              }
            ]
          })
        );
      } else {
        this._store.dispatch(
          ticketActions.removeOdds({ odds: [this.odd.OddsID] })
        );
      }
    }
  }

  /** unsubscribe all subscriptions */
  ngOnDestroy(): void {
    this._subscription$.unsubscribe();
    this._oddSubscription$?.unsubscribe();
  }
}
