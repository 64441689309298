import {
  Component,
  ComponentFactoryResolver,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[parseLabel]'
})
export class ParseLabelDirective {
  @Input() set parseLabel(text: string) {
    // parse text and get [key](val) from text
    const matches = [...text.matchAll(/(\[(.*?)\]\((.*?)\))/g)].reduce(
      (a, match) => ({
        ...a,
        [match[1]]: {
          val: match[2],
          key: match[3]
        }
      }),
      {}
    );
    // wait for binding templates map

    setTimeout(() => {
      // split text by special chars
      this._container.clear();
      text
        .split(/(\[.*?\]\(.*?\))/g)
        .map(label => {
          if (matches[label]) {
            return {
              template:
                this.parseLabelTemplateMap[matches[label].template] ||
                this.parseLabelTemplateMap.$default,
              text: matches[label].val,
              key: matches[label].key
            };
          } else {
            return {
              text: label
            };
          }
        })
        // create embeded view
        .forEach(val => {
          if (!val.template) {
            const componentFactory =
              this._componentFactoryResolver.resolveComponentFactory(
                TextComponent
              );
            this._container.createComponent<TextComponent>(
              componentFactory
            ).instance.text = val.text;
          } else {
            const view = this._container.createEmbeddedView(
              val.template as TemplateRef<any>
            );
            view.context.$implicit = val.text;
            view.context.key = val.key;
          }
        });
    }, 10);
  }
  @Input() parseLabelTemplateMap: any = {};

  constructor(
    private _container: ViewContainerRef,
    private _componentFactoryResolver: ComponentFactoryResolver
  ) {}
}

@Component({
  template: '{{text}}'
})
export class TextComponent {
  public text: string;
}
