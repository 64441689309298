import { Pipe, PipeTransform } from '@angular/core';
import { configuration } from '@configuration';
import { formatNumber } from '@core/functions/formatNumber';
import { environment } from '@environment';

@Pipe({
  name: 'rate'
})
export class RatePipe implements PipeTransform {
  /**
   * Pipe to return value with decimal pieces
   */
  transform(value: any): string {
    if (!value) {
      return null;
    }
    let rate = formatNumber(value, { decimalDigits: 2 });
    const locale = configuration.base.languages.find(
      lang => lang.code === environment.language
    ).locale;
    // Slovenské kurzy musia byť s bodkou (.)(.)
    if (locale === 'sk-SK') {
      rate = rate.replace(',', '.');
    }
    return rate;
  }
}
