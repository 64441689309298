import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLanding from '../landing.reducer';
import * as fromBanners from './landing-banner.reducer';

const selectLandingState = createFeatureSelector<fromLanding.State>(
  fromLanding.LANDING_FEATURE_KEY
);

const selectBannersState = createSelector(
  selectLandingState,
  (state: fromLanding.State) => state.banners
);

export const selectBettingBanners = createSelector(
  selectBannersState,
  (state: fromBanners.State) => state.betting
);

export const selectAvailableBettingBanners = createSelector(
  selectBannersState,
  (state: fromBanners.State) =>
    state.betting.filter(banner =>
      new Date().isBetween(
        new Date(banner.StartDateString),
        new Date(banner.EndDateString)
      )
    )
);

export const selectCasinoBanners = createSelector(
  selectBannersState,
  (state: fromBanners.State) => state.casino
);

export const selectAvailableCasinoBanners = createSelector(
  selectBannersState,
  (state: fromBanners.State) =>
    state.casino.filter(banner =>
      new Date().isBetween(
        new Date(banner.StartDateString),
        new Date(banner.EndDateString)
      )
    )
);

export const selectRioBanners = createSelector(
  selectBannersState,
  (state: fromBanners.State) => state.rio
);

export const selectAvailableRioBanners = createSelector(
  selectBannersState,
  (state: fromBanners.State) =>
    state.rio.filter(banner =>
      new Date().isBetween(
        new Date(banner.StartDateString),
        new Date(banner.EndDateString)
      )
    )
);
