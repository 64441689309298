import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { ProfileService } from '@core/services/profile/profile.service';
import { PresentMessageService } from 'src/app/services/present-message.service';

@Component({
  selector: 'doxx-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: [
    './image-picker.component.scss',
    '../../../shared/styles/text.scss'
  ]
})
export class ImagePickerComponent implements OnInit {
  constructor(
    private _profileService: ProfileService,
    private _presentMessageService: PresentMessageService
  ) {}

  get profileService(): ProfileService {
    return this._profileService;
  }

  get presentMessageService(): PresentMessageService {
    return this._presentMessageService;
  }
  @Input() allowedTypes: any;

  @Input() message: string;

  @Output() picture = new EventEmitter<any>();

  @ViewChild('fileInput') input: HTMLInputElement;

  url: string | ArrayBuffer;

  test: any;
  public image: any;

  /**
   * ngoninit
   */
  ngOnInit(): void {}

  /**
   * remove picutre
   */
  removePicture(): void {
    this.url = '';
    this.picture.emit();
    this.image = undefined;
    this.input.value = undefined;
  }

  /**
   * get file from input
   * chceck if format is correct
   * emit to parent
   * @param event from input
   */
  selectFile(event): void {
    this.url = '';

    if (!event.target.files[0] || event.target.files[0].length === 0) {
      console.log('You must select an image');
      return;
    }

    const mimeType = event.target.files[0].type;

    let found = false;
    if (this.allowedTypes) {
      this.allowedTypes.forEach(element => {
        if (mimeType === element) {
          found = true;
          return;
        }
      });
    }

    if (this.allowedTypes) {
      if (!found) {
        console.log('File is not supeorted');
        return;
      }
    }

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = _event => {
      this.url = reader.result;
      this.picture.emit(event.target.files[0]);
      this.image = event.target.files[0];
    };
  }
}
