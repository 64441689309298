import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '@environment';
import {
  ITicketHistoryResponse,
  ITicketStornoResponse,
  Ticket,
  ITicketBuybackInfoResponse
} from '@models/Ticket';
import { Winner } from '@models/Winners';
import { oneLine } from '@core/functions/oneLine';
import {
  TicketEditation,
  TicketHistoryListItem,
  TicketHistroyDetail
} from '@models/TicketHistory';

@Injectable({
  providedIn: 'root'
})
export class TickethistoryService {
  constructor(private _httpClient: HttpClient) {}

  /**
   * Universal get method
   */
  private _getRequest(url: string): Observable<any> {
    return this._httpClient
      .get<any>(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        withCredentials: true
      })
      .pipe(
        catchError(err => {
          if (err.status === 409) {
            return of(err.error);
          }
          return throwError(err);
        })
      );
  }

  /** load list */
  public getTicketHistory(options: {
    type: string;
    page?: number;
    size?: number;
    dateFrom?: string;
    dateTo?: string;
  }): Observable<{ ticketCount: number; tickets: TicketHistoryListItem[] }> {
    let url = `${environment.apiUrl}/centralticket/client/history/${options.type}`;
    if (
      options.dateFrom &&
      options.dateTo &&
      !isNaN(options.page) &&
      !isNaN(options.size)
    ) {
      url += `/${options.dateFrom}/${options.dateTo}/${options.page}/${options.size}`;
    }
    return this._getRequest(url).pipe(
      tap(data => {
        if (data.errors) {
          throw data.errors;
        }
      }),
      map(response => ({
        ...response,
        tickets: (response.tickets as any[]).map<TicketHistoryListItem>(
          ticket => ({
            ...ticket,
            overallBet: ticket.totalBet,
            overallPotWin: ticket.amountMoneyWin
          })
        )
      }))
    );
  }

  /**
   * Get user history tickets
   * @param type ticket types
   * @param page page of tickets list
   * @param size number of tickets
   * @param dateFrom date since query tickets
   * @param dateTo date until query tickets
   */
  public getTicketHistoryCount(
    type: string = 'last',
    page: number = 1,
    size: number = 15,
    dateFrom: Date,
    dateTo: Date
  ): Observable<any> {
    // console.log('getTicketHistory called');
    if (dateFrom && dateTo) {
      const url = oneLine`${environment.apiUrl}/client/tickets/history/count
        ?from=${dateFrom}
        &to=${dateTo}`;
      return this._getRequest(url);
    } else {
      const url = oneLine`${environment.apiUrl}/client/tickets/history/count`;
      return this._getRequest(url);
    }
  }

  /**
   * Checks if ticket is private and then toggle it to public
   */
  shareTicket(ticketCode: string): Observable<any> {
    const headers = new HttpHeaders();
    const url = oneLine`${environment.apiUrl}/web/client/ticket/share/${ticketCode}`;
    return this._httpClient
      .post<any>(
        url,
        {},
        {
          headers,
          withCredentials: true
        }
      )
      .pipe(
        catchError(err => {
          if (err.status === 409) {
            return of(err.error);
          }
          return throwError(err);
        }),
        map(resp => {
          return resp;
        })
      );
  }

  /**
   * Copy ticket
   * Exmaple: https://m-api-staging.doxxbet.sk/centralticket/clone/ticket/1012450011/949667418
   */
  public copyTicket(ticketId: number): Observable<Ticket> {
    const url = oneLine`${environment.apiUrl}/responsive/web/centralticket/clone/ticket/${ticketId}/`;
    return this._httpClient
      .get<Ticket>(url, {
        withCredentials: true
      })
      .pipe(
        map((resp: Ticket) => {
          return resp;
        })
      );
  }

  /**
   * Ticket Detail -c entral ticket
   */
  public getTicketDetailNEW(
    ticketCode: string
  ): Observable<TicketHistroyDetail> {
    const url = oneLine`${environment.apiUrl}/centralticket/client/history/detail/${ticketCode}`;

    return this._getRequest(url).pipe(
      tap(data => {
        if (data.data?.action?.status === 'nok') {
          throw data;
        }
        // remove sportboxGUID if empty, to prevent override guid from scanner
        if (!(data.sportBoxTicketGUID?.length > 0)) {
          delete data.sportBoxTicketGUID;
        }
      })
    );
  }

  /**
   * Ticket Detail
   */
  public getTicketOdds(ticketCode: string) {
    const url = oneLine`${environment.apiUrl}/web/client/ticketoddslist/ticketcode/${ticketCode}`;
    return this._getRequest(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  /** get edotation history */
  getTicketEditHistory(ticketCode: string): Observable<TicketEditation[]> {
    return this._getRequest(
      oneLine`${environment.apiUrl}/centralticket/client/history/editation/${ticketCode}`
    );
  }

  /**
   * Get one winner ticket
   * https://m-api-staging.doxxbet.sk/Loyality/InterestingTicket/Actual
   *
   * https://m-api-staging.doxxbet.sk/Loyality/InterestingTicket/Actual/{ticketCode}
   * https://m-api-staging.doxxbet.sk/Loyality/InterestingTicket/Actual/56FAA2197F
   */
  public getBettingWinnerTicket(ticketCode?: string): Observable<Winner> {
    if (ticketCode) {
      // get winning ticket by ticket Code
      const url = oneLine`${environment.apiUrl}/Loyality/InterestingTicket/Actual/${ticketCode}`;
      return this._getRequest(url);
    } else {
      // get actual winner ticket (landing)
      const url = oneLine`${environment.apiUrl}/Loyality/InterestingTicket/Actual`;
      return this._getRequest(url);
    }
  }

  /**
   * Get winner tickets
   * https://m-api-staging.doxxbet.sk/Loyality/InterestingTicket/List/{order}
   */
  public getBettingWinners(order: number): Observable<Array<Winner>> {
    const url = oneLine`${environment.apiUrl}/Loyality/InterestingTicket/List/${order}`;
    return this._getRequest(url).pipe(
      map(winners =>
        winners.map(winner => ({
          ...winner,
          Created: new Date(+winner.Created.match(/\((\d*)/)[1])
        }))
      )
    );
  }

  /**
   * Ticket Odds combi new
   */
  public getTicketOddsCombi(ticketId: string): Observable<TicketHistroyDetail> {
    const url = oneLine`${environment.apiUrl}/web/client/ticket/history/${ticketId}/detail/oddscombi`;
    return this._getRequest(url);
  }

  /**
   * ziska hodnotu odkupu pre tiket
   * @param ticketId id tiketu
   */
  public getBuybackValue(ticketId: string): Observable<number> {
    const url = oneLine`${environment.apiUrl}/web/client/ticket/${ticketId}/buybackvalue`;
    return this._getRequest(url);
  }

  /**
   * ziska hodnotu odkupu pre tiket spolu s casom, za ktory bude odkupeny (pre zobrazenie loadingu napr.)
   * @param ticketId id tiketu
   */
  public getBuybackValueWithTime(
    ticketId: number
  ): Observable<ITicketBuybackInfoResponse> {
    const url = oneLine`${environment.apiUrl}/web/client/ticket/${ticketId}/buybackvalueext`;
    return this._getRequest(url);
  }

  /**
   * ziska hodnotu odkupu pre tiket spolu s casom, za ktory bude odkupeny (pre zobrazenie loadingu napr.)
   * @param ticketId id tiketu
   */
  public getBuybackValueWithTimeTicketCode(
    ticketcode: string
  ): Observable<ITicketBuybackInfoResponse> {
    const url = oneLine`${environment.apiUrl}/web/client/ticketcode/${ticketcode}/buybackvalueext`;
    return this._getRequest(url);
  }

  /**
   * odkupi tiket
   * @param ticketId id tiketu
   * @param ticketCode kod tiketu
   * @param value hodnota tiketu, ak ma uzivatel zakliknute povolenie zmeny sumy, tak treba nastavit 0
   */
  public buybackTicket(
    ticketCode: string,
    value: number
  ): Observable<{
    AutoBuyBackValue: number;
    BuyBackDelayMs: number;
    BuyBackValue: number;
    IsBuyBack: boolean;
  }> {
    // const url = oneLine`${environment.apiUrl}/web/client/ticket/${ticketId}/executebuyback/${ticketCode}/value/${value}`;
    const url = oneLine`${environment.apiUrl}/web/client/ticketcode/${ticketCode}/executebuyback/value/${value}`;
    return this._getRequest(url).pipe(
      map(data => {
        if (data.ErrorMessage) {
          throw data;
        } else {
          return data;
        }
      })
    );
  }

  /**
   * auto odkup tiketu
   * @param ticketCode kod tiketu
   * @param value hodnota odkupu
   */
  public autoBuybackTicket(
    ticketCode: string,
    value: number
  ): Observable<{
    AutoBuyBackValue: number;
    BuyBackDelayMs: number;
    BuyBackValue: number;
    IsBuyBack: boolean;
  }> {
    const url = `${environment.apiUrl}/web/client/ticketcode/${ticketCode}/autobuyback/value/${value}`;
    return this._getRequest(url);
  }

  /**
   * Ticket Storno
   */
  public stornoTicket(ticketCode: string): Observable<ITicketStornoResponse> {
    // https://m-api-staging.doxxbet.sk/web/client/ticket/history/storno/E6EB11E8CE
    // return $http.post($rootScope.config.url + 'web/client/ticket/history/storno/' + tickedCode, null, {withCredentials: true});

    return this._httpClient
      .post<any>(
        oneLine`${environment.apiUrl}/web/client/ticket/history/storno/${ticketCode}`,
        null,
        {
          withCredentials: true
        }
      )
      .pipe(
        map((resp: ITicketStornoResponse) => {
          return resp;
        })
      );
  }

  /**
   * Crete deposit from won sportbox ticket
   */
  public depositCreate(
    sportboxGuid: string
  ): Observable<ITicketHistoryResponse> {
    const url = oneLine`${environment.apiUrl}/web/bp/credit/deposit/create/${sportboxGuid}`;
    return this._getRequest(url);
  }
}
