import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';
import { User } from '@models/User';
import { FavouriteChanceType } from '@models/FavouriteChanceType';

export const selectAuthState =
  createFeatureSelector<fromAuth.AuthState>('auth');

export const selectIsUserLoggedIn = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState) => {
    return state.user != null;
  }
);

export const selectAuthError = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState) => {
    return state.error;
  }
);

export const selectLoading = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState) => {
    return state.loading;
  }
);

export const selectLoggedUser = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState) => {
    return state.user;
  }
);

export const selectBalance = createSelector(selectLoggedUser, (user: User) => {
  return user?.balanceDecimal;
});

export const selectSettings = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): fromAuth.AuthState['settings'] => state.settings
);

export const selectShowFavLeague = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState) =>
    state?.settings?.setting?.offerFavouriteLeagueStatus?.value
);

export const selectShowFavChanceType = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState) =>
    state?.settings?.setting?.offerFavouriteChanceStatus?.value
);
