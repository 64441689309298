<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <doxx-main-header-back-button
        (click)="closeFreebetsModal()"
        [disabled]="true"
      >
      </doxx-main-header-back-button>
    </ion-buttons>
    <ion-title
      ><h1>{{ 'bonus_freebet_title_2' | translate }}</h1></ion-title
    >
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-radio-group
      [allowEmptySelection]="true"
      [(ngModel)]="selectedFreebet"
      (ngModelChange)="selectFreebet($event)"
    >
      <div
        *ngFor="
          let freebet of freebets$ | async;
          trackBy: 'FreebetID' | trackByProperty
        "
      >
        <ng-container *ngVar="{ expanded: false } as state">
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-item
                  lines="{{ state.expanded ? 'none' : 'inset' }}"
                  disabled="{{ freebet.IsApplicable ? 'false' : 'true' }}"
                >
                  <ion-label>{{ freebet.FreebetName }}</ion-label>
                  <ion-label class="amount" color="secondary">{{
                    freebet.Amount | formatNumberWithCurrency : {
                      decimalDigits:
                        configuration.base.defaultCurrency.numberDecimalPlaces
                    }
                  }}</ion-label>
                  <ion-radio
                    [value]="freebet.FreebetID"
                    slot="start"
                  ></ion-radio>
                </ion-item>
              </ion-col>
              <ion-col size="2">
                <ion-item
                  class="info"
                  lines="{{ state.expanded ? 'none' : 'inset' }}"
                  (click)="state.expanded = !state.expanded"
                >
                  <ion-icon name="information-circle-outline"></ion-icon>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-grid *ngIf="state.expanded" class="freebet-info">
            <ion-row>
              <ion-col class="title">
                {{ 'freebets_min_rate' | translate }}
              </ion-col>
              <ion-col size="3">
                {{ freebet.MinRateOdd }}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="title">
                {{ 'freebets_max_rate' | translate }}
              </ion-col>
              <ion-col size="3">
                {{ freebet.MaxRateOdd }}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="title">
                {{ 'freebets_max_ticket_rate' | translate }}
              </ion-col>
              <ion-col size="3">
                {{ freebet.MaxRateAll }}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="title">
                {{ 'freebets_min_ticket_rate' | translate }}
              </ion-col>
              <ion-col size="3">
                {{ freebet.MinRateAll }}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="title">
                {{ 'freebets_min_events_count' | translate }}
              </ion-col>
              <ion-col size="3">
                {{ freebet.MinOdds }}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="title">
                {{ 'freebets_live_odd_required' | translate }}
              </ion-col>
              <ion-col size="3">
                {{ (freebet.LiveRequired ? 'app_yes' : 'app_no') | translate }}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="title">
                {{ 'freebets_prematch_odd_required' | translate }}
              </ion-col>
              <ion-col size="3">
                {{
                  (freebet.PrematchRequired ? 'app_yes' : 'app_no') | translate
                }}
              </ion-col>
            </ion-row>
          </ion-grid>
        </ng-container>
      </div>
    </ion-radio-group>
  </ion-list>
</ion-content>
