<ion-tab-bar slot="bottom">
  <ng-container *ngFor="let tab of links; trackBy: 'content' | trackByProperty">
    <ion-tab-button
      [routerLink]="tab.isActive ? [] : (tab.content | translateRoute)"
      [queryParamsHandling]="tab.isActive ? 'preserve' : ''"
      [ngClass]="{ 'link-active': tab.isActive }"
    >
      <img
        *ngIf="(tab.activeIcon && !tab.isActive) || !tab.activeIcon"
        class="tabbar-icon active-color"
        src="{{ tab.icon }}"
      />
      <img
        *ngIf="tab.activeIcon && tab.isActive"
        class="tabbar-icon"
        src="{{ tab.activeIcon }}"
      />
      <ion-label
        [ngClass]="{
          'active-color': (tab.activeIcon && !tab.isActive) || !tab.activeIcon
        }"
        >{{ tab.title | translate }}</ion-label
      >
    </ion-tab-button>
  </ng-container>
</ion-tab-bar>
<ion-footer
  *ngIf="
    ({ and: ['responsibleGaming.modalAllowed'] } | available) &&
    (isUserLoggedIn$ | async) === true
  "
  class="ion-no-border"
>
  <div class="responsible-gaming-button">
    <span (click)="showResponsibleGamingModal()">{{
      'footer_nav_responsible_gaming' | translate
    }}</span>
  </div>
</ion-footer>
