import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
  switchMap,
  catchError,
  map,
  mergeMap,
  pluck,
  withLatestFrom,
  filter
} from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ProfileService } from '@core/services/profile/profile.service';
import { authSelectors } from '@store/selectors';
import { authActions } from '@store/actions';
import { PresentMessageService } from 'src/app/services/present-message.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

@Injectable()
export class AuthEffects {
  /**
   * LOGIN
   */
  authLogin$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.loginStart),
      mergeMap(action =>
        this._authService.login(action.email, action.password).pipe(
          switchMap(response =>
            this._profileService.getSettings().pipe(
              map(settings =>
                authActions.loginSuccess({
                  payload: response,
                  settings
                })
              )
            )
          ),
          catchError(err => {
            this._cookieService.delete('LoginTime');
            return of(
              authActions.loginFailure({
                error:
                  err.errors.validation[0].messages[0] ||
                  'Some error occured while logging'
              })
            );
          })
        )
      )
    )
  );

  autologin$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.autologinStart),
      mergeMap(action =>
        forkJoin({
          client: this._authService.getInfo(),
          settings: this._profileService.getSettings()
        }).pipe(
          map(({ client, settings }) => {
            if (client.login === '1') {
              return authActions.autologinSuccess({ user: client, settings });
            } else {
              this._cookieService.delete('LoginTime');
              return authActions.autologinFailure();
            }
          }),
          catchError(error => of(authActions.autologinFailure()))
        )
      )
    )
  );

  /**
   *  LOGOUT
   */
  logout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.logoutStart),
      switchMap(action => {
        return this._authService.logout().pipe(
          catchError(error => of('')),
          map(data => {
            this._cookieService.delete('LoginTime');
            return authActions.logoutSuccess();
          })
        );
      })
    )
  );

  /**
   * BALANCE REFRESH FAIL
   */
  balanceRefreshFail = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.balanceRefreshFailure),
      map(() => authActions.softLogoutStart())
    )
  );

  /**
   * BALANCE REFRESH
   */
  balanceRefresh$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.balanceRefreshStart),
      switchMap(() =>
        this._authService.getBalance().pipe(
          pluck('data', 'client'),
          map(client => {
            if (client.login === '1') {
              return authActions.balanceRefreshSuccess({ payload: client });
            } else {
              console.error('client.login !== "1"');
              this._cookieService.delete('LoginTime');
              return authActions.balanceRefreshFailure({
                error: 'Could not get balance'
              });
            }
          }),
          catchError(err =>
            of(
              authActions.balanceRefreshFailure({
                error: ''
              })
            )
          )
        )
      )
    )
  );

  /**
   * SOFT LOGOUT
   * pri necinnosti alebo vypadnuti session odhlasi pouzivatela z appky, a zobrazi allert message
   */
  softLogout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.softLogoutStart),
      withLatestFrom(this._store.select(authSelectors.selectLoggedUser)),
      map(([action, user]) => {
        this._presentMessage.presentCustomMessage(
          this._translateService.instant('errors_logout_ok'),
          'info-alert-blue',
          undefined,
          ['OK']
        );
        return authActions.softLogoutSuccess();
      })
    )
  );

  loadUserSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.loadSettings),
      switchMap(action =>
        this._profileService.getSettings().pipe(
          map(
            settings => authActions.loadSettingsSuccess({ settings }),
            catchError(error => of(authActions.loadSettingsFailure({ error })))
          )
        )
      )
    )
  );

  constructor(
    private _actions$: Actions,
    private _authService: AuthService,
    private _cookieService: CookieService,
    private _profileService: ProfileService,
    private _presentMessage: PresentMessageService,
    private _translateService: TranslateService,
    private _store: Store
  ) {}
}
