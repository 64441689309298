import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TicketService } from 'src/app/pages/ticket/ticket.service';
import { PresentMessageService } from 'src/app/services/present-message.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { translateRoute } from '@core/functions/translateRoute';
import { ITicketHistoryTicket } from '@models/Ticket';
import { authSelectors } from '@store/selectors';
import { BetslipscannerService } from './betslipscanner.service';
import { FormBuilder, Validators } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { tickethistoryActions } from '@store/actions';

@Component({
  selector: 'doxx-sportbox-ticket-verify',
  templateUrl: './sportbox-ticket-verify.component.html',
  styleUrls: [
    './sportbox-ticket-verify.component.scss',
    './../../styles/landing-section-dark.scss'
  ]
})
export class SportboxTicketVerifyComponent implements OnDestroy, OnInit {
  constructor(
    private _ticketService: TicketService,
    private _presentMessageService: PresentMessageService,
    private _translateService: TranslateService,
    private _store: Store,
    private _router: Router,
    public betslipscannerService: BetslipscannerService,
    private _fb: FormBuilder
  ) {}
  private _subscriptions$ = new Subscription();
  @Input() style: 'landing' | 'classic' | 'wallet' = 'classic';
  @ViewChildren('input') inputs: QueryList<IonInput>;

  ticketGuidForm = this._fb.group({
    0: ['', [Validators.required, Validators.minLength(4)]],
    1: ['', [Validators.required, Validators.minLength(4)]],
    2: ['', [Validators.required, Validators.minLength(4)]],
    3: ['', [Validators.required, Validators.minLength(4)]]
  });

  isLoggedIn = false;

  /**
   * Called when input changed
   * Sets focus to next component
   */
  handleInput(currentInput: IonInput, currentIndex: number) {
    if (
      currentInput.value.toString().length === 4 &&
      currentIndex < this.inputs.length - 1 &&
      this.ticketGuidForm.controls[currentIndex + 1].invalid
    ) {
      this.inputs.toArray()[currentIndex + 1].setFocus();
    }
  }

  /**
   * Clear all input values when component is loaded
   */
  ngOnInit(): void {
    const isClientLoggedIn$ = this._store.select(
      authSelectors.selectIsUserLoggedIn
    );

    this._subscriptions$.add(
      isClientLoggedIn$.subscribe(isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
      })
    );
  }

  /**
   * Submit button event
   */
  verifyCode(sportboxTicketGuid: string): Promise<void> {
    sportboxTicketGuid
      .match(/(.{4})/g)
      .slice(0, 4)
      .forEach((element, index) => {
        this.ticketGuidForm.controls[index].patchValue(element);
      });
    return this.manualTicketInput();
  }

  /**
   * Submit manual ticket input
   */
  manualTicketInput(): Promise<void> {
    this.ticketGuidForm.markAllAsTouched();
    this.ticketGuidForm.updateValueAndValidity();
    if (this.ticketGuidForm.invalid) {
      return this._presentMessageService
        .presentCustomMessage(
          this._translateService.instant('sportbox_ticket_invalid'),
          'error-alert'
        )
        .then();
    } else {
      return this.ticketCodeVerified(
        Object.values(this.ticketGuidForm.value).join('').toUpperCase()
      );
    }
  }

  /**
   * Called after code was verified
   */
  ticketCodeVerified(sportboxTicketGuid: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this._ticketService.guidTicketCheck(sportboxTicketGuid).subscribe(
        ticketData => {
          if (ticketData.data.creditCheck.errorCode === 'wrong_guid') {
            return this._presentMessageService
              .presentCustomMessage(
                ticketData.data.creditCheck.message,
                'error-alert'
              )
              .then();
          } else {
            if (ticketData.data.creditCheck.ticket) {
              const ticket = ticketData.data.ticketHistory.tickets[0];
              // zobrazi prevod button len ak je parameter sportboxTicketGuid
              if (ticketData.data.creditCheck.transfer) {
                ticket.sportboxTicketGuid = sportboxTicketGuid;
              }
              this._showTicket(ticket);
              this.ticketGuidForm.reset();
            } else if (
              ticketData.data.creditCheck.ticket === false &&
              ticketData.data.creditCheck.success &&
              ticketData.data.creditCheck.transfer
            ) {
              this.betslipscannerService.credit = {
                amount: ticketData.data.creditCheck.amount,
                code: sportboxTicketGuid
              };
              this._router.navigate([
                translateRoute('BetslipScanner', {
                  _intern_subpage: 'credit',
                  _intern_guid: sportboxTicketGuid
                })
              ]);
              this.ticketGuidForm.reset();
            }
            resolve();
          }
        },
        error => {
          this._presentMessageService
            .presentCustomMessage(
              this._translateService.instant(
                error.error.errors.payment.message
              ),
              'error-alert'
            )
            .finally(() => reject());
          this.ticketGuidForm.reset();
        }
      );
    });
  }

  /**
   * show ticket
   * TODO show ticket detail
   */
  private _showTicket(ticket: ITicketHistoryTicket): void {
    this._store.dispatch(
      tickethistoryActions.loadTicketDetailSuccess({
        ticket: {
          ticketCode: ticket.ticketCode,
          sportBoxTicketGUID: ticket.sportboxTicketGuid
        }
      })
    );
    this._router.navigate([
      translateRoute('BetslipHistoryDetail', {
        ticketID: ticket.ticketCode
      })
    ]);
  }

  /**
   * On destroy unsubscribe
   */
  ngOnDestroy(): void {
    this._subscriptions$.unsubscribe();
  }
}
