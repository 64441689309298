import * as fromDetail from './detail.reducer';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ParamGroup } from '@models/ParamGroup';
import { EventChanceType } from '@models/EventChanceType';
import { Odd } from '@models/Odd';
import { ChanceTypeGroup } from '@models/ChanceTypeGroup';
import { Event } from '@models/Event';
import { EntityState } from '@ngrx/entity';
import { visibility } from '@models/Visibility';

/* EVENT DETAIL SELECTORS */
export const selectEventDetail = createFeatureSelector(
  fromDetail.EVENT_DETAIL_STATE_KEY
);

export const selectEvent = createSelector(
  selectEventDetail,
  (state: fromDetail.EventDetailState): Event => state.event
);

export const selectChanceTypeGroups = createSelector(
  selectEventDetail,
  (state: fromDetail.EventDetailState): ChanceTypeGroup[] => {
    return state.chanceTypeGroups;
  }
);

export const selectOdds = createSelector(
  selectEventDetail,
  (state: fromDetail.EventDetailState) => state.odds
);

export const selectEventChanceTypes = createSelector(
  selectEventDetail,
  (state: fromDetail.EventDetailState) => state.eventChanceTypes
);

export const selectSelectedChancetypeGroupID = createSelector(
  selectEventDetail,
  (state: fromDetail.EventDetailState) => state.selectedChancetypeGroupID
);

export const selectChanceTypeGroupByID = (id: string) =>
  createSelector(selectEventDetail, (state: fromDetail.EventDetailState) =>
    state.chanceTypeGroups.find(grp => grp.ChanceTypeGroupID === id)
  );

export const selectIsSelectedGroupVisible = createSelector(
  selectEventDetail,
  (state: fromDetail.EventDetailState) => {
    let selectedGroup: ChanceTypeGroup = null;
    if (
      !['all', 'active', 'locked'].includes(state.selectedChancetypeGroupID)
    ) {
      selectedGroup = state.chanceTypeGroups.find(grp => {
        return grp.ChanceTypeGroupID === state.selectedChancetypeGroupID;
      });
    }
    for (const ectID in state.eventChanceTypes.entities) {
      if (
        Object.prototype.hasOwnProperty.call(
          state.eventChanceTypes.entities,
          ectID
        )
      ) {
        const ect: EventChanceType = state.eventChanceTypes.entities[ectID];
        if (
          !selectedGroup ||
          selectedGroup.ChanceTypes.includes(ect.ChanceTypeID)
        ) {
          if (ect.Visibility > visibility.HIDE) {
            for (const oddID in state.odds.entities) {
              if (
                Object.prototype.hasOwnProperty.call(state.odds.entities, oddID)
              ) {
                const odd: Odd = state.odds.entities[oddID];
                if (
                  odd.EventChanceTypeID === ect.EventChanceTypeID &&
                  odd.Visibility > visibility.HIDE
                ) {
                  return true;
                }
              }
            }
          }
        }
      }
    }
    return false;
  }
);

export type ParamGroupWrapper = {
  paramGroup: ParamGroup;
  eventChanceTypes: { [id: number]: EventChanceType };
  order: number;
};

/**
 * @param chanceTypeGroup Id of a chance type group ect. for Popular => ['uf:1', 'uf:11', ...]
 */
export const selectParamGroups = (chanceTypeGroup: ChanceTypeGroup) =>
  createSelector(
    selectEventDetail,
    (state: fromDetail.EventDetailState): ParamGroupWrapper[] => {
      let filteredEventChacneTypes = fromDetail.eventChanceTypeAdapter
        .getSelectors()
        .selectAll(state.eventChanceTypes);
      if (
        !['all', 'active', 'locked'].includes(
          chanceTypeGroup?.ChanceTypeGroupID
        )
      ) {
        filteredEventChacneTypes = filteredEventChacneTypes.filter(ECT =>
          chanceTypeGroup.ChanceTypes.includes(ECT.ChanceTypeID)
        );
      }

      // create dictionary of param group wrappers
      const paramGroupWrappers: { [id: string]: ParamGroupWrapper } = {};

      filteredEventChacneTypes.forEach((originalECT: EventChanceType) => {
        if (originalECT.ParamGroupID) {
          // Param group exists for this EventChanceType
          const existingParamGroupWrapper = paramGroupWrappers[
            'G_' + originalECT.ParamGroupID
          ] || { order: Number.MAX_SAFE_INTEGER, eventChanceTypes: {} };

          const paramGroup: ParamGroupWrapper = {
            paramGroup: state.chanceTypeParamGroups.find(
              pg => +pg.ParamGroupID === originalECT.ParamGroupID
            ),
            eventChanceTypes: {
              ...existingParamGroupWrapper.eventChanceTypes,
              [originalECT.EventChanceTypeID]: originalECT
            },
            order: Math.min(existingParamGroupWrapper.order, originalECT.Order)
          };
          paramGroupWrappers['G_' + originalECT.ParamGroupID] = paramGroup;
        } else {
          // Param group does not exists for this EventChanceType
          // We will create new fake one
          paramGroupWrappers['FG_' + originalECT.EventChanceTypeID] = {
            paramGroup: {
              ParamGroupID: 'FG_' + originalECT.EventChanceTypeID,
              ParamGroupName: originalECT.ChanceTypeName,
              ParamGroupTooltip: originalECT.ToolTip
            },
            eventChanceTypes: [originalECT],
            order: originalECT.Order
          };
        }
      });
      return Object.values(paramGroupWrappers).sort(
        (a, b) => a.order - b.order
      );
    }
  );

export const selectOddsByEventChanceTypeIds = (ids: number[]) =>
  createSelector(selectOdds, (state: EntityState<Odd>): Odd[] => {
    return fromDetail.eventDetailOddAdapter
      .getSelectors()
      .selectAll(state)
      .filter(
        odd =>
          ids.includes(odd.EventChanceTypeID) &&
          (odd.Visibility === visibility.SHOW ||
            odd.Visibility === visibility.LOCK)
      )
      .sort((a, b) => +a.TipOrder - +b.TipOrder);
  });

export const selectEventChanceTypesByIds = (ids: number[]) =>
  createSelector(
    selectEventChanceTypes,
    (state: EntityState<EventChanceType>) =>
      fromDetail.eventChanceTypeAdapter
        .getSelectors()
        .selectAll(state)
        .filter(ect => ids.includes(ect.EventChanceTypeID))
  );

export const selectPlayers = createSelector(
  selectEventDetail,
  (state: fromDetail.EventDetailState) => {
    const playerList = [];
    if (state.odds?.entities) {
      Object.values(state.odds.entities).forEach(odd => {
        if (odd.BetradarPlayerID) {
          playerList.push({
            id: odd.BetradarPlayerID,
            name: state.labels['PL_' + odd.PlayerID].Name,
            competitorID: Object.entries(state.event.betradarTeams).find(
              ([k, v]) => new RegExp(`^${odd.CompetitorID}_[1|2]0$`).test(k)
            )?.[1]
          });
        }
      });
    }

    return playerList;
  }
);
