import { Component, Input, OnInit, ViewChild, Output } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { formatDate } from '@core/functions/formatDate';
import {
  MatDatepicker,
  MatDatepickerInputEvent
} from '@angular/material/datepicker';

export interface ToolbarListDateFilterOption {
  translation: string;
  value: string;
}

export interface DateFilterConfig {
  defaultDate: ToolbarListDateFilterOption;
  filterOptions?: ToolbarListDateFilterOption[];
  minDate?: string;
  maxDate?: string;
}

@Component({
  selector: 'doxx-toolbar-list-filter',
  templateUrl: './toolbar-list-filter.component.html',
  styleUrls: ['./toolbar-list-filter.component.scss'],
  providers: [DoxxPipesModule]
})
export class ToolbarListFilterComponent implements OnInit {
  customPickerOptions: any;
  selectedDate: ToolbarListDateFilterOption;
  @Input() config: DateFilterConfig;
  @Output() dateSelected = new Subject<ToolbarListDateFilterOption>();
  @ViewChild(MatDatepicker) datePicker: MatDatepicker<Date>;
  @Input() active = false;

  private _defaultValue: ToolbarListDateFilterOption;
  private _minDate: Date;
  private _maxDate: Date;

  constructor(
    private _actionSheetController: ActionSheetController,
    private _route: ActivatedRoute,
    private _translateService: TranslateService
  ) {}

  /** set context for filter */
  setValue(value: string): void {
    this._selectDate(value);
  }

  /**
   * Use initial value for filter, chceck for data in url to get value about the date
   */
  ngOnInit(): void {
    this._defaultValue = this.config.defaultDate;
    this._maxDate = new Date(this.config.maxDate);
    this._minDate = new Date(this.config.minDate);

    // get date from url
    const { date } = this._route.snapshot.queryParams;
    // this._selectDate(date);
  }

  /**
   * on date picked
   */
  onDatePickerChange(event: MatDatepickerInputEvent<Date>) {
    this.selectedDate = {
      translation: formatDate(event.value, 'MMDD'),
      value: event.value.toLocaleISODateString().replaceAll('-', '')
    };
    this.dateSelected.next(this.selectedDate);
  }

  /**
   * Open filter dialog
   */
  onShowActionSheet(): void {
    this._actionSheetController
      .create({
        mode: 'ios',
        header: 'Filter',
        buttons: [
          ...this.config.filterOptions.map(button => {
            return {
              text: this._translateService.instant(button.translation),
              handler: () => {
                this.selectedDate = button;
                this.dateSelected.next(button);
              }
            };
          }),
          {
            text: this._translateService.instant('analyses_filter_calendar'),
            handler: () => {
              this.datePicker.open();
            }
          },
          {
            text: this._translateService.instant('analyses_filter_close'),
            role: 'cancel',
            handler: () => {}
          }
        ]
      })
      .then(actionSheet => actionSheet.present());
  }

  /**
   * Set date value passed as parameter as a value of filter, if passed date value is not valid, default
   * value will be used
   * @param value date in format YYYYMMDD or string reprezentation of common date values TD | YT | L3H | ...
   */
  private _selectDate(value: string): void {
    if (value) {
      const commonOption = this.config.filterOptions.find(
        button => button.value === value
      );
      // if value is common date such as TD | L3H ... and this value is filter buttons
      if (commonOption) {
        this.selectedDate = commonOption;

        return;
      } else {
        const date = new Date(
          value.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
        );
        if (date.isValid()) {
          if (
            !(
              (this._minDate && this._minDate.isAfter(date)) ||
              (this._maxDate && this._maxDate.isBefore(date))
            )
          ) {
            this.selectedDate = {
              translation: formatDate(date, 'MMDD'),
              value: date.toISODateString().replaceAll('-', '')
            };

            return;
          }
        }
      }
    } else {
      this.selectedDate = this._defaultValue;
    }
  }
}
