import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BuybackModalComponent } from './buyback-modal/buyback-modal.component';
import { AutoBuybackModalComponent } from './autobuyback-modal/autobuyback-modal.component';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { TicketeventsModule } from '../ticketevents/ticketevents.module';
import {
  AutoBuybackModalTriggerDirective,
  BuybackDirective,
  BuybackModalTriggerDirective
} from './buyback.directive';

@NgModule({
  declarations: [
    BuybackModalComponent,
    AutoBuybackModalComponent,
    BuybackDirective,
    BuybackModalTriggerDirective,
    AutoBuybackModalTriggerDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    IonicModule,
    DoxxPipesModule,
    TicketeventsModule
  ],
  exports: [
    BuybackDirective,
    BuybackModalTriggerDirective,
    AutoBuybackModalTriggerDirective
  ]
})
export class BuybackModule {}
