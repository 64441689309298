import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addLeadingZero'
})
export class AddLeadingZeroPipe implements PipeTransform {
  /**
   * Adds leading zero to number, if number is less than 10
   */
  transform(num: number): string {
    if (typeof num === 'number' && num < 10) {
      return '0' + num;
    }
    return num && num.toString();
  }
}
