import { createAction, props } from '@ngrx/store';
import { Label } from '@models/Label';
import { Region } from '@models/Region';
import { League } from '@models/League';

export const loadVirtualGamesList = createAction(
  '[Landing] Load Virtual Games List'
);

export const loadVirtualGamesListSuccess = createAction(
  '[Landing] Load Virtual Games List Success',
  props<{ regions: Region[]; leagues: League[] }>()
);

export const loadVirtualGamesListFailure = createAction(
  '[Landing] Load Virtual Games List Failure',
  props<{ err: any }>()
);
