import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  HostBinding
} from '@angular/core';
import { IonSearchbar } from '@ionic/angular';
import { Subscription, forkJoin, of } from 'rxjs';
import { map, switchMap, tap, filter, catchError, first } from 'rxjs/operators';
import { OfferService } from '@core/services/offer/offer.service';
import { Router, NavigationEnd } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { Store } from '@ngrx/store';
import { authSelectors } from '@store/selectors';
import { User } from '@models/User';
import { CasinoGame } from '@models/CasinoGame';
import { GameCategory } from '@models/GameCategory';
import { CasinoService } from '@core/services/casino/casino.service';
import { TicketBubleService } from '@core/services/ticket-buble/ticket-buble.service';
import { BonusCategory } from '@models/Bonus';
import { isAvailable } from '@core/functions/isAvailable';
import { configuration } from '@configuration';

const MIN_SEARCH_CHARACTERS = 3;

@Component({
  selector: 'doxx-main-header-search-bar',
  templateUrl: './main-header-search-bar.component.html',
  styleUrls: ['./main-header-search-bar.component.scss'],
  animations: [
    trigger('backdrop', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))])
    ]),
    trigger('searchbar', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)', zIndex: 9 }),
        animate('100ms', style({ transform: 'translateY(0)', zIndex: 2001 }))
      ]),
      transition(':leave', [
        animate('100ms', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ]
})
export class MainHeaderSearchBarComponent implements AfterViewInit, OnDestroy {
  @HostBinding('@backdrop')
  private _subscription$ = new Subscription();
  searchPhrase = '';
  searchRresults: {
    sportsBetting?: any;
    casino?: any;
    rio?: any;
    bonuses?: any;
  } = {};
  @Output() closeSeachBar = new EventEmitter<void>();
  @ViewChild(IonSearchbar) private _searchBarInput: IonSearchbar;

  user: User;

  constructor(
    private _offerService: OfferService,
    private _elRef: ElementRef,
    private _router: Router,
    private _store: Store,
    private _casinoService: CasinoService,
    private _ticketBubleService: TicketBubleService
  ) {
    this._subscription$.add(
      this._store
        .select(authSelectors.selectLoggedUser)
        .subscribe(user => (this.user = user))
    );
  }

  display = {
    casino: true,
    rio: true,
    bonuses: true,
    betting: true,
    showButton: 'more'
  };

  /**
   * functionality of button zobrazit vsetko and zobrazit menej
   * @param section secton to turn on
   * @returns void
   */
  showMore(section: string): void {
    if (section === 'all') {
      this.display = {
        casino: true,
        rio: true,
        bonuses: true,
        betting: true,
        showButton: 'more'
      };
      return;
    }
    this.display = {
      casino: section === 'casino' ? true : false,
      rio: section === 'rio' ? true : false,
      bonuses: section === 'bonuses' ? true : false,
      betting: section === 'betting' ? true : false,
      showButton: 'less'
    };
  }

  /**
   * Subscribe on search input changes
   */
  ngAfterViewInit(): void {
    this._ticketBubleService.setInvisibleTicketBuble();
    this._searchBarInput.debounce = 350;
    this._subscription$.add(
      this._searchBarInput.ionChange
        .pipe(
          map((event: CustomEvent): string => event.detail.value),
          filter(value => typeof value === 'string'),
          tap(value => {
            this.searchPhrase = value;
            if (value.length < MIN_SEARCH_CHARACTERS) {
              this.searchRresults = {};
            }
          }),
          filter(value => value.length >= MIN_SEARCH_CHARACTERS),
          switchMap(value =>
            forkJoin({
              sportsBetting: isAvailable({ or: ['offer', 'offerLive'] })
                ? this._offerService.searchEvents(value).pipe(
                    map(this._handleOfferSearchResult),
                    catchError(err => of({}))
                  )
                : of({}),
              searchAll: this._offerService.searchAllEvents(value).pipe(
                first(),
                map(games => games.Response),
                catchError(err => of({}))
              )
            })
          )
        )
        .subscribe(results => {
          this.showMore('all');
          this.searchRresults = {
            sportsBetting: results.sportsBetting,
            casino: this._handleCasinoSearchResults(
              results.searchAll.CasinoGames
            ),
            rio: this._handleCasinoSearchResults(results.searchAll.RioGames),
            bonuses: this._handleBonusResults(results.searchAll.Content)
          };
        })
    );

    // Hide search bar when user navigates to other page
    this._subscription$.add(
      this._router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(event => this.closeSeachBar.emit())
    );

    const el: HTMLElement = this._elRef.nativeElement;
    el.style.height = `${el.clientHeight - el.offsetTop}px`;
  }

  /** Show casino game play options */
  presentCasinoPlayOptions(
    casinoGameName: string,
    casinoType: string,
    categoryID?: number
  ) {
    this._casinoService.presentPlayOptions(
      casinoGameName,
      casinoType,
      categoryID
    );
  }

  /** Otvori hru pre zabavu */
  openFunGame(
    game: CasinoGame,
    casinoType: 'rio-online' | 'kasino-online'
  ): void {
    this._casinoService.openFunGame(game.SystemName, casinoType);
  }

  /**
   * Clear all subscriptions
   */
  ngOnDestroy(): void {
    this._ticketBubleService.setVisibleTicketBuble();
    this._subscription$.unsubscribe();
  }

  /** This method is called when user clear the search input */
  clear(): void {
    this.searchPhrase = '';
    this.searchRresults = {};
  }

  /**
   * Emits event to parent componet to close searc bar
   */
  closeSearchBar(): void {
    this.closeSeachBar.emit();
  }

  private _handleOfferSearchResult(data: any): any {
    const searchRresults = {};
    // creates a tree of sports, leagues, regions and events
    data.Items.forEach(item => {
      if (
        item.Typ === 'E' &&
        item.LiveBetting === 'Y' &&
        !isAvailable('offerLive')
      ) {
        return;
      }
      if (item.LiveBetting !== 'Y' && !isAvailable('offer')) {
        return;
      }

      const sport = data.Labels['SP_' + item.SportID];
      searchRresults['SP_' + item.SportID] = sport;

      sport.regions = sport.regions || {};
      const region = data.Labels['RE_' + item.RegionID];
      searchRresults['SP_' + item.SportID].regions['RE_' + item.RegionID] =
        region;

      if (item.Typ === 'R') {
        return;
      }

      region.leagues = region.leagues || {};
      const league = data.Labels['LC_' + item.LeagueCupID];
      searchRresults['SP_' + item.SportID].regions[
        'RE_' + item.RegionID
      ].leagues['LC_' + item.LeagueCupID] = league;

      if (item.Typ === 'L') {
        return;
      }

      league.events = league.events || {};
      league.events['E_' + item.EventID] = item;
    });
    return searchRresults;
  }

  private _handleCasinoSearchResults(data: GameCategory[]): any {
    if (configuration.casino.allowed) {
      return data
        .map(category => ({
          ...category,
          Games: category.Games.filter(game =>
            game.DisplayName.match(new RegExp(this.searchPhrase, 'gi'))
          )
        }))
        .filter(category => category.Games.length > 0);
    } else {
      return [];
    }
  }

  private _handleBonusResults(data: BonusCategory[]): any {
    return data
      .map(category => ({
        ...category
      }))
      .filter(category => {
        if (!configuration.casino.allowed && category.Category === 'casino') {
          return false;
        }
        return (
          (category.ReslutType === 'bonus' &&
            category.Category === 'sportbook' &&
            isAvailable('bonuses.allowedBetting')) ||
          (category.ReslutType === 'bonus' &&
            category.Category === 'casino' &&
            isAvailable('bonuses.allowedCasino')) ||
          (category.ReslutType === 'news' && isAvailable('news'))
        );
      });
  }
}
