<ng-container *ngIf="buyBackValue$ | async as bbValue">
  <div
    class="green-text icon icon-ic_money-in-hnad top-icon"
    [ngClass]="{
      disabled: bbValue.BuyBackValue === 0
    }"
  ></div>
  <ion-row class="ion-text-center bold modal-header">
    <ion-col class="bold" *ngIf="bbValue.BuyBackValue !== 0">
      <span> {{ bbValue.AmountMoney | formatNumberWithCurrency: { decimalDigits: configuration.base.defaultCurrency.numberDecimalPlaces} }} / </span>
      <span class="green-text">
        {{ bbValue.AmountMoneyWin | formatNumberWithCurrency: { decimalDigits: configuration.base.defaultCurrency.numberDecimalPlaces} }}
      </span>
    </ion-col>
    <ion-col class="bold blocked" *ngIf="bbValue.BuyBackValue === 0"
      ><span> {{ 'ticket_buyback_ticketbuyback' | translate }}</span></ion-col
    >
    <div
      (click)="closeBuybackModal(false)"
      class="icon-ic_close btn-close"
    ></div>
  </ion-row>
  <div
    class="bordered modal-content"
    *ngIf="bbValue.BuyBackValue !== 0 && ticket"
  >
    <doxx-ticketevents
      [ticket]="ticket"
      [style]="'buyback'"
    ></doxx-ticketevents>
  </div>
  <ion-list mode="ios" lines="none" *ngIf="bbValue.BuyBackValue !== 0">
    <ion-item class="accept-checkbox">
      <ion-checkbox
        color="secondary"
        [(ngModel)]="acceptChanges"
      ></ion-checkbox>
      <ion-label> {{ 'ticket_buyback_acceptchanges' | translate }}</ion-label>
    </ion-item>
  </ion-list>
  <div
    class="bordered modal-content blocked"
    *ngIf="bbValue.BuyBackValue === 0"
  >
    <span>{{ 'buyback_na_msg' | translate }}</span>
  </div>

  <ion-footer *ngIf="bbValue.BuyBackValue !== 0">
    <ion-button
      class="buy-back-btn"
      expand="block"
      (click)="executeBuyback(bbValue.BuyBackValue)"
      [disabled]="isBuybackActive"
    >
      <span class="capitalize-first">
        {{ 'ticket_buyback_sellticketfor' | translate }}
      </span>
      &nbsp;
      {{ bbValue.BuyBackValue | formatNumberWithCurrency: { decimalDigits: configuration.base.defaultCurrency.numberDecimalPlaces} }}
    </ion-button>
  </ion-footer>
</ng-container>
