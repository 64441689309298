<div
  class="ticket-list-item is-anchor flex items-center w-full justify-start border-b border-gray-100"
  *ngVar="ticket | statusTransform as status"
>
  <div
    *ngIf="isStoredTicket"
    size="3"
    class="stored font-bold flex flex-col px-1 space-y-1"
  >
    <span class="ticket-code-label"
      >{{ 'ticket_booking_ticketcode' | translate }}
    </span>
    <span class="ticket-code">
      {{ ticket.ticketCode }}
    </span>
  </div>
  <div *ngIf="!isStoredTicket" class="font-bold w-15 flex-none text-center">
    <div
      class="ticket-status-icon"
      [ngClass]="[status.icon, status.status]"
    ></div>
  </div>
  <div class="flex flex-col justify-center space-y-1 h-full truncate">
    <div class="flex">
      <span class="darker-bold mr-1 truncate"
        >{{ ticket.ticketDate | formatDate : 'MMMMDD' }},</span
      >{{ ticket.ticketDate | formatDate : 'YYYYhhmm' }}
    </div>
    <div class="flex">
      <span class="truncate"> {{ 'app_bet' | translate }}: </span>
      <span class="darker-bold mx-1 flex-none">
        {{ ticket.overallBet | formatNumberWithCurrency: { decimalDigits: configuration.base.defaultCurrency.numberDecimalPlaces} }}
      </span>

      <ng-container [ngSwitch]="status.status">
        <ng-container
          *ngSwitchCase="
            status.status === 'won' || status.status === 'buyback'
              ? status.status
              : ''
          "
        >
          <span class="truncate">
            {{ 'history_won' | translate }}
          </span>
          &nbsp;
          <span class="value bold" [ngClass]="status.status">{{
            ticket.fiscalOverallPotWin | formatNumberWithCurrency : { decimalDigits: configuration.base.defaultCurrency.numberDecimalPlaces}
          }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'lost'">
          <span class="truncate">
            {{ 'ticket_eventual_win_short' | translate }}:
          </span>
          &nbsp;
          <span class="value bold" [ngClass]="status.status">{{
            ticket.fiscalOverallPotWin | formatNumberWithCurrency: { decimalDigits: configuration.base.defaultCurrency.numberDecimalPlaces}
          }}</span>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <span class="truncate">
            {{ 'ticket_eventual_win_short' | translate }}:
          </span>
          &nbsp;
          <span
            class="value bold"
            [ngClass]="status.status === 'canceled' ? 'neutral' : status.status"
            >{{ ticket.fiscalOverallPotWin | formatNumberWithCurrency : { decimalDigits: configuration.base.defaultCurrency.numberDecimalPlaces} }}</span
          >
        </ng-container>
      </ng-container>
    </div>
    <div *ngIf="buybackValue > 0 && ('betslip.buyBack' | available)">
      <!-- {{ 'ticket_buyback_sellfor' | translate }} -->
      <!-- TODO -->
      <b class="won flex">
        <span class="truncate"> {{ 'ticket_buyback_value' | translate }} </span>
        <span class="flex-none">
          {{ buybackValue | formatNumberWithCurrency : { decimalDigits: configuration.base.defaultCurrency.numberDecimalPlaces} }}
        </span>
      </b>
    </div>
  </div>
  <div
    class="flex-none flex flex-col justify-center space-y-1 h-full items-end ml-auto px-1"
  >
    <ion-badge class="rate">
      <ng-container *ngIf="ticket.type === 'SRT'">-</ng-container>
      <ng-container *ngIf="ticket.type === 'JT'">{{
        ticket.ticketRate | rate
      }}</ng-container>
    </ion-badge>
    <div class="flex">
      <ion-badge
        class="rate index y"
        *ngIf="ticket.oddsStats | eventsCount : 'Y'"
      >
        {{ ticket.oddsStats | eventsCount : 'Y' }}
      </ion-badge>
      <ion-badge
        class="rate index r"
        *ngIf="ticket.oddsStats | eventsCount : 'N'"
      >
        {{ ticket.oddsStats | eventsCount : 'N' }}
      </ion-badge>
      <ion-badge
        class="rate index b"
        *ngIf="ticket.oddsStats | eventsCount : 'U'"
      >
        {{ ticket.oddsStats | eventsCount : 'U' }}
      </ion-badge>
    </div>
  </div>
</div>
