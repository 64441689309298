import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  concatMap,
  map,
  switchMap,
  withLatestFrom,
  catchError,
  filter,
  first
} from 'rxjs/operators';

import { analysesActions } from '@store/actions';
import { analysesSelectors, appSelectors } from '@store/selectors';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AnalysesService } from '@core/services/analyses/analyses.service';

@Injectable()
export class AnalysesEffects {
  init$ = createEffect(() => {
    return this._store.select(appSelectors.selectIsAppInitialized).pipe(
      filter(isInitialized => !!isInitialized),
      first(),
      switchMap(() => [analysesActions.loadActualAnalyses()])
    );
  });

  loadAnalysess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(analysesActions.loadActualAnalyses),
      concatMap(() =>
        this._analysesService.fetchActualAnalysisList().pipe(
          map(analyses => {
            return analysesActions.loadActualAnalysesSuccess({ analyses });
          })
        )
      )
    );
  });

  setHistoryAnalysesDateFilter$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(analysesActions.setAnalysisHistoryDateFilter),
      map(() => analysesActions.loadNextPageOfAnalysisHistory())
    );
  });

  loadNextPageOfAnalysisHistory$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(analysesActions.loadNextPageOfAnalysisHistory),
      withLatestFrom(
        this._store.select(analysesSelectors.selectAnalysesHistoryCursor)
      ),
      switchMap(([action, cursor]) =>
        this._analysesService
          .fetchAnalysisHistory(cursor)
          .pipe(
            map(analyses =>
              analysesActions.nextAnalysisHistoryLoaded({ analyses })
            )
          )
      )
    );
  });

  loadAnalysisDetail$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(analysesActions.loadAnalysisDetail),
      concatMap(action =>
        this._analysesService.fetchAnalysisDetail(action.analysisID).pipe(
          map(analysis =>
            analysesActions.loadAnalysisDetailSuccess({ analysis })
          ),
          catchError(err => of(analysesActions.loadAnalysisDetailFailure(err)))
        )
      )
    );
  });

  constructor(
    private _actions$: Actions,
    private _analysesService: AnalysesService,
    private _store: Store
  ) {}
}
