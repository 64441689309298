import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TicketHistoryListItem } from '@models/TicketHistory';
import { TickethistoryService } from 'src/app/pages/betslip/tickethistory/tickethistory.service';
import { SignalrService } from 'src/app/services/signalr.service';
import { AutoBuybackModalComponent } from './autobuyback-modal/autobuyback-modal.component';
import { BuybackModalComponent } from './buyback-modal/buyback-modal.component';
import { ActivationEnd, Router } from '@angular/router';
import {
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap
} from 'rxjs/operators';
import { NAVIGATION } from 'src/navigation';
import { Store } from '@ngrx/store';
import { authSelectors } from '@store/selectors';
import { Subscription, of } from 'rxjs';
import { BUYBACK_SIGNALR_SCOPE } from '@constants';
import { AppVisibilityService } from '@core/services/app-visibility/app-visibility.service';
import { buyBackActions } from '@store/actions';

@Injectable({ providedIn: 'root' })
export class BuybackService {
  private _visibilitySubscription: Subscription;
  constructor(
    private _ticketHistoryService: TickethistoryService,
    private _modalController: ModalController,
    private _signalrService: SignalrService,
    private _router: Router,
    private _store: Store,
    private _visibilityService: AppVisibilityService
  ) {
    this._store
      .select(authSelectors.selectLoggedUser)
      .pipe(
        map(user => user?.playerId),
        switchMap(playerId =>
          !playerId
            ? of({ shouldSubscribe: false, playerId })
            : this._router.events.pipe(
                filter(event => event instanceof ActivationEnd),
                map((event: ActivationEnd) => event.snapshot.data.PageName),
                filter(pageName => !!pageName),
                map((pageName: keyof typeof NAVIGATION) => {
                  const buybackSubscribeWhitelist: (keyof typeof NAVIGATION)[] =
                    [
                      'CasinoFavorites',
                      'CasinoHome',
                      'CasinoSpinHistoryList',
                      'BonusDetailCasino',
                      'BonusesListCasino',
                      'ResponsibleGamingCasino',
                      'RioHome'
                    ];
                  return !buybackSubscribeWhitelist.includes(pageName);
                }),
                map(shouldSubscribe => ({ shouldSubscribe, playerId }))
              )
        ),
        distinctUntilKeyChanged('shouldSubscribe')
      )
      .subscribe(({ shouldSubscribe, playerId }) => {
        this._visibilitySubscription?.unsubscribe();
        if (playerId) {
          this._visibilitySubscription =
            this._visibilityService.visibilityChangeReveal.subscribe(
              revealAfter => {
                if (revealAfter > 1000) {
                  this._store.dispatch(buyBackActions.loadBuybackTickets());
                }
              }
            );
        }

        if (shouldSubscribe) {
          this._signalrService.changeViews(
            [`bb_${playerId}`],
            BUYBACK_SIGNALR_SCOPE
          );
        } else {
          if (BUYBACK_SIGNALR_SCOPE in this._signalrService.currentViews) {
            this._signalrService.changeViews([], BUYBACK_SIGNALR_SCOPE);
          }
        }
      });
  }

  /**
   * opens buybackmodal
   */
  showBuybackModal(
    ticketCode: TicketHistoryListItem['ticketCode']
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._modalController
        .create({
          component: BuybackModalComponent,
          cssClass: 'buyback-modal',
          componentProps: { ticketCode }
        })
        .then(modal => {
          modal.present();
          modal.onDidDismiss().then(reason => {
            resolve(reason.data === true);
          });
        });
    });
  }

  /**
   * opens autobuybackmodal
   */
  async showAutoBuybackModal(
    ticketCode: TicketHistoryListItem['ticketCode']
  ): Promise<{ success: boolean; autobuyback?: boolean }> {
    return new Promise((resolve, reject) => {
      this._ticketHistoryService
        .getTicketDetailNEW(ticketCode)
        .subscribe(ticket => {
          this._modalController
            .create({
              component: AutoBuybackModalComponent,
              cssClass: 'autobuyback-modal',
              componentProps: { ticketCode }
            })
            .then(modal => {
              modal.present();
              modal.onDidDismiss().then(reason => {
                resolve({ ...reason.data });
              });
            });
        });
    });
  }
}
