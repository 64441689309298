<ng-container *ngIf="buyBack$ | async as bbValue">
  <div
    class="green-text icon icon-ic_money-in-hnad top-icon"
    [ngClass]="{
      disabled: bbValue.AutoBuyBackValue === 0
    }"
  ></div>
  <ion-row class="ion-text-center bold modal-header">
    <ion-col class="bold">
      <!-- Automatický odkup tiketu -->
      {{ 'ticket_buyback_ticketbuyback' | translate }}

      <!-- <span> {{ ticket.bet | formatNumberWithCurrency }} / </span> -->
      <span class="green-text">
        <!-- {{ ticket.possibleWinValue | formatNumberWithCurrency }} -->
      </span>
    </ion-col>
    <div
      (click)="closeBuybackModal(false)"
      class="icon-ic_close btn-close"
    ></div>
  </ion-row>
  <!-- <div class="bordered modal-content">
    <doxx-ticketevents [ticket]="ticket" [style]="'buyback'"></doxx-ticketevents>
  </div> -->
  <ion-row class="gray-background values-row">
    <ion-col>
      <!-- <span *ngIf="this.ticket?.BuyBackValue">{{
        this.ticket.BuyBackValue | formatNumberWithCurrency
      }}</span>
      <span *ngIf="!this.ticket?.BuyBackValue">-</span>  -->
      <span> {{ bbValue.AmountMoney | formatNumberWithCurrency: { decimalDigits: configuration.base.defaultCurrency.numberDecimalPlaces} }} </span> /
      <span class="bold">{{
        bbValue.AmountMoneyWin | formatNumberWithCurrency: { decimalDigits: configuration.base.defaultCurrency.numberDecimalPlaces}
      }}</span>
    </ion-col>
  </ion-row>
  <ion-row class="gray-background info pd-btm">
    <ion-col>
      <span>
        <!-- Win for buyback -->
        <!-- Požadovaná výhra na výplatu -->

        {{ 'ticket_buyback_wintopay' | translate }}
        <i
          class="icon icon-ic_info-circle-filled"
          (click)="showAutoBuybackTooltip()"
        ></i
      ></span>
    </ion-col>
  </ion-row>
  <div>
    <ion-row
      class="gray-background"
      [ngClass]="{
        disabled: bbValue.AutoBuyBackValue
      }"
    >
      <ion-col>
        <ion-item class="sum">
          <input
            [ngModel]="autobuybackValueSlider"
            (ngModelChange)="onInputChange($event)"
            type="number"
            step="0.010"
            inputmode="decimal"
            (focus)="focusOnValue($event)"
            (blur)="checkRange(bbValue)"
          />
          <ion-label class="currency" slot="end">
            &nbsp;{{ config.base.defaultCurrency.symbol }}</ion-label
          >
        </ion-item>
      </ion-col>
    </ion-row>
  </div>
  <ion-row class="gray-background pd-btm">
    <ion-col>
      <ion-range
        class="slider"
        (ionChange)="changeAutobuybackValue()"
        [ngClass]="{
          disabled: bbValue.AutoBuyBackValue
        }"
        [(ngModel)]="autobuybackValueSlider"
        [min]="min"
        [max]="bbValue.AmountMoneyWin"
        step="0.010"
        snaps="true"
        ticks="false"
      >
      </ion-range>
    </ion-col>
  </ion-row>
  <!-- <ion-list mode="ios" lines="none">
    <ion-item class="accept-checkbox">
      <ion-checkbox color="secondary" [(ngModel)]="acceptChanges"></ion-checkbox>
      accept change
       <ion-label> {{ 'ticket_buyback_acceptchanges' | translate }}</ion-label> 
    </ion-item>
  </ion-list> -->

  <ion-footer>
    <ion-button
      class="buy-back-btn"
      expand="block"
      (click)="executeAutoBuyback(autobuybackValueSlider)"
      [disabled]="isAutoBuybackActive"
      *ngIf="!bbValue.AutoBuyBackValue"
    >
      <!-- *ngIf="this.ticket.autoBuyBackSet === false" -->
      <span class="capitalize-first">
        {{ 'password_reset_set' | translate }}
        <!-- {{ 'ticket_buyback_sellticketfor' | translate }} -->
      </span>
    </ion-button>
    <ion-button
      class="buy-back-btn red"
      expand="block"
      (click)="executeAutoBuyback(0)"
      [disabled]="isAutoBuybackActive"
      *ngIf="
        bbValue.AutoBuyBackValue &&
        bbValue.AutoBuyBackValue !== 0 &&
        autoBuyBackSet
      "
    >
      <!-- *ngIf="this.ticket.autoBuyBackSet === true" -->
      <span class="capitalize-first">
        <!-- Zrušiť automatický odkup -->
        {{ 'ticket_autobuyback_cancel' | translate }}
      </span>
    </ion-button>
  </ion-footer>
</ng-container>
