import { Pipe, PipeTransform } from '@angular/core';
import { configuration } from '@configuration';
import { ConfigOverride } from 'src/configuration/types/configOverride';

@Pipe({
  name: 'isProfileEditable'
})
export class IsProfileEditablePipe implements PipeTransform {
  /**
   * Check is profile field is editable
   *
   * @param profile profile parameter
   * @param isVerified is verified
   * @returns boolean
   */
  transform(
    profile: keyof ConfigOverride['profile'],
    isVerified: boolean
  ): boolean {
    return isProfileEditable(profile, isVerified);
  }
}
/**
 *
 * Check is profile field is editable
 */
export function isProfileEditable(
  profile: keyof ConfigOverride['profile'],
  isVerified: boolean
): boolean {
  if (isVerified) {
    return (configuration.profile[profile] as any).verifiedEditable;
  } else {
    return (configuration.profile[profile] as any).editable;
  }
}
