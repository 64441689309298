import { OfferResponse } from './offerResponse';

/** transforms response from main menu to unified response */
export function transformStreamListResponse(data): OfferResponse {
  const tree: OfferResponse['tree'] = {};
  const sports: OfferResponse['sports'] = {};
  const events: OfferResponse['events'] = {};
  const odds: OfferResponse['odds'] = {};
  const regions: OfferResponse['regions'] = {};
  const phases: OfferResponse['phases'] = {};
  const leagues: OfferResponse['leagues'] = {};
  const stream: OfferResponse['stream'] = {
    eventCount: 0,
    streamList: []
  };

  data.Events.sortBy(ev =>
    ev.LiveBetting === 'Y' ? -1 : new Date(ev.EventDate).getTime()
  ).forEach(event => {
    events[event.EventID] = event;
    sports[event.SportID] = data.Labels[`SP_` + event.SportID];
    regions[event.RegionID] = data.Labels[`RE_` + event.RegionID];
    leagues[event.LeagueCupID] = data.Labels[`LC_` + event.LeagueCupID];
    if (event.LiveBetting === 'Y') {
      stream.eventCount += 1;
    }
    stream.streamList.push(event.EventID);
  });

  return {
    sports,
    phases,
    regions,
    leagues,
    events,
    odds,
    tree,
    stream
  };
}
