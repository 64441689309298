import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { DefaultPopupComponent } from './default-popup/default-popup.component';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { MaterialDesignModule } from '@shared/material-design/material-design.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DefaultPopupComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    DoxxPipesModule,
    MaterialDesignModule,
    FormsModule
  ]
})
export class PopupsModule {}
