import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { bonus as _bonus, Bonus, MyBonus } from '@models/Bonus';
import { environment } from '@environment';
import { map } from 'rxjs/operators';
import { oneLine } from '@core/functions/oneLine';
import { CodeBonus, LoyaltyBonusState } from 'src/app/pages/bonuses';
import { Store } from '@ngrx/store';
import { isAvailable } from '@core/functions/isAvailable';

@Injectable({
  providedIn: 'root'
})
export class BonusesService {
  constructor(private _httpClient: HttpClient, private _store: Store) {}

  /** Fetch bonuses for sportsbetting */
  fetchSportsBettingBonuses(): Observable<Bonus[]> {
    if (!isAvailable('bonuses.allowedBetting')) {
      return of([]);
    }
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/web/client/data/bonuses/category/sportbook`
      )
      .pipe(
        map(data =>
          data.Response.map(bonus => ({
            ...bonus
          }))
        )
      );
  }

  /** Fetch bonuses for sportsbetting */
  fetchMySportsBettingBonuses(): Observable<MyBonus[]> {
    if (!isAvailable('bonuses.allowedBetting')) {
      return of([]);
    }
    return this._httpClient
      .get<any>(oneLine`${environment.apiUrl}/central/bonus/list`, {
        withCredentials: true
      })
      .pipe(
        map(data =>
          data.map(bonus => ({
            ...bonus
            // ,
            // startDate: bonus.startDate?.replace(' ', 'T'),
            // endDate: bonus.endDate?.replace(' ', 'T')
          }))
        )
      );
  }

  /** Fetch bonuses for casino */
  fetchCasinoBonuses(): Observable<Bonus[]> {
    if (!isAvailable('bonuses.allowedCasino')) {
      return of([]);
    }
    return forkJoin({
      casino: this._httpClient.get<any>(oneLine`
        ${environment.apiUrl}/web/client/data/bonuses/category/casino
      `),
      rio: this._httpClient.get<any>(oneLine`
        ${environment.apiUrl}/web/client/data/bonuses/category/rio
      `)
    }).pipe(
      map(({ casino, rio }) => [
        ...casino.Response.map(bonus => ({
          ...bonus
          // StartDateString: bonus.StartDateString?.replace(' ', 'T'),
          // EndDateString: bonus.EndDateString?.replace(' ', 'T')
        })),
        ...rio.Response.map(bonus => ({
          ...bonus
          // ,
          // StartDateString: bonus.StartDateString?.replace(' ', 'T'),
          // EndDateString: bonus.EndDateString?.replace(' ', 'T')
        }))
      ])
    );
  }

  /** Fetch welcome bonus state */
  fetchWelcomeBonusState(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/bp/client/bonus/welcome/partial/conditions`,
      {
        withCredentials: true
      }
    );
  }

  /** Apply promocode */
  applyPromocode(promocode: string): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/bp/client/bonus/freebets/apply/code/${promocode}`,
      {
        withCredentials: true
      }
    );
  }

  /** Fetch freebets */
  fetchFreebetsList(): Observable<any> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/web/bp/client/bonus/freebets/list`,
        {
          withCredentials: true
        }
      )
      .pipe(map(data => data.data.bonus.freeBets));
  }

  /** Fetches sumary of scout bonus */
  fetchScoutSummary(): Observable<any> {
    return this._httpClient
      .get<any>(oneLine`${environment.apiUrl}/web/bp/scout/summary`, {
        withCredentials: true
      })
      .pipe(map(data => data.data.scout.summary));
  }

  /** Fetches profit of scout bonus */
  fetchScoutProfit(): Observable<any> {
    return this._httpClient
      .get<any>(oneLine`${environment.apiUrl}/web/bp/scout/profit`, {
        withCredentials: true
      })
      .pipe(map(data => data.data.scout.profit));
  }

  /** Send scout code */
  sendScoutCode(type: 'phone' | 'email'): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/bp/scout/send/${type}`,
      {
        withCredentials: true
      }
    );
  }

  /** Generate scout code */
  generateScoutCode(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/bp/scout/generate`,
      {
        withCredentials: true
      }
    );
  }

  /** Fetch casino loyalty bonus points */
  fetchLoyaltyPoints(): Observable<LoyaltyBonusState> {
    return this._httpClient
      .get<any>(oneLine`${environment.apiUrl}/web/casino/getLoyaltyPoints`, {
        withCredentials: true
      })
      .pipe(map(data => data.Result));
  }

  /** Change loyalty bonus points to money */
  changeLoyaltyPointsToMoney(): Observable<any> {
    return this._httpClient.get(
      oneLine`${environment.apiUrl}/web/casino/changeLoyaltyPointsToMoney`,
      {
        withCredentials: true
      }
    );
  }

  /** Fetch VIP bonus */
  fetchVipBonus(): Observable<any> {
    return this._httpClient.get(
      oneLine`${environment.apiUrl}/web/casino/vipBonus`,
      {
        withCredentials: true
      }
    );
  }

  /** Fetch Client Code Bonus History */
  fetchClientCodeBonusHistory(): Observable<CodeBonus[]> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/web/casino/clientCodeBonusHistory`,
        {
          withCredentials: true
        }
      )
      .pipe(map(data => data.Result));
  }

  /** Activate client code bonus */
  activateClientCodeBonus(bonusCode: string): Observable<any> {
    bonusCode = encodeURIComponent(bonusCode);
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/casino/activateClientCodeBonus/BonusCode/${bonusCode}`,
      {
        withCredentials: true
      }
    );
  }

  /** Deactivate activated code bonus */
  deactivateClientCodeBonus(): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/casino/deactivateClientCodeBonus`,
      {
        withCredentials: true
      }
    );
  }

  /** Fetch active freespins */
  fetchActiveFreespins(): Observable<any> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/web/casino/getClientActiveFreespins`,
        {
          withCredentials: true
        }
      )
      .pipe(map(data => data.Result));
  }

  /** Fetch freespin history */
  fetchFreespinsHistory(): Observable<any> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/web/casino/getClientFreespinHistory`,
        {
          withCredentials: true
        }
      )
      .pipe(map(data => data.Result));
  }

  /** Remove bonus */
  removeBonus(bonusID: string): Observable<any> {
    bonusID = encodeURIComponent(bonusID);
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/casino/removeBonus/BonusInstanceCode/${bonusID}`,
      {
        withCredentials: true
      }
    );
  }

  /** get bonus detail based on his ID */
  getBonusDetail(bonusID: string, playerID?: number): Observable<_bonus> {
    let bonusDetail = this._httpClient.get<_bonus>(
      oneLine`${environment.apiUrl}/central/bonus/detail/${bonusID}`
    );
    if (!environment.production && playerID) {
      bonusDetail = this._httpClient.get<_bonus>(
        oneLine`https://central-sportbox-staging.doxxbet.sk:8420/mobile_responsive_web/bonus/bonus-detail?bonusName=${bonusID}&playerId=${playerID}&forPlatform=online`
      );
    }
    return bonusDetail.pipe(
      map(resp => {
        if (!resp.bonusTitle) {
          throw new Error('Bonus not found');
        } else {
          return resp;
        }
      })
    );
  }
}
