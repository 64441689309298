import { Action, createReducer, on } from '@ngrx/store';
import { Ticket, TicketOdd } from '@models/Ticket';
import { ticketActions } from '@store/actions';

export const TICKET_STATE_KEY = 'ticket';
export interface TicketState {
  ticket: Ticket;
}

const initialState: TicketState = {
  ticket: null
};

export const ticketReducer = createReducer(
  initialState,
  // OPTIMISTIC UI, instant add/remove odds on ticket
  on(ticketActions.addOdds, (state, action) => {
    const newOddEvents = action.odds.map(odd => odd.EVT);
    return {
      ...state,
      ticket: {
        ...state.ticket,
        Errors: [],
        Odds: [
          // first distinct odds with eventIDS
          ...(state.ticket?.Odds?.filter(
            odd => !newOddEvents.includes(odd.EVT)
          ) || []),
          // add odds
          ...(action.odds as TicketOdd[])
        ]
      }
    };
  }),
  on(ticketActions.removeOdds, (state, action) => ({
    ...state,
    ticket: {
      ...state.ticket,
      Errors: [],
      Odds: state.ticket?.Odds?.filter(odd => !action.odds.includes(odd.OddID))
    }
  })),

  on(
    ticketActions.switchTicketComplete, // SWITCH
    ticketActions.resetTicketComplete, // RESET
    ticketActions.refreshTicketComplete, // REFRESH
    ticketActions.changeTicketTypeComplete, // CHANGE TICKET TYPE
    ticketActions.setTotalBetComplete, // SET TOTAL BET
    ticketActions.setCombinationStakeComplete, // SET COMBINATION STAKE
    ticketActions.setSpellStakeComplete, // SET COMBINATION STAKE
    ticketActions.unpinCombinationComplete, // UNPIN COMBINATION
    ticketActions.setAllowedRateChangeComplete, // SET ALLOWED RATE CHANGE
    ticketActions.setFreebetComplete, // SET FREEBET
    ticketActions.removeFreebetComplete, // REMOVE FREEBET
    ticketActions.addOddsComplete, // ADD ODDS
    ticketActions.removeOddsComplete, // REMOVE ODDS
    ticketActions.oddTagComplete, // TAG ODDS
    ticketActions.oddUntagComplete, // UNTAG ODDS
    ticketActions.oddTopComplete, // TOP ODDS
    ticketActions.activateCombiComplete, // ACTIVATE COMBI
    ticketActions.deactivateCombiComplete, // DEACTIVATE COMBI
    ticketActions.placeBetComplete, // PLACEBET
    ticketActions.cloneTicketSuccess, // CLONE
    ticketActions.restoreTicketSuccess, // RESTORE
    ticketActions.editTicketComplete, // EDIT
    ticketActions.oddBuybackComplete, // ODD BUYBACK
    ticketActions.oddBuybackResetComplete, // ODD BUYBACK RESET
    ticketActions.cancelEditationComplete, // CANCEL EDIT
    (state, action) => {
      action.ticket?.Odds.forEach(odd => {
        // format date to ISO string
        odd.DATE = odd.DATE?.replace(/(\d*)\.(\d*)\.(\d*)*/, '$3-$2-$1');
        // find previous version for odd
        const oldOdd = state.ticket?.Odds?.find(
          _odd => _odd.OddID === odd.OddID
        );
        // if there was existing score, use it because score in response is not actual
        if (oldOdd?.SCORENEW) {
          odd.SCORENEW = oldOdd.SCORENEW;
        }
      });
      return {
        ...state,
        ticket: {
          ...state.ticket,
          ...action.ticket
        }
      };
    }
  ),

  on(
    ticketActions.update,
    ticketActions.deltaTicketComplete,
    (state, action) => ({
      ...state,
      ticket: {
        ...state.ticket,
        ...action.ticket,
        Odds: state.ticket.Odds.map(odd => ({
          ...odd,
          ...(action.ticket?.Odds?.find(o => o.OddID === odd.OddID) || {})
        })),
        OddsCombi: state.ticket.OddsCombi.map(oddCombi => ({
          ...oddCombi,
          ...(action.ticket?.OddsCombi?.find(oc => oc.ID === oddCombi.ID) || {})
        }))
      }
    })
  )
);

/** ticket reducer */
export function reducer(state: TicketState, action: Action) {
  return ticketReducer(state, action);
}
