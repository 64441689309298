import {
  Component,
  Input,
  OnInit,
  ContentChildren,
  QueryList
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToolbarListService } from '../toolbar-list.service';
import { ToolbarListItemComponent } from '../toolbar-list-item/toolbar-list-item.component';

@Component({
  selector: 'doxx-toolbar-list',
  templateUrl: './toolbar-list.component.html',
  styleUrls: ['./toolbar-list.component.scss'],
  providers: [ToolbarListService]
})
export class ToolbarListComponent<T> implements OnInit {
  @ContentChildren(ToolbarListItemComponent)
  listItemsCmp: QueryList<ToolbarListItemComponent<T>>;

  @Input() uneslectable = false;
  @Input() compareValuesFunction: (a: T, b: T) => boolean = (a, b) =>
    JSON.stringify(a) === JSON.stringify(b);

  constructor(private _toolbarListService: ToolbarListService<T>) {}

  /**
   * set component behaviour to make items unselectable or not
   */
  ngOnInit(): void {
    this._toolbarListService.unselectable = this.uneslectable;
    this._toolbarListService.compareValuesFunction = this.compareValuesFunction;
  }

  get selectedItem(): BehaviorSubject<T> {
    return this._toolbarListService.selectedItem;
  }

  /** scroll to selected item */
  scrollToSelected(): void {
    if (this.listItemsCmp && this.listItemsCmp.length) {
      this.listItemsCmp
        .find(cmp => cmp.value === this.selectedItem.value)
        .el.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
    }
  }

  /**
   * Handler for item selection
   */
  selectItem(item: T): void {
    this._toolbarListService.selectedItem.next(item || null);
  }
}
