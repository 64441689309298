import { Pipe, PipeTransform } from '@angular/core';
import { visibility } from '@models/Visibility';
import { EventChanceTypeData } from 'src/app/pages/detail/detail/chance-types-container/param-group-container/param-group-container.component';

@Pipe({
  name: 'hasVisibleOdds'
})
export class HasVisibleOddsPipe implements PipeTransform {
  /**
   * Slugify any string, function wraps function from  package https://www.npmjs.com/package/slugify
   * with defaul config for our application
   */
  transform(ectData: EventChanceTypeData[]): boolean {
    let i = 0;
    let j = 0;
    let k = 0;
    if (!ectData) {
      return false;
    }
    while (ectData[i]) {
      if (ectData[i].eventChanceType.Visibility !== visibility.HIDE) {
        while (ectData[i].odds[j]) {
          while (ectData[i].odds[j][k]) {
            if (ectData[i].odds[j][k].Visibility !== visibility.HIDE) {
              return true;
            }
            k++;
          }
          j++;
        }
      }
      i++;
    }
    return false;
  }
}
