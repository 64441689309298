import { mapParamAlias } from '@core/functions/mapParamAlias';
import { oneLine } from '@core/functions/oneLine';
import { environment } from '@environment';
import { ParameterAlias } from '@models/ParameterAlias';
import { ScreenMember } from '@models/ScreensMember';
import { startWith } from 'rxjs/operators';
import { NAVIGATION, NavigationConfig } from './navigation';

export const INTERN_PARAM_PREFIX = '_intern_';

interface PageData {
  LinkMetadataKey?: string;
  PageName?: string;
  [key: string]: any;
}

type LinkAlias = { path: string; data: PageData };

export type ParamValue = (string | number) | [string | number, string];

export type ScreenParamsType<T extends keyof NavigationConfig> =
  NavigationConfig[T]['params'][number];

export const param = <T extends keyof NavigationConfig>(
  path: ScreenParamsType<T>
) => path;

type ResolveParamFunc<T extends string> = (options: {
  prewParameters: Map<T, ParameterAlias>;
  screen: ScreenConfig<T>;
  navigation: any;
}) => ParameterAlias[] | ParameterAlias[][];

export class ScreenConfig<T extends string> {
  get defaultLink() {
    return this._links[0]?.path;
  }
  get data() {
    return this._links[0].data;
  }
  private _links: LinkAlias[] = [];
  public parametersAlias: ParameterAlias[] = [];
  public params: T[] = [];
  private _ready = false;

  get links(): LinkAlias[] {
    return this._links
      .map(link => ({
        ...link,
        path: link.path.replace(/\/{.*}/gm, '').split(/\?|#/)[0]
      }))
      .sortBy((link: LinkAlias) => -link.path.length)
      .filter(() => this._ready);
  }

  public resolveFunctions: Map<T, ResolveParamFunc<T>>;
  constructor(resolveFunctions?: [T, ResolveParamFunc<T>][]) {
    if (resolveFunctions) {
      this.resolveFunctions = new Map(resolveFunctions);
      this.params = resolveFunctions.map(([pa]) => pa);
    }
  }

  /** assig params map and add routes */
  addRoute(
    screen: ScreenMember & {
      Data?: Record<string, any>;
    }
  ): ScreenConfig<T> {
    const paramNames = (screen.Link.match(/{\w*}/g) || []).map(x =>
      x.replace(/{(\w*)}/g, '$1')
    );
    const internalParams = this.params.filter(pa =>
      pa.startsWith(INTERN_PARAM_PREFIX)
    );
    const routeParams = this.params.filter(
      pa => !pa.startsWith(INTERN_PARAM_PREFIX)
    );

    if (routeParams.length !== paramNames.length) {
      const ofiginalLink = screen.Link;
      screen.Link = `${screen.Link}/${routeParams
        .filter(_param => !paramNames.includes(_param))
        .map(_param => `{${_param}}`)
        .join('/')}`;
      if (!environment.production) {
        console.warn(
          oneLine`[ROUTING] Screen: [${
            screen.Screen
          }] Route ${ofiginalLink} has incompatible params length, expected: [${routeParams.toString()}], got [${paramNames.toString()}]` +
            '\n' +
            oneLine`Missing params are added to end of route: ${screen.Link}`
        );
      }
    }

    // add internal params to end of route
    if (internalParams?.length > 0) {
      screen.Link = `${screen.Link}/${internalParams
        .map(_param => `{${_param}}`)
        .join('/')}`;
    }

    routeParams.forEach((key, index) => {
      if (key && paramNames[index] && key !== paramNames[index]) {
        console.error(
          `[ROUTING] Screen: [${screen.Screen}] There is colision for route alias ${screen.Link} param names`
        );
      } else if (typeof key !== 'string') {
        this.params[index] = paramNames[index] as T;
      }
    });

    this._addParamsAlias(screen.ParametersAlias);
    this._links[screen.IsDefault ? 'push' : 'unshift']({
      path: screen.Link,
      data: {
        LinkMetadataKey: screen.LinkMetadataKey,
        PageName: screen.Screen
      }
    });
    this._ready = true;
    return this;
  }

  /** find and return parameter value in tree structure */
  getParameterAliasByParamName(
    paramName: T,
    value: string | number
  ): ParameterAlias {
    const findParam = parametersAlias => {
      if (parametersAlias) {
        const foundOnThisLevel = parametersAlias.find(
          pa =>
            pa.ParamName === this.params[paramName as string] &&
            // eslint-disable-next-line eqeqeq
            value == pa.Value
        );
        if (foundOnThisLevel) {
          return foundOnThisLevel;
        } else {
          for (const iterator of parametersAlias) {
            const fonudOnLowerLevel = findParam(iterator.childrens);
            if (fonudOnLowerLevel) {
              return fonudOnLowerLevel;
            }
          }
        }
      } else {
        return null;
      }
    };
    const { childrens, ...rest } = findParam(this.parametersAlias) || {};
    return rest.Value ? rest : null;
  }

  /** path of values */
  getParameterAlias(...values: string[]): string {
    return values.reduce((previousValue, currentValue, currentIndex, array) => {
      if (currentIndex === array.length - 1) {
        return previousValue.find(p => p.Value === currentValue)?.Link;
      } else {
        return (
          previousValue.find(p => p.Value === currentValue)?.childrens || []
        );
      }
    }, this.parametersAlias) as string;
  }

  /** build link to screen, position of parameters matters, use position defined in constructor */
  buildLink(params?: Record<T, { value; fallbackValue }>): string {
    if (!this._ready) {
      return null;
    }
    let link = this._links[0].path;
    if (!params) {
      params = {} as Record<T, { value; fallbackValue }>;
    }

    const pa = this.resolveParams(
      Object.keys(params).reduce(
        (accumulator, paramName) => ({
          ...accumulator,
          [paramName]: [
            params[paramName].value,
            params[paramName].fallbackValue
          ]
        }),
        {}
      ) as Record<T, string[]>
    );

    Array.from(pa.entries()).forEach(([paramName, { Link }]) => {
      link = link.replace(`{${paramName}}`, Link || 'PA_NOT_FOUND');
    });
    link = link.replace(/\/?PA_NOT_FOUND?.+$/g, '');

    return link;
  }

  /** resolve params */
  resolveParams(params: Record<T, string | string[]>): Map<T, ParameterAlias> {
    const parameters = new Map();
    this.params?.forEach((parameterName, index) => {
      let pa = params[parameterName];

      if (!Array.isArray(pa) || (Array.isArray(pa) && pa.length < 2)) {
        pa = [pa, pa] as string[];
      }
      const transformFunction = this.resolveFunctions.get(parameterName);

      if (transformFunction) {
        const parametersAliasToSearchIn = transformFunction({
          screen: this,
          prewParameters: parameters,
          navigation: NAVIGATION
        });

        const foundParameterAlias: ParameterAlias = this._findInParametersAlias(
          parametersAliasToSearchIn,
          parameterName,
          pa[0]
        );
        if (foundParameterAlias) {
          parameters.set(parameterName, foundParameterAlias);
        }
      }
      if (!parameters.has(parameterName)) {
        parameters.set(parameterName, {
          Link: pa[1],
          Value: pa[0],
          ParamName: parameterName
        });
      }
    });

    return parameters;
  }

  /** find parameter alias in array for given pair value and name */
  protected _findInParametersAlias(
    parametersAliases: ParameterAlias[] | ParameterAlias[][],
    paramName: string,
    paramValue: string
  ): ParameterAlias {
    parametersAliases = parametersAliases || [];

    if (!Array.isArray(parametersAliases[0])) {
      parametersAliases = [parametersAliases] as ParameterAlias[][];
    }

    for (const parametersAlias of parametersAliases) {
      const paramAlias =
        // find by value
        parametersAlias.find(
          alias =>
            alias.ParamName === paramName &&
            alias.Value?.toString() === paramValue?.toString()
        ) ||
        // find item by Link
        parametersAlias.find(
          alias =>
            alias.ParamName === paramName &&
            alias.Link?.toString() === paramValue?.toString()
        ) ||
        null;
      return paramAlias;
    }
  }

  private _addParamsAlias(parametersAlias: ParameterAlias[]) {
    if (parametersAlias) {
      const newParams = mapParamAlias(parametersAlias);

      function deepMergeParameterAlias(
        arr1: ParameterAlias[],
        arr2: ParameterAlias[]
      ): ParameterAlias[] {
        // Helper function to find object in array by id
        function findByValue(array: ParameterAlias[], value) {
          return array.find(obj => obj.Value === value);
        }

        // Helper function to merge two objects
        function mergeParamAlias(obj1: ParameterAlias, obj2: ParameterAlias) {
          return {
            ...obj1,
            ...obj2,
            childrens: deepMergeParameterAlias(
              obj1.childrens || [],
              obj2.childrens || []
            )
          };
        }

        // Iterate through first array
        for (let obj1 of arr1) {
          // Try to find matching object in second array
          const obj2 = findByValue(arr2, obj1.Value);
          // If found, merge the objects
          if (obj2) {
            obj1 = mergeParamAlias(obj1, obj2);
            // Remove merged object from second array
            arr2 = arr2.filter(obj => obj.id !== obj1.Value);
          }
        }
        // Concatenate remaining objects from second array
        return arr1.concat(arr2);
      }

      this.parametersAlias = deepMergeParameterAlias(
        this.parametersAlias,
        newParams
      );
    }
  }
}
