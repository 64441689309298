<form [formGroup]="codeForm">
  <div class="code">
    <div
      class="number"
      *ngFor="let item of codeForm.controls | keyvalue; let first = first"
    >
      <input
        [id]="item.key"
        #codeNumberInput
        type="text"
        [formControl]="item.value"
        (click)="item.value.setValue(null)"
        [attr.autocomplete]="first ? autocomplete : 'off'"
        [attr.inputmode]="inputmode"
        [attr.pattern]="pattern"
      />
    </div>
  </div>
</form>
