<h1>{{ 'contacts_title' | translate }}</h1>

<ion-card>
  <ion-grid>
    <ion-row *ngFor="let contact of contacts; let i = index">
      <ion-col [class]="i === 0 ? 'line' : ''">
        <a [href]="contact.link">
          <span>
            <img [src]="contact.icon" />
          </span>
          <span class="text">{{ contact.name }}</span>
        </a>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-card>

<ion-button expand="full" color="whitered" (click)="close()">{{
  'contacts_close' | translate
}}</ion-button>
