import { NgModule } from '@angular/core';
import { AvailablePipe } from './available.pipe';
import { ConfPipe } from './conf.pipe';
import { IfAvailableDirective } from './if-available.directive';

@NgModule({
  declarations: [AvailablePipe, IfAvailableDirective, ConfPipe],
  exports: [AvailablePipe, IfAvailableDirective, ConfPipe]
})
export class UtilsModule {}
