import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLanding from '../landing.reducer';
import * as fromTopBetting from './landing-top-betting.reducer';
import { Dictionary } from '@ngrx/entity';
import { TopEvent } from '@models/TopEvent';
import { Odd } from '@models/Odd';

const selectLandingState = createFeatureSelector<fromLanding.State>(
  fromLanding.LANDING_FEATURE_KEY
);

export const selectTopBetting = createSelector(
  selectLandingState,
  (state: fromLanding.State) => state.topBetting
);

export const selectTopBettingFilter = createSelector(
  selectTopBetting,
  (state: fromTopBetting.State) => state.filter
);

export const selectTopBettingEvents = createSelector(
  selectTopBetting,
  (state: fromTopBetting.State) => state.events.entities
);

/** Selects all odds from top betting */

export const selectTopBettingOdds = createSelector(
  selectTopBetting,
  (state: fromTopBetting.State) => state.odds.entities
);

/** Select all odds from top betting which are top betted */
export const selectTopBettingTopOdds = createSelector(
  selectTopBettingOdds,
  (odds: Dictionary<Odd>): Odd[] =>
    Object.values(odds).filter(odd => odd.isTopTip)
);

export const selectTopBettingVisibleEventsIDs = createSelector(
  selectTopBetting,
  (state: fromTopBetting.State) => state.visibleEventIds
);

export const selectVisibleTopBettingEvents = createSelector(
  selectTopBettingFilter,
  selectTopBettingEvents,
  selectTopBettingVisibleEventsIDs,
  (filter, events, visibleEventIds, date?: string): Dictionary<TopEvent> => {
    const ids = visibleEventIds[date || filter.date];
    if (Boolean(ids)) {
      return ids.reduce((acc, id) => ({ ...acc, [id]: events[id] }), {});
    }
  }
);

export const selectTopBettingEvent = createSelector(
  selectTopBetting,
  (state: fromTopBetting.State, props: { eventID: number }) =>
    state.events.entities[props.eventID]
);

export const selectTopBettingEventOdds = createSelector(
  selectTopBetting,
  (state: fromTopBetting.State, props: { eventID: number }) =>
    Object.values(state.odds.entities).reduce((odds, currentOdd) => {
      if (currentOdd.EventID === props.eventID) {
        return { ...odds, [currentOdd.OddsID]: currentOdd };
      } else {
        return odds;
      }
    }, {})
);

export const selectTopBettingVisibleEventOdds = createSelector(
  selectVisibleTopBettingEvents,
  selectTopBettingTopOdds,
  (events: Dictionary<TopEvent>, odds: Odd[]) =>
    Object.values(events).map(event => ({
      ...odds.find(odd => odd.EventID === event.EventID),
      event
    }))
);
