import { Banner } from '@models/Banner';
import { createAction, props } from '@ngrx/store';

/** BETTING BANNERS */
export const loadBettingBanners = createAction(
  '[Landing] Load Betting Banners'
);

export const loadBettingBannersSuccess = createAction(
  '[Lading] Load Betting Banners Success',
  props<{ banners: Banner[] }>()
);

export const loadBettingBannersFailure = createAction(
  '[Lading] Load Betting Banners Failure'
);

/** CASINO BANNERS */
export const loadCasinoBanners = createAction('[Lading] Set Casino Banner');

export const loadCasinoBannersSuccess = createAction(
  '[Lading] Load Casino Banners Success',
  props<{ banners: Banner[] }>()
);

export const loadCasinoBannersFailure = createAction(
  '[Lading] Load Casino Banners Failure'
);

/** RIO BANNERS */
export const loadRioBanners = createAction('[Lading] Set Rio Banner');

export const loadRioBannersSuccess = createAction(
  '[Lading] Load Rio Banners Success',
  props<{ banners: Banner[] }>()
);

export const loadRioBannersFailure = createAction(
  '[Lading] Load Rio Banners Failure'
);
