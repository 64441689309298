import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare global {
  // defined in webpack
  const BUILD_INFORMATION: {
    VERSION: string;
    ENVIRONMENT: string;
    BUILD_TIME: string;
    AUTHOR: string;
    HASH: string;
    BRANCH_NAME: string;
    PRODUCTION: boolean;
  };
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
