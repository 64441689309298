import { createReducer, on } from '@ngrx/store';
import { landingActions } from '@store/actions';
import { Banner } from '@models/Banner';

export type State = {
  casino: Banner[];
  betting: Banner[];
  rio: Banner[];
};

const initialBannerState: State = {
  casino: [],
  betting: [],
  rio: []
};

export const bettingBannersReducer = createReducer(
  initialBannerState,
  on(landingActions.loadBettingBannersSuccess, (state, action) => ({
    ...state,
    betting: action.banners
  })),
  on(landingActions.loadCasinoBannersSuccess, (state, action) => ({
    ...state,
    casino: action.banners
  })),
  on(landingActions.loadRioBannersSuccess, (state, action) => ({
    ...state,
    rio: action.banners
  }))
);
