import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { SportboxTicketVerifyComponent } from './sportbox-ticket-verify.component';

@NgModule({
  declarations: [SportboxTicketVerifyComponent],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    DoxxPipesModule
  ],
  exports: [SportboxTicketVerifyComponent]
})
export class SportboxTicketVerifyModule {}
