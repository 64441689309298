import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, ActionCreator, Creator, Store } from '@ngrx/store';
import { authActions, ticketActions } from '@store/actions';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  exhaustMap,
  filter,
  first,
  startWith,
  bufferTime
} from 'rxjs/operators';
import { EMPTY, Observable, fromEvent, merge, of, race } from 'rxjs';
import { TicketService } from '../ticket.service';
import { authSelectors, ticketSelectors } from '@store/selectors';
import { Ticket } from '@models/Ticket';
import { SignalrService } from 'src/app/services/signalr.service';
import { AppVisibilityService } from '@core/services/app-visibility/app-visibility.service';
import { TICKET_REFRESH_INACTIVE_TIMEOUT } from '@constants';
import { refreshTicketComplete } from './ticket.actions';

@Injectable()
export class TicketEffects {
  private _createTicketEffect = <
    AC extends ActionCreator<string, Creator>[],
    V = ReturnType<AC[number]>,
    B = Ticket | Partial<Ticket>
  >(
    action: AC,
    call: (action: V) => Observable<B>,
    complete: (ticket?: B) => Action
  ) =>
    createEffect(() =>
      this._actions$.pipe(
        ofType(...action),
        switchMap(act =>
          call(act as V).pipe(
            map(ticket => complete(ticket)),
            catchError(error =>
              of(
                ticketActions.systemTicketError({ error }),
                complete(),
                ticketActions.refreshTicket()
              )
            )
          )
        )
      )
    );

  switchTickeet$ = this._createTicketEffect(
    [ticketActions.switchTicket],
    action => this._ticketService.switchTicket(action.ticketFlag),
    ticket => ticketActions.switchTicketComplete({ ticket })
  );

  resetTicket$ = createEffect(() =>
    merge(
      this._actions$.pipe(ofType(ticketActions.resetTicket)),
      this._actions$.pipe(
        ofType(authActions.logoutSuccess, authActions.softLogoutSuccess),
        concatLatestFrom(() =>
          this._store.select(ticketSelectors.selectTicket)
        ),
        filter(([action, ticket]) => ticket.IsEdited)
      )
    ).pipe(
      switchMap(action =>
        this._ticketService.clearTicket().pipe(
          map(ticket => ticketActions.resetTicketComplete({ ticket })),
          catchError(error =>
            of(
              ticketActions.systemTicketError({ error }),
              refreshTicketComplete(),
              ticketActions.refreshTicket()
            )
          )
        )
      )
    )
  );

  refreshTicket$ = createEffect(() =>
    merge(
      this._actions$.pipe(ofType(authActions.loginSuccess)),
      this._actions$.pipe(
        ofType(ticketActions.refreshTicket),
        bufferTime(2000),
        filter(actions => actions.length > 0)
      )
    ).pipe(
      switchMap(act =>
        this._ticketService.ticketRefresh().pipe(
          map(ticket => ticketActions.refreshTicketComplete({ ticket })),
          catchError(error =>
            of(
              ticketActions.systemTicketError({ error }),
              ticketActions.refreshTicketComplete()
            )
          )
        )
      )
    )
  );

  deltaTicket$ = this._createTicketEffect(
    [ticketActions.deltaTicket],
    action => this._ticketService.deltaTicket(),
    ticket =>
      ticketActions.deltaTicketComplete({
        ticket: { ...ticket, Errors: [] }
      })
  );

  changeTicketType$ = this._createTicketEffect(
    [ticketActions.changeTicketType],
    action => this._ticketService.changeTicketType(action.ticketType),
    ticket => ticketActions.changeTicketTypeComplete({ ticket })
  );

  setTotalBet$ = this._createTicketEffect(
    [ticketActions.setTotalBet],
    action => this._ticketService.setTotalBet(action.bet),
    ticket => ticketActions.setTotalBetComplete({ ticket })
  );

  setCombinationStake$ = this._createTicketEffect(
    [ticketActions.setCombinationStake],
    action =>
      this._ticketService.setCombinationStake(
        action.bet,
        action.combiID,
        action.isPartial
      ),
    ticket => ticketActions.setCombinationStakeComplete({ ticket })
  );

  setSpellStake$ = this._createTicketEffect(
    [ticketActions.setSpellStake],
    action => this._ticketService.setSpellStake(action.bet, action.spell),
    ticket => ticketActions.setSpellStakeComplete({ ticket })
  );

  unpinCombination$ = this._createTicketEffect(
    [ticketActions.unpinCombination],
    action => this._ticketService.unpinCombination(action.combi, action.spell),
    ticket => ticketActions.unpinCombinationComplete({ ticket })
  );

  setAllowedRateChange$ = this._createTicketEffect(
    [ticketActions.setAllowedRateChange],
    action =>
      this._ticketService.setallowedratechange(action.allowedRateChange),
    ticket => ticketActions.setAllowedRateChangeComplete({ ticket })
  );

  setFreebet$ = this._createTicketEffect(
    [ticketActions.setFreebet],
    action => this._ticketService.activateFreebet(action.freebetID),
    ticket => ticketActions.setFreebetComplete({ ticket })
  );

  removeFreebet$ = this._createTicketEffect(
    [ticketActions.removeFreebet],
    action => this._ticketService.deactivateFreebet(),
    ticket => ticketActions.removeFreebetComplete({ ticket })
  );

  addOdds$ = this._createTicketEffect(
    [ticketActions.addOdds],
    action =>
      action.odds.length === 1
        ? this._ticketService.addOdd(action.odds[0].OddID)
        : this._ticketService.oddaddlist(action.odds.map(odd => odd.OddID)),
    ticket => ticketActions.addOddsComplete({ ticket })
  );

  removeOdds$ = this._createTicketEffect(
    [ticketActions.removeOdds],
    action =>
      action.odds.length === 1
        ? this._ticketService.removeOdd(action.odds[0])
        : this._ticketService.removeOdds(action.odds),
    ticket => ticketActions.removeOddsComplete({ ticket })
  );

  cloneTicket$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ticketActions.cloneTicket),
      switchMap(action =>
        this._ticketService.cloneTicket(action.ticketCode).pipe(
          map(ticket => ticketActions.cloneTicketSuccess({ ticket })),
          catchError(() => of(ticketActions.cloneTicketFailure({ errors: [] })))
        )
      )
    )
  );

  storeTicket$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ticketActions.storeTicket),
      switchMap(action =>
        this._ticketService.storeTicket().pipe(
          map(ticket => ticketActions.storeTicketSuccess({ ticket })),
          catchError(() => of(ticketActions.storeTicketFailure({ errors: [] })))
        )
      )
    )
  );
  restoreTicket$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ticketActions.restoreTicket),
      switchMap(action =>
        this._ticketService.restoreTicket(action.ticketCode).pipe(
          map(ticket => ticketActions.restoreTicketSuccess({ ticket })),
          catchError(() =>
            of(ticketActions.restoreTicketFailure({ errors: [] }))
          )
        )
      )
    )
  );

  editTicket$ = this._createTicketEffect(
    [ticketActions.editTicket],
    action => this._ticketService.editTicket(action.ticketCode),
    ticket => ticketActions.editTicketComplete({ ticket })
  );

  oddBuyback$ = this._createTicketEffect(
    [ticketActions.oddBuyback],
    action => this._ticketService.oddBuyback(action.oddID),
    ticket => ticketActions.oddBuybackComplete({ ticket })
  );

  oddBuybackReset$ = this._createTicketEffect(
    [ticketActions.oddBuybackReset],
    action => this._ticketService.oddBuybackReset(action.oddID),
    ticket => ticketActions.oddBuybackResetComplete({ ticket })
  );

  cancelEditation$ = this._createTicketEffect(
    [ticketActions.cancelEditation],
    action => this._ticketService.cancelEditation(),
    ticket => ticketActions.cancelEditationComplete({ ticket })
  );

  subscribe$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ticketActions.subscribe),
      switchMap(({ operation, ticketCode }) =>
        this._ticketService.subscribe(ticketCode, operation).pipe(
          map(data => ticketActions.subscribeSuccess()),
          catchError(error => of(ticketActions.subscribeFailure()))
        )
      )
    )
  );

  changeSubscription$ = createEffect(() =>
    this._store.select(ticketSelectors.selectTicket).pipe(
      exhaustMap(ticket =>
        ticket?.IsEdited
          ? race(
              fromEvent(window, 'beforeunload'),
              this._store
                .select(ticketSelectors.selectTicket)
                .pipe(filter(_ticket => !_ticket.IsEdited))
            ).pipe(
              first(),
              map(() =>
                ticketActions.subscribe({
                  ticketCode: ticket.PlaceBetTicketCode,
                  operation: 'UNSUBSCRIBE'
                })
              ),
              startWith(
                ticketActions.subscribe({
                  ticketCode: ticket.PlaceBetTicketCode,
                  operation: 'SUBSCRIBE'
                })
              )
            )
          : EMPTY
      )
    )
  );

  placeBet$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ticketActions.placeBet),
      withLatestFrom(
        this._store.select(ticketSelectors.selectTicket),
        this._store.select(authSelectors.selectIsUserLoggedIn)
      ),
      switchMap(([action, placeBetTicket, isLoggedIn]) => {
        let errors = [];
        if (!isLoggedIn) {
          errors = [...errors, 'ERR_NOCLIENT'];
        }
        // if (
        //   placeBetTicket.TYPE === 'JT' &&
        //   +placeBetTicket.OverallTotalBet > +placeBetTicket.MaxBet
        // ) {
        //   errors = [...errors, `ERR_MAXBET:${placeBetTicket.MaxBet}`];
        // }
        // if (
        //   placeBetTicket.TYPE === 'JT' &&
        //   +placeBetTicket.OverallTotalBet < +placeBetTicket.MinBet
        // ) {
        //   errors = [...errors, `ERR_MINBET:${placeBetTicket.MinBet}`];
        // }
        // if (!placeBetTicket.Odds.length) {
        //   errors = [...errors, 'ERR_MOREODDS'];
        // }
        // if (placeBetTicket.Odds.some(odd => !odd.IsEditedOdd && odd.BetStatus === 'EXP')) {
        //   errors = [
        //     ...errors,
        //     `ERR_ODDEXP:${placeBetTicket.Odds.filter(
        //       odd => odd.BetStatus === 'EXP'
        //     )
        //       .map(odd => odd.OddID)
        //       .join(':')}`
        //   ];
        // }
        // if (!placeBetTicket.OverallRate) {
        //   errors = [...errors, 'ERR_NOSTAKE'];
        // }
        // if (
        //   !placeBetTicket.Freebets.some(freebet => freebet.IsActive) &&
        //   +placeBetTicket.OverallTotalBet > +placeBetTicket.PlacebetBalance
        // ) {
        //   errors = [...errors, 'INSUFICIENT_FOUNDS'];
        // }
        if (errors.length) {
          return of(
            ticketActions.placeBetComplete({
              ticket: {
                ...placeBetTicket,
                Errors: [errors.join(';')]
              }
            })
          );
        }
        return this._ticketService.placeBet(placeBetTicket.TicketFlag).pipe(
          map(ticket => ticketActions.placeBetComplete({ ticket })),
          catchError(error =>
            of(
              ticketActions.systemTicketError({ error }),
              ticketActions.placeBetComplete({
                ticket: placeBetTicket
              })
            )
          )
        );
      })
    )
  );

  oddTag$ = this._createTicketEffect(
    [ticketActions.oddTag],
    action => this._ticketService.oddTag(action.odds),
    ticket => ticketActions.oddTagComplete({ ticket })
  );

  oddUntag$ = this._createTicketEffect(
    [ticketActions.oddUntag],
    action => this._ticketService.oddUntag(action.odds),
    ticket => ticketActions.oddUntagComplete({ ticket })
  );

  oddTop$ = this._createTicketEffect(
    [ticketActions.oddTop],
    action => this._ticketService.oddTop(action.odds),
    ticket => ticketActions.oddTopComplete({ ticket })
  );

  activateCombi$ = this._createTicketEffect(
    [ticketActions.activateCombi],
    action => this._ticketService.activateCombi(action.combi),
    ticket => ticketActions.activateCombiComplete({ ticket })
  );

  deactivateCombi$ = this._createTicketEffect(
    [ticketActions.deactivateCombi],
    action => this._ticketService.deactivateCombi(action.combi),
    ticket => ticketActions.deactivateCombiComplete({ ticket })
  );

  // SIGNALR UPDATE ODDBUYBACK
  oddBuybackChanges$ = createEffect(() =>
    this._signarService.oddsBuyBackChanged.pipe(
      concatLatestFrom(() => this._store.select(ticketSelectors.selectOdds)),
      switchMap(([changes, odds]) => {
        const update = {};
        let shouldRefresh = false;
        changes.reverse().forEach(change => {
          const updatedOdd = odds.find(odd => odd.OddID === change.oddID);
          if (updatedOdd) {
            const isBuybackCheckedOddUpdated =
              updatedOdd?.IsWinning === 'BB' &&
              !updatedOdd.IsEditedEvaluated &&
              +updatedOdd.BuyBackValue !== +change.buybackValue;
            const maybeSuspendedOdd =
              !updatedOdd.IsEditedEvaluated &&
              updatedOdd.IsActive &&
              +change.oddRate <= 1;
            const maybeActive =
              !updatedOdd.IsEditedEvaluated &&
              !updatedOdd.IsActive &&
              change.isRemoveable === false &&
              +change.buybackValue > 0;
            if (
              isBuybackCheckedOddUpdated ||
              maybeSuspendedOdd ||
              maybeActive
            ) {
              shouldRefresh = true;
            } else {
              update[change.oddID] = {
                OddID: change.oddID,
                BuyBackValue: change.buybackValue,
                IsRemoveable: change.isRemoveable
              };
            }
          }
        });
        return [
          ticketActions.update({ ticket: { Odds: Object.values(update) } }),
          ...(shouldRefresh ? [ticketActions.refreshTicket()] : [])
        ];
      })
    )
  );

  // SIGNALR UPDATE SCORE
  eventChanges$ = createEffect(() =>
    this._signarService.eventChangesJson.pipe(
      withLatestFrom(this._store.select(ticketSelectors.selectTicket)),
      map(([changes, ticket]) => {
        let update;
        changes.forEach(change => {
          ticket?.Odds.forEach(odd => {
            if (odd.EVT === change.EventID) {
              update = update || { Odds: [] };
              update.Odds = [
                ...update.Odds,
                { OddID: odd.OddID, SCORENEW: change.Score }
              ];
            }
          });
        });
        return update;
      }),
      filter(update => !!update),
      map(update =>
        ticketActions.update({
          ticket: update
        })
      )
    )
  );

  // SIGNALR UPDATE RATE
  // ZATIAL NEPOTREBNE, REFRESH ODDOV SA BUDE ROBIT CEZ POOL REFRESH
  // oddChanges$ = createEffect(() =>
  //   this._signarService.oddsChanges.pipe(
  //     concatLatestFrom(() => this._store.select(ticketSelectors.selectTicket)),
  //     map(([changes, ticket]) => {
  //       let update;
  //       changes.forEach(change => {
  //         ticket.Odds.forEach(odd => {
  //           if (odd.OddID === change.oddID) {
  //             update = update || { Odds: [] };
  //             update.Odds = [
  //               ...update.Odds,
  //               {
  //                 OddID: odd.OddID,
  //                 Rate: change.oddRate.toFixed(2),
  //                 ...(change.oddStatus === 'active'
  //                   ? { BetStatus: 'ACT' }
  //                   : change.oddStatus === 'suspended'
  //                   ? { BetStatus: 'SUS' }
  //                   : change.oddStatus === 'closed'
  //                   ? { BetStatus: 'EXP' }
  //                   : {})
  //               }
  //             ];
  //           }
  //         });
  //       });
  //       return update;
  //     }),
  //     filter(update => !!update),
  //     map(update =>
  //       ticketActions.update({
  //         ticket: update
  //       })
  //     )
  //   )
  // );

  refresAfterIdle$ = createEffect(() =>
    this._appVisibilityService.visibilityChangeReveal.pipe(
      filter(revealAfter => revealAfter > TICKET_REFRESH_INACTIVE_TIMEOUT),
      map(() => ticketActions.refreshTicket())
    )
  );

  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _ticketService: TicketService,
    private _signarService: SignalrService,
    private _appVisibilityService: AppVisibilityService
  ) {}
}
