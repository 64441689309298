import { CUSTOM_ELEMENTS_SCHEMA, Inject, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CaptchaComponent } from './captcha/captcha.component';

import { CaptchaService } from './captcha.service';
import { DOCUMENT } from '@angular/common';
import { oneLine } from '@core/functions/oneLine';
import { environment } from '@environment';

@NgModule({
  imports: [TranslateModule],
  declarations: [CaptchaComponent],
  providers: [CaptchaService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CaptchaModule {
  constructor(@Inject(DOCUMENT) document: Document) {
    const gCaptcha = document.createElement('script');
    gCaptcha.src = oneLine`${environment.staticUrl}/mobile/recaptcha/dist/g-recaptcha.js`;
    document.body.appendChild(gCaptcha);
  }
}
