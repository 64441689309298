<div class="container">
  <ion-label mode="ios">
    <h2>{{ 'wallet_balance' | translate }}</h2>
    <h1>{{ balance$ | async | formatNumberWithCurrency : { decimalDigits: configuration.base.defaultCurrency.numberDecimalPlaces } }}</h1>
    <h2
      [routerLink]="'TransactionHistory' | translateRoute"
      *ngIf="showTransactionHistory"
    >
      {{ 'wallet_view_history' | translate }}
      <ion-icon class="icon-ic_history"></ion-icon>
    </h2>
  </ion-label>
</div>
