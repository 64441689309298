import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * describe notice style
 */
enum NotieStyle {
  info = 'info',
  alert = 'alert'
}

/**
 * How to use notice component
 *
 * example:
 *
 * <doxx-new-notice
 *  (btnClick)="test()"
 *  [style]="'info'"
 *  [btnText]="'zrusit' | translate"
 *  [header]="'Lorem_Ipsum' | translate"
 *  [message]="'Lorem_Ipsum_is_simply' | translate">
 * </doxx-new-notice>
 *
 * and more ->
 */
@Component({
  selector: 'doxx-new-notice',
  templateUrl: './new-notice.component.html',
  styleUrls: ['./new-notice.component.scss']
})
export class NewNoticeComponent implements OnInit {
  // nastavenie stylu na info alebo alert
  @Input() style: NotieStyle;
  // text hlavicky, v stringu mozu byt html tagy
  @Input() header: string;
  // text pod hlavickou, v stringu mozu byt html tagy
  @Input() message: string;
  // button text
  @Input() btnText;

  // custom nastavnie ikony ak treba inu ako je v @param style
  @Input() icon: string;
  // custom nastavnie farby ikony ak treba inu ako je v @param style
  @Input() iconColor: string;
  // custom nastavnie pozadia ak treba ine ako je v @param style
  @Input() background: string;

  // emitter pri stlaceni na button
  @Output() btnClick = new EventEmitter<void>();

  constructor() {}

  /**
   * Emit when click on buton
   */
  emitClick(): void {
    this.btnClick.emit();
  }

  /**
   * Chose color scheme base on this.style property
   */
  ngOnInit(): void {
    switch (this.style) {
      // modre s i ikonkou
      case 'info':
        this.icon = 'icon-ic_info-circle-filled';
        this.iconColor = 'var(--ion-color-blue)';
        this.background = '#E8F5FE';
        break;

      // zlte s ! ikonkou
      case 'alert':
        this.icon = 'icon-ic_alert-fill';
        this.iconColor = '#E2C241';
        this.background = '#F7F2E8';
        break;
    }
  }
}
