import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'formatNumberFractions'
})
export class FormatNumberFractionsPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  /**
   *  Change size of integer and fraction part of number. Should be used after formatNumberWithCurrency pipe since this pipe expect correctly formatted currency
   * @param currency Currency (formatted number)
   * @param props integerSize - size of integer part of an number (includes currency identifier), fractionsSize - size of fractions size of an number (includes fractions separator)
   * @returns formatted HTML
   */
  transform(
    currency: string,
    props: { integerSize: number; fractionsSize: number }
  ): SafeHtml {
    const validFractionsSeparators = [',', '.'];

    let fractionsSeparator: string | undefined;

    validFractionsSeparators.forEach(separator => {
      if (currency.includes(separator)) {
        fractionsSeparator = separator;
      }
    });

    // return currency if no fractions point is present
    if (!fractionsSeparator) {
      return this._sanitizer.bypassSecurityTrustHtml(
        `<span style='font-size: ${props.integerSize}px'>${currency}</span>`
      );
    }

    const currencyParts = currency.split(fractionsSeparator);

    const integer = currencyParts[0];
    // get fractions and delete all non-numeric characters
    const fractions = currencyParts[1].replace(/\D/g, '');
    const fractionsIdentifier = currencyParts[1].replace(/[0-9]/g, '');

    if (fractionsIdentifier && fractionsIdentifier.trim()) {
      return this._sanitizer.bypassSecurityTrustHtml(
        `<span style='font-size:${
          props.integerSize
        }px'>${integer}</span><span style='font-size:${
          props.fractionsSize
        }px'>${fractionsSeparator + fractions}</span><span style='font-size:${
          props.integerSize
        }px'>${fractionsIdentifier}</span>`
      );
    }

    return this._sanitizer.bypassSecurityTrustHtml(
      `<span style='font-size: ${
        props.integerSize
      }px'>${integer}</span><span style='font-size: ${props.fractionsSize}px'>${
        fractionsSeparator + fractions
      }</span>`
    );
  }
}
