// https://stackoverflow.com/questions/42972386/prevent-click-event-on-long-press
/* eslint-disable @angular-eslint/directive-class-suffix, @angular-eslint/directive-selector, @angular-eslint/no-output-on-prefix */

import {
  Directive,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  HostListener
} from '@angular/core';

@Directive({ selector: '[longPress]' })
export class LongPressDirective {
  @Output()
  longPress = new EventEmitter();
  private _isLong = false;
  private _isEmitted = false;

  private _timeout: any;

  @HostListener('mousedown')
  @HostListener('touchstart')
  onTouchStart() {
    this._timeout = setTimeout(() => {
      this._isLong = true;
      this._isEmitted = false;
      if (!this._isEmitted) {
        this.longPress.emit();
        this._isEmitted = true;
      }
    }, 500);
  }

  @HostListener('mouseup')
  @HostListener('touchend')
  onTouchEnd(e) {
    clearTimeout(this._timeout);
    if (this._isLong && !this._isEmitted) {
      this.longPress.emit(e);
      this._isEmitted = true;
    }
  }

  @HostListener('mouseleave')
  @HostListener('touchcancel')
  onTouchCancel(e) {
    this._isLong = false;
    clearTimeout(this._timeout);
  }

  @HostListener('touchmove') onTouchMove() {
    this._isLong = false;
    clearTimeout(this._timeout);
  }
}
