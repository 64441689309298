import { TicketHistoryListItem } from '@models/TicketHistory';
import { createAction, props } from '@ngrx/store';

export const loadBuybackTickets = createAction(
  '[BuyBack] Load Buyback Tickets'
);

export const loadBuybackTicketsSuccess = createAction(
  '[BuyBack] Load Buyback Tickets Success',
  props<{
    data: {
      AmountMoney: number;
      AmountMoneyWin: number;
      ClientID: number;
      EventIDs: number[];
      IsFreebetTicket: number;
      TicketCode: string;
      TicketType: string;
    }[];
  }>()
);
export const loadBuybacTicketsFailure = createAction(
  '[BuyBack] Load Buyback Tickets Failure',
  props<{ error: any }>()
);

export const loadBuybackValues = createAction(
  '[BuyBack] Load Buyback Values',
  props<{ ticketCode: TicketHistoryListItem['ticketCode'] }>()
);
export const loadBuybackValuesSuccess = createAction(
  '[BuyBack] Load Buyback Values Success',
  props<{
    ticketCode: TicketHistoryListItem['ticketCode'];
    buybackValues: {
      AutoBuyBackValue: number;
      BuyBackDelayMs: number;
      BuyBackValue: number;
      IsBuyBack: boolean;
    };
  }>()
);
export const loadBuybackValuesFailure = createAction(
  '[BuyBack] Load Buyback Values Failure',
  props<{
    ticketCode: TicketHistoryListItem['ticketCode'];
    error: any;
  }>()
);

export const updateBuybackValue = createAction(
  '[BuyBack] Update Buyback Values',
  props<{
    changes: {
      ticketCode: TicketHistoryListItem['ticketCode'];
      changes: {
        BuyBackValue?: number;
        AutoBuyBackValue?: number;
      };
    }[];
  }>()
);

export const executeBuyback = createAction(
  '[BuyBack] Execute Buyback',
  props<{
    ticketCode: TicketHistoryListItem['ticketCode'];
    buybackValue: number;
  }>()
);
export const executeBuybackSuccess = createAction(
  '[BuyBack] Execute Buyback Success',
  props<{
    ticketCode: TicketHistoryListItem['ticketCode'];
    data: {
      AutoBuyBackValue: number;
      BuyBackDelayMs: number;
      BuyBackValue: number;
      IsBuyBack: boolean;
    };
  }>()
);

export const executeBuybackFailure = createAction(
  '[BuyBack] Execute Buyback Failure',
  props<{
    ticketCode: TicketHistoryListItem['ticketCode'];
    error: any;
  }>()
);

// execute autobuyback
export const executeAutoBuyback = createAction(
  '[BuyBack] Execute Auto Buyback',
  props<{
    ticketCode: TicketHistoryListItem['ticketCode'];
    autoBuybackValue: number;
  }>()
);
export const executeAutoBuybackSuccess = createAction(
  '[BuyBack] Execute Auto Buyback Success',
  props<{
    ticketCode: TicketHistoryListItem['ticketCode'];
    data: {
      AutoBuyBackValue: number;
      BuyBackDelayMs: number;
      BuyBackValue: number;
      IsBuyBack: boolean;
    };
  }>()
);

export const executeAutoBuybackFailure = createAction(
  '[BuyBack] Execute Auto Buyback Failure',
  props<{
    ticketCode: TicketHistoryListItem['ticketCode'];
    error: any;
  }>()
);

export const removeTicket = createAction(
  '[BuyBack] Remove Ticket',
  props<{
    ticketCode: TicketHistoryListItem['ticketCode'];
  }>()
);
