<div 
  class="flex flex-nowrap items-center w-full h-20 p-5 gap-5 bg-no-repeat bg-right bg-cover rounded-md" 
  [style.background-image]="'url(' + image + ')'"
  [routerLink]="'Quickbet' | translateRoute"
>
    <!-- Icon -->
    <div class="w-6 h-6">
        <img *ngIf="icon" [src]="icon" alt="quickbet sport" class="text-gray-400">
    </div>

    <!-- Chip and title -->
    <div class="flex flex-col gap-1 flex-1 font-sans">
        <!-- Chip -->
        <span *ngIf="isNew" class="bg-[#F40034] rounded-sm px-1 py-px uppercase font-semibold text-white text-[10px] w-fit">{{'quickbet_new' | translate}}</span>
        <!-- Text -->
        <span class="font-semibold text-white text-lg">{{'quickbet_bet' | translate}}</span>
    </div>

    <!-- Arrow -->
    <i class="text-base text-white icon icon-ic_arrow"></i>
</div>
