import { Pipe, PipeTransform } from '@angular/core';
import { visibility } from '@models/Visibility';
import { ParamGroupWrapper } from 'src/app/pages/detail/detail/store/detail.selectors';

@Pipe({
  name: 'showParamGroupWrapper'
})
export class ShowParamGroupWrapperPipe implements PipeTransform {
  /**
   * Slugify any string, function wraps function from  package https://www.npmjs.com/package/slugify
   * with defaul config for our application
   */
  transform(group: ParamGroupWrapper): boolean {
    const ids = Object.keys(group.eventChanceTypes);
    let i = 0;
    while (group.eventChanceTypes[ids[i]]) {
      if (group.eventChanceTypes[ids[i]].Visibility !== visibility.HIDE) {
        return true;
      }
      i++;
    }
    return false;
  }
}
