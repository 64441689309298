import {
  sequence,
  trigger,
  animate,
  style,
  group,
  query,
  transition,
  animateChild,
  state,
  animation,
  useAnimation,
  stagger,
  keyframes
} from '@angular/animations';
import { duration } from 'moment';

export const openCloseArrowAnimation = [
  trigger('openCloseArrow', [
    state(
      '0, void',
      style({ transform: 'rotate(90deg)', display: 'inline-block' })
    ),
    state(
      '1, *',
      style({ transform: 'rotate(-90deg)', display: 'inline-block' })
    ),
    transition('* <=> void', animate('0ms'))
  ])
];

const ODD_RATE_UP_COLOR = '#72CD21';
const ODD_RATE_DOWN_COLOR = '#FEEBEF';

export const oddsAnimations = [
  trigger('oddRateChanged', [
    transition(':increment', [
      group([
        query('@oddRateArrow', [animateChild()]),
        animate(
          '4s',
          keyframes([
            style({
              color: 'var(--ion-color-light-contrast)'
            }),
            style({
              color: 'var(--color-buyback)'
            }),
            style({ color: 'var(--ion-color-light-contrast)' })
          ])
        )
      ]),
      group([
        query('@oddRateNumbers', [animateChild()]),
        animate(
          '4s',
          keyframes([
            style({
              color: 'var(--ion-color-light-contrast)'
            }),
            style({
              color: 'var(--color-buyback)'
            }),
            style({
              color: 'var(--ion-color-light-contrast)'
            })
          ])
        )
      ])
    ]),
    transition(':decrement', [
      group([
        query('@oddRateArrow', [animateChild()]),
        animate(
          '4s',
          keyframes([
            style({
              color: 'var(--ion-color-light-contrast)'
            }),
            style({
              // textDecorationColor: ODD_RATE_DOWN_COLOR,
              color: 'var(--ion-color-secondary)'
            }),
            style({
              color: 'var(--ion-color-light-contrast)'
            })
          ])
        )
      ]),
      group([
        query('@oddRateNumbers', [animateChild()]),
        animate(
          '4s',
          keyframes([
            style({
              color: 'var(--ion-color-light-contrast)'
            }),
            style({
              // textDecorationColor: ODD_RATE_DOWN_COLOR,
              color: 'var(--ion-color-secondary)'
            }),
            style({
              color: 'var(--ion-color-light-contrast)'
            })
          ])
        )
      ])
    ])
  ]),
  // trigger('oddRateChanged', [
  //   transition(':increment', [
  //     group([
  //       query('@oddRateNumbers', [animateChild()]),
  //       animate(
  //         '4s',
  //         keyframes([
  //           style({
  //             color: '#000'
  //           }),
  //           style({
  //             color: '#fff'
  //           }),
  //           style({ color: '#000' })
  //         ])
  //       )
  //     ])
  //   ]),
  //   transition(':decrement', [
  //     group([
  //       query('@oddRateNumbers', [animateChild()]),
  //       animate(
  //         '4s',
  //         keyframes([
  //           style({
  //             backgroundColor: '#ffffff'
  //           }),
  //           style({
  //             // textDecorationColor: ODD_RATE_DOWN_COLOR,
  //             color: 'var(--ion-color-secondary)'
  //           }),
  //           style({
  //             backgroundColor: '#ffffff'
  //           })
  //         ])
  //       )
  //     ])
  //   ])
  // ]),
  trigger('oddRateArrow', [
    transition(':increment', [
      style({
        transform: 'translate(50%, -50%) rotate(-90deg)',
        display: 'block'
      }),
      animate(
        '2s ease-out',
        keyframes([
          style({ top: '80%', opacity: '0', color: 'var(--color-buyback)' }),
          style({ top: '78%', opacity: '1' }),
          style({ top: '70%', opacity: '1' }),
          style({ top: '60%', opacity: '0', color: 'var(--color-buyback)' })
        ])
      )
    ]),
    transition(':decrement', [
      style({
        transform: 'translate(50%, -50%) rotate(90deg)',
        display: 'block'
      }),
      animate(
        '2s ease-out',
        keyframes([
          style({
            top: '60%',
            opacity: '1',
            color: 'var(--ion-color-secondary)'
          }),
          style({ top: '80%' }),
          style({
            top: '90%',
            opacity: '0',
            color: 'var(--ion-color-secondary)'
          })
        ])
      )
    ])
  ]),

  trigger('oddRateNumbers', [
    transition(':increment', [
      style({
        display: 'block',
        color: 'var(--color-buyback)'
      }),
      animate('2s ease-out')
    ]),
    transition(':decrement', [
      style({
        display: 'block',
        color: 'var(--ion-color-secondary)'
      }),
      animate('2s ease-in')
    ])
  ])
];

export const ticketOddsRateAnimations = [
  trigger('ticketOddRateChanged', [
    transition(':increment', [
      animate(
        '3s',
        keyframes([
          style({ backgroundColor: 'green', offset: 0 }),
          style({ backgroundColor: '#7277d', offset: 0.5 }),
          style({ backgroundColor: 'green', offset: 0.7 }),
          style({ backgroundColor: '#7277d', offset: 1.0 })
        ])
      )
    ]),
    transition(':decrement', [
      animate(
        '3s',
        keyframes([
          style({ backgroundColor: 'red', offset: 0 }),
          style({ backgroundColor: '#7277d', offset: 0.5 }),
          style({ backgroundColor: 'red', offset: 0.7 }),
          style({ backgroundColor: '#7277d', offset: 1.0 })
        ])
      )
    ])
  ])
];

export const landingAnimations = [
  trigger('expandCollapse', [
    state(
      'open',
      style({
        height: '*'
      })
    ),
    state(
      'close',
      style({
        height: '0px'
      })
    ),
    transition('open <=> close', animate(200))
  ])
];
