import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';
/* eslint-disable @angular-eslint/directive-selector */

@Directive({
  selector: '[ngVar]'
})
export class VarDirective {
  @Input()
  set ngVar(context: any) {
    this.context.$implicit = this.context.ngVar = context;
    this.updateView();
  }

  context: any = {};

  constructor(
    private _vcRef: ViewContainerRef,
    private _templateRef: TemplateRef<any>
  ) {}

  /**
   * bind variable to view
   */
  updateView(): void {
    this._vcRef.clear();
    this._vcRef.createEmbeddedView(this._templateRef, this.context);
  }
}
