import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketoddsComponent } from './ticketodds.component';
import { FreebetsModalComponent } from '../freebets-modal/freebets-modal.component';
import { RouterModule } from '@angular/router';
import { IsInGroupPipe } from './is-in-group.pipe';
import { IsTopLinePipe } from './is-top-line.pipe';
import { IsBottomLinePipe } from './is-bottom-line.pipe';
import { SharedModule } from '@shared/shared.module';
import { MaterialDesignModule } from '@shared/material-design/material-design.module';

@NgModule({
  declarations: [
    TicketoddsComponent,
    FreebetsModalComponent,
    IsInGroupPipe,
    IsTopLinePipe,
    IsBottomLinePipe
  ],
  imports: [CommonModule, SharedModule, RouterModule, MaterialDesignModule],
  exports: [TicketoddsComponent]
})
export class TicketoddsModule {}
