import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { environment } from '@environment';
import {
  catchError,
  concatMap,
  map,
  retry,
  retryWhen,
  take
} from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import {
  Ticket,
  ITicketHistoryResponse,
  AllowedRateChange,
  ticketFlag,
  TicketOdd,
  Combi
} from '../../models/Ticket';
import { oneLine } from '@core/functions/oneLine';
import { TicketHistroyDetail } from '@models/TicketHistory';
import { DEVICE_CODE } from '@core/core.module';

const CONTROLER_NAMESPACE = '/responsive/web/centralticket';

function isTicket(obj: any): obj is Ticket {
  if (obj && 'Bet' in obj && 'ID' in obj && 'Odds' in obj) {
    return true;
  }
  // for delta ticket there is no effective way to determine if object is ticket
  // but in ticket response only ticket has more than 3 keys in object
  else if (Object.keys(obj || {}).length > 3) {
    return true;
  }
  return false;
}
@Injectable({
  providedIn: 'root'
})
export class TicketService {
  // set default value
  // private url: string =  environment.apiEndpoint;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  constructor(
    private _httpClient: HttpClient,
    @Inject(DEVICE_CODE) private _deviceCode: { getCode(): string }
  ) {}

  /**
   * Remove Odd on ticket
   * @param oddID oddID
   */
  public removeOddd(oddID: number): Observable<Ticket> {
    const headers = new HttpHeaders();

    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/centralticket/oddremove/${oddID}`,
        {
          withCredentials: true
        }
      )
      .pipe(
        catchError(err => {
          if (err.status === 409) {
            return of(err.error);
          }
          return throwError(err);
        }),
        map(resp => {
          return resp;
        })
      );
  }

  /** remove odd list */
  public removeOdds(oddIDs: TicketOdd['OddID'][]): Observable<Ticket> {
    return this._getRequest(
      oneLine`${
        environment.apiUrl
      }${CONTROLER_NAMESPACE}/oddremovelist/${oddIDs.join(',')}`
    );
  }

  /**
   * Ticket switch
   */
  public switchTicket(flag: ticketFlag): Observable<Ticket> {
    return this._httpClient
      .post<Ticket>(
        oneLine`${
          environment.apiUrl
        }${CONTROLER_NAMESPACE}/${this._deviceCode.getCode()}/switch/${flag}`,
        {}
      )
      .pipe(
        catchError(err => {
          if (err.status === 409) {
            return of(err.error);
          }
          return throwError(err);
        }),
        map(resp => {
          if (resp.errors?.label === 'CENTRAL_TICKET_NOT_FOUND') {
            throwError('CENTRAL_TICKET_NOT_FOUND');
          } else {
            return resp;
          }
        }),
        retry(3)
      );
  }

  /**
   * Ticket refresh
   */
  public ticketRefresh(): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/refresh`;
    return this._getRequest(url);
  }

  /**
   * Ticket refresh
   */
  public deltaTicket(): Observable<Partial<Ticket>> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/delta`;
    return this._getRequest(url).pipe(
      map(ticket => {
        delete ticket.IsEdited;
        ticket.Odds?.forEach(odd => {
          delete odd.LiveBet;
          delete odd.IsEditedOdd;
        });
        return ticket;
      })
    );
  }

  /**
   * Add Odd on ticket
   * @param oddID oddID
   */
  public addOdd(oddID: TicketOdd['OddID']): Observable<Ticket> {
    // const url : string  = `${environment.apiUrl}${CONTROLER_NAMESPACE}`+ 'centralticket/oddadd/'  + oddID;
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/oddadd/${oddID}`;
    return this._getRequest(url);
  }

  /**
   * Pridanie viacerych oddov na ticket
   */
  public oddaddlist(oddIDs: TicketOdd['OddID'][]): Observable<Ticket> {
    // const url : string  = `${environment.apiUrl}${CONTROLER_NAMESPACE}`+ 'centralticket/oddadd/'  + oddID;
    const url: string = oneLine`${
      environment.apiUrl
    }${CONTROLER_NAMESPACE}/oddaddlist/${oddIDs.join(',')}`;
    return this._getRequest(url);
  }

  /**
   * Remove Odd from ticket
   * @param oddID oddID
   */
  public removeOdd(oddID: TicketOdd['OddID']): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/oddremove/${oddID}`;
    return this._getRequest(url);
  }

  /**
   * Reset ticket
   * @param oddID oddID
   */
  public clearTicket(): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/ticket/reset`;
    return this._getRequest(url);
  }

  /**
   * Set total bet - JT ticket
   * @param totalBet totalbet
   */
  public setTotalBet(totalBet: string): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/settotalbet/${totalBet}`;
    return this._getRequest(url);
  }

  /**
   * Place bet
   */
  public placeBet(glag): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/placebet/${glag}/balance`;
    return this._getRequest(url);
  }

  /**
   * Edit ticket
   */
  public editTicket(ticketCode): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/edit/${ticketCode}`;
    return this._getRequest(url);
  }

  /**
   * cancel editation
   */
  public cancelEditation(): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/edit/cancel`;
    return this._getRequest(url);
  }

  /** subscribe to buyback changes */
  public subscribe(
    ticketCode: Ticket['PlaceBetTicketCode'],
    operation: 'SUBSCRIBE' | 'UNSUBSCRIBE'
  ): Observable<any> {
    return this._httpClient.get(
      oneLine`${environment.apiUrl}/centralticket/subscription/${ticketCode}/${operation}`
    );
  }

  /** buyback odd */
  public oddBuyback(oddID: TicketOdd['OddID']): Observable<Ticket> {
    return this._getRequest(
      `${environment.apiUrl}/centralticket/odd/buyback/${oddID}`
    );
  }

  /** buyback odd rest */
  public oddBuybackReset(oddID: TicketOdd['OddID']): Observable<Ticket> {
    return this._getRequest(
      `${environment.apiUrl}/centralticket/odd/buyback/${oddID}/reset`
    );
  }

  /** clone ticket */
  public cloneTicket(
    ticketCode: TicketHistroyDetail['ticketCode']
  ): Observable<any> {
    return this._getRequest(
      `${environment.apiUrl}${CONTROLER_NAMESPACE}/clone/ticketcode/${ticketCode}`
    );
  }

  /**
   * Check ticket
   */
  public checkTicket(): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}/centralticket/refresh`;
    return this._getRequest(url);
  }

  /**
   * Change ticket type
   */
  public changeTicketType(ticketType: string): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/type/${ticketType}`;
    return this._getRequest(url);
  }

  /**
   * Store ticket
   */
  public storeTicket(): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/store`;
    return this._getRequest(url);
  }

  /**
   * Restore ticket
   */
  public restoreTicket(ticketCode: string): Observable<any> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/restore/code/${ticketCode}`;
    return this._getRequest(url);
  }

  /**
   * Remove ticket???
   */
  public removeTicket(ticketCode: string): Observable<Ticket> {
    const url: string = oneLine`${
      environment.apiUrl
    }${CONTROLER_NAMESPACE}/${this._deviceCode.getCode()}/store/remove/code/${ticketCode}`;
    return this._getRequest(url);
  }

  /**
   * Detail stored ticket
   */
  public detailTicket(ticketCode: string): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/store/detail/code/${ticketCode}`;
    return this._getRequest(url);
  }

  /**
   * Short ticket
   */
  public shortTicket(ticketCode: string): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}
      /centralticket/${this._deviceCode.getCode()}
      /store/requestpin/${ticketCode}
      /ispublic/1`;
    return this._getRequest(url);
  }

  /**
   * Aktivacia  Freebetu
   */
  public activateFreebet(freebetID: string): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/setfreebet/${freebetID}`;
    return this._getRequest(url);
  }

  /**
   * Deaktivacia  Freebetu
   */
  public deactivateFreebet(): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/removefreebet`;
    return this._getRequest(url);
  }

  /**
   * Create ticket group
   */
  public oddTag(oddIds: TicketOdd['OddID'][]): Observable<Ticket> {
    const url: string = oneLine`${
      environment.apiUrl
    }${CONTROLER_NAMESPACE}/oddtag/${oddIds.join(',')}`;
    return this._getRequest(url);
  }

  /**
   * Cancel ticket group
   */
  public oddUntag(oddIds: TicketOdd['OddID'][]): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/
      odduntag/${oddIds.join(',')}`;
    return this._getRequest(url);
  }

  /**
   * Top ticket odd
   */
  public oddTop(oddIds: TicketOdd['OddID'][]): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/
      oddtop/${oddIds.join(',')}`;
    return this._getRequest(url);
  }

  /**
   * Set AllowedRateChange
   */
  public setallowedratechange(type: AllowedRateChange): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/setallowedratechange/${type}`;
    return this._getRequest(url);
  }

  /**
   * Get ticket detail by ticket GUID (sportbox ticket)
   */
  public guidTicketCheck(
    ticketGUID: string
  ): Observable<ITicketHistoryResponse> {
    const url: string = oneLine`${environment.apiUrl}/web/bp/credit/check/${ticketGUID}`;
    return this._httpClient
      .get<ITicketHistoryResponse>(url, {
        withCredentials: true
      })
      .pipe(
        catchError(err => {
          if (err.status === 409) {
            return of(err.error);
          }
          return throwError(err);
        }),
        map(resp => {
          return resp;
        })
      );
  }

  // COMBINATION TICKET
  /**
   * 2. Stavka na ROZPIS (celkovo, parcialne)
   */
  public setCombinationStake(bet, combiID, isPartial): Observable<Ticket> {
    // console.log('setcombinationstake');
    // console.log(bet);
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}
        /setcombinationstake/${['own', 'vlastný'].includes(bet) ? 0 : bet}
        /combinationid/${combiID}
        /isparcial/${isPartial ? 1 : 0}`;
    // console.log(url);
    return this._getRequest(url);
  }

  /**
   * 3. Stavka na konkretnu KOMBINACIU
   */
  public setSpellStake(bet, combiLetters): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}
      /setspellstake/${bet}
      /spell/${combiLetters}`;
    // console.log('setPerCombinationStake');
    return this._getRequest(url);
  }

  /**
   * Odpinnutie kombinacie
   */
  public unpinCombination(combi?, combiLetters?): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}
      /unpincombination/${combi || 'all'}
      /spell/${combiLetters || 'all'}`;
    return this._getRequest(url);
  }

  /**
   * 5. Lock / unlock vkladov - nerozpocitova nepinnuty vklad medzi kombinacie
   */
  public lockTicket(): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/lock`;
    return this._getRequest(url);
  }

  /**
   * Odomknutie tiketu
   */
  public unlockTicket(): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/unlock`;
    return this._getRequest(url);
  }

  /**
   * Aktivacia kombinacie
   */
  public activateCombi(combination: Combi['ID']): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/activatecombi/${combination}`;
    return this._getRequest(url);
  }

  /**
   * deaktivacia kombinacie
   */
  public deactivateCombi(combination: Combi['ID']): Observable<Ticket> {
    const url: string = oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/deactivatecombi/${combination}`;
    return this._getRequest(url);
  }

  /**
   * Get Request
   * @param url url
   */
  private _getRequest(url: string): Observable<Ticket> {
    // const headers = new HttpHeaders();
    return this._httpClient
      .get<any>(url, {
        withCredentials: true
      })
      .pipe(
        // z erroru spravi response, pretoze BE si errory posiela ako chce a neda sa na to spolahnut
        catchError(err => {
          return of(err instanceof HttpErrorResponse ? err.error : err);
        }),
        // ak z BE pride CENTRAL_TICKET_NOT_FOUND treba este zavolat switch tiketu a zopakovat volanie
        concatMap(resp => {
          if (resp.errors?.label === 'CENTRAL_TICKET_NOT_FOUND') {
            return throwError(() => resp);
          }
          return of(resp);
        }),
        retryWhen(errors =>
          errors.pipe(
            concatMap(() => this.switchTicket('current')),
            take(3)
          )
        ),
        // z response treba vyparsovat tiket, bud pride priamo alebo zasity ako data.centralTicket.centralTicket
        // errory nas nemusia zaujimat, java BE spravi len toto resp.errors.centralTicket.ticket = data.centralTicket.centralTicket.Errors.map(error => ({label: error})),
        // cize tie errory su dostupne aj v tikete
        map(resp => {
          if (isTicket(resp.data?.centralTicket?.centralTicket)) {
            return resp.data.centralTicket.centralTicket;
          }
          if (isTicket(resp)) {
            return resp;
          }
          throw resp;
        })
      );
  }

  /**
   * Get Slider values
   * Synchronous
   */
  // getBetSliderValues(): any {
  //   return this.betSliderValues;
  // }

  /**
   * TODO: Zjednotit s oddmi v tikete
   * Nastavi group label oddov v kombinacnom tikete
   * @param odds pole oddov
   */
  sortOdds(odds: any): void {
    if (odds.length === 0) {
      return;
    }

    function compare(a: { group: number }, b: { group: number }): number {
      if (a.group < b.group) {
        return -1;
      }
      if (a.group > b.group) {
        return 1;
      }
      return 0;
    }

    odds.sort(compare);
    // odds =  odds.sort((a, b) => (a.group > b.group) ? 1 : -1)

    // nastavenie nulteho oddu
    odds[0].GroupValue = odds[0].group;
    odds[0].drowline2 = false;

    for (let i = 1; i < odds.length; i++) {
      const prevOdd = odds[i - 1];
      const odd = odds[i];
      const nextOdd = odds[i + 1];
      odd.drowline2 = true;

      // osetrenie posledneho oddu a konca skupiny
      if (nextOdd === undefined || odd.group !== nextOdd.group) {
        // ak je predchadzajuci odd === '.' alebo rovnaky ako aktualny mame koniec skupiny
        if (prevOdd.GroupValue === '.' || prevOdd.GroupValue === odd.group) {
          odd.GroupValue = 'X';
          odd.drowline2 = false;
          prevOdd.drowline2 = true;
          // jednoprvkova skupina
        } else {
          odd.drowline2 = false;
          odd.GroupValue = odd.group;
        }

        // koniec pola
        if (nextOdd === undefined) {
          // odd.drowline2 = false;
          break;
        }
      }

      // osetrenie zaciatku a stredu skupiny
      if (odd.group === nextOdd.group) {
        // zaciatok skupiny
        // odd.drowline2 = false;
        if (
          prevOdd.GroupValue === 'X' ||
          // (prevOdd.group === '1' &&
          //   prevOdd.GroupValue === '1' &&
          //   odd.group !== '1') (
          // fix groupvalue vs. group nemoze porovnavat explicitne
          (prevOdd.group === prevOdd.GroupValue && odd.group !== prevOdd.group)
        ) {
          odd.GroupValue = odd.group;
          odd.drowline2 = true;
          // stred skupiny
        } else {
          prevOdd.drowline2 = true;
          odd.GroupValue = '.';
        }
      }
    }
  }
}
