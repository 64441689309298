import * as fromTicket from './ticket.reducer';
import { createSelector, createFeatureSelector } from '@ngrx/store';

/* TICKET SELECTORS */
export const selectTicketState = createFeatureSelector(
  fromTicket.TICKET_STATE_KEY
);

export const selectTicket = createSelector(
  selectTicketState,
  (state: fromTicket.TicketState) => state.ticket
);

export const selectOddIds = createSelector(selectTicket, ticket =>
  (ticket?.Odds || []).map(odd => odd.OddID)
);

export const selectCombi = createSelector(selectTicket, ticket => ticket.Combi);

export const selectOdds = createSelector(
  selectTicket,
  ticket => ticket?.Odds || []
);

export const selectHasOdds = createSelector(
  selectTicket,
  ticket => ticket?.Odds?.length > 0
);

export const selectFreebets = createSelector(
  selectTicket,
  ticket => ticket.Freebets
);

export const selectActiveFreebet = createSelector(selectTicket, ticket =>
  ticket.Freebets.find(freebet => freebet.IsActive)
);

export const selectAllowedRateChange = createSelector(
  selectTicket,
  ticket => ticket?.AllowedRateChangeDesc
);

export const selectTicketErrors = createSelector(
  selectTicket,
  ticket => ticket?.Errors || []
);
