<div class="blue-text icon icon-ic_info top-icon"></div>
<ion-row class="ion-text-center bold modal-header">
  <ion-col class="bold">{{ 'menu_favourites' | translate }}</ion-col>
</ion-row>
<ion-row class="info pd-btm">
  <ion-col>
    <span>
      {{ 'favourite_login_modal_description' | translate }}
    </span>
  </ion-col>
</ion-row>
<div>
  <ion-row>
    <ion-col> </ion-col>
  </ion-row>
</div>

<ion-footer>
  <ion-row>
    <ion-button
      class="footer-btn"
      expand="block"
      (click)="closeModal()"
      [routerLink]="'Register' | translateRoute"
    >
      <span class="capitalize-first">
        {{ 'menu_register' | translate }}
      </span>
    </ion-button>
    <ion-button class="footer-btn" expand="block">
      <span
        class="capitalize-first"
        (click)="closeModal()"
        [routerLink]="'Login' | translateRoute"
      >
        {{ 'menu_login' | translate }}
      </span>
    </ion-button>
  </ion-row>
</ion-footer>
