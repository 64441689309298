import { Pipe, PipeTransform } from '@angular/core';
import { Event } from '@models/Event';
import { TopEvent } from '@models/TopEvent';

@Pipe({
  name: 'hasMatchTracker',
  pure: false
})
export class HasMatchTrackerPipe implements PipeTransform {
  /**
   * Pipe for determining, whether to display matchtracker
   * @param ev sport event
   * @returns return boolean, true if matchtracker is valid for event
   */
  transform(ev: Event | any): any {
    if (ev) {
      return ev.HasMatchTracker;
    } else {
      return false;
    }
  }
}
