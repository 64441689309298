<div class="sticky top-0 w-full z-20">
  <doxx-main-header
    [showRegisterButton]="false"
    [showLoginButton]="false"
    [showSearchButton]="false"
  >
    <doxx-main-header-back-button (click)="hideTicket()" [disabled]="true">
    </doxx-main-header-back-button>
    <ion-title>
      <h1>
        {{ 'ticket_leftTitle' | translate }}
        <i
          class="icon icon-ic_trash"
          *ngIf="!ticket?.IsEdited"
          (click)="clearTicket()"
        ></i>
        <span *ngIf="ticket?.IsEdited">
          {{ ticket?.PlaceBetTicketCode }}
        </span>
      </h1>
    </ion-title>
  </doxx-main-header>
</div>

<doxx-empty-ticket
  class="pt-4 block"
  *ngIf="!(ticket?.Odds?.length > 0)"
></doxx-empty-ticket>
<div *ngIf="ticket?.Odds?.length > 0" class="relative">
  <ion-toolbar
    class="sticky top-[60px]"
    mode="ios"
    *ifAvailable="{ and: ['betslip.combinations', !ticket.IsEdited] }"
  >
    <ion-segment
      [value]="ticket.TYPE"
      (ionChange)="changeTicketType($event.detail.value)"
    >
      <ion-segment-button value="JT">
        {{ 'ticket_simple' | translate }}</ion-segment-button
      >
      <ion-segment-button value="SRT">
        {{ 'ticket_combined' | translate }}
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>

  <div *ngIf="ticket.IsEdited" class="p-2">
    <button
      class="text-accent w-full rounded border border-gray-200 bg-transparent border-solid py-3"
      (click)="cancelEditation()"
    >
      {{ 'ticket_editing_button_cancel_editing' | translate }}
    </button>
  </div>

  <div mode="ios">
    <doxx-new-notice
      *ngIf="ticket._hasSuspendedOdds === true"
      (btnClick)="removeInactiveOdds()"
      [style]="'info'"
      [btnText]="'ticket_notice_removed_btn' | translate"
      [message]="'ticket_notice_removed_msg' | translate"
    >
    </doxx-new-notice>

    <doxx-new-notice
      *ngIf="ratexOdds.length > 0"
      (btnClick)="acceptRatexOdds()"
      [style]="'info'"
      [btnText]="'OK' | translate"
      [message]="'ticket_course_info' | translate"
    >
    </doxx-new-notice>

    <!-- waitingRegistrationNotice -->
    <ng-container *ngIf="user && user?.status === 'waiting'">
      <doxx-new-notice
        [style]="'alert'"
        [header]="'account_info_when_user_status_is_waiting_header' | translate"
        [message]="'account_info_when_user_status_is_waiting' | translate"
      >
      </doxx-new-notice>
    </ng-container>
    <!-- maybeGamblingProhibitedOrVerifiedNotice -->
    <ng-container *ngIf="user && user?.status !== 'waiting'">
      <doxx-new-notice
        *ngIf="('verification' | available) && !user.verified"
        [style]="'alert'"
        [btnText]="'identity_verification_general_msg_10' | translate"
        [message]="'identity_verification_general_msg_6' | translate"
        [header]="'identity_verification_general_msg_11' | translate"
        background="#F7F2E8"
        (btnClick)="openVerification()"
      >
      </doxx-new-notice>

      <doxx-new-notice
        *ngIf="('verification.rvo' | available) && user.gamblingProhibited"
        [style]="'alert'"
        [header]="'gamblingprohibited_header' | translate"
        [message]="'gamblingprohibited_msg' | translate"
      >
      </doxx-new-notice>
    </ng-container>

    <ion-grid>
      <doxx-ticketodds (removeOdd)="removeOdd($event)" [ratexOdds]="ratexOdds">
        <div
          *ngIf="ticket?.IsEdited"
          (click)="openPrematch()"
          slot="start"
          class="px-5 flex justify-between items-center bg-white border-b h-14"
        >
          <span class="font-bold text-gray-800">
            {{ 'ticket_editing_button_add_events' | translate }}
          </span>
          <i class="icon-ic_plus text-2xl text-gray-400"></i>
        </div>
      </doxx-ticketodds>

      <doxx-ticketcombinations *ngIf="ticket.TYPE === 'SRT'">
      </doxx-ticketcombinations>

      <div class="ticket-bottom">
        <!-- input field + slider-->
        <div class="padding-around">
          <ion-row>
            <ion-col>
              <h6>
                {{ 'ticket_your_bet' | translate }}
                <i
                  *ngIf="ticket.TYPE === 'SRT'"
                  class="icon icon-ic_info-circle-filled"
                  (click)="showSrtTooltyp()"
                >
                </i>
              </h6>
              <ion-item
                class="bet"
                [ngClass]="{
                  freebet: user && ticket.Freebets?.length,
                  edited: ticket.IsEdited,
                  'text-secondary': !ticket.IsEdited
                }"
              >
                <!-- TODO:  zobrazovat, len lognutemu userovi bug na backende (freebety chodia aj po odhlaseni) -->
                <ion-button
                  slot="start"
                  expand="full"
                  *ngIf="
                    !ticket.IsEdited &&
                    user &&
                    ticket.Freebets?.length &&
                    ticket.TYPE === 'JT'
                  "
                  (click)="openFreebetsModal()"
                  [ngClass]="{
                    green: !!ticket._activeFreebet?.FreebetID
                  }"
                >
                  <ion-icon
                    slot="start"
                    name="checkmark-outline"
                    *ngIf="!!ticket._activeFreebet?.FreebetID"
                  ></ion-icon>
                  {{ ticket._activeFreebet?.FreebetName || 'Freebet' }}
                </ion-button>
                <input
                  spyFocus
                  #inputSpyFocus="spyFocus"
                  [type]="inputSpyFocus.focused ? 'number' : 'decimal'"
                  inputmode="decimal"
                  [ngModel]="
                    inputSpyFocus.focused
                      ? undefined
                      : (bet
                        | formatNumber
                          : {
                              decimalDigits:
                                configuration.base.defaultCurrency
                                  .numberDecimalPlaces
                            })
                  "
                  (focus)="$event.target.value = null; focusBet()"
                  (blur)="changeTicketBet(+$event.target.value)"
                  (ngModelChange)="changeVisibleBet(+$event)"
                  [disabled]="
                    ticket.IsEdited || ticket.IsFreeBetTicket === true
                  "
                  step="0.5"
                />
                <ion-label>{{
                  'base.defaultCurrency.symbol' | conf
                }}</ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="!ticket.IsEdited">
            <ion-col>
              <ion-range
                spyFocus
                #rangeSpyFocus="spyFocus"
                (ionFocus)="focusBet()"
                (ngModelChange)="changeVisibleBet(sliderToBet($event))"
                (ionBlur)="changeTicketBet(sliderToBet($event.target.value))"
                (mouseup)="$event.target.blur()"
                (touchend)="$event.target.blur()"
                [ngModel]="rangeSpyFocus.focused ? undefined : betToSlider(bet)"
                [min]="0"
                [max]="sliderValues.length - 1"
                step="1"
                [disabled]="ticket.IsFreeBetTicket === true"
                [snaps]="true"
                [ticks]="false"
              >
              </ion-range>
            </ion-col>
          </ion-row>
        </div>
        <ion-row
          class="bet-shortcut"
          *ngIf="
            !ticket.IsEdited &&
            !ticket._activeFreebet?.FreebetID &&
            ('predefinedBets' | available)
          "
        >
          <ion-col
            *ngFor="
              let betValue of [[1], [5], [10], [20]];
              trackBy: '0' | trackByProperty
            "
            class="ion-text-center"
            [ngClass]="{ red: bet === betValue[0] }"
          >
            <div (click)="changeTicketBet(betValue[0])">
              {{
                betValue[0]
                  | formatNumberWithCurrency
                    : {
                        decimalDigits:
                          configuration.base.defaultCurrency.numberDecimalPlaces
                      }
              }}
            </div>
          </ion-col>
        </ion-row>
        <!-- END input field + slider-->

        <ion-row *ngIf="ticket.TYPE === 'JT'">
          <ion-col>
            <div id="overallRate">
              <div>{{ 'ticket_final_course' | translate }}</div>
              <ion-badge color="secondary">{{
                ticket.OverallRate | rate
              }}</ion-badge>
            </div>
          </ion-col>
          <ion-col *ngIf="ticket.Fee !== '0.00'">
            <div id="overallRate" class="fee">
              <div>{{ 'ticket_fee' | translate }}: &nbsp;</div>
              <div class="ticket-fee">
                {{
                  ticket.Fee
                    | formatNumberWithCurrency
                      : {
                          decimalDigits:
                            configuration.base.defaultCurrency
                              .numberDecimalPlaces
                        }
                }}
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ng-container>
          <div class="padding-bottom" *ifAvailable="'betslip'">
            <doxx-xxl-bonus [ticket]="ticket"> </doxx-xxl-bonus>
          </div>
        </ng-container>
        <ion-row class="padding-bottom" *ifAvailable="'betslip.rateChange'">
          <ion-col>
            <ion-item
              class="action"
              (click)="presentActionSheet()"
              detail="true"
              lines="none"
            >
              <ion-label>
                {{
                  {
                    ALL: 'ticket_accept_any_rate_change',
                    UP: 'ticket_accept_rate_change_up',
                    NONE: 'ticket_accept_no_rate_change'
                  }[ticket.AllowedRateChangeDesc] || 'ticket_accept_any'
                    | translate
                }}
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row
          style="margin-bottom: 15px"
          *ifAvailable="'betslip.showTaxInfo'"
        >
          <ion-col>
            <div style="width: 40%; float: left; padding: 10px">
              <span style="color: #7e848a; float: right">
                {{ 'betslip_tax' | translate }}:
                <span style="color: black">
                  {{
                    ticket.NetWinTax
                      | formatNumberWithCurrency
                        : {
                            decimalDigits:
                              configuration.base.defaultCurrency
                                .numberDecimalPlaces
                          }
                  }}</span
                >
              </span>
            </div>
            <div style="padding: 10px">
              <span style="color: #7e848a">
                {{ 'betslip_tax_eventual_win' | translate }}
              </span>
              <span style="color: black">
                {{
                  ticket.NetWinPayout
                    | formatNumberWithCurrency
                      : {
                          decimalDigits:
                            configuration.base.defaultCurrency
                              .numberDecimalPlaces
                        }
                }}</span
              >
            </div>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="!ticket.IsEdited">
          <ion-col>
            <div
              *ifAvailable="'betslip.saveBetslip'"
              id="addOnTicketBottom"
              (click)="storeTicket()"
            >
              <i class="icon icon-ic_save"></i>
              <span>
                {{ 'ticket_booking_saveticket' | translate }}
              </span>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div class="blankspace"></div>
          </ion-col>
        </ion-row>
      </div>
    </ion-grid>
  </div>

  <ion-footer mode="ios">
    <ion-grid>
      <ion-row class="backdrop-blur">
        <ion-col size="4" class="text">
          <ion-label> {{ 'ticket_eventual_win' | translate }}: </ion-label>
        </ion-col>
        <ion-col class="potential-win">
          <ion-label>
            {{
              ticket.FiscalOverallPotWin || ticket.OverallPotWin
                | formatNumberWithCurrency
                  : {
                      decimalDigits:
                        configuration.base.defaultCurrency.numberDecimalPlaces
                    }
            }}
          </ion-label>
        </ion-col>
        <ion-col class="submit">
          <ion-button
            (click)="submitTicketConfirm()"
            expand="full"
            [ngClass]="ticket.IsEdited ? 'bg-blue' : 'bg-accent'"
            [disabled]="
              betFocused ||
              (user &&
                user?.verified === false &&
                ('verificationallowed' | available)) ||
              !('betslip.allowed' | available)
            "
          >
            {{
              (ticket.IsEdited ? 'ticket_submit_done_editing' : 'ticket_submit')
                | translate
            }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-footer>
</div>
