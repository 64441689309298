<ion-app>
  <doxx-download-app-banner [appType]="appType"> </doxx-download-app-banner>

  <ion-content>
    <ion-menu
      type="push"
      side="start"
      contentId="mainOutlet"
      menuId="mainMenu"
      id="mainMenu"
    >
      <ion-content>
        <ion-list id="inbox-list" class="ion-no-padding">
          <ion-grid
            id="menuLeftScrollableContent"
            class="ion-no-margin ion-no-padding menu-header"
          >
            <!-- user info -->
            <ion-row id="userInfo">
              <ion-col size="2" id="userPhoto" class="ion-padding-start">
                <ion-avatar
                  *ngIf="(isClientLoggedIn$ | async) === true"
                  class="circle is-anchor"
                  routerLink="{{ 'Profile' | translateRoute }}"
                  (click)="closeMenu()"
                >
                  <span
                    *ngIf="!isProfilePicutre"
                    class="icon icon-ic_user"
                  ></span>
                  <img *ngIf="isProfilePicutre" [src]="profilePicutre" />
                </ion-avatar>
                <div
                  *ngIf="(isClientLoggedIn$ | async) === false"
                  class="circle"
                >
                  <span class="icon icon-ic_user"></span>
                </div>
              </ion-col>
              <ion-col
                *ngIf="(isClientLoggedIn$ | async) === false"
                size="8"
                id="userNameBalance"
              >
                <div id="userNameNotLoggedIn">
                  {{ 'menu_not_logged_in' | translate }}
                </div>
              </ion-col>
              <ion-col
                *ngIf="(isClientLoggedIn$ | async) === true"
                size="8"
                id="userNameBalance"
                routerLink="{{ 'Profile' | translateRoute }}"
                (click)="closeMenu()"
                [ngClass]="{ login: location === 'nga' }"
              >
                <div *ngIf="userData && userData.name">
                  {{ userData.name }} {{ userData.surname }}
                </div>
                <div
                  *ngIf="userData && userData.name && location === 'nga'"
                  id="userLogin"
                >
                  <!-- TODO: label -->
                  Login: {{ userData.loginName }}
                </div>
                <div *ngIf="userData && userData.balance" id="userNameValue">
                  {{
                    userData.balanceDecimal
                      | formatNumberWithCurrency
                        : {
                            decimalDigits:
                              configuration.base.defaultCurrency
                                .numberDecimalPlaces
                          }
                  }}
                </div>
              </ion-col>
              <ion-col
                *ngIf="
                  (isClientLoggedIn$ | async) === true || showSettingsWheel()
                "
                id="userSettings"
                size="2"
              >
                <span
                  routerLink="{{ 'Settings' | translateRoute }}"
                  (click)="closeMenu()"
                  class="icon icon-ic_settings is-anchor ion-padding"
                >
                </span>
              </ion-col>
            </ion-row>
            <!-- user points, login, registraton -->
            <ion-row
              id="userPoints"
              [ngClass]="{
                'ion-hide': userData && userData.status === 'waiting'
              }"
            >
              <!-- user points -->
              <ng-container
                *ngIf="
                  (isClientLoggedIn$ | async) === true &&
                  ({
                    or: [{ and: ['casino', 'casinoPoints'] }, 'bettingPoints']
                  } | available)
                "
              >
                <ion-col *ifAvailable="'bettingPoints'">
                  <span class="user-points-label">
                    {{ 'betting' | translate }}
                  </span>
                  <span class="user-points-value">
                    {{ userData.bonusPoints }}
                    {{ 'menu_points_short' | translate }}
                  </span>
                </ion-col>
                <ion-col
                  *ngIf="
                    ({ and: ['casino', 'casinoPoints'] } | available) &&
                    casinoPoints
                  "
                  (click)="closeMenu()"
                  [routerLink]="[
                    'BonusDetailCasino'
                      | translateRoute
                        : {
                            bonusID: KNOWN_BONUSES.LoyaltyBonusCasino,
                            tabIndex: 2
                          }
                  ]"
                >
                  <span class="user-points-label"
                    >{{ 'casino_points' | translate }}
                  </span>
                  <span class="user-points-value">
                    {{ casinoPoints }}
                    {{ 'menu_points_short' | translate }}
                  </span>
                </ion-col>
              </ng-container>
              <!-- login / register -->
              <ng-container *ngIf="(isClientLoggedIn$ | async) === false">
                <ion-col>
                  <ion-button
                    [routerLink]="'Login' | translateRoute"
                    (click)="closeMenu()"
                    expand="full"
                    class="ion-no-margin"
                    [disabled]="!('login' | available)"
                  >
                    {{ 'menu_login' | translate }}
                  </ion-button>
                </ion-col>
                <ion-col>
                  <ion-button
                    [routerLink]="'Register' | translateRoute"
                    (click)="closeMenu()"
                    expand="full"
                    class="ion-no-margin is-highlighted"
                    [disabled]="!('login' | available)"
                  >
                    <span class="icon icon-ic_sign-up1"></span>
                    {{ 'menu_register' | translate }}
                  </ion-button>
                </ion-col>
              </ng-container>
            </ion-row>

            <ng-container
              *ngIf="
                (isClientLoggedIn$ | async) === true &&
                  userData.status === 'waiting';
                then waitingRegistrationNotice;
                else maybeGamblingProhibitedAndVerificationNotice
              "
            ></ng-container>
            <ng-template #waitingRegistrationNotice>
              <ion-row>
                <doxx-new-notice
                  [style]="'alert'"
                  [header]="
                    'account_info_when_user_status_is_waiting_header'
                      | translate
                  "
                  [message]="
                    'account_info_when_user_status_is_waiting' | translate
                  "
                >
                </doxx-new-notice>
              </ion-row>
            </ng-template>
            <ng-template #maybeGamblingProhibitedAndVerificationNotice>
              <!-- userNotVerfied notice -->
              <ion-row
                *ngIf="
                  (isClientLoggedIn$ | async) === true &&
                  notice &&
                  location !== 'nga' &&
                  location !== 'lbr'
                "
              >
                <doxx-notice [noticeSetup]="notice"></doxx-notice>
              </ion-row>

              <!-- gamblingprohibited notice -->
              <ion-row
                *ngIf="
                  ('verification.rvo' | available) &&
                  (isClientLoggedIn$ | async) === true &&
                  userData.gamblingProhibited &&
                  userData.verified
                "
              >
                <doxx-new-notice
                  [style]="'alert'"
                  [header]="'gamblingprohibited_header' | translate"
                  [message]="'gamblingprohibited_msg' | translate"
                >
                </doxx-new-notice>
              </ion-row>
            </ng-template>
          </ion-grid>

          <!-- TODO dorobit 
              - disabled pri maintanance
              - disabled ak je user prihlaseny
             -->
          <ion-grid
            [ngClass]="{ 'ion-hide': userData?.status === 'waiting' }"
            id="menuLeftScrollableContent"
            class="ion-no-margin ion-no-padding menu-content"
          >
            <ng-container
              *ngFor="
                let item of menu;
                trackBy: 'type' | trackByProperty;
                let i = index
              "
            >
              <ion-row *ngIf="item.destination | isScreenAllowed">
                <ion-col>
                  <ion-item
                    class="{{ item.type }} {{ i === 0 ? 'first' : '' }}"
                    [hrefOrRouterLink]="item.destination | translateRoute"
                    (click)="closeMenu()"
                    [disabled]="
                      !(
                        item.destination
                        | isScreenOnlyForLogged : (userSettings$ | async)
                      ) || (item.destination | getSection | isBlocked | async)
                    "
                  >
                    <ion-label
                      (click)="
                        item.title === 'menu_contacts'
                          ? presentContactsPopover($event)
                          : null
                      "
                    >
                      <span *ngIf="item.icon" class="icon">
                        <ion-img [src]="item.icon"></ion-img>
                      </span>
                      {{ item.title | translate }}
                    </ion-label>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ng-container>

            <!-- <ion-row>
              <ion-col>
                <ion-item
                  class="mainitem"
                  (click)="presentContactsPopover()"
                  [disabled]="!('contact' | available)"
                >
                  <ion-label>
                    {{ 'menu_contacts' | translate }}
                  </ion-label>
                </ion-item>
              </ion-col>
            </ion-row> -->
          </ion-grid>
          <ion-grid
            id="leftMenuFixedContent"
            class="ion-no-margin ion-no-padding menu-footer"
          >
            <!-- menu download -->
            <ion-row>
              <ion-col id="downloadApp">
                <ion-item
                  *ngIf="
                    operatingSystem === 'desktop' ||
                    operatingSystem === 'winphone'
                  "
                  class="top-bordered"
                  (click)="closeMenu()"
                  [href]="otherPlatforms?.web.link"
                  [disabled]="!otherPlatforms?.web.allowed"
                >
                  <ion-label>
                    {{ 'menu_app_pc' | translate }}
                  </ion-label>
                </ion-item>
                <ion-item
                  *ngIf="operatingSystem === 'ios'"
                  class="top-bordered"
                  (click)="downloadIosApp(); closeMenu()"
                  [disabled]="disableDownloadIosApp()"
                >
                  <ion-label>
                    {{ 'menu_app_download' | translate }}
                  </ion-label>
                </ion-item>
                <ion-item
                  *ngIf="operatingSystem === 'android'"
                  class="top-bordered"
                  (click)="closeMenu()"
                  [href]="otherPlatforms?.android.link"
                  [disabled]="!otherPlatforms?.android.allowed"
                >
                  <ion-label>
                    {{ 'menu_app_download' | translate }}
                  </ion-label>
                </ion-item>
              </ion-col>
            </ion-row>
            <!-- logout-->
            <ion-row
              *ngIf="(isClientLoggedIn$ | async) === true"
              id="logout-bar"
            >
              <ion-col>
                <ion-item class="top-bordered is-anchor" (click)="onLogout()">
                  <ion-label id="logout">
                    <span class="icon icon-ic_exit"> </span>
                    <span class="text">{{ 'menu_logout' | translate }}</span>
                  </ion-label>
                </ion-item>
              </ion-col>
              <ion-col>
                <ion-item class="top-bordered smaller-font">
                  <ion-label>
                    <span id="counter">
                      {{ 'menu_logged_in' | translate }}:
                      {{
                        loginDiffTime
                          | formatDate
                            : {
                                hour12: false,
                                hour:
                                  loginDiffTime.getHours() > 0
                                    ? '2-digit'
                                    : undefined,
                                minute: '2-digit',
                                second: '2-digit'
                              }
                      }}
                    </span>
                  </ion-label>
                </ion-item>
              </ion-col>
            </ion-row>
            <!-- version -->
            <ion-row (click)="onAtilla()" id="version-bar">
              <ion-col>
                <ion-item class="smaller-font">
                  <ion-label>
                    <ng-container *ngIf="BUILD_INFORMATION.PRODUCTION">
                      v{{ BUILD_INFORMATION.VERSION }}.2
                      <!-- RC -->
                    </ng-container>
                    <ng-container *ngIf="!BUILD_INFORMATION.PRODUCTION">
                      🚧
                      <span [ngStyle]="{ color: '#' + BUILD_INFORMATION.HASH }">
                        v {{ BUILD_INFORMATION.VERSION }}
                        {{ BUILD_INFORMATION.ENVIRONMENT }} <br />
                      </span>
                      ⌚ ({{ BUILD_INFORMATION.BUILD_TIME }}) <br />
                      😎 @{{ BUILD_INFORMATION.AUTHOR }} <br />
                      🌿 {{ BUILD_INFORMATION.BRANCH_NAME }} <br />
                      🎉 {{ BUILD_INFORMATION.HASH }}
                    </ng-container>
                  </ion-label>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-list>
      </ion-content>
    </ion-menu>
    <!-- End left menu -->

    <!-- Start content -->
    <ion-router-outlet id="mainOutlet">
      <ng-template doxxTicketBubleHost></ng-template>
    </ion-router-outlet>
    <!-- End content -->

    <!-- Start ticket menu -->
    <!-- *ngIf="('betting' | isBlocked | async) === true" -->
    <ion-menu
      type="overlay"
      side="end"
      contentId="mainOutlet"
      menuId="ticketMenu"
      id="ticketMenu"
      *ngIf="'betslip' | available"
    >
      <doxx-ticket id="overallTicketMenu"> </doxx-ticket>
    </ion-menu>
    <!-- End ticket menu -->
    <!-- </ion-split-pane> -->
  </ion-content>
  <ion-footer
    *ngIf="
      ('cookieAlert' | available) &&
      isAcceptsCookies !== true &&
      tempHideCookies !== true
    "
    id="cookies-info-banner"
  >
    <h2>{{ 'cookies_info_title' | translate }}</h2>
    <!-- {{ 'app_cookies_info_1' | translate }} -->
    <!-- <span>
      <span
        class="text-white"
        [routerLink]="'cookieAlert.documentLink' | conf | translateRoute"
      >
        {{ 'app_cookies_info_2' | translate }}
      </span>
      {{ 'app_cookies_info_3' | translate }}
    </span> -->
    <div>
      <div class="cookies-text">
        <span
          class="cookies-text-span"
          [innerHtml]="'cookies_info_text' | translateSpecial"
        ></span>
      </div>
      <div class="cookies-buttons">
        <ion-button class="agree-button" mode="md" (click)="acceptCookies()">
          {{ 'cookies_info_button_accept' | translate }}
          <!-- {{ 'app_btn_understand' | translate | uppercase }} -->
        </ion-button>
        <ion-button
          class="agree-button"
          mode="md"
          [routerLink]="
            'Profile'
              | translateRoute
                : {
                    _intern_subpage: COOKIES_SETTINGS_PATH
                  }
          "
          skipLocationChange
          (click)="tempHideCookies = true"
        >
          {{ 'cookies_info_button_setting' | translate }}
        </ion-button>
      </div>
    </div>
  </ion-footer>
</ion-app>
