import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'doxx-empty-ticket',
  templateUrl: './empty-ticket.component.html',
  styleUrls: ['./empty-ticket.component.scss']
})
export class EmptyTicketComponent implements OnInit {
  constructor(private _menu: MenuController) {}

  /** ngonint */
  ngOnInit(): void {}

  /** Schova tiketove menu */
  hideTicket(): void {
    this._menu.close('ticketMenu');
  }
}
