import { Component, Input } from '@angular/core';

@Component({
  selector: 'doxx-quickbet-banner',
  templateUrl: './quickbet-banner.component.html',
  styles: []
})
export class QuickbetBannerComponent {
  @Input() image: string;
  @Input() icon: string;
  @Input() isNew: boolean;

  constructor() {}
}
