import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { configuration } from '@configuration';
import { AuthService } from '@core/services/auth/auth.service';

const REGX =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/g;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hrefOrRouterLink]'
})
export class HrefOrRouterLinkDirective {
  private _isLink = false;
  @HostBinding('attr.href') private _link: string | string[];

  @HostListener('click', ['$event']) private _onClick(event): void {
    event.preventDefault();

    if (this._isLink) {
      // IS VLC
      if (
        new RegExp(configuration.connections.vlc.url).test(this._link as string)
      ) {
        this._authService.getVlcState().subscribe(
          res => {
            const url = new URL(this._link as string);
            if (res.token) {
              url.searchParams.set('token', res.token);
            }
            (window as any).open(url.toString(), '_blank');
          },
          error => {
            (window as any).open(this._link as string, '_blank');
          }
        );
      }
      // IS NORMAL LINK
      else {
        (window as any).open(this._link, '_blank');
      }
    } else {
      let routerLink: string[];
      if (Array.isArray(this._link)) {
        routerLink = this._link;
      } else {
        routerLink = [this._link];
      }
      this._router.navigate(routerLink);
    }
  }

  @Input() set hrefOrRouterLink(link: string) {
    this._link = link;
    if (REGX.test(link)) {
      this._link = link.match(REGX)[0];
      this._isLink = true;
    } else {
      this._isLink = false;
    }
  }

  constructor(private _router: Router, private _authService?: AuthService) {}
}
