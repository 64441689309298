import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { configuration } from '@configuration';
import { KNOWN_GAMES_CATEGORY } from '@constants/knownGamesCategory';
import { translateRoute } from '@core/functions/translateRoute';
import { environment } from '@environment';
import { Platform } from '@ionic/angular';
import { KNOWN_BONUSES } from 'src/constants/knownBonuses';
import { NAVIGATION } from 'src/navigation';
import { COOKIES_SETTINGS_PATH } from '@constants';
import { ConfigFooterFastLinks } from '@configurationTypes/configFooterFastLinks';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromApp from '@store/app.reducer';
import { authSelectors } from '@store/selectors';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'doxx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
  //encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit, OnDestroy {
  language = environment.language;
  location = environment.location;
  COOKIES_SETTINGS_PATH = COOKIES_SETTINGS_PATH;
  _type: 'casino' | 'betting' | 'rio';
  fastLinks = configuration.footer.fastLinks.map(section => ({
    ...section,
    links: section.links.map(link => ({
      ...link,
      inApp: !!NAVIGATION[link.destination.split('/')[0]]
    }))
  }));

  otherPlatforms = configuration.otherPlatforms;

  textLinksMap = {
    casino: {
      bonuses: translateRoute('BonusesListCasino'),
      loyalty_bonus: translateRoute('BonusDetailCasino', {
        bonusID: KNOWN_BONUSES.LoyaltyBonusCasino
      }),
      table_games: translateRoute('CasinoHome', {
        categoryID: KNOWN_GAMES_CATEGORY.CasinoTableGames
      }),
      slot_games: translateRoute('CasinoHome', {
        categoryID: KNOWN_GAMES_CATEGORY.CasinoSlots
      })
    },
    rio: {
      bonuses: translateRoute('BonusesListCasino'),
      loyalty_bonus: translateRoute('BonusDetailCasino', {
        bonusID: KNOWN_BONUSES.LoyaltyBonusCasino
      }),
      table_games: translateRoute('CasinoHome', {
        categoryID: KNOWN_GAMES_CATEGORY.CasinoTableGames
      }),
      slot_games: translateRoute('CasinoHome', {
        categoryID: KNOWN_GAMES_CATEGORY.CasinoSlots
      })
    },
    betting: {
      bonuses: translateRoute('BonusesList'),
      prematch: translateRoute('PrematchList'),
      live: translateRoute('LiveList'),
      actualities: translateRoute('NewsList')
    }
  };

  @Input() set type(type: 'casino' | 'betting' | 'rio') {
    this._type = type;
  }

  private _subscription$: Subscription;
  isUserLoggedIn = false;

  isIos: boolean;
  isAndroid: boolean;
  isDesktop: boolean;
  year: number;

  /** detect changes after 100ms */
  ngOnInit(): void {
    this._subscription$ = new Subscription();
    setTimeout(() => {
      this._cd.detectChanges();
    }, 100);
    this._subscription$.add(
      this.isClientLoggedIn$.subscribe(isLogged => {
        this.isUserLoggedIn = isLogged;
      })
    );
  }

  constructor(
    private _store: Store<fromApp.AppState>,
    private _platform: Platform,
    private _cd: ChangeDetectorRef,
    private _sanitizer: DomSanitizer
  ) {
    // Recognize platform
    this.isAndroid = this._platform.is('android');
    this.isIos = this._platform.is('ios');
    this.isDesktop = this._platform.is('desktop');
    this.year = new Date().getFullYear();
  }
  public isClientLoggedIn$: Observable<boolean> = this._store.select(
    authSelectors.selectIsUserLoggedIn
  );

  /** track by in array of primitive values */
  trackByIndex(index: number, item: number) {
    return index;
  }

  /** used to remov */
  sanitize(url: string) {
    return this._sanitizer.bypassSecurityTrustUrl(url);
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this._subscription$.unsubscribe();
  }

  replaceOssrLink = (str: string) =>
    str.replace(
      /{{(.*?)}}/g,
      '<a class="link" href="http://www.orsr.sk/vypis.asp?ID=244579&SID=5&P=1" target="_blank">$1</a>'
    );
}
