import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromBuyBack from './buyback.reducer';

export const selectBuybackState = createFeatureSelector<fromBuyBack.State>(
  fromBuyBack.BUYBACK_STATE_KEY
);

export const selectBuybackStateValueByTicketCode = ticketCode =>
  createSelector(selectBuybackState, state => state.buyBackTickets[ticketCode]);

export const selectBuybackStateValueByEventID = eventID =>
  createSelector(selectBuybackState, state =>
    state.buyBackEvents
      .filter(ev => ev.eventID === eventID)
      .map(ev => state.buyBackTickets[ev.ticketCode])
  );

export const selectHasBuybackByEventID = eventID =>
  createSelector(selectBuybackState, state =>
    state.buyBackEvents.some(item => item.eventID === eventID)
  );

export const selectBuybackTickets = createSelector(
  selectBuybackState,
  state => state.buyBackTickets
);
