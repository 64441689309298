import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

interface Window {
  [options: string]: any; // Add index signature
}
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private _activeRoute = new BehaviorSubject<string[]>(['']);
  private _navigation = new Subject<any>();
  public operatingSystem: string;

  /**
   * Subscribable property to get active route
   */
  get activeRoute(): Observable<any[]> {
    return this._activeRoute;
  }

  constructor() {}

  /**
   * Called whenever route changes
   */
  changeRoute(newRoute: string[]): void {
    this._activeRoute.next(newRoute);
  }

  /**
   * Call whenever wants custom navigation (back - need testing)
   */
  navigate(direction: string): void {
    this._navigation.next(direction);
  }

  /**
   * Possibility subscribe to custom navigation (back - need testing)
   */
  getNavigation(): Subject<any> {
    return this._navigation;
  }
}
