import { Pipe, PipeTransform } from '@angular/core';
import { configuration } from '@configuration';

@Pipe({
  name: 'conf',
  pure: false
})
export class ConfPipe implements PipeTransform {
  /**
   * Pipe for fetching configuration variables in templates
   * @param propertyPath dot separated key path to object from configuration
   * @returns returns object from configuration
   * @author M.Vorcak
   */
  transform(propertyPath: string): any {
    const value = propertyPath.split('.').reduce((object, key) => {
      return object[key];
    }, configuration);
    return value;
  }
}
