import { Pipe, PipeTransform } from '@angular/core';
import { formatNumberWithCurrency } from '@core/functions/formatNumberWithCurrency';

@Pipe({
  name: 'formatNumberWithCurrency'
})
export class FormatNumberWithCurrencyPipe implements PipeTransform {
  constructor() {}

  /**
   * Transforms number to string with currency, you can use additional properties if you want
   * default value for currency is currency set in evnironment
   * default number of decimalDigits is 2
   */
  transform(
    value: number | string,
    props?: { currency?: string; decimalDigits?: number }
  ): string {
    return formatNumberWithCurrency(value, props);
  }
}
