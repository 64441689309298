import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromNotifications from './notifications.reducer';

export const selectNotificationsState =
  createFeatureSelector<fromNotifications.State>(
    fromNotifications.NOTIFICATIONS_STATE_KEY
  );

export const selectUnreadNotificationsCount = createSelector(
  selectNotificationsState,
  state => state.unreadNotificationsCount
);

export const selectNotifications = createSelector(
  selectNotificationsState,
  state => state.notifications
);

export const selectNotificationsListState = createSelector(
  selectNotificationsState,
  state => state.list
);

export const selectNotificationsList = createSelector(
  selectNotifications,
  selectNotificationsListState,
  (notifications, list) =>
    list.list
      .map(guid => notifications[guid])
      .sortBy(notification => -new Date(notification.created))
);
export const selectNotificationsListCursor = createSelector(
  selectNotificationsListState,
  state => state.cursor
);
export const selectNotificationsListStatus = createSelector(
  selectNotificationsListState,
  state => state.status
);
export const selectNotificationsListComplete = createSelector(
  selectNotificationsListState,
  state => state.complete
);
