<ion-grid
  *ngIf="_ticketDetail"
  [ngClass]="(_ticketDetail | statusTransform).background"
  class="ticket-history-grid"
>
  <!-- REDESIGN TICKET DETAIL -->
  <div
    class="block h-full text-slate-200 bg-slate-900"
    *ngVar="_ticketDetail | statusTransform as status"
  >
    <!-- <div class="pt-5 pb-12 mb-0.5 px-5 xx-tickets">
      <div
        class="xx-ticket-vertical-small w-[140px] flex flex-col transition-opacity"
        style="
          --ticket-status-bg: var(--ticket-status-bg-lost);
          --ticket-status-color: var(--ticket-status-color-lost);
        "
      >
        <div class="rounded-t pt-1.5 text-xs text-center bg-white text-slate">
          1200,00 €
        </div>
        <div
          class="xx-ticket-vertical-small-separator flex flex-col before:content-['_'] before:h-[7px] before:block before:w-full before:border-b before:border-dashed before:bg-white before:border-slate-50 after:block after:w-full after:content-['_'] after:h-[7px]"
        ></div>
        <div
          class="text-sm leading-4 pb-0.5 text-center font-bold"
          style="
            color: var(--ticket-status-color);
            background-color: var(--ticket-status-bg);
          "
        >
          1 775,30 €
        </div>
        <div class="xx-ticket-vertical-small-end w-full h-[6px]"></div>
      </div>

   
    </div> -->
    <div class="xx-ticket-vertical-big bg-slate-25">
      <div
        class="relative px-5 pt-6 border-b border-slate-75"
        [style.background]="status.background"
      >
        <div id="my-pie-chart-container" *ngIf="!isStoredTicket">
          <i
            class="icon icon-ic_info-circle-filled"
            (click)="$event.stopPropagation(); showTicketChart()"
          ></i>
          <div
            id="my-pie-chart"
            *ngIf="status.status !== 'expired'"
            class="w-[76px] h-[76px] absolute left-1/2 -ml-[38px] -top-[38px] rounded-full flex justify-center items-center"
            [ngStyle]="{
              background:
                'conic-gradient(rgb(0, 109, 178) 0%,  rgb(95, 193, 255) ' +
                _oddsStats.U.percent +
                '%, rgb(171, 0, 36)' +
                _oddsStats.U.percent +
                '%, rgb(244, 121, 0)' +
                _oddsStats.N.percent +
                '%,rgb(69, 176, 12 ) ' +
                _oddsStats.N.percent +
                '%,  rgb(234, 250, 130) ' +
                _oddsStats.Y.percent +
                '% )'
            }"
          ></div>
        </div>
        <div
          *ngIf="!isStoredTicket"
          class="w-[70px] h-[70px] absolute left-1/2 -ml-[35px] -top-[35px] rounded-full flex justify-center items-center bg-white shadow-[0_6px_14px_rgba(0,0,0,0.15)]"
          style="color: var(--ticket-status-icon-color)"
        >
          <svg
            [ngClass]="
              {
                await: '-ml-1 scale-125',
                pend: '-mt-0.5 -mr-0.5 scale-110',
                won: 'scale-110',
                lost: 'scale-110',
                canceled: 'scale-110',
                expired: 'scale-110',
                refused: 'scale-110',
                compensation: 'scale-110',
                norisk: 'scale-110',
                buyback: 'scale-110',
                edited: 'scale-110 ml-2'
              }[status.status]
            "
            [style.fill]="status.color"
            class="icon-svg text-4xl transform"
          >
            <use
              [attr.xlink:href]="
                './assets/symbol-defs.svg#' +
                {
                  await: 'icon-card-in-speed-stroke-light',
                  pend: 'icon-check-in-circle-stroke-light',
                  won: 'icon-thumb-up-stroke-light',
                  lost: 'icon-thumb-down-stroke-light',
                  canceled: 'icon-block-stroke-light',
                  expired: 'icon-alert-stroke-light',
                  refused: 'icon-alert-stroke-light',
                  compensation: 'icon-checklist-stroke-light',
                  norisk: 'icon-shield-stroke-light',
                  buyback: 'icon-coins-in-hand-stroke-light',
                  edited: 'icon-edit-ticket-stroke-light'
                }[status.status]
              "
            ></use>
          </svg>
        </div>

        <div class="grid grid-cols-3 gap-1">
          <div class="text-xs text-center text-slate-250">
            <span>{{
              'ticket_history_detail_edit_history_table_rate' | translate
            }}</span>
          </div>
          <div></div>
          <div
            *ngIf="status.status !== 'edited'"
            class="text-xs text-center text-slate-250"
          >
            {{ 'ticket_history_bet_time' | translate }}
          </div>
        </div>
        <div class="mb-4 grid grid-cols-3 gap-1">
          <div class="text-center">
            <span
              class="px-4 py-0.5 rounded-sm text-xs font-bold text-white bg-accent"
              *ngIf="_ticketDetail.type !== 'SRT'"
              >{{ _ticketDetail.overallRate | rate }}</span
            >
            <!-- {{ _ticketDetail | json }} -->
            <span *ngIf="_ticketDetail.type === 'SRT'"> - </span>
          </div>
          <div
            class="py-0.5 text-center text-sm text-slate font-bold"
            *ngIf="!_ticketDetail.noRiskTicket"
            [ngStyle]="isStoredTicket && { visibility: 'hidden' }"
          >
            <span>
              {{
                {
                  won: 'ticketHistory_won_labelOne',
                  buyback: 'ticketHistory_cashback_labelOne',
                  lost: 'ticketHistory_lost_labelOne',
                  pend: 'ticketHistory_pend_labelOne',
                  canceled: 'ticketHistory_canceled_labelOne',
                  expired: 'ticketHistory_expired_labelOne',
                  refused: 'ticketHistory_refused_labelOne',
                  await: 'ticketHistory_await_labelOne',
                  edited: 'ticket_history_detail_state_edited',
                  compensation: 'ticketHistory_compensed_labelOne',
                  norisk: 'ticketHistory_norisk_labelOne'
                }[status.status] | translate
              }}
            </span>
          </div>

          <div class="py-0.5 text-center text-sm text-slate">
            <span *ngIf="status.status !== 'edited'">
              {{ _ticketDetail.ticketDate | formatDate : 'YYYYMMDDhhmm' }}
            </span>
            <span *ngIf="status.status === 'edited'">
              {{ 'ticket_history_detail_edit_version' | translate }}&nbsp;{{
                _ticketDetail.editationVersion
              }}
            </span>
            <!-- raw {{ _ticketDetail.ticketDate }} -->
          </div>
        </div>
        <div
          class="w-full text-center text-xs pb-4 text-slate-250"
          *ngIf="_ticketDetail.compensationTicket"
        >
          {{ 'ticket_history_compensation_desc' | translate }}
        </div>
        <div class="flex justify-center" *ngIf="_ticketDetail.freeBetTicket">
          <div class="text-xs text-center text-slate-250 mb-1">
            {{ 'ticketHistory_freebet_labelOne' | translate }}
          </div>
        </div>
        <div
          class="flex items-center flex-col py-1"
          *ngIf="+_ticketDetail.boosterBonusValue"
        >
          <!-- (click)="xxlBonus.presentXXLBonusInfo()" -->
          <span class="flex items-center bold h-6">
            <span class="text-[#EC1B34] !text-5xl icon-ic_xxl-logo"></span>
            <span class="text-[#313841]">Bonus</span>
            <!-- <span
              class="icon icon-ic_info-circle-filled text-base pl-1 text-[#7e848a]"
            ></span> -->
          </span>
          <span class="text-[#313841] text-xl">
            {{
              _ticketDetail.boosterBonusValue
                | formatNumberWithCurrency
                  : {
                      decimalDigits:
                        configuration.base.defaultCurrency.numberDecimalPlaces
                    }
            }}
          </span>
        </div>
        <div class="flex pb-4">
          <div class="flex-1 py-4 pr-1 border-r border-slate-75">
            <div class="text-xs text-center text-slate-250 mb-1">
              {{ 'help_ticket_stake' | translate }}
            </div>
            <div class="text-center text-[1.375rem] text-slate">
              {{
                _ticketDetail.overallBet
                  | formatNumberWithCurrency
                    : {
                        decimalDigits:
                          configuration.base.defaultCurrency.numberDecimalPlaces
                      }
              }}
            </div>
          </div>
          <div class="flex-1 py-4 pl-1">
            <div
              *ngIf="status.status === 'won' || status.status === 'buyback'"
              class="text-xs text-center text-slate-250 mb-1"
            >
              {{ 'history_won' | translate }}
            </div>
            <div
              *ngIf="status.status !== 'won' && status.status !== 'buyback'"
              class="text-xs text-center text-slate-250 mb-1"
            >
              {{ 'ticket_eventual_win' | translate }}
            </div>
            <div class="text-center text-[1.375rem]" [ngClass]="status.status">
              {{
                _ticketDetail.fiscalOverallPotWin
                  | formatNumberWithCurrency
                    : {
                        decimalDigits:
                          configuration.base.defaultCurrency.numberDecimalPlaces
                      }
              }}
            </div>
          </div>
        </div>

        <!-- <button
          class="w-full min-h-[3.5rem] rounded py-2 text-sm mb-5 font-bold uppercase bg-green text-white active:bg-green-600"
        >
          Pay off
        </button> -->

        <div
          class="w-full text-center text-xs pb-4 text-slate-250"
          *ngIf="_ticketDetail.winDate"
        >
          {{ 'betting_ticket_detail_win_date' | translate }}
          <span class="text-slate">{{
            _ticketDetail.winDate | formatDate : 'YYYYMMDDhhmm'
          }}</span>
        </div>

        <div
          class="w-full text-center text-xs pb-4 text-slate-250"
          *ngIf="
            _ticketDetail.fee &&
            _ticketDetail.fee !== '0.00' &&
            !isStoredTicket &&
            status.status !== 'expired' &&
            _ticketDetail.type !== 'SRT'
          "
        >
          {{ 'ticket_fee' | translate }}
          <span class="text-slate">{{
            _ticketDetail.fee
              | formatNumberWithCurrency
                : {
                    decimalDigits:
                      configuration.base.defaultCurrency.numberDecimalPlaces
                  }
          }}</span>
        </div>

        <ion-row
          style="margin-bottom: 15px; font-size: 12px"
          *ifAvailable="'betslip.showTaxInfo'"
        >
          <ion-col>
            <div style="width: 40%; float: left; padding: 10px">
              <span style="color: #7e848a; float: right">
                {{ 'betslip_tax' | translate }}:
                <span style="color: black">
                  {{
                    _ticketDetail.netWinTax
                      | formatNumberWithCurrency
                        : {
                            decimalDigits:
                              configuration.base.defaultCurrency
                                .numberDecimalPlaces
                          }
                  }}</span
                >
              </span>
            </div>
            <div style="padding: 10px">
              <span style="color: #7e848a">
                {{ 'betslip_tax_eventual_win' | translate }}
              </span>
              <span style="color: black">
                {{
                  _ticketDetail.netWinPayout
                    | formatNumberWithCurrency
                      : {
                          decimalDigits:
                            configuration.base.defaultCurrency
                              .numberDecimalPlaces
                        }
                }}</span
              >
            </div>
          </ion-col>
        </ion-row>
        <div class="xx-button-row button-group w-full mb-5">
          <button
            class="min-h-[3.5rem] rounded bg-white text-slate-500 px-6 py-2 text-sm flex-1 active:bg-slate-25"
            *ngIf="_ticketDetail.cloneable && ('betslip.duplicate' | available)"
            (click)="duplicateTicket(_ticketDetail)"
          >
            <!-- ticketHistoryService.hasActiveOdds(_ticketDetail)  -->
            <svg
              class="icon-svg icon-copy-stroke-bold text-xl text-slate-250 mr-1"
            >
              <use
                xlink:href="./assets/symbol-defs.svg#icon-copy-stroke-bold"
              ></use>
            </svg>
            {{ 'ticket_actions_copy' | translate }}
          </button>
          <button
            class="min-h-[3.5rem] rounded bg-white border-slate-75 text-slate-500 px-6 py-2 text-sm flex-1 active:bg-slate-25 flex items-center justify-center"
            *ngIf="
              !isStoredTicket &&
              _ticketDetail.editable &&
              isClientLoggedIn &&
              ('betslip.storno' | available)
            "
            (click)="editTicket(_ticketDetail)"
          >
            <svg
              class="icon-svg icon-x-in-square-stroke-bold text-xl text-slate-250 mr-1"
            >
              <use
                xlink:href="./assets/symbol-defs.svg#icon-edit-stroke-bold"
              ></use>
            </svg>
            {{ 'ticket_actions_edit' | translate }}
          </button>
          <button
            class="min-h-[3.5rem] rounded bg-white border border-slate-75 text-slate-500 px-6 py-2 text-sm flex-1 active:bg-slate-25"
            *ngIf="
              !isStoredTicket &&
              _ticketDetail.cancelable &&
              isClientLoggedIn &&
              ('betslip.storno' | available)
            "
            (click)="cancelTicket(_ticketDetail)"
          >
            <svg
              class="icon-svg icon-x-in-square-stroke-bold text-xl text-slate-250 mr-1"
            >
              <use
                xlink:href="./assets/symbol-defs.svg#icon-x-in-square-stroke-bold"
              ></use>
            </svg>
            {{ 'ticket_cancel' | translate }}
          </button>
        </div>
        <ng-container
          *ngIf="
            _buyback &&
            _ticketDetail.state === 'approved' &&
            isClientLoggedIn &&
            ('betslip.buyBack' | available)
          "
        >
          <div
            *doxxBuybackTicket="
              _ticketDetail.ticketCode;
              let bb;
              let hasBuyBack = hasBuyBack
            "
          >
            <div class="xx-button-row w-full mb-5" *ngIf="hasBuyBack">
              <button
                class="flex-auto min-h-[3.5rem] px-6 py-2 text-sm font-bold uppercase text-left bg-green text-white active:bg-green-600 rounded-l"
                *ngIf="bb.BuyBackValue > 0"
                (click)="showBuybackModal(_ticketDetail.ticketCode)"
              >
                {{ 'ticket_buyback_ticketbuyback' | translate }}
                {{
                  bb.BuyBackValue
                    | formatNumberWithCurrency
                      : {
                          decimalDigits:
                            configuration.base.defaultCurrency
                              .numberDecimalPlaces
                        }
                }}
              </button>
              <button
                *ngIf="
                  bb.BuyBackValue !== -1 &&
                  isClientLoggedIn &&
                  ('betslip.buyBack' | available) &&
                  !isStoredTicket
                "
                class="min-h-[3.5rem] items-center w-full justify-center text-sm flex-1 flex active:bg-green-800 bg-white text-green font-bold uppercase border-2 border-green border-solid rounded-r transition-colors"
                [doxxAutoBuybackModalTrigger]="_ticketDetail.ticketCode"
                [ngClass]="{
                  '!border-green-700 !bg-green-700':
                    bb.AutoBuyBackValue && bb.BuyBackValue > 0,
                  'border-green !bg-green':
                    bb.AutoBuyBackValue && bb.BuyBackValue === 0,
                  'rounded-l': bb.BuyBackValue === 0,
                  '!text-white': bb.AutoBuyBackValue
                }"
              >
                <div class="w-14">
                  <svg
                    class="icon-svg text-xl"
                    [ngClass]="{
                      autobuyback: bb.AutoBuyBackValue
                    }"
                  >
                    <use
                      xlink:href="./assets/symbol-defs.svg#icon-gear-fill"
                    ></use>
                  </svg>
                </div>
                <span *ngIf="bb.BuyBackValue === 0">
                  {{ 'ticket_autobuyback_title' | translate }}
                  <span *ngIf="bb.AutoBuyBackValue"
                    >&nbsp;
                    {{
                      bb.AutoBuyBackValue
                        | formatNumberWithCurrency
                          : {
                              decimalDigits:
                                configuration.base.defaultCurrency
                                  .numberDecimalPlaces
                            }
                    }}</span
                  >
                </span>
              </button>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- <div class="p-2.5">
        <div
          class="flex flex-col relative drop-shadow-[0_2px_5px_rgba(0,0,0,0.10)]"
        >
          <section
            class="xx-ticket-group first:mt-[2px] first:before:block first:before:w-full first:before:absolute first:before:top-0 first:before:bg-white first:before:content-['_'] first:before:h-[2px] last:mb-[2px] last:after:block last:after:w-full last:after:absolute last:after:bottom-0 last:after:bg-white last:after:content-['_'] last:after:h-[2px]"
          >
            <div
              class="xx-ticket-match-box overflow-hidden relative"
              style="
                --sport-color: var(--sport-color-soccer);
                --match-status-gradient: var(--match-status-gradient-win);
              "
            >
              <div
                class="xx-ticket-group-mark xx-ticket-group-mark-safe flex justify-center items-center text-xs font-bold w-6 h-6 border-2 rounded-full absolute mt-3 ml-4 z-10 border-accent text-accent"
              >
                0
              </div>

              <mat-expansion-panel class="xx-ticket-match-wrapper" hideToggle>
                <mat-expansion-panel-header
                  class="xx-ticket-match bg-transparent relative p-4 border-t h-auto block border-slate-75 after:block after:absolute after:top-0 after:right-0 after:h-full after:content-['_'] after:w-[2px]"
                >
                  <div class="w-full flex justify-between">
                    <div class="flex flex-wrap">
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Futbal
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Anglicko
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Premier League
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Premier League
                      </div>
                    </div>
                    <div
                      class="flex-shrink-0 whitespace-nowrap text-xs pt-0.5 text-slate-300"
                    >
                      03.05.2021, 18:00
                    </div>
                  </div>

                  <div class="w-full flex justify-between overflow-hidden">
                    <div class="flex truncate mt-1">
                      <div class="flex truncate text-sm font-bold text-slate">
                        <svg
                          class="flex-none icon-svg icon-l-in-square-fill text-xs mt-1 mr-1 text-accent"
                        >
                          <use
                            xlink:href="./assets/ymbol-defs.svg#icon-l-in-square-fill"
                          ></use>
                        </svg>
                        <div class="flex-auto truncate">Arsenal FC</div>
                        <div
                          class="flex-none mx-1.5 font-normal text-slate-300"
                        >
                          2 : 1
                        </div>
                        <div class="flex-auto truncate">
                          Paris Saint-Germain
                        </div>
                      </div>
                    </div>
                    <div class="vlc-expand-arrow flex-none ml-1 text-slate-300">
                      <svg
                        class="icon-svg icon-arrow-down text-xl text-slate-200"
                      >
                        <use
                          xlink:href="./PATH/symbol-defs.svg#icon-arrow-down"
                        ></use>
                      </svg>
                    </div>
                  </div>

                  <div class="w-full flex justify-between items-center mt-1">
                    <div class="text-sm">
                      <div class="text-slate-300">Result:</div>
                      <div class="text-accent font-bold">Arsenal FC</div>
                    </div>
                    <div class="flex-shrink-0 text-slate-300">
                      <div
                        class="text-xs font-bold rounded-sm px-1 py-0.5 bg-slate-200 text-white"
                      >
                        3.40
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel-header>

                <div
                  class="xx-ticket-detail border-t border-slate-75 px-4 py-1"
                >
                  <table class="w-full">
                    <thead>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs"
                      >
                        <th
                          colspan="2"
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          <svg
                            class="icon-svg icon-live_in_rectangle-fill text-3xl text-accent"
                          >
                            <use
                              xlink:href="./PATH/symbol-defs.svg#icon-live_in_rectangle-fill"
                            ></use>
                          </svg>
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        ></th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          1.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          2.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          3.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          4.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          5.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs last:border-0"
                      >
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          <svg
                            class="icon-svg icon-shield-with-star-fill text-lg align-bottom text-slate-100"
                          >
                            <use
                              xlink:href="./PATH/symbol-defs.svg#icon-shield-with-star-fill"
                            ></use>
                          </svg>
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right truncate max-w-0"
                        >
                          Arsenal FC
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-bold whitespace-nowrap text-accent before:w-3 before:h-3 before:align-text-top before:mt-[2px] before:inline-block before:mr-2.5 before:rounded-full before:bg-slate-100 before:content-['_']"
                        >
                          2
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          7
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          1
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          2
                        </td>
                      </tr>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs last:border-0"
                      >
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          <img
                            class="w-4 float-left align-bottom"
                            src="https://static.doxxbet.sk/teams/medium/2046.png"
                          />
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right truncate max-w-0"
                        >
                          Arsenal FC
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-bold whitespace-nowrap text-accent"
                        >
                          2
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          7
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          1
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          2
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </mat-expansion-panel>
            </div>

            <div
              class="xx-ticket-match-box overflow-hidden relative"
              style="
                --sport-color: var(--sport-color-soccer);
                --match-status-gradient: var(--match-status-gradient-lost);
              "
            >
              <div
                class="xx-ticket-group-mark xx-ticket-group-mark-safe flex justify-center items-center text-xs font-bold w-6 h-6 border-2 rounded-full absolute mt-3 ml-4 z-10 border-accent text-accent"
              >
                0
              </div>

              <mat-expansion-panel class="xx-ticket-match-wrapper" hideToggle>
                <mat-expansion-panel-header
                  class="xx-ticket-match bg-transparent relative p-4 border-t h-auto block border-slate-75 after:block after:absolute after:top-0 after:right-0 after:h-full after:content-['_'] after:w-[2px]"
                >
                  <div class="w-full flex justify-between">
                    <div class="flex flex-wrap">
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Futbal
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Anglicko
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Premier League
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Premier League
                      </div>
                    </div>
                    <div
                      class="flex-shrink-0 whitespace-nowrap text-xs pt-0.5 text-slate-300"
                    >
                      03.05.2021, 18:00
                    </div>
                  </div>

                  <div class="w-full flex justify-between overflow-hidden">
                    <div class="flex truncate mt-1">
                      <div class="flex truncate text-sm font-bold text-slate">
                        <svg
                          class="flex-none icon-svg icon-l-in-square-fill text-xs mt-1 mr-1 text-accent"
                        >
                          <use
                            xlink:href="./PATH/symbol-defs.svg#icon-l-in-square-fill"
                          ></use>
                        </svg>
                        <div class="flex-auto truncate">Arsenal FC</div>
                        <div
                          class="flex-none mx-1.5 font-normal text-slate-300"
                        >
                          2 : 1
                        </div>
                        <div class="flex-auto truncate">
                          Paris Saint-Germain
                        </div>
                      </div>
                    </div>
                    <div class="vlc-expand-arrow flex-none ml-1 text-slate-300">
                      <svg
                        class="icon-svg icon-arrow-down text-xl text-slate-200"
                      >
                        <use
                          xlink:href="./PATH/symbol-defs.svg#icon-arrow-down"
                        ></use>
                      </svg>
                    </div>
                  </div>

                  <div class="w-full flex justify-between items-center mt-1">
                    <div class="text-sm">
                      <div class="text-slate-300">Result:</div>
                      <div class="text-accent font-bold">Arsenal FC</div>
                    </div>
                    <div class="flex-shrink-0 text-slate-300">
                      <div
                        class="text-xs font-bold rounded-sm px-1 py-0.5 bg-slate-200 text-white"
                      >
                        3.40
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel-header>

                <div
                  class="xx-ticket-detail border-t border-slate-75 px-4 py-1"
                >
                  <table class="w-full">
                    <thead>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs"
                      >
                        <th
                          colspan="2"
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          <svg
                            class="icon-svg icon-live_in_rectangle-fill text-3xl text-accent"
                          >
                            <use
                              xlink:href="./PATH/symbol-defs.svg#icon-live_in_rectangle-fill"
                            ></use>
                          </svg>
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        ></th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          1.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          2.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          3.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          4.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          5.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs last:border-0"
                      >
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          <svg
                            class="icon-svg icon-shield-with-star-fill text-lg align-bottom text-slate-100"
                          >
                            <use
                              xlink:href="./PATH/symbol-defs.svg#icon-shield-with-star-fill"
                            ></use>
                          </svg>
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right truncate max-w-0"
                        >
                          Arsenal FC
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-bold whitespace-nowrap text-accent before:w-3 before:h-3 before:align-text-top before:mt-[2px] before:inline-block before:mr-2.5 before:rounded-full before:bg-slate-100 before:content-['_']"
                        >
                          2
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          7
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          1
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          2
                        </td>
                      </tr>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs last:border-0"
                      >
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          <img
                            class="w-4 float-left align-bottom"
                            src="https://static.doxxbet.sk/teams/medium/2046.png"
                          />
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right truncate max-w-0"
                        >
                          Arsenal FC
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-bold whitespace-nowrap text-accent"
                        >
                          2
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          7
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          1
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          2
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </mat-expansion-panel>
            </div>

            <div
              class="xx-ticket-match-box overflow-hidden relative"
              style="
                --sport-color: var(--sport-color-soccer);
                --match-status-gradient: var(--match-status-gradient-lost);
              "
            >
              <div
                class="xx-ticket-group-mark xx-ticket-group-mark-safe flex justify-center items-center text-xs font-bold w-6 h-6 border-2 rounded-full absolute mt-3 ml-4 z-10 border-accent text-accent"
              >
                0
              </div>

              <mat-expansion-panel class="xx-ticket-match-wrapper" hideToggle>
                <mat-expansion-panel-header
                  class="xx-ticket-match bg-transparent relative p-4 border-t h-auto block border-slate-75 after:block after:absolute after:top-0 after:right-0 after:h-full after:content-['_'] after:w-[2px]"
                >
                  <div class="w-full flex justify-between">
                    <div class="flex flex-wrap">
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Futbal
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Anglicko
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Premier League
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Premier League
                      </div>
                    </div>
                    <div
                      class="flex-shrink-0 whitespace-nowrap text-xs pt-0.5 text-slate-300"
                    >
                      03.05.2021, 18:00
                    </div>
                  </div>

                  <div class="w-full flex justify-between overflow-hidden">
                    <div class="flex truncate mt-1">
                      <div class="flex truncate text-sm font-bold text-slate">
                        <svg
                          class="flex-none icon-svg icon-l-in-square-fill text-xs mt-1 mr-1 text-accent"
                        >
                          <use
                            xlink:href="./PATH/symbol-defs.svg#icon-l-in-square-fill"
                          ></use>
                        </svg>
                        <div class="flex-auto truncate">Arsenal FC</div>
                        <div
                          class="flex-none mx-1.5 font-normal text-slate-300"
                        >
                          2 : 1
                        </div>
                        <div class="flex-auto truncate">
                          Paris Saint-Germain
                        </div>
                      </div>
                    </div>
                    <div class="vlc-expand-arrow flex-none ml-1 text-slate-300">
                      <svg
                        class="icon-svg icon-arrow-down text-xl text-slate-200"
                      >
                        <use
                          xlink:href="./PATH/symbol-defs.svg#icon-arrow-down"
                        ></use>
                      </svg>
                    </div>
                  </div>

                  <div class="w-full flex justify-between items-center mt-1">
                    <div class="text-sm">
                      <div class="text-slate-300">Result:</div>
                      <div class="text-accent font-bold">Arsenal FC</div>
                    </div>
                    <div class="flex-shrink-0 text-slate-300">
                      <div
                        class="text-xs font-bold rounded-sm px-1 py-0.5 bg-slate-200 text-white"
                      >
                        3.40
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel-header>

                <div
                  class="xx-ticket-detail border-t border-slate-75 px-4 py-1"
                >
                  <table class="w-full">
                    <thead>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs"
                      >
                        <th
                          colspan="2"
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          <svg
                            class="icon-svg icon-live_in_rectangle-fill text-3xl text-accent"
                          >
                            <use
                              xlink:href="./PATH/symbol-defs.svg#icon-live_in_rectangle-fill"
                            ></use>
                          </svg>
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        ></th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          1.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          2.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          3.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          4.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          5.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs last:border-0"
                      >
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          <svg
                            class="icon-svg icon-shield-with-star-fill text-lg align-bottom text-slate-100"
                          >
                            <use
                              xlink:href="./PATH/symbol-defs.svg#icon-shield-with-star-fill"
                            ></use>
                          </svg>
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right truncate max-w-0"
                        >
                          Arsenal FC
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-bold whitespace-nowrap text-accent before:w-3 before:h-3 before:align-text-top before:mt-[2px] before:inline-block before:mr-2.5 before:rounded-full before:bg-slate-100 before:content-['_']"
                        >
                          2
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          7
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          1
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          2
                        </td>
                      </tr>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs last:border-0"
                      >
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          <img
                            class="w-4 float-left align-bottom"
                            src="https://static.doxxbet.sk/teams/medium/2046.png"
                          />
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right truncate max-w-0"
                        >
                          Arsenal FC
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-bold whitespace-nowrap text-accent"
                        >
                          2
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          7
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          1
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          2
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </mat-expansion-panel>
            </div>
          </section>

          <section
            class="xx-ticket-group first:mt-[2px] first:before:block first:before:w-full first:before:absolute first:before:top-0 first:before:bg-white first:before:content-['_'] first:before:h-[2px] last:mb-[2px] last:after:block last:after:w-full last:after:absolute last:after:bottom-0 last:after:bg-white last:after:content-['_'] last:after:h-[2px"
          >
            <div
              class="xx-ticket-match-box overflow-hidden relative"
              style="
                --sport-color: var(--sport-color-soccer);
                --match-status-gradient: var(--match-status-gradient-lost);
              "
            >
              <div
                class="xx-ticket-group-mark flex justify-center items-center text-xs font-bold w-6 h-6 border-2 rounded-full absolute mt-3 ml-4 z-10 border-accent text-accent"
              >
                A
              </div>

              <mat-expansion-panel class="xx-ticket-match-wrapper" hideToggle>
                <mat-expansion-panel-header
                  class="xx-ticket-match bg-transparent relative p-4 border-t h-auto block border-slate-75 after:block after:absolute after:top-0 after:right-0 after:h-full after:content-['_'] after:w-[2px]"
                >
                  <div class="w-full flex justify-between">
                    <div class="flex flex-wrap">
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Futbal
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Anglicko
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Premier League
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Premier League
                      </div>
                    </div>
                    <div
                      class="flex-shrink-0 whitespace-nowrap text-xs pt-0.5 text-slate-300"
                    >
                      03.05.2021, 18:00
                    </div>
                  </div>

                  <div class="w-full flex justify-between overflow-hidden">
                    <div class="flex truncate mt-1">
                      <div class="flex truncate text-sm font-bold text-slate">
                        <svg
                          class="flex-none icon-svg icon-l-in-square-fill text-xs mt-1 mr-1 text-accent"
                        >
                          <use
                            xlink:href="./PATH/symbol-defs.svg#icon-l-in-square-fill"
                          ></use>
                        </svg>
                        <div class="flex-auto truncate">Arsenal FC</div>
                        <div
                          class="flex-none mx-1.5 font-normal text-slate-300"
                        >
                          2 : 1
                        </div>
                        <div class="flex-auto truncate">
                          Paris Saint-Germain
                        </div>
                      </div>
                    </div>
                    <div class="vlc-expand-arrow flex-none ml-1 text-slate-300">
                      <svg
                        class="icon-svg icon-arrow-down text-xl text-slate-200"
                      >
                        <use
                          xlink:href="./PATH/symbol-defs.svg#icon-arrow-down"
                        ></use>
                      </svg>
                    </div>
                  </div>

                  <div class="w-full flex justify-between items-center mt-1">
                    <div class="text-sm">
                      <div class="text-slate-300">Result:</div>
                      <div class="text-accent font-bold">Arsenal FC</div>
                    </div>
                    <div class="flex-shrink-0 text-slate-300">
                      <div
                        class="text-xs font-bold rounded-sm px-1 py-0.5 bg-slate-200 text-white"
                      >
                        3.40
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel-header>

                <div
                  class="xx-ticket-detail border-t border-slate-75 px-4 py-1"
                >
                  <table class="w-full">
                    <thead>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs"
                      >
                        <th
                          colspan="2"
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          <svg
                            class="icon-svg icon-live_in_rectangle-fill text-3xl text-accent"
                          >
                            <use
                              xlink:href="./PATH/symbol-defs.svg#icon-live_in_rectangle-fill"
                            ></use>
                          </svg>
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        ></th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          1.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          2.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          3.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          4.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          5.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs last:border-0"
                      >
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          <svg
                            class="icon-svg icon-shield-with-star-fill text-lg align-bottom text-slate-100"
                          >
                            <use
                              xlink:href="./PATH/symbol-defs.svg#icon-shield-with-star-fill"
                            ></use>
                          </svg>
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right truncate max-w-0"
                        >
                          Arsenal FC
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-bold whitespace-nowrap text-accent before:w-3 before:h-3 before:align-text-top before:mt-[2px] before:inline-block before:mr-2.5 before:rounded-full before:bg-slate-100 before:content-['_']"
                        >
                          2
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          7
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          1
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          2
                        </td>
                      </tr>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs last:border-0"
                      >
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          <img
                            class="w-4 float-left align-bottom"
                            src="https://static.doxxbet.sk/teams/medium/2046.png"
                          />
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right truncate max-w-0"
                        >
                          Arsenal FC
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-bold whitespace-nowrap text-accent"
                        >
                          2
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          7
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          1
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          2
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </mat-expansion-panel>
            </div>

            <div
              class="xx-ticket-match-box overflow-hidden relative"
              style="
                --sport-color: var(--sport-color-soccer);
                --match-status-gradient: var(--match-status-gradient-lost);
              "
            >
              <div
                class="xx-ticket-group-mark flex justify-center items-center text-xs font-bold w-6 h-6 border-2 rounded-full absolute mt-3 ml-4 z-10 border-accent text-accent"
              >
                A
              </div>

              <mat-expansion-panel class="xx-ticket-match-wrapper" hideToggle>
                <mat-expansion-panel-header
                  class="xx-ticket-match bg-transparent relative p-4 border-t h-auto block border-slate-75 after:block after:absolute after:top-0 after:right-0 after:h-full after:content-['_'] after:w-[2px]"
                >
                  <div class="w-full flex justify-between">
                    <div class="flex flex-wrap">
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Futbal
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Anglicko
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Premier League
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Premier League
                      </div>
                    </div>
                    <div
                      class="flex-shrink-0 whitespace-nowrap text-xs pt-0.5 text-slate-300"
                    >
                      03.05.2021, 18:00
                    </div>
                  </div>

                  <div class="w-full flex justify-between overflow-hidden">
                    <div class="flex truncate mt-1">
                      <div class="flex truncate text-sm font-bold text-slate">
                        <svg
                          class="flex-none icon-svg icon-l-in-square-fill text-xs mt-1 mr-1 text-accent"
                        >
                          <use
                            xlink:href="./PATH/symbol-defs.svg#icon-l-in-square-fill"
                          ></use>
                        </svg>
                        <div class="flex-auto truncate">Arsenal FC</div>
                        <div
                          class="flex-none mx-1.5 font-normal text-slate-300"
                        >
                          2 : 1
                        </div>
                        <div class="flex-auto truncate">
                          Paris Saint-Germain
                        </div>
                      </div>
                    </div>
                    <div class="vlc-expand-arrow flex-none ml-1 text-slate-300">
                      <svg
                        class="icon-svg icon-arrow-down text-xl text-slate-200"
                      >
                        <use
                          xlink:href="./PATH/symbol-defs.svg#icon-arrow-down"
                        ></use>
                      </svg>
                    </div>
                  </div>

                  <div class="w-full flex justify-between items-center mt-1">
                    <div class="text-sm">
                      <div class="text-slate-300">Result:</div>
                      <div class="text-accent font-bold">Arsenal FC</div>
                    </div>
                    <div class="flex-shrink-0 text-slate-300">
                      <div
                        class="text-xs font-bold rounded-sm px-1 py-0.5 bg-slate-200 text-white"
                      >
                        3.40
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel-header>

                <div
                  class="xx-ticket-detail border-t border-slate-75 px-4 py-1"
                >
                  <table class="w-full">
                    <thead>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs"
                      >
                        <th
                          colspan="2"
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          <svg
                            class="icon-svg icon-live_in_rectangle-fill text-3xl text-accent"
                          >
                            <use
                              xlink:href="./PATH/symbol-defs.svg#icon-live_in_rectangle-fill"
                            ></use>
                          </svg>
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        ></th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          1.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          2.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          3.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          4.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          5.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs last:border-0"
                      >
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          <svg
                            class="icon-svg icon-shield-with-star-fill text-lg align-bottom text-slate-100"
                          >
                            <use
                              xlink:href="./PATH/symbol-defs.svg#icon-shield-with-star-fill"
                            ></use>
                          </svg>
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right truncate max-w-0"
                        >
                          Arsenal FC
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-bold whitespace-nowrap text-accent before:w-3 before:h-3 before:align-text-top before:mt-[2px] before:inline-block before:mr-2.5 before:rounded-full before:bg-slate-100 before:content-['_']"
                        >
                          2
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          7
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          1
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          2
                        </td>
                      </tr>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs last:border-0"
                      >
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          <img
                            class="w-4 float-left align-bottom"
                            src="https://static.doxxbet.sk/teams/medium/2046.png"
                          />
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right truncate max-w-0"
                        >
                          Arsenal FC
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-bold whitespace-nowrap text-accent"
                        >
                          2
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          7
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          1
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          2
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </mat-expansion-panel>
            </div>

            <div
              class="xx-ticket-match-box overflow-hidden relative"
              style="
                --sport-color: var(--sport-color-soccer);
                --match-status-gradient: var(--match-status-gradient-lost);
              "
            >
              <div
                class="xx-ticket-group-mark flex justify-center items-center text-xs font-bold w-6 h-6 border-2 rounded-full absolute mt-3 ml-4 z-10 border-accent text-accent"
              >
                A
              </div>

              <mat-expansion-panel class="xx-ticket-match-wrapper" hideToggle>
                <mat-expansion-panel-header
                  class="xx-ticket-match bg-transparent relative p-4 border-t h-auto block border-slate-75 after:block after:absolute after:top-0 after:right-0 after:h-full after:content-['_'] after:w-[2px]"
                >
                  <div class="w-full flex justify-between">
                    <div class="flex flex-wrap">
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Futbal
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Anglicko
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Premier League
                      </div>
                      <div
                        class="vlc-label px-1 py-0.5 mr-1 mb-1 rounded-sm text-xs font-bold uppercase text-white"
                        style="background-color: var(--sport-color)"
                      >
                        Premier League
                      </div>
                    </div>
                    <div
                      class="flex-shrink-0 whitespace-nowrap text-xs pt-0.5 text-slate-300"
                    >
                      03.05.2021, 18:00
                    </div>
                  </div>

                  <div class="w-full flex justify-between overflow-hidden">
                    <div class="flex truncate mt-1">
                      <div class="flex truncate text-sm font-bold text-slate">
                        <svg
                          class="flex-none icon-svg icon-l-in-square-fill text-xs mt-1 mr-1 text-accent"
                        >
                          <use
                            xlink:href="./PATH/symbol-defs.svg#icon-l-in-square-fill"
                          ></use>
                        </svg>
                        <div class="flex-auto truncate">Arsenal FC</div>
                        <div
                          class="flex-none mx-1.5 font-normal text-slate-300"
                        >
                          2 : 1
                        </div>
                        <div class="flex-auto truncate">
                          Paris Saint-Germain
                        </div>
                      </div>
                    </div>
                    <div class="vlc-expand-arrow flex-none ml-1 text-slate-300">
                      <svg
                        class="icon-svg icon-arrow-down text-xl text-slate-200"
                      >
                        <use
                          xlink:href="./PATH/symbol-defs.svg#icon-arrow-down"
                        ></use>
                      </svg>
                    </div>
                  </div>

                  <div class="w-full flex justify-between items-center mt-1">
                    <div class="text-sm">
                      <div class="text-slate-300">Result:</div>
                      <div class="text-accent font-bold">Arsenal FC</div>
                    </div>
                    <div class="flex-shrink-0 text-slate-300">
                      <div
                        class="text-xs font-bold rounded-sm px-1 py-0.5 bg-slate-200 text-white"
                      >
                        3.40
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel-header>

                <div
                  class="xx-ticket-detail border-t border-slate-75 px-4 py-1"
                >
                  <table class="w-full">
                    <thead>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs"
                      >
                        <th
                          colspan="2"
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          <svg
                            class="icon-svg icon-live_in_rectangle-fill text-3xl text-accent"
                          >
                            <use
                              xlink:href="./PATH/symbol-defs.svg#icon-live_in_rectangle-fill"
                            ></use>
                          </svg>
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        ></th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          1.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          2.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          3.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          4.
                        </th>
                        <th
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-normal"
                        >
                          5.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs last:border-0"
                      >
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          <svg
                            class="icon-svg icon-shield-with-star-fill text-lg align-bottom text-slate-100"
                          >
                            <use
                              xlink:href="./PATH/symbol-defs.svg#icon-shield-with-star-fill"
                            ></use>
                          </svg>
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right truncate max-w-0"
                        >
                          Arsenal FC
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-bold whitespace-nowrap text-accent before:w-3 before:h-3 before:align-text-top before:mt-[2px] before:inline-block before:mr-2.5 before:rounded-full before:bg-slate-100 before:content-['_']"
                        >
                          2
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          7
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          1
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          2
                        </td>
                      </tr>
                      <tr
                        class="border-b border-slate-75 text-slate-300 text-center text-xs last:border-0"
                      >
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          <img
                            class="w-4 float-left align-bottom"
                            src="https://static.doxxbet.sk/teams/medium/2046.png"
                          />
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right truncate max-w-0"
                        >
                          Arsenal FC
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right font-bold whitespace-nowrap text-accent"
                        >
                          2
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          7
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          5
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          1
                        </td>
                        <td
                          class="px-1 h-[3.2em] first:text-left first:pl-0 first:pr-0 child-2:text-left child-2:font-bold child-2:w-full child-2:text-slate child-3:text-right"
                        >
                          2
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </mat-expansion-panel>
            </div>
          </section>
        </div>
      </div> -->
    </div>
  </div>
  <!-- REDESIGN TICKET DETAIL -->

  <!-- <ion-row *ngIf="!isStoredTicket">
    <ion-col size="12" class="ion-padding-top ion-text-center">
      <div
        id="ticketStatusIcon"
        [ngClass]="_ticketDetail | statusTransform: true:true"
      ></div>
    </ion-col>
  </ion-row>
  <ion-row class="ion-padding" *ngIf="!isStoredTicket">
    <ion-col
      *ngIf="!_ticketDetail.compensationTicket && !ticketDetail.noRiskTicket"
      id="ticketStatusText"
      size="12"
      class="ion-text-center"
    >
      <div
        *ngIf="ticketDetail.status === 'won' && !ticketDetail.buyBackActivity"
      >
        {{ 'ticketHistory_won_labelOne' | translate }}
      </div>
      <div
        *ngIf="ticketDetail.status === 'won' && ticketDetail.buyBackActivity"
      >
        {{ 'ticketHistory_cashback_labelOne' | translate }}
      </div>
      <div *ngIf="ticketDetail.status === 'lost'">
        {{ 'ticketHistory_lost_labelOne' | translate }}
      </div>
      <div *ngIf="ticketDetail.status === 'pend'">
        {{ 'ticketHistory_pend_labelOne' | translate }}
      </div>
      <div *ngIf="ticketDetail.status === 'canceled'">
        {{ 'ticketHistory_canceled_labelOne' | translate }}
      </div>
      <div *ngIf="ticketDetail.status === 'refused'">
        {{ 'ticketHistory_refused_labelOne' | translate }}
      </div>
      <div *ngIf="ticketDetail.status === 'await'">
        {{ 'ticketHistory_await_labelOne' | translate }}
      </div>
    </ion-col>
  </ion-row>
  <ng-container
    *ngIf="ticketDetail.compensationTicket || ticketDetail.noRiskTicket"
  >
    <ion-row>
      <ion-col id="ticketStatusText" size="12" class="ion-text-center">
        <div *ngIf="ticketDetail.compensationTicket">
          {{ 'ticketHistory_compensed_labelOne' | translate }}
        </div>
        <div *ngIf="ticketDetail.noRiskTicket">
          {{ 'ticketHistory_norisk_labelOne' | translate }}
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="ion-text-center ion-padding-bottom">
      <ion-col *ngIf="ticketDetail.noRiskTicket" class="ticket-detail-label">
        {{ 'ticketHistory_norisk_labelTwo' | translate }}
      </ion-col>
      <ion-col
        *ngIf="ticketDetail.compensationTicket"
        class="ticket-detail-label"
      >
        {{ 'ticketHistory_compensed_labelTwo' | translate }}
      </ion-col>
    </ion-row>
  </ng-container>
  <ion-row
    class="ion-text-center ion-padding-bottom"
    *ngIf="ticketDetail.freeBetTicket"
  >
    <ion-col size="12" class="ticket-detail-label">
      {{ 'ticketHistory_freebet_labelOne' | translate }}
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6" class="ion-text-left ticket-detail-label">

      {{ 'ticket_history_detail_edit_history_table_time' | translate }}
    </ion-col>
    <ion-col size="6" class="ion-text-right ticket-detail-value">
      {{ ticketDetail.ticketDate | formatDate: 'YYYYMMDDhhmm' }}
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6" class="ion-text-left ticket-detail-label">
      {{ 'ticket_detail_typ' | translate }}
    </ion-col>
    <ion-col
      *ngIf="ticketDetail.ticketType === 'JT'"
      size="6"
      class="ion-text-right ticket-detail-value"
    >
      {{ 'ticket_simple' | translate }}
    </ion-col>
    <ion-col
      *ngIf="ticketDetail.ticketType !== 'JT'"
      size="6"
      class="ion-text-right ticket-detail-value"
    >
      {{ 'ticket_combined' | translate }}
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6" class="ion-text-left ticket-detail-label">
      {{ 'help_ticket_stake' | translate }}
    </ion-col>
    <ion-col size="6" class="ion-text-right ticket-detail-value">
      {{ ticketDetail.amountMoneyOverall | formatNumberWithCurrency }}
    </ion-col>
  </ion-row>
  <ion-row *ngIf="parseFloat(ticketDetail.overallFee) !== 0">
    <ion-col size="6" class="ion-text-left ticket-detail-label">
      {{ 'ticket_fee' | translate }}
    </ion-col>
    <ion-col size="6" class="ion-text-right ticket-detail-value">
      {{ ticketDetail.overallFee | formatNumberWithCurrency}}
    </ion-col>
  </ion-row>
  <ion-row *ifAvailable="'betslip.bonus'">
    <ion-col size="6" class="ion-text-left ticket-detail-label">
      {{ 'ticket_bonus' | translate }}
    </ion-col>
    <ion-col size="6" class="ion-text-right ticket-detail-value">
      {{ ticketDetail.doxxbetBonus | formatNumberWithCurrency }}
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6" class="ion-text-left ticket-detail-label">
      {{ 'ticket_detail_rate' | translate }}
    </ion-col>
    <ion-col size="6" class="ion-text-right">
      <ion-badge class="rate overall">
        {{ ticketDetail.ticketRateOverall | rate }}
      </ion-badge>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="ticketDetail.OddsCombi">
    <ion-col size="6" class="ion-text-left ticket-detail-label">
      {{ 'ticket_count' | translate }}
    </ion-col>
    <ion-col size="6" class="ion-text-right ticket-detail-label">
      {{ ticketDetail.OddsCombi.length }}
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6" class="ion-text-left ticket-detail-label">
      <div *ngIf="ticketDetail.status === 'won'">
        {{ 'history_won' | translate }}
      </div>
      <div *ngIf="ticketDetail.status !== 'won'">
        {{ 'ticket_eventual_win' | translate }}
      </div>
    </ion-col>
    <ion-col size="6" class="ion-text-right bold ticket-detail-won">
      <span
        *ngIf="ticketDetail.status === 'won'"
        class="bold"
        [ngClass]="ticketDetail | statusTransform: false:true"
      >
        {{ ticketDetail.amountMoneyWon | formatNumberWithCurrency }}
      </span>
      <span
        *ngIf="ticketDetail.status === 'lost'"
        [ngClass]="ticketDetail | statusTransform: false:true"
      >
  
        {{ ticketDetail.amountMoneyWin | formatNumberWithCurrency }}
      </span>
      <span
        *ngIf="ticketDetail.status !== 'lost' && ticketDetail.status !== 'won'"
        class="bold"
        [ngClass]="
          ticketDetail.status === 'canceled' ? 'neutral' : ticketDetail.status
        "
      >
      
        {{ ticketDetail.amountMoneyWin | formatNumberWithCurrency }}
      </span>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="ticketDetail.winDate">
    <ion-col size="6" class="ion-text-left ticket-detail-label">
      {{ 'ticket_windate' | translate }}
    </ion-col>
    <ion-col size="6" class="ion-text-right ticket-detail-label">
      {{ ticketDetail.winDate | formatDate: 'YYYYMMDDhhmm' }}
    </ion-col>
  </ion-row> -->
  <!-- {{ ticketDetail.status }} -->
  <!-- <ion-row
    *ngIf="
      buybackValue !== -1 &&
      ticketDetail.status !== 'canceled' &&
      ticketDetail.status !== 'won' &&
      ticketDetail.status !== 'await' &&
      ticketDetail.status !== 'lost' &&
      isClientLoggedIn &&
      ('betslip.buyBack' | available) &&
      !isStoredTicket
    "
  >
    <ion-col>
      <ion-button
        class="auto-buy-back-btn"
        expand="block"
        (click)="showAutoBuybackModal()"
      >
        <i
          class="icon-ic_gear-arrows-stroke autobuyback-icon"
          *ngIf="ticketDetail.autobuybackValue"
        ></i>
 

        <span class="autobuyback-text"> Automatické vyplatenie</span>
        <span class="autobuyback-short-text"> Auto. vyplatenie</span>
   
        <span *ngIf="ticketDetail.autobuybackValue"
          >&nbsp;-&nbsp;
          {{ ticketDetail.autobuybackValue | formatNumberWithCurrency }}
        </span>
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row
    *ngIf="
      buybackValue &&
      buybackValue > 0 &&
      !ticketDetail.buyBackActivity &&
      ticketDetail.status !== 'canceled' &&
      isClientLoggedIn &&
      ('betslip.buyBack' | available)
    "
  >
    <ion-col>
      <ion-button
        class="buy-back-btn"
        expand="block"
        (click)="showBuybackModal()"
      >
        Vyplatiť tiket za
        {{ buybackValue | formatNumberWithCurrency }}
      </ion-button>
    </ion-col>
  </ion-row> -->
  <!-- {{ 'ticket_buyback_sellticketfor' | translate }} -->
</ion-grid>
