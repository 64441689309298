<div class="analysis analysis-detail" *ngIf="analysis">
  <div class="analysis-body">
    <div class="path">
      <ion-badge
        [routerLink]="
          'PrematchList'
            | translateRoute
              : {
                  sportID: [analysis.SportID, analysis.SportName | slugify]
                }
        "
        [style.background]="
          'var(--xx-sport-color-' + analysis.SportID + ', black)'
        "
        ><h4>{{ analysis.SportName }}</h4></ion-badge
      >
      <ion-badge
        [routerLink]="
          'PrematchList'
            | translateRoute
              : {
                  sportID: [analysis.SportID, analysis.SportName | slugify],
                  regionID: [analysis.RegionID, analysis.RegionName | slugify]
                }
        "
        [style.background]="
          'var(--xx-sport-color-' + analysis.SportID + ', black)'
        "
        ><h4>{{ analysis.RegionName }}</h4></ion-badge
      >
      <ion-badge
        [routerLink]="
          'PrematchList'
            | translateRoute
              : {
                  sportID: [analysis.SportID, analysis.SportName | slugify],
                  regionID: [analysis.RegionID, analysis.RegionName | slugify],
                  leagueID: [
                    analysis.LeagueCupID,
                    analysis.LeagueCupName | slugify
                  ]
                }
        "
        [style.background]="
          'var(--xx-sport-color-' + analysis.SportID + ', black)'
        "
        ><h4>{{ analysis.LeagueCupName }}</h4></ion-badge
      >
    </div>
    <div class="analysis-date">
      {{ analysis.EventDateToString | formatDate: 'YYYYMMDDhhmm' }}
    </div>
    <div class="analysis-title-wrapper">
      <h1
        class="analysis-title"
        [routerLink]="
          'EventDetail'
            | translateRoute
              : {
                  sportID: [analysis.SportID, analysis.SportName | slugify],
                  regionID: [analysis.RegionID, analysis.RegionName | slugify],
                  leagueID: [
                    analysis.LeagueCupID,
                    analysis.LeagueCupName | slugify
                  ],
                  eventID: analysis.EventID,
                  eventName:
                    analysis.TeamHome + 'vs.' + analysis.TeamAway | slugify
                }
        "
      >
        {{ analysis.TeamHome }} vs. {{ analysis.TeamAway }}
      </h1>
    </div>
    <div class="analysis-banner">
      <img
        [src]="analysis.Banner | safe: 'resourceUrl'"
        alt="{{ analysis.TeamHome }} vs. {{ analysis.TeamAway }}"
      />
    </div>
    <div class="analysis-article">
      <p [innerHTML]="analysis.Article"></p>
    </div>
  </div>
  <div class="analysis-footer" *ngIf="analysis.Stats">
    <div class="analysis-stats-progress-container">
      <h3 class="analysis-stats-title">
        {{ 'analysis_statistics' | translate }}
      </h3>
      <div class="analysis-stats-progress">
        <img
          class="icon-img"
          src="{{ 'staticUrl' | env }}/teams/medium/{{
            analysis.TeamHomeID
          }}.png"
          (error)="$event.target.style.visibility = 'hidden'"
        />
        <h4 class="team-name">{{ analysis.TeamHome }}</h4>
        <div class="progressbar-wrapper">
          <div [style.width.%]="analysis.Stats.Tip1" class="progressbar"></div>
        </div>
        <span class="progress">{{ analysis.Stats.Tip1 }}%</span>

        <!-- HIDE DRAW STATS ON BLACKLISTED SPORTS IDS -->
        <ng-container *ngIf="![58, 55, 82, 171].includes(analysis.SportID)">
          <span>X</span>
          <h4 class="team-name">{{ 'app_draw' | translate }}</h4>
          <div class="progressbar-wrapper">
            <div
              [style.width.%]="analysis.Stats.TipX"
              class="progressbar"
            ></div>
          </div>
          <span class="progress">{{ analysis.Stats.TipX }}%</span>
        </ng-container>

        <img
          class="icon-img"
          src="{{ 'staticUrl' | env }}/teams/medium/{{
            analysis.TeamAwayID
          }}.png"
          (error)="$event.target.style.visibility = 'hidden'"
        />
        <h4 class="team-name">{{ analysis.TeamAway }}</h4>
        <div class="progressbar-wrapper">
          <div [style.width.%]="analysis.Stats.Tip2" class="progressbar"></div>
        </div>
        <span class="progress">{{ analysis.Stats.Tip2 }}%</span>
      </div>
    </div>
  </div>
</div>
