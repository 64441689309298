import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewNoticeComponent } from './new-notice.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [NewNoticeComponent],
  imports: [CommonModule, IonicModule],
  exports: [NewNoticeComponent]
})
export class NewNoticeModule {}
