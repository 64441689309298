import { Injectable } from '@angular/core';
import { configuration } from '@configuration';
import { ModalController } from '@ionic/angular';
import { ResponsibleGamingModalComponent } from './responsible-gaming-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ResponsibleGamingModalService {
  constructor(private _modalController: ModalController) {}

  /**
   * Displays modal with rewsponible gaming content with countdown animation
   */
  showResponsibleGamingModal(): void {
    if (configuration.responsibleGaming.modalAllowed) {
      this._modalController
        .create({
          component: ResponsibleGamingModalComponent,
          cssClass: 'responsible-gaming-modal',
          mode: 'ios',
          backdropDismiss: false,
          keyboardClose: false,
          swipeToClose: false
        })
        .then(modal => {
          modal.present();
        });
    }
  }

  /**
   * Displays modal with rewsponible gaming content withou countdown animation
   * modal can be dissmisable
   */
  showDismissableResponsibleGamingModal(): void {
    if (configuration.responsibleGaming.modalAllowed) {
      this._modalController
        .create({
          component: ResponsibleGamingModalComponent,
          cssClass: 'responsible-gaming-modal',
          mode: 'ios',
          backdropDismiss: true,
          keyboardClose: true,
          swipeToClose: true,
          componentProps: {
            isDismissable: true
          }
        })
        .then(modal => {
          modal.present();
        });
    }
  }
}
