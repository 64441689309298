import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { oneLine } from '@core/functions/oneLine';
import { map } from 'rxjs/operators';
import { Analysis, AnalysisWithOdds } from '@models/Analysis';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@core/services/translation/translation.service';

@Injectable({
  providedIn: 'root'
})
export class AnalysesService {
  constructor(
    private _httpClient: HttpClient,
    private _translateService: TranslateService,
    private _translationService: TranslationService
  ) {}

  /**
   * Fetch list of actual analyses
   */
  fetchActualAnalysisList(): Observable<AnalysisWithOdds[]> {
    //setTimeout(() => {
    const result = this._httpClient
      .get<any>(
        // oneLine`https://m-api.doxxbet.sk/Analyze/EventAnalyze/languageID/sk/sendStats/true/onlyNewer/true`
        oneLine`${environment.apiUrl}/json/Analyze/EventAnalyze/languageID/${environment.language}/sendStats/true/onlyNewer/true`
      )
      .pipe(
        map(res => {
          const offerList = res?.OfferList;
          const odds: any[] = offerList?.Odds;
          const eventChanceTypes: any[] = offerList?.EventChanceTypes;
          const analyses: AnalysisWithOdds[] = res.Response.map(
            (_analysis: Omit<Analysis, 'Stats'>): AnalysisWithOdds => ({
              ..._analysis,
              Stats: res.Stats.find(stat => stat.EventID === _analysis.EventID),
              EventDateToString: _analysis.EventDateToString?.replace(' ', 'T'),
              Odds: []
            })
          );
          if (offerList) {
            analyses.forEach(analysis => {
              Object.values(eventChanceTypes)
                .filter(ect => ect.EventID === analysis.EventID)
                .forEach(ect => {
                  analysis.Odds = [
                    ...analysis.Odds,
                    ...Object.values(odds)
                      .filter(
                        odd => odd.EventChanceTypeID === ect.EventChanceTypeID
                      )
                      .map(odd => {
                        let templabel;
                        if (this._translationService.ready) {
                          templabel =
                            this._translateService.instant('app_draw');
                        } else {
                          if (environment.language === 'sk') {
                            templabel = 'Remíza';
                          } else {
                            templabel = 'Draw';
                          }
                        }
                        const [home, away] = ect.EventName.split(' vs.');
                        return {
                          ...odd,
                          OddNameNaming:
                            odd.TipType === '1'
                              ? home
                              : odd.TipType === '2'
                              ? away
                              : odd.TipType === 'X'
                              ? templabel
                              : odd.TipType,
                          OddNameNumbering: odd.TipType
                        };
                      })
                  ];
                });
            });
          }
          return analyses;
        })
      );
    return result;

    //    }, 10);
  }

  /**
   * Fetch list of event ids with analysis
   */
  fetchAnalysisHistory(cursor: {
    page: number;
    itemsPerPage: number;
    date: string; // Date in format MM-DD-YYYY
  }): Observable<Analysis[]> {
    const result = this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/Analyze/EventAnalyzeFilter
          /languageID/${environment.language}
          /sendStats/true
          /date/${cursor.date}
          /startIndex/${cursor.page}
          /pageSize/${cursor.itemsPerPage}`
      )
      .pipe(
        map(res =>
          res.Response.map(
            (analysis: Omit<Analysis, 'Stats'>): Analysis => ({
              ...analysis,
              Stats:
                res.Stats &&
                res.Stats.find(stat => stat.EventID === analysis.EventID),
              EventDateToString: analysis.EventDateToString?.replace(' ', 'T')
            })
          )
        )
      );
    return result;
  }

  /**
   * Fetch analysis detail
   */
  fetchAnalysisDetail(analysisID: number): Observable<Analysis> {
    const result = this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}/Analyze/EventAnalyzeByEventID/languageID/${environment.language}/sendStats/true/eventID/${analysisID}`
      )
      .pipe(
        map(res => ({
          ...res.Response,
          Stats: res.Stats
        }))
      );
    return result;
  }
}
