/** generates device code in GUID form */
export function generateDeviceCode(): string {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

/** verify if device code is in GUID form */
export function isValidDeviceCode(deviceCode): boolean {
  return (
    typeof deviceCode === 'string' &&
    /[(a-f)|\d]{8}-[(a-f)|\d]{4}-4[(a-f)|\d]{3}-.[(a-f)|\d]{3}-[(a-f)|\d]{12}/.test(
      deviceCode
    )
  );
}
