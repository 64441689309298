import { createReducer, on } from '@ngrx/store';
import { landingActions } from '@store/actions';
import { IMyAvailableBonus } from '@models/Bonus';

export interface State {
  casinoWins: {
    loading: boolean;
    wins: any[];
    error: any;
  };
  bonusesStatus: {
    loading: boolean;
    hasActiveBonuses: boolean;
    error: any;
  };
}

const initialMyAccountState: State = {
  casinoWins: {
    loading: false,
    wins: [],
    error: null
  },
  bonusesStatus: {
    loading: false,
    hasActiveBonuses: false,
    error: null
  }
};

export const myAccountReducer = createReducer(
  initialMyAccountState,

  // MY CASINO WINS
  on(landingActions.loadMyCasionWins, (state, action) => ({
    ...state,
    casinoWins: {
      ...state.casinoWins,
      loading: true,
      error: undefined
    }
  })),
  on(landingActions.loadMyCasionWinsSuccess, (state, action) => ({
    ...state,
    casinoWins: {
      ...state.casinoWins,
      loading: false,
      error: null,
      wins: action.wins
    }
  })),
  on(landingActions.loadMyCasionWinsFailure, (state, action) => ({
    ...state,
    casinoWins: {
      ...state.casinoWins,
      loading: false,
      error: action.error
    }
  })),
  on(landingActions.loadBonusesStatus, state => ({
    ...state,
    bonusesStatus: {
      ...state.bonusesStatus,
      loading: true,
      error: null
    }
  })),
  on(landingActions.loadBonusesStatusSuccess, (state, action) => ({
    ...state,
    bonusesStatus: {
      ...state.bonusesStatus,
      loading: false,
      error: null,
      hasActiveBonuses: action.hasActiveBonuses
    }
  })),
  on(landingActions.loadBonusesStatusFailure, (state, action) => ({
    ...state,
    bonusesStatus: {
      ...state.bonusesStatus,
      loading: false,
      error: action.error,
      hasActiveBonuses: false
    }
  }))
);
