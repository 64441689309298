<form (ngSubmit)="manualTicketInput()" [formGroup]="ticketGuidForm">
  <ion-grid
    id="content"
    class="ion-no-padding"
    [ngClass]="
      style === 'landing' ? 'landing-section-dark' : 'single-component'
    "
  >
    <ion-row
      class="form-group ion-margin-horizontal"
      *ngIf="style === 'classic'"
    >
      <ion-col class="label-scan ion-padding-vertical ion-text-center">
        {{ 'sportbox_ticket_scanner_text' | translate }}
      </ion-col>
    </ion-row>
    <ion-row class="form-group ion-margin-horizontal">
      <ion-col
        class="ion-no-padding"
        *ngFor="
          let control of ticketGuidForm.controls | keyvalue;
          trackBy: 'key' | trackByProperty;
          let i = index
        "
      >
        <ion-input
          #input
          class="form-control ion-no-padding"
          (keyup)="handleInput(input, i)"
          [formControlName]="control.key"
          maxlength="4"
          placeholder="- - - -"
          autocapitalize="characters"
          required
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="ion-margin-top ion-margin-horizontal">
      <ion-col *ngIf="style === 'landing'" size="6">
        <ion-button
          [routerLink]="'BetslipScanner' | translateRoute"
          id="ticketScanner"
          expand="block"
          class="button"
        >
          {{ 'sportbox_ticket_scanner' | translate }}
        </ion-button>
      </ion-col>
      <ion-col [size]="style === 'landing' ? 6 : 12">
        <ion-button
          id="ticketVerify"
          type="submit"
          expand="block"
          class="button"
          [disabled]="ticketGuidForm.invalid"
        >
          {{ 'sportbox_ticket_verify_confirm' | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
