import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainHeaderComponent } from './main-header.component';
import { MainHeaderBackButtonComponent } from './main-header-back-button/main-header-back-button.component';
import { IonicModule } from '@ionic/angular';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { RouterModule } from '@angular/router';
import { MainHeaderSearchBarComponent } from './main-header-search-bar/main-header-search-bar.component';
import { DoxxDirectivesModule } from '@shared/directives/doxx-directives.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { NotificationsModule } from '@shared/components/notifications/notifications.module';

@NgModule({
  declarations: [
    MainHeaderComponent,
    MainHeaderBackButtonComponent,
    MainHeaderSearchBarComponent

  ],
  imports: [
    CommonModule,
    IonicModule,
    DoxxPipesModule,
    DoxxDirectivesModule,
    RouterModule,
    UtilsModule,
    NotificationsModule
  ],
  exports: [MainHeaderComponent, MainHeaderBackButtonComponent]
})
export class MainHeaderModule {}
