import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { authActions } from '@store/actions';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class LoginCheckInterceptor implements HttpInterceptor {
  constructor(private _store: Store) {}

  /** Po requeste overi ci je pouzivatel odhlaseny ak ano tak pouzivatela odhlasi z appky */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((e: HttpEvent<any>) => {
        if (e instanceof HttpResponse) {
          if (e?.body?.errors?.api?.codes?.includes(4010) || false) {
            this._store.dispatch(authActions.softLogoutStart());
          }
        }
        return e;
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err?.error?.errors?.api?.codes.includes(4010) || false) {
            this._store.dispatch(authActions.softLogoutStart());
          }
        }

        return throwError(err);
      })
    );
  }
}
