<ion-header
  *ngIf="
    ['ios', 'android'].includes(getOperatingSystem()) &&
    shouldShowAppModal !== false &&
    isAllowed()
  "
  id="download-app-banner"
>
  <div class="banner">
    <i class="icon icon-ic_close-small" (click)="hideAppModal()"></i>
    <img
      [src]="
        getOperatingSystem() === 'ios'
          ? 'assets/images/appicon-ios.png'
          : 'assets/images/appicon-android.png'
      "
    />
    <div class="info">
      <span class="app-title">{{ 'app_title' | translate }}</span>
      <span class="app-subtitle">{{ 'app_subtitle' | translate }}</span>
      <span class="app-platform" *ngIf="getOperatingSystem() === 'android'">{{
        'app_android' | translate
      }}</span>
      <span class="app-platform" *ngIf="getOperatingSystem() === 'ios'">{{
        'app_ios' | translate
      }}</span>
    </div>
    <a
      *ngIf="getOperatingSystem() === 'ios'"
      class="download ios"
      (click)="downloadIosApp(); hideAppModal()"
      >{{ 'download_ios' | translate }}</a
    >
    <a
      [href]="otherPlatforms.android.link"
      *ngIf="getOperatingSystem() === 'android'"
      class="download android"
      (click)="hideAppModal()"
      target="_blank"
      >{{ 'download_android' | translate }}</a
    >
  </div>
</ion-header>
