import { Component, OnInit, Input } from '@angular/core';
import { NoticeSettup } from './notice.model';
import { NoticeService } from './notice.service';

@Component({
  selector: 'doxx-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
/**
 * Dokumentacia v notice.model.ts
 */
export class NoticeComponent implements OnInit {
  @Input() noticeSetup: NoticeSettup;
  noticeRadio: boolean;

  constructor(private _noticeService: NoticeService) {}
  /** oninit */
  ngOnInit(): void {
    if (this.noticeSetup.radioText) {
      this.noticeRadio = false;
    } else {
      this.noticeRadio = true;
    }
  }

  /**
   * button click
   */
  buttonClick(context: string): void {
    this._noticeService.buttonEmit(context);
  }
}
