<ion-grid [ngStyle]="{ 'background-color': background }">
  <ion-row class="bottom-padding">
    <ion-col size="2" *ngIf="icon.length > 0">
      <ion-icon class="{{ icon }}" [ngStyle]="{ color: iconColor }"></ion-icon>
    </ion-col>
    <ion-col class="right-padding">
      <h1><span [innerHTML]="header"></span></h1>
      <p><span [innerHTML]="message"></span></p>
      <ion-button
        (click)="emitClick()"
        *ngIf="btnText"
        color="secondary"
        expand="block"
        size="small"
        >{{ btnText }}</ion-button
      >
    </ion-col>
  </ion-row>
</ion-grid>
