import { Environment } from './types/Environment';

export const environment: Environment = {
  production: false,
  location: 'svk',
  apiUrl: 'https://m-api-responsive-staging.doxxbet.sk',
  hubConnection: 'https://offer-signalr-mobile-staging.doxxbet.sk/signalr',
  casinoGameURL:
    'https://extstg3-login01.ptstaging.eu/GameLauncher?gameCodeName=',
  casinoURLparam: 'ptstaging3.35',
  name: 'default',
  language: 'sk',
  staticUrl: 'https://static.doxxbet.sk',
  sportradarStatisticsUrl: 'https://s5.sir.sportradar.com/doxxbetm',
  cookies: {
    lang: {
      key: 'lang',
      expiration: '7'
    },
    appModal: {
      key: 'ShowAppModal',
      expiration: '365'
    },
    acceptCookies: {
      key: 'CookiesAccept',
      expiration: '365'
    },
    allowedRateChange: {
      key: 'AllowedRateChange',
      expiration: '365'
    }
  },
  virtualGames: {
    vgSportID: 153
  },
  betBuilder: {
    oddsDelimiter: ' && ',
    betBuilderUrl: 'https://m-betbuilder-staging.doxxbet.sk'
  },
  notLoginExceptionCode: 4010,
  configVersion: '0.0',
  firebase: {
    config: {
      apiKey: 'AIzaSyDEUUlJb1ht92jXA0FXvXQIqkVSgqGho-Y',
      authDomain: 'doxxbet-mobile-stg.firebaseapp.com',
      databaseURL: 'https://doxxbet-mobile-stg.firebaseio.com',
      projectId: 'doxxbet-mobile-stg',
      storageBucket: 'doxxbet-mobile-stg.appspot.com',
      messagingSenderId: '1021348191649',
      appId: '1:1021348191649:web:72e5c3ca9abe5f06ebffe0',
      measurementId: 'G-B9RKW8V6NX'
    },
    vapidKey:
      'BMK25c6pyI1Kl2THONo2RB9gYKe5sgK7Yu39Bfbj2jy5m5HthqjvNqmk5_4FCmUK3FIibksC2tnUQrcfOE2ITM8'
  },
  notificationHubUrl:
    'https://notifications-hub-staging.doxxbet.sk/NotificationsHub'
};
