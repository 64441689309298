import { environment } from '@environment';
import {
  NAVIGATION,
  NavigationConfig,
  ParamValue,
  ScreenConfig,
  ScreenParamsType
} from 'src/navigation';

/**
 * Translate route based on environment navigation object
 * Navigation starts from /:lang prefix
 * lang is automaticaly bind from environment
 * @param path exapmles for navigating from root :
 * 'AnalysisList' => /en/analysis
 * 'PrematchList', {'sportID': [58,futbal]} => /sk/sportove-tipovanie/futbal
 */
export function translateRoute<T extends keyof NavigationConfig>(
  screen: T,
  params?: { [key in ScreenParamsType<T>]?: ParamValue }
): string {
  const lang: string = environment.language;
  const [screenName, ...screenParams] = screen?.split('/') || [];
  const navScreen: ScreenConfig<any> = NAVIGATION[screenName];
  // let routeParams = '';

  if (!params && screenParams.length > 0) {
    // prefix param with index to keep ordering
    params = screenParams.reduce(
      (accumulator, value, index) => ({
        ...accumulator,
        [navScreen.params?.[index]]: value
      }),
      {}
    );
  }

  if (params) {
    Object.entries(params).forEach(([paramName, param]) => {
      params[paramName] = {
        value: Array.isArray(param) ? param[0]?.toString() : param?.toString(),
        fallbackValue: Array.isArray(param)
          ? param[1]?.toString()
          : param?.toString()
      };
    });
  }

  const screenLink = navScreen?.buildLink(params as any);
  let route = `/${lang}`;
  route = screenLink?.length ? `${route}/${screenLink}` : route;

  return route;
}

translateRoute('NewsList', {});
translateRoute('EventDetail', { eventID: '' });
