import {
  AllowedRateChange,
  Combi,
  Ticket,
  ticketFlag,
  TicketOdd,
  TicketOddsCombi,
  TicketType
} from '@models/Ticket';
import { TicketHistroyDetail } from '@models/TicketHistory';
import { createAction, props } from '@ngrx/store';

export const systemTicketError = createAction(
  '[Ticket] System Error',
  props<{ error: any }>()
);

/** SWITCH TICKET */
export const switchTicket = createAction(
  '[Ticket] Switch',
  props<{ ticketFlag: ticketFlag }>()
);
export const switchTicketComplete = createAction(
  '[Ticket] Switch Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** RESET TICKET */
export const resetTicket = createAction('[Ticket] Reset');
export const resetTicketComplete = createAction(
  '[Ticket] Reset Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** REFRESH TICKET */
export const refreshTicket = createAction('[Ticket] Refresh');
export const refreshTicketComplete = createAction(
  '[Ticket] Refresh Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** REFRESH TICKET */
export const deltaTicket = createAction('[Ticket] Delta');
export const deltaTicketComplete = createAction(
  '[Ticket] Delta Complete',
  (payload?: { ticket: Partial<Ticket> }) => payload
);

/** UPDATE TICKET */
export const update = createAction(
  '[Ticket] Update',
  (payload?: {
    ticket: Partial<Omit<Ticket, 'Odds'> & { Odds: Partial<TicketOdd>[] }>;
  }) => payload
);

/** CHANGE TICKET TYPE */
export const changeTicketType = createAction(
  '[Ticket] Change Ticket Type',
  props<{ ticketType: TicketType }>()
);
export const changeTicketTypeComplete = createAction(
  '[Ticket] Change Ticket Type Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** SET TOTAL BET */
export const setTotalBet = createAction(
  '[Ticket] Set Total Bet',
  props<{ bet: string }>()
);
export const setTotalBetComplete = createAction(
  '[Ticket] Set Total Bet Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** SET COMBINATION STAKE */
export const setCombinationStake = createAction(
  '[Ticket] Set Combination Stake',
  props<{ bet: string; combiID?: string; isPartial: boolean }>()
);
export const setCombinationStakeComplete = createAction(
  '[Ticket] Set Combination Stake Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** SET FREEBET */
export const setFreebet = createAction(
  '[Ticket] Set Freebet',
  props<{ freebetID: string }>()
);
export const setFreebetComplete = createAction(
  '[Ticket] Set Freebet Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** REMOVE FREEBET */
export const removeFreebet = createAction('[Ticket] Remove Freebet');
export const removeFreebetComplete = createAction(
  '[Ticket] Remove Freebet Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** SET ALLOWED RATE CHANGE */
export const setAllowedRateChange = createAction(
  '[Ticket] Set Allowed Rate Change',
  props<{ allowedRateChange: AllowedRateChange }>()
);
export const setAllowedRateChangeComplete = createAction(
  '[Ticket] Set Allowed Rate Change Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** REMOVE ODDS */
export const removeOdds = createAction(
  '[Ticket] Remove Odds',
  props<{ odds: TicketOdd['OddID'][] }>()
);
export const removeOddsComplete = createAction(
  '[Ticket] Remove Odds Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** ADD ODD */
export const addOdds = createAction(
  '[Ticket] Add Odds',
  props<{ odds: (Pick<TicketOdd, 'OddID'> & Partial<TicketOdd>)[] }>()
);
export const addOddsComplete = createAction(
  '[Ticket] Add Odds Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** PLACE BET */
export const placeBet = createAction('[Ticket] Place Bet');
export const placeBetComplete = createAction(
  '[Ticket] Place Bet Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** CLONE TICKET */
export const cloneTicket = createAction(
  '[Ticket] Clone',
  props<{ ticketCode: TicketHistroyDetail['ticketCode'] }>()
);
export const cloneTicketSuccess = createAction(
  '[Ticket] Clone Success',
  (payload?: { ticket: Ticket }) => payload
);
export const cloneTicketFailure = createAction(
  '[Ticket] Clone Failure',
  props<{ errors: string[] }>()
);

/** EDIT TICKET */
export const editTicket = createAction(
  '[Ticket] Edit',
  props<{ ticketCode: TicketHistroyDetail['ticketCode'] }>()
);
export const editTicketComplete = createAction(
  '[Ticket] Edit Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** ODD BUYBACK */
export const oddBuyback = createAction(
  '[Ticket] Odd Buyback',
  props<{ oddID: TicketOdd['OddID'] }>()
);
export const oddBuybackComplete = createAction(
  '[Ticket] Odd Buyback Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** ODD BUYBACK RESET */
export const oddBuybackReset = createAction(
  '[Ticket] Odd Buyback Reset',
  props<{ oddID: TicketOdd['OddID'] }>()
);
export const oddBuybackResetComplete = createAction(
  '[Ticket] Odd Buyback Reset Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** CANCEL EDITATION*/
export const cancelEditation = createAction('[Ticket] Cancel Editation');
export const cancelEditationComplete = createAction(
  '[Ticket] Cancel Editation Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** CHANGE SUBSCRIPTION*/
export const subscribe = createAction(
  '[Ticket] Subscribe',
  props<{
    ticketCode: TicketHistroyDetail['ticketCode'];
    operation: 'SUBSCRIBE' | 'UNSUBSCRIBE';
  }>()
);
export const subscribeSuccess = createAction('[Ticket] Subscribe Sucess');
export const subscribeFailure = createAction('[Ticket] Subscribe Failure');

/** STORE TICKET */
export const storeTicket = createAction('[Ticket] Store');
export const storeTicketSuccess = createAction(
  '[Ticket] Store Success',
  (payload?: { ticket: Ticket }) => payload
);
export const storeTicketFailure = createAction(
  '[Ticket] Store Failure',
  props<{ errors: string[] }>()
);

/** RESTORE TICKET */
export const restoreTicket = createAction(
  '[Ticket] Restore',
  props<{ ticketCode: TicketHistroyDetail['ticketCode'] }>()
);
export const restoreTicketSuccess = createAction(
  '[Ticket] Restore Success',
  (payload?: { ticket: Ticket }) => payload
);
export const restoreTicketFailure = createAction(
  '[Ticket] Restore Failure',
  props<{ errors: string[] }>()
);

/** ODD TAG */
export const oddTag = createAction(
  '[Ticket] Odd Tag',
  props<{ odds: TicketOdd['OddID'][] }>()
);
export const oddTagComplete = createAction(
  '[Ticket] Odd Tag Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** ODD UNTAG */
export const oddUntag = createAction(
  '[Ticket] Odd Untag',
  props<{ odds: TicketOdd['OddID'][] }>()
);
export const oddUntagComplete = createAction(
  '[Ticket] Odd Untag Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** ODD TOP */
export const oddTop = createAction(
  '[Ticket] Odd Top',
  props<{ odds: TicketOdd['OddID'][] }>()
);
export const oddTopComplete = createAction(
  '[Ticket] Odd Top Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** ACTIVATE COMBI */
export const activateCombi = createAction(
  '[Ticket] Activate Combi',
  props<{ combi: Combi['ID'] }>()
);
export const activateCombiComplete = createAction(
  '[Ticket] Activate Combi Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** DEACTIVATE COMBI */
export const deactivateCombi = createAction(
  '[Ticket] Deactivate Combi',
  props<{ combi: Combi['ID'] }>()
);
export const deactivateCombiComplete = createAction(
  '[Ticket] Deactivate Combi Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** SET SPELL STAKE */
export const setSpellStake = createAction(
  '[Ticket] Set Spell Stake',
  props<{ bet: TicketOddsCombi['Bet']; spell: TicketOddsCombi['ID'] }>()
);
export const setSpellStakeComplete = createAction(
  '[Ticket] Set Spell Stake Complete',
  (payload?: { ticket: Ticket }) => payload
);

/** UNPIN COMBINATION */
export const unpinCombination = createAction(
  '[Ticket] Unpin Combination',
  props<{ combi: 'all' | string; spell: TicketOddsCombi['ID'] | 'all' }>()
);
export const unpinCombinationComplete = createAction(
  '[Ticket] Unpin Combination Complete',
  (payload?: { ticket: Ticket }) => payload
);
