import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef
} from '@angular/material/bottom-sheet';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environment';
import { Notification, NotificationsCount } from '@models/Notifications';
import { Observable, Subject } from 'rxjs';
import { delay, filter, first, map } from 'rxjs/operators';
import { NotificationsComponent } from './notifications.component';
import { initializeApp } from 'firebase/app';
import { deleteToken, getMessaging, getToken } from 'firebase/messaging';
import { Actions, ofType } from '@ngrx/effects';
import {
  authActions,
  notificationsActions,
  tickethistoryActions
} from '@store/actions';
import { oneLine } from '@core/functions/oneLine';
import { SignalrService } from 'src/app/services/signalr.service';
import { AppVisibilityService } from '@core/services/app-visibility/app-visibility.service';
import * as signalR from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { PresentMessageService } from 'src/app/services/present-message.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslationService } from '@core/services/translation/translation.service';
import { DEVICE_CODE } from '@core/core.module';
import { isUrl } from '@core/functions/isUrl/isUrl';
import { translateRoute } from '@core/functions/translateRoute';
import { NavigationConfig } from 'src/navigation';
import { config } from 'process';
import { configuration } from '@configuration';

// key to cookies, whe the notification pre alert was dismissed
const NOTIFICATION_PRE_ALERT_COOKIES_KEY = 'npa';

@Injectable()
export class NotificationsService {
  private _bottomSheetRef: MatBottomSheetRef;
  private _hub: signalR.HubConnection;

  signalRnotification$ = new Subject();

  constructor(
    private _bottomSheet: MatBottomSheet,
    private _router: Router,
    private _http: HttpClient,
    private _actions$: Actions,
    private _signalrService: SignalrService,
    private _appVisibilityService: AppVisibilityService,
    private _store: Store,
    private _presentMessage: PresentMessageService,
    private _translate: TranslateService,
    private _translationService: TranslationService,
    private _cookiesService: CookieService,
    @Inject(DEVICE_CODE) private _deviceCode: { getCode(): string }
  ) {
    // ability to close all notifications on app reveal
    // this._appVisibilityService.visibilityChangeReveal.subscribe(reveal =>
    //   setTimeout(() => {
    //     navigator.serviceWorker
    //       .getRegistration('firebase-cloud-messaging-push-scope')
    //       .then(registration =>
    //         registration
    //           ?.getNotifications()
    //           .then(notifications =>
    //             notifications.forEach(notification => notification.close())
    //           )
    //       );
    //   }, 2000)
    // );

    navigator.serviceWorker.addEventListener('message', event => {
      if (event.data.type === '[Notification] open') {
        this.openNotification({ url: event.data.notification.url });
      }
    });

    this._actions$
      .pipe(ofType(authActions.loginSuccess, authActions.autologinSuccess))
      .subscribe(action => {
        this.initSignalRNotifications();
      });

    this._actions$.pipe(ofType(authActions.loginSuccess)).subscribe(action => {
      navigator.serviceWorker
        .getRegistration('firebase-cloud-messaging-push-scope')
        .then(registration => registration?.update());

      const [time, count] = this._cookiesService
        .get(NOTIFICATION_PRE_ALERT_COOKIES_KEY)
        .split('-')
        .map(val => parseInt(val, 10));
      const dismissedDate = isNaN(time) ? null : new Date(time);
      const dismissedCount = isNaN(count) ? 0 : count;

      if (
        Notification.permission === 'default' &&
        configuration.notifications.allowed &&
        configuration.notifications.pushNotifications &&
        dismissedCount < 3 &&
        (!dismissedDate ||
          !dismissedDate.isValid() ||
          dismissedDate.isBefore(new Date().addDays(-14))) &&
        [
          action.settings.setting.sendBonusNotifications.value,
          action.settings.setting.sendTicketNotifications.value
        ].some(setting => setting === true)
      ) {
        this._translationService.ready$.pipe(delay(2000)).subscribe({
          complete: () =>
            this._presentMessage
              .presentCustomMessage(
                this._translate.instant('notifications_btn_allow_info'),
                'info-alert-blue',
                this._translate.instant('notifications_stay_with_us'),
                [
                  {
                    text: this._translate.instant('notifications_btn_allow'),
                    role: 'confirm',
                    cssClass: 'uppercase font-bold'
                  },
                  {
                    text: this._translate.instant('btn_not_now').toUpperCase(),
                    cssClass: 'uppercase font-bold',
                    role: 'dismiss'
                  }
                ]
              )
              .then(alert =>
                alert.onDidDismiss().then(event => {
                  if (event.role === 'confirm') {
                    this.registerFirebaseToken();
                  } else if (event.role === 'dismiss') {
                    this._cookiesService.set(
                      NOTIFICATION_PRE_ALERT_COOKIES_KEY,
                      `${Date.now().toString()}-${dismissedCount + 1}`
                    );
                  }
                })
              )
        });
      } else if (
        Notification.permission === 'granted' &&
        [
          action.settings.setting.sendBonusNotifications.value,
          action.settings.setting.sendTicketNotifications.value
        ].some(setting => setting === true)
      ) {
        this.registerFirebaseToken();
      }
    });

    this._actions$
      .pipe(ofType(authActions.logoutSuccess, authActions.softLogoutSuccess))
      .subscribe(action => {
        this._hub?.stop();
      });
  }

  /** register firebase token */
  registerFirebaseToken(): Promise<string> {
    const app = initializeApp(environment.firebase.config);
    const messaging = getMessaging(app);
    return new Promise((resolve, reject) => {
      getToken(messaging, {
        vapidKey: environment.firebase.vapidKey
      })
        .then(currentToken => {
          if (currentToken) {
            // firebase token
            resolve(currentToken);
          } else {
            // No registration token available. Request permission to generate one.
            Notification.requestPermission().then(permission => {
              if (permission === 'granted') {
                getToken(messaging, {
                  vapidKey: environment.firebase.vapidKey
                }).then(token => resolve(token));
              } else {
                throw new Error('Notification permission denied.');
              }
            });
          }
        })
        .catch(err => {
          // An error occurred while retrieving token.
          reject(err);
        });
    }).then((token: string) => {
      this.postTokenRegistration(token).subscribe();
      return token;
    });
  }

  /** unregister firebase token, manualy turn off notifications for user */
  unregisterFirebaseToken() {
    const app = initializeApp(environment.firebase.config);
    const messaging = getMessaging(app);
    return deleteToken(messaging);
  }

  /** show notifications */
  showNotifications(): void {
    this._bottomSheetRef?.dismiss();

    this._bottomSheetRef = this._bottomSheet.open(NotificationsComponent, {
      panelClass: ['notifications-bottom-sheet']
    });

    this._router.events
      .pipe(
        filter(ev => ev instanceof NavigationEnd),
        first()
      )
      .subscribe(() => this._bottomSheetRef?.dismiss());
  }

  /** get notifications list */
  getNotificationList(options?: {
    page?: number;
    pageSize?: number;
  }): Observable<Notification[]> {
    return this._http.get<Notification[]>(
      `${environment.apiUrl}/client/notification/hub/list`,
      {
        params: {
          page: options.page,
          page_size: options.pageSize
        }
      }
    );
  }

  /** get unread notifications count */
  getUnreadNotifications(): Observable<NotificationsCount> {
    return this._http.get<NotificationsCount>(
      `${environment.apiUrl}/client/notification/hub/unread/count`
    );
  }

  /** register firebase token */
  postTokenRegistration(token: string): Observable<any> {
    return this._http.post<any>(
      `${environment.apiUrl}/client/notification/hub/token/registration`,
      {
        deviceId: this._deviceCode.getCode(),
        token
      }
    );
  }

  /** start recieving notification over signalr channel */
  initSignalRNotifications(): void {
    this._http
      .post<any>(
        oneLine`${environment.apiUrl}/client/notification/hub/connection/token`,
        null
      )
      .subscribe(({ connectionToken }) => {
        this._hub?.stop();
        this._hub = new signalR.HubConnectionBuilder()
          .withUrl(
            oneLine`${environment.notificationHubUrl}?
              token=${encodeURIComponent(connectionToken)}&
              group=mobile_responsive_web`,
            {
              transport: signalR.HttpTransportType.WebSockets,
              skipNegotiation: true
            }
          )
          .withAutomaticReconnect()
          .build();
        this._hub.on('notify', event => {
          this.signalRnotification$.next(event);
          this._store.dispatch(
            notificationsActions.incrementUnreadNotificationsCount()
          );
          switch (event) {
            case 'Ticket':
              this._store.dispatch(authActions.balanceRefreshStart());
              this._store.dispatch(
                tickethistoryActions.refreshTickethistoryList()
              );
              break;
            case 'Freebet':
            case 'noriskbonus':
            case 'compensationbonus':
            case 'codebonus':
              this._store.dispatch(authActions.balanceRefreshStart());
              break;
          }
        });
        this._hub.start();

        this._signalrService.changeViews([connectionToken], 'notifications');
      });
  }

  /** opens notification */
  openNotification(
    notification: { url: string },
    options: { target: '_self' | '_blank' } = { target: '_self' }
  ): void {
    notification.url = notification.url.replace(
      /^(doxxbet:|doxxbetcasino:|doxxbetvlc:)/,
      ''
    );
    if (isUrl(notification.url)) {
      window.open(notification.url, options.target);
      this._router.navigate([''], {
        replaceUrl: true,
        queryParams: null,
        queryParamsHandling: 'merge'
      });
    } else {
      this._router.navigateByUrl(
        this._router.createUrlTree([
          translateRoute(notification.url as keyof NavigationConfig)
        ])
      );
    }
  }
}
