import { createReducer, on } from '@ngrx/store';
import { landingActions } from '@store/actions';
import { League } from '@models/League';
import { Region } from '@models/Region';

export interface State {
  leagues: League[];
  regions: Region[];
  loading: boolean;
  error: any;
}

const initialVirtualGamesState: State = {
  leagues: [],
  regions: [],
  loading: false,
  error: null
};

export const landingVirtualGamesReducer = createReducer(
  initialVirtualGamesState,
  on(landingActions.loadVirtualGamesList, (state, action) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(landingActions.loadVirtualGamesListSuccess, (state, action) => ({
    ...state,
    loading: false,
    leagues: action.leagues,
    regions: action.regions
  })),
  on(landingActions.loadVirtualGamesListFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.err
  }))
);
