import { Pipe, PipeTransform } from '@angular/core';
import {
  dateFormat,
  formatDate,
  formatOption
} from '@core/functions/formatDate';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  /** turn date to locale string */
  transform(date: Date | string, format: dateFormat | formatOption): string {
    if (typeof date === 'string') {
      const _date = new Date(date);
      if (_date.isValid()) {
        date = _date;
      } else {
        console.error(`Cannot convert value ${date} to Date`);
        return date;
      }
    }
    if (!(date instanceof Date)) {
      console.error(
        `Type error, expected Date or string, get ${JSON.stringify(date)}`
      );
      return date;
    }
    return formatDate(date, format);
  }
}
