import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventScoreTableComponent } from './event-score-table.component';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';

@NgModule({
  declarations: [EventScoreTableComponent],
  imports: [CommonModule, DoxxPipesModule],
  exports: [EventScoreTableComponent]
})
export class EventScoreTableModule {}
