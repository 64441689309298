import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'guid'
})
export class GuidPipe implements PipeTransform {
  /** transform to guid format */
  transform(str: string): string {
    return str.match(/.{1,4}/g).join('-');
  }
}
