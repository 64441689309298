import { User } from '@models/User';
import { createAction, props } from '@ngrx/store';

export const loadClientInfo = createAction('[PersonalInfo] Load client info');

export const reLoadClientInfo = createAction(
  '[PersonalInfo] Reload client info'
);

export const loadClientInfoSuccess = createAction(
  '[PersonalInfo] Load client info success',
  props<{ client: User; form: any }>()
);

export const loadBankCards = createAction('[PersonalInfo] Load bank cards');
