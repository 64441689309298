import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { landingActions } from '@store/actions';
import { OfferService } from '@core/services/offer/offer.service';
import { concatMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class LandingTopLeaguesEffect {
  loadTopLeagues$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(landingActions.loadTopLeagues),
      concatMap(action =>
        this._offerService.feetchTopLeagues(action.count).pipe(
          map(leagues => landingActions.loadTopLeaguesSuccess({ leagues })),
          catchError(error =>
            of(landingActions.loadTopLeaguesFailure({ error }))
          )
        )
      )
    );
  });

  constructor(
    private _actions$: Actions,
    private _offerService: OfferService
  ) {}
}
