import {
  Component,
  ContentChildren,
  QueryList,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { TabComponent, TAB_TRANSITION_DURATION } from './tab/tab.component';

@Component({
  selector: 'doxx-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss']
})
export class TabGroupComponent implements AfterViewInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  activeComponent: TabComponent;
  skipEmit = true;

  @ViewChild('tabOutlet') tabOutlet: ElementRef;

  preloaded = true;
  @Input() activeTabIndex = 0;
  @Output() tabWillChanged = new EventEmitter<any>();
  @Output() tabDidChanged = new EventEmitter<any>();
  get value(): string {
    return this.activeComponent?.value;
  }

  constructor() {}

  /** Update tabs position */
  ngAfterViewInit(): void {
    this.activeTabIndex = this.tabs
      .toArray()
      .findIndex(elem => elem.disabled === false);
    this.onTabChange();
  }

  /** Resolve tab positon */
  onTabChange(): void {
    this.tabs.forEach((tab, index) => {
      if (index < this.activeTabIndex) {
        tab.position = 'right';
      } else if (index > this.activeTabIndex) {
        tab.position = 'left';
      } else {
        tab.position = 'center';
        this.activeComponent = tab;
        if (!this.skipEmit) {
          this.tabWillChanged.emit(tab.value || index);
          setTimeout(() => {
            this.tabDidChanged.emit(tab.value || index);
          }, TAB_TRANSITION_DURATION);
        }
      }
    });
    this.skipEmit = false;
  }

  trackTabs = index => index;
}
