import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ParsedScore, parseScore } from '@core/functions/parseScore';
import { openCloseArrowAnimation } from 'src/app/animations';
import { Router } from '@angular/router';
import { translateRoute } from '@core/functions/translateRoute';
import { SignalrService } from 'src/app/services/signalr.service';
import { Subscription } from 'rxjs';
import { NavigationConfig } from 'src/navigation';
import { slugify } from '@core/functions/slugify';
import { CookieService } from 'ngx-cookie-service';
import { TicketHistroyDetail } from '@models/TicketHistory';
import { environment } from '@environment';
import { getCombinationGroups } from '@core/functions/getCombinationsGroups/getCombinationsGroup';
import { trycatch } from '@core/tryCatch/tryCatch';
import { TICKET_HISTORY_DETAIL_SIGNALR_SCOPE } from '@constants';
import { PresentMessageService } from 'src/app/services/present-message.service';
import { Odd } from '@models/Odd';
import { TranslateService } from '@ngx-translate/core';

const ORDER_KEY = 'OrderingTicketByTime';

type OddWithExtras = TicketHistroyDetail['odds'][number] & {
  extras: {
    isScoreExpanded: boolean;
    isSignalrConnected: boolean;
    parsedScore: ParsedScore;
    isVirtual: boolean;
    actualGamePartTime?: number;
    combinations?: {
      GroupValue: string;
      drowline2: boolean;
    };
  };
};

@Component({
  selector: 'doxx-ticketevents',
  templateUrl: './ticketevents.component.html',
  styleUrls: ['./ticketevents.component.scss'],
  animations: [openCloseArrowAnimation]
})
export class TicketeventsComponent implements OnInit, OnDestroy {
  _ticket: Omit<TicketHistroyDetail, 'odds'> & {
    odds: OddWithExtras[];
  };
  private _subscription$: Subscription;

  _orderingCache: string[] = trycatch(
    () => JSON.parse(this._cookieService.get(ORDER_KEY)),
    []
  );
  order: 'time' | 'default';

  @Input() set ticket(ticket: TicketHistroyDetail) {
    const prewSignalRScope =
      TICKET_HISTORY_DETAIL_SIGNALR_SCOPE + this._ticket?.ticketCode;
    const currentSignalRScope =
      TICKET_HISTORY_DETAIL_SIGNALR_SCOPE + ticket.ticketCode;
    // unsubscribe previous ticket
    if (prewSignalRScope !== currentSignalRScope) {
      this._signalrService.changeViews([], prewSignalRScope);
    }

    const combinations = getCombinationGroups(ticket.odds) || {};

    this._ticket = {
      ...ticket,
      odds: ticket.odds?.map((odd, index) => {
        const prewState = this._ticket?.odds?.find(
          _odd => _odd.oddId === odd.oddId
        )?.extras;
        const parsedScore = odd.scoreNew
          ? parseScore(odd.scoreNew, Number(odd.sportId))
          : null;
        return {
          ...odd,
          extras: {
            isScoreExpanded:
              prewState?.isScoreExpanded ||
              (!!parsedScore && odd.eventLiveActivity),
            parsedScore,
            isSignalrConnected: prewState?.isSignalrConnected || false,
            isVirtual:
              odd.sportId === environment.virtualGames.vgSportID.toString(),
            combinations: combinations[odd.oddId]
          }
        };
      })
    };

    const oddViews =
      this._ticket.odds
        ?.filter(odd => odd.eventStatus === 'active')
        .map(odd => 'ES_' + odd.eventId) || [];

    if (
      oddViews.length > 0 &&
      this._signalrService.currentViews[currentSignalRScope]?.join() !==
        oddViews.join()
    ) {
      this._signalrService.changeViews(oddViews, currentSignalRScope);
    }

    if (ticket.type === 'JT' && ticket.odds?.length > 1) {
      this._sortOdds();
    }
  }

  @Input() style: 'regular' | 'buyback';
  public oddsCombiExpanded = false;

  constructor(
    private _router: Router,
    private _signalrService: SignalrService,
    private _cookieService: CookieService,
    private _presentMessage: PresentMessageService,
    private _translate: TranslateService
  ) {}

  /**
   * After click on Teams fot to detail of event
   */
  showEventDetail(odd: OddWithExtras): void {
    let eventUrl: Extract<
      keyof NavigationConfig,
      'EventDetail' | 'EventDetail_Results' | 'EventDetail_Live'
    >;
    // Virtualne hry
    if (this.style === 'buyback') return;
    if (odd.extras.isVirtual) return;
    if (!odd.eventResultsType) return;
    // show result detail if event is over
    // event cannot be older than 29 days
    // if event is win&&lost&&cancel but is still live, show event detail not event result detail
    if (
      odd.eventResultsType === 'results' &&
      new Date(odd.date).isBefore(new Date().addDays(-29))
    ) {
      return;
    }

    if (odd.eventResultsType === 'results') {
      eventUrl = 'EventDetail_Results';
    } else if (odd.eventResultsType === 'offer') {
      eventUrl = odd.eventLiveActivity ? 'EventDetail_Live' : 'EventDetail';
    }
    this._router.navigate([
      translateRoute(eventUrl, {
        sportID: [odd.sportId, slugify(odd.sportName)],
        regionID: [odd.regionId, slugify(odd.regionName)],
        leagueID: [odd.leagueCupId, slugify(odd.leagueCupName)],
        eventID: odd.eventId,
        eventName: slugify(`${odd.team1} vs ${odd.team2}`)
      })
    ]);
  }

  /**
   * Subscribe to signalr changes to get info if the event is live
   */
  ngOnInit(): void {
    this._subscription$ = new Subscription();
    this._subscription$.add(
      this._signalrService.eventChangesJson.subscribe(changes => {
        changes.forEach(change => {
          const odd = this._ticket.odds.find(
            _odd => _odd.eventId === change?.EventID
          );

          if (!!odd) {
            const parsedScore = parseScore(change?.Score, Number(odd.sportId));
            const isLive = !!parsedScore;
            const isExpanded =
              odd.eventLiveActivity && odd.extras.isScoreExpanded === false
                ? false
                : isLive;
            odd.actualGamePartId = change?.ActualGamePartID;
            odd.date = change?.EventDate;
            odd.eventLiveActivity = isLive;
            odd.scoreNew = change?.Score;
            odd.extras = {
              ...odd.extras,
              actualGamePartTime: change?.ActualGamePartTime,
              isScoreExpanded: isExpanded,
              isSignalrConnected: true,
              parsedScore
            };
          }
        });
      })
    );
  }

  /** change ordering odds */
  orderBy(type): void {
    if (type === 'time') {
      if (!this._orderingCache.includes(this._ticket.ticketCode)) {
        this._orderingCache = [
          ...new Set([...this._orderingCache, this._ticket.ticketCode])
        ];
        this._cookieService.set(ORDER_KEY, JSON.stringify(this._orderingCache));
      }
    } else if (type === 'default') {
      if (this._orderingCache.includes(this._ticket.ticketCode)) {
        this._orderingCache = this._orderingCache.filter(
          code => code !== this._ticket.ticketCode
        );
        this._cookieService.set(ORDER_KEY, JSON.stringify(this._orderingCache));
      }
    }
    this._sortOdds();
  }

  /**
   * clear all subscriptions
   */
  ngOnDestroy(): void {
    this._subscription$?.unsubscribe();
    this._signalrService.changeViews(
      [],
      TICKET_HISTORY_DETAIL_SIGNALR_SCOPE + this._ticket.ticketCode
    );
  }

  /**
   * show tooltip message
   */
  //TODO: zjednodusit tvorenie hlasky + v ponuke
  showTooltip(event: Odd): void {
    if (
      event.eventStatus !== 'ended' &&
      event.eventInfo.comment &&
      event.eventInfo.aggregateScore &&
      event.eventInfo.seriesResult
    ) {
      this._presentMessage.presentCustomMessage(
        event.eventInfo.comment +
          ', ' +
          this._translate.instant('agregate_score') +
          ':' +
          event.eventInfo.aggregateScore +
          ', ' +
          this._translate.instant('series_result') +
          ':' +
          event.eventInfo.seriesResult,
        'info-alert'
      );
    } else if (
      event.eventStatus !== 'ended' &&
      event.eventInfo.aggregateScore &&
      event.eventInfo.seriesResult
    ) {
      this._presentMessage.presentCustomMessage(
        this._translate.instant('agregate_score') +
          ':' +
          event.eventInfo.aggregateScore +
          ' , ' +
          this._translate.instant('series_result') +
          ':' +
          event.eventInfo.seriesResult,
        'info-alert'
      );
    } else if (
      event.eventStatus !== 'ended' &&
      event.eventInfo.comment &&
      event.eventInfo.aggregateScore
    ) {
      this._presentMessage.presentCustomMessage(
        event.eventInfo.comment +
          ', ' +
          this._translate.instant('agregate_score') +
          ':' +
          event.eventInfo.aggregateScore,
        'info-alert'
      );
    } else if (event.eventStatus !== 'ended' && event.eventInfo.comment) {
      this._presentMessage.presentCustomMessage(
        event.eventInfo.comment,
        'info-alert'
      );
    } else if (
      event.eventStatus !== 'ended' &&
      event.eventInfo.aggregateScore
    ) {
      this._presentMessage.presentCustomMessage(
        this._translate.instant('agregate_score') +
          ':' +
          event.eventInfo.aggregateScore,
        'info-alert'
      );
    } else if (event.eventStatus !== 'ended' && event.eventInfo.seriesResult) {
      this._presentMessage.presentCustomMessage(
        this._translate.instant('series_result') +
          ':' +
          event.eventInfo.seriesResult,
        'info-alert'
      );
    } else if (event.eventStatus === 'ended' && event.eventInfo.resultComment) {
      this._presentMessage.presentCustomMessage(
        event.eventInfo.resultComment,
        'info-alert'
      );
    }
  }

  private _sortOdds() {
    this.order = this._orderingCache.includes(this._ticket.ticketCode)
      ? 'time'
      : 'default';
    const sortByTag = (a, b) => a.orderTag - b.orderTag;
    const sortByTime = (a, b) => +new Date(a.date) - +new Date(b.date);
    this._ticket.odds = this._ticket.odds.sort(
      this.order === 'time' ? sortByTime : sortByTag
    );
  }
}
