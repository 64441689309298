import { createAction, props } from '@ngrx/store';
import {
  SignalrEventChanceTypeChangePayload,
  SignalrEventChangeJsonPayload,
  SignalrOddChangePayload
} from 'src/app/services/signalr.service';
import { Event } from '@models/Event';
import { Label } from '@models/Label';
import { FetchEventsOptions } from '@core/services/types';
import { Sport } from '@models/Sport';
import { parseScore } from '@core/functions/parseScore';
import { OfferResponse } from '@core/services/offer/offerResponse';
import { Region } from '@models/Region';

/**
 * LOADING WHOLE MENU
 */
export const loadWholeMenuStart = createAction(
  '[Offer] Loading whole menu start',
  props<{
    options: {
      date: string;
      sportID?: Sport['ID'];
      live: boolean;
      sportEventID?: Sport['ID'];
    };
    section: string;
  }>()
);

export const loadWholeMenuSuccess = createAction(
  '[Offer] Loading whole menu success',
  props<{ menu: Label[] }>()
);

export const loadWholeMenuFailure = createAction(
  '[Offer] Loading whole menu failure',
  props<{ error: any }>()
);

export const updateOffer = createAction(
  '[Offer] Update offer',
  props<
    OfferResponse & {
      date: string;
      delta?: 'sports' | 'leagues' | 'events';
      section: string;
    }
  >()
);

/** Load sports menu */
export const loadSportsMenuStart = createAction(
  '[Offer] Load sports menu start',
  props<{
    options: {
      date: string;
      live: boolean;
    };
    section: string;
  }>()
);
export const loadSportsMenuSuccess = createAction(
  '[Offer] Load sports menu success',
  props<{ data: any }>()
);
export const loadSportsMenuFailure = createAction(
  '[Offer] Load sports menu failure',
  props<{ error: any }>()
);

export const refreshViewSuccess = createAction('[Offer] Refresh View Success');

/**
 * LOADING EVENTS
 */
export const loadEventsStart = createAction(
  '[Offer] Loading events start',
  props<{ options: FetchEventsOptions; section: string }>()
);

export const loadEventsSuccess = createAction('[Offer] Loading events success');

export const loadEventsFail = createAction(
  '[Offer] Loading events fail',
  props<{ payload: any }>()
);

/**
 * UPDATE ODDS
 */
export const updateOdds = createAction(
  '[Offer] Update Odds',
  (changes: SignalrOddChangePayload[]) => ({
    payload: changes.map(change => ({
      id: change.oddID,
      changes: {
        OddsRate: change.oddRate,
        Status: change.oddStatus
      }
    }))
  })
);

export const updateEvents = createAction(
  '[Offer] Update Events',
  (
    changes: Partial<
      SignalrEventChangeJsonPayload & SignalrEventChanceTypeChangePayload
    >[]
  ) => ({
    payload: changes.map<{
      id: number;
      changes: Partial<Event>;
    }>(change => {
      const payload = {
        id: change.EventID,
        changes: {
          EventID: change.EventID,
          ActualGamePartID: change.ActualGamePartID,
          ActualGamePartTime: change.ActualGamePartTime,
          LiveBettingView: change.LiveBettingView,
          EventDate: change.EventDate,
          Score: change.Score,
          EventChanceTypeStatus: change.EventChanceTypeStatus
        }
      };
      Object.keys(payload.changes).forEach(key =>
        payload.changes[key] === undefined ? delete payload.changes[key] : {}
      );
      return payload;
    })
  })
);

export const updateStreamScheduleEvents = createAction(
  '[Offer] Update Stream Schedule Events',
  (changes: Partial<Event>[]) => ({
    payload: changes.map(change => ({
      id: change.EventID,
      changes: {
        ...change,
        ParsedScore: parseScore(change.Score, change.SportID)
      }
    }))
  })
);

/** STREAM SCHEDULE */
export const loadStreamSchedule = createAction('[Offer] Load Stream Schedule');
export const loadStreamScheduleSuccess = createAction(
  '[Offer] Load Stream Schedule Success'
);
export const loadStreamScheudleFailure = createAction(
  '[Offer] Load Stream Schedule Failure',
  props<{ error: any }>()
);

/**
 * LOADING WHOLE MENU
 */
export const loadLeagueCupMenuStart = createAction(
  '[Offer] Loading league cup menu start',
  props<{
    date: string;
    section: string;
    sportID?: Sport['ID'];
    regionID?: Region['ID'];
    sportEventID?: number;
    live?: boolean;
  }>()
);

export const loadLeagueCupMenuSuccess = createAction(
  '[Offer] Loading league cup menu success'
);
