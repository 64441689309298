import { TicketHistroyDetail } from '@models/TicketHistory';

const compare = (
  a: TicketHistroyDetail['odds'][number],
  b: TicketHistroyDetail['odds'][number]
): number => {
  if (a.group < b.group) {
    return -1;
  }
  if (a.group > b.group) {
    return 1;
  }
  return 0;
};

/**
 * Nastavi group label oddov v kombinacnom tikete
 * @param odds pole oddov
 */
export function getCombinationGroups(odds: TicketHistroyDetail['odds']): Record<
  string,
  {
    GroupValue: '.' | 'X' | '1' | string;
    drowline2: boolean;
  }
> {
  if (!odds) {
    return;
  }
  const groups: Record<
    string,
    {
      GroupValue: '.' | 'X' | '1' | string;
      drowline2: boolean;
    }
  > = odds.reduce((acc, odd) => ({ ...acc, [odd.oddId]: {} }), {});

  odds.sort(compare);

  // odds =  odds.sort((a, b) => (a.Group > b.Group) ? 1 : -1)

  // nastavenie nulteho oddu
  groups[odds[0].oddId] = {
    drowline2: false,
    GroupValue: odds[0].group
  };

  for (let i = 1; i < odds.length; i++) {
    const prevOdd = odds[i - 1];
    const odd = odds[i];
    const nextOdd = odds[i + 1];

    groups[odd.oddId].drowline2 = true;

    // osetrenie posledneho oddu a konca skupiny
    if (nextOdd === undefined || odd.group !== nextOdd.group) {
      // ak je predchadzajuci odd === '.' alebo rovnaky ako aktualny mame koniec skupiny
      if (
        groups[prevOdd.oddId].GroupValue === '.' ||
        groups[prevOdd.oddId].GroupValue === odd.group
      ) {
        groups[odd.oddId].GroupValue = 'X';
        groups[odd.oddId].drowline2 = false;
        groups[prevOdd.oddId].drowline2 = true;
        // jednoprvkova skupina
      } else {
        groups[odd.oddId].drowline2 = false;
        groups[odd.oddId].GroupValue = odd.group;
      }

      // koniec pola
      if (nextOdd === undefined) {
        // odd.drowline2 = false;
        break;
      }
    }

    // osetrenie zaciatku a stredu skupiny
    if (odd.group === nextOdd.group) {
      // zaciatok skupiny
      // odd.drowline2 = false;
      if (
        groups[prevOdd.oddId].GroupValue === 'X' ||
        // (prevOdd.Group === '1' &&
        //   prevOdd.GroupValue === '1' &&
        //   odd.Group !== '1') (
        // fix groupvalue vs. group nemoze porovnavat explicitne
        (prevOdd.group === groups[prevOdd.oddId].GroupValue &&
          odd.group !== prevOdd.group)
      ) {
        groups[odd.oddId].GroupValue = odd.group;
        groups[odd.oddId].drowline2 = true;
        // stred skupiny
      } else {
        groups[prevOdd.oddId].drowline2 = true;
        groups[odd.oddId].GroupValue = '.';
      }
    }
  }
  return groups;
}
