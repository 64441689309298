import * as offerSelectors from '../../pages/offer/store/offer.selectors';
import * as authSelectors from '../auth/auth.selectors';
import * as detailSelectors from '../../pages/detail/detail/store/detail.selectors';
import * as landingSelectors from '../../pages/landing/landing/store/landing.selectors';
import * as analysesSelectors from '../../pages/analysis/store/analyses.selectors';
import * as actualitiesSelectors from '../../pages/actuality/store/actualities.selectors';
import * as maintenanceSelectors from '../maintenance/maintenance.selectors';
import * as personalInfoSelectors from '../../pages/profile/store/personalinfo.selectors';
import * as tickethistorySelectors from '../../pages/betslip/tickethistory/store/tickethistory.selectors';
import * as documentSelecotors from '../../pages/document/store/document.selectors';
import * as ticketSelectors from '../../pages/ticket/store/ticket.selectors';
import * as buyBackSelectors from '../buyback/buyback.selectors';
import * as favoritesSelectors from '../favorites/favorites.selectors';
import * as notificationsSelectors from '../notifications/notifications.selectors';

export {
  authSelectors,
  offerSelectors,
  detailSelectors,
  landingSelectors,
  analysesSelectors,
  actualitiesSelectors,
  maintenanceSelectors,
  personalInfoSelectors,
  tickethistorySelectors,
  documentSelecotors,
  ticketSelectors,
  buyBackSelectors,
  favoritesSelectors,
  notificationsSelectors
};

import * as bonusesSelectors from 'src/app/pages/bonuses/store/bonuses.selectors';
import * as casinoSelectors from 'src/app/pages/casino/store/casino.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '@store/app.reducer';

export { bonusesSelectors, casinoSelectors };

const selectAppState = createFeatureSelector<AppState['app']>('app');

export const appSelectors = {
  selectIsAppInitialized: createSelector(
    selectAppState,
    state => state.initialized
  )
};
