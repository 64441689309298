import { Directive, ElementRef, Input } from '@angular/core';

const bonusesDividerColor = '#FFFFFF';

export type BadgeData = {
  showBadge: boolean;
  colors: [];
};

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[badgeDrawer]'
})
export class BadgeDrawerDirective {
  @Input() set badgeDrawer(colors: string[]) {
    if (colors.length) {
      this._badge.nativeElement.style.background = this.generateBadgeBG(colors);
    }
  }

  /**
   * Generates solid/linear gradient background for badge based on colors Array
   * Directive triggers by showBadge, example: *ngIf="tab.badge?.showBadge"
   */
  generateBadgeBG(colors: string[] = []): string {
    const uniqueColors = [...new Set(colors)];
    if (uniqueColors.length === 1) {
      return uniqueColors[0];
    } else {
      return `linear-gradient(135deg,\
   ${uniqueColors[0]},\
   ${uniqueColors[0]} 45%,\
   ${bonusesDividerColor} 45%,\
   ${bonusesDividerColor} 55%,\
   ${uniqueColors[1]} 55%,\
   ${uniqueColors[1]})`;
    }
  }

  constructor(private _badge: ElementRef) {}
}
