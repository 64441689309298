import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { RouterModule } from '@angular/router';
import { FooterMenuComponent } from './footer-menu/footer-menu.component';
import { MainHeaderModule } from './main-header/main-header.module';
import { ToolbarListModule } from './toolbar-list/toolbar-list.module';
import { ListItemHeaderComponent } from './list-item-header/list-item-header.component';
import { LoaderComponent } from './loader/loader.component';
import { ProgressiveLoadingContainerComponent } from './progressive-loading-container/progressive-loading-container.component';
import { TicketListItemComponent } from './ticket-list-item/ticket-list-item.component';
import { FiltermodalModule } from './filtermodal/filtermodal.module';
import { TabGroupModule } from './tab-group/tab-group.module';
import { BannerGroupModule } from './banner-group/banner-group.module';
import { CasinoGameCardComponent } from './casino-game-card/casino-game-card.component';
import { SafePipeModule } from 'safe-pipe';
import { FormsModule } from '@angular/forms';
import { TooltipComponent } from './tooltip/tooltip.component';
import { LoadingComponent } from './loading/loading.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { AnalysisDetailComponent } from './analysis-detail/analysis-detail.component';
import { ImagePickerComponent } from '@shared/components/image-picker/image-picker.component';
import { FooterComponent } from './footer/footer.component';
import { BoxInputModule } from './box-input/box-input.module';
import { TicketeventsModule } from './ticketevents/ticketevents.module';
import { EventScoreTableModule } from './event-score-table/event-score-table.module';
import { WalletBalanceComponent } from './wallet-balance/wallet-balance.component';
import { DoxxDirectivesModule } from '@shared/directives/doxx-directives.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { NewNoticeModule } from './new-notice/new-notice.module';
import { NoticeComponent } from './notice/notice.component';
import { TranslateRoutePipe } from '@shared/pipes/translate-route.pipe';
import { BarcodeModule } from './barcode/barcode.module';
import { InViewportModule } from '@shared/directives/in-viewport/in-viewport.module';
import { PopupsModule } from './popups/popups.module';
import { IsOddLockedPipe } from '@shared/pipes/is-odd-locked.pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RegisterLogInModalComponent } from './register-log-in-modal/register-log-in-modal.component';
import { SportboxTicketVerifyModule } from './sportbox-ticket-verify/sportbox-ticket-verify.module';
import { TicketdetailModule } from './ticketdetail/ticketdetail.module';
import { EventListItemModule } from './event-list-item/event-list-item.module';
import { OddModule } from './odd/odd.module';
import { QuickbetBannerComponent } from './quickbet-banner/quickbet-banner.component';

const components = [
  FooterMenuComponent,
  ListItemHeaderComponent,
  LoaderComponent,
  ProgressiveLoadingContainerComponent,
  TicketListItemComponent,
  NoticeComponent,
  CasinoGameCardComponent,
  TooltipComponent,
  LoadingComponent,
  AnalysisComponent,
  AnalysisDetailComponent,
  ImagePickerComponent,
  FooterComponent,
  WalletBalanceComponent,
  RegisterLogInModalComponent,
  QuickbetBannerComponent
];
@NgModule({
  declarations: [...components],
  imports: [
    RouterModule,
    CommonModule,
    IonicModule,
    DoxxPipesModule,
    MainHeaderModule,
    ToolbarListModule,
    FiltermodalModule,
    TabGroupModule,
    BannerGroupModule,
    SafePipeModule,
    FormsModule,
    BoxInputModule,
    TicketeventsModule,
    EventScoreTableModule,
    DoxxDirectivesModule,
    UtilsModule,
    NewNoticeModule,
    InViewportModule,
    BarcodeModule,
    PopupsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    SportboxTicketVerifyModule,
    TicketdetailModule,
    EventListItemModule,
    OddModule
  ],
  exports: [
    ...components,
    ToolbarListModule,
    MainHeaderModule,
    TabGroupModule,
    BannerGroupModule,
    BoxInputModule,
    TicketeventsModule,
    EventScoreTableModule,
    NewNoticeModule,
    BarcodeModule,
    OddModule,
    EventListItemModule
  ],
  providers: [TranslateRoutePipe, IsOddLockedPipe]
})
export class DoxxComponentsModule {}
