import { createReducer, on } from '@ngrx/store';
import { landingActions } from '@store/actions';
import { League } from '@models/League';

export interface State {
  loading: boolean;
  leagues: League[];
  error: any;
}

const initialTopLeaguesState = {
  loading: false,
  leagues: [],
  error: undefined
};
export const topLeaguesReducer = createReducer(
  initialTopLeaguesState,
  on(landingActions.loadTopLeagues, (state, action) => ({
    ...state,
    loading: true,
    error: undefined
  })),
  on(landingActions.loadTopLeaguesSuccess, (state, action) => ({
    ...state,
    loading: false,
    leagues: action.leagues
  })),
  on(landingActions.loadTopLeaguesFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.error
  }))
);
