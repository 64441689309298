import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPersonalInfo from './personalinfo.reducer';

export const selectPersonalInfoState =
  createFeatureSelector<fromPersonalInfo.State>(
    fromPersonalInfo.personalInfoFeatureKey
  );

export const selectPersonalInfoClient = createSelector(
  selectPersonalInfoState,
  state => state.client
);

export const selectPersonalInfoForm = createSelector(
  selectPersonalInfoState,
  state => state.form
);
