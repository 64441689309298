<div
  id="ticket-bubble"
  *ngIf="ticket$ | async as ticket"
  [ngClass]="{
    virtual: ticket.TicketFlag === 'TVG1',
    editing: ticket.IsEdited,
    submited: showPlaceBetAnimation
  }"
>
  <ion-menu-button
    menuClose
    menu="ticketMenu"
    style="--border-radius: 0px"
    *ngIf="!showPlaceBetAnimation"
  >
    <div class="ticket-arrow"></div>
    <div class="ticket-bubble-info">
      <div class="ticket-bubble-bets">
        <div class="ticket-rate">
          <div class="roww">{{ 'buble_rate' | translate }}</div>
          <div class="roww">{{ ticket.OverallRate | rate }}</div>
        </div>
        <div class="ticket-poswin">
          <div class="roww">
            <span class="label-long">{{ 'buble_poswin' | translate }}</span>
            <span class="label-short">
              {{ 'ticket_eventual_win_short' | translate }}
            </span>
          </div>

          <div class="roww">
            {{ ticket.FiscalOverallPotWin | formatNumberWithCurrency : { decimalDigits: configuration.base.defaultCurrency.numberDecimalPlaces} }}
          </div>
        </div>
      </div>
      <div class="ticket-bubble-count">
        <span>{{ ticket.Odds?.length }}</span>
      </div>
    </div>
    <div class="ticket-circle">
      <i *ngIf="!ticket.IsEdited" class="icon-ic_betslip"></i>
      <i *ngIf="ticket.IsEdited" class="icon-ic_edit-ticket-stroke"></i>
    </div>
  </ion-menu-button>

  <div
    class="ticket-submited"
    style="--border-radius: 0px"
    *ngIf="showPlaceBetAnimation"
    (click)="showLastTicket()"
  >
    <div class="ticket-arrow"></div>
    <div class="ticket-bubble-info">
      <div class="place-bet-message">
        {{ placeBetMessage }}
      </div>
    </div>
    <div class="ticket-circle">
      <div
        class="svg-anim"
        [style]="
          'background-image: url(./../../assets/placebet-animation.svg?' +
          newImage +
          ')'
        "
      ></div>
    </div>
  </div>
</div>
