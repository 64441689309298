/**
 * Trims all whitespaces from given template string
 */
export function oneLine(strings, ...values): string {
  // Interweave the strings with the
  // substitution vars first.
  let output = '';
  for (let i = 0; i < values.length; i++) {
    output += strings[i] + values[i];
  }
  output += strings[values.length];

  // Split on newlines.
  const lines = output.split(/(?:\r\n|\n|\r)/);

  // Rip out the leading whitespace.
  output = lines
    .map(line => {
      return line.replace(/^\s+/gm, '');
    })
    .join('')
    .trim();
  return output;
}
