import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { timer } from 'rxjs';

import { maintenanceActions } from '@store/actions';
import { MaintenanceService } from '@core/services/maintenance/maintenance.service';
import { Store } from '@ngrx/store';
import { maintenanceSelectors } from '@store/selectors';

@Injectable()
export class MaintenanceEffects {
  // First load of maintenance state is loaded in APP_INITIALIZER in app module
  // Load refresh maintanence info after one minute, then every minute
  refresh$ = createEffect(() => {
    return timer(60 * 1000, 60 * 1000).pipe(
      withLatestFrom(
        this._store.select(maintenanceSelectors.selectMaintenanceState)
      ),
      map(([_, { isAppBlocked }]) => isAppBlocked),
      filter(isAppBlocked => !isAppBlocked),
      map(() => maintenanceActions.loadMaintenance())
    );
  });

  loadManitenance$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(maintenanceActions.loadMaintenance),
      switchMap(() =>
        this._maintenanceService
          .getBlockingInformation()
          .pipe(
            map(maintenance =>
              maintenanceActions.loadMaintenanceSuccess({ maintenance })
            )
          )
      )
    );
  });

  constructor(
    private _actions$: Actions,
    private _maintenanceService: MaintenanceService,
    private _store: Store
  ) {}
}
