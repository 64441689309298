import { Region } from '@models/Region';
import { Sport } from '@models/Sport';
import { OfferResponse } from './offerResponse';

/** transforms response from league cup to unified response */
export function transformLeagueCupMenuResponse(data): OfferResponse {
  const tree: OfferResponse['tree'] = {};
  const leagues: OfferResponse['leagues'] = {};

  let sport: Sport;
  let region: Region;

  data.forEach(label => {
    if (label.Typ === 'SP') {
      sport = label;
    } else if (label.Typ === 'RE') {
      region = label;
    } else if (label.Typ === 'LC') {
      leagues[label.ID] = label;
    }
  });

  tree[sport.ID] = {
    typ: 'SP',
    key: `SP_${sport.ID}`,
    order: 1,
    sportID: sport.ID,
    children: {
      [region.ID]: {
        typ: 'RE',
        key: `RE_${region.ID}`,
        regionID: region.ID,
        order: 1,
        children: {}
      }
    }
  };

  Object.values(leagues).forEach(league => {
    tree[sport.ID].children[region.ID].children[league.ID] = {
      typ: 'LC',
      key: `LC_${league.ID}`,
      eventCount: league.EventCount,
      hasStatistics: league.HasStatistics,
      sportID: sport.ID,
      leagueID: league.ID,
      countryID: region.CountryID,
      seasonID: league.CurrentSeasonID,
      order: league.Order
    };
  });

  return {
    sports: {},
    regions: {},
    phases: {},
    leagues,
    events: {},
    odds: {},
    tree,
    stream: {}
  };
}
