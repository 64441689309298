import {
  Component,
  Input,
  HostListener,
  Output,
  HostBinding,
  ElementRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { openCloseArrowAnimation } from 'src/app/animations';

@Component({
  selector: 'doxx-list-item-header',
  templateUrl: './list-item-header.component.html',
  styleUrls: ['./list-item-header.component.scss'],
  animations: [openCloseArrowAnimation]
})
export class ListItemHeaderComponent {
  private _isExpanded = false;
  @Input() set expanded(expanded: boolean) {
    this._isExpanded = expanded;
  }
  get expanded(): boolean {
    return this._isExpanded;
  }

  @HostBinding('class') get klass(): string {
    return this._isExpanded ? 'expanded' : 'collapsed';
  }

  @Output() opened = new Subject<any>();

  @HostListener('click') private _clicked(): void {
    setTimeout(() => {
      if (this._isExpanded) {
        this.opened.next();
      }
    }, 0);
  }

  constructor(public el: ElementRef) {}
}
