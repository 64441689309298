import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromApp from '@store/app.reducer';
import * as authSelectors from '@store/auth/auth.selectors';
import * as authActions from '@store/auth/auth.actions';
import { Observable, Subscription } from 'rxjs';
import { coerceBooleanProperty } from '@core/functions/coerceBooleanProperty';
import { User } from '@models/User';
import { configuration } from '@configuration';
import { NotificationsService } from '@shared/components/notifications/notifications.service';
import { notificationsSelectors } from '@store/selectors';

@Component({
  selector: 'doxx-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  private _subscription$ = new Subscription();
  user: User;
  unreadNotificationsCount = 0;

  // This flag is used to recognize if toolbar is in search mode
  // and shows searchbar with resulsts
  showSearchBar = false;
  config;

  private _transparent: boolean;
  @Input()
  set transparent(value: any) {
    this._transparent = coerceBooleanProperty(value);
  }
  get isTransparent(): boolean {
    return this._transparent;
  }
  @Input() showRegisterButton = false;
  @Input() showLoginButton = true;
  @Input() showSearchButton: boolean = null;
  @Input() showBalance = true;
  @Input() showTicketHistoryButton = false;
  @Input() showNotificationsButton = false;

  constructor(
    private _store: Store<fromApp.AppState>,
    private _el: ElementRef,
    private _notificationsService: NotificationsService
  ) {}

  /**
   * Select flag from store if user is logged in
   */
  ngOnInit(): void {
    this.config = configuration;
    
    if (this.showSearchButton === null) {
      this.showSearchButton = configuration.search.allowed;
    }

    if (configuration.search.allowed === false) {
      this.showSearchButton = false;
    }

    if (configuration.notifications.allowed === false) {
      this.showNotificationsButton = false;
    }

    this._subscription$.add(
      this._store
        .select(authSelectors.selectLoggedUser)
        .subscribe(user => (this.user = user))
    );

    if (this.showNotificationsButton) {
      this._subscription$.add(
        this._store
          .select(notificationsSelectors.selectUnreadNotificationsCount)
          .subscribe(count => (this.unreadNotificationsCount = count))
      );
    }
  }

  /** Clear all subscriptions */
  ngOnDestroy(): void {
    this._subscription$.unsubscribe();
  }

  /**
   * Handle logout
   */
  onLogout(): void {
    this._store.dispatch(authActions.logoutStart());
  }

  /** opens notifications botton sheet */
  showNotifications(): void {
    this._notificationsService.showNotifications();
  }
}
