import { createSelector } from '@ngrx/store';

import * as fromApp from '../app.reducer';
const selectMaintenance = (state: fromApp.AppState) => state.maintenance;

export const selectMaintenanceState = createSelector(
  selectMaintenance,
  state => state
);

export const selectIsBettingBlocked = createSelector(
  selectMaintenanceState,
  state => state.isAppBlocked || state.maintenance?.IsBettingBlocked
);
export const selectIsCasinoBlocked = createSelector(
  selectMaintenanceState,
  state => state.isAppBlocked || state.maintenance?.IsCasinoBlocked
);
export const selectIsTotalBlocked = createSelector(
  selectMaintenanceState,
  state =>
    state.isAppBlocked ||
    (state.maintenance?.IsCasinoBlocked && state.maintenance?.IsBettingBlocked)
);
export const selectIsDepositBlocked = createSelector(
  selectMaintenanceState,
  state => state.isAppBlocked || state.maintenance?.IsDepositBlocked
);
export const selectIsWithdrawalBlocked = createSelector(
  selectMaintenanceState,
  state => state.isAppBlocked || state.maintenance?.IsWithdrawalBlocked
);
export const selectIsWalletBlocked = createSelector(
  selectMaintenanceState,
  state =>
    state.isAppBlocked ||
    (state.maintenance?.IsDepositBlocked &&
      state.maintenance?.IsWithdrawalBlocked)
);
