import { Actions, ofType, createEffect, act } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, mergeMap, catchError } from 'rxjs/operators';
import { OfferService } from '@core/services/offer/offer.service';
import { ResultsService } from '@core/services/results/results.service';
import { of } from 'rxjs';
import { offerActions } from '@store/actions';

@Injectable()
export class OfferEffects {
  /**
   * LOAD WHOLE MENU
   * This effect whole menu from backend for current section
   */
  loadWholeMenu = createEffect(() =>
    this._actions$.pipe(
      ofType(offerActions.loadWholeMenuStart),
      switchMap(action => {
        return (
          action.section === 'results'
            ? this._resultsService.fetchWholeMenu({
                date: action.options.date,
                sportId: action.options.sportID,
                sportEvent: action.options.sportEventID
              })
            : this._offerService.fetchWholeMenu({
                date: action.options.date,
                live: action.options.live,
                sportId: action.options.sportID,
                sportEvent: action.options.sportEventID
              })
        ).pipe(
          switchMap(data => [
            offerActions.updateOffer({
              ...data,
              date: action.options.date,
              section: action.section
            }),
            offerActions.loadWholeMenuSuccess({ menu: [] })
          ]),
          catchError(error => of(offerActions.loadWholeMenuFailure({ error })))
        );
      })
    )
  );

  /**
   * LOAD LEAGUE CUP MENU
   * This effect whole menu from backend for current section
   */
  loadLeagueCupMenu = createEffect(() =>
    this._actions$.pipe(
      ofType(offerActions.loadLeagueCupMenuStart),
      switchMap(action => {
        return (
          action.section === 'results'
            ? this._resultsService.fetchLeagueCupMenu({
                date: action.date,
                sportID: action.sportID,
                regionID: action.regionID,
                sportEventID: action.sportEventID
              })
            : this._offerService.fetchLeagueCupMenu({
                date: action.date,
                sportID: action.sportID,
                regionID: action.regionID,
                sportEventID: action.sportEventID,
                live: action.section === 'live'
              })
        ).pipe(
          switchMap(data => [
            offerActions.updateOffer({
              ...data,
              date: action.date,
              section: action.section
            }),
            offerActions.loadLeagueCupMenuSuccess()
          ])
        );
      })
    )
  );

  /**
   * LOAD EVENTS
   * This effect takes filter state from store for current section
   * and fetch sport events for this filter from backend
   */
  offerLoadEvents = createEffect(() =>
    this._actions$.pipe(
      ofType(offerActions.loadEventsStart),
      mergeMap(action =>
        action.section === 'results'
          ? this._resultsService.fetchEvents(action.options).pipe(
              switchMap(data => [
                offerActions.updateOffer({
                  ...data,
                  date: action.options.date,
                  section: action.section,
                  delta: 'events'
                }),
                offerActions.loadEventsSuccess()
              ])
            )
          : this._offerService.fetchEvents(action.options).pipe(
              switchMap(data => [
                offerActions.updateOffer({
                  ...data,
                  date: action.options.date,
                  section: action.section,
                  delta: 'events'
                }),
                offerActions.loadEventsSuccess()
              ])
            )
      )
    )
  );

  loadStreamSchedule$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(offerActions.loadStreamSchedule),
      mergeMap(() =>
        this._offerService.fetchStreamList().pipe(
          switchMap(list => [
            offerActions.updateOffer({ ...list, date: '-1', section: 'live' }),
            offerActions.loadStreamScheduleSuccess()
          ]),
          catchError(error =>
            of(offerActions.loadStreamScheudleFailure({ error }))
          )
        )
      )
    );
  });

  loadSportsMenu$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(offerActions.loadSportsMenuStart),
      switchMap(action =>
        (action.section === 'results'
          ? this._resultsService.fetchMainMenu({
              date: action.options.date
            })
          : this._offerService.fetchMainMenu({
              date: action.options.date,
              live: action.options.live
            })
        ).pipe(
          switchMap(data => [
            offerActions.updateOffer({
              ...data,
              date: action.options.date,
              delta: 'sports',
              section: action.section
            }),
            offerActions.loadSportsMenuSuccess({ data })
          ]),
          catchError(error => of(offerActions.loadSportsMenuFailure({ error })))
        )
      )
    );
  });

  constructor(
    private _actions$: Actions,
    private _offerService: OfferService,
    private _resultsService: ResultsService
  ) {}
}
