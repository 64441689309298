import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@shared/components/loader/loader.service';
import { filter, first, tap } from 'rxjs/operators';
import { PresentMessageService } from 'src/app/services/present-message.service';
import { Store } from '@ngrx/store';
import { TickethistoryService } from 'src/app/pages/betslip/tickethistory/tickethistory.service';
import {
  TicketHistoryListItem,
  TicketHistroyDetail
} from '@models/TicketHistory';
import { authActions, buyBackActions } from '@store/actions';
import { buyBackSelectors } from '@store/selectors';
import { race, Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { configuration } from '@configuration';

@Component({
  templateUrl: './buyback-modal.component.html',
  styleUrls: ['./buyback-modal.component.scss']
})
export class BuybackModalComponent implements OnInit, OnDestroy {
  private _subscriptions$: Subscription;
  acceptChanges = true;
  isBuybackActive: boolean;
  constructor(
    private _translateService: TranslateService,
    private _presentMessageService: PresentMessageService,
    private _modalCtrl: ModalController,
    private _ticketHistoryService: TickethistoryService,
    private _loaderService: LoaderService,
    private _router: Router,
    private _store: Store,
    private _actions$: Actions
  ) {}

  @Input() ticketCode: TicketHistoryListItem['ticketCode'];
  buyBackValue$;
  ticket: TicketHistroyDetail;
  configuration = configuration;

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.buyBackValue$ = this._store
      .select(
        buyBackSelectors.selectBuybackStateValueByTicketCode(this.ticketCode)
      )
      .pipe(
        tap(ticket => {
          if (ticket.BuyBackValue === -1) {
            this.closeBuybackModal(false);
          }
        })
      );

    this._ticketHistoryService
      .getTicketDetailNEW(this.ticketCode)
      .subscribe(ticket => (this.ticket = ticket));
    this._subscriptions$ = new Subscription();

    this._subscriptions$.add(
      this.buyBackValue$.subscribe(data => {
        if (data.BuyBackValue === -1) {
          this.closeBuybackModal(false);
        }
      })
    );

    this._subscriptions$.add(
      this._router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          first()
        )
        .subscribe(e => {
          this._modalCtrl.getTop().then(modal => {
            if (modal) {
              modal.dismiss();
            }
          });
        })
    );
  }

  /**  Clear subscriptions */
  ngOnDestroy(): void {
    this._subscriptions$?.unsubscribe();
  }

  /**
   * znovu nacita buybackvalue a buybacktime
   */
  reloadBuybackValue(): Promise<any> {
    return new Promise((resolve, reject) => {
      race(
        this._actions$.pipe(
          ofType(buyBackActions.loadBuybackValuesSuccess),
          tap(action => resolve(action.buybackValues))
        ),
        this._actions$.pipe(
          ofType(buyBackActions.loadBuybackValuesSuccess),
          tap(() => reject())
        )
      )
        .pipe(first())
        .subscribe();
      this._store.dispatch(
        buyBackActions.loadBuybackValues({ ticketCode: this.ticketCode })
      );
    });
  }

  /**
   * closes modal
   */
  closeBuybackModal(success: boolean): void {
    this._modalCtrl.dismiss(success);
  }

  /**
   * uskutocni odkupenie tiketu
   */
  executeBuyback(buybackValue): void {
    this._loaderService.presentXXLoader();
    this.isBuybackActive = true;

    race(
      this._actions$.pipe(
        ofType(buyBackActions.executeBuybackSuccess),
        tap(() => {
          this._store.dispatch(authActions.balanceRefreshStart());
          this._presentMessageService.presentCustomMessage(
            this._translateService.instant('ticket_buyback_buybacksuccessful'),
            'success-alert'
          );
          this.closeBuybackModal(true);
        })
      ),
      this._actions$.pipe(
        ofType(buyBackActions.executeBuybackFailure),
        tap(({ error }) => {
          const showErrors = () => {
            if (
              (error.ErrorMessage && error.BuyBackValue > 0) ||
              error.ErrorMessage === 'BB_ERR_VALUE'
            ) {
              this._presentMessageService.presentCustomMessage(
                this._translateService.instant('ticket_buyback_valuechanged'),
                'error-alert'
              );
            } else {
              this._presentMessageService.presentCustomMessage(
                this._translateService.instant('ticket_buyback_buybackfailed'),
                'error-alert'
              );
            }
          };
          this.reloadBuybackValue()
            .then(({ BuyBackValue }) => {
              // if buybackValue <= do not show any errors, modal changes its wiew itself
              if (BuyBackValue > 0) {
                showErrors();
              }
            })
            .catch(() => {
              showErrors();
            });
        })
      )
    )
      .pipe(first())
      .subscribe(() => {
        this.isBuybackActive = false;
        this._loaderService.dismissXXLoader();
      });

    this._store.dispatch(
      buyBackActions.executeBuyback({
        ticketCode: this.ticketCode,
        buybackValue: this.acceptChanges === true ? 0 : buybackValue
      })
    );
  }
}
