import { Notification } from '@models/Notifications';
import { createAction, props } from '@ngrx/store';

/** UNREAD COUNT */
export const getUnreadNotificationsCount = createAction(
  '[Notifications] Get Unread Notifications Count'
);

export const getUnreadNotificationsCountSuccess = createAction(
  '[Notifications] Get Unread Notifications Count Success',
  props<{ count: number }>()
);

export const getUnreadNotificationsCountFailure = createAction(
  '[Notifications] Get Unread Notifications Count Failure',
  props<{ error: any }>()
);

export const incrementUnreadNotificationsCount = createAction(
  '[Notifications] Increment Unread Notifications Count'
);

/** LIST */
export const getNotificationsList = createAction(
  '[Notifications] Get Notifications List',
  props<{ page: number; pageSize: number }>()
);

export const getFirstNotificationsList = createAction(
  '[Notifications] Get First Notifications List',
  props<{ count: number }>()
);

export const getNextNotificationsList = createAction(
  '[Notifications] Get Next Notifications List'
);

export const getNotificationsListSuccess = createAction(
  '[Notifications] Get Notifications List Success',
  props<{ notifications: Notification[] }>()
);

export const getNotificationsListFailure = createAction(
  '[Notifications] Get Notifications List Failure',
  props<{ error: any }>()
);

export const markNotificationAsRead = createAction(
  '[Notifications] Mark Notification As Read',
  props<{ notificationID: Notification['guid'] }>()
);
