import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { TabGroupComponent } from './tab-group.component';
import { TabComponent } from './tab/tab.component';
import { FormsModule } from '@angular/forms';
import { DoxxDirectivesModule } from '@shared/directives/doxx-directives.module';

@NgModule({
  declarations: [TabGroupComponent, TabComponent],
  imports: [CommonModule, IonicModule, FormsModule, DoxxDirectivesModule],
  exports: [TabGroupComponent, TabComponent]
})
export class TabGroupModule {}
