<div class="relative h-full">
  <div
    *ngIf="data.type"
    class="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full shadow-lg w-14 h-14 flex justify-center items-center bg-white"
  >
    <div [ngSwitch]="data.type">
      <div *ngSwitchCase="'success'">
        <svg class="icon icon-check text-2xl text-green">
          <use xlink:href="#icon-check"></use>
        </svg>
      </div>
      <div *ngSwitchCase="'info'">
        <i class="icon icon-ic_info-circle text-2xl text-blue"></i>
      </div>
      <div *ngSwitchCase="'danger'">
        <i class="icon icon-ic_alert-fill text-2xl text-blue"></i>
      </div>
      <div *ngSwitchDefault>
        <span
          [ngClass]="$any(data).type?.class"
          [innerHTML]="$any(data).type?.html | safe : 'html'"
        ></span>
      </div>
    </div>
  </div>
  <div
    class="rounded-sm overflow-hidden text-primary-300 flex flex-col bg-white"
    [ngClass]="{ 'pt-7': data.type }"
  >
    <div class="text-center p-6">
      <h2 *ngIf="data.heading" class="font-bold mb-3 text-xl text-primary-900">
        {{ $any(data.heading) | async }}
      </h2>
      <ng-container *ngIf="data.text">
        <p
          class="text-primary-300 whitespace-pre-line"
          [innerHTML]="$any(data.text) | async | safe : 'html'"
        ></p>
      </ng-container>
      <ng-container *ngIf="data.template">
        <ng-container *ngTemplateOutlet="data.template"></ng-container>
      </ng-container>
    </div>

    <div class="grid grid-flow-col border-t mt-auto h-12" *ngIf="data.buttons">
      <div
        *ngFor="
          let button of data.buttons;
          trackBy: 'value' | trackByProperty;
          let last = last
        "
        [ngClass]="{ 'border-r': !last }"
      >
        <button
          [mat-dialog-close]="button.value"
          color="accent"
          class="rounded-none relative uppercase font-bold w-full h-full transition-colors active:bg-primary-25 text-accent bg-white"
        >
          {{ button.title | async }}
        </button>
      </div>
    </div>
  </div>
</div>
