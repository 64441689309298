<ion-grid class="bet-combinations">
  <ion-row>
    <ion-col>
      <h6>{{ 'ticket_bet_on' | translate }}</h6>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <ion-segment [(ngModel)]="section">
        <ion-segment-button value="group">
          {{ 'ticket_group' | translate }}
        </ion-segment-button>
        <ion-segment-button value="combs">
          {{ 'ticket_combs' | translate }}
        </ion-segment-button>
      </ion-segment>
    </ion-col>
  </ion-row>

  <ion-row *ngFor="let combi of combinations; trackBy: 'ID' | trackByProperty">
    <!-- Zaciatok kombinacii 1z2 + toggle-->
    <ion-col size="9">
      <ion-item class="toogle-comb" lines="none">
        <ion-toggle
          color="secondary"
          [ngModel]="combi.On"
          (click)="onToggleCombi(combi.ID, combi.On)"
        ></ion-toggle>
        <ion-label class="test">{{ combi.Desc }}</ion-label>
      </ion-item>
    </ion-col>
    <!-- Koniec kombinacii 1z2-->

    <!-- Zaciatok inputu -->
    <ion-col size="3" *ngIf="section === 'group'">
      <div class="flex w-full items-center rounded border p-1.5">
        <input
          spyFocus
          #spyFocus="spyFocus"
          #combiBetInput
          [ngModel]="
            spyFocus.focused
              ? undefined
              : +combi.Bet !== 0 || !combi.On
              ? combi.Bet
              : ''
          "
          (focus)="combiBetInput.value = null"
          (blur)="onCombiBetChange($event, combi, false)"
          class="text-right bg-transparent w-full"
          type="number"
          inputmode="decimal"
          [placeholder]="combi.Bet"
        />
        <span
          [style.order]="
            ('base.defaultCurrency.symbolPosition' | conf) === 'start' ? -1 : 1
          "
          class="px-1"
          *ngIf="+combi.Bet !== 0 || !combi.On"
          >{{ 'base.defaultCurrency.symbol' | conf }}</span
        >
      </div>
    </ion-col>
    <ion-col size="3" *ngIf="section === 'combs'">
      <div class="flex w-full items-center rounded border p-1.5">
        <input
          spyFocus
          #spyFocus="spyFocus"
          #combiPartialBetInput
          [ngModel]="
            spyFocus.focused
              ? undefined
              : +combi.ParcialBet !== 0 || !combi.On
              ? combi.ParcialBet
              : ''
          "
          (focus)="combiPartialBetInput.value = null"
          (blur)="onCombiBetChange($event, combi, true)"
          class="text-right bg-transparent w-full"
          type="number"
          inputmode="decimal"
          [placeholder]="
            +combi.ParcialBet === 0 && combi.On && !spyFocus.focused
              ? ''
              : combi.ParcialBet
          "
        />
        <span
          [style.order]="
            ('base.defaultCurrency.symbolPosition' | conf) === 'start' ? -1 : 1
          "
          class="px-1"
          *ngIf="+combi.ParcialBet !== 0 || !combi.On || spyFocus.focused"
          >{{ 'base.defaultCurrency.symbol' | conf }}</span
        >
        <span
          *ngIf="+combi.ParcialBet === 0 && combi.On && !spyFocus.focused"
          >{{ 'ticket_own' | translate }}</span
        >
      </div>
    </ion-col>
  </ion-row>
  <!-- Koniec inputu-->

  <ion-row>
    <ion-col *ngIf="isAnyCombinationActivated">
      <ion-item
        class="action"
        (click)="openCombinationsDetail()"
        detail="true"
        lines="none"
      >
        <ion-label slot="start">{{
          'ticket_show_combi' | translate
        }}</ion-label>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>
