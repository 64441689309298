<ion-item>
  <ion-grid
    class="ion-no-padding"
    [ngStyle]="{ 'background-color': noticeSetup.background }"
  >
    <ion-row>
      <ion-col
        size="2"
        *ngIf="noticeSetup.icon.length > 0"
        class="ion-text-center"
      >
        <ion-icon
          *ngIf="!noticeSetup.customIcon"
          name="{{ noticeSetup.icon }}"
          [ngStyle]="{ color: noticeSetup.iconBackground }"
        ></ion-icon>
        <span
          *ngIf="noticeSetup.customIcon"
          [className]="'icon icon-ic_' + noticeSetup.icon"
          [ngStyle]="{ color: noticeSetup.iconBackground }"
        ></span>
      </ion-col>
      <ion-col style="padding: 0 5px 0 5px">
        <h6>{{ noticeSetup.header }}</h6>
        <p>{{ noticeSetup.text }}</p>

        <ion-radio-group
          *ngIf="noticeSetup.radioText?.length > 0"
          [(ngModel)]="noticeRadio"
          background="white"
        >
          <ion-item lines="none">
            <ion-label
              style="white-space: break-spaces; font-size: 11px; color: #919597"
              >{{ noticeSetup.radioText }}</ion-label
            >
            <ion-radio color="red" slot="start" value="true"></ion-radio>
          </ion-item>
        </ion-radio-group>

        <ion-button
          [ngClass]="{ disabled: noticeRadio === false }"
          (click)="buttonClick(noticeSetup.context)"
          *ngIf="noticeSetup.buttonText.length > 0"
          color="secondary"
          expand="block"
          size="small"
          >{{ noticeSetup.buttonText }}</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-item>
