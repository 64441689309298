import { Injectable } from '@angular/core';
import { BUYBACK_REFRESH_INACTIVE_TIMEOUT } from '@constants';
import { AppVisibilityService } from '@core/services/app-visibility/app-visibility.service';
import { AuthService } from '@core/services/auth/auth.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { authActions, buyBackActions } from '@store/actions';
import { buyBackSelectors } from '@store/selectors';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { TickethistoryService } from 'src/app/pages/betslip/tickethistory/tickethistory.service';
import { SignalrService } from 'src/app/services/signalr.service';

@Injectable()
export class BuyBackEffects {
  reloadTickets$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(authActions.autologinSuccess, authActions.loginSuccess),
      map(() => buyBackActions.loadBuybackTickets())
    );
  });

  loadEvents$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(buyBackActions.loadBuybackTickets),
      switchMap(() =>
        this._authService.fetchClientEvents().pipe(
          tap(data => {
            if (!data) {
              throw new Error('no data loaded');
            }
          }),
          map(data =>
            Object.values<any>({
              ...data.reduce(
                (acc, { EventID, ...rest }) => ({
                  ...acc,
                  [rest.TicketCode]: {
                    ...rest,
                    EventIDs: Array.from(
                      new Set([
                        ...(acc[rest.TicketCode]?.EventIDs || []),
                        EventID
                      ])
                    )
                  }
                }),
                {}
              )
            })
          ),
          map(data => buyBackActions.loadBuybackTicketsSuccess({ data })),
          catchError(error =>
            of(buyBackActions.loadBuybacTicketsFailure({ error }))
          )
        )
      )
    );
  });

  loadBuybackValues$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(buyBackActions.loadBuybackValues),
      concatMap(action =>
        this._ticketHistoryService
          .getBuybackValueWithTimeTicketCode(action.ticketCode)
          .pipe(
            map(data =>
              buyBackActions.loadBuybackValuesSuccess({
                ticketCode: action.ticketCode,
                buybackValues: data
              })
            ),
            catchError(error =>
              of(
                buyBackActions.loadBuybackValuesFailure({
                  ticketCode: action.ticketCode,
                  error
                })
              )
            )
          )
      )
    );
  });

  executeBuyback$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(buyBackActions.executeBuyback),
      switchMap(action =>
        this._ticketHistoryService
          .buybackTicket(action.ticketCode, action.buybackValue)
          .pipe(
            map(data =>
              buyBackActions.executeBuybackSuccess({
                ticketCode: action.ticketCode,
                data
              })
            ),
            catchError(error =>
              of(
                buyBackActions.executeBuybackFailure({
                  ticketCode: action.ticketCode,
                  error
                })
              )
            )
          )
      )
    );
  });

  executeAutoBuyback$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(buyBackActions.executeAutoBuyback),
      switchMap(action =>
        this._ticketHistoryService
          .autoBuybackTicket(action.ticketCode, action.autoBuybackValue)
          .pipe(
            map(data =>
              buyBackActions.executeAutoBuybackSuccess({
                ticketCode: action.ticketCode,
                data
              })
            ),
            catchError(error =>
              of(
                buyBackActions.executeAutoBuybackFailure({
                  ticketCode: action.ticketCode,
                  error
                })
              )
            )
          )
      )
    );
  });

  // SIGNALR UPDATE
  updateBuyback$ = createEffect(() =>
    this._signalrService.buyBackChanges.pipe(
      concatLatestFrom(() =>
        this._store.select(buyBackSelectors.selectBuybackTickets)
      ),
      map(([changes, tickets]) => {
        // if signalr returns ticket ID which is not in store, refresh list
        if (changes.some(change => !(change.ticketID in tickets))) {
          return buyBackActions.loadBuybackTickets();
        }
        return buyBackActions.updateBuybackValue({
          changes: changes.map(change => ({
            ticketCode: change.ticketID,
            changes: {
              BuyBackValue: change.buybackValue
            }
          }))
        });
      })
    )
  );

  // refresh list after reveal window after inactivity
  refresh$ = createEffect(() =>
    this._appVisibilityService.visibilityChangeReveal.pipe(
      filter(revealAfter => revealAfter > BUYBACK_REFRESH_INACTIVE_TIMEOUT),
      map(() => buyBackActions.loadBuybackTickets())
    )
  );

  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _authService: AuthService,
    private _ticketHistoryService: TickethistoryService,
    private _signalrService: SignalrService,
    private _appVisibilityService: AppVisibilityService
  ) {}
}
