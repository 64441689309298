import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

// Animation duratition in miliseconds
const LOADER_SHOW_HIDE_ANIMATION_DURATION = 70;

@Component({
  selector: 'doxx-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  constructor(private _elRef: ElementRef) {}

  private _loadingSubscibtion: Subscription;
  @Input() loading: Observable<boolean>;

  /**
   * If there is observable loading input, start to subscribe on it and show/hide loader depends on current value
   */
  ngOnInit(): void {
    if (this.loading) {
      this._loadingSubscibtion = this.loading.subscribe(showLoading => {
        if (showLoading) {
          this.show();
        } else {
          this.hide();
        }
      });
    }
  }

  /**
   * Clear all subscriptions
   */
  ngOnDestroy(): void {
    if (this._loadingSubscibtion) {
      this._loadingSubscibtion.unsubscribe();
    }
  }

  /**
   * This mehtod adds animation to loader spinner, to show spinner with animation
   */
  show(): void {
    const spinnerContainer = (this._elRef.nativeElement as HTMLElement)
      .firstChild;
    (
      spinnerContainer as HTMLElement
    ).style.animation = `loaderIn ${LOADER_SHOW_HIDE_ANIMATION_DURATION}ms forwards`;
  }

  /**
   * This method adds hide animation to loader spinner.
   * This method returns Promise and is resolved after hide animation ends
   */
  hide(): Promise<void> {
    const spinnerContainer = (this._elRef.nativeElement as HTMLElement)
      .firstChild;
    (
      spinnerContainer as HTMLElement
    ).style.animation = `loaderOut ${LOADER_SHOW_HIDE_ANIMATION_DURATION}ms forwards`;
    return new Promise(resolve =>
      setTimeout(resolve, LOADER_SHOW_HIDE_ANIMATION_DURATION)
    );
  }
}
