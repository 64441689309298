import { Pipe, PipeTransform } from '@angular/core';
import { NavigationConfig } from 'src/navigation';
import { MaintenanceSelectorsAlias } from './is-blocked.pipe';

@Pipe({
  name: 'getSection'
})
export class GetSectionPipe implements PipeTransform {
  /**
   * for use with isBlocked pipe
   *
   * !not all screens are included!
   *
   * @returns section where screen belongs
   * */
  transform(
    destination: keyof NavigationConfig
  ): keyof MaintenanceSelectorsAlias {
    switch (destination) {
      case 'DepositWithdrawal_Deposit':
        return 'wallet';
      case 'PrematchList':
      case 'BetslipHistoryList':
      case 'SavedBetslipsList':
      case 'BonusesList':
      case 'ResultsList':
      case 'FavoritesList':
        return 'betting';
      case 'CasinoHome':
      case 'BonusesListCasino':
      case 'CasinoSpinHistoryList':
        return 'casino';
      default:
        return null;
    }
  }
}
