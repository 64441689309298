  <swiper
  (touchStart)="previewAnimation.style.animationPlayState = 'paused'"
  (touchEnd)="previewAnimation.style.animationPlayState = 'running'"
  (slideChangeTransitionStart)="updatePreviewSliderState($event)"
  (reachEnd)="onReachEnd()"
  (reachStart)="onReachStart()"
  #slider
  pager="true"
  [config]="slideOpts"
>
  <ng-container *ngFor="let item of slides">
    <ng-template swiperSlide [attr.data-swiper-autoplay]="item.timing">
      <ng-container [ngTemplateOutlet]="item.bannerTemplate"></ng-container>
    </ng-template>
  </ng-container>
</swiper>

<!-- PREVIEW -->
<div
  [style.width.px]="PREVIEW_WIDTH"
  [style.height.px]="PREVIEW_HEIGHT"
  class="preview"
>
  <swiper #preview [config]="previewOpts">
    <ng-container *ngFor="let item of slides">
      <ng-template swiperSlide>
        <div class="progress">
          <div class="content">
            <ng-container [ngTemplateOutlet]="item.preview"></ng-container>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </swiper>

  <svg 
    attr.viewBox="0 0 {{ PREVIEW_WIDTH }} {{ PREVIEW_HEIGHT }}"
  >
    <path
      (animationend)="slideNext()"
      #previewAnimation
      attr.d="M{{ PREVIEW_WIDTH / 2 }} 0 L{{ PREVIEW_WIDTH }} 0 L{{
        PREVIEW_WIDTH
      }} {{ PREVIEW_HEIGHT }} L 0 {{ PREVIEW_HEIGHT }} L 0 0  Z"
    />
  </svg>
  <i (click)="slideNext()" class="icon-next icon-ic_arrow"></i>
</div>
