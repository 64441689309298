import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLanding from '../landing.reducer';
import * as fromWinners from './landing-winners.reducer';

const selectLandingState = createFeatureSelector<fromLanding.State>(
  fromLanding.LANDING_FEATURE_KEY
);

export const selectWinners = createSelector(
  selectLandingState,
  (state: fromLanding.State) => state.winners
);

// TICKETS
export const selectWinnersTickets = createSelector(
  selectWinners,
  (state: fromWinners.State) => state.ticketWins
);
export const selectWinnersTicketsLoading = createSelector(
  selectWinnersTickets,
  state => state.loading
);
export const selectWinnersTicketsTickets = createSelector(
  selectWinnersTickets,
  state => state.tickets
);
export const selectWinnersTicketsError = createSelector(
  selectWinnersTickets,
  state => state.error
);

// CASINO WINS
export const selectCasinoWins = createSelector(
  selectWinners,
  (state: fromWinners.State) => state.casinoWins
);
export const selectCasinoWinsLoading = createSelector(
  selectCasinoWins,
  state => state.loading
);
export const selectCasinoWinsCasinoWins = createSelector(
  selectCasinoWins,
  state => state.wins
);
export const selectCasinoWinsError = createSelector(
  selectCasinoWins,
  state => state.error
);
