import { Settings } from '@models/Settings';
import { User } from '../../models/User';
import * as authActions from './auth.actions';
import { Action, createReducer, on } from '@ngrx/store';

export interface AuthState {
  user: User;
  error: string;
  loading: boolean;
  settings: Settings;
}

const initialState: AuthState = {
  user: null,
  error: '',
  loading: false,
  settings: null
};

const reducer = createReducer(
  initialState,
  // LOGIN
  on(authActions.loginStart, state => ({ ...state, error: '', loading: true })),
  on(authActions.loginSuccess, (state, action) => ({
    ...state,
    user: {
      ...action.payload,
      registrationDuration: new Date(
        action.payload.registrationCreated
      ).daysDiff(new Date())
    },
    loading: false,
    settings: {
      ...state.settings,
      ...action.settings
    }
  })),
  on(authActions.loginFailure, (state, action) => ({
    ...state,
    user: null,
    error: action.error,
    loading: false,
    settings: null
  })),
  // AUTOLOGIN
  on(authActions.autologinStart, (state, action) => ({
    ...state,
    loading: true
  })),
  on(authActions.autologinSuccess, (state, action) => ({
    ...state,
    user: {
      ...action.user,
      registrationDuration: new Date(action.user.registrationCreated).daysDiff(
        new Date()
      )
    },
    settings: {
      ...state.settings,
      ...action.settings
    },
    loading: false
  })),
  on(authActions.autologinFailure, (state, action) => ({
    ...state,
    loading: false
  })),
  // LOGOUT
  on(authActions.logoutSuccess, (state, action) => ({
    ...state,
    user: null,
    settings: null
  })),
  on(authActions.softLogoutSuccess, (state, action) => ({
    ...state,
    user: null,
    settings: null
  })),

  // BALANCE
  on(authActions.balanceRefreshSuccess, (state, action) => ({
    ...state,
    user: {
      ...state.user,
      ...action.payload
    },
    error: ''
  })),
  on(authActions.balanceRefreshFailure, (state, action) => ({
    ...state,
    user: null,
    error: action.error,
    loading: false
  })),

  // LOAD SETTINGS
  on(authActions.loadSettingsSuccess, (state, action) => ({
    ...state,
    settings: action.settings
  }))
);

/**
 * Reducer for managing auth state, depends on called actoion
 */
export function authReducer(
  state: AuthState = initialState,
  action: Action
): AuthState {
  return reducer(state, action);
}
