import {
  Injectable,
  ComponentFactoryResolver,
  ComponentFactory,
  ViewChild
} from '@angular/core';
import { TicketBubleHostDirective } from 'src/app/ticket-buble/ticket-buble-host.directive';
import { TicketBubleComponent } from 'src/app/ticket-buble/ticket-buble.component';

@Injectable({
  providedIn: 'root'
})
export class TicketBubleService {
  private _componentFactory: ComponentFactory<TicketBubleComponent>;
  public ticketBubleHostDirective: TicketBubleHostDirective;
  private _ticketBubleComponentRef: TicketBubleComponent;

  constructor(private _cfr: ComponentFactoryResolver) {}

  /** Zobrazi bublinu s tiketmi ak este nie je zobrazena */
  // window.location.search.includes(storedTicketShowMatcher)
  showTicketBuble(): void {
    if (!this._ticketBubleComponentRef) {
      this._componentFactory =
        this._cfr.resolveComponentFactory(TicketBubleComponent);
      this.ticketBubleHostDirective.viewContainerRef.clear();
      const componentRef =
        this.ticketBubleHostDirective.viewContainerRef.createComponent(
          this._componentFactory
        );
      this._ticketBubleComponentRef = componentRef.instance;
      this._ticketBubleComponentRef.show();
    }
  }

  /** Ak je bublina zobrazena, schova ju */
  hideTicketBuble(showAnimation?: boolean, message?: string): void {
    if (this._ticketBubleComponentRef) {
      this._ticketBubleComponentRef.hide(showAnimation, message).then(
        () => {
          this.ticketBubleHostDirective.viewContainerRef.clear();
          this._ticketBubleComponentRef = null;
        },
        () => {}
      );
    }
  }

  /** Ak je bublina zobrazena, schovaju cez css */
  setVisibleTicketBuble(): void {
    if (this._ticketBubleComponentRef) {
      this._ticketBubleComponentRef.visible = true;
    }
  }

  /** Ak je bublina zobrazena, schovaju cez css */
  setInvisibleTicketBuble(): void {
    if (this._ticketBubleComponentRef) {
      this._ticketBubleComponentRef.visible = false;
    }
  }
}
