import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TicketcombinationsComponent } from './ticketcombinations.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [TicketcombinationsComponent],
  imports: [CommonModule, IonicModule, FormsModule, SharedModule],
  exports: [TicketcombinationsComponent]
})
export class TicketcombinationsModule {}
