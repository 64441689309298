import { Pipe, PipeTransform } from '@angular/core';
import { slugify } from '@core/functions/slugify';

@Pipe({
  name: 'slugify'
})
export class SlugifyPipe implements PipeTransform {
  /**
   * Slugify any string, function wraps function from  package https://www.npmjs.com/package/slugify
   * with defaul config for our application
   */
  transform(value: string): string {
    if (typeof value === 'string') {
      return slugify(value);
    }
    return value;
  }
}
