import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { landingActions } from '@store/actions';
import { concatMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { OfferService } from '@core/services/offer/offer.service';
import { environment } from '@environment';
import { configuration } from '@configuration';

@Injectable()
export class LandingVirtualGamesEffects {
  loadVirtualGamesList$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(landingActions.loadVirtualGamesList),
      concatMap(action =>
        this._offerService
          .fetchWholeMenu({ sportId: environment.virtualGames.vgSportID })
          .pipe(
            map(data =>
              landingActions.loadVirtualGamesListSuccess({
                leagues: Object.values(data.leagues).filter(
                  league =>
                    configuration.virtualGames.sports[league.ParentID]?.leagues[
                      league.ID
                    ]?.allowed
                ),
                regions: Object.values(data.regions).filter(
                  region =>
                    configuration.virtualGames.sports[region.ID]?.allowed
                )
              })
            ),
            catchError(err =>
              of(landingActions.loadVirtualGamesListFailure({ err }))
            )
          )
      )
    );
  });

  constructor(
    private _actions$: Actions,
    private _offerService: OfferService
  ) {}
}
