import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@environment';

@Pipe({
  name: 'env',
  pure: false
})
export class EnvPipe implements PipeTransform {
  /**
   * Pipe for fetching environment variables in templates
   * @param propertyPath dot separated key path to object from environment
   * @returns returns object from environment
   * @author M.Vorcak
   */
  transform(propertyPath: string): any {
    const value = propertyPath.split('.').reduce((object, key) => {
      return object[key];
    }, environment);
    return value;
  }
}
