import { createAction, props } from '@ngrx/store';
import { Actuality } from '@models/Actuality';

export const loadActualities = createAction('[Actualities] Load Actualities');

export const loadActualitiesSuccess = createAction(
  '[Actualities] Load Actualities Success',
  props<{
    data: {
      actualities: Actuality[];
    };
  }>()
);

export const loadActualitiesFailure = createAction(
  '[Actualities] Load Actualities Failure',
  props<{ error: any }>()
);
