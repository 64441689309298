import {
  Injectable,
  ComponentFactoryResolver,
  Injector,
  ComponentRef,
  Inject
} from '@angular/core';
import { LoaderComponent } from './loader.component';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public loaderIsPresent = false;
  loaderComponentRef: ComponentRef<LoaderComponent>;

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  /**
   * show loader with XX logo over all pages
   */
  async presentXXLoader(): Promise<void> {
    if (this.loaderComponentRef) {
      return;
    }

    const componentFactory =
      this._componentFactoryResolver.resolveComponentFactory(LoaderComponent);
    const componentRef = componentFactory.create(this._injector);
    this.loaderComponentRef = componentRef;

    const { nativeElement } = componentRef.location;

    this._document.body.appendChild(nativeElement);
    this.loaderComponentRef.instance.show();
  }

  /**
   * Hide loader with XX logo. You can only hide loader tahat is was being present by method presentXXLoader().
   * @param force true - loader will be dissmissed immediately without animation.
   */
  async dismissXXLoader(force: boolean = false): Promise<void> {
    if (this.loaderComponentRef) {
      if (force) {
        this.loaderComponentRef.destroy();
        this._document.body.removeChild(
          this.loaderComponentRef.location.nativeElement
        );
        this.loaderComponentRef = null;
        return;
      } else {
        await this.loaderComponentRef.instance.hide().then(() => {
          if (this.loaderComponentRef) {
            this.loaderComponentRef.destroy();
            this._document.body.removeChild(
              this.loaderComponentRef.location.nativeElement
            );
            this.loaderComponentRef = null;
            return;
          }
        });
      }
    }
  }
}
