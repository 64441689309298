import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { configuration } from '@configuration';
import { ConfigContactContacts } from '@configurationTypes/configContactContacts';

@Component({
  selector: 'doxx-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  constructor(private _popoverCtrl: PopoverController) {}

  contacts: Array<ConfigContactContacts> = [];

  /**
   * ngoninit
   */
  ngOnInit(): void {
    this.contacts = configuration.contact.contacts;
  }

  /**
   * close popover
   */
  close(): void {
    this._popoverCtrl.dismiss();
  }
}
