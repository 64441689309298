import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TicketComponent } from './ticket.component';
import { SharedModule } from '@shared/shared.module';
import { TicketoddsModule } from './ticketodds/ticketodds.module';
import { TicketcombinationsModule } from './ticketcombinations/ticketcombinations.module';
import { CombinationsdetailModule } from './combinationsdetail/combinationsdetail.module';
import { DoxxPipesModule } from '@shared/pipes/doxx-pipes.module';
import { TranslateSpecialPipe } from '@shared/pipes/translate.pipe';
import { EmptyTicketComponent } from './empty-ticket/empty-ticket.component';
import { XxlBonusComponent } from './xxl-bonus/xxl-bonus.component';
import { TicketErrorParser } from './ticket.error-parser';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    TicketoddsModule,
    TicketcombinationsModule,
    CombinationsdetailModule,
    DoxxPipesModule
  ],
  declarations: [TicketComponent, EmptyTicketComponent, XxlBonusComponent],
  exports: [TicketComponent],
  providers: [TranslateSpecialPipe, TicketErrorParser]
})
export class TicketModule {}
