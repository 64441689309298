// https://stackoverflow.com/questions/42972386/prevent-click-event-on-long-press
/* eslint-disable @angular-eslint/directive-class-suffix, @angular-eslint/directive-selector, @angular-eslint/no-output-on-prefix */

import {
  Directive,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  HostListener
} from '@angular/core';

@Directive({ selector: '[longPressEnd]' })
export class LongPressDirectiveEnd {
  @Output()
  longPressEnd = new EventEmitter();
  private _isLong = false;
  private _isEmitted = false;

  private _timeout: any;

  @HostListener('mousedown')
  @HostListener('touchstart')
  onTouchStart() {
    // console.log('onTouchStart');
    this._timeout = setTimeout(() => {
      this._isLong = true;
      this._isEmitted = false;
    }, 500);
  }

  @HostListener('mouseup')
  @HostListener('touchend')
  onTouchEnd() {
    // console.log('onTouchEnd');
    clearTimeout(this._timeout);
    if (this._isLong && !this._isEmitted) {
      this.longPressEnd.emit();
      this._isEmitted = true;
    }
  }

  @HostListener('mouseleave')
  @HostListener('touchcancel')
  onTouchCancel(e) {
    // console.log('onTouchCancel');
    this._isLong = false;
    if (!this._isEmitted) {
      this.longPressEnd.emit();
      this._isEmitted = true;
    }
    clearTimeout(this._timeout);
  }

  @HostListener('touchmove') onTouchMove() {
    // console.log('onTouchMove');
    this._isLong = false;
    if (!this._isEmitted) {
      this.longPressEnd.emit();
      this._isEmitted = true;
    }
    clearTimeout(this._timeout);
  }
}
