import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFavourites from './favorites.reducer';
import { FavouriteChanceType } from '@models/FavouriteChanceType';

export const selectFavoritesState = createFeatureSelector<fromFavourites.State>(
  fromFavourites.FAVORITES_STATE_KEY
);

export const selectFavEventIDs = createSelector(
  selectFavoritesState,
  (state: fromFavourites.State) => state.favouriteEvents
);

export const selectIsEventInFav = (eventID: number) =>
  createSelector(selectFavoritesState, (state: fromFavourites.State) => {
    return state.favouriteEvents.indexOf(eventID) > -1;
  });

export const selectIsSportInFav = (sportID: number) =>
  createSelector(selectFavoritesState, (state: fromFavourites.State) => {
    return state.favouriteSports.indexOf(sportID) > -1;
  });

export const selectIsLeagueInFav = (leagueID: number) =>
  createSelector(selectFavoritesState, (state: fromFavourites.State) => {
    return state.favouriteLeagues.indexOf(leagueID) > -1;
  });

export const selectIsChanceTypeInFav = (cht: FavouriteChanceType) =>
  createSelector(selectFavoritesState, (state: fromFavourites.State) => {
    for (const favCht of state.favouriteChanceTypes) {
      if (
        favCht.chanceTypeId === cht.chanceTypeId &&
        favCht.sportId === cht.sportId &&
        favCht.liveBetting === cht.liveBetting
      ) {
        return true;
      }
    }
    return false;
  });

export const selectFavChanceTypes = createSelector(
  selectFavoritesState,
  (state: fromFavourites.State) => {
    return state.favouriteChanceTypes;
  }
);

export const selectFavSports = createSelector(
  selectFavoritesState,
  (state: fromFavourites.State): number[] => {
    return state.favouriteSports;
  }
);

export const selectFavLeagues = createSelector(
  selectFavoritesState,
  (state: fromFavourites.State) => {
    return state.favouriteLeagues;
  }
);

export const selectHasFavSportsOrLeagues = createSelector(
  selectFavoritesState,
  (state: fromFavourites.State) => {
    return (
      state.favouriteLeagues.length > 0 || state.favouriteSports.length > 0
    );
  }
);

export const selectFavChanceTypesFiltered = (live: boolean, sportID: number) =>
  createSelector(selectFavoritesState, (state: fromFavourites.State) => {
    return state.favouriteChanceTypes.filter(
      favCht => favCht.liveBetting === live && favCht.sportId === sportID
    );
  });
