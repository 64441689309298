<div class="analysis-body">
  <div class="path">
    <ion-badge
      [style.background]="
        'var(--xx-sport-color-' + analysis.SportID + ', black)'
      "
      mode="md"
      [routerLink]="
        ('offer' | available)
          ? ('PrematchList'
            | translateRoute
              : {
                  sportID: [analysis.SportID, analysis.SportName | slugify]
                })
          : ('AnalysisDetail'
            | translateRoute : { analysisID: analysis.EventID })
      "
      ><h4>{{ analysis.SportName }}</h4></ion-badge
    >
    <ion-badge
      [style.background]="
        'var(--xx-sport-color-' + analysis.SportID + ', black)'
      "
      mode="md"
      [routerLink]="
        ('offer' | available)
          ? ('PrematchList'
            | translateRoute
              : {
                  sportID: [analysis.SportID, analysis.SportName | slugify],
                  regionID: [analysis.RegionID, analysis.RegionName | slugify]
                })
          : ('AnalysisDetail'
            | translateRoute : { analysisID: analysis.EventID })
      "
      ><h4>{{ analysis.RegionName }}</h4></ion-badge
    >
    <ion-badge
      [style.background]="
        'var(--xx-sport-color-' + analysis.SportID + ', black)'
      "
      mode="md"
      [routerLink]="
        ('offer' | available)
          ? ('PrematchList'
            | translateRoute
              : {
                  sportID: [analysis.SportID, analysis.SportName | slugify],
                  regionID: [analysis.RegionID, analysis.RegionName | slugify],
                  leagueID: [
                    analysis.LeagueCupID,
                    analysis.LeagueCupName | slugify
                  ]
                })
          : ('AnalysisDetail'
            | translateRoute : { analysisID: analysis.EventID })
      "
      ><h4>{{ analysis.LeagueCupName }}</h4></ion-badge
    >
  </div>
  <div class="analysis-date">
    {{ analysis.EventDateToString | formatDate : 'YYYYMMDDhhmm' }}
  </div>
  <div
    [routerLink]="
      ('offer' | available)
        ? ('EventDetail'
          | translateRoute
            : {
                sportID: [analysis.SportID, analysis.SportName | slugify],
                regionID: [analysis.RegionID, analysis.RegionName | slugify],
                leagueID: [
                  analysis.LeagueCupID,
                  analysis.LeagueCupName | slugify
                ],
                eventID: analysis.EventID,
                eventName:
                  analysis.TeamHome + 'vs.' + analysis.TeamAway | slugify
              })
        : ('AnalysisDetail' | translateRoute : { analysisID: analysis.EventID })
    "
    [queryParams]="('offer' | available) ? { tab: 'analysis' } : {}"
  >
    <div class="analysis-title-wrapper">
      <h3 class="analysis-title">
        {{ analysis.TeamHome }} vs. {{ analysis.TeamAway }}
      </h3>
    </div>
    <div class="analysis-article">
      <p [innerHTML]="analysis.Article"></p>
    </div>
  </div>
</div>
<div class="analysis-footer" *ifAvailable="'offer'">
  <div class="analysis-stats-container">
    <ng-container
      *ngTemplateOutlet="statsTmp; context: { $implicit: { Tip: '1' } }"
    ></ng-container>
    <ng-container *ngIf="odds['X']">
      <ng-container
        *ngTemplateOutlet="statsTmp; context: { $implicit: { Tip: 'X' } }"
      ></ng-container>
    </ng-container>
    <ng-container
      *ngTemplateOutlet="statsTmp; context: { $implicit: { Tip: '2' } }"
    ></ng-container>
  </div>
</div>

<ng-template #statsTmp let-stats>
  <doxx-odd
    *ngIf="odds[stats.Tip]"
    class="analysis-stats"
    [odd]="odds[stats.Tip]"
    [event]="{
      EventID: analysis.EventID,
      LiveBetting: 'N'
    }"
  ></doxx-odd>
</ng-template>
