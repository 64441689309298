import { NgModule } from '@angular/core';
import { VarDirective } from './var.directive';
import { CopyClipboardDirective } from './copy-clipboard.directive';
import { LongPressDirective } from './long-press.directive';
import { ProgresiveLoadingDirective } from './progresive-loading/progresive-loading.directive';
import { HrefOrRouterLinkDirective } from './href-or-router-link.directive';
import { ParseLabelDirective } from './parse-label/parse-label.directive';
import { FormControlFormatDirective } from './form-control-format/form-control-format.directive';
import { OnErrorDirective } from './on-error.directive';
import { BadgeDrawerDirective } from './badge-drawer.directive';
import { IonSelectInterfaceModalDirective } from './ion-select-interface-modal/ion-select-interface-modal.directive';
import { LongPressDirectiveEnd } from './long-press-end.directive';
import { SpyFocusDirective } from './spy-focus/spy-focus.directive';

@NgModule({
  declarations: [
    VarDirective,
    CopyClipboardDirective,
    LongPressDirective,
    LongPressDirectiveEnd,
    ProgresiveLoadingDirective,
    HrefOrRouterLinkDirective,
    ParseLabelDirective,
    FormControlFormatDirective,
    OnErrorDirective,
    BadgeDrawerDirective,
    IonSelectInterfaceModalDirective,
    SpyFocusDirective
  ],
  imports: [],
  exports: [
    VarDirective,
    CopyClipboardDirective,
    LongPressDirective,
    LongPressDirectiveEnd,
    ProgresiveLoadingDirective,
    HrefOrRouterLinkDirective,
    ParseLabelDirective,
    FormControlFormatDirective,
    OnErrorDirective,
    BadgeDrawerDirective,
    IonSelectInterfaceModalDirective,
    SpyFocusDirective
  ]
})
export class DoxxDirectivesModule {}
