import { Component, Input, OnInit } from '@angular/core';
import { AnalysisWithOdds } from '@models/Analysis';
import { Odd } from '@models/Odd';

@Component({
  selector: 'doxx-analysis',
  templateUrl: './analysis.component.html'
})
export class AnalysisComponent implements OnInit {
  @Input() analysis: AnalysisWithOdds;
  odds: { [tipType: string]: Odd } = {};

  /**
   * map analysis odds to object for rendering purposes
   */
  ngOnInit(): void {
    this.odds = this.analysis.Odds.reduce(
      (a, c) => ({ ...a, [c.TipType]: c }),
      {}
    );
  }
}
