import { Component, ElementRef, ViewChild } from '@angular/core';
import { configuration } from '@configuration';
import { oneLine } from '@core/functions/oneLine';
import { environment } from '@environment';
import { ModalController } from '@ionic/angular';

@Component({
  template: `
    <g-recaptcha
      [attr.validation-endpoint]="validationEndpoint"
      [attr.site-key]="siteKey"
      [attr.language]="lang"
      (verified)="onVerify($event)"
    >
    </g-recaptcha>
  `
})
export class CaptchaComponent {
  validationEndpoint = oneLine`${environment.apiUrl}/recaptcha/validate`;
  siteKey = configuration.base.captchaSiteKey;
  lang = environment.language;

  @ViewChild('captchaPortal') _captchaElementRef: ElementRef<HTMLDivElement>;

  constructor(private _modalCtrl: ModalController) {}

  /** after verify captcha */
  onVerify($event): void {
    setTimeout(() => {
      this._modalCtrl.dismiss($event.detail);
    }, 1000);
  }
}
