/** get odds stats  */
export function getOddsCounts(value): Record<
  'Y' | 'U' | 'N',
  {
    count: number;
    percent: number;
  }
> {
  const stats = {
    Y: {
      count: 0,
      percent: 0
    },
    U: {
      count: 0,
      percent: 0
    },
    N: {
      count: 0,
      percent: 0
    }
  };

  if (value.match(/Y=(\w*)/)) {
    stats.Y.count = parseInt(value.match(/Y=(\w*)/)[1], 10);
  }
  if (value.match(/U=(\w*)/)) {
    stats.U.count = parseInt(value.match(/U=(\w*)/)[1], 10);
  }
  if (value.match(/N=(\w*)/)) {
    stats.N.count = parseInt(value.match(/N=(\w*)/)[1], 10);
  }
  const all = stats.Y.count + stats.U.count + stats.N.count;
  if (stats.U.count !== 0) {
    stats.U.percent = (stats.U.count / all) * 100;
  }
  if (stats.N.count !== 0) {
    stats.N.percent = (stats.N.count / all) * 100 + stats.U.percent;
  }
  if (stats.Y.count !== 0) {
    stats.Y.percent = (stats.Y.count / all) * 100 + stats.N.percent;
  }

  return stats;
}
