<div
  class="item"
  [ngClass]="{ selected: active }"
  (click)="onShowActionSheet()"
>
  <div class="item-icon">
    <i class="icon icon-ic_history"></i>
  </div>
  <div class="item-title">
    {{ selectedDate?.translation | translate }}
  </div>
</div>

<div class="hidden">
  <input
    [matDatepicker]="picker"
    [min]="config.minDate"
    [max]="config.maxDate"
    (dateChange)="onDatePickerChange($event)"
  />
  <mat-datepicker touchUi #picker></mat-datepicker>
</div>
