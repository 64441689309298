import { Component, HostBinding, Input } from '@angular/core';
import { BadgeData } from '@shared/directives/badge-drawer.directive';
import { tabAnimations } from '../tab-animations';
type Position = 'left' | 'center' | 'right';
export const TAB_TRANSITION_DURATION = 150;
@Component({
  selector: 'doxx-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  animations: [tabAnimations]
})
export class TabComponent {
  @HostBinding('@tabHeight')
  @HostBinding('@tabPosition')
  get currnetPosition(): any {
    return {
      value: this.position,
      params: { duration: TAB_TRANSITION_DURATION }
    };
  }

  @Input() public badge: BadgeData;

  @Input() public label: string;
  @Input() public disabled = false;

  @Input() public value?: any;
  constructor() {}
  public position: Position = 'left';
}
