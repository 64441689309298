import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Observable, of } from 'rxjs';
import { oneLine } from '@core/functions/oneLine';
import { concatMap, map } from 'rxjs/operators';
import {
  FetchWholeMenuOptions,
  FetchEventsOptions,
  FetchEventDetailOptions,
  FetchTopBettingOptions
} from '../types';
import { TopEvent } from '@models/TopEvent';
import { Event } from '@models/Event';
import { Odd } from '@models/Odd';
import { configuration } from '@configuration';
import { TimezoneService } from '../timezone/timezone.service';
import { transformOfferListResponse } from './transformOfferListResponse';
import { OfferResponse } from './offerResponse';
import { transformMainMenuResponse } from './transformMainMenuResponse';
import { transformWholeMenuResponse } from './transformWholeMenuResponse';
import { transformStreamListResponse } from './transformStreamListResponse';
import { TranslateService } from '@ngx-translate/core';
import { transformLeagueCupMenuResponse } from './transformLeagueCupMenuResponse';

const CONTROLER_NAMESPACE = '/json/ActualOfferService.svc';
@Injectable({
  providedIn: 'root'
})
export class OfferService {
  constructor(
    private _httpClient: HttpClient,
    private _timezoneService: TimezoneService,
    private _translateService: TranslateService
  ) {}

  /**
   * Fetch events from offer service and parse it to JSON
   */
  fetchEvents(options: FetchEventsOptions): Observable<OfferResponse> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/OfferList
        /SportEvent/${options.sportEvent || '-1'}
        /Sport/${options.sportId || '-1'}
        /Region/${options.regionId || '-1'}
        /LeagueCup/${options.leagueCupID || '-1'}
        /Live/${options.live ? '1' : '-1'}
        /Date/${options.date || '-1'}
        /Zone/${this._timezoneService.timezone}
        /Top/${options.top ? '1' : '-1'}
        /LanguageID/${environment.language}`,
        {
          params: {
            ...(options.onlyWithStream ? { streamOnly: '1' } : {})
          }
        }
      )
      .pipe(map(data => transformOfferListResponse(data)));
  }

  /**
   * Fetch event detail from offer service and parse it to JSON
   * https://m-api-staging.doxxbet.sk/ActualOfferService.svc/OfferEventDetail/Event/15631337/Zone/107/LanguageID/SK?nocache=1595421813305
   */
  fetchEventDetail(options: FetchEventDetailOptions): Observable<any> {
    const result = this._httpClient.get<any>(
      oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/OfferEventDetail
        /Event/${options.eventId}
        /Zone/${this._timezoneService.timezone}
        /LanguageID/${environment.language}`
    );
    return result;
  }

  /**
   * Fetch whole menu from offer service and parse it to JSON
   */
  fetchWholeMenu(options: FetchWholeMenuOptions): Observable<OfferResponse> {
    const live = options.live ? '1' : '-1';
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/Menu/WholeMenu
      /SportEvent/${options.sportEvent || '-1'}
      /Sport/${options.sportId || '-1'}
      /Live/${live || '-1'}
      /Date/${options.date || '-1'}
      /Zone/${this._timezoneService.timezone}
      /LanguageID/${environment.language}`
      )
      .pipe(map(result => transformWholeMenuResponse(result)));
  }

  /**
   * Fetch top betting, example of url:
   * https://offer-staging-cf.doxxbet.sk/ActualOfferService.svc/TopBetting/Top/5/Site/STG/LanguageID/sk/TimeZoneID/107/Date/LIVE
   */
  feetchTopBetting(options: FetchTopBettingOptions): Observable<{
    topRateSum: number;
    events: TopEvent[];
    odds: Odd[];
  }> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/TopBetting
          /Top/${options.count}
          /Site/${environment.location.toUpperCase()}
          /LanguageID/${environment.language}
          /TimezoneID/${this._timezoneService.timezone}
          /Date/${options.date}`
      )
      .pipe(
        map(result => {
          const events = result.TopEvents;
          const topRateSum = result.TopRateSum;
          let odds = [];
          events.forEach(event => {
            const [home, away] = event.EventName.split(' vs.');

            ['1', 'X', '2', '1X', 'X2', '12'].forEach(tipType => {
              if (event['ID_' + tipType]) {
                odds = [
                  ...odds,
                  {
                    OddsID: event['ID_' + tipType],
                    OddNameNaming:
                      tipType === '1'
                        ? home
                        : tipType === '2'
                        ? away
                        : tipType === 'X'
                        ? this._translateService.instant('app_draw')
                        : tipType,
                    OddNameNumbering: tipType,
                    OddsRate: event['Rate_' + tipType],
                    TipType: tipType,
                    EventChanceTypeID: event.EventChanceTypeID,
                    EventID: event.EventID,
                    Status: 'active',
                    isTopTip: event.TopTip === tipType
                  }
                ];
              }
            });
          });
          return {
            events,
            topRateSum,
            odds
          };
        })
      );
  }

  /**
   * Fetch top leagues, example of url:
   */
  feetchTopLeagues(count: number): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/TopOfferLeagueCups
        /Top/${count}
        /Site/${environment.location.toUpperCase()}
        /LanguageID/${environment.language}`
    );
  }

  /**
   * Search events
   */
  searchEvents(searchText: string): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/OfferSearch
        /SearchText/${searchText}
        /Zone/${this._timezoneService.timezone}
        /LanguageID/${environment.language}`
    );
  }

  /**
   * Vyhladavanie textu v:
   *   results
   *   casino
   *   rio
   *   bonus
   *   news
   *   /client/data/searchString/{searchString}/count/{count}
   */
  searchAllEvents(searchString: string): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/client/data
        /searchString/${searchString}
        /count/${3}`,
      {
        withCredentials: true
      }
    );
  }

  /**
   * Fetch event master chance types
   * https://m.doxxbet.sk/ActualOfferService.svc/OfferEventMasterChanceType/Event/{eventid}/Zone/{zone}/LanguageID/{lang}
   */
  fetchEventMasterChanceTypes(eventID: number): Observable<any> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/OfferEventMasterChanceType
        /Event/${eventID}
        /Zone/${this._timezoneService.timezone}
        /LanguageID/${environment.language}`
    );
  }

  /**
   * zisti ci moze uzivatel sledovat streamy resp ake su podmienky
   */
  canWatchStream(): Observable<CanWatchStreamResult> {
    return this._httpClient.get<any>(
      oneLine`${environment.apiUrl}/web/client/canWatchStream`,
      {
        withCredentials: true
      }
    );
  }

  /** fetch stream list */
  fetchStreamList(): Observable<OfferResponse> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/StreamList
        /Zone/${this._timezoneService.timezone}
        /LanguageID/${environment.language}`,
        {
          params: { nocahce: Date.now().toString() },
          headers: {
            'Cache-Control': 'no-cache'
          }
        }
      )
      .pipe(map(result => transformStreamListResponse(result)));
  }

  /** ziska url k streamu */
  fetchStreamUrl(options: {
    provider: Event['StreamProvider'];
    id: string;
    eventID?: string;
    stream?: string;
  }): Observable<string> {
    if (options.provider === 'UOF') {
      let myIP = of(null);
      if (!environment.production) {
        myIP = this._httpClient
          .get('https://api.ipify.org', { responseType: 'text' })
          .pipe(map(ip => ({ 'stream-ip': ip })));
      }
      return myIP
        .pipe(
          concatMap(headers =>
            this._httpClient.get<any>(
              oneLine`
              ${environment.apiUrl}/Stream/
              Betradar/${options.id}
            `,
              { withCredentials: true, headers }
            )
          )
        )
        .pipe(
          map(
            resp =>
              oneLine`${configuration.streams.providers.UOF.url}?
              platform=responsive&
              language=${environment.language}&
              streamUrl=${encodeURIComponent(resp.url)}`
          )
        );
    } else if (options.provider === 'StatsPerform') {
      return this._httpClient
        .post<any>(
          oneLine`
        ${environment.apiUrl}/Stream/
        StatsPerform/${options.id}
      `,
          null,
          { withCredentials: true }
        )
        .pipe(
          map(
            resp =>
              oneLine`${configuration.streams.providers.StatsPerform.url}?
              token=${resp.access_token}&
              event=${options.eventID}&
              stream=${options.id}&
              client=${resp.uuid}&
              outlet=${configuration.streams.providers.StatsPerform.outlet}&
              language=${environment.language}&
              platform=responsive
              `
          )
        );
    } else if (options.provider === 'BETGENIUS') {
      return this._httpClient
        .post<any>(
          oneLine`${environment.apiUrl}/Stream/BetGenius`,
          {
            url: options.stream
          },
          { withCredentials: true }
        )
        .pipe(
          map(
            resp =>
              oneLine`${configuration.streams.providers.Betgenius.url}?
              payload=${encodeURIComponent(JSON.stringify(resp))}&
              customerId=${configuration.streams.providers.Betgenius.customer}&
              fixtureId=${options.id}&
              language=${environment.language}&
              platform=responsive`
          )
        );
    } else if (options.provider === 'IMG') {
      return this._httpClient
        .get<any>(
          oneLine`${environment.apiUrl}/central/system/stream/img/` + options.id
        )
        .pipe(
          map(
            resp =>
              oneLine`${configuration.streams.providers.IMG.url}?
              hlsUrl=${encodeURIComponent(resp.url)}`
          )
        );
    }
  }

  /** fetch sports menu */
  fetchMainMenu(options: {
    date: string;
    live: boolean;
  }): Observable<OfferResponse> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/Menu/MainMenu
      /Live/${options.live === true ? '1' : '-1'}
      /Date/${options.date}
      /Zone/${this._timezoneService.timezone}
      /LanguageID/${environment.language}`
      )
      .pipe(map(result => transformMainMenuResponse(result)));
  }

  /**
   * Loads available leagues
   */
  fetchLeagueCupMenu(
    options: {
      sportID?: number;
      regionID?: number;
      sportEventID?: number;
      live?: boolean;
      date?: string;
    } = {}
  ): Observable<OfferResponse> {
    return this._httpClient
      .get<any>(
        oneLine`${environment.apiUrl}${CONTROLER_NAMESPACE}/Menu/LeagueCupMenu
        /SportEvent/${options?.sportEventID || '-1'}
        /Sport/${options?.sportID || '-1'}
        /Region/${options?.regionID || '-1'}
        /Live/${options?.live === true ? '1' : '-1'}
        /Date/${options?.date || '-1'}
        /Zone/${this._timezoneService.timezone}
        /LanguageID/${environment.language}`
      )
      .pipe(map(result => transformLeagueCupMenuResponse(result)));
  }
}

export interface CanWatchStreamResult {
  ErrorCode: number;
  ErrorDescription: string;
  IsSuccessed: boolean;
  ResponseStatus: string;
  Result: {
    Amount: number;
    CanWatch: boolean;
    DaysCount: number;
    TicketCount: number;
  };
}
