import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  concatMap,
  map,
  catchError,
  take,
  switchMap,
  filter
} from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';

import { BonusesService } from '@core/services/bonuses/bonuses.service';
import { bonusesActions } from '@store/actions';
import { Store } from '@ngrx/store';
import { appSelectors } from '@store/selectors';

@Injectable()
export class BonusesEffects {
  init$ = createEffect(() => {
    return this._store.select(appSelectors.selectIsAppInitialized).pipe(
      filter(isInitialized => !!isInitialized),
      switchMap(() => [
        // bonusesActions.loadBettingBonusesOnce(),
        bonusesActions.loadMyBettingBonuses()
        // bonusesActions.loadCasinoBonusesOnce()
      ])
    );
  });

  loadBettingBonuses$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(bonusesActions.loadBettingBonusesOnce),
      take(1),
      concatMap(() =>
        this._bonusesService.fetchSportsBettingBonuses().pipe(
          map(data =>
            bonusesActions.loadBettingBonusesSuccess({ bonuses: data })
          ),
          catchError(err => of(bonusesActions.loadBettingBonusesFailure()))
        )
      )
    );
  });

  loadMyBettingBonuses$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(bonusesActions.loadMyBettingBonuses),
      concatMap(() =>
        this._bonusesService.fetchMySportsBettingBonuses().pipe(
          map(data =>
            bonusesActions.loadMyBettingBonusesSuccess({ bonuses: data })
          ),
          catchError(err => of(bonusesActions.loadMyBettingBonusesFailure()))
        )
      )
    );
  });

  /**
   * LOYALITY POINTS
   */
  //  loadLoyalityPoints = createEffect(() => {
  //   return this._actions$.pipe(
  //     ofType(bonusesActions.loadLoyalityPoints),
  //     switchMap(() =>
  //       this._bonusesService
  //         .fetchLoyaltyPoints()
  //         .pipe(
  //           map(data =>
  //             bonusesActions.loadLoyalityPointsSuccess({ points: data })
  //           )
  //         )
  //     )
  //   );
  // });

  loadCasinoBonuses$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(bonusesActions.loadCasinoBonusesOnce),
      take(1),
      concatMap(() =>
        this._bonusesService.fetchCasinoBonuses().pipe(
          map(data =>
            bonusesActions.loadCasinoBonusesSuccess({ bonuses: data })
          ),
          catchError(err => of(bonusesActions.loadCasinoBonusesFailure()))
        )
      )
    );
  });

  loadFreebets$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(bonusesActions.loadFreebets),
      concatMap(() =>
        this._bonusesService
          .fetchFreebetsList()
          .pipe(
            map(freebets =>
              bonusesActions.loadFreebetsSuccess({ freeBets: freebets })
            )
          )
      )
    );
  });

  /**
   * SCOUT SUMMARY
   */
  loadScoutState = createEffect(() =>
    this._actions$.pipe(
      ofType(bonusesActions.loadScoutSummary),
      concatMap(() =>
        forkJoin({
          profit: this._bonusesService.fetchScoutProfit(),
          summary: this._bonusesService.fetchScoutSummary()
        }).pipe(
          map(data =>
            bonusesActions.setScoutSummary({ scoutBonusSummary: data })
          )
        )
      )
    )
  );

  /**
   * LOYALITY POINTS
   */
  loadLoyalityPoints = createEffect(() => {
    return this._actions$.pipe(
      ofType(bonusesActions.loadLoyalityPoints),
      switchMap(() =>
        this._bonusesService
          .fetchLoyaltyPoints()
          .pipe(
            map(data =>
              bonusesActions.loadLoyalityPointsSuccess({ points: data })
            )
          )
      )
    );
  });

  loadCodeBonuses$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(bonusesActions.loadCodeBonusHistory),
      switchMap(() =>
        this._bonusesService
          .fetchClientCodeBonusHistory()
          .pipe(
            map(data =>
              bonusesActions.loadCodeBonusHistorySuccess({ codeBonuses: data })
            )
          )
      )
    );
  });

  loadFreespins$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(bonusesActions.loadFreespins),
      concatMap(action =>
        (action.freespinsType === 'history'
          ? this._bonusesService.fetchFreespinsHistory()
          : this._bonusesService.fetchActiveFreespins()
        ).pipe(
          map(data =>
            bonusesActions.loadFreespinsSuccess({
              freespins: data,
              freespinsType: action.freespinsType
            })
          ),
          catchError(() => of(bonusesActions.loadFreespinsFailure))
        )
      )
    );
  });

  changeLoyaltyPointsToMoney$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(bonusesActions.changeLoyalityPointsToMoney),
      switchMap(() =>
        this._bonusesService.changeLoyaltyPointsToMoney().pipe(
          switchMap(data => {
            if (data.IsSuccessed) {
              return [
                bonusesActions.changeLoyalityPointsToMoneySuccess(),
                bonusesActions.loadLoyalityPoints()
              ];
            } else {
              return [bonusesActions.changeLoyalityPointsToMoneyFailure()];
            }
          }),
          catchError(err =>
            of(bonusesActions.changeLoyalityPointsToMoneyFailure())
          )
        )
      )
    );
  });

  constructor(
    private _actions$: Actions,
    private _bonusesService: BonusesService,
    private _store: Store
  ) {}
}
