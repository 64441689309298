import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCasino from './casino.reducer';
import {
  CasinoJackpotsGames,
  CasinoJackpotsGroups
} from '@models/CasinoJackpot';
import { CasinoGame } from '@models/CasinoGame';
import { GameCategory } from '@models/GameCategory';

export const selectCasinoGamesState = createFeatureSelector<fromCasino.State>(
  fromCasino.casinoFeatureKey
);

export const selectCasinoGames = createSelector(
  selectCasinoGamesState,
  state => state.casinoGames
);

export const selectJackpots = createSelector(
  selectCasinoGamesState,
  state => state.casinoJackpots.jackpots
);

// select casino games grouped by GameID and corresponding jackpots as value
export const selectJackpotsGames = createSelector(
  selectJackpots,
  jackpotResponse => {
    const jackpotGames: CasinoJackpotsGames = {};

    jackpotResponse.jackpots.forEach(jackpot => {
      jackpot.games.forEach(casinoGame => {
        if (!jackpotGames.hasOwnProperty(casinoGame)) {
          jackpotGames[casinoGame] = [];
        }
        jackpotGames[casinoGame].push(jackpot);
      });
    });

    // sort jackpots from highest to lowest win amount
    Object.values(jackpotGames).forEach(casinoGame => {
      casinoGame.sort(
        (prevJackpot, currentJackpot) =>
          currentJackpot.jackpotAmount - prevJackpot.jackpotAmount
      );
    });

    return jackpotGames;
  }
);

export const selectCasinoCategories = createSelector(
  selectCasinoGames,
  selectJackpotsGames,
  (casinoGames, jackpotsGames) => {
    const gamesCategories: GameCategory[] = casinoGames.games;

    // add coresponding jackpots to casino games
    gamesCategories.forEach(gameCategory => {
      gameCategory.Games.forEach(game => {
        // if jackpot for given casino game exists
        if (jackpotsGames.hasOwnProperty(game.GameID)) {
          game.jackpots = jackpotsGames[game.GameID];
        }
      });
    });

    return gamesCategories;
  }
);

export const selectRioGames = createSelector(
  selectCasinoGamesState,
  state => state.rioGames
);

export const selectRioCategories = createSelector(
  selectRioGames,
  rioGames => rioGames.games
);

export const selectGames = createSelector(
  selectCasinoGamesState,
  state => state.categoryGames
);

export const selectGamesByCategoriess = createSelector(
  selectGames,
  categoryGames => categoryGames.games
);

// select casino jackpots grouped by its jackpotGroupID
export const selectJackpotsGroups = createSelector(
  selectJackpots,
  selectCasinoCategories,
  (jackpotResponse, casinoGames) => {
    const jackpotGroups: CasinoJackpotsGroups = [];

    jackpotResponse.jackpots.forEach(jackpot => {
      const jackpotGroupsID = jackpotGroups.findIndex(
        obj => obj.jackpotGroupId === jackpot.jackpotGroupId
      );

      const foundGames: CasinoGame[] = [];

      // find corresponding casino games
      jackpot.games.forEach(gameID => {
        casinoGames.forEach(category => {
          category.Games.forEach(casinoGame => {
            const foundGameID = jackpot.games.find(
              id => id === casinoGame.GameID
            );
            if (
              foundGameID &&
              foundGames.findIndex(
                game => game.GameID === casinoGame.GameID
              ) === -1
            ) {
              foundGames.push(casinoGame);
              return;
            }
          });
        });
      });

      if (jackpotGroupsID === -1) {
        jackpotGroups.push({
          casinoGames: foundGames,
          jackpotGroupId: jackpot.jackpotGroupId,
          jackpotGroupName: jackpot.jackpotGroupName,
          jackpots: [jackpot]
        });
      } else {
        jackpotGroups[jackpotGroupsID].jackpots.push(jackpot);
      }
    });

    // sort jackpots by their amount
    jackpotGroups.forEach(group => {
      group.jackpots = group.jackpots.sort(
        (prev, cur) => cur.jackpotAmount - prev.jackpotAmount
      );
    });

    // sort groups by highest jackpot
    jackpotGroups.sort(
      (prev, cur) =>
        cur.jackpots[0].jackpotAmount - prev.jackpots[0].jackpotAmount
    );

    return jackpotGroups;
  }
);

// export const selectCategoriesByVariant = type => {
//   switch (type) {
//     case 'casino':
//       return selectCasinoCategories;
//     case 'rio':
//       return selectRioCategories;
//     // return selectCasinoCategories;
//   }
// };

export const selectCategoriesByVariant = type => {
  switch (type) {
    case 'casino':
      return createSelector(selectCasinoCategories, categories =>
        categories.sort((a, b) => +a.CategoryOrder - +b.CategoryOrder)
      );

    case 'rio':
      return createSelector(selectRioCategories, categories =>
        categories.sort((a, b) => +a.CategoryOrder - +b.CategoryOrder)
      );
  }
};

export const selectGamesByCategories = (type?) => {
  switch (type) {
    case 1:
      return selectGamesByCategoriess;
    case 'rio':
      return selectRioCategories;
  }
};

export const selectGamesByCategoryID = (
  categoryID: number,
  casinoType: 'rio' | 'casino' | 'favorites'
) =>
  createSelector(
    casinoType === 'casino' ? selectCasinoGames : selectRioGames,
    games => games.games.find(game => game.CategoryID === categoryID)?.Games
  );

export const selectCasinoGamesByCategoryID = (categoryID: number) =>
  createSelector(
    selectCasinoGames,
    games => games.games.find(game => game.CategoryID === categoryID)?.Games
  );

export const selectRioGamesByCategoryID = (categoryID: number) =>
  createSelector(
    selectRioGames,
    games => games.games.find(game => game.CategoryID === categoryID)?.Games
  );
