import { Pipe, PipeTransform } from '@angular/core';
import { isAvailable, ModulesWithOperator } from '@core/functions/isAvailable';
@Pipe({
  name: 'available',
  pure: true
})
export class AvailablePipe implements PipeTransform {
  constructor() {}

  /** checkne ci je modul dostupny */
  transform(searchModule: string | string[] | ModulesWithOperator): boolean {
    return isAvailable(searchModule);
  }
}
