import { Notification } from '@models/Notifications';
import { Action, createReducer, on } from '@ngrx/store';
import { authActions, notificationsActions } from '@store/actions';

export const NOTIFICATIONS_STATE_KEY = 'notifications';
export const NOTIFICATION_COUNT = 5;
// const LOCAL_STORAGE_UNREAD_NOTIFICATION_KEY = 'unreadNotifications';
type Status = 'idle' | 'loading' | 'success' | 'failure';
export interface State {
  unreadNotificationsCount: number;
  list: {
    cursor: {
      page: number;
      pageSize: number;
    };
    list: Notification['guid'][];
    complete: boolean;
    status: Status;
  };
  notifications: Record<Notification['guid'], Notification>;
}
const initialState: State = {
  unreadNotificationsCount: 0,
  notifications: {},
  list: {
    cursor: {
      page: 0,
      pageSize: NOTIFICATION_COUNT
    },
    list: [],
    complete: false,
    status: 'idle'
  }
};

const notificationsReducer = createReducer(
  initialState,
  on(authActions.logoutSuccess, () => initialState),
  on(authActions.softLogoutSuccess, () => initialState),
  on(
    notificationsActions.getUnreadNotificationsCountSuccess,
    (state, action) => ({
      ...state,
      unreadNotificationsCount: action.count
      // +
      // (
      //   JSON.parse(
      //     localStorage.getItem(LOCAL_STORAGE_UNREAD_NOTIFICATION_KEY)
      //   ) || []
      // ).length
    })
  ),
  on(
    notificationsActions.incrementUnreadNotificationsCount,
    (state, action) => ({
      ...state,
      unreadNotificationsCount: state.unreadNotificationsCount + 1
    })
  ),
  on(notificationsActions.getFirstNotificationsList, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      cursor: {
        ...initialState.list.cursor,
        page: Math.floor(action.count / NOTIFICATION_COUNT)
      },
      complete: false,
      status: 'loading' as Status
    }
  })),
  on(notificationsActions.getNextNotificationsList, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      status: 'loading' as Status
    }
  })),
  on(notificationsActions.getNotificationsListSuccess, (state, action) => {
    // // get unread notifications from localstorage
    // let unreadNotifications: string[] =
    //   JSON.parse(localStorage.getItem(LOCAL_STORAGE_UNREAD_NOTIFICATION_KEY)) ||
    //   [];

    // // mark newly fetched notifications as unread
    // action.notifications.forEach(notification => {
    //   notification.viewed = notification.viewed
    //     ? !unreadNotifications.includes(notification.guid)
    //     : notification.viewed;
    // });

    // // set new unread notifications
    // unreadNotifications = [
    //   ...unreadNotifications,
    //   ...action.notifications
    //     .filter(notification => !notification.viewed)
    //     .map(notification => notification.guid)
    // ].distinct();
    // localStorage.setItem(
    //   LOCAL_STORAGE_UNREAD_NOTIFICATION_KEY,
    //   JSON.stringify(unreadNotifications)
    // );

    const notifications: State['notifications'] = {
      ...state.notifications,
      ...action.notifications.reduce(
        (acc, notification) => ({
          ...acc,
          [notification.guid]: {
            ...notification,
            viewed:
              typeof state.notifications[notification.guid]?.viewed ===
              'boolean'
                ? state.notifications[notification.guid].viewed
                : notification.viewed
          }
        }),
        {}
      )
    };
    return {
      ...state,
      notifications,
      unreadNotificationsCount: 0,
      list: {
        ...state.list,
        status: 'success' as Status,
        list: Object.values(notifications)
          .sortBy(notification => +new Date(notification.created))
          .map(notification => notification.guid),
        ...(action.notifications.length < state.list.cursor.pageSize
          ? {
              complete: true
            }
          : {
              cursor: {
                ...state.list.cursor,
                page: state.list.cursor.page + 1
              }
            })
      }
    };
  }),
  on(notificationsActions.getNotificationsListFailure, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      status: 'failure' as Status
    }
  })),
  on(notificationsActions.markNotificationAsRead, (state, action) => {
    // localStorage.setItem(
    //   LOCAL_STORAGE_UNREAD_NOTIFICATION_KEY,
    //   JSON.stringify(
    //     (
    //       JSON.parse(
    //         localStorage.getItem(LOCAL_STORAGE_UNREAD_NOTIFICATION_KEY)
    //       ) || []
    //     ).filter(id => id !== action.notificationID)
    //   )
    // );
    return {
      ...state,
      notifications: {
        ...state.notifications,
        [action.notificationID]: {
          ...state.notifications[action.notificationID],
          viewed: true
        }
      }
    };
  })
);

/** notifications reducer */
export function reducer(state: State | undefined, action: Action) {
  return notificationsReducer(state, action);
}
