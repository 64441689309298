<div class="icon top-icon icon-ic_info-circle"></div>
<ion-row class="ion-text-center bold modal-header">
  <ion-col class="bold">
    {{ 'ticket_history_detail_graph_modal_title' | translate }}
    <!-- Chart of events -->
    <span class="green-text">
      <!-- {{ ticket.possibleWinValue | formatNumberWithCurrency }} -->
    </span>
  </ion-col>
  <!-- <div (click)="closeBuybackModal(false)" class="icon-ic_close btn-close"></div> -->
</ion-row>
<!-- <div class="bordered modal-content">
  <doxx-ticketevents [ticket]="ticket" [style]="'buyback'"></doxx-ticketevents>
</div> -->
<ion-row class="values-row">
  <ion-col style="background: white; padding-top: 0">
    <div id="my-pie-chart-container">
      <div
        id="my-pie-chart"
        class="w-[120px] h-[120px] absolute left-1/2 -ml-[60px] -top-[60px] rounded-full flex justify-center items-center"
        [ngStyle]="{
          background:
            'conic-gradient(rgb(0, 109, 178) 0%,  rgb(95, 193, 255) ' +
            _oddsStats.U.percent +
            '%, rgb(171, 0, 36)' +
            _oddsStats.U.percent +
            '%, rgb(244, 121, 0)' +
            _oddsStats.N.percent +
            '%,rgb(69, 176, 12 ) ' +
            _oddsStats.N.percent +
            '%,  rgb(234, 250, 130) ' +
            _oddsStats.Y.percent +
            '% )'
        }"
      ></div>
      <div
        *ngIf="_oddsStats.N.count !== 0"
        class="line"
        [ngStyle]="{
          transform: 'rotate(' + badgen + 'turn)'
        }"
      >
        <!-- transform: 'rotate(' + (360 * (n / 200) - 180) + 'deg)' -->
        <div
          class="badge"
          [ngStyle]="{
            transform: 'rotate(-' + badgen + 'turn)'
          }"
        >
          {{ _oddsStats.N.count }}
        </div>
      </div>

      <div
        *ngIf="_oddsStats.U.count !== 0"
        class="line u"
        [ngStyle]="{
          transform: 'rotate(' + badgeu + 'turn)'
        }"
      >
        <div
          class="badge u"
          [ngStyle]="{
            transform: 'rotate(-' + badgeu + 'turn)'
          }"
        >
          {{ _oddsStats.U.count }}
        </div>
      </div>

      <div
        *ngIf="_oddsStats.Y.count !== 0"
        class="line y"
        [ngStyle]="{
          transform: 'rotate(' + badgey + 'turn)'
        }"
      >
        <div
          class="badge y"
          [ngStyle]="{
            transform: 'rotate(-' + badgey + 'turn)'
          }"
        >
          {{ _oddsStats.Y.count }}
        </div>
      </div>

      <div
        class="w-[106px] h-[106px] absolute left-1/2 -ml-[53px] -top-[53px] rounded-full flex justify-center items-center bg-white shadow-[0_6px_14px_rgba(0,0,0,0.15)]"
        style="color: var(--ticket-status-icon-color)"
      >
        <span class="ion-text-center bold circle-header">{{
          'ticket_history_detail_graph_text' | translate
        }}</span>
        <!-- <span class="ion-text-center bold circle-header">Events count</span> -->
      </div>
    </div>
  </ion-col>
</ion-row>

<div class="type-list">
  <ion-row class="info pd-btm">
    <ion-col>
      <span>
        <!-- Win for buyback -->
        <!-- Požadovaná výhra na výplatu -->

        <img
          slot="start"
          class="card-icon"
          style="width: 24px; float: left; margin: -3px 8px"
          src="assets/icon/ticket-graph-gradient-red.png"
          alt="card-type-img"
        />
        {{ 'ticket_history_detail_graph_modal_legend_lose' | translate }}
        <!-- Lost events -->
      </span>
    </ion-col>
  </ion-row>
  <ion-row class="info pd-btm">
    <ion-col>
      <span>
        <!-- Win for buyback -->
        <!-- Požadovaná výhra na výplatu -->
        <img
          slot="start"
          class="card-icon"
          style="width: 24px; float: left; margin: -3px 8px"
          src="assets/icon/ticket-graph-gradient-green.png"
          alt="card-type-img"
        />
        {{ 'ticket_history_detail_graph_modal_legend_win' | translate }}
        <!-- Winning events -->
      </span>
    </ion-col>
  </ion-row>
  <ion-row class="info pd-btm">
    <ion-col>
      <span>
        <!-- Win for buyback -->
        <!-- Požadovaná výhra na výplatu -->
        <img
          slot="start"
          class="card-icon"
          style="width: 24px; float: left; margin: -3px 8px"
          src="assets/icon/ticket-graph-gradient-blue.png"
          alt="card-type-img"
        />
        {{ 'ticket_history_detail_graph_modal_legend_approved' | translate }}
        <!-- Pending events -->
      </span>
    </ion-col>
  </ion-row>
</div>

<ion-footer>
  <ion-button class="buy-back-btn" expand="block" (click)="closeModal()">
    <!-- *ngIf="this.ticket.autoBuyBackSet === false" -->
    <span class="capitalize-first">
      {{ 'contacts_close' | translate }}
      <!-- {{ 'ticket_buyback_sellticketfor' | translate }} -->
    </span>
  </ion-button>
</ion-footer>
