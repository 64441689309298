import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTickethistory from './tickethistory.reducer';

const selectTickethistoryState = createFeatureSelector<fromTickethistory.State>(
  fromTickethistory.tickethistoryFeatureKey
);

const selectTickets = createSelector(
  selectTickethistoryState,
  state => state.tickets
);

// Last tickets
// TICKETS
export const selectLastTickets = createSelector(
  selectTickethistoryState,
  (state: fromTickethistory.State) => state.lastTickets
);
export const selectLastTicketsLoading = createSelector(
  selectLastTickets,
  state => state.loading
);
export const selectLastTicketsTickets = createSelector(
  selectLastTickets,
  selectTickets,
  (state, tickets) => state.tickets.map(ticketCode => tickets[ticketCode])
);
export const selectLastTicketsError = createSelector(
  selectLastTickets,
  state => state.error
);

// HYSTORY LIST
const selectList = createSelector(
  selectTickethistoryState,
  state => state.list
);

export const selectIsTicketHistoryLoading = createSelector(
  selectList,
  state => state.loading
);

export const selectTicketHistoryListError = createSelector(
  selectList,
  state => state.error
);

export const selectTicketHistoryList = createSelector(
  selectList,
  selectTickets,
  (state, tickets) => state.list.map(ticketCode => tickets[ticketCode])
);

export const selectTicketHistoryCursor = createSelector(
  selectList,
  state => state.cursor
);

// HISTORY DETAIL
export const selectTicketHistoryDetail = ticketCode =>
  createSelector(selectTickets, state => state[ticketCode]);

export const selectHistoryDetail = createSelector(
  selectTickethistoryState,
  state => state.detail
);

export const selectHistoryDetailLoading = createSelector(
  selectHistoryDetail,
  state => state.loading
);

export const selectHistoryDetailError = createSelector(
  selectHistoryDetail,
  state => state.error
);

// EDIT HISTORY
export const selectTicketEditHistory = ticketCode =>
  createSelector(selectTickethistoryState, state => ({
    ...state.editHistory[ticketCode],
    edits: state.editHistory[ticketCode]?.edits.sortBy(edit => -edit.version)
  }));
