import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class SharepopupService {
  constructor(private _matDialog: MatDialog) {}

  /** open stare popup, use native if there is available */
  share(options: { url: string; title: string; text: string }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        navigator
          .share({
            url: options.url,
            title: options.title,
            text: options.text
          })
          .then(() => resolve())
          .catch(() => reject());
      } catch (err) {
        import('./sharepopup.component').then(c => {
          this._matDialog
            .open(c.SharepopupComponent, {
              data: {
                url: options.url,
                title: options.title,
                text: options.text
              }
            })
            .afterClosed()
            .subscribe(value => {
              if (value === true) {
                resolve();
              } else {
                reject();
              }
            });
        });
      }
    });
  }
}
