import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { maintenanceSelectors } from '@store/selectors';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export interface MaintenanceSelectorsAlias {
  betting: (state: object) => unknown;
  casino: (state: object) => unknown;
  withdrawal: (state: object) => unknown;
  deposit: (state: object) => unknown;
  wallet: (state: object) => unknown;
}
const MAINTENANCE_SECTIONS: MaintenanceSelectorsAlias = {
  betting: maintenanceSelectors.selectIsBettingBlocked,
  casino: maintenanceSelectors.selectIsCasinoBlocked,
  withdrawal: maintenanceSelectors.selectIsWithdrawalBlocked,
  deposit: maintenanceSelectors.selectIsDepositBlocked,
  wallet: maintenanceSelectors.selectIsWalletBlocked
};

@Pipe({
  name: 'isBlocked'
})
export class IsBlockedPipe implements PipeTransform {
  constructor(private _store: Store) {}

  /** checkne ci je nieco blokovane */
  transform(
    value:
      | (keyof MaintenanceSelectorsAlias)[]
      | keyof MaintenanceSelectorsAlias,
    condition: 'AND' | 'OR' = 'AND'
  ): Observable<boolean> {
    if (!Array.isArray(value)) {
      value = [value];
    }
    return combineLatest(
      value
        .filter(section => MAINTENANCE_SECTIONS[section] !== undefined)
        .map(section => this._store.select(MAINTENANCE_SECTIONS[section]))
    ).pipe(
      distinctUntilChanged(),
      map((blocked: boolean[]) =>
        blocked.reduce((a, b) => (condition === 'AND' ? a && b : a || b))
      )
    );
  }
}
